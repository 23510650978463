import baseUrl from '../utils/Constants';

export default async function (token) {
  try {
    let apiUrl = `${baseUrl}/users/notifications/unread`;
    const response = await fetch(apiUrl, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });

    const responseJson = await response.json();
    return responseJson;
  } catch (error) {
    console.log(error);
  }
}
