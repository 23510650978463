/* eslint-disable react/prop-types */
import React, {useState, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import useStyles from './styles';
import {
  Box,
  Typography,
  Checkbox,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Grid,
  Link,
  Divider,
  createMuiTheme,
} from '@material-ui/core';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import {useHistory} from 'react-router-dom';
import WebToolbar from '../../components/toolbar/WebToolbar';
import SideNav from '../../components/sidenav/SideNav';
import ActivityIndicator from '../../components/ActivityIndicator';
import sendOrder from '../../services/sendOrder';
import addItemToCart from '../../services/addItemToCart';
import addFavoriteVendor from '../../services/addFavoriteVendor';
import AddShoppingCartOutlinedIcon from '@material-ui/icons/AddShoppingCartOutlined';
import {
  setRepeatOrderChecked,
  setHomeDeliveryChecked,
  setCartOrderId,
  setSelectedCartItems,
} from '../../redux/actions/user';
import showToast from '../Toast';
import AppBarName from '../DumbComponents/AppBarName';
import {isEmpty, isNumber} from 'lodash';
import moment from 'moment';
import MobileFooter from '../../pages/MobileFooter';
import useNotifications from '../../helpers/useNotifications';

// const materialTheme = createMuiTheme({
//   overrides: {
//     MuiButton: {
//       textPrimary: {
//         color: '#fff',
//         backgroundColor: '#0277BD',
//         '&:hover': {
//           color: '#0277BD',
//           backgroundColor: '#fff',
//           border: '1px solid #0277BD',
//         },
//       },
//     },
//   },
// });

const OrderSummary = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const {user, token} = useSelector((state) => state.user);
  const repeatOrderchecked = useSelector(
    (state) => state.user.repeatOrderChecked
  );

  const {getNotificationData} = useNotifications();
  const homeDeliverychecked = useSelector((state) => {
    return state.user.homeDeliveryChecked;
  });
  const [frequency, setFrequency] = useState('');
  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());
  const [width, setWidth] = useState(window.innerWidth);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [subTotal, setSubTotal] = useState();
  const [total, setTotal] = useState('');
  const [agreeTnC, setAgreeTnC] = useState(true);
  const [state, setState] = React.useState({
    checkedA: true,
    checkedB: true,
    checkedF: true,
    checkedG: true,
  });
  const [savedOrderId, setSavedOrderId] = useState(null);

  const handleChange = (event) => {
    setState({...state, [event.target.name]: event.target.checked});
  };

  const cartOrderId = useSelector((state) => {
    return state.user.cartOrderId;
  });

  useEffect(() => {
    // eslint-disable-next-line react/prop-types
    const _item = props.location.state.selectedCartItems.map((item) => {
      return {
        itemName: item.name,
        itemCount: item.count,
        itemPrice: item.price,
      };
    });
    setSelectedItems(_item);
    calculateSubTotal();
    dispatch(setRepeatOrderChecked(false));
  }, []);

  function calculateSubTotal() {
    var tot = 0;
    var isTotal = false;
    props.location.state.selectedCartItems.forEach((element) => {
      if (element.price !== 0) {
        const t = element.count * element.price;
        tot = tot + t;
        isTotal = true;
      } else {
        isTotal = false;
      }
    });
    if (tot > 0) {
      setSubTotal(`${tot}`);
    }
    if (isTotal) {
      setTotal(`${tot}`);
    }
  }

  async function orderAddedItems() {
    try {
      setLoading(true);
      // eslint-disable-next-line react/prop-types
      const vendorId = props.location.state.vendor.vendor.vendorId;
      const messages = props.location.state.messages;
      const arr = [];
      const len = selectedItems.length;

      for (var i = 0; i < len; i++) {
        arr.push({
          Item: selectedItems[i].itemName,
          Qty: selectedItems[i].itemCount,
          Unit: '1',
          Price: selectedItems[i].itemPrice.toString(),
        });
      }

      const payload = {
        vendorId: vendorId,
        mode: 4,
        requireHomeDelivery: homeDeliverychecked,
        OrderItems: arr,
      };
      const ordermessages = messages.filter((message) => {
        if (message.messageType === 0) {
          return {messageText: message.item};
        }
      });
      const orderImages = messages.filter((message) => {
        if (message.messageType === 1) {
          return {messageText: message.item};
        }
      });
      if (!isEmpty(ordermessages)) {
        payload['ordermessages'] = ordermessages;
      }
      if (!isEmpty(orderImages)) {
        payload['orderImages'] = orderImages;
      }
      if (props.location.state.vendorFor !== 0) {
        const obj = {
          TimeSlot: '00:00-00:00',
          ServiceDate: moment(new Date()).format('YYYY-MM-DD'),
          RequestFor: user.username,
        };
        payload.ServiceRequest = obj;
      }
      const response = await sendOrder(payload, token);
      if (response.successCode === 1) {
        showToast('success', 'Order Placed Successfully');
        setLoading(false);
        dispatch(setSelectedCartItems([]));
        setSavedOrderId(response.response.orderId);
        getNotificationData();
        if (!response.response.isFavouriteVendor) {
          setDialogOpen(true);
        } else {
          history.push({
            pathname: '/orderplaced',
            state: {orderId: response.response.orderId},
          });
        }
      } else {
        // setVendors([]);
        setLoading(false);
      }
    } catch (error) {
      console.log('error image', error);
      setLoading(false);
    }
  }

  const handleDialogOpen = () => {
    setDialogOpen(true);
  };

  const handleDialogYesClose = async () => {
    try {
      const response = await addFavoriteVendor(
        props.location.state.vendor.vendor.vendorId,
        token
      );
      if (response.successCode === 1) {
        setLoading(false);
        showToast('success', 'Added to favourite vendor ');
        // history.push('/home');
        history.push({
          pathname: '/orderplaced',
          state: {orderId: savedOrderId},
        });
      } else {
        // setVendors([]);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
    setDialogOpen(false);
    // history.push({pathname: '/home'});
  };

  const handleFromDateChange = (date) => {
    setFromDate(date);
  };

  const handleToDateChange = (date) => {
    setToDate(date);
  };

  const handleFrequencyChange = (event) => {
    setFrequency(event.target.value);
  };
  const handleRepeatOrderCheckChange = () => {
    dispatch(setRepeatOrderChecked(!repeatOrderchecked));
    // setRepeatOrderChecked(!repeatOrderchecked);
  };

  const handleHomeDeliveryCheckChange = () => {
    dispatch(setHomeDeliveryChecked(!homeDeliverychecked));
    // setHomeDeliveryChecked(!homeDeliverychecked);
  };

  const saveOrder = async () => {
    try {
      setLoading(true);

      let payload = {
        // OrderId: 12345,
        vendorid: props.location.state.vendor.vendor.vendorId,
        mode: 4,
        OrderItems: props.location.state.selectedCartItems.map((item) => {
          return {
            Item: item.name,
            Qty: item.count,
            Unit: '1',
            Price: item.price.toString(),
          };
        }),
        ordermessages: props.location.state.messages.filter((message) => {
          if (message.messageType === 0) {
            return {messageText: message.item};
          }
        }),
        OrderImages: props.location.state.messages.filter((message) => {
          if (message.messageType === 1) {
            return {messageText: message.item};
          }
        }),
      };

      const response = await addItemToCart(token, JSON.stringify(payload));
      if (response.successCode === 1) {
        dispatch(setCartOrderId(response.response.orderId));
        showToast('success', 'Order saved successfully');
        history.push('/home');
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (error) {
      console.log('error', error);
      setLoading(false);
    }
  };

  const classes = useStyles();
  return (
    <>
      <div className={classes.container}>
        <WebToolbar />
        <div className={classes.sideNav}>
          <SideNav />
        </div>
        <AppBarName title={'Order Summary'} />
        <div className={classes.content}>
          {loading ? (
            <ActivityIndicator />
          ) : (
            <>
              <Box
                boxShadow={1}
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  padding: '15px',
                }}
              >
                <Grid container xs={12}>
                  <Grid xs={6}>
                    <Typography
                      style={{
                        fontWeight: '500',
                        fontSize: '14px',
                        padding: '6px 12px',
                        color: 'white',
                        backgroundColor: '#3578BD',
                        display: 'flex',
                        justifyContent: 'center',
                        marginBottom: '5px',
                      }}
                    >
                      Items{' '}
                    </Typography>
                    <Box>
                      {selectedItems.length === 0 ? (
                        <Typography>No items Added</Typography>
                      ) : (
                        <Box>
                          <Grid container>
                            <Grid item xs={6}>
                              <Typography style={{fontWeight: 900}}>
                                Name
                              </Typography>
                            </Grid>
                            <Grid item xs={6}>
                              <Typography style={{fontWeight: 900}}>
                                Price
                              </Typography>
                            </Grid>
                          </Grid>
                          {selectedItems.map((item, index) => {
                            return (
                              <>
                                <Grid container key={index}>
                                  <Grid item xs={6}>
                                    <Typography>{`${item.itemName}  `}</Typography>
                                  </Grid>
                                  <Grid item xs={6}>
                                    <Typography>{`₹ ${
                                      item.itemPrice === 0
                                        ? 'NA'
                                        : item.itemPrice
                                    }`}</Typography>
                                  </Grid>
                                </Grid>
                                <Divider />
                              </>
                            );
                          })}
                        </Box>
                      )}
                    </Box>
                  </Grid>
                </Grid>
                <Box pt={1}>
                  <Grid container xs={6}>
                    <Grid item xs={6}>
                      <Box fontWeight="600" display="inline">
                        Sub total
                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography>{`₹  ${
                        subTotal >= 0 ? subTotal : 'NA'
                      }`}</Typography>
                    </Grid>
                  </Grid>
                </Box>
                <Box pt={1} style={{display: 'flex', flexDirection: 'row'}}>
                  <Typography>
                    <Box fontWeight="fontWeightMedium" display="inline">
                      Total-
                    </Box>
                    To be confirmed by vendor
                  </Typography>
                </Box>
                <Box pt={1} pb={1}>
                  <Typography>
                    <Box fontWeight="fontWeightMedium" display="inline">
                      Note-
                    </Box>
                    This is not final amount, please contact vendor for final
                    amount
                  </Typography>
                </Box>
              </Box>
              <Box boxShadow={1}>
                {/* <Box>
                <ThemeProvider theme={materialTheme}>
                  <Accordion
                    style={{
                      backgroundColor: 'transparent',
                    }}
                  >
                    <AccordionSummary
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Box
                        style={{
                          justifyContent: 'space-between',
                          width: '100%',
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                        }}
                        onClick={handleRepeatOrderCheckChange}
                      >
                        <Typography className={classes.root}>
                          Repeat this order
                        </Typography>
                        <Checkbox
                          color="primary"
                          inputProps={{'aria-label': 'secondary checkbox'}}
                          checked={repeatOrderchecked}
                          onChange={handleRepeatOrderCheckChange}
                        />
                      </Box>
                    </AccordionSummary>
                    <AccordionDetails
                      style={{display: 'flex', flexDirection: 'column'}}
                    >
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          Repeat Frequency
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={frequency}
                          onChange={handleFrequencyChange}
                        >
                          <MenuItem value="" disabled>
                            Repeat Frequency
                          </MenuItem>
                          <MenuItem value={'Daily'}>Daily</MenuItem>
                          <MenuItem value={'Weekly'}>Weekly</MenuItem>
                          <MenuItem value={'Fortnightly'}>Fortnightly</MenuItem>
                          <MenuItem value={'Monthly'}>Monthly</MenuItem>
                        </Select>
                      </FormControl>
                      <Box style={{display: 'flex', flexDirection: 'column'}}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <KeyboardDatePicker
                            margin="normal"
                            id="date-picker-dialog"
                            label="From Date"
                            format="MM/dd/yyyy"
                            autoOk={true}
                            value={fromDate}
                            disablePast={true}
                            onChange={handleFromDateChange}
                            KeyboardButtonProps={{
                              'aria-label': 'change date',
                            }}
                          />
                        </MuiPickersUtilsProvider>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <KeyboardDatePicker
                            margin="normal"
                            id="date-picker-dialog"
                            label="To Date"
                            format="MM/dd/yyyy"
                            autoOk={true}
                            value={toDate}
                            disablePast={true}
                            minDate={fromDate}
                            minDateMessage={
                              'Date should not be before From date'
                            }
                            onChange={handleToDateChange}
                            KeyboardButtonProps={{
                              'aria-label': 'change date',
                            }}
                          />
                        </MuiPickersUtilsProvider>
                      </Box>
                    </AccordionDetails>
                  </Accordion>
                </ThemeProvider>
              </Box> */}

                <Box
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    margin: '15px',
                    justifyContent: 'space-between',
                  }}
                  onClick={handleHomeDeliveryCheckChange}
                >
                  <Typography>Require home delivery</Typography>
                  <Checkbox
                    color="primary"
                    inputProps={{'aria-label': 'secondary checkbox'}}
                    checked={homeDeliverychecked}
                    onChange={handleHomeDeliveryCheckChange}
                  />
                </Box>
                <Divider />
                <Box
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    margin: '15px',
                    justifyContent: 'space-between',
                  }}
                  // onClick={handleHomeDeliveryCheckChange}
                >
                  <Typography>
                    By placing this order you agree to gearConnect{' '}
                    <Link
                      style={{cursor: 'pointer'}}
                      onClick={() => history.push('/terms-of-use')}
                    >
                      terms and condition
                    </Link>
                  </Typography>

                  <Checkbox
                    color="primary"
                    checked={agreeTnC}
                    onChange={(check) => {
                      // setAgreeTnC(check.target.checked);
                    }}
                    inputProps={{'aria-label': 'secondary checkbox'}}
                  />
                </Box>
                <Divider />
                <Box
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    margin: '15px',
                    justifyContent: 'space-between',
                  }}
                  // onClick={handleHomeDeliveryCheckChange}
                >
                  <Typography style={{cursor: 'pointer'}}>
                    <Link
                      onClick={() => {
                        // history.push('/terms-of-use');
                      }}
                    >
                      {`I agree to Vendor's delivery, return and refund policy`}
                    </Link>
                  </Typography>
                  <Checkbox
                    color="primary"
                    checked
                    inputProps={{'aria-label': 'secondary checkbox'}}
                  />
                </Box>
              </Box>
              <Box
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <Button
                  variant="outlined"
                  disabled={!agreeTnC}
                  className={classes.submitButtons}
                  onClick={() => {
                    saveOrder();
                  }}
                >
                  <AddShoppingCartOutlinedIcon style={{fontSize: '20px'}} />
                  Save To Cart
                </Button>
                <Button
                  variant="outlined"
                  disabled={!agreeTnC}
                  className={classes.submitButtons}
                  onClick={() => {
                    orderAddedItems();
                  }}
                >
                  <NavigateNextIcon style={{fontSize: '20px'}} />
                  {props.location.state.vendorFor === 0
                    ? 'Place Order'
                    : 'Request Service'}
                </Button>
              </Box>
              <Box>
                <Dialog
                  open={dialogOpen}
                  onClose={() => {
                    setDialogOpen(false);
                    history.push({
                      pathname: '/orderplaced',
                      state: {orderId: savedOrderId},
                    });
                  }}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogTitle id="alert-dialog-title">
                    {`Do you want to add ${props.location.state.vendor.vendor.organizationName} to your
                  favourite vendors?`}
                  </DialogTitle>
                  <DialogActions>
                    <Button
                      variant="outlined"
                      onClick={() => {
                        setDialogOpen(false);
                        history.push({
                          pathname: '/orderplaced',
                          state: {orderId: savedOrderId},
                        });
                      }}
                      autoFocus
                    >
                      NO
                    </Button>
                    <Button variant="outlined" onClick={handleDialogYesClose}>
                      YES
                    </Button>
                  </DialogActions>
                </Dialog>
              </Box>
            </>
          )}
          {/* {orderIdCart ??
          orderIdCart.map((i, ind) => {
            return <Typography key={ind}>{i.orderId}</Typography>;
          })} */}
        </div>
      </div>
      <MobileFooter />
    </>
  );
};

export default OrderSummary;
