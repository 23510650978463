import React from 'react';
import PropTypes from 'prop-types';
import WebToolbar from '../components/toolbar/WebToolbar';
import {Box, makeStyles} from '@material-ui/core';
import AppBarName from '../components/DumbComponents/AppBarName';
import SideNav from '../components/sidenav/SideNav';
import {useSelector} from 'react-redux';
import MobileFooter from './MobileFooter';

const useStyle = makeStyles((theme) => ({
  container: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    paddingBottom: '100px',
    backgroundColor: 'white',
    flex: 1,
    width: '80%',
    margin: 'auto',
    '& > header > header': {
      position: 'inherit',
    },
  },
  sideNav: {
    ['@media (max-width:700px)']: {
      display: 'none',
    },
    //height: '100%',
    width: '92px',
    position: 'fixed',
    zIndex: 1,
    top: 0,
    // right: 0,
    right: '15px',
    overflowX: 'hidden',
    marginTop: 190,
    // marginRight: theme.spacing(1),
    marginRight: 0,
    backgroundColor: '#3B3B3B',
    elevation: 2,
  },
}));

function TermsOfUse() {
  const classes = useStyle();
  const isLoggedIn = useSelector((state) => state.user.isLoggedIn);

  return (
    <>
      <div className={classes.container}>
        <WebToolbar />
        <div className={classes.sideNav}>{isLoggedIn && <SideNav />}</div>
        <AppBarName title={'Terms Of Use'} />
        <Box>
          <iframe
            src="https://gcterms.s3.ap-southeast-1.amazonaws.com/termsofuse.html"
            style={{
              width: '100%',
              flex: 1,
              marginTop: 0,
              height: '100vh',
              overflow: 'hidden',
            }}
            title="Iframe Example"
          ></iframe>
        </Box>
      </div>
      <MobileFooter />
    </>
  );
}

TermsOfUse.propTypes = {};

export default TermsOfUse;
