import {makeStyles} from '@material-ui/styles';

const useStyle = makeStyles((theme) => ({
  root: {},
  container: {
    backgroundColor: 'white',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    paddingBottom: '100px',
    width: '80%',
    margin: 'auto',
    '& > header > header': {
      position: 'inherit',
      // display: 'flex',
      // justifyContent: 'center',
      // width: '80%',
    },
  },
  sideNav: {
    ['@media (max-width:700px)']: {
      display: 'none',
    },
    //height: '100%',
    width: '92px',
    position: 'fixed',
    zIndex: 1,
    top: 0,
    // right: 0,
    right: '15px',
    overflowX: 'hidden',
    marginTop: 190,
    // marginRight: theme.spacing(1),
    marginRight: 0,
    backgroundColor: '#3B3B3B',
    elevation: 2,
  },
  basketDetailCont: {
    cursor: 'pointer',
    padding: '10px',
  },
  basketDetailContOne: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  basketName: {fontWeight: '900', fontSize: 18, color: 'black'},
  menuCont: {display: 'flex', alignItems: 'center'},
  basketDetailText: {fontSize: 16, color: 'gray'},
  icon: {
    width: 40,
    height: 40,
  },
  textActive: {
    paddingLeft: '5px',
    paddingRight: '5px',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: 'green',
    color: 'white',
    paddingHorizontal: 5,
    borderRadius: 4,
  },
  textClosed: {
    paddingLeft: '5px',
    paddingRight: '5px',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: 'red',
    color: 'white',
    paddingHorizontal: 5,
    borderRadius: 4,
  },
  textFreeze: {
    paddingLeft: '5px',
    paddingRight: '5px',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: 'orange',
    color: 'white',
    paddingHorizontal: 5,
    borderRadius: 4,
  },
  openCameraCont: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  ordersFab: {
    position: 'fixed',
    bottom: '10%',
    right: '10%',
  },
  vendorsFabCont: {
    position: 'fixed',
    bottom: '10%',
    right: '10%',
  },
  avatar: {color: 'white', backgroundColor: '#4497A8', marginLeft: '5%'},
  msgIpCont: {
    width: '100%',
    position: 'absolute',
    bottom: 0,
  },
  saveBtn: {backgroundColor: '#3F8F3C', color: 'white'},
  closeBtn: {backgroundColor: '#9C27B0', color: 'white'},
  btnCont: {display: 'flex', justifyContent: 'space-evenly'},
  saveCloseBtnCont: {display: 'flex', flexDirection: 'column'},
  closeBtn: {backgroundColor: '#DD254E', color: 'white'},
  closeCaptureBtnCont: {display: 'flex', justifyContent: 'space-evenly'},
  messagesTabPanel: {
    height: '450px',
    position: 'relative',
    // '& .MuiBox-root': {
    //   padding: '0px',
    // },
  },
  fab: {
    position: 'fixed',
    bottom: '10%',
    right: '15%',
  },
  tabFont: {
    borderLeft: '1px solid gray',
    ['@media (max-width:450px)']: {
      fontSize: '12px',
    },
  },
  menuDropdown: {
    transform: 'translateY(50px) !important',
    ['@media (min-width:960px)']: {
      transform: 'translateX(-150px) translateY(50px) !important',
    },
    ['@media (max-width:500px)']: {
      transform: 'translateY(50px) !important',
    },
  },
}));

export default useStyle;
