import baseUrl from '../utils/Constants';

export default async function (fcmToken) {
  console.log('fcmToken', fcmToken);
  const response = await fetch(`${baseUrl}/auth/logout`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${fcmToken}`,
    },
  });
  const responseJson = await response.json();
  return responseJson;
}
