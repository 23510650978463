/* eslint-disable react/prop-types */
import React from 'react';
import {Box, TextField} from '@material-ui/core';
import {lowerCase} from 'lodash';
import Autocomplete from '@material-ui/lab/Autocomplete';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import PropTypes from 'prop-types';

const SearchVendorAutoComplete = ({
  dropdownOptions,
  placeholder,
  onChange,
  onSelectionChange,
}) => {
  const handleChange = (event) => {
    const {value} = event.target;
    onChange(value);
  };
  const handleSelectionChange = (event, val) => {
    const data = [];
    data.push(val);
    onSelectionChange(data);
  };
  return (
    <Box style={{marginTop: '15px'}}>
      <Autocomplete
        id="free-solo-demo"
        freeSolo
        size="small"
        disableClearable={true}
        options={
          dropdownOptions ??
          dropdownOptions.map((item) => {
            console.log('item', item);
            return item.organizationName
              ? lowerCase(item.organizationName)
              : 'NA';
          })
        }
        getOptionLabel={(searchResults) =>
          searchResults ? lowerCase(searchResults.organizationName) : 'NA'
        }
        onChange={(e, val) => {
          handleSelectionChange(e, val);
        }}
        renderInput={(searchItem) => (
          <TextField
            {...searchItem}
            //   label="Search Items"
            onChange={handleChange}
            placeholder={placeholder}
            variant="outlined"
            InputProps={{
              ...searchItem.InputProps,
              startAdornment: (
                <>
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                  {searchItem.InputProps.startAdornment}
                </>
              ),
            }}
          />
        )}
      />
    </Box>
  );
};

SearchVendorAutoComplete.propTypes = {
  open: PropTypes.bool.isRequired,
  onSimpleDialogClose: PropTypes.func.isRequired,
  message: PropTypes.string.isRequired,
  handleBasketListItemClick: PropTypes.func.isRequired,
};

export default SearchVendorAutoComplete;
