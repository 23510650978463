import {makeStyles} from '@material-ui/core/styles';

const useStyle = makeStyles((theme) => ({
  container: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    paddingBottom: '100px',
    width: '80%',
    backgroundColor: 'white',
    margin: 'auto',
    '& > header > header': {
      position: 'inherit',
      // display: 'flex',
      // justifyContent: 'center',
      // width: '80%',
    },
  },
  ordersText: {
    fontWeight: '900',
    fontSize: '16px',
    ['@media (max-width:450px)']: {
      fontSize: '10px',
    },
  },
  containerOne: {
    padding: '5px',
    display: 'flex',
    flexDirection: 'column',
  },
  customerDetailsContainer: {display: 'flex', justifyContent: 'center'},
  detailsContainer: {display: 'flex', flexDirection: 'column'},
  phoneIcon: {
    fontSize: '20px',
    marginLeft: '5px',
  },
  sideNav: {
    ['@media (max-width:700px)']: {
      display: 'none',
    },
    //height: '100%',
    width: '92px',
    position: 'fixed',
    zIndex: 1,
    top: 0,
    // right: 0,
    right: '15px',
    overflowX: 'hidden',
    marginTop: 190,
    // marginRight: theme.spacing(1),
    marginRight: 0,
    backgroundColor: '#3B3B3B',
    elevation: 2,
  },
}));

export default useStyle;
