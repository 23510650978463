import axios from 'axios';
import baseUrl from '../utils/Constants';

export default async function (token) {
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
  };
  const response = axios
    .get(`${baseUrl}/users/favorite_vendors`, {
      headers,
    })
    .then((response) => {
      console.log('0000', response);
      return response;
    });
  return response;

  // const response = await fetch(`${baseUrl}/users/favorite_vendors`, {
  //   method: 'GET',
  //   headers: {
  //     'Content-Type': 'application/json',
  //     Authorization: `Bearer ${token}`,
  //   },
  // });
  // const responseJson = await response.json();
  // return responseJson;
}
