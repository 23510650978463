import {makeStyles} from '@material-ui/styles';

const useStyle = makeStyles((theme) => ({
  container: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    paddingBottom: '100px',
    width: '80%',
    margin: 'auto',
    '& > header > header': {
      position: 'inherit',
      // display: 'flex',
      // justifyContent: 'center',
      // width: '80%',
    },
    position: 'relative',
    backgroundColor: 'white',
    height: '87vh',
  },
  content: {
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column',
  },
  listContainer: {
    overflow: 'auto',
    height: '74vh',
    padding: '1rem',
    width: '100%',
    marginBottom: '0.5rem',
  },
  button: {
    margin: '1rem 0',
  },
  sideNav: {
    ['@media (max-width:700px)']: {
      display: 'none',
    },
    //height: '100%',
    width: '92px',
    position: 'fixed',
    zIndex: 1,
    top: 0,
    // right: 0,
    right: '15px',
    overflowX: 'hidden',
    marginTop: 190,
    // marginRight: theme.spacing(1),
    marginRight: 0,
    backgroundColor: '#3B3B3B',
    elevation: 2,
  },
  list: {
    flexGrow: 1,
    paddingBottom: 50,
  },
  containerItem: {
    padding: '10px',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: 10,
    paddingVertical: 5,
  },
  containerColumn: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    ['@media (max-width:450px)']: {
      flexDirection: 'column',
      alignItems: 'baseline',
    },
  },
  textAndCheckboxContainer: {
    minWidth: '18rem',
    paddingRight: '2rem',
    paddingLeft: '1rem',
    display: 'flex',
    flexDirection: 'row',
  },
  itemNameContainer: {
    minWidth: '16rem',
    paddingLeft: '1.5rem',
    paddingTop: '1rem',
    paddingBottom: '1rem',
  },
  textItem: {
    fontSize: 14,
    color: 'black',
    flex: 1,
  },
  fab: {
    position: 'inherit',
    width: '7rem',
  },
  btn: {
    margin: 10,
  },
  checkBox: {
    marginRight: 15,
  },
  textInput: {
    marginHorizontal: 2,
    borderColor: 'lightgray',
    borderWidth: 0.5,
    borderRadius: 4,
    fontSize: 15,
    minWidth: 100,
    height: 30,
  },
  activityIndicator: {
    height: '90%',
  },
  divider: {
    width: '100%',
    height: 0.5,
    backgroundColor: 'lightgray',
  },
}));

export default useStyle;
