import React from 'react';
import {Provider} from 'react-redux';
import {BrowserRouter as Router} from 'react-router-dom';
import {store, persister} from './redux/store';
import {PersistGate} from 'redux-persist/integration/react';

import Routes from './routes';
import {ThemeProvider} from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import {theme} from './theme/MuiTheme';
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {I18nextProvider} from 'react-i18next';
import i18n from './translations/i18n';
import GlobalStyles from './theme/GlobalStyles';

function App() {
  return (
    <Provider store={store}>
      <I18nextProvider i18n={i18n}>
        <PersistGate loading={null} persistor={persister}>
          <ToastContainer />
          <CssBaseline />
          <ThemeProvider theme={theme}>
            <GlobalStyles />
            <Router>
              <Routes />
            </Router>
          </ThemeProvider>
        </PersistGate>
      </I18nextProvider>
    </Provider>
  );
}

export default App;
