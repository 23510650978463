import {Box, Grid, Button} from '@material-ui/core';
import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useHistory} from 'react-router';
import ActivityIndicator from '../../components/ActivityIndicator';
import showToast from '../../components/Toast';
import useStyle from './style';
import changePassword from '../../services/changePassword';
import {setToken} from '../../redux/actions/user';
import {useDispatch, useSelector} from 'react-redux';
import WebToolbar from '../../components/toolbar/WebToolbar';
import AppBarName from '../../components/DumbComponents/AppBarName';
import SideNav from '../../components/sidenav/SideNav';
import {Controller, useForm} from 'react-hook-form';
import Joi from 'joi';
import RHFTextInput from '../../components/DumbComponents/RHFTextInput';
import {TextFieldsOutlined} from '@material-ui/icons';
import ErrorBoundary from '../../components/ErrorBoundary';
import ErrorBoundaryFallback from '../../components/ErrorBoundaryFallback';
import {
  changePasswordRules,
  requiredCurrentPasswordRule,
} from '../ResetPasswordScreen/utils';
import MobileFooter from '../MobileFooter';

const ChangePasswordScreen = () => {
  const classes = useStyle();
  const history = useHistory();
  const {register, handleSubmit, control} = useForm({
    defaultValues: {
      currentpassword: '',
      newpassword: '',
      confirmpassword: '',
    },
  });

  const dispatch = useDispatch();
  const {t} = useTranslation(['changePassword']);
  const token = useSelector((state) => state.user.token);
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [loading, setLoading] = useState(false);

  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errorCPText, setErrorCPText] = useState('');
  const [errorNPText, setErrorNPText] = useState('');
  const [errorCoPText, setErrorCoPText] = useState('');

  const handleClickShowPassword = () =>
    setShowCurrentPassword(!showCurrentPassword);

  const handleClickShowNewPassword = () => setShowNewPassword(!showNewPassword);
  const handleClickShowConfirmPassword = () =>
    setShowConfirmPassword(!showConfirmPassword);

  async function reset(data) {
    console.log('data', data);
    // if (!currentPassword.trim()) {
    //   setErrorCPText('Please enter current password');
    //   showToast('error', 'Please enter current password');
    //   return;
    // }
    // if (!newPassword.trim()) {
    //   setErrorNPText('Please enter new password');
    //   showToast('error', 'Please enter new password');
    //   return;
    // }
    // if (!confirmPassword.trim()) {
    //   setErrorCoPText('Please enter confirm password');
    //   showToast('error', 'Please enter confirm password');
    //   return;
    // }
    // setErrorCPText();
    // setErrorNPText();
    // setErrorCoPText();
    if (data.currentpassword.trim().length < 6) {
      showToast(
        'error',
        'Invalid password, current password must be at least 6 characters long'
      );
      return;
    }
    if (data.newpassword.length < 6) {
      showToast(
        'error',
        'Invalid password, new password must be at least 6 characters long'
      );
      return;
    }
    if (data.newpassword !== data.confirmpassword) {
      showToast('error', 'Passwords did not matched');
      return;
    }
    if (data.currentpassword === data.confirmpassword) {
      showToast('error', 'Current password and new password must not be same.');
      return;
    }
    // Keyboard.dismiss();
    setLoading(true);
    try {
      const payload = {
        CurrentPassword: data.currentpassword,
        NewPassword: data.newpassword,
        ConfirmPassword: data.newpassword,
      };
      const response = await changePassword(payload, token);
      console.log('changePassword response', response);
      if (response.successCode === 1) {
        showToast('success', 'Password changed successfully.');
        dispatch(setToken(response.response.token));
        history.push('/home');
      } else {
        const message =
          t(response.messageCode) || response.returnMessage || 'Failed';
        showToast('error', message);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }

  return (
    <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
      <Box className={classes.container}>
        <WebToolbar />
        <div className={classes.sideNav}>
          <SideNav />
        </div>
        <AppBarName title={'Change Password'} />
        <Box p={1}>
          <form className={classes.root} onSubmit={handleSubmit(reset)}>
            <Grid container direction={'column'} spacing={1}>
              <Grid item>
                <RHFTextInput
                  name="currentpassword"
                  label={t('currentPassword')}
                  control={control}
                  type={showCurrentPassword ? 'text' : 'password'}
                  rules={requiredCurrentPasswordRule}
                  showPassword={showCurrentPassword}
                  showPasswordIcon={true}
                  handleClickShowPassword={handleClickShowPassword}
                />
              </Grid>
              <Grid item>
                <RHFTextInput
                  name="newpassword"
                  label={t('newPassword')}
                  control={control}
                  type={showNewPassword ? 'text' : 'password'}
                  rules={changePasswordRules}
                  showPassword={showNewPassword}
                  showPasswordIcon={true}
                  handleClickShowPassword={handleClickShowNewPassword}
                />
              </Grid>
              <Grid item>
                <RHFTextInput
                  name="confirmpassword"
                  label={t('confirmPassword')}
                  control={control}
                  type={showConfirmPassword ? 'text' : 'password'}
                  rules={changePasswordRules}
                  showPassword={showConfirmPassword}
                  showPasswordIcon={true}
                  handleClickShowPassword={handleClickShowConfirmPassword}
                />
              </Grid>
            </Grid>
            <Box p={2} className={classes.btnContainer}>
              {loading ? (
                <ActivityIndicator color="white" size="small" />
              ) : (
                <Button
                  variant="outlined"
                  style={{width: '12rem', borderRadius: '20px'}}
                  type="submit"
                  // onClick={() => reset()}
                >
                  {t('changePassword')}
                </Button>
              )}
            </Box>
          </form>
        </Box>
        {/* <Box py={1}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container direction={'column'} spacing={1}>
            <Grid item>
              <TextField
                className={classes.textInput}
                fullWidth
                // placeholder={t('currentPassword')}
                id="outlined-basic1"
                label={t('currentPassword')}
                value={currentPassword}
                type={showCurrentPassword ? 'text' : 'password'}
                size="small"
                variant="outlined"
                inputProps={{maxLength: 32}}
                onChange={(txt) => {
                  setCurrentPassword(txt.target.value);
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                      >
                        {showCurrentPassword ? (
                          <VisibilityIcon />
                        ) : (
                          <VisibilityOffIcon />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item>
              <TextField
                className={classes.textInput}
                fullWidth
                // placeholder={t('newPassword')}
                id="outlined-basic2"
                label={t('newPassword')}
                value={newPassword}
                type={showNewPassword ? 'text' : 'password'}
                size="small"
                inputProps={{maxLength: 32}}
                variant="outlined"
                helperText={errorNPText}
                error={errorNPText}
                onChange={(txt) => {
                  setNewPassword(txt.target.value);
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowNewPassword}
                      >
                        {showNewPassword ? (
                          <VisibilityIcon />
                        ) : (
                          <VisibilityOffIcon />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item>
              <TextField
                className={classes.textInput}
                fullWidth
                // placeholder={t('confirmPassword')}
                id="outlined-basic3"
                label={t('confirmPassword')}
                value={confirmPassword}
                type={showConfirmPassword ? 'text' : 'password'}
                size="small"
                variant="outlined"
                inputProps={{maxLength: 32}}
                helperText={errorCoPText}
                error={errorCoPText}
                onChange={(txt) => {
                  setConfirmPassword(txt.target.value);
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowConfirmPassword}
                      >
                        {showConfirmPassword ? (
                          <VisibilityIcon />
                        ) : (
                          <VisibilityOffIcon />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
        </form>
        <Box p={2} className={classes.btnContainer}>
          {loading ? (
            <ActivityIndicator color="white" size="small" />
          ) : (
            <Button
              variant="contained"
              style={{width: '30%', borderRadius: '20px'}}
              onClick={() => reset()}
            >
              {t('changePassword')}
            </Button>
          )}
        </Box>
      </Box> */}
      </Box>
      <MobileFooter />
    </ErrorBoundary>
  );
};

export default ChangePasswordScreen;
