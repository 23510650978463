import React, {useState} from 'react';
import MaterialDrawer from '@material-ui/core/Drawer';
import {makeStyles} from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import Container from '@material-ui/core/Container';
import PropTypes from 'prop-types';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Icon from '@material-ui/core/Icon';
import items from './Items';
import {useDispatch, useSelector} from 'react-redux';
import showToast from '../../components/Toast';
import {useHistory} from 'react-router-dom';
import useStyles from './style';
import {Box, Dialog, Divider, List, ListItem, Button} from '@material-ui/core';
import getCurrentVendor from '../../services/getCurrentVendor';
import ShareDialog from '../../components/DumbComponents/ShareDialog';
import SimpleDialog from '../DumbComponents/SimpleDialog';
import logoutUser from '../../services/logout';
import ImagePreview from '../../components/DumbComponents/ImagePreview';
import ImageDialog from '../../components/DumbComponents/ImageDialog';
import {
  setUser,
  setLoggedIn,
  setToken,
  setProfilePic,
  setUnreadNotifications,
  setVendorId,
} from '../../redux/actions/user';
import updateProfilePic from '../../services/updateProfilePic';
import Analytics, {EVENTS} from '../../analytics';
import {s3BaseUrl} from '../../utils/Constants';

const emails = [
  'Edit Name & Address',
  'Edit Timing',
  'Edit Photos',
  'Edit Delivery Option',
];

function _SimpleDialog(props) {
  const history = useHistory();
  const classes = useStyles();
  const {onClose, selectedValue, open} = props;

  const handleClose = () => {
    onClose(selectedValue);
  };

  const handleListItemClick = (option) => {
    console.log('option', option, typeof option);
    if (option === 'Edit Name & Address') {
      console.log('idd');
      history.push('/editvendordetails');
    } else if (option === 'Edit Timing') {
      console.log('iddddf');
      history.push('edithoursscreen');
    } else if (option === 'Edit Photos') {
      history.push('/editvendorimages');
    } else if (option === 'Edit Delivery Option') {
      history.push('/editdeliveryoptionsscreen');
    }
    // onClose(option);
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <List>
        {emails.map((email) => (
          <>
            <ListItem
              button
              variant="outlined"
              onClick={() => handleListItemClick(email)}
              key={email}
            >
              <ListItemText primary={email} />
            </ListItem>
            <Divider />
          </>
        ))}
      </List>
    </Dialog>
  );
}

_SimpleDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired,
};

function Drawer({open, handleDrawerClose, onMenuSelected}) {
  const classes = useStyles();
  const history = useHistory();
  const [_open, _setOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState(emails[1]);
  const [userDetails, setUserDetails] = useState('');
  const [openShare, setOpenShare] = useState(false);
  const [openLogoutDialog, setOpenLogoutDialog] = useState(false);
  const dispatch = useDispatch();
  const [imageViewer, setImageViewer] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [cameraDialog, setCameraDialog] = useState(false);
  const [selectedImage, setSelectedImage] = useState([]);

  const isLoggedIn = useSelector((state) => state.user.isLoggedIn);
  const user = useSelector((state) => state.user.user);
  const token = useSelector((state) => state.user.token);

  const closeDialogFunction = () => {
    setOpenDialog(false);
    //Camera
    // setShowCamera(!showCamera);
  };

  const handleListItemClick = (val) => {
    //Gallary
    // history.push('addvendorshopdeteils');
    console.log('val', val); //got image from gallary
    // setHeaderImage({
    //   photoURL: URL.createObjectURL(val.target.files[0]),
    // });
    uploadDp(val);
    setOpenDialog(false);
    setCameraDialog(false);
  };

  const closeImagePreviewDialogFunction = () => {
    setImageViewer(false);
  };

  const handleClickOpen = () => {
    console.log('in handle cklick op[en');
    // _setOpen(true);
    // history.push('/editdetailshome');
  };

  const handleClose = (value) => {
    _setOpen(false);
    history.push('/home');
    setSelectedValue(value);
  };

  const handleShareVendor = async () => {
    try {
      const response = await getCurrentVendor(token);
      console.log('res', response);
      if (response.successCode === 1) {
        setUserDetails(response.response);
        setOpenShare(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const closeShareDialogFunction = () => {
    setOpenShare(false);
  };

  const closeLogoutDialogFunction = (value) => {
    setOpenLogoutDialog(false);
  };
  const handleLogoutClick = async () => {
    console.log('logout from drawer');
    try {
      const response = await logoutUser(token);
      console.log('logout response', response);
      // logoutUser(token);
      dispatch(setUser({}));
      dispatch(setToken(''));
      dispatch(setProfilePic(''));
      dispatch(setLoggedIn(false));
      dispatch(setUnreadNotifications(0));
      dispatch(setVendorId(0));
      history.push('/login');
      // props.navigation.replace('Splash');
      // Analytics.reportEvent(EVENTS.LOGOUT, {
      //   username: user.username,
      // });
    } catch (error) {
      dispatch(setUser({}));
      dispatch(setToken(''));
      dispatch(setProfilePic(''));
      dispatch(setLoggedIn(false));
      dispatch(setUnreadNotifications(0));
      dispatch(setVendorId(0));
      history.push('/login');
      // props.navigation.replace('Splash');
      // console.log(error);
      // Analytics.reportEvent(EVENTS.LOGOUT, {
      //   username: user.username,
      // });
    }
  };

  function onPressDp() {
    console.log('user.profilePicture', user.username);
    if (user.profilePicture) {
      // setShowDpModal(true);
      setImageViewer(true);
    } else {
      console.log('In setImageModal');
      setOpenDialog(!openDialog);
      // setImageModal(true);
    }
  }
  async function uploadDp(image) {
    try {
      const payloadImage = new FormData();
      payloadImage.append('formFile', image);
      const responsePic = await updateProfilePic(payloadImage, token);
      if (responsePic.successCode === 1) {
        dispatch(setProfilePic(responsePic.response.profilePictureIdentifier));
        showToast('success', 'Updated');
      } else {
        showToast('error', 'Failed');
      }
    } catch (error) {
      console.log(error);
    }
  }

  const getProfileImage = () => {
    if (user.profilePicture) {
      // TODO: convert to base64 and assign to Avatar
      return <Avatar className={classes.avatar} src={user.profilePicture} />;
    } else {
      return (
        <Avatar className={classes.avatar}>
          {user.username ? user.username.charAt(0) : 'P'}
        </Avatar>
      );
    }
  };

  return (
    <MaterialDrawer
      anchor="left"
      open={open}
      onClose={handleDrawerClose}
      className={classes.drawer}
    >
      <Container component="div" fixed className={classes.container}>
        {isLoggedIn ? (
          <Container className={classes.header}>
            <Box
              style={{cursor: 'pointer'}}
              onClick={() => {
                onPressDp();
              }}
            >
              {getProfileImage()}
            </Box>
            <Box>
              <ImageDialog
                open={openDialog}
                setOpen={setOpenDialog}
                message="Pick Image From?"
                onSimpleDialogClose={closeDialogFunction}
                handleListItemClick={handleListItemClick}
                cameraDialog={cameraDialog}
                setCameraDialog={setCameraDialog}
                closeShowCameraDialogFunction={handleListItemClick}
              />
            </Box>

            <Container className={classes.info}>
              <Typography className={classes.textName}>
                {user.username}
              </Typography>
              <Typography
                className={classes.textEmail}
              >{`+91-${user.mobile}`}</Typography>
              {user.email ? (
                <Typography className={classes.textEmail}>
                  {user.email}
                </Typography>
              ) : (
                ''
              )}
            </Container>
            <ImagePreview
              open={imageViewer}
              onSimpleDialogClose={closeImagePreviewDialogFunction}
              image={user.profilePicture}
            />
          </Container>
        ) : (
          <Container className={classes.header}>
            <Button
              variant="outlined"
              type="submit"
              className={classes.button}
              onClick={() => history.push('/login')}
            >
              <Typography>Login</Typography>
            </Button>
          </Container>
        )}

        <MenuItem
          onClick={
            () => onMenuSelected('home')
            // onMenuSelected(item)
          }
          className={classes.menuItem}
        >
          <ListItemIcon className={classes.textSizeIcon}>
            <Icon>{'home'}</Icon>
          </ListItemIcon>
          <ListItemText className={classes.textSize} primary={'Home'} />
        </MenuItem>

        {user.isVendor ? (
          <MenuItem
            className={classes.menuItem}
            onClick={() => onMenuSelected('Build Catalouge')}
          >
            <ListItemIcon className={classes.textSizeIcon}>
              <Icon>{'add'}</Icon>
            </ListItemIcon>
            <ListItemText
              className={classes.textSize}
              primary={'Build Catalouge'}
            />
          </MenuItem>
        ) : (
          <MenuItem
            className={classes.menuItem}
            onClick={() => onMenuSelected('Become Vendor')}
          >
            <ListItemIcon className={classes.textSizeIcon}>
              <Icon>{'person_add_icon'}</Icon>
            </ListItemIcon>
            <ListItemText
              className={classes.textSize}
              primary={'Register as Vendor'}
            />
          </MenuItem>
        )}
        {user.isVendor && (
          <MenuItem
            className={classes.menuItem}
            onClick={() => onMenuSelected('Advertisement')}
          >
            <ListItemIcon className={classes.textSizeIcon}>
              <Icon>{'announcement'}</Icon>
            </ListItemIcon>
            <ListItemText
              className={classes.textSize}
              primary={'Advertisement'}
            />
          </MenuItem>
        )}

        <MenuItem
          className={classes.menuItem}
          onClick={() => onMenuSelected('Update Profile')}
        >
          <ListItemIcon className={classes.textSizeIcon}>
            <Icon>{'person'}</Icon>
          </ListItemIcon>
          <ListItemText
            className={classes.textSize}
            primary={'Update Profile'}
          />
        </MenuItem>

        <Box>
          <_SimpleDialog
            selectedValue={selectedValue}
            open={_open}
            onClose={handleClose}
          />
        </Box>
        <SimpleDialog
          open={openLogoutDialog}
          onSimpleDialogClose={closeLogoutDialogFunction}
          message="Do you want to Logout?"
          handleBasketListItemClick={handleLogoutClick}
        />
        {user.isVendor && (
          <MenuItem
            className={classes.menuItem}
            onClick={() => {
              onMenuSelected('Edit Vendor Details');
              // handleClickOpen();
            }}
          >
            <ListItemIcon className={classes.textSizeIcon}>
              <Icon>{'edit'}</Icon>
            </ListItemIcon>
            <ListItemText
              className={classes.textSize}
              primary={'Edit Vendor Details'}
            />
          </MenuItem>
        )}

        <MenuItem
          className={classes.menuItem}
          onClick={() => onMenuSelected('Favourite Vendors')}
        >
          <ListItemIcon className={classes.textSizeIcon}>
            <Icon>{'star'}</Icon>
          </ListItemIcon>
          <ListItemText
            className={classes.textSize}
            primary={'Favourite Vendors'}
          />
        </MenuItem>

        <MenuItem
          className={classes.menuItem}
          onClick={() => onMenuSelected('Change Password')}
        >
          <ListItemIcon className={classes.textSizeIcon}>
            <Icon>{'key'}</Icon>
          </ListItemIcon>
          <ListItemText
            className={classes.textSize}
            primary={'Change Password'}
          />
        </MenuItem>

        <MenuItem
          className={classes.menuItem}
          onClick={() => onMenuSelected('Reset Password')}
        >
          <ListItemIcon className={classes.textSizeIcon}>
            <Icon>{'lock'}</Icon>
          </ListItemIcon>
          <ListItemText
            className={classes.textSize}
            primary={'Reset Password'}
          />
        </MenuItem>

        {user.isVendor && (
          <MenuItem
            className={classes.menuItem}
            onClick={(item) => {
              // onMenuSelected(item);
              handleShareVendor();
            }}
          >
            <ListItemIcon className={classes.textSizeIcon}>
              <Icon>{'share'}</Icon>
            </ListItemIcon>
            <ListItemText
              className={classes.textSize}
              primary={'Share Vendor'}
            />
          </MenuItem>
        )}
        <ShareDialog
          open={openShare}
          onSimpleDialogClose={closeShareDialogFunction}
          data={userDetails}
          message={`Hey! I am using GearConnect, an app to facilitate direct
          communication between Buyers and Sellers. Check this out
          https://www.gearten.in/vendors?vendorId=${userDetails.vendorId}`}
        />

        <MenuItem
          className={classes.menuItem}
          onClick={() => onMenuSelected('Contact Us')}
        >
          <ListItemIcon className={classes.textSizeIcon}>
            <Icon>{'phone'}</Icon>
          </ListItemIcon>
          <ListItemText className={classes.textSize} primary={'Contact Us'} />
        </MenuItem>

        <MenuItem
          className={classes.menuItem}
          onClick={() => onMenuSelected('Feedback')}
        >
          <ListItemIcon className={classes.textSizeIcon}>
            <Icon>{'star-outline'}</Icon>
          </ListItemIcon>
          <ListItemText className={classes.textSize} primary={'Feedback'} />
        </MenuItem>

        <MenuItem
          className={classes.menuItem}
          onClick={() => onMenuSelected('FAQ')}
        >
          <ListItemIcon className={classes.textSizeIcon}>
            <Icon>{'help'}</Icon>
          </ListItemIcon>
          <ListItemText className={classes.textSize} primary={'FAQ'} />
        </MenuItem>

        <MenuItem
          className={classes.menuItem}
          onClick={() => onMenuSelected('Terms of Use')}
        >
          <ListItemIcon className={classes.textSizeIcon}>
            <Icon>{'forum'}</Icon>
          </ListItemIcon>
          <ListItemText className={classes.textSize} primary={'Terms of Use'} />
        </MenuItem>

        <MenuItem
          className={classes.menuItem}
          onClick={() => onMenuSelected('Privacy Policy')}
        >
          <ListItemIcon className={classes.textSizeIcon}>
            <Icon>{'block'}</Icon>
          </ListItemIcon>
          <ListItemText
            className={classes.textSize}
            primary={'Privacy Policy'}
          />
        </MenuItem>

        <MenuItem
          className={classes.menuItem}
          onClick={() => onMenuSelected('About')}
        >
          <ListItemIcon className={classes.textSizeIcon}>
            <Icon>{'information'}</Icon>
          </ListItemIcon>
          <ListItemText className={classes.textSize} primary={'About'} />
        </MenuItem>

        <MenuItem
          className={classes.menuItem}
          onClick={(item) => {
            setOpenLogoutDialog(true);
            // onMenuSelected(item);
          }}
        >
          <ListItemIcon className={classes.textSizeIcon}>
            <Icon>{'logout'}</Icon>
          </ListItemIcon>
          <ListItemText className={classes.textSize} primary={'Logout'} />
        </MenuItem>

        {/* {items.map((item, index) => {
          const show = item.login ? isLoggedIn : true;
          return show ? (
            <div key={index} className={classes.menuItem}>
              <MenuItem className={classes.menuItem} onClick={() => onMenuSelected(item)}>
                <ListItemIcon className={classes.textSizeIcon}>
                  <Icon>{item.icon}</Icon>
                </ListItemIcon>
                <ListItemText className={classes.textSize} primary={item.name} />
              </MenuItem>
            
          ) : (
            <div />
          );
        })} */}
      </Container>
    </MaterialDrawer>
  );
}

Drawer.propTypes = {
  open: PropTypes.bool,
  handleDrawerClose: PropTypes.func,
  onMenuSelected: PropTypes.func,
};

export default Drawer;
