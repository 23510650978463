import baseUrl from '../utils/Constants';

export default async function (token, lastOrderId) {
  let apiUrl = `${baseUrl}/vendors/orders/recent?numberOfOrders=10`;
  if (lastOrderId) {
    apiUrl = `${baseUrl}/vendors/orders/recent?numberOfOrders=10&lastOrderId=${lastOrderId}`;
  }
  try {
    const response = await fetch(apiUrl, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });

    const responseJson = await response.json();
    return responseJson;
  } catch (error) {
    console.log(error);
  }
}
