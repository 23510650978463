import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import PropTypes from 'prop-types';

function ActivityIndicator({type, style}) {
  return (
    <div
      style={{
        marginTop: 65,
        justifyContent: 'center',
        alignItems: 'center',
        height: '70vh',
        display: 'flex',
        ...style,
      }}
    >
      <CircularProgress
        style={{alignSelf: 'center'}}
        color="secondary"
        size={40}
        thickness={4.5}
      />
    </div>
  );
}

ActivityIndicator.propTypes = {
  type: PropTypes.string,
  style: PropTypes.object,
};

export default ActivityIndicator;
