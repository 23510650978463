import React, {useCallback, useRef, useState} from 'react';
import {Helmet} from 'react-helmet';
import Visibility from '@material-ui/icons/Visibility';
import Lock from '@material-ui/icons/Lock';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import {useHistory} from 'react-router-dom';
import ageGroups from '../../utils/ageGrooups';
import {setUser} from '../../redux/actions/user';
import {
  Typography,
  Box,
  Button,
  IconButton,
  Select,
  Grid,
  Chip,
  TextField,
  Avatar,
  Card,
  CardHeader,
  CardContent,
  FormControlLabel,
  Radio,
  RadioGroup,
  FormControl,
  Checkbox,
  Link,
  Dialog,
  List,
  ListItem,
  ListItemText,
  makeStyles,
} from '@material-ui/core';
import PersonOutlinedIcon from '@material-ui/icons/PersonOutlined';
import AddIcon from '@material-ui/icons/Add';
import useStyle from './styles';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import PermIdentityIcon from '@material-ui/icons/PermIdentity';
import {withStyles} from '@material-ui/core/styles';
import showToast from '../../components/Toast';
import pinCodeService from '../../services/pincode';
import signUpService from '../../services/signUp';
import DoneIcon from '@material-ui/icons/Done';
import AppBarName from '../../components/DumbComponents/AppBarName';
import {Controller, useForm} from 'react-hook-form';
import RHFTextInput from '../../components/DumbComponents/RHFTextInput';
import {
  nameRules,
  emailRules,
  passwodRules,
  pincodeRules,
  phoneRules,
} from './utils';
import PropTypes from 'prop-types';
import {blue} from '@material-ui/core/colors';
import Webcam from 'react-webcam';
import ImageDialog from '../../components/DumbComponents/ImageDialog';
import ErrorBoundary from '../../components/ErrorBoundary';
import ErrorBoundaryFallback from '../../components/ErrorBoundaryFallback';
import {get, isObject} from 'lodash';

export default function SignUp(props) {
  const classes = useStyle();
  const history = useHistory();

  const {t} = useTranslation('signUp');
  const dispatch = useDispatch();
  const [fName, setFname] = useState('');
  const [lName, setLname] = useState('');
  const [email, setEmail] = useState('');
  const [state, setState] = useState('');
  const [city, setCity] = useState('');
  const [phone, setPhone] = useState('');
  const [pinCode, setPincode] = useState('');
  const [address1, setAddress1] = useState('');
  const [address2, setAddress2] = useState('');
  const [password, setPassword] = useState('');
  const [locality, setLocality] = useState('');
  const [localities, setLocalities] = useState([]);
  const [subLocality, setSubLocality] = useState('');
  const [subLocalities, setSubLocalities] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [imageModal, setImageModal] = useState(false);
  const [terms, setTerms] = useState(false);
  const [profilePic, setProfilePic] = useState('');
  const [gender, setGender] = useState('male');
  const [yearOfBirth, setYearOfBirth] = useState(0);
  const [confirmPassword, setConfirmPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [pincodeObj, setPincodeObj] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassrod, setShowConfirmPassrod] = useState(false);

  const [selectedValue, setSelectedValue] = useState();
  const [showCamera, setShowCamera] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedImage, setSelectedImage] = useState([]);
  const webcamRef = useRef(false);
  const videoConstraints = {
    width: 100,
    height: 100,
    facingMode: 'user',
  };

  const {handleSubmit, control, watch, setError} = useForm({
    defaultValues: {
      fName: '',
      phone: '',
      email: '',
      pinCode: '',
      password: '',
      confirmPassword: '',
    },
  });

  const _password = watch('password');
  const _confirmPassword = watch('confirmPassword');

  async function onPressSignUp(data) {
    setLoading(true);
    if (_password !== _confirmPassword) {
      console.log('password not match');
      setError(
        'confirmPassword',
        'notMatch',
        'please choose a different username'
      );
      setLoading(false);
      return;
    }
    // if (!profilePic) {
    //   showToast('error',t('selectDp'));
    //   return;
    // }
    // const age = new Date().getFullYear() - Number(yearOfBirth);
    // if (age < 18) {
    //   showToast(t('vaildYob'));
    //   return;
    // }
    if (!terms) {
      showToast('error', t('agreeTerms'));
      setLoading(false);
      return;
    }
    var base64 = '';
    if (profilePic) {
      // base64 = await RNFS.readFile(profilePic.path, 'base64');
    }
    const selectedLocaliity = localities.find((loc) => loc.name === locality);
    const selectedSubLocality = selectedLocaliity.subLocalities.find(
      (loc) => loc.name === subLocality
    );
    // console.log(selectedLocaliity.id);
    // console.log(selectedSubLocality.id);
    const payload = {
      Name: `${data.fName}`,
      Email: data.email,
      Mobile: `${data.phone}`,
      AddressLine1: address1,
      AddressLine2: address2,
      SubLocalityID: selectedSubLocality.id,
      SubLocalityName: pincodeObj.area.localities[0].subLocalities[0].name,
      LocalityID: selectedLocaliity.id,
      LocalityName: pincodeObj.area.localities[0].name,
      PinCodeId: Number(pinCode),
      CityID: pincodeObj.area.city.id,
      stateId: pincodeObj.area.state.id,
      CountryID: pincodeObj.area.country.id,
      ReferredBy: 1,
      Password: data.password,
      ConfirmPassword: data.password,
      ProfilePicture: base64,
      //YearOfBirth: Number(yearOfBirth),
      AgeGroup: yearOfBirth,
      Sex: gender === 'male' ? 0 : 1,
    };

    try {
      const response = await signUpService(
        payload,
        // Number(DeviceInfo.getBuildNumber())
        25
      );
      console.log(response);
      if (response.successCode === 1) {
        const user = {
          username: `${fName}`,
          email: email,
          mobile: phone,
          pincode: pinCode,
          localityId: pincodeObj.area.localities[0].id,
          sublicalityId: pincodeObj.area.localities[0].subLocalities[0].id,
          isVendor: false,
        };
        dispatch(setUser(user));
        history.push({
          pathname: '/OTP',
          state: {userName: response.response.userName},
        });
        setLoading(false);
        showToast('success', t(response.messageCode));
      } else if (response.messageCode === '400702492') {
        history.goBack();
        // navigation.replace('ForceUpdate');
        setLoading(false);
      } else {
        setLoading(false);
        showToast('error', t(response.messageCode));
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
      showToast('error', 'Failed, please try again after some time');
    }
  }

  async function onPincodeChange(text) {
    console.log('txt', text);
    setPincode(text);
    setPincodeObj('');
    if (text.length === 6) {
      setModalVisible(true);
      try {
        const response = await pinCodeService(text);
        console.log('pincode response', response);
        setModalVisible(false);
        if (response.successCode === 1) {
          const data = response.response;
          // showToast(t(response.messageCode), 'success');
          setPincodeObj(data);
          setLocalities(get(data, 'area.localities', ''));
          setLocality(get(data, 'area.localities.0.name', ''));
          setCity(get(data, 'area.city.name', ''));
          setState(get(data, 'area.state.name', ''));
          setSubLocality(
            get(data, 'area.localities.0.subLocalities.0.name', '')
          );
          setSubLocalities(get(data, 'area.localities.0.subLocalities', ''));
        } else {
          showToast('error', t(response.messageCode));
          setPincodeObj('');
          setLocality('');
          setSubLocality('');
        }
      } catch (error) {
        setModalVisible(false);
        showToast('error', 'Failed to get pincode details');
        console.log(error);
      }
    }
  }

  const openProfilePicModal = () => {
    setOpenDialog(true);
  };

  function addDp(type) {
    console.log('type in add dp', type);
    if (type === 'Capture Image') {
      setShowCamera(!showCamera);
    } else if (type === 'Select from Library') {
    }
  }

  const capture = useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    console.log('imageSrc', imageSrc);
    setProfilePic(imageSrc);
  }, [webcamRef]);

  const handleClickShowConfirmPassword = () =>
    setShowConfirmPassrod(!showConfirmPassrod);
  const handleClickShowPassword = () => setShowPassword(!showPassword);

  const closeDialogFunction = (value) => {
    console.log('val', value);
    addDp(value);
    setOpenDialog(false);
    //Camera
    // setShowCamera(!showCamera);
  };

  const handleListItemClick = (val) => {
    //Gallary
    // history.push('addvendorshopdeteils');
    console.log('val', val); //got image from gallary
    if (get(val, 'name', false)) {
      setProfilePic(val);
    } else {
      setProfilePic(false);
    }
    // setDp({ photo: val, photoUrl: URL.createObjectURL(val) })

    // setHeaderImage({
    //   photoURL: URL.createObjectURL(val.target.files[0]),
    // });
    setOpenDialog(false);
    setShowCamera(false);
  };

  return (
    <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
      <Box className={classes.container}>
        <Box>
          <Helmet>
            <title>Sign Up - GearConnect</title>
            <meta name="title" content="GearConnect create new account" />
          </Helmet>
          <AppBarName title={'Sign Up'} />
        </Box>
        <Box style={{display: 'flex', justifyContent: 'center'}} py={2}>
          {get(profilePic, 'type', false) ? (
            <Box>
              <img
                src={URL.createObjectURL(profilePic)}
                width="100"
                height="100"
              />
            </Box>
          ) : (
            <Box onClick={openProfilePicModal}>
              <label htmlFor="icon-button-file">
                <IconButton
                  style={{
                    height: '100px',
                    width: '100px',
                  }}
                  color="primary"
                  aria-label="upload picture"
                  component="span"
                >
                  <Avatar
                    // src="https://www.w3schools.com/howto/img_avatar.png"
                    style={{
                      height: '100px',
                      width: '100px',
                    }}
                  >
                    <PermIdentityIcon
                      style={{
                        height: '60px',
                        width: '60px',
                        color: 'black',
                      }}
                    />
                  </Avatar>
                </IconButton>
              </label>
            </Box>
          )}
          <Box>
            <ImageDialog
              open={openDialog}
              setOpen={setOpenDialog}
              cameraDialog={showCamera}
              setCameraDialog={setShowCamera}
              onSimpleDialogClose={closeDialogFunction}
              message="Pick Image From?"
              handleListItemClick={handleListItemClick}
              closeShowCameraDialogFunction={handleListItemClick}
            />
          </Box>
          {/* <SimpleDialog
          selectedValue={selectedValue}
          open={modalVisible}
          onClose={closeProfilePicModal}
          selectedImage={selectedIamge}
        /> */}
          {/* {showCamera ? (
            <Box style={{display: 'flex', flexDirection: 'column'}}>
              <Webcam
                audio={false}
                height={200}
                ref={webcamRef}
                screenshotFormat="image/jpeg"
                width={220}
                videoConstraints={videoConstraints}
              />
              <Box
                py={1}
                style={{display: 'flex', justifyContent: 'space-evenly'}}
              >
                <Button variant="outlined" onClick={capture}>
                  Capture
                </Button>
                <Button
                  variant="outlined"
                  onClick={() => {
                    setShowCamera(!showCamera);
                  }}
                >
                  Cancel
                </Button>
              </Box>
            </Box>
          ) : (
            ''
          )} */}
          {/* <Box
          // onPress={() => setImageModal(true)}
          className={classes.viewImg}
        >
          {profilePic ? (
            <img
              className={classes.image}
              src={{
                uri: profilePic.path,
              }}
            />
          ) : (
            <Box className={classes._root}>
              <Badge
                // overlap="circular"
                badgeContent={
                  <AddIcon
                    style={{
                      border: 'solid',
                      borderRadius: '20px',
                      backgroundColor: '#4497A8',
                      color: 'white',
                    }}
                  />
                }
                // color="primary"
                style={{
                  backgroundColor: 'gray',
                  borderRadius: '25px',
                }}
                // anchorOrigin={{
                //   vertical: 'top',
                //   horizontal: 'right',
                // }}
              >
                <PersonOutlinedIcon style={{fontSize: 40}} />
              </Badge>
            </Box>
          )}
        </Box> */}
        </Box>
        <Box m={2}>
          <form className={classes.root} onSubmit={handleSubmit(onPressSignUp)}>
            <Grid
              container
              direction={'column'}
              spacing={1}
              style={{display: 'contents'}}
            >
              <Grid item>
                <RHFTextInput
                  name="fName"
                  label="Full Name"
                  control={control}
                  type="text"
                  rules={nameRules}
                  showPasswordIcon={false}
                  maxLength={50}
                />
                {/* <TextField
                className={classes.textInput}
                fullWidth
                id="outlined-basic"
                label="Full Name"
                value={fName}
                size="small"
                variant="outlined"
                onChange={(txt) => {
                  setFname(txt.target.value);
                }}
              /> */}
              </Grid>
              <Grid item>
                <RHFTextInput
                  name="phone"
                  label="Mobile Number"
                  control={control}
                  type="text"
                  rules={phoneRules}
                  showPasswordIcon={false}
                  maxLength={15}
                />
                {/* <TextField
                className={classes.textInput}
                fullWidth
                id="outlined-basic"
                label="Mobile Number"
                value={phone}
                size="small"
                variant="outlined"
                onChange={(txt) => {
                  setPhone(txt.target.value);
                }}
              /> */}
              </Grid>
              <Grid item>
                <RHFTextInput
                  name="email"
                  label="Email(Optional)"
                  control={control}
                  type="text"
                  rules={emailRules}
                  showPasswordIcon={false}
                />
                {/* <TextField
                className={classes.textInput}
                fullWidth
                id="outlined-basic"
                label="Email(Optional)"
                value={email}
                size="small"
                variant="outlined"
                onChange={(txt) => {
                  setEmail(txt.target.value);
                }}
              /> */}
              </Grid>
              <Grid item className={classes.containerPicker}>
                <Box boxShadow={1} style={{padding: '5px'}}>
                  <Typography variant="h6">Age Group(years)</Typography>
                  <Typography variant="subtitle1">
                    Person less than 18 years not allowed
                  </Typography>
                  <Box className={classes.ageGroupContainer}>
                    {ageGroups.map((value, index) => {
                      return (
                        <Chip
                          className={classes.ageGroupChip}
                          // className={
                          //   yearOfBirth === value.id
                          //     ? classes.chipOne
                          //     : classes.chipTwo
                          // }
                          color={
                            yearOfBirth === value.id ? 'primary' : 'default'
                          }
                          variant={
                            yearOfBirth === value.id ? 'default' : 'outlined'
                          }
                          deleteIcon={<DoneIcon />}
                          onDelete={yearOfBirth === value.id && (() => {})}
                          // chipSelected={yearOfBirth === value.id}
                          key={index}
                          clickable
                          label={value.group}
                          onClick={() => {
                            setYearOfBirth(value.id);
                          }}

                          // onClick={(e) => handleClick(e, index)}
                        />
                      );
                    })}
                  </Box>
                </Box>
              </Grid>
              <Grid item>
                <FormControl
                  component="fieldset"
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                >
                  {/* <FormLabel component="legend">Gender</FormLabel> */}
                  <Grid
                    container
                    style={{display: 'flex', alignItems: 'center'}}
                  >
                    <Grid item style={{marginRight: '1rem'}}>
                      <Typography variant="h6">{t('gender')}</Typography>
                    </Grid>
                    <Grid item>
                      <RadioGroup
                        row
                        aria-label="gender"
                        name="gender1"
                        value={gender}
                        onChange={(event) => {
                          setGender(event.target.value);
                        }}
                      >
                        <FormControlLabel
                          value="female"
                          control={<Radio />}
                          label={t('female')}
                        />
                        <FormControlLabel
                          value="male"
                          control={<Radio />}
                          label={t('male')}
                        />
                      </RadioGroup>
                    </Grid>
                  </Grid>
                </FormControl>
              </Grid>
              <Grid item>
                <Controller
                  name="pinCode"
                  control={control}
                  defaultValue=""
                  render={({field: {onChange, value}, fieldState: {error}}) => (
                    <TextField
                      id="outlined-basic1"
                      size="small"
                      type="number"
                      fullWidth
                      label="Pin Code"
                      onChange={(e) => {
                        onChange(e);
                        onPincodeChange(e.target.value);
                      }}
                      variant="outlined"
                      inputProps={{maxLength: 6}}
                      value={value}
                      error={!!error}
                      helperText={error ? error.message : null}
                    />
                  )}
                  rules={pincodeRules}
                />
                {/* <TextField
                className={classes.textInput}
                fullWidth
                id="outlined-basic"
                label="Pin Code"
                value={pinCode}
                size="small"
                variant="outlined"
                onChange={(txt) => {
                  onPincodeChange(txt.target.value);
                }}
              /> */}
              </Grid>
              <Grid item>
                <Box>
                  {pincodeObj ? (
                    <Box>
                      <Grid container direction={'column'} spacing={1}>
                        <Grid item>
                          <TextField
                            label={t('state')}
                            fullWidth
                            size="small"
                            inputProps={{readOnly: true}}
                            value={state}
                            onChange={(text) => setState(text.target.value)}
                            variant="outlined"
                            disabled={loading}
                            className={classes.textInput}
                          />
                        </Grid>
                        <Grid item>
                          <TextField
                            label={t('city')}
                            value={city}
                            variant="outlined"
                            fullWidth
                            size="small"
                            inputProps={{readOnly: true}}
                            onChange={(text) => setCity(text.target.value)}
                            editable={false}
                            disabled={loading}
                            className={classes.textInput}
                          />
                        </Grid>
                        <Grid item>
                          <Box className={classes.containerPicker}>
                            <FormControl
                              variant="outlined"
                              fullWidth
                              size="small"
                            >
                              <Select
                                fullWidth
                                variant="outlined"
                                native
                                value={locality}
                                onChange={(type, index) => {
                                  setLocality(type.target.value);
                                  let selectedLocality = localities.find(
                                    ({name}) => name === type.target.value
                                  );
                                  setSubLocalities(
                                    selectedLocality?.subLocalities
                                  );
                                  setSubLocality(
                                    selectedLocality?.subLocalities.name
                                  );
                                }}
                                inputProps={{
                                  name: 'age',
                                  id: 'outlined-age-native-simple',
                                }}
                              >
                                {localities.map((element, index) => (
                                  <option key={index} value={element.name}>
                                    {element.name}
                                  </option>
                                ))}
                              </Select>
                            </FormControl>
                          </Box>
                        </Grid>
                        <Grid item>
                          <Box className={classes.containerPicker}>
                            <FormControl
                              variant="outlined"
                              fullWidth
                              size="small"
                            >
                              {/* <Typography className={[classes.textPicker]}>
                            {t('subLocality')}
                          </Typography> */}
                              <Select
                                fullWidth
                                native
                                variant="outlined"
                                value={subLocality}
                                onChange={(type, index) => {
                                  setSubLocality(type.target.value);
                                }}
                                inputProps={{
                                  name: 'age',
                                  id: 'outlined-age-native-simple',
                                }}
                              >
                                {subLocalities.map((element, index) => (
                                  <option key={index} value={element.name}>
                                    {element.name}
                                  </option>
                                ))}
                              </Select>
                            </FormControl>
                          </Box>
                        </Grid>
                        <Grid item>
                          <TextField
                            label={t('address1')}
                            size="small"
                            fullWidth
                            value={address1}
                            onChange={(text) => setAddress1(text.target.value)}
                            variant="outlined"
                            disabled={loading}
                            className={classes.textInput}
                          />
                        </Grid>
                        <Grid item>
                          <TextField
                            label={t('address2')}
                            fullWidth
                            size="small"
                            value={address2}
                            onChange={(text) => setAddress2(text.target.value)}
                            variant="outlined"
                            disabled={loading}
                            className={classes.textInput}
                          />
                        </Grid>
                      </Grid>

                      {/* <TextInput
                label={t('locality')}
                value={locality}
                onChangeText={(text) => setLocality(text)}
                mode="outlined"
                numberOfLines={1}
                editable={false}
                disabled={loading}
                style={styles.textInput}
              /> */}

                      {/* <TextInput
                label={t('subLocality')}
                value={subLocality}
                onChangeText={(text) => setSubLocality(text)}
                mode="outlined"
                numberOfLines={1}
                editable={false}
                disabled={loading}
                style={styles.textInput}
              /> */}
                    </Box>
                  ) : (
                    ''
                  )}
                </Box>
              </Grid>
              <Grid item>
                <RHFTextInput
                  name="password"
                  label={t('password')}
                  control={control}
                  type={showPassword ? 'text' : 'password'}
                  rules={passwodRules}
                  showPassword={showPassword}
                  showPasswordIcon={true}
                  handleClickShowPassword={handleClickShowPassword}
                />
                {/* <TextField
                className={classes.textInput}
                fullWidth
                id="outlined-basic"
                label={t('password')}
                value={password}
                size="small"
                variant="outlined"
                onChange={(txt) => {
                  setPassword(txt.target.value);
                }}
              /> */}
              </Grid>
              <Grid item>
                <RHFTextInput
                  name="confirmPassword"
                  label={t('confirmPassword')}
                  control={control}
                  type={showConfirmPassrod ? 'text' : 'password'}
                  rules={passwodRules}
                  showPassword={showConfirmPassrod}
                  showPasswordIcon={true}
                  handleClickShowPassword={handleClickShowConfirmPassword}
                />
                {/* <TextField
                className={classes.textInput}
                fullWidth
                id="outlined-basic"
                label={t('confirmPassword')}
                value={confirmPassword}
                size="small"
                variant="outlined"
                onChange={(txt) => {
                  setConfirmPassword(txt.target.value);
                }}
              /> */}
              </Grid>
              <Grid item style={{display: 'flex', alignItems: 'center'}}>
                <Checkbox
                  color="primary"
                  value={terms}
                  inputProps={{'aria-label': 'secondary checkbox'}}
                  onChange={(event) => {
                    setTerms(event.target.checked);
                  }}
                />
                <Typography>
                  I agree with{' '}
                  <Link
                    style={{cursor: 'pointer'}}
                    onClick={() => {
                      history.push('/terms-of-use');
                    }}
                  >
                    Terms of use{' '}
                  </Link>
                  and{' '}
                  <Link
                    style={{cursor: 'pointer'}}
                    onClick={() => {
                      history.push('/privacy-policy');
                    }}
                  >
                    Privacy Policy.
                  </Link>
                </Typography>
              </Grid>
              <Grid item style={{display: 'flex', justifyContent: 'center'}}>
                <Button variant="outlined" type="submit" disabled={!terms}>
                  Sign Up
                </Button>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Box>
    </ErrorBoundary>
  );
}
