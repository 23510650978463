import {Box, Button, TextField, Typography} from '@material-ui/core';
import ActivityIndicator from '../../components/ActivityIndicator';
import React, {useState} from 'react';
import useStyle from './style';
import Rating from '@material-ui/lab/Rating';
import {useSelector} from 'react-redux';
import showToast from '../../components/Toast';
import feedbackService from '../../services/feedback';
import {useHistory} from 'react-router';
import WebToolbar from '../../components/toolbar/WebToolbar';
import AppBarName from '../../components/DumbComponents/AppBarName';
import SideNav from '../../components/sidenav/SideNav';
import ErrorBoundary from '../../components/ErrorBoundary';
import ErrorBoundaryFallback from '../../components/ErrorBoundaryFallback';
import MobileFooter from '../MobileFooter';

const Feedback = () => {
  const classes = useStyle();
  const [rating, setRating] = useState(0);
  const token = useSelector((state) => state.user.token);
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  async function onPressSend() {
    // if (!message.trim()) {
    //   showToast('Please enter your message', 'danger');
    //   return;
    // }
    // Keyboard.dismiss();
    setLoading(true);
    try {
      const payload = {
        Rating: rating,
        Message: message,
      };
      const response = await feedbackService(token, payload);
      console.log('feedbackService response', response);

      if (response.successCode === 1) {
        setLoading(false);
        showToast('success', 'Thanks for submitting your feedback');
        // navigation.goBack();
        history.push('/home');
      } else {
        setLoading(false);
        showToast('error', 'Failed');
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      showToast('error', 'Failed');
    }
  }

  return (
    <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
      <div className={classes.container}>
        <WebToolbar />
        <div className={classes.sideNav}>
          <SideNav />
        </div>
        <AppBarName title={'Feedback'} />
        {loading ? (
          <ActivityIndicator size="large" style={{height: '100%'}} />
        ) : (
          <>
            <Box p={2} style={{display: 'flex', justifyContent: 'center'}}>
              <Typography variant="h6">
                How would you rate GearConnect App experience?
              </Typography>
            </Box>
            <Box p={2} style={{display: 'flex', justifyContent: 'center'}}>
              <Rating
                name="simple-controlled"
                value={rating}
                size="large"
                style={{color: 'green'}}
                onChange={(event, newValue) => {
                  if (newValue === null) {
                    setRating(0);
                    return;
                  }
                  setRating(newValue);
                }}
              />
            </Box>
            <Box p={2}>
              <TextField
                label={'Message'}
                fullWidth
                size="small"
                value={message}
                onChange={(text) => {
                  setMessage(text.target.value);
                }}
                variant="outlined"
                multiline
                rows={2}
                inputProps={{maxLength: 250}}
                className={classes.textInput}
              />
            </Box>
            <Box style={{display: 'flex', justifyContent: 'center'}} py={2}>
              <Button
                disabled={message.length === 0 && rating === 0}
                variant="outlined"
                className={classes.button}
                onClick={onPressSend}
              >
                Submit
              </Button>
            </Box>
          </>
        )}
      </div>
      <MobileFooter />
    </ErrorBoundary>
  );
};

export default Feedback;
