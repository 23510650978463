import React from 'react';
import {Box} from '@material-ui/core';

const ErrorBoundaryFallback = () => {
  return (
    <Box
      style={{
        display: 'flex',
        justifyContent: 'center',
        textAlign: 'center',
        padding: '1rem',
      }}
      sx={{
        backgroundColor: 'primary.dark',
        '&:hover': {
          backgroundColor: 'primary.main',
          opacity: [0.9, 0.8, 0.7],
        },
      }}
    >
      <Box>
        <h2>Something went wrong.</h2>
        <h3>But you can try again.</h3>
      </Box>
    </Box>
  );
};

export default ErrorBoundaryFallback;
