import React, {useState} from 'react';
import {
  Typography,
  Button,
  Dialog,
  Box,
  DialogTitle,
  List,
  ListItem,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import useStyle from './style';
import CameraDialog from '../CameraDialog';

const SimpleDialog = ({
  open,
  onSimpleDialogClose,
  message,
  handleListItemClick,
  closeShowCameraDialogFunction,
  setOpen,
  cameraDialog,
  setCameraDialog,
}) => {
  const classes = useStyle();

  return (
    <>
      <Dialog
        onClose={onSimpleDialogClose}
        aria-labelledby="simple-dialog-title"
        open={open}
      >
        <List style={{width: '300px'}}>
          <ListItem>
            <Typography>{message}</Typography>
          </ListItem>

          <Box
            style={{
              display: 'flex',
              justifyContent: 'space-evenly',
            }}
          >
            <Button
              variant="outlined"
              component="label"
              // onClick={() => {
              //   handleListItemClick('GALLARY');
              // }}
            >
              <input
                type="file"
                hidden
                accept="image/png, image/jpeg"
                onChange={(e) => {
                  console.log('e', e.target.files[0]);
                  if (e.target.files.length > 0) {
                    handleListItemClick(
                      e.target.files[0]
                      // window.URL.createObjectURL(e.target.files[0])
                    );
                  }
                }}
              />
              GALLARY
            </Button>
            <Button
              variant="outlined"
              onClick={() => {
                setCameraDialog(true);
                setOpen(false);
              }}
            >
              CAMERA
            </Button>
          </Box>
        </List>
      </Dialog>
      <CameraDialog
        open={cameraDialog}
        camera={cameraDialog}
        onSimpleDialogClose={closeShowCameraDialogFunction}
      />
    </>
  );
};

SimpleDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  onSimpleDialogClose: PropTypes.func.isRequired,
  message: PropTypes.string.isRequired,
  handleListItemClick: PropTypes.func.isRequired,
  closeShowCameraDialogFunction: PropTypes.func.isRequired,
  cameraDialog: PropTypes.bool.isRequired,
  setCameraDialog: PropTypes.func.isRequired,
};

export default SimpleDialog;
