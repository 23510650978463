import React, { useState } from 'react';
import PropTypes from 'prop-types';
import useStyle from './style';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import EditVendorDetails from '../../../pages/EditVendorDetails';
import EditHoursScreen from '../../../pages/EditHoursScreen';
import EditVendorImages from '../../../pages/EditVendorImages';
import EditDeliveryOptionsScreen from '../../../pages/EditDeliveryOptionsScreen';
import ErrorBoundary from '../../ErrorBoundary';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function SimpleTabs() {
  const classes = useStyle();
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <AppBar position="static" elevation={0}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="simple tabs example"
          variant="fullWidth"
        >
          <Tab
            label="Edit Vendor"
            {...a11yProps(0)}
            className={classes.tabFont}
          />
          <Tab
            label="Edit Hours"
            {...a11yProps(1)}
            className={classes.tabFont}
          />
          <Tab
            label="Edit Images"
            {...a11yProps(2)}
            className={classes.tabFont}
          />
          <Tab
            label="Edit Delivery Options"
            {...a11yProps(3)}
            className={classes.tabFont}
          />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <EditVendorDetails />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <EditHoursScreen />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <EditVendorImages />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <ErrorBoundary FallbackComponent={() => {
          return <div>Some error found</div>
        }}
          onError={(error, errorInfo) => console.log({ error, errorInfo })}
        >
          <EditDeliveryOptionsScreen />
        </ErrorBoundary>
      </TabPanel>
    </div>
  );
}
