/* eslint-disable react/prop-types */
import React from 'react';
import {TextField, InputAdornment, IconButton} from '@material-ui/core';
import {Controller} from 'react-hook-form';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';

const RHFTextInput = ({
  name,
  label,
  type,
  rules,
  control,
  showPassword,
  handleClickShowPassword,
  showPasswordIcon,
  maxLength,
}) => {
  return (
    <>
      {showPasswordIcon ? (
        <Controller
          name={name}
          control={control}
          defaultValue=""
          render={({field: {onChange, value}, fieldState: {error}}) => (
            <TextField
              id="outlined-basic1"
              size="small"
              fullWidth
              label={label}
              variant="outlined"
              value={value}
              onChange={onChange}
              // onChange={(e) => {
              //   onChange(e);
              //   onPincodeChange(e.target.value);
              // }}
              error={!!error}
              helperText={error ? error.message : null}
              type={type}
              inputProps={{maxLength: 25}}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                    >
                      {showPassword ? (
                        <VisibilityIcon />
                      ) : (
                        <VisibilityOffIcon />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          )}
          rules={rules}
        />
      ) : (
        <Controller
          name={name}
          control={control}
          defaultValue=""
          render={({field: {onChange, value}, fieldState: {error}}) => (
            <TextField
              id="outlined-basic1"
              size="small"
              fullWidth
              style={{backgroundColor: 'transparent'}}
              label={label}
              variant="outlined"
              value={value}
              onChange={onChange}
              error={!!error}
              helperText={error ? error.message : null}
              type={type}
              inputProps={{maxLength: maxLength}}
            />
          )}
          rules={rules}
        />
      )}
    </>
  );
};

export default RHFTextInput;
