/* eslint-disable react/prop-types */
import React, {useState} from 'react';
import useStyle from './styles';
import {
  Box,
  Typography,
  Paper,
  Grid,
  Menu,
  Dialog,
  MenuItem,
  Divider,
  Card,
  CircularProgress,
  DialogContent,
  DialogActions,
  Button,
} from '@material-ui/core';
import moment from 'moment';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import WebToolbar from '../../components/toolbar/WebToolbar';
import {useHistory} from 'react-router';
import {get} from 'lodash';
import {useDispatch, useSelector} from 'react-redux';
import CallIcon from '@material-ui/icons/Call';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import getOrderDetails from '../../services/getOrderDetails';
import updateOrderStatus from '../../services/updateOrderStatus';
import addOrderAttachment from '../../services/addOrderAttachment';
import sendMessage from '../../services/sendMessage';
import {useTranslation} from 'react-i18next';
import showToast from '../../components/Toast';
import ActivityIndicator from '../../components/ActivityIndicator';
import CameraDialog from '../../components/DumbComponents/CameraDialog';
import SideNav from '../../components/sidenav/SideNav';
import AppBarName from '../../components/DumbComponents/AppBarName';
import {TabPanel} from '../../components/DumbComponents/TapPanelProps';
import MessageInput from '../../components/MessageInput';

const MessagesTabPanel = ({
  value,
  messageLoading,
  receivedMessages,
  bottomRef,
  orderDetails,
  flatListRef,
  setMessageLoading,
  getOrderMessages,
}) => {
  const classes = useStyle();
  const [message, setMessage] = useState();
  const token = useSelector((state) => state.user.token);
  const __orderId = useSelector((state) => state.user.orderId);

  const [sending, setSending] = useState(false);
  const [showCameraDialog, setShowCameraDialog] = useState(false);
  const [cameraOn, setCameraOn] = useState(false);
  const [showPreview, setShowPreview] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [selectedValue, setSelectedValue] = useState();

  const handleClickOpenImgViewer = () => {
    setOpen(true);
  };

  const handleCloseImgViewer = (value) => {
    setOpen(false);
  };

  const FileComponent = ({message, type, item, i}) => {
    const [imageViewer, setImageViewer] = useState(false);
    const [selectedImage, setSelectedImage] = useState([]);
    const [initialIndex, setInitialIndex] = useState(0);

    // console.log('item', item);
    const split = item.split('?');
    const split1 = split[0].split('.');

    // console.log('split', 'split1', split, split1);

    const fileType = split1[split1.length - 1];
    // console.log('fileType', fileType);

    async function onPressFile() {
      try {
        const fileExtention = fileType;
        const tmp = split[0].split('/');
        const name = tmp;
        let directory = `${name[name.length - 1]}`;
        window.open(item);
      } catch (error) {
        console.log(error);
      }
    }

    if (
      fileType === 'png' ||
      fileType === 'PNG' ||
      fileType === 'jpg' ||
      fileType === 'JPG' ||
      fileType === 'jpeg' ||
      fileType === 'JPEG'
    ) {
      return (
        <>
          <Box
            onClick={() => {
              handleClickOpenImgViewer();
              // setSelectedImage([
              //   {
              //     url: `${item}`,
              //   },
              // ]);
            }}
          >
            <img
              src={item}
              height="150px"
              width="200px"
              style={{cursor: 'pointer'}}
            />
          </Box>
          <Dialog
            onClose={handleCloseImgViewer}
            aria-labelledby="simple-dialog-title"
            open={open}
          >
            <img src={item} />
          </Dialog>
        </>
      );
    } else if (fileType === 'pdf') {
      return (
        <>
          <Box
            style={{
              display: 'flex',
              flexDirection: 'row',
            }}
            onClick={() => onPressFile()}
          >
            <PictureAsPdfIcon color="white" name="pdf-box" size={48} />
            <Typography className={classes.texFile}>PDF File</Typography>
          </Box>
        </>
      );
    } else {
      return <Box />;
    }
  };

  const CommentItem = ({message, type, item, i}) => {
    const user = useSelector((state) => state.user.user);
    return (
      <>
        <Card
          className={
            user.userId === i.userId ? classes.cardSelfMsg : classes.cardMessage
          }
        >
          {user.userId !== i.userId && (
            <Typography className={classes.textSender}>
              {i.communicationBy}
            </Typography>
          )}
          {type === 1 ? (
            <FileComponent message={message} type={type} item={item} i={i} />
          ) : (
            <Typography className={classes.textSelfMsg}>{message}</Typography>
          )}
          <Typography className={classes.textDate}>
            {moment(new Date(i.communicationDate)).format('DD/MM/YYYY, h:mm A')}
          </Typography>
        </Card>
      </>
    );
  };

  async function sendMessageData(event) {
    if (message?.trim()) {
      setMessageLoading(true);
      // const _orderId = get(props, 'location.orderID', '');
      try {
        const response = await sendMessage(__orderId.orderId, message, token);
        if (response.successCode === 1) {
          setMessage('');
          getOrderMessages();
          // dispatch(setUser(response.response));
        } else if (response.messageCode === '400300124') {
          setMessage('');
          // navigation.replace('OTP', {userName: email});
        } else {
          // showToast(event, response.returnMessage);
          setMessage('');
        }
        setMessageLoading(false);
      } catch (error) {
        setMessage('');
        setMessageLoading(false);
        console.log('response error', error);
      }
    }
  }

  async function sendDoc(doc) {
    try {
      const payload = new FormData();
      payload.append('files', doc);
      payload.append('orderId', orderDetails.orderID);
      const response = await addOrderAttachment(payload, token);
      console.log('response from sendDoc', response);
      if (response.successCode === 1) {
        setMessage('');
        getOrderMessages();
      }
    } catch (error) {
      console.log(error);
    }
  }

  const fileSelectorHandler = (event) => {
    console.log('event', event.target.value);
    console.log('valueee', event.target.files[0]);
    sendDoc(event.target.files[0], event.target.value);
    // setMessage(event.target.files[0]);
  };

  const showImage = async () => {
    let _capturedImg = webRef.current.getScreenshot({width: 300, height: 200});
    console.log('_capturedImg', _capturedImg);
    setCapturedImage(_capturedImg);
  };

  async function sendImage(image) {
    setShowCameraDialog(true);
    setCameraOn(true);
  }

  const closeShowCameraDialogFunction = async (capturedFile) => {
    if (capturedFile) {
      setShowCameraDialog(false);
      setCameraOn(false);

      try {
        const formData = new FormData();
        formData.append('files', capturedFile);
        formData.append('orderId', orderDetails.orderID);

        const response = await addOrderAttachment(formData, token);
        console.log('response', response);
        if (response.successCode === 1) {
          setMessage('');
          getOrderMessages();
          setShowPreview(false);
        }
      } catch (error) {
        console.log(error);
      }
    } else if (typeof data === 'object') {
      setShowCameraDialog(false);
      setCameraOn(false);
    }
  };

  return (
    <>
      <TabPanel value={value} index={1}>
        {messageLoading ? (
          <ActivityIndicator style={{minHeight: '100%', height: 'auto'}} />
        ) : (
          <Grid
            container
            direction={'column'}
            component={Paper}
            className={classes.chatSection}
          >
            <Grid
              item
              style={{
                overflow: 'auto',
                height: '32vh',
              }}
            >
              {receivedMessages &&
                receivedMessages.map((item, index) => {
                  return (
                    <Box key={index} ref={flatListRef}>
                      <CommentItem
                        message={item.message}
                        type={item.communicationType}
                        item={item.message}
                        i={item}
                      />
                    </Box>
                  );
                })}
              <div ref={bottomRef}></div>
            </Grid>
            <Grid
              item
              style={{
                width: '100%',
                position: 'absolute',
                bottom: 0,
              }}
            >
              <MessageInput
                message={message}
                loading={sending}
                onDocument={(doc) => sendDoc(doc)}
                onPhoto={(image) => sendImage(image)}
                setMessage={(text) => setMessage(text)}
                placeholder="Enter your message here"
                onPressFab={sendMessageData}
                disabled={false}
              />
              <CameraDialog
                open={showCameraDialog}
                camera={cameraOn}
                onSimpleDialogClose={(capturedImage) => {
                  if (capturedImage) {
                    setShowPreview(capturedImage);
                  }
                  setShowCameraDialog(false);
                  setCameraOn(false);
                }}
              />
            </Grid>
          </Grid>
        )}
        {/* <Box>
                    {!isEmpty(receivedMessages)
                      ? receivedMessages.map((singleMessage, index) => {
                          return singleMessage.message.length !== 0 ? (
                            <Box
                              key={index}
                              boxShadow={3}
                              className={
                                user.userId === singleMessage.userId
                                  ? classes.cardSelfMsg
                                  : classes.cardMessage
                              }
                            >
                              {user.userId !== singleMessage.userId && (
                                <Typography style={{fontWeight: '900'}}>
                                  {singleMessage.communicationBy}
                                </Typography>
                              )}
                              {singleMessage.communicationType === 1 ? (
                                <FileComponent
                                  message={singleMessage.message}
                                  type={singleMessage.communicationType}
                                  item={singleMessage.message}
                                  i={singleMessage}
                                />
                              ) : (
                                <Tooltip title={singleMessage.message}>
                                  <Typography
                                    className={
                                      user.userId === singleMessage.userId
                                        ? classes.abc
                                        : classes.xyz
                                    }
                                  >
                                    {singleMessage.message}
                                  </Typography>
                                </Tooltip>
                              )}

                              <Typography
                                style={{
                                  fontSize: 10,
                                  textAlign: 'right',
                                }}
                              >
                                {moment(
                                  new Date(singleMessage.communicationDate)
                                ).format('DD/MM/YYYY, h:mm A')}
                              </Typography>
                            </Box>
                          ) : (
                            ''
                          );
                        })
                      : ''}
                  </Box>
                  <Box className={classes.msgIpBtnContainer} m={1}>
                    <TextField
                      size="small"
                      variant="outlined"
                      fullWidth
                      multiline
                      rows={3}
                      value={message}
                      placeholder="Enter your message here..."
                      className={classes.txtfild}
                      onChange={(text) => {
                        setMessage(text.target.value);
                      }}
                      InputProps={{
                        endAdornment: (
                          <>
                            <InputAdornment position="end">
                              <input
                                accept="image/*"
                                className={classes.input}
                                id="icon-button-file"
                                type="file"
                                onChange={(event) => fileSelectorHandler(event)}
                              />
                              <label htmlFor="icon-button-file">
                                <IconButton
                                  color="primary"
                                  aria-label="upload picture"
                                  component="span"
                                >
                                  <AttachmentIcon />
                                </IconButton>
                              </label>
                            </InputAdornment>
                            <InputAdornment position="end">
                              <CameraAltIcon
                                onClick={() => {
                                  setOpenCamera(true);
                                }}
                              />
                            </InputAdornment>
                          </>
                        ),
                      }}
                    ></TextField>
                    <IconButton onClick={sendMessageData}>
                      <Avatar className={classes.sendMessageBtn}>
                        <SendIcon style={{fontSize: 25}} />
                      </Avatar>
                    </IconButton>
                  </Box>
                  <Box boxShadow={3} className={classes.cameraContainer}>
                    {openCamera ? (
                      <Box p={1} className={classes.cameraContainerOne}>
                        <Webcam
                          audio={false}
                          screenshotFormat="image/jpeg"
                          ref={webRef}
                          height={400}
                          // width={500}
                        ></Webcam>
                        <Box m={3} className={classes.captureSaveBtnContainer}>
                          <Button
                            variant="outlined"
                            color="primary"
                            onClick={() => {
                              showImage();
                            }}
                          >
                            Capture
                          </Button>
                          <Button
                            variant="outlined"
                            className={classes.retakeBtn}
                            onClick={() => {
                              setOpenCamera(false);
                            }}
                          >
                            Close
                          </Button>
                        </Box>
                      </Box>
                    ) : (
                      ''
                    )}
                    <Box>
                      {capturedImage && (
                        <Box className={classes.captureImgContainer}>
                          <img src={capturedImage} />
                          <Box
                            m={2}
                            className={classes.captureSaveBtnContainer}
                          >
                            <Button
                              variant="outlined"
                              className={classes.saveImgBtn}
                              onClick={() => {
                                // setMessage(capturedImage);
                                sendImage(capturedImage);
                                setCapturedImage('');
                                setOpenCamera(false);
                              }}
                            >
                              Save
                            </Button>
                            <Button
                              variant="outlined"
                              className={classes.retakeBtn}
                              onClick={() => {
                                setCapturedImage('');
                                setMessage('');
                              }}
                            >
                              Retake
                            </Button>
                          </Box>
                        </Box>
                      )}
                    </Box>
                  </Box> */}
      </TabPanel>
      <Dialog
        onClose={() => {
          setShowPreview(false);
        }}
        aria-labelledby="customized-dialog-title"
        open={!!showPreview}
      >
        <DialogContent dividers>
          {showPreview ? (
            <img
              src={window.URL.createObjectURL(showPreview)}
              style={{
                height: '390px',
                width: '310px',
              }}
            />
          ) : (
            <Box>No image to show</Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            onClick={() => closeShowCameraDialogFunction(showPreview)}
          >
            Submit
          </Button>
          <Button variant="outlined" onClick={() => setShowPreview(false)}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default MessagesTabPanel;
