import {
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  IconButton,
  Avatar,
  RadioGroup,
  Select,
  TextField,
  Typography,
  Dialog,
  List,
  ListItem,
  ListItemText,
  InputLabel,
} from '@material-ui/core';
import {setUser, setProfilePic as setUserPic} from '../../redux/actions/user';
import React, {useCallback, useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {get} from 'lodash';
import useStyle from './style';
import getProfile from '../../services/getProfile';
import showToast from '../../components/Toast';
import pinCodeService from '../../services/pincode';
import updateProfile from '../../services/updateProfile';
import updateProfilePic from '../../services/updateProfilePic';
import PermIdentityIcon from '@material-ui/icons/PermIdentity';
import ageGroups from '../../utils/ageGrooups';
import {useHistory} from 'react-router';
import {makeStyles} from '@material-ui/core/styles';
import {blue} from '@material-ui/core/colors';
import PropTypes from 'prop-types';
import Webcam from 'react-webcam';
import WebToolbar from '../../components/toolbar/WebToolbar';
import DoneIcon from '@material-ui/icons/Done';
import AppBarName from '../../components/DumbComponents/AppBarName';
import ActivityIndicator from '../../components/ActivityIndicator';
import SideNav from '../../components/sidenav/SideNav';
import ImageDialog from '../../components/DumbComponents/ImageDialog';
import {s3BaseUrl} from '../../utils/Constants';
import ErrorBoundary from '../../components/ErrorBoundary';
import ErrorBoundaryFallback from '../../components/ErrorBoundaryFallback';
import MobileFooter from '../MobileFooter';
import useNotifications from '../../helpers/useNotifications';

const useStyles = makeStyles({
  avatar: {
    backgroundColor: blue[100],
    color: blue[600],
  },
  input: {display: 'none'},
});

function SimpleDialog(props) {
  const classes = useStyles();
  const [_selectedIamge, _setSelectedImage] = useState(null);
  const {onClose, selectedValue, open, selectedImage} = props;

  const handleClose = () => {
    onClose(selectedValue);
  };

  const handleListItemClick = (value) => {
    onClose(value);
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
      selectedImage={selectedImage}
    >
      <List>
        <ListItem
          button
          onClick={() => handleListItemClick('Capture Image')}
          key={'Capture Image'}
        >
          <ListItemText primary={'Capture Image'} />
        </ListItem>
        <ListItem
          button
          onClick={() => handleListItemClick('Select from Library')}
          key={'Select from Library'}
        >
          <input
            type="file"
            // hidden
            onChange={(event) => {
              console.log('sdsdsdsd', event);
              _setSelectedImage(event.target.files[0]);
            }}
          />
          <ListItemText primary={'Select from Library'} />
        </ListItem>
      </List>
    </Dialog>
  );
}

SimpleDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired,
  selectedImage: PropTypes.any,
};

const UpdateProfileScreen = () => {
  const labelRef = useRef();
  const classes = useStyle();
  const {t} = useTranslation('signUp');
  const dispatch = useDispatch();
  const token = useSelector((state) => state.user.token);
  const user = useSelector((state) => state.user.user);
  const [fName, setFname] = useState(user.username);
  const [lName, setLname] = useState('');
  const [imageModal, setImageModal] = useState(false);
  const [email, setEmail] = useState(user.email);
  const [state, setState] = useState('');
  const [city, setCity] = useState('');
  const [phone, setPhone] = useState(user.mobile);
  const [dp, setDp] = useState('');
  const [pinCode, setPincode] = useState('');
  const [address1, setAddress1] = useState('');
  const [address2, setAddress2] = useState('');
  //const [password, setPassword] = useState('');
  const [locality, setLocality] = useState('');
  const [subLocality, setSubLocality] = useState('');
  const [modalVisible, setModalVisible] = useState(false);
  const [profilePic, setProfilePic] = useState('');
  const [gender, setGender] = useState('male');
  const [yearOfBirth, setYearOfBirth] = useState('');
  const [loading, setLoading] = useState(false);
  const [pincodeObj, setPincodeObj] = useState('');
  const [reqesting, setReqesting] = useState(true);
  const [subLocalities, setSubLocalities] = useState([]);
  const [localities, setLocalities] = useState([]);
  const [showCamera, setShowCamera] = useState(false);
  const [id, setId] = useState('');
  const history = useHistory();
  const {getNotificationData} = useNotifications();

  const [open, setOpen] = useState(false);
  const [cameraDialog, setCameraDialog] = useState(false);
  const [selectedValue, setSelectedValue] = useState();
  const [selectedIamge, setSelectedImage] = useState(null);

  const webcamRef = useRef(false);
  const videoConstraints = {
    width: 100,
    height: 100,
    facingMode: 'user',
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value) => {
    console.log('val', value);
    addDp(value);

    setOpen(false);
    setSelectedValue(value);
  };

  useEffect(() => {
    //onPincodeChange(`${user.pincode}`);
    getUserProfile();
  }, []);

  async function getUserProfile() {
    try {
      setLoading(true);
      const response = await getProfile(token);
      console.log('getProfile response', response);
      if (response.successCode === 1) {
        const {userProfile} = response.response;
        console.log('userProfile', userProfile);
        setId(userProfile.id);
        setSubLocality(userProfile.subLocalityID);
        setLocality(userProfile.localityID);
        setAddress1(userProfile.addressLine1);
        setAddress2(userProfile.addressLine2);
        if (userProfile.profilePicture) {
          setDp({photo: null, photoUrl: userProfile.profilePicture});
        }
        setEmail(userProfile.email);
        setYearOfBirth(userProfile.ageGroup);
        setFname(userProfile.name);
        setPhone(userProfile.mobile);
        setGender(userProfile.sex === 0 ? 'male' : 'female');
        onPincodeChange(`${userProfile.pinCodeID}`, userProfile.localityID);
        setReqesting(false);
        setLoading(false);
      } else {
        setReqesting(false);
        setLoading(false);
        showToast('error', t('getProfileFailed'));
        history.goBack();
      }
    } catch (error) {
      console.log(error);
      setReqesting(false);
      setLoading(false);
      history.goBack();
      showToast('error', t('getProfileFailed'));
    }
  }

  async function onPincodeChange(text, localityID) {
    setPincode(text);
    setPincodeObj('');
    if (text.length === 6) {
      setModalVisible(true);
      try {
        const response = await pinCodeService(text);
        console.log('pinCodeService response', response);
        setModalVisible(false);
        if (response.successCode === 1) {
          const data = response.response;
          setPincodeObj(data);
          if (!localityID) {
            setLocalities(data.area.localities);
            setSubLocalities(data.area.localities[0].subLocalities);
            setLocality(data.area.localities[0].id);
            setSubLocality(data.area.localities[0].subLocalities[0].id);
            setCity(data.area.city.name);
            setState(data.area.state.name);
          } else {
            setLocalities(data.area.localities);
            const subLoc = data.area.localities.find(
              (a) => a.id === localityID
            );
            setSubLocalities(subLoc.subLocalities);
            setCity(data.area.city.name);
            setState(data.area.state.name);
          }
        } else {
          showToast(t(response.messageCode), 'danger');
          setPincodeObj('');
          setLocality('');
          setSubLocality('');
        }
        // console.log(response);
      } catch (error) {
        setModalVisible(false);
        console.log(error);
      }
    }
  }

  async function uploadDp(image) {
    try {
      const payloadImage = new FormData();
      payloadImage.append('formFile', image);
      const responsePic = await updateProfilePic(payloadImage, token);
      if (responsePic.successCode === 1) {
        dispatch(setProfilePic(responsePic.response.profilePictureIdentifier));
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function onPressSignUp() {
    if (!fName.trim()) {
      showToast('error', t('enterFName'));
      return;
    }
    if (email.trim()) {
      var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
      if (!reg.test(email.trim())) {
        showToast('error', t('invalidEmail'));
        return;
      }
    }
    if (yearOfBirth === 0) {
      showToast('error', t('invalidYob'));
      return;
    }
    if (!address1.trim()) {
      showToast('error', t('invalidAddres'));
      return;
    }

    try {
      const selectedLocaliity = localities.find((loc) => loc.id === locality);
      const selectedSubLocality = selectedLocaliity.subLocalities.find(
        (loc) => loc.id === subLocality
      );
      const payload = {
        ID: id,
        Name: `${fName} ${lName}`,
        Email: email,
        Mobile: `${phone}`,
        AddressLine1: address1,
        AddressLine2: address2,
        SubLocalityID: selectedSubLocality.id,
        SubLocalityName: selectedSubLocality.name,
        LocalityID: selectedLocaliity.id,
        LocalityName: selectedLocaliity.name,
        PinCodeId: Number(pinCode),
        CityID: pincodeObj.area.city.id,
        stateId: pincodeObj.area.state.id,
        CountryID: pincodeObj.area.country.id,
        ReferredBy: 1,
        // Password: password,
        // ConfirmPassword: password,
        // ProfilePicture: await toBase64(dp.photo),
        AgeGroup: yearOfBirth,
        Sex: gender === 'male' ? 0 : 1,
      };
      setLoading(true);
      const response = await updateProfile(payload, token);
      console.log('updateProfile response', response);
      if (response.successCode === 1) {
        getNotificationData();
        const u = {
          ...user,
          username: `${fName}`,
          email: email,
          mobile: phone,
          pincode: pinCode,
          localityId: pincodeObj.area.localities[0].id,
          sublicalityId: pincodeObj.area.localities[0].subLocalities[0].id,
          isVendor: user.isVendor,
        };
        dispatch(setUser(u));
        if (dp.photo) {
          const payloadImage = new FormData();
          payloadImage.append('formFile', dp.photo);
          const responsePic = await updateProfilePic(payloadImage, token);
          if (responsePic.successCode === 1) {
            dispatch(setUserPic(responsePic.response.profilePictureIdentifier));
            setLoading(false);
            showToast('success', t(response.messageCode));
          } else {
            setLoading(false);
            showToast('error', 'Failed');
          }
        } else {
          setLoading(false);
          showToast('success', t(response.messageCode));
        }
      } else {
        setLoading(false);
        const message =
          t(response.messageCode) || response.title || response.returnMessage;
        showToast('error', message);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
      showToast('error', 'Failed');
    }
  }

  function addDp(type) {
    console.log('type in add dp', type);
    if (type === 'Capture Image') {
      setShowCamera(!showCamera);
    } else if (type === 'Select from Library') {
    }
  }
  const capture = useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    console.log('imageSrc', imageSrc);
    setProfilePic(imageSrc);
  }, [webcamRef]);

  const handleListItemClick = (val) => {
    // history.push('addvendorshopdeteils');
    console.log('val', val); //got image from gallary
    setDp({photo: val, photoUrl: URL.createObjectURL(val)});
    // setHeaderImage({
    //   photoURL: URL.createObjectURL(val.target.files[0]),
    // });
    setOpen(false);
    setCameraDialog(false);
  };
  const closeDialogFunction = (value) => {
    console.log('val', value);
    addDp(value);
    setOpen(false);
    //Camera
    // setShowCamera(!showCamera);
  };

  return (
    <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
      <div className={classes.container}>
        <WebToolbar />{' '}
        <div className={classes.sideNav}>
          <SideNav />
        </div>
        <AppBarName title={'Update Profile'} />
        {loading ? (
          <ActivityIndicator />
        ) : (
          <Box className={[classes.card]}>
            <Box className={classes.profilePicContainer}>
              {profilePic ? (
                <img src={profilePic.path} width="100" height="100" />
              ) : (
                <Box onClick={handleClickOpen}>
                  {get(dp, 'photoUrl', '').length ? (
                    <img
                      width="100"
                      height="100"
                      alt="profile image"
                      style={{borderRadius: '50px'}}
                      src={
                        dp.photoUrl.startsWith('profile')
                          ? `${s3BaseUrl}/${dp.photoUrl}`
                          : dp.photoUrl
                      }
                    />
                  ) : (
                    <Box>
                      <label htmlFor="icon-button-file">
                        <IconButton
                          className={classes.profilePicNAContainer}
                          color="primary"
                          aria-label="upload picture"
                          component="span"
                        >
                          <Avatar
                            // src="https://www.w3schools.com/howto/img_avatar.png"
                            className={classes.profilePicNAContainer}
                          >
                            <PermIdentityIcon
                              style={{
                                height: '60px',
                                width: '60px',
                                color: 'black',
                              }}
                            />
                          </Avatar>
                        </IconButton>
                      </label>
                    </Box>
                  )}
                </Box>
              )}
              {showCamera ? (
                <Box style={{display: 'flex', flexDirection: 'column'}}>
                  <Webcam
                    audio={false}
                    height={200}
                    ref={webcamRef}
                    screenshotFormat="image/jpeg"
                    width={220}
                    videoConstraints={videoConstraints}
                  />
                  <Box
                    py={1}
                    style={{display: 'flex', justifyContent: 'space-evenly'}}
                  >
                    <Button variant="outlined" onClick={capture}>
                      Capture
                    </Button>
                    <Button
                      variant="outlined"
                      onClick={() => {
                        setShowCamera(!showCamera);
                      }}
                    >
                      Cancel
                    </Button>
                  </Box>
                </Box>
              ) : (
                ''
              )}
              <Box>
                <ImageDialog
                  open={open}
                  setOpen={setOpen}
                  cameraDialog={cameraDialog}
                  setCameraDialog={setCameraDialog}
                  onSimpleDialogClose={closeDialogFunction}
                  message="Pick Image From?"
                  handleListItemClick={handleListItemClick}
                  closeShowCameraDialogFunction={handleListItemClick}
                />
              </Box>
              {/* <SimpleDialog
              selectedValue={selectedValue}
              open={open}
              onClose={handleClose}
              selectedImage={selectedIamge}
            /> */}
            </Box>
            <Box style={{flexDirection: 'row'}} py={2}>
              {/* <TextInput
            label={t('lastName')}
            value={lName}
            onChangeText={(text) => setLname(text)}
            mode="outlined"
            maxLength={20}
            numberOfLines={1}
            keyboardType="name-phone-pad"
            disabled={loading}
            className={classes.textInput}
          /> */}
            </Box>
            <Grid container direction={'column'} spacing={1}>
              <Grid item>
                <TextField
                  className={classes.textInput}
                  fullWidth
                  placeholder="Enter Name"
                  id="outlined-basic"
                  label={t('name')}
                  inputProps={{maxLength: 30}}
                  value={fName}
                  size="small"
                  variant="outlined"
                  onChange={(txt) => {
                    setFname(txt.target.value);
                  }}
                />
              </Grid>
              <Grid item>
                <TextField
                  label={t('email')}
                  value={email}
                  onChange={(txt) => {
                    setEmail(txt.target.value);
                  }}
                  className={classes.textInput}
                  fullWidth
                  placeholder="Enter Email"
                  id="outlined-basic"
                  inputProps={{maxLength: 30}}
                  size="small"
                  variant="outlined"
                />
              </Grid>
              <Grid item>
                <TextField
                  label={t('mobile')}
                  value={phone}
                  onChange={(txt) => {
                    setPhone(txt.target.value);
                  }}
                  className={classes.textInput}
                  fullWidth
                  placeholder="Enter Phone"
                  id="outlined-basic"
                  inputProps={{maxLength: 10, readOnly: true}}
                  size="small"
                  variant="outlined"
                />
              </Grid>
              <Grid item>
                <Box className={classes.containerPicker}>
                  <Card>
                    <Box px={1}>
                      <Typography>Age Group(years)</Typography>
                    </Box>

                    <CardContent
                      style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        listStyle: 'none',
                        padding: '0.5px',
                      }}
                    >
                      {ageGroups.map((value, index) => {
                        return (
                          <Chip
                            // className={
                            //   yearOfBirth === value.id
                            //     ? classes.chipSelected
                            //     : classes.chip
                            // }
                            style={{margin: '5px'}}
                            color={
                              yearOfBirth === value.id ? 'primary' : 'default'
                            }
                            variant={
                              yearOfBirth === value.id ? 'default' : 'outlined'
                            }
                            deleteIcon={<DoneIcon />}
                            onDelete={yearOfBirth === value.id && (() => {})}
                            key={index}
                            // selectedColor={yearOfBirth === value.id ? 'white' : 'black'}
                            // selected={yearOfBirth === value.id}
                            label={value.group}
                            onClick={() => setYearOfBirth(value.id)}
                          >
                            {value.group}
                          </Chip>
                        );
                      })}
                    </CardContent>
                  </Card>
                </Box>
              </Grid>
              <Grid item>
                <FormControl
                  component="fieldset"
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                >
                  {/* <FormLabel component="legend">Gender</FormLabel> */}
                  <Grid
                    container
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <Grid item style={{marginRight: '1rem'}}>
                      <Typography variant="h6">{t('gender')}</Typography>
                    </Grid>
                    <Grid item>
                      <RadioGroup
                        row
                        aria-label="gender"
                        name="gender1"
                        value={gender}
                        onChange={(event) => {
                          setGender(event.target.value);
                        }}
                      >
                        <FormControlLabel
                          value="female"
                          control={<Radio />}
                          label={t('female')}
                        />
                        <FormControlLabel
                          value="male"
                          control={<Radio />}
                          label={t('male')}
                        />
                      </RadioGroup>
                    </Grid>
                  </Grid>
                </FormControl>
              </Grid>
              <Grid item>
                <TextField
                  label={t('pincode')}
                  value={pinCode}
                  onChange={(txt) => {
                    onPincodeChange(txt.target.value);
                  }}
                  className={classes.textInput}
                  fullWidth
                  placeholder="Enter PinCode"
                  id="outlined-basic"
                  inputProps={{maxLength: 6}}
                  size="small"
                  variant="outlined"
                />
              </Grid>
            </Grid>

            {/* <TextInput
            label={t('yearOfBirth')}
            value={yearOfBirth}
            onChangeText={(text) => setYearOfBirth(text)}
            mode="outlined"
            numberOfLines={1}
            maxLength={4}
            keyboardType="numeric"
            disabled={loading}
            className={classes.textInput}
          /> */}

            <Grid item>
              <Box>
                {pincodeObj ? (
                  <Box>
                    {/* <TextField
                label={t('locality')}
                value={locality}
                select
                onChange={(type, index) => {
                  console.log('type.target.value', type, 'index', index);
                  setLocality(type.target.value);
                  setSubLocalities(localities[index].subLocalities);
                  setSubLocality(localities[index].subLocalities.id);
                }}
                variant="outlined"
                className={classes.textInput}
              >
                {localities.map((element, index) => {
                  console.log('element', element);
                  return (
                    <option key={index} value={element.id}>
                      {element.name}
                    </option>
                  );
                })}
              </TextField> */}
                    <Grid container direction={'column'} spacing={2}>
                      <Grid item>
                        <FormControl
                          variant="outlined"
                          size="small"
                          className={classes.containerPicker}
                          fullWidth
                        >
                          {/* <Typography className={[classes.textPicker]}>
                          {t('locality')}
                        </Typography> */}
                          <InputLabel htmlFor="my-input">
                            {t('locality')}
                          </InputLabel>
                          <Select
                            variant="outlined"
                            native
                            labelWidth="60"
                            // label={t('locality')}
                            value={locality}
                            onChange={(type, index) => {
                              setLocality(type.target.value);
                              setSubLocalities(
                                localities[type.target.selectedIndex]
                                  .subLocalities
                              );
                              setSubLocality(
                                localities[type.target.selectedIndex]
                                  .subLocalities.id
                              );
                            }}
                            inputProps={{
                              name: 'age',
                              id: 'outlined-age-native-simple',
                            }}
                          >
                            {localities.map((element, index) => (
                              <option key={index} value={element.id}>
                                {element.name}
                              </option>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item>
                        <FormControl
                          variant="outlined"
                          size="small"
                          fullWidth
                          className={classes.containerPicker}
                        >
                          {/* <Typography className={[classes.textPicker]}>
                          {t('subLocality')}
                        </Typography> */}
                          <InputLabel htmlFor="my-input">
                            {t('subLocality')}
                          </InputLabel>

                          <Select
                            variant="outlined"
                            labelWidth={90}
                            native
                            value={subLocality}
                            onChange={(type) => {
                              setSubLocality(type.target.value);
                            }}
                            inputProps={{
                              name: 'age',
                              id: 'outlined-age-native-simple',
                            }}
                          >
                            {localities.map((element, index) => (
                              <option key={index} value={element.id}>
                                {element.name}
                              </option>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>

                    {/* <TextInput
                  label={t('subLocality')}
                  value={subLocality}
                  onChangeText={(text) => setSubLocality(text)}
                  mode="outlined"
                  numberOfLines={1}
                  editable={false}
                  disabled={loading}
                  className={classes.textInput}
                /> */}

                    <Grid container direction={'column'} spacing={2}>
                      <Grid item>
                        <TextField
                          label={t('city')}
                          value={city}
                          onChange={(txt) => {
                            setCity(txt.target.value);
                          }}
                          className={classes.textInput}
                          fullWidth
                          placeholder="Enter City"
                          id="outlined-basic"
                          inputProps={{readOnly: true}}
                          size="small"
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item>
                        <TextField
                          label={t('state')}
                          value={state}
                          onChange={(txt) => {
                            setState(txt.target.value);
                          }}
                          className={classes.textInput}
                          fullWidth
                          placeholder="Enter State"
                          id="outlined-basic"
                          inputProps={{readOnly: true}}
                          size="small"
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item>
                        <TextField
                          label={t('address1')}
                          value={address1}
                          onChange={(txt) => {
                            setAddress1(txt.target.value);
                          }}
                          className={classes.textInput}
                          fullWidth
                          placeholder="Enter Address"
                          id="outlined-basic"
                          inputProps={{maxLength: 100}}
                          size="small"
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item>
                        <TextField
                          label={t('address2')}
                          value={address2}
                          onChange={(txt) => {
                            setAddress2(txt.target.value);
                          }}
                          className={classes.textInput}
                          fullWidth
                          placeholder="Enter Address"
                          id="outlined-basic"
                          inputProps={{maxLength: 100}}
                          size="small"
                          variant="outlined"
                        />
                      </Grid>
                    </Grid>
                  </Box>
                ) : null}
              </Box>
            </Grid>

            {/* <TextInput
            label={t('password')}
            value={password}
            secureTextEntry
            onChangeText={(text) => setPassword(text)}
            mode="outlined"
            numberOfLines={1}
            disabled={loading}
            className={classes.textInput}
          /> */}

            <Box
              style={{display: 'flex', justifyContent: 'space-evenly'}}
              py={2}
            >
              <Button
                className={classes.btn}
                variant="outlined"
                onClick={() => history.goBack()}
              >
                Cancel
              </Button>
              <Button
                className={classes.btn}
                variant="outlined"
                onClick={() => onPressSignUp()}
              >
                Update
              </Button>
            </Box>
          </Box>
        )}
      </div>
      <MobileFooter />
    </ErrorBoundary>
  );
};

export default UpdateProfileScreen;
