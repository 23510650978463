/* eslint-disable react/prop-types */
import React, {useEffect, useState, useCallback} from 'react';
import {useSelector} from 'react-redux';
import searchVendor from '../../services/searchVendorByName';

import useStyle from './style';
import WebToolbar from '../../components/toolbar/WebToolbar';
import {Box, Typography} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {get, lowerCase, debounce} from 'lodash';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';

import PropTypes from 'prop-types';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import SimpleDialog from '../../components/DumbComponents/SimpleDialog';
import SearchVendorAutoComplete from '../../components/DumbComponents/SearchVendorAutocomplete';
import showToast from '../../components/Toast';
import AppBarName from '../../components/DumbComponents/AppBarName';
import ErrorBoundary from '../../components/ErrorBoundary';
import ErrorBoundaryFallback from '../../components/ErrorBoundaryFallback';
import MobileFooter from '../MobileFooter';

const SubmitBasket = (props) => {
  const classes = useStyle();
  const token = useSelector((state) => state.user.token);
  const [query, setQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [items, setItems] = useState([]);
  const [price, setPrice] = useState('');
  const [loading, setLoading] = useState(false);
  const [isModal, setIsModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState([]);
  // const [clickedVendorFromList, setClickedVendorFromList] = useState([]);
  const [checked, setChecked] = useState(false);
  const [open, setOpen] = useState(false);

  function onChangeText(text) {
    console.log('yext', text);
    setQuery(text);
    if (text.length > 0) {
      //setLoading(true);
      //searchLocations(text);
      handler(text);
    } else {
      // setSearchResults([]);
      setLoading(false);
    }
  }
  const handler = useCallback(
    debounce(async (text) => {
      if (!text.trim()) {
        return;
      }
      try {
        const response = await searchVendor(token, text);
        console.log('response', response);
        if (response.successCode === 1) {
          const data = response.response.customerVendors;

          setSearchResults(data);
        } else {
          const data = [];
          setSearchResults(data);
        }
        //setLoading(false);
      } catch (error) {
        //setLoading(false);
      }
    }, 500),
    []
  );

  const handleClose = (value) => {
    setOpen(false);
  };
  const handleChange = (value) => {
    onChangeText(value);
  };
  const handleSelectionChange = (value) => {
    setSelectedItem([...selectedItem, ...value]);
  };

  const handleVendorClick = (clickedVendor) => {
    setOpen(!open);
    // setClickedVendorFromList({...clickedVendor});
  };

  const closeSubmitBasketDialogFunction = (value) => {
    setOpen(false);
  };
  const handleCloseSubmitBasketListItemClick = async () => {
    // const payload = {
    //   basketId: basketId,
    //   vendorId: item.vendorId,
    // };
    // try {
    //   const response = await placeBasketOrder(payload, token);
    //   console.log(response);
    //   if (response.successCode === 1) {
    //     // getList();
    //     showToast('success', 'Submitted');
    //     // navigation.pop(2);
    //   } else {
    //     showToast('error', response.returnMessage || 'Failed to submit');
    //   }
    // } catch (error) {
    //   console.log(error);
    //   showToast('error', 'Failed to submit');
    // }
  };

  return (
    <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
      <div className={classes.container}>
        <WebToolbar />
        <AppBarName title={'Select Vendor Name'} />
        <Box>
          <SearchVendorAutoComplete
            dropdownOptions={searchResults}
            placeholder="Search Vendors"
            onChange={handleChange}
            onSelectionChange={handleSelectionChange}
          />
          <SimpleDialog
            open={open}
            onSimpleDialogClose={closeSubmitBasketDialogFunction}
            message="Do you want to submit this basket to the selected vendor ?"
            handleBasketListItemClick={handleCloseSubmitBasketListItemClick}
          />
          {/* <Autocomplete
          id="free-solo-demo"
          freeSolo
          disableClearable={true}
          fullWidth
          size="small"
          options={
            searchResults ??
            searchResults.map((item) => {
              console.log('item', item);
              return item.organizationName
                ? lowerCase(item.organizationName)
                : 'NA';
            })
          }
          getOptionLabel={(searchResults) =>
            searchResults ? lowerCase(searchResults.organizationName) : 'NA'
          }
          onChange={(event, value) => {
            console.log('value', value.organizationName);
            const data = [];
            data.push(value.organizationName);
            setSelectedItem(data);
            //   setQuery(value.name);
            // setSelectedItem((oldArray) => [...oldArray, value]);
          }}
          renderInput={(searchItem) => (
            <TextField
              className={classes.ordersText}
              {...searchItem}
              //   label="Search Items"
              onChange={(search) => {
                onChangeText(search.target.value);
              }}
              placeholder="Search For Vendors"
              variant="outlined"
              InputProps={{
                ...searchItem.InputProps,
                startAdornment: (
                  <>
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                    {searchItem.InputProps.startAdornment}
                  </>
                ),
              }}
            />
          )}
        /> */}
        </Box>
        <Box my={2} p={2} boxShadow={3} style={{cursor: 'pointer'}}>
          {selectedItem.length > 0 ? (
            selectedItem.map((itm, index) => {
              return (
                <Box
                  style={{display: 'flex'}}
                  key={index}
                  onClick={() => handleVendorClick(itm)}
                >
                  <Typography style={{marginRight: '1rem'}}>
                    {get(itm, 'vendorId', 'NA')}
                  </Typography>
                  <Typography>{get(itm, 'organizationName', 'NA')}</Typography>
                </Box>
              );
            })
          ) : (
            <Box p={2}>
              <Typography>No Vendor selected</Typography>
            </Box>
          )}
        </Box>
      </div>
      <MobileFooter />
    </ErrorBoundary>
  );
};

export default SubmitBasket;
