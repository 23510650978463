/* eslint-disable react/prop-types */
import {Box, Divider, Grid, Typography} from '@material-ui/core';
import React, {useEffect, useState} from 'react';
import WebToolbar from '../../components/toolbar/WebToolbar';
import useStyle from './style';
import WatchLaterIcon from '@material-ui/icons/WatchLater';
import {useHistory} from 'react-router';
import AppBarName from '../../components/DumbComponents/AppBarName';
import SideNav from '../../components/sidenav/SideNav';
import {setOrderId} from '../../redux/actions/user';
import {useDispatch} from 'react-redux';
import MobileFooter from '../MobileFooter';

const ViewAppointmentsScreen = (props) => {
  //   const {appointments} = props.location.state;
  const appointments = props.location.state.appointments || [];
  //   [
  //   {
  //     id: 0,
  //     orderId: 1,
  //     specializationId: 0,
  //     serviceFrom: 0,
  //     timeSlot: '15:00-16:00',
  //     serviceDate: '2020-07-12T00:00:00',
  //     requestFor: 'Rajesh Kumar',
  //     token: null,
  //     requestedBy: 'Y C',
  //     workingHourId: 0,
  //   },
  // ]
  const classes = useStyle();
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    console.log('props', props);
  }, []);

  function onPressItem(item) {
    console.log('ite,', item);
    dispatch(setOrderId({orderId: item.orderId}));
    history.push('/orderdetails');
    // history.push('orderdetails', {item, isVendorOrder: true});
  }

  return (
    <>
      <div className={classes.container}>
        <WebToolbar />
        <div className={classes.sideNav}>
          <SideNav />
        </div>
        <AppBarName title="View Appointments" />
        <Box py={2}>
          {appointments.length > 0 ? (
            appointments.map((item, index) => {
              return (
                <Box key={index} onClick={() => onPressItem(item)}>
                  <Grid container xs={12} className={classes.card}>
                    <Grid item xs={7}>
                      <Typography
                        style={{flex: 1}}
                      >{`${item.requestedBy}`}</Typography>
                    </Grid>
                    <Grid item xs={1}>
                      <WatchLaterIcon size={20} />
                    </Grid>
                    <Grid item xs={4}>
                      <Typography>{`${item.timeSlot}`}</Typography>
                    </Grid>
                  </Grid>
                  <Divider style={{margin: '2px'}} />
                </Box>
              );
            })
          ) : (
            <Box>
              <Typography>No Appointment found.</Typography>
            </Box>
          )}
        </Box>
      </div>
      <MobileFooter />
    </>
  );
};

export default ViewAppointmentsScreen;
