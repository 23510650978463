/* eslint-disable react/jsx-key */
/* eslint-disable react/prop-types */
import {Box, Fab, makeStyles, TextField} from '@material-ui/core';
import React from 'react';
import AttachmentIcon from '@material-ui/icons/Attachment';
import SendIcon from '@material-ui/icons/Send';
import CameraAltOutlinedIcon from '@material-ui/icons/CameraAltOutlined';

const styles = makeStyles((theme) => ({
  containerBottom: {
    display: 'flex',
    width: '100%',
    padding: '0.5%',
    backgroundColor: 'white',
  },
  containerBottomDisabled: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#F8F8F8',
  },
  textInputMsg: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  btnTextInput: {
    width: '15%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-evenly',
  },
  fabContainer: {
    width: '8%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  input: {
    display: 'none',
  },
  cameraIp: {display: 'none'},
}));

const MessageInput = ({
  message,
  setMessage,
  placeholder,
  onPressFab,
  loading,
  onPhoto,
  onDocument,
  disabled,
}) => {
  const classes = styles();
  function captureImage() {
    onPhoto();
  }

  async function selectDoc(e) {
    console.log('e selected fie', e);
    try {
      onDocument(e.target.files[0]);
      // if (e.target.files.length > 0) {
      //   setSelectedUploadImage(
      //     window.URL.createObjectURL(e.target.files[0])
      //   );
      // }
      // onDocument({
      //   path: window.URL.createObjectURL(e.target.files[0]).replace(
      //     'blob:',
      //     ''
      //   ),
      //   name: e.target.files[0].name,
      //   mime: e.target.files[0].type,
      // });
    } catch (err) {
      console.log('error', err);
    }
  }

  return (
    <Box
      className={
        disabled ? classes.containerBottomDisabled : classes.containerBottom
      }
    >
      <Box className={classes.textInputMsg}>
        <TextField
          variant="outlined"
          size="small"
          fullWidth
          inputProps={{maxLength: 200}}
          value={message}
          onKeyDown={(e) => {
            if (e.keyCode == 13) {
              onPressFab();
            }
          }}
          onChange={(text) => {
            setMessage(text.target.value);
          }}
          placeholder={placeholder}
        />
      </Box>

      {!message && (
        <Box className={classes.btnTextInput}>
          <Box
            disabled={disabled}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: '100%',
            }}
          >
            <input
              accept="image/*,.pdf"
              className={classes.input}
              id="contained-button-file"
              multiple
              type="file"
              onChange={(e) => {
                selectDoc(e);
              }}
            />
            <label htmlFor="contained-button-file">
              <AttachmentIcon
                style={{
                  cursor: 'pointer',
                  marginTop: '3px',
                }}
              />
            </label>
          </Box>
          <Box
            style={{
              display: 'flex',
              cursor: 'pointer',
              height: '100%',
              justifyContent: 'center',
              alignItems: 'center',
            }}
            disabled={disabled}
            onClick={() => captureImage()}
          >
            <CameraAltOutlinedIcon style={{cursor: 'pointer'}} />
          </Box>
        </Box>
      )}

      <Box className={classes.fabContainer}>
        <Fab
          style={{transform: 'scale(0.8)'}}
          size="small"
          disabled={loading || disabled}
          onClick={() => onPressFab()}
        >
          <SendIcon />
        </Fab>
      </Box>
    </Box>
  );
};

export default MessageInput;
