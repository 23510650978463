import React, {useState, useEffect} from 'react';
import MobileHome from '../home/MobileHome';
import WebHome from '../home/webHome';

function Home() {
  const [width, setWidth] = useState(window.innerWidth);
  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);
  return width <= 768 ? <MobileHome /> : <WebHome />;
}

export default Home;
