import baseUrl from '../utils/Constants';

export default async function (payload, token) {
  console.log('payload', payload);
  const response = await fetch(`${baseUrl}/users/uploadprofilepicture`, {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    body: payload,
  });
  const responseJson = await response.json();
  return responseJson;
}
