import baseUrl from '../utils/Constants';

export default async function (token, customerId) {
  console.log('token, customerId', token, customerId);
  const response = await fetch(
    `${baseUrl}/vendors/customers/${customerId}/orders`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }
  );
  const responseJson = await response.json();
  return responseJson;
}
