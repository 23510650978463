import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
  sideNav: {
    //height: '100%',
    width: '92px',
    position: 'fixed',
    zIndex: 1,
    top: 0,
    right: 0,
    overflowX: 'hidden',
    marginTop: 140,
    marginRight: theme.spacing(1),
    backgroundColor: '#3B3B3B',
    elevation: 2,
  },
  main: {},
  title: {
    fontSize: '20px',
    fontWeight: '900',
  },
  content: {
    // padding: theme.spacing(2),
  },
  container: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    width: '80%',
    margin: 'auto',
    paddingBottom: '100px',
    backgroundColor: 'white',
    '& > header > header': {
      position: 'inherit',
      // display: 'flex',
      // justifyContent: 'center',
      // width: '80%',
    },
  },
}));

export default useStyles;
