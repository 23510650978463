import React from 'react';
import {Typography, Dialog, DialogContent, Box, Link} from '@material-ui/core';
import PropTypes from 'prop-types';
import {
  FacebookShareButton,
  FacebookIcon,
  WhatsappShareButton,
  WhatsappIcon,
  EmailShareButton,
  EmailIcon,
  TelegramShareButton,
  TelegramIcon,
  LinkedinIcon,
  LinkedinShareButton,
  TwitterShareButton,
  TwitterIcon,
} from 'react-share';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import {withStyles} from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';

const DIALOG_TITLE = 'Share';
const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(0.5),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});
const DialogTitle = withStyles(styles)((props) => {
  const {children, classes, onClose, ...other} = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const SimpleDialog = ({open, onSimpleDialogClose, data, message}) => {
  console.log(message);
  return (
    <Dialog
      onClose={onSimpleDialogClose}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <DialogTitle id="customized-dialog-title" onClose={onSimpleDialogClose}>
        {DIALOG_TITLE}
      </DialogTitle>
      <DialogContent>
        <Link>{message}</Link>
      </DialogContent>
      <Box
        style={{
          display: 'flex',
          padding: '10px',
          justifyContent: 'space-evenly',
        }}
      >
        <EmailShareButton
          url={`Hey! I am using GearConnect, an app to facilitate direct communication between Buyers and Sellers. Check this out https://www.gearten.in/vendors?vendorId=${data.vendorId}`}
        >
          <EmailIcon size={40} round={true} />
        </EmailShareButton>
        <FacebookShareButton
          url={`Hey! I am using GearConnect, an app to facilitate direct communication between Buyers and Sellers. Check this out https://www.gearten.in/vendors?vendorId=${data.vendorId}`}
          hashtag={'#portfolio...'}
        >
          <FacebookIcon size={40} round={true} />
        </FacebookShareButton>

        <WhatsappShareButton url={message} hashtag={'#portfolio...'}>
          <WhatsappIcon size={40} round={true} />
        </WhatsappShareButton>
        <TelegramShareButton
          url={`Hey! I am using GearConnect, an app to facilitate direct communication between Buyers and Sellers. Check this out https://www.gearten.in/vendors?vendorId=${data.vendorId}`}
          hashtag={'#portfolio...'}
        >
          <TelegramIcon size={40} round={true} />
        </TelegramShareButton>
        <LinkedinShareButton
          url={`Hey! I am using GearConnect, an app to facilitate direct communication between Buyers and Sellers. Check this out https://www.gearten.in/vendors?vendorId=${data.vendorId}`}
          hashtag={'#portfolio...'}
        >
          <LinkedinIcon size={40} round={true} />
        </LinkedinShareButton>
        <TwitterShareButton
          url={`Hey! I am using GearConnect, an app to facilitate direct communication between Buyers and Sellers. Check this out https://www.gearten.in/vendors?vendorId=${data.vendorId}`}
          hashtag={'#portfolio...'}
        >
          <TwitterIcon size={40} round={true} />
        </TwitterShareButton>
      </Box>
    </Dialog>
  );
};

SimpleDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onSimpleDialogClose: PropTypes.func.isRequired,
  data: PropTypes.any.isRequired,
  message: PropTypes.any.isRequired,
};

export default SimpleDialog;
