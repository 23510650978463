import {makeStyles} from '@material-ui/styles';

const useStyle = makeStyles((theme) => ({
  container: {
    border: '1px solid gray',
  },

  menu: {
    color: 'blue',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 10,
    paddingBottom: 10,
  },

  name: {
    color: 'white',
    fontSize: '13px',
  },
}));

export default useStyle;
