import baseUrl from '../utils/Constants';

export default async function (token, query, vendorId) {
  const response = await fetch(`${baseUrl}/vendors/orders?param=${query}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });
  const responseJson = await response.json();
  return responseJson;
}
