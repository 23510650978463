import {Box, Typography, Grid, Divider} from '@material-ui/core';
import React, {useEffect, useState} from 'react';
import useStyle from './style';
import ActivityIndicator from '../../components/ActivityIndicator';
import {useSelector, useDispatch} from 'react-redux';
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';
import RemoveCircleOutlineOutlinedIcon from '@material-ui/icons/RemoveCircleOutlineOutlined';
import DeleteIcon from '@material-ui/icons/Delete';
import {s3BaseUrl} from '../../utils/Constants';
import getFavouriteVendors from '../../services/getFavouriteVendors';
import removeFavouriteVendors from '../../services/removeFavouriteVendors';
import {useHistory} from 'react-router';
import {
  setSelectedCartItems,
  setSelectedVendorForOrder,
} from '../../redux/actions/user';
import showToast from '../../components/Toast';
import WebToolbar from '../../components/toolbar/WebToolbar';
import AppBarName from '../../components/DumbComponents/AppBarName';
import SideNav from '../../components/sidenav/SideNav';
import {get} from 'lodash';
import ErrorBoundary from '../../components/ErrorBoundary';
import ErrorBoundaryFallback from '../../components/ErrorBoundaryFallback';
import MobileFooter from '../MobileFooter';

const FavouriteVendors = () => {
  const classes = useStyle();
  const history = useHistory();
  const dispatch = useDispatch();
  const [vendors, setVendors] = useState([]);
  const token = useSelector((state) => state.user.token);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getData();
  }, []);

  async function getData() {
    console.log('getData');
    try {
      const response = await getFavouriteVendors(token);
      console.log('favourite vendors', response);
      if (response.data.successCode === 1) {
        setVendors(response.data.response.favouriteVendors);
        setLoading(false);
      } else {
        showToast('error', response.data.returnMessage);
        // history.goBack();
        setVendors([]);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  }

  function onPress(i) {
    // navigation.navigate('PlaceOrder', {vendor: i, savedOrder: null});
    dispatch(setSelectedVendorForOrder({vendor: i}));
    dispatch(setSelectedCartItems([]));
    history.push('/placeorder');
  }

  async function onPressRemove(i) {
    try {
      const response = await removeFavouriteVendors(i.vendorId, token);
      console.log('removeFavouriteVendors', response);
      if (response.successCode === 1) {
        showToast('success', 'Removed');
        setLoading(true);
        getData();
      } else {
        showToast('error', 'Failed');
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  }

  return (
    <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
      <Box className={classes.container}>
        <WebToolbar />{' '}
        <div className={classes.sideNav}>
          <SideNav />
        </div>
        <AppBarName title={'Favourite Vendors'} />
        <Box>
          {loading ? (
            <ActivityIndicator size="large" />
          ) : (
            <Box p={2}>
              {console.log('512', vendors)}
              {vendors.length > 0 ? (
                vendors.map((item, index) => {
                  console.log('Vendors', item);
                  return (
                    <Box key={index}>
                      <Grid container>
                        <Grid item xs={2} className={classes.imgContainer}>
                          {get(item, 'vendorHeaderImage', '') ? (
                            <img
                              className={classes.imageVendor}
                              alt="NA"
                              src={`${s3BaseUrl}/${item.vendorHeaderImage}`}
                            />
                          ) : (
                            <AccountCircleOutlinedIcon
                              className={classes.iconStyle}
                            />
                          )}
                        </Grid>
                        <Grid
                          item
                          xs={8}
                          className={classes.dataContainer}
                          onClick={() => onPress(item)}
                        >
                          <Typography className={classes.textName}>
                            {get(item, 'organizationName', '')}
                          </Typography>
                        </Grid>
                        <Grid item xs={2} className={classes.btnContainer}>
                          <DeleteIcon
                            style={{cursor: 'pointer'}}
                            color="error"
                            onClick={() => onPressRemove(item)}
                          />
                        </Grid>
                      </Grid>
                      <Divider />
                    </Box>
                  );
                })
              ) : (
                <Box>
                  <Typography className={classes.textEmpty}>
                    No favourite vendors found.
                  </Typography>
                </Box>
              )}
            </Box>
          )}
        </Box>
      </Box>
      <MobileFooter />
    </ErrorBoundary>
  );
};

export default FavouriteVendors;
