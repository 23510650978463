import {makeStyles} from '@material-ui/core';
import Colors from '../../theme/Colors';

const useStyle = makeStyles((theme) => ({
  container: {
    width: '80%',
    height: '600px',
    paddingBottom: '100px',
    backgroundColor: 'white',
    position: 'relative',
    margin: 'auto',
    '& > header > header': {
      position: 'inherit',
      // display: 'flex',
      // justifyContent: 'center',
      // width: '80%',
    },
  },
  sideNav: {
    ['@media (max-width:700px)']: {
      display: 'none',
    },
    //height: '100%',
    width: '92px',
    position: 'fixed',
    zIndex: 1,
    top: 0,
    // right: 0,
    right: '15px',
    overflowX: 'hidden',
    marginTop: 190,
    // marginRight: theme.spacing(1),
    marginRight: 0,
    backgroundColor: '#3B3B3B',
    elevation: 2,
  },
  listItems: {
    flex: 1,
    padding: 5,
    paddingTop: 10,
  },
  btn: {},
  flex1: {
    display: 'flex',
  },
  btnAdd: {},
  card: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    borderRadius: 5,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  textBtn: {},
  textNo: {
    width: 60,
  },
  textName: {
    fontSize: 16,
    flex: 1,
  },
  textPrice: {
    fontSize: 16,
  },
  textQty: {
    fontSize: 16,
  },
  textTotal: {
    fontSize: 18,
    fontWeight: 'bold',
  },
}));

export default useStyle;
