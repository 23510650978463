import useStyles from './styles';
import React, {useState, useEffect} from 'react';
import {Helmet} from 'react-helmet';
import {toast} from 'react-toastify';
import {
  Button,
  CircularProgress,
  TextField,
  FormControlLabel,
  Checkbox,
  Link,
  Paper,
  IconButton,
  InputAdornment,
  Grid,
  Typography,
  Hidden,
  Box,
  List,
  ListItem,
} from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import intro from '../../assets/images/intro.png';
import intro1 from '../../assets/images/gc_login.png';
import logo from '../../assets/icons/logo.png';
import GetTheApp from '../../components/GetTheApp';
import {useHistory} from 'react-router-dom';
import {useSelector, useDispatch} from 'react-redux';
import loginService from '../../services/login';
import RHFTextInput from '../../components/DumbComponents/RHFTextInput';
import {
  setUser,
  setLoggedIn,
  setToken,
  setProfilePic,
  setVendorId,
  setEmail,
  setPassword,
  setPincode,
} from '../../redux/actions/user';
import {useTranslation} from 'react-i18next';
import showToast from '../../components/Toast';
import {GoogleLogin, GoogleLogout} from 'react-google-login';
import {Controller, useForm} from 'react-hook-form';
import {emailRules, passwordRules, numberRules} from './utils';
import ErrorBoundary from '../../components/ErrorBoundary';
import ErrorBoundaryFallback from '../../components/ErrorBoundaryFallback';

const CopyRight = () => (
  <Typography variant="body2" color="textSecondary" align="center">
    {`© ${new Date().getFullYear()}  `}
    <Link
      target="_blank"
      underline="always"
      color="inherit"
      href="https://gearten.in/"
    >
      Gearten Technologies Pvt Ltd
    </Link>
  </Typography>
);

function Login() {
  const {t} = useTranslation(['loginScreen', 'common']);
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();
  const isLoggedIn = useSelector((state) => state.user.isLoggedIn);
  const isTrue = useSelector((state) => state.user.isTrue);
  const [loading, setLoading] = useState(false);
  // const email = useSelector((state) => state.user.email);
  // const password = useSelector((state) => state.user.password);
  const [rememberMe, setRememberMe] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const {register, handleSubmit, control, setValue, watch} = useForm({
    defaultValues: {
      email: '',
      password: '',
    },
  });

  useEffect(() => {
    const remMe = localStorage.getItem('rememberMe');
    if (remMe === 'true') {
      const emailLS = localStorage.getItem('email');
      setRememberMe(remMe);
      setValue('email', emailLS);
    }
  }, []);

  async function login(data) {
    if (rememberMe) {
      localStorage.setItem('rememberMe', rememberMe);
      localStorage.setItem('email', data.email);
    } else {
      localStorage.setItem('rememberMe', false);
      localStorage.setItem('email', '');
    }
    // event.preventDefault();
    // if (!email.trim() || !password.trim()) {
    //   showToast('error', 'Please enter email/phone and password');
    //   return;
    // }
    setLoading(true);
    setShowPassword(false);
    const fcmToken = 'fcmToken';
    const deviceId = 'deviceId';
    const payload = {
      userName: data.email,
      password: data.password,
      deviceId: deviceId,
      fcmToken: fcmToken,
    };
    try {
      const response = await loginService(payload, fcmToken, deviceId, 25);
      console.log('login response 1', response, response.messageCode, history);
      if (response.successCode === 1) {
        dispatch(setUser(response.response));
        dispatch(setToken(response.response.token));
        dispatch(setProfilePic(response.response.profilePicture));
        if (response.response.vendor) {
          dispatch(setVendorId(response.response.vendor.vendorId));
        }
        dispatch(setLoggedIn(true));
        dispatch(setPincode(response.response.pincode));
        history.replace('/home');
      } else if (response.messageCode === '400300124') {
        history.push({pathname: '/otp', state: {userName: data.email}});
      } else {
        showToast('error', 'Invalid Credentials');
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  }

  const responseGoogle = (response) => {
    console.log('social login response', response);
  };

  const logout = (response) => {};

  const handleClickShowPassword = () => setShowPassword(!showPassword);

  const handleRememberMe = (e) => {
    setRememberMe(e.target.checked);
  };

  return (
    <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
      <div>
        <Helmet>
          <title>Login - GearConnect</title>
          <meta name="title" content="GearConnect Login" />
        </Helmet>
        <Grid container component="main" className={classes.main}>
          <Hidden only="xs">
            <Grid
              className={classes.containerLeft}
              item
              xs={false}
              sm={4}
              md={7}
            >
              <Typography className={classes.textWelcome} variant="h4">
                Welcome to GearConnect !!!
              </Typography>
              <img draggable="false" src={intro1} height={200}></img>
              <Box
                py={1}
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <Typography variant="h5">
                  Gearing up for Digital Economy
                </Typography>
                <Typography
                  style={{
                    flexGrow: 1,
                    textAlign: 'center',
                    letterSpacing: '1px',
                  }}
                >
                  Made in India app, specially designed for Indian
                </Typography>
                <Typography
                  style={{
                    flexGrow: 1,
                    textAlign: 'center',
                    letterSpacing: '1px',
                  }}
                >
                  style of communication between Buyers and Sellers.
                </Typography>
                <ul>
                  <li>Direct communication</li>
                  <li>Order request through chat, photos & attachments</li>
                  <li>User groups</li>
                  <li>Payment request</li>
                </ul>
              </Box>

              <Button
                variant="outlined"
                type="button"
                size="medium"
                className={classes.buttonGetStarted}
                onClick={(e) => {
                  e.preventDefault();
                  history.push('/accounts/new');
                }}
              >
                Get Started
              </Button>
              <div style={{marginTop: 5}}>
                <GetTheApp />
                <CopyRight />
              </div>
            </Grid>
          </Hidden>

          <Grid
            item
            xs={12}
            sm={8}
            md={5}
            className={classes.containerRight}
            component={Paper}
            elevation={4}
            square
          >
            <img
              draggable="false"
              height={70}
              width={70}
              alt="logo"
              src={logo}
            />

            <Typography component="h1" variant="h4">
              Sign In
            </Typography>

            <form
              className={classes.form}
              onSubmit={handleSubmit(login)}
              noValidate
            >
              <Grid container direction={'column'} spacing={1}>
                <Grid item>
                  <RHFTextInput
                    name="email"
                    label="Email or Phone Number"
                    control={control}
                    type="text"
                    rules={emailRules}
                    showPasswordIcon={false}
                    // showPassword={showConfirmPassword}
                    // handleClickShowPassword={handleClickShowConfirmPassword}
                  />
                </Grid>

                {/* <TextField
              type="email"
              placeholder="Email"
              label={t('email')}
              fullWidth
              name="username"
              variant="outlined"
              inputProps={{maxLength: 50}}
              value={email}
              onChange={(event) => dispatch(setEmail(event.target.value))}
              required
              autoFocus
              multiline={false}
              disabled={loading}
              className={classes.input_email}
              helperText=""
            /> */}
                <Grid item>
                  <RHFTextInput
                    name="password"
                    label="Password"
                    control={control}
                    type={showPassword ? 'text' : 'password'}
                    rules={passwordRules}
                    showPasswordIcon={true}
                    showPassword={showPassword}
                    handleClickShowPassword={handleClickShowPassword}
                  />
                </Grid>
              </Grid>

              {/* <TextField
              type={showPassword ? 'text' : 'password'}
              placeholder="Password"
              label="Password"
              fullWidth
              name="password"
              variant="outlined"
              value={password}
              multiline={false}
              onChange={(event) => dispatch(setPassword(event.target.value))}
              required
              disabled={loading}
              className={classes.input_password}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      disabled={loading}
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            /> */}

              <Grid container className={classes.rememberMeSection}>
                <Grid item xs>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={rememberMe}
                        color="primary"
                        disabled={loading}
                        onChange={(e) => {
                          handleRememberMe(e);
                        }}
                      />
                    }
                    label="Remember me"
                  />
                </Grid>
                <Grid item>
                  <Link
                    color="secondary"
                    style={{cursor: 'pointer'}}
                    // href="/login/resetpassword"
                    variant="body2"
                    onClick={() => {
                      history.push('/resetpasswordscreen');
                    }}
                  >
                    Forgot password?
                  </Link>
                </Grid>
              </Grid>

              <Button
                variant="outlined"
                type="submit"
                disabled={loading}
                className={classes.button}
              >
                {loading ? (
                  <CircularProgress size={24} />
                ) : (
                  <Typography>Login</Typography>
                )}
              </Button>

              {/* <GoogleLogin
              clientId=""
              buttonText="Login"
              onSuccess={responseGoogle}
              onFailure={responseGoogle}
              cookiePolicy={'single_host_origin'}
            />
            <GoogleLogout
              clientId=""
              buttonText="Logout"
              onLogoutSuccess={logout}
            /> */}

              <Button
                variant="outlined"
                type="button"
                disabled={loading}
                className={classes.button}
                onClick={(e) => {
                  e.preventDefault();
                  console.log('signUp');
                  history.push('/accounts/new');
                }}
              >
                <Typography>{`New to GearConnect? Sign Up`}</Typography>
              </Button>
            </form>
            <Hidden mdUp smUp>
              <GetTheApp />
              <CopyRight />
            </Hidden>
          </Grid>
        </Grid>
      </div>
    </ErrorBoundary>
  );
}

export default Login;
