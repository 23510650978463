import {makeStyles} from '@material-ui/styles';
import Colors from '../../theme/Colors';

const useStyle = makeStyles((theme) => ({
  container: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    backgroundColor: 'white',
    // padding: 10,
    flex: 1,
    width: '80%',
    margin: 'auto',
    '& > header > header': {
      position: 'inherit',
      // display: 'flex',
      // justifyContent: 'center',
      // width: '80%',
    },
  },
  sideNav: {
    ['@media (max-width:700px)']: {
      display: 'none',
    },
    //height: '100%',
    width: '92px',
    position: 'fixed',
    zIndex: 1,
    top: 0,
    // right: 0,
    right: '15px',
    overflowX: 'hidden',
    marginTop: 190,
    // marginRight: theme.spacing(1),
    marginRight: 0,
    backgroundColor: '#3B3B3B',
    elevation: 2,
  },
  textInput: {
    backgroundColor: 'white',
    flex: 1,
  },
  textInputContainer: {
    display: 'flex',
    flexDirection: 'row',
    // alignItems: 'center',
  },
  textCountryCode: {
    margin: '5px',
    fontSize: 18,
    fontWeight: '500',
  },
  iconPassword: {
    position: 'absolute',
    right: 0,
    marginEnd: 12,
    zIndex: 5,
  },
  textInputTitle: {
    fontSize: 16,
    color: 'gray',
    marginTop: 10,
    fontWeight: 'bold',
  },
  NumIpContainer: {
    border: 'solid green',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  btnContainer: {display: 'flex', justifyContent: 'center'},
  btn: {
    backgroundColor: Colors.secondary,
    height: 48,
    borderRadius: 5,
    justifyContent: 'center',
    alignItems: 'center',
    elevation: 2,
    shadowColor: Colors.primary,
    shadowOffset: {height: 0, width: 0},
    alignSelf: 'center',
    marginTop: 30,
    width: '100%',
    marginBottom: 50,
  },
}));

export default useStyle;
