import React from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import googlePlay from '../assets/images/googleplay.png';
import appstore from '../assets/images/appstore.png';

const GetTheApp = () => (
  <div>
    <Typography variant="body2" color="textSecondary" align="center">
      {`Get the App`}
    </Typography>
    <Box
      style={{
        marginTop: 10,
        marginBottom: 20,
        display: 'flex',
        justifyContent: 'center',
      }}
      variant="elevation"
    >
      {/* <img draggable="false" height={34} src={appstore} /> */}
      <a
        style={{marginLeft: 5}}
        href="https://play.google.com/store/apps/details?id=in.gearten.gc"
      >
        <img draggable="false" height={34} src={googlePlay} />
      </a>
    </Box>
  </div>
);

export default GetTheApp;
