import {makeStyles} from '@material-ui/core/styles';

const useStyle = makeStyles((theme) => ({
  container: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    paddingBottom: '100px',
    backgroundColor: 'white',
    height: '586px',
    width: '80%',
    margin: 'auto',
    '& > header > header': {
      position: 'inherit',
    },
  },
  buttonWrapper: {
    display: 'flex',
    justifyContent: 'space-evenly',
    padding: '15px',
    ['@media (max-width:450px)']: {
      display: 'inline-grid',
      width: '100%',
    },
  },
  submitButton: {
    borderRadius: '25px',
    padding: '5px 15px',
    minWidth: '12rem',
    fontSize: '14px',
    ['@media (max-width:450px)']: {
      margin: '5px',
    },
  },
  sideNav: {
    ['@media (max-width:700px)']: {
      display: 'none',
    },
    //height: '100%',
    width: '92px',
    position: 'fixed',
    zIndex: 1,
    top: 0,
    right: 0,
    overflowX: 'hidden',
    marginTop: 190,
    // marginRight: theme.spacing(1),
    marginRight: 0,
    backgroundColor: '#3B3B3B',
    elevation: 2,
  },
}));

export default useStyle;
