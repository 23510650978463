import baseUrl from '../utils/Constants';
// `https://api.gearten.in/api/usergroups/baskets/orders?basketId=${basketId}`,
// `${baseUrl}/usergroups/baskets/orders?basketId=${basketId}`,

export default async function (token, basketId) {
  try {
    const response = await fetch(
      `${baseUrl}/orders/cart`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    );

    const responseJson = await response.json();
    return responseJson;
  } catch (error) {
    console.log(error);
  }
}
