import baseUrl from '../utils/Constants';

export default async function (token, categoryId, text) {
  console.log(' categoryId', categoryId, 'text', text);
  const response = await fetch(
    `${baseUrl}/customers/vendors?category=${categoryId}&vendorName=${text}`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }
  );
  const responseJson = await response.json();
  return responseJson;
}
