/* eslint-disable react/prop-types */
import React, {Component, useEffect, useState} from 'react';
import Colors from '../theme/Colors';
import moment from 'moment';
import {makeStyles} from '@material-ui/styles';
import {Badge, Box, Tooltip, Typography} from '@material-ui/core';
import showToast from './Toast';
import {useHistory} from 'react-router';
import {get} from 'lodash';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    padding: '1%',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  containerWeek: {
    marginBottom: 4,
    marginTop: 4,
    flex: 1,
    height: 20,
  },
  textWeek: {
    flex: 1,
    textAlign: 'center',
    fontSize: 16,
    color: 'black',
  },
  textDay: {
    flex: 1,
    fontSize: 16,
    textAlign: 'center',
    alignSelf: 'center',
    marginTop: 7,
  },
  btnText: {
    display: 'flex',
    height: 35,
    width: 35,
    borderRadius: 20,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: Colors.secondary,
    marginHorizontal: 2,
    marginVertical: 2,
  },
}));

const MonthView = (props) => {
  const [today, setToday] = useState(moment());
  const [_rows, set_Rows] = useState([]);
  const styles = useStyles();
  const history = useHistory();
  useEffect(() => {
    abc();
  }, [props.data]);

  console.log('propsss', props.data);

  const getDayColor = (item) => {
    if (item.item.length > 0) {
      return Colors.secondary;
    }
    if (item.date) {
      if (today.isSame(item.date, 'day')) {
        return 'gray';
      }
    }
    return 'white';
  };

  const getTextColor = (item) => {
    if (item.item.length > 0) {
      return 'white';
    }
    if (item.date) {
      if (today.isSame(item.date, 'day')) {
        return 'white';
      }
    }
    return 'black';
  };
  function onPressItem(item) {
    console.log('item on click', item);
    if (item.item.length > 0) {
      history.push({
        pathname: '/viewappointmentsscreen',
        state: {appointments: item.item},
      });
      //   navigation.navigate('ViewAppointments', {appointments: item.item});
    } else {
      showToast(
        'warn',
        `No appointments for ${moment(item.date).format('Do MMM YYYY')}`
      );
    }
  }

  const abc = () => {
    let rows = [];
    rows = props.data.map((row, rowIndex) => {
      console.log('row', row, 'rowIndex', rowIndex);
      const rowItems = row.map((item, index) => {
        return rowIndex === 0 ? (
          <Box className={styles.containerWeek}>
            <Typography className={styles.textWeek}>
              {item.day ? item.day : ''}
            </Typography>
          </Box>
        ) : (
          <Box
            // className={[styles.btnText, {backgroundColor: getDayColor(item)}]}
            className={styles.btnText}
            style={{backgroundColor: getDayColor(item)}}
            disabled={!item.item}
            onClick={() => onPressItem(item)}
          >
            {/* <Tooltip
              title={
                item.item.length > 0 ? item.item.length + ' Appointment' : ''
              }
            >
              <Typography
                className={styles.textDay}
                style={{color: getTextColor(item)}}
                // className={[styles.textDay, {color: getTextColor(item)}]}
              >
                {get(item, 'day', '')}
              </Typography>
            </Tooltip> */}
            <Badge
              showZero={false}
              badgeContent={item.item.length}
              max={9}
              color="error"
            >
              <Typography
                className={styles.textDay}
                style={{color: getTextColor(item)}}
                // className={[styles.textDay, {color: getTextColor(item)}]}
              >
                {get(item, 'day', '')}
              </Typography>
            </Badge>
          </Box>
        );
      });
      return (
        <Box key={rowIndex} className={styles.container}>
          {rowItems}
        </Box>
      );
    });
    set_Rows(rows);
  };

  return <Box>{_rows}</Box>;
};

export default MonthView;
