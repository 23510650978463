import {makeStyles} from '@material-ui/core';

const useStyle = makeStyles(() => ({
  deleteIcon: {fontSize: 25},
  avatarContaier: {
    height: '30px',
    width: '30px',
    cursor: 'pointer',
    color: 'white',
    backgroundColor: 'red',
  },
}));

export default useStyle;
