import React, {useEffect, useState} from 'react';
import {
  Box,
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Switch,
  TextField,
  Typography,
} from '@material-ui/core';
import {get} from 'lodash';
import {useHistory} from 'react-router';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import getCurrentVendor from '../../services/getCurrentVendor';
import getDeliveryVendors from '../../services/getDeliveryVendors';

import ActivityIndicator from '../../components/ActivityIndicator';
import showToast from '../../components/Toast';
import AddOutlinedIcon from '@material-ui/icons/AddOutlined';
import NotFound from '../NotFound';
import ErrorBoundary from '../../components/ErrorBoundary';
import Colors from '../../theme/Colors';
import ErrorBoundaryFallback from '../../components/ErrorBoundaryFallback';
import useStyle from './style';

const EditDeliveryOptionsScreen = () => {
  const classes = useStyle();
  const history = useHistory();
  const {t} = useTranslation('becomeVendor');
  const user = useSelector((state) => state.user.user);
  const token = useSelector((state) => state.user.token);
  const [requesting, setRequesting] = useState(true);
  const [loading, setLoading] = useState(true);
  const [isHomeDelivery, setIsHomeDelivery] = useState(true);
  const [details, setDetails] = useState({});
  const [homeDeliveryRemarks, setHomeDeliveryRemarks] = useState('');
  const [homeDeliveryRemarksHint, setHomeDeliveryRemarksHint] = useState('');
  const [deliveryPartners, setDeliveryPartners] = useState([]);
  const [value, setValue] = useState('0');

  useEffect(() => {
    getData();
  }, []);

  async function getData() {
    try {
      const response = await getCurrentVendor(token);
      console.log('currentVendor,deliveryV response', response);

      setLoading(false);
      if (response.successCode === 1) {
        console.log('in response');
        setIsHomeDelivery(response.response.provideHomeDelivery);
        setDetails(response.response);
        setHomeDeliveryRemarks(response.response.homeDeliveryRemarks);
        setHomeDeliveryRemarksHint(response.response.homeDeliveryRemarksHint);
        setDeliveryPartners(
          get(response, 'response.deliveryPartners', []) || []
        );
        setValue(
          response.response.deliveryBy ? `${response.response.deliveryBy}` : '0'
        );
      } else {
        history.goBack();
        showToast('error', response.returnMessage);
      }
    } catch (error) {
      setLoading(false);
    }
  }

  const handleRadioChange = (event) => {
    setValue(event.target.value);
  };

  const handleChange = (event) => {
    setIsHomeDelivery(event.target.checked);
  };

  return (
    <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
      <div>
        {loading ? (
          <ActivityIndicator
            style={{height: '100%'}}
            size="large"
            color={Colors.secondary}
          />
        ) : (
          <Box p={2}>
            <Grid container sx={12} className={classes.phdContainer}>
              <Grid xs={6}>
                <Typography>{`${t('provideHomeDelivery')}    ${
                  isHomeDelivery ? t('yes') : t('no')
                }`}</Typography>
              </Grid>
              <Grid xs={6}>
                <Switch
                  checked={isHomeDelivery}
                  onChange={handleChange}
                  color="primary"
                  name="checkedB"
                  inputProps={{'aria-label': 'primary checkbox'}}
                />
              </Grid>
            </Grid>
            <Box>
              {isHomeDelivery && (
                <Box>
                  <TextField
                    label={'Comments'}
                    placeholder={
                      homeDeliveryRemarksHint
                        ? homeDeliveryRemarksHint
                        : 'We provide home delivery under 5 kms'
                    }
                    fullWidth
                    size="small"
                    value={homeDeliveryRemarks}
                    onChange={(text) =>
                      setHomeDeliveryRemarks(text.target.value)
                    }
                    variant="outlined"
                    disabled={loading}
                    className={classes.textInput}
                  />
                  <Box py={2}>
                    <FormControl component="fieldset">
                      <RadioGroup
                        row
                        aria-label="gender"
                        name="gender1"
                        value={value}
                        onChange={handleRadioChange}
                      >
                        <FormControlLabel
                          value="0"
                          control={<Radio />}
                          label="Self"
                        />
                        <FormControlLabel
                          value="1"
                          control={<Radio />}
                          label="Through delivery partners"
                        />
                        <FormControlLabel
                          value="2"
                          control={<Radio />}
                          label="Both"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Box>
                  <Box className={classes.btnContainer}>
                    {value !== '0' && (
                      <Button
                        icon="plus"
                        variant="outlined"
                        uppercase={false}
                        color={Colors.primary}
                        className={[classes.btn]}
                        onClick={() =>
                          history.push({
                            pathname: 'adddeliverypartnerscreen',
                            state: {
                              ProvideHomeDelivery: isHomeDelivery,
                              HomeDeliveryRemarks: homeDeliveryRemarks,
                              DeliveryBy: Number(value),
                            },
                          })
                        }
                      >
                        <AddOutlinedIcon /> Add/Update Delivery Partner
                      </Button>
                    )}
                  </Box>
                </Box>
              )}
            </Box>
            <Box py={2}>
              {deliveryPartners.length > 0 ? (
                deliveryPartners.map((item, index) => {
                  return (
                    <>
                      <Box
                        key={index}
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                      >
                        <Typography>
                          {get(item, 'organizationName', 'NA')}
                        </Typography>
                        <Typography>
                          {get(
                            item,
                            'secondaryMobiles[0].secondaryMobile',
                            'NA'
                          )}
                        </Typography>
                      </Box>
                      <Divider />
                    </>
                  );
                })
              ) : (
                <NotFound />
              )}
            </Box>
          </Box>
        )}
      </div>
    </ErrorBoundary>
  );
};

export default EditDeliveryOptionsScreen;
