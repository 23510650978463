export default [
  // {
  //   group: 'Less than 18 years',
  //   id: -1,
  // },
  {
    group: '18 to 30 years',
    id: 1830,
  },
  {
    group: '31 to 44 years',
    id: 3144,
  },
  {
    group: '45 to 60 years',
    id: 4560,
  },
  {
    group: '61+ years',
    id: 6100,
  },
];
