import React, {useEffect, useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {Typography, Box, Divider, Grid} from '@material-ui/core';
import WebToolbar from '../../components/toolbar/WebToolbar';
import ActivityIndicator from '../../components/ActivityIndicator';
import AppBarName from '../../components/DumbComponents/AppBarName';
import {isEmpty} from 'lodash';
import {useHistory} from 'react-router-dom';
import moment from 'moment';
import useStyles from './styles';
import markReadNotification from '../../services/markReadNotification';
import {
  setNotificationData,
  setUnreadNotifications,
  setOrderId,
} from '../../redux/actions/user';
import SideNav from '../../components/sidenav/SideNav';
import ErrorBoundary from '../../components/ErrorBoundary';
import ErrorBoundaryFallback from '../../components/ErrorBoundaryFallback';
import InifiniteScrollComponent from '../../components/InifiniteScrollComponent';
import getNotification from '../../services/getNotification';
import MobileFooter from '../MobileFooter';

const Notifications = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [serverError, setServerError] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [lastOrderId, setLastOrderId] = useState(0);
  // const loading = useSelector((state) => state.user.loading);
  const token = useSelector((state) => state.user.token);
  // const notifications = useSelector((state) => {
  //   console.log('notifications', state.user.notificationData);
  //   return state.user.notificationData;
  // });
  const [openedNotification, setOpenedNotification] = useState(false);
  const unreadNotifications = useSelector(
    (state) => state.user.unreadNotifications
  );
  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  const getNotificationData = async (currPage, setLastList, setPrevPage) => {
    try {
      let _lastId = lastOrderId;
      if (notifications.length) {
        _lastId = notifications[notifications.length - 1]['id'];
      }
      const response = await getNotification(token, '0', _lastId);
      if (response.successCode === 1) {
        if (
          !response.response.notifications.length &&
          _lastId === lastOrderId
        ) {
          setLastList(true);
          return;
        }
        setLastOrderId(_lastId);
        setPrevPage(currPage);
        let newListData = [
          ...notifications,
          ...response.response.notifications,
        ];
        setNotifications(newListData);
        setLoading(false);
      } else if (response.messageCode === '401500100') {
        setLoading(false);
      } else {
        setServerError(true);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  const onPressItem = async (item, index) => {
    setOpenedNotification(!openedNotification);
    const _orderId = item.objectId;

    if (item.state === 0) {
      try {
        const response = await markReadNotification(token, item.id);
        if (response.successCode === 1) {
          const data = notifications;
          data[index].state = 1;
          dispatch(setNotificationData(data));
          if (unreadNotifications > 0) {
            dispatch(setUnreadNotifications(unreadNotifications - 1));
          }
        }
      } catch (error) {
        console.log(error);
      }
    }
    if (item.type === 2) {
      dispatch(setOrderId({orderId: _orderId}));
      history.push({
        pathname: '/orderDetails',
        // orderID: _orderId,
      });
    } else if (item.type === 3) {
      dispatch(setOrderId({orderId: _orderId}));
      history.push({
        pathname: '/orderDetails',
        // orderID: _orderId,
      });
    }
    // else if (item.type === 4) {
    //   history.push('GroupMessages', {
    //     item: {group: {id: item.objectId, name: ''}, isAdmin: false},
    //     getData: null,
    //   });
    // } else if (item.type === 11) {
    //   history.push('GroupMessages', {
    //     item: {group: {id: item.objectId, name: ''}, isAdmin: false},
    //     getData: null,
    //   });
    // } else if (item.type === 7) {
    //   history.push('GroupMessages', {
    //     item: {group: {id: item.objectId, name: ''}, isAdmin: false},
    //     getData: null,
    //   });
    // }
  };
  const notificationListItem = (item, index) => {
    const angle = ` ${
      item.state === 0
        ? classes.backgroundColorGray
        : classes.backgroundColorWhite
    }`;

    return (
      <Box key={index}>
        <Grid
          container
          className={angle}
          onClick={() => {
            onPressItem(item, index);
          }}
          style={{
            cursor: 'pointer',
            padding: '2px 10px',
          }}
          key={index}
        >
          <Grid item xs={7}>
            <Typography>{item.message}</Typography>
          </Grid>
          {/* ORDER_RECEIVED= 2, ORDER_UPDATED=3, ORDER_SAVED_IN_BASKET = 8 */}
          {[2, 3, 8].includes(item.type) ? (
            <Grid item xs={2}>
              <Typography>Order Id: {item.objectId}</Typography>
            </Grid>
          ) : (
            <></>
          )}
          <Grid item xs={3}>
            <Typography>
              {/* {item.timeStamp.replace('T', ' ')} */}
              {moment(item.timeStamp).format('D/MM/YYYY, hh:mm A')}
            </Typography>
          </Grid>
        </Grid>
        <Divider classes={{root: classes.divider}} />
      </Box>
    );
  };
  return (
    <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
      <div className={classes.container}>
        <WebToolbar />
        <AppBarName title={'Notifications'} />
        <Box className={classes.sideNav}>
          <SideNav />
        </Box>
        {serverError ? (
          <ErrorBoundaryFallback />
        ) : (
          <InifiniteScrollComponent
            loading={loading}
            height="80vh"
            listData={notifications}
            scrollListItem={notificationListItem}
            fetchRecords={getNotificationData}
            noDataMessage="No notifications found"
          />
        )}
        <MobileFooter />
      </div>
    </ErrorBoundary>
  );
};

export default Notifications;
