export const emailRules = {
  required: 'Email required',
  //   pattern: {
  //     value: /^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+.)+([a-zA-Z0-9]{2,4})|(^[0-9]{10})+$/,
  //     message: 'Please enter a valid input',
  //   },
};

export const numberRules = {
  required: 'phone number required',
  minLength: {
    value: 10,
    message: 'Minimum 10 characters required',
  },
  maxLength: {
    value: 12,
    message: 'Limit exceed',
  },
};

export const passwordRules = {
  required: {value: true, message: 'Password required'},
  minLength: {
    value: 6,
    message: 'Minimum 6 characters required',
  },
  maxLength: {
    value: 50,
    message: 'Limit exceed',
  },
};
