import {makeStyles} from '@material-ui/styles';
import Colors from '../../theme/Colors';

const useStyle = makeStyles((theme) => ({
  updateBtnContainer: {
    display: 'flex',
    justifyContent: 'center',
    padding: '20px',
  },
  isServiceTypeContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginHorizontal: 10,
    alignItems: 'center',
    marginTop: 10,
  },
  isOrgContainer: {padding: '5px', display: 'flex', alignItems: 'center'},
  //--------------------------------------------------------
  container: {
    backgroundColor: 'white',
  },
  textInput: {
    // backgroundColor: '#F5F5F5',
  },
  btn: {},
  card: {
    padding: 10,
    //elevation: 2,
    borderRadius: 10,
    paddingTop: 20,
    //marginTop: 20,
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  itemStyle: {
    flexDirection: 'row',
    padding: 5,
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  textGender: {
    fontSize: 18,
    color: 'black',
    marginRight: 20,
    // fontWeight: 'bold',
  },
  textMale: {
    fontSize: 16,
    color: 'black',
  },
  viewImg: {
    height: 100,
    width: 100,
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    marginBottom: 20,
  },
  image: {
    height: 100,
    width: 100,
    borderRadius: 50,
  },
  btnPlus: {
    position: 'absolute',
    top: 0,
    right: 0,
    backgroundColor: Colors.secondary,
    elevation: 2,
    height: 36,
    width: 36,
    borderRadius: 18,
    justifyContent: 'center',
    alignItems: 'center',
  },
  placeholder: {
    height: 100,
    width: 100,
    borderRadius: 50,
    backgroundColor: 'lightgray',
    justifyContent: 'center',
    alignItems: 'center',
  },
  textBtn: {
    color: 'white',
    fontSize: 24,
  },
  textTitle: {
    fontSize: 16,
    color: 'black',
  },
  containerPicker: {
    // backgroundColor: '#F5F5F5',
    paddingVertical: 2,
    borderWidth: 1,
    borderColor: 'gray',
    borderRadius: 4,
    marginTop: 5,
  },
  picker: {
    //width: width - 40,
  },
  textPicker: {
    fontSize: 12,
  },
  chip: {
    margin: 2,
    elevation: 2,
    backgroundColor: 'lightgray',
  },
  chipSelected: {
    margin: 2,
    backgroundColor: Colors.secondary,
    elevation: 2,
  },
  containerCategories: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: 5,
    // borderWidth: 0.7,
  },
  btn: {
    backgroundColor: '#3578BD',
    color: 'white',
    width: '40%',
    borderRadius: '30px',
  },
}));

export default useStyle;
