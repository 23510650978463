/* eslint-disable react/prop-types */
import React, {useEffect, useState} from 'react';
import {
  Box,
  Typography,
  TextField,
  Fab,
  AppBar,
  Toolbar,
  Link,
  Zoom,
} from '@material-ui/core';
import useStyle from './styles';
import {useDispatch} from 'react-redux';
import {useTranslation} from 'react-i18next';
import verifyOtp from '../../services/verifyOtp';
import resendOtp from '../../services/resendOtp';
import {
  setToken,
  setLoggedIn,
  setProfilePic,
  setUser,
} from '../../redux/actions/user';
import showToast from '../../components/Toast';
import SimpleDialog from '../../components/DumbComponents/SimpleDialog';
import {useHistory} from 'react-router';
import WebToolbar from '../../components/toolbar/WebToolbar';
import SideNav from '../../components/sidenav/SideNav';
import AppBarName from '../../components/DumbComponents/AppBarName';
import ErrorBoundary from '../../components/ErrorBoundary';
import ErrorBoundaryFallback from '../../components/ErrorBoundaryFallback';
import {get} from 'lodash';

const OTPVerifyScreen = (props) => {
  const userName = get(props, 'location.state.userName', '');

  const classes = useStyle();
  const dispatch = useDispatch();
  const history = useHistory();
  const [otp, setOtp] = useState('');
  const {t} = useTranslation(['verifyOtp']);
  const [loading, setLoading] = useState(false);
  const [isResend, setIsResend] = useState(false);
  const [timeLeft, setTimeLeft] = useState(100);
  const [openFilterDialog, setOpenFilterDialog] = useState(false);

  useEffect(() => {
    console.log('props', props);
    if (timeLeft === 0) {
      setIsResend(true);
    }
    if (!timeLeft) {
      return;
    }
    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);
    return () => clearInterval(intervalId);
  }, [timeLeft]);

  const verify = async () => {
    // Keyboard.dismiss();
    try {
      setLoading(true);
      // const fcmToken = await iid().getToken();
      const fcmToken = 1234;
      const payload = {
        userName: userName,
        otp: otp,
        // deviceId: DeviceInfo.getUniqueId(),
        deviceId: 'dd96dec43fb81c97',
        fcmToken: fcmToken,
      };
      const response = await verifyOtp(
        payload,
        fcmToken,
        'dd96dec43fb81c97'
        // DeviceInfo.getUniqueId()
      );
      console.log('verify otp response', response);
      setLoading(false);
      if (response.successCode === 1) {
        dispatch(setProfilePic(response.response.profilePicture));
        dispatch(setUser(response.response));
        dispatch(setToken(response.response.token));
        dispatch(setLoggedIn(true));
        // navigation.dispatch(
        //   CommonActions.reset({
        //     index: 1,
        //     routes: [{name: 'Splash'}],
        //   })
        // );
        setOpenFilterDialog(!openFilterDialog);
        // history.push('/home');
      } else {
        showToast('error', t(response.returnMessage));
        history.push('/login');
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const closeFilterDialogFunction = () => {
    setOpenFilterDialog(false);
  };

  const handleCloseBasketListItemClick = async () => {
    history.push('addvendorshopdeteils');
  };

  function fmtMSS(s) {
    return (s - (s %= 60)) / 60 + (s > 9 ? ':' : ':0') + s;
  }

  async function resend() {
    try {
      const response = await resendOtp(userName);
      console.log('resendOtp response', response);
      setTimeLeft(100);
      setIsResend(false);
    } catch (error) {}
  }

  return (
    <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
      <Box className={classes.container}>
        {/* <WebToolbar /> */}
        {/* <div className={classes.sideNav}>
        <SideNav />
      </div> */}
        <AppBarName title="Verify OTP" />

        <Box p={2}>
          <Typography variant="h6">
            {'Please enter OTP sent on your registered mobile number'}
          </Typography>
        </Box>

        <Box
          style={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <TextField
            className={classes.textInput}
            id="outlined-basic"
            // label="OTP"
            // type="number"
            inputProps={{maxLength: 4}}
            placeholder="- - - -"
            value={otp}
            size="small"
            variant="outlined"
            onChange={(txt) => {
              setOtp(txt.target.value);
            }}
          />
        </Box>

        <Box p={2} className={[classes.center, classes.my20]}>
          <Typography className={classes.text17}>{`Resend OTP in: ${fmtMSS(
            timeLeft
          )}`}</Typography>
        </Box>
        {isResend && (
          <Box p={2} className={[classes.center, classes.btnWrap]}>
            <Box
              // disabled={!isResend}
              className={classes.btnBlue}
              onClick={() => resend()}
            >
              <Link>Request new code</Link>
            </Box>
          </Box>
        )}
        <Box p={2} className={classes.fabWrapper}>
          <Zoom in={true} timeout={{enter: 500, exit: 500}} unmountOnExit>
            <Fab
              className={classes.fab}
              variant="extended"
              aria-label="add"
              disabled={!(otp?.length === 4)}
              onClick={verify}
            >
              {loading ? t('verifying') : t('verify')}
            </Fab>
          </Zoom>
        </Box>
        <SimpleDialog
          open={openFilterDialog}
          onSimpleDialogClose={closeFilterDialogFunction}
          message="Login success, Do you want to register as a vendor?"
          handleBasketListItemClick={handleCloseBasketListItemClick}
        />
      </Box>
    </ErrorBoundary>
  );
};

export default OTPVerifyScreen;
