/* eslint-disable react/prop-types */
import {
  Box,
  Button,
  Checkbox,
  TextField,
  Typography,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Grid,
  createMuiTheme,
  FormLabel,
} from '@material-ui/core';
import React, {useEffect, useState} from 'react';
import moment from 'moment';
import {ThemeProvider} from '@material-ui/core/styles';
import useStyle from './style';
import DateFnsUtils from '@date-io/date-fns';
// import getDefaultImages from '../../../services/getDefaultImages';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  KeyboardTimePicker,
} from '@material-ui/pickers';
// import showToast from '../../../components/Toast';
// import WebToolbar from '../../../components/toolbar/WebToolbar';
// import AppBarName from '../../../components/DumbComponents/AppBarName';
// import SideNav from '../../../components/sidenav/SideNav';
// import moment from 'moment';
import SimpleSelect from '../../../components/DumbComponents/SimpleSelect';
import {useSelector} from 'react-redux';
import getListOfCities from '../../../services/getListOfCities';
import getListOfPincodes from '../../../services/getListOfPincodes';
import getListOfSublocalities from '../../../services/getListOfSublocalities';
import {get, isEmpty} from 'lodash';

const materialTheme = createMuiTheme({
  overrides: {
    MuiButton: {
      textPrimary: {
        color: '#fff',
        backgroundColor: '#0277BD',
        '&:hover': {
          color: '#0277BD',
          backgroundColor: '#fff',
          border: '1px solid #0277BD',
        },
      },
    },
  },
});

const TextFieldComponent = (props) => {
  return <TextField {...props} disabled={true} />;
};

const AdDetails = ({
  onPressCreateAdd,
  onPressDeleteAdd,
  typeOfAd,
  loading,
  setLoading,
  defaultImages = [],
  states,
  isEdit = false,
  editData,
  advertisementId,
}) => {
  const {typeName} = typeOfAd;

  // const [value1, setValue1] = useState('city');
  const [citiess, setCitiess] = useState([]);
  const [pinCode, setPinCode] = useState([]);
  const [locality, setLocality] = useState([]);
  const token = useSelector((state) => state.user.token);

  const [adDetailsState, setAdDetailsState] = useState({
    selectedImage: defaultImages[0],
    selectedImageIndex: 0,
    isImgSelected: true,
    title: '',
    detailsText: '',
    selectedState: '',
    selectedCity: '',
    selectedPincode: '',
    selectedLocality: '',
    selectedStartDate: new Date(),
    linkToDetailsScreen: true,
    level: 4,
    advertisementLevel: '',
  });

  const [errors, setErrors] = useState({
    selectedImage: '',
    title: '',
    detailsText: '',
    advertisementLevel: '',
    state: '',
    city: '',
    pincode: '',
    locality: '',
    linkToDetailsScreen: '',
    startDate: '',
  });

  const classes = useStyle();

  useEffect(() => {
    console.log('isEdit', isEdit, defaultImages);
    if (isEdit) {
      const _adDetailsState = {...adDetailsState};
      _adDetailsState.selectedImage = editData.image;
      _adDetailsState.advertisementLevel = editData.level.toLowerCase();
      console.log('advertisementLevel', defaultImages, editData);
      _adDetailsState.title = editData.title;
      _adDetailsState.detailsText = editData.text;
      _adDetailsState.startDate = editData.dateFrom;
      defaultImages?.forEach((imageUrl, index) => {
        if (imageUrl === editData.image) {
          _adDetailsState.selectedImageIndex = index;
          _adDetailsState.isImgSelected = true;
        }
      });
      setAdDetailsState(_adDetailsState);
    }
  }, []);

  useEffect(() => {
    console.log(
      'state level',
      adDetailsState?.advertisementLevel,
      adDetailsState
    );
    if (adDetailsState?.selectedState?.id) {
      getCities(adDetailsState.selectedState.id);
    }
  }, [adDetailsState.selectedState]);

  useEffect(() => {
    console.log(
      'city level',
      adDetailsState?.advertisementLevel,
      adDetailsState
    );
    if (
      adDetailsState?.selectedCity?.id &&
      adDetailsState?.advertisementLevel === 'pincode'
    ) {
      getPincodeLevel(adDetailsState.selectedCity.id);
    }
  }, [adDetailsState.selectedCity]);

  useEffect(() => {
    if (
      adDetailsState?.selectedPincode?.id &&
      adDetailsState?.advertisementLevel === 'locality'
    ) {
      // getLocalityLevel(adDetailsState.selectedPincode.id);
    }
  }, [adDetailsState.selectedPincode]);

  const handleTitleChange = (txt) => {
    setAdDetailsState({...adDetailsState, title: txt.target.value});
    // setTitle(txt.target.value);
    if (txt.target.value) {
      setErrors({...errors, title: ''});
    }
  };
  const handleDetailsChange = (txt) => {
    setAdDetailsState({...adDetailsState, detailsText: txt.target.value});
    // setDetailsText(txt.target.value);
    if (txt.target.value) {
      setErrors({...errors, detailsText: ''});
    }
  };
  const handleStartTimeChange = (time) => {
    setAdDetailsState({...adDetailsState, startTime: time});
    // setStartTime(time);
  };
  const handleEndTimeChange = (time) => {
    setAdDetailsState({...adDetailsState, endTime: time});
    // setEndTime(time);
  };

  const handleUploadChange = (e) => {
    console.log('e', e);
    let _adDetailsState = {...adDetailsState};
    _adDetailsState.selectedImage = '';
    _adDetailsState.selectedImageIndex = '';
    _adDetailsState.isImgSelected = true;
    // setSelectedImage();
    // setSelectedImageIndex(true);
    // setIsImgSelected(true);
    if (e.target.files.length > 0) {
      // setSelectedUploadImage(e.target.files[0]);
      _adDetailsState.selectedUploadImage = e.target.files[0];
    }
    setAdDetailsState({..._adDetailsState});
  };

  const onDefaultImageClick = (data, index) => {
    console.log('index', index);
    // setSelectedUploadImage();
    // setSelectedImage(data.target.src);
    // setSelectedImageIndex(index);
    // setIsImgSelected(true);
    setAdDetailsState({
      ...adDetailsState,
      selectedUploadImage: '',
      selectedImage: data.target.src,
      selectedImageIndex: index,
      isImgSelected: true,
    });
    if (data.target.src) {
      setErrors({...errors, selectedImage: ''});
    }
  };

  const handleStartDateChange = (date) => {
    setAdDetailsState({
      ...adDetailsState,
      selectedStartDate: date,
      selectedEndDate: moment(date, 'DD-MM-YYYY').add(15, 'days'),
    });
    // setSelectedStartDate(date);
    // setSelectedEndDate(moment(date, 'DD-MM-YYYY').add(15, 'days'));
  };

  const handleEndDateChange = (date) => {
    setAdDetailsState({
      ...adDetailsState,
      selectedEndDate: date,
    });
    // setSelectedEndDate(date);
  };

  const handleChangeVD = (event) => {
    setAdDetailsState({
      ...adDetailsState,
      linkToDetailsScreen: event.target.checked,
    });
    // setCheckedVD(event.target.checked);
  };

  const handleAdvertisementLevelChange = (event) => {
    console.log('event.target.value', event.target.value);
    setAdDetailsState({
      ...adDetailsState,
      advertisementLevel: event.target.value,
      selectedCity: '',
      selectedState: '',
      selectedPincode: '',
    });
    setCitiess([]);
    setPinCode([]);
  };

  // const handleChange = (event) => {
  //   console.log('handleChange', event.target.name, event.target.checked);
  //   if (event.target.value === 'value1') {
  //     setAdDetailsState({...adDetailsState, checkedNP: event.target.checked});
  //     // setCheckedNP(event.target.checked);
  //   } else if (event.target.value === 'value2') {
  //     setAdDetailsState({
  //       ...adDetailsState,
  //       linkToDetailsScreen: event.target.checked,
  //     });
  //     // setCheckedVD(event.target.checked);
  //   }
  //   setValue(event.target.value);
  // };

  async function getCities(_stateId) {
    try {
      const response = await getListOfCities(token, _stateId);
      console.log('get cities', response.response.area);
      if (response.successCode === 1) {
        setCitiess(response.response.area);
        setLoading(false);
        setAdDetailsState({...adDetailsState, level: 3});
        // setLevel(2);
      } else {
        setLoading(false);
        console.log('err');
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }

  async function getPincodeLevel(cityId) {
    try {
      // setLoading(true);
      const response = await getListOfPincodes(token, cityId);
      console.log('get pincode', response);
      if (response.successCode === 1) {
        setPinCode(response.response.area);
        setLoading(false);
        setAdDetailsState({...adDetailsState, level: 2});
        // setLevel(1);
      } else {
        setLoading(false);
        console.log('err');
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }

  async function getLocalityLevel(localityId) {
    try {
      // setLoading(true);
      const response = await getListOfSublocalities(token, localityId);
      console.log('get sublocalities', response.response.area);
      if (response.successCode === 1) {
        setLocality(response.response.area);
        setLoading(false);
        setAdDetailsState({...adDetailsState, level: 1});
        // setLevel(0);
      } else {
        setLoading(false);
        console.log('err');
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }

  const isFormInvalid = () => {
    let isInvalid = false;
    // let hasErrors =  ? false : true;
    // const l = errors.
    for (const key in errors) {
      if (errors[key].length) {
        isInvalid = true;
      }
    }
    if (!isInvalid) {
      isInvalid =
        adDetailsState.selectedImageIndex >= 0 &&
        adDetailsState.title?.length &&
        adDetailsState.detailsText?.length &&
        adDetailsState.selectedState?.name?.length &&
        adDetailsState.selectedCity?.name?.length
          ? false
          : true;
    }
    return isInvalid;
  };

  return (
    <div>
      <Box p={1}>
        <Box>
          <Typography style={{fontWeight: '600'}}>
            {isEdit ? 'Selected background image' : 'Select a background image'}
          </Typography>
        </Box>
        <Box
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'center',
          }}
        >
          {defaultImages.length > 0 ? (
            defaultImages.map((data, index) => {
              return (
                <Box
                  key={index}
                  style={{padding: '10px', cursor: 'pointer'}}
                  onClick={(data) =>
                    !isEdit && onDefaultImageClick(data, index)
                  }
                >
                  {isEdit && adDetailsState.selectedImageIndex === index ? (
                    <img
                      className={
                        adDetailsState.selectedImageIndex === index
                          ? classes.slectedClass
                          : classes.nonSelectedClass
                      }
                      src={data}
                      width="300px"
                      height="150px"
                    />
                  ) : (
                    <img
                      className={
                        adDetailsState.selectedImageIndex === index
                          ? classes.slectedClass
                          : classes.nonSelectedClass
                      }
                      src={data}
                      width="300px"
                      height="150px"
                    />
                  )}
                </Box>
              );
            })
          ) : (
            <Box p={1}>
              <Typography>No images availble</Typography>
            </Box>
          )}
          {errors.selectedImage === 0 ? (
            <Box>
              <Typography color="red">
                Please select background image
              </Typography>
            </Box>
          ) : (
            <></>
          )}
        </Box>

        <Box p={1}>
          <TextField
            label={'Enter title'}
            value={adDetailsState.title}
            variant="outlined"
            fullWidth
            size="small"
            inputProps={{maxLength: 50}}
            onChange={handleTitleChange}
            required={true}
            error={errors.title.length}
            helperText={errors.title}
            onBlur={(e) => {
              if (!e.target.value) {
                setErrors({...errors, title: 'Title is required!'});
              }
            }}
            className={classes.textInput}
          />
        </Box>
        <Box p={1}>
          <TextField
            label={'Enter details'}
            value={adDetailsState.detailsText}
            variant="outlined"
            fullWidth
            multiline
            rows={2}
            size="small"
            inputProps={{maxLength: 80}}
            onChange={handleDetailsChange}
            className={classes.textInput}
            required={true}
            error={errors.detailsText.length}
            helperText={errors.detailsText}
            onBlur={(e) => {
              if (!e.target.value) {
                setErrors({...errors, detailsText: 'Details is required!'});
              }
            }}
          />
        </Box>
        <Box p={1}>
          <FormControl component="fieldset" style={{width: '100%'}}>
            <FormLabel component="legend">Select Advertisement level</FormLabel>
            <RadioGroup
              className={classes.radioGrp}
              aria-label="city"
              name="city1"
              value={adDetailsState.advertisementLevel}
              onChange={handleAdvertisementLevelChange}
            >
              <FormControlLabel
                value="city"
                disabled={isEdit}
                control={<Radio required={true} />}
                label="City"
              />
              <FormControlLabel
                disabled={isEdit}
                value="pincode"
                control={<Radio />}
                label="Pincode"
              />
            </RadioGroup>
          </FormControl>
        </Box>
        <Box px={1}>
          {adDetailsState.advertisementLevel === 'locality' ? (
            <Grid container direction={'column'} spacing={2}>
              <Grid item>
                <SimpleSelect
                  title="Select State"
                  label="State Level"
                  val={states}
                  disabled={isEdit}
                  selectedEle={adDetailsState.selectedState}
                  setLoc={(e) => {
                    const value = e.target.value;
                    const myJSON = JSON.parse(value);
                    console.log('>>>>> states', myJSON);
                    setAdDetailsState({
                      ...adDetailsState,
                      selectedState: myJSON,
                    });
                    // setSelectedState(myJSON);
                  }}
                />
              </Grid>
              <Grid item>
                <SimpleSelect
                  title="Select City"
                  label="City Level"
                  val={citiess}
                  disabled={isEdit}
                  selectedEle={adDetailsState.selectedCity}
                  setLoc={(e) => {
                    const value = e.target.value;
                    const myJSON = JSON.parse(value);
                    setAdDetailsState({
                      ...adDetailsState,
                      selectedCity: myJSON,
                    });
                    // setSelectedCity(myJSON);
                    // getPincodeLevel(myJSON.id);
                  }}
                />
              </Grid>
              <Grid item>
                <SimpleSelect
                  title="Select Pincode"
                  label="Pincode Level"
                  val={pinCode}
                  disabled={isEdit}
                  selectedEle={adDetailsState.selectedPincode}
                  setLoc={(e) => {
                    const value = e.target.value;
                    const myJSON = JSON.parse(value);
                    setAdDetailsState({
                      ...adDetailsState,
                      selectedPincode: myJSON,
                    });
                    // setSelectedPincode(myJSON);
                    // getLocalityLevel(myJSON.id);
                  }}
                />
              </Grid>
              <Grid item>
                <SimpleSelect
                  title="Select Locality"
                  label="Locality Level"
                  val={locality}
                  disabled={isEdit}
                  selectedEle={adDetailsState.selectedLocality}
                  setLoc={(e) => {
                    const value = e.target.value;
                    const myJSON = JSON.parse(value);
                    setAdDetailsState({
                      ...adDetailsState,
                      selectedLocality: myJSON,
                    });
                    // setSelectedLocality(myJSON);
                  }}
                />
              </Grid>
            </Grid>
          ) : (
            ''
          )}
        </Box>
        <Box px={1}>
          {adDetailsState.advertisementLevel === 'pincode' ? (
            <Grid container direction={'column'} spacing={2}>
              <Grid item>
                <SimpleSelect
                  title="Select State"
                  label="State Level"
                  val={states}
                  disabled={isEdit}
                  selectedEle={adDetailsState.selectedState}
                  setLoc={(e) => {
                    const value = e.target.value;
                    const myJSON = JSON.parse(value);
                    setAdDetailsState({
                      ...adDetailsState,
                      selectedState: myJSON,
                    });
                    // setSelectedState(myJSON);
                    // getCities(myJSON.id);
                  }}
                />
              </Grid>
              <Grid item>
                <SimpleSelect
                  title="Select City"
                  label="City Level"
                  val={citiess}
                  disabled={isEdit}
                  selectedEle={adDetailsState.selectedCity}
                  setLoc={(e) => {
                    const value = e.target.value;
                    const myJSON = JSON.parse(value);
                    setAdDetailsState({
                      ...adDetailsState,
                      selectedCity: myJSON,
                    });
                    // setSelectedCity(myJSON);
                    // getPincodeLevel(myJSON.id);
                  }}
                />
              </Grid>
              <Grid item>
                <SimpleSelect
                  title="Select Pincode"
                  label="Pincode Level"
                  val={pinCode}
                  disabled={isEdit}
                  selectedEle={adDetailsState.selectedPincode}
                  setLoc={(e) => {
                    const value = e.target.value;
                    const myJSON = JSON.parse(value);
                    setAdDetailsState({
                      ...adDetailsState,
                      selectedPincode: myJSON,
                    });
                    // setSelectedPincode(myJSON);
                  }}
                />
              </Grid>
            </Grid>
          ) : (
            ''
          )}
        </Box>
        <Box px={1}>
          {adDetailsState.advertisementLevel === 'city' ? (
            <Grid container direction={'column'} spacing={2}>
              <Grid item>
                <SimpleSelect
                  title="Select State"
                  label="State Level"
                  val={states}
                  disabled={isEdit}
                  selectedEle={adDetailsState.selectedState}
                  setLoc={(e) => {
                    const value = e.target.value;
                    const myJSON = JSON.parse(value);
                    console.log('>>> state', myJSON);
                    setAdDetailsState({
                      ...adDetailsState,
                      selectedState: myJSON,
                    });
                    // setSelectedState(myJSON);
                    // getCities(myJSON.id);
                  }}
                />
              </Grid>
              <Grid item>
                <SimpleSelect
                  title="Select City"
                  label="City Level"
                  val={citiess}
                  disabled={isEdit}
                  selectedEle={adDetailsState.selectedCity}
                  setLoc={(e) => {
                    const myJSON = JSON.parse(e.target.value);
                    console.log('city', myJSON);
                    setAdDetailsState({
                      ...adDetailsState,
                      selectedCity: myJSON,
                    });
                    // setSelectedCity(myJSON);
                  }}
                />
              </Grid>
            </Grid>
          ) : (
            ''
          )}
        </Box>
        <Box px={1}>
          {adDetailsState.advertisementLevel === 'state' ? (
            <Box>
              <SimpleSelect
                title="Select State"
                label="State Level"
                val={states}
                disabled={isEdit}
                selectedEle={adDetailsState.selectedState}
                setLoc={(e) => {
                  const value = e.target.value;
                  const myJSON = JSON.parse(value);
                  setSelectedState(myJSON);
                }}
              />
            </Box>
          ) : (
            ''
          )}
        </Box>
        {/* <Box px={2} style={{display: 'flex', alignItems: 'center'}}>
          <Checkbox
            checked={adDetailsState.linkToDetailsScreen}
            onChange={handleChangeVD}
            inputProps={{'aria-label': 'primary checkbox'}}
          />
          <Typography style={{marginLeft: '10px'}}>
            Link to new page
          </Typography>
        </Box> */}
        <Box px={2} style={{display: 'flex', alignItems: 'center'}}>
          <Checkbox
            checked={adDetailsState.linkToDetailsScreen}
            onChange={handleChangeVD}
            disabled={isEdit}
            inputProps={{'aria-label': 'primary checkbox'}}
          />
          <Typography style={{marginLeft: '10px'}}>
            Link to your details screen
          </Typography>
        </Box>
        <ThemeProvider theme={materialTheme}>
          <Box>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Grid
                container
                style={{
                  justifyContent: 'space-around',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <KeyboardDatePicker
                  margin="normal"
                  size="small"
                  id="date-picker-dialog"
                  label="Start Date"
                  format="dd/MM/yyyy"
                  inputVariant="outlined"
                  autoOk={true}
                  disabled={isEdit}
                  disablePast={true}
                  value={adDetailsState.selectedStartDate}
                  onChange={handleStartDateChange}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />
                <Typography style={{fontWeight: '600'}}>
                  Advertisement will end after 15 days
                </Typography>
              </Grid>
            </MuiPickersUtilsProvider>
          </Box>
        </ThemeProvider>

        <Box
          style={{
            display: 'flex',
            justifyContent: advertisementId ? 'space-evenly' : 'center',
          }}
          py={2}
        >
          <Button
            variant="outlined"
            size="small"
            className={classes.button}
            onClick={() => onPressCreateAdd(adDetailsState)}
            disabled={isFormInvalid()}
          >
            Next
          </Button>
          {advertisementId && (
            <Button
              variant="outlined"
              size="small"
              className={classes.deleteButton}
              onClick={() => onPressDeleteAdd(adDetailsState)}
            >
              Delete
            </Button>
          )}
        </Box>
      </Box>
    </div>
  );
};

export default AdDetails;
