/* eslint-disable react/prop-types */
/* Copyright (C) 2020 Gearten Technologies Private Limited - All Rights Reserved
 NOTICE:  All information contained herein is, and remains
 * the property of Gearten Technologies Private Limited
 * The intellectual and technical concepts contained
 * herein are proprietary to Gearten Technologies Private Limited.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Gearten Technologies Private Limited.
 */
import React, {Component} from 'react';
import Colors from '../theme/Colors';
import moment from 'moment';
import {Box, makeStyles, Typography} from '@material-ui/core';

const styles = makeStyles(() => ({
  container: {
    flex: 1,
    flexDirection: 'row',
    padding: 22,
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  containerWeek: {
    marginBottom: 4,
    marginTop: 4,
    flex: 1,
    height: 20,
  },
  textWeek: {
    flex: 1,
    textAlign: 'center',
    fontSize: 14,
    color: 'black',
  },
  textDay: {
    flex: 1,
    fontSize: 16,
    textAlign: 'center',
    alignSelf: 'center',
    marginTop: 7,
  },
  btnText: {
    height: 35,
    width: 35,
    borderRadius: 20,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: Colors.secondary,
    marginHorizontal: 2,
    marginVertical: 2,
  },
}));

class MonthView extends Component {
  constructor(props) {
    super(props);
    this.today = moment();
  }

  getDayColor(item) {
    if (item.item.length > 0) {
      return Colors.secondary;
    }
    if (item.date) {
      if (this.today.isSame(item.date, 'day')) {
        return 'gray';
      }
    }
    return 'white';
  }

  getTextColor(item) {
    if (item.item.length > 0) {
      return 'white';
    }
    if (item.date) {
      if (this.today.isSame(item.date, 'day')) {
        return 'white';
      }
    }
    return 'black';
  }

  render() {
    let rows = [];
    rows = this.props.data.map((row, rowIndex) => {
      const rowItems = row.map((item) =>
        rowIndex === 0 ? (
          <Box style={styles.containerWeek}>
            <Typography style={styles.textWeek}>
              {item.day ? item.day : ''}
            </Typography>
          </Box>
        ) : (
          <Box
            style={[styles.btnText, {backgroundColor: this.getDayColor(item)}]}
            disabled={!item.item}
            activeOpacity={0.65}
            onClick={() => this.props.onPress(item)}
          >
            <Typography
              style={[styles.textDay, {color: this.getTextColor(item)}]}
            >
              {item.day ? item.day : ''}
            </Typography>
          </Box>
        )
      );
      return (
        <Box key={rowIndex} style={styles.container}>
          {rowItems}
        </Box>
      );
    });

    return <Box>{rows}</Box>;
  }
}

export default MonthView;
