import {makeStyles} from '@material-ui/core/styles';
import {deepPurple} from '@material-ui/core/colors';

const drawerWidth = 240;
const catHeight = 240;

const useStyles = makeStyles((theme, props) => ({
  root: {
    display: 'flex',
    //width: 500,
  },
  title: {
    flexGrow: 1,
    color: 'black',
    // '@media (max-width: 900px)': {
    //   fontSize: '13px',
    // },
  },
  logoContainer: {
    display: 'flex',
    '@media (max-width: 380px)': {
      display: 'block',
    },
  },
  appLogoContainer: {
    display: 'flex',
    // justifyContent: 'center',
    alignItems: 'center',
  },
  appNameContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  appLogo: {
    height: 50,
    aspectRatio: 1,
    margin: 2,
    cursor: 'pointer',
    '@media (max-width: 450px)': {
      height: 30,
    },
  },
  appName: {
    color: '#000000',
    cursor: 'pointer',
    '@media (max-width: 450px)': {
      fontSize: 15,
    },
    '@media (max-width: 350px)': {
      fontSize: 13,
    },
  },
  drawer: {},
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: '#fff',
    height: 60,
    '@media (max-width: 900px)': {
      // paddingLeft: 0,
    },
  },
  toolbar: {
    height: 60,
    padding: 0,
  },
  drawerCont: {border: 'solid red'},
  drawerItem: {
    flexGrow: 1,
    color: '#000000',
    cursor: 'pointer',
  },
  menuButton: {
    marginRight: theme.spacing(1),
  },
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(1),
  },
  sideMenu: {
    marginTop: 80,
    width: 200,
  },
  closeMenuButton: {
    marginRight: 'auto',
    marginLeft: 0,
  },
  progressbar: {
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    //flexGrow: 1,
  },
  blogsContainer: {
    paddingTop: theme.spacing(3),
    paddingBottom: '50px',
  },
  blogTitle: {
    fontWeight: 800,
    paddingBottom: theme.spacing(3),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  card: {
    maxWidth: '100%',
  },
  media: {
    height: 50,
    aspectRatio: 1.4,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 20,
    width: '100%',
  },
  drawer: {
    width: drawerWidth,
  },
  logoutBtn: {
    color: 'white',
    backgroundColor: '#0277BD',
    '&:hover': {
      color: '#0277BD',
      backgroundColor: 'white',
    },
  },

  buttonLang: {
    color: '#000000',
  },
  purple: {
    color: theme.palette.getContrastText(deepPurple[500]),
    backgroundColor: '#4497A8',
    cursor: 'pointer',
  },
}));

export default useStyles;
