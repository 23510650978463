import baseUrl from '../utils/Constants';

export default async function (token, vendorId, item_categories_list) {
  try {
    const response = await fetch(
      // `https://api.gearten.in/api/vendors/${vendorId}/item_catgories/${item_categories_list}/items`,
      `${baseUrl}/vendors/${vendorId}/item_catgories/${item_categories_list}/items`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    );

    const responseJson = await response.json();
    return responseJson;
  } catch (error) {
    console.log(error);
  }
}
