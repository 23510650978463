import baseUrl from '../utils/Constants';

export default async function (order_id, msg, fcmToken) {
  console.log('fcmToken', order_id, msg, fcmToken);
  const response = await fetch(`${baseUrl}/orders/message`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${fcmToken}`,
    },
    body: JSON.stringify({OrderId: order_id, Message: msg}),
  });
  const responseJson = await response.json();
  return responseJson;
}
