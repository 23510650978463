import {createStyles, makeStyles} from '@material-ui/core';

//Another way of global css

const useStyles = makeStyles(() =>
  createStyles({
    '@global': {
      '.app_bg_color': {
        backgroundColor: '#F2F2F2',
      },
      '.selected_tab_bg': {
        backgroundColor: '#0277bd ',
        color: '#ffffff',
      },
    },
  })
);

const GlobalStyles = () => {
  useStyles();
  return null;
};

export default GlobalStyles;
