/* eslint-disable react/display-name */
import React from 'react';
import Colors from '../theme/Colors';
import moment from 'moment';
import {Box, makeStyles, Typography} from '@material-ui/core';

const styles = makeStyles({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  text: {
    backgroundColor: 'gray',
    borderRadius: 10,
    fontSize: 11,
    color: 'white',
    padding: '3px',
  },
});

// eslint-disable-next-line react/prop-types
const DateSeparator = React.memo(({date}) => {
  const diaplayDate = moment(date).format('DD MMM YYYY');
  const classes = styles();

  return (
    <Box className={classes.container}>
      {/* <View style={classes.line} /> */}
      <Typography className={classes.text}>{diaplayDate}</Typography>
      {/* <View style={classes.line} /> */}
    </Box>
  );
});

export default DateSeparator;
