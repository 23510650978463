/* eslint-disable react/prop-types */
import React, {useState} from 'react';
import {Box, Typography, Button} from '@material-ui/core';
import useStyle from './style';
import WebToolbar from '../../../components/toolbar/WebToolbar';
import AppBarName from '../../../components/DumbComponents/AppBarName';
import SideNav from '../../../components/sidenav/SideNav';
import {useSelector} from 'react-redux';
import {useHistory} from 'react-router';
import ErrorBoundary from '../../../components/ErrorBoundary';
import ErrorBoundaryFallback from '../../../components/ErrorBoundaryFallback';
import createAdvertisement from '../../../services/createAdvertisement';
import showToast from '../../../components/Toast';
import MobileFooter from '../../MobileFooter';

const AdvertisementPreview = (props) => {
  const newAdDetails = useSelector((state) => {
    console.log('123', state.user.newAdvertisementDetails);
    return state.user.newAdvertisementDetails;
  });
  const classes = useStyle();
  const history = useHistory();

  const userDetails = useSelector((state) => {
    return state.user;
  });

  const [loading, setLoading] = useState(false);

  const onPressNext = async () => {
    // setLoading(true);
    console.log('newAdDetails', newAdDetails);
    try {
      const formData = new FormData();
      formData.append('AdvURL', newAdDetails.selectedImage);
      formData.append('AdvText', newAdDetails.AdvertisementDetails);
      formData.append('AdvTitle', newAdDetails.AdvertisementText);
      formData.append('AdvDesciption', newAdDetails.AdvertisementDetails);
      formData.append('Advlevel', newAdDetails.AdvertisementLevel);
      formData.append('AreaId', newAdDetails.areaId);
      formData.append('DateFrom', newAdDetails.selectedStartDate);
      formData.append('AdvertisementType', 0);
      formData.append('RequiredNewPage', 1);
      const response = await createAdvertisement(formData, userDetails.token);
      console.log('response from create add', response);
      if (response.successCode === 1) {
        showToast('success', 'Add Created');
        history.push('/advertisement');
      } else {
        showToast('error', response.returnMessage);
      }
      setLoading(false);
    } catch (error) {
      console.log('error', error);
      setLoading(false);
    }
  };

  const backgroundImage = newAdDetails.selectedImage
    ? newAdDetails.selectedImage
    : '';

  return (
    <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
      <div className={classes.container}>
        <WebToolbar />{' '}
        <div className={classes.sideNav}>
          <SideNav />
        </div>
        <AppBarName title={'Advertisement Preview'} />
        <Box style={{display: 'flex', justifyContent: 'center'}} p={2}>
          <Box
            className={classes.imageContainer}
            style={{
              backgroundImage: `url(${backgroundImage})`,
            }}
          >
            <Box className={classes.imageContainerOverlay}></Box>
            <Box className={classes.imageTextContainer}>
              <Typography
                style={{fontWeight: '900', color: 'black', fontSize: '35px'}}
              >
                {newAdDetails.AdvertisementText}
              </Typography>
              <Typography
                style={{
                  fontWeight: '900',
                  color: 'black',
                  fontSize: '22px',
                  paddingBottom: '2rem !important',
                }}
              >
                {newAdDetails.AdvertisementDetails}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box
          style={{display: 'flex', justifyContent: 'space-evenly'}}
          pt={5}
          pb={2}
        >
          <Button
            variant="outlined"
            className={classes.button}
            onClick={() => {
              history.goBack();
            }}
          >
            Back
          </Button>
          <Button
            className={classes.button}
            size="small"
            variant="outlined"
            onClick={onPressNext}
          >
            Confirm
          </Button>
        </Box>
      </div>
      <MobileFooter />
    </ErrorBoundary>
  );
};

export default AdvertisementPreview;
