/* eslint-disable react/prop-types */
import React, {useEffect, useState, useCallback} from 'react';
import useStyle from './style';
import WebToolbar from '../../components/toolbar/WebToolbar';
import SimpleAutocomplete from '../../components/DumbComponents/SimpleAutocomplete';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {Box, TextField, Typography, Grid, Button} from '@material-ui/core';
import {get, lowerCase, debounce} from 'lodash';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import searchvendor from '../../services/searchBasketVendor';
import {useDispatch, useSelector} from 'react-redux';
import searchItems from '../../services/searchItems';
import Checkbox from '@material-ui/core/Checkbox';
import showToast from '../../components/Toast';
import {useHistory} from 'react-router';
import AppBarName from '../../components/DumbComponents/AppBarName';
import SideNav from '../../components/sidenav/SideNav';
import {setPlaceBasketOrders} from '../../redux/actions/user';
import MobileFooter from '../MobileFooter';

const CreateBasketOrdersScreen = (props) => {
  const {id} = props.location.state.item;
  const classes = useStyle();

  const forceUpdate = useState()[1].bind(null, {});
  const token = useSelector((state) => state.user.token);
  const history = useHistory();
  const [query, setQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [items, setItems] = useState([]);
  const [price, setPrice] = useState('');
  const [loading, setLoading] = useState(false);
  const [searching, setSearching] = useState(false);
  const [isModal, setIsModal] = useState(false);

  const [selectedItem, setSelectedItem] = useState([]);
  const [checked, setChecked] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    console.log('props', props);
    const data = [];
    props.location.state.items.forEach((element) => {
      data.push({
        ...element,
        selected: false,
        quantity: 1,
      });
    });
    setItems(data);
  }, []);

  const handleChange = (item, event, index) => {
    console.log('item', item, 'index', index);

    const updatedItems = items.map((val) => {
      if (item.id === val.id) {
        return {...val, selected: event.target.checked};
      } else {
        return val;
      }
    });
    setItems(updatedItems);
    // setVendors(updatedVendors);

    // items.forEach((val) => {
    //   val.id === item.id ? checked : !checked;
    // });

    // setChecked(event.target.checked);
  };

  function onChangeText(text) {
    setQuery(text);
    setSearching(true);
    if (text.length > 0) {
      //setLoading(true);
      //searchLocations(text);
      handler(text);
    } else {
      // setSearchResults([]);
      setLoading(false);
      setSearching(false);
    }
  }
  const handler = useCallback(
    debounce(async (text) => {
      if (!text.trim()) {
        return;
      }
      try {
        const response = await searchItems(token, text);
        console.log('response', response);
        if (response.successCode === 1) {
          const data = response.response;
          // const obj = {
          //   description: text,
          //   isVendorItem: false,
          //   name: text,
          //   price: '',
          // };
          // data.push(obj);
          setSearchResults(data);
          setSearching(false);
        } else {
          const data = [];
          // const obj = {
          //   description: text,
          //   isVendorItem: false,
          //   name: text,
          // };
          // data.push(obj);
          //showToast(response.returnMessage, 'danger');
          setSearchResults(data);
          setSearching(false);
        }
        //setLoading(false);
      } catch (error) {
        //setLoading(false);
        setSearching(false);
      }
    }, 500),
    []
  );

  function onItemSelected(item) {
    console.log('qwe', item);
    const data = items;
    data.push({
      itemName: item,
      tentativePrice: item.price ? item.price : 0,
      selected: true,
      quantity: 1,
    });
    setItems(data);
    setSearchResults([]);
    setQuery('');
    forceUpdate();
  }
  function onPressNext() {
    const data = [];
    items.forEach((element) => {
      if (element.selected) {
        data.push(element);
      }
    });
    if (data.length === 0) {
      showToast('error', 'Please select at least one item');
    } else {
      dispatch(
        setPlaceBasketOrders({
          items: data,
          BasketId: id,
          // getBasketData,
          // addMoreCallback: addMoreCallback
        })
      );
      history.push('/placebasketorderscreen');
      // history.push({
      //   pathname: 'placebasketorderscreen',
      //   state: {
      //     items: data,
      //     BasketId: id,
      //     // getBasketData,
      //     // addMoreCallback: addMoreCallback,
      //   },
      // });
    }
  }

  const onChangeValue = (value) => {
    console.log('value', value);
    //   setQuery(value.name);
    onItemSelected(value);
    // setSelectedItem((oldArray) => [...oldArray, value]);
  };

  return (
    <>
      <div className={classes.container}>
        <WebToolbar />
        <div className={classes.sideNav}>
          <SideNav />
        </div>
        <AppBarName title={'Create Basket Orders'} />

        <Box className={classes.searchContainer}>
          <Box style={{width: '100%'}}>
            <SimpleAutocomplete
              options={
                searchResults
                  ? searchResults.map((option) => {
                      console.log('/option', option);
                      return lowerCase(option.name);
                    })
                  : ''
              }
              handleACOnChange={(value) => {
                return onChangeValue(value);
              }}
              labelText="Search Items"
              handleOnChange={onChangeText}
              searching={searching}
            />
          </Box>

          {/* <Box
          style={{
            width: '7%',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Fab
            style={{
              height: '40px',
              width: '40px',
            }}
            aria-label="add"
            onClick={() => {
              history.push('/createbasketordersscreen');
            }}
          >
            <AddIcon />
          </Fab>
        </Box> */}
        </Box>
        <Box boxShadow={1} p={1}>
          {items.length > 0 ? (
            items.map((item, index) => {
              return (
                <Box key={index} className={classes.listIem}>
                  <Grid container className={classes.selectedItemCont}>
                    <Grid item xs={7}>
                      <Typography>{get(item, 'itemName', '')}</Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <Typography>
                        ₹ {get(item, 'tentativePrice', '-')}
                      </Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Checkbox
                        // checked={checked}
                        checked={item.selected ? true : false}
                        onChange={(e) => {
                          handleChange(item, e, index);
                        }}
                        color="primary"
                        inputProps={{'aria-label': 'primary checkbox'}}
                      />
                    </Grid>
                  </Grid>
                </Box>
              );
            })
          ) : (
            <Box>
              <Typography>No items added</Typography>
            </Box>
          )}
        </Box>
        <Box className={classes.btnCont}>
          <Button variant="outlined" onClick={onPressNext}>
            Next
          </Button>
        </Box>
      </div>
      <MobileFooter />
    </>
  );
};

export default CreateBasketOrdersScreen;
