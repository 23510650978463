/* eslint-disable react/prop-types */
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  Divider,
  List,
  ListItem,
  ListItemText,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core';
import {Autocomplete} from '@material-ui/lab';
import {useSelector, useDispatch} from 'react-redux';
import {useHistory} from 'react-router';
import {debounce, isEmpty, lowerCase, upperCase} from 'lodash';
import moment from 'moment';
import React, {useCallback, useEffect, useState, useRef} from 'react';
import ActivityIndicator from '../../components/ActivityIndicator';
import FilterListIcon from '@material-ui/icons/FilterList';
import {setOrderId, setPlacedOrders} from '../../redux/actions/user';
import {useTranslation} from 'react-i18next';
import filterItems from './utils';
import useStyle from './styles';
import searchOrders from '../../services/searchUserOrders';
import showToast from '../../components/Toast';
import PropTypes from 'prop-types';
import getVendorsPlacedOrders from '../../services/getVendorsPlacedOrders';
import getOrderStates from '../../services/getOrderStates';
import SimpleAutocomplete from '../../components/DumbComponents/SimpleAutocomplete';
import ErrorBoundary from '../../components/ErrorBoundary';
import ErrorBoundaryFallback from '../../components/ErrorBoundaryFallback';
import InifiniteScrollComponent from '../../components/InifiniteScrollComponent';

function SimpleDialog(props) {
  const {onClose, selectedValue, open, orders, setListD, listD} = props;
  const {t} = useTranslation(['orderDetails', 'common']);

  const handleClose = () => {
    onClose(selectedValue);
  };

  const handleListItemClick = (value) => {
    console.log('value', value);
    const _filteredItems = orders.filter((item) => {
      if (value === 'All') {
        return listD;
      }
      if (t(item.status) === value) {
        return item;
      }
    });
    console.log('filteredItems', _filteredItems);
    // setOrders(filteredItems);
    setListD(_filteredItems);
    onClose(value);
  };

  return (
    <Dialog
      style={{
        height: '400px',
        position: 'absolute',
        left: '43%',
        top: '22%',
      }}
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <Box
        style={{
          display: 'flex',
          alignItems: 'center',
          backgroundColor: '#F7F7F7',
        }}
      >
        <DialogTitle id="simple-dialog-title">Filter By :-</DialogTitle>
        <FilterListIcon style={{fontSize: 28}}></FilterListIcon>
      </Box>

      <List>
        {filterItems.map((email, index) => (
          <Box key={index}>
            <ListItem
              button
              onClick={() => handleListItemClick(email)}
              key={email}
            >
              <ListItemText primary={email} />
            </ListItem>
            <Divider component="li" />
          </Box>
        ))}
      </List>
    </Dialog>
  );
}

SimpleDialog.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
  selectedValue: PropTypes.string,
  orders: PropTypes.array,
  dispatch: PropTypes.func,
};

const MyOrdersScreen = ({pageType}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const classes = useStyle();
  const {t} = useTranslation(['orderDetails', 'common']);
  const token = useSelector((state) => state.user.token);
  const [listData, setListData] = useState([]);
  const [loading, setLoading] = useState(true);
  const placedOrders = useSelector((state) => state.user.placedOrders);
  const [openFilterDialog, setOpenFilterDialog] = useState(false);
  const [selectedValue, setSelectedValue] = useState([filterItems[0]]);
  const [searching, setSearching] = useState(false);
  const [query, setQuery] = useState('');
  const [pOrders, setPOrders] = useState([]);
  const [lastOrderId, setLastOrderId] = useState(0);

  const closeFilterDialogFunction = (value) => {
    setOpenFilterDialog(false);
    setSelectedValue(value);
  };

  const openFilterDialogFunction = () => {
    setOpenFilterDialog(!openFilterDialog);
  };

  const handleSearchedOrder = (q) => {
    console.log('q text', q);
    setSearching(true);
    setQuery(q);
    if (q.length > 0) {
      handler(q);
    } else {
      setListData(pOrders);
      setSearching(false);
    }
  };

  const handler = useCallback(
    debounce(async (text) => {
      if (!text.trim()) {
        return;
      }
      try {
        console.log('text', text);
        const response = await searchOrders(token, text);
        console.log('serach orders response', response);
        if (response.successCode === 1) {
          setListData(response.response.orders);
          setSearching(false);
        } else {
          showToast('warn', 'No matching orders found.');
          setListData(pOrders);
          setSearching(false);
        }
      } catch (error) {
        setSearching(false);
      }
    }, 1000),
    []
  );

  const scrollListItem = (val, index) => {
    return (
      <Box key={index}>
        <Box
          className={classes.placedOrdersContainer}
          style={{
            display: 'flex',
            padding: '10px',
            cursor: 'pointer',
          }}
          onClick={() => {
            dispatch(
              setOrderId({
                orderId: val.orderId,
                address: val.vendorAddress,
                from: 'MyOrdersScreen',
              })
            );
            history.push({
              pathname: '/orderDetails',
              state: {
                pageType: pageType,
              },
            });
          }}
        >
          <Box
            style={{
              width: '65%',
            }}
          >
            <Typography
              component={'div'}
              className={classes.ordersText}
            >{`Order ID: ${val.orderId}`}</Typography>
            {val.vendorName && (
              <Typography
                component={'div'}
                className={classes.ordersText}
              >{`Vendor: ${val.vendorName}`}</Typography>
            )}
            <Typography
              component={'div'}
              className={classes.ordersText}
            >{`Date: ${moment(val.orderDate).format(
              'DD/MM/YYYY, h:mm A'
            )}`}</Typography>
          </Box>
          <Box
            style={{
              width: '35%',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Typography component={'div'} className={classes.ordersText}>{`${t(
              val.status
            )}`}</Typography>
          </Box>
        </Box>
        <Divider />
      </Box>
    );
  };

  const getData = async (currPage, setLastList, setPrevPage) => {
    try {
      let _lastId = lastOrderId;
      if (listData.length) {
        _lastId = listData[listData.length - 1]['orderId'];
      }
      // const response = await Promise.all([
      //   getVendorsPlacedOrders(token, _lastId),
      //   getOrderStates(token),
      // ]);
      const response = await getVendorsPlacedOrders(token, _lastId);

      if (response.successCode === 1) {
        if (!response.response.orders.length && _lastId === lastOrderId) {
          setLastList(true);
          return;
        }
        setLastOrderId(_lastId);
        setPrevPage(currPage);
        let newListData = [...listData, ...response.response.orders];
        setListData(newListData);
        setPOrders(newListData);
        dispatch(setPlacedOrders(newListData));
      } else {
        //
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
      <div>
        <Box
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
          py={1}
        >
          <Box style={{width: '100%'}}>
            <SimpleAutocomplete
              options={
                listData
                  ? listData.map((option, index) => {
                      return lowerCase(option.vendorName);
                    })
                  : ''
              }
              labelText="Search Orders by Vendor Name"
              handleACOnChange={() => {}}
              handleOnChange={handleSearchedOrder}
              searching={searching}
            />
          </Box>

          <Tooltip title="Filter Orders">
            <Button
              variant="outlined"
              className={classes.filterBtn}
              style={{margin: 1}}
              onClick={() => {
                openFilterDialogFunction();
              }}
            >
              <FilterListIcon style={{fontSize: 28}}></FilterListIcon>
            </Button>
          </Tooltip>
          <SimpleDialog
            orders={placedOrders}
            selectedValue={selectedValue}
            open={openFilterDialog}
            onClose={closeFilterDialogFunction}
            listD={listData}
            setListD={setListData}
          />
        </Box>
        <InifiniteScrollComponent
          loading={loading}
          listData={listData}
          scrollListItem={scrollListItem}
          fetchRecords={getData}
          noDataMessage="No Orders Placed"
        />
      </div>
    </ErrorBoundary>
  );
};

export default MyOrdersScreen;
