import {makeStyles} from '@material-ui/core';

const useStyle = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  tabFont: {
    borderLeft: '1px solid gray',
    ['@media (max-width:450px)']: {
      fontSize: '12px',
    },
  },
}));

export default useStyle;
