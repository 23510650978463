import {makeStyles} from '@material-ui/styles';

const useStyle = makeStyles((theme) => ({
  root: {border: 'solid'},
  container: {
    height: '600px',
    position: 'relative',
    backgroundColor: 'white',
    paddinhBottom: '100px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    width: '80%',
    margin: 'auto',
    '& > header > header': {
      position: 'inherit',
      // display: 'flex',
      // justifyContent: 'center',
      // width: '80%',
    },
  },
  sideNav: {
    ['@media (max-width:700px)']: {
      display: 'none',
    },
    //height: '100%',
    width: '92px',
    position: 'fixed',
    zIndex: 1,
    top: 0,
    // right: 0,
    right: '15px',
    overflowX: 'hidden',
    marginTop: 190,
    // marginRight: theme.spacing(1),
    marginRight: 0,
    backgroundColor: '#3B3B3B',
    elevation: 2,
  },
  button: {
    backgroundColor: 'red',
    color: 'white',
    marginTop: '5px',
    '&:hover': {
      backgroundColor: '#4497A8',
      color: 'white',
    },
  },
  fab: {
    position: 'absolute',
    bottom: 10,
    right: 10,
  },
  removeIcon: {
    width: '10px',
    height: '10px',
    '& svg': {
      fontSize: 25,
    },
  },
  pink: {
    height: '25px',
    width: '25px',
  },
  title: {
    fontSize: '16px',
    fontWeight: '900',
  },
  AdminBrdr: {
    border: '1px solid gray',
    borderRadius: '8px',
    display: 'flex',
    justifyContent: 'center',
    width: 'fit-content',
    padding: '0 5px',
  },
}));

export default useStyle;
