/* eslint-disable react/prop-types */
import React, {useState, useEffect} from 'react';
import {useHistory} from 'react-router';
import {get, isEmpty} from 'lodash';
import {useDispatch, useSelector} from 'react-redux';
import useStyle from './styles';
import WebToolbar from '../../components/toolbar/WebToolbar';
import {Box, Divider, Grid, Typography} from '@material-ui/core';
import getCustomerOrders from '../../services/getCustomerOrders';
import ActivityIndicator from '../../components/ActivityIndicator';
import {useTranslation} from 'react-i18next';
import {setOrderId} from '../../redux/actions/user';
import AppbarName from '../../components/DumbComponents/AppBarName';

const CustomerOrders = (props) => {
  const classes = useStyle();
  const history = useHistory();
  const dispatch = useDispatch();

  const token = useSelector((state) => state.user.token);
  const [orders, setOrders] = useState([]);
  const [customerId, setCustomerId] = useState('');
  const [loading, setLoading] = useState(false);
  const {t} = useTranslation(['orderDetails', 'common']);
  const {customer} = props.location.state;

  useEffect(() => {
    getCustomerOrdersData();
    console.log('props', props);
  }, []);

  async function getCustomerOrdersData() {
    try {
      setLoading(true);
      const response = await getCustomerOrders(token, customer.userId);
      if (response.successCode === 1) {
        setOrders(response.response.orders);
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (error) {
      console.log('error', error);
      setLoading(false);
    }
  }

  return (
    <div className={classes.container}>
      <WebToolbar />
      <AppbarName title={`Customer Orders of ${customer.customerName}`} />
      {loading ? (
        <ActivityIndicator />
      ) : (
        <Box py={1}>
          {!isEmpty(orders) ? (
            orders.map((order, index) => {
              return (
                <Box key={index}>
                  <Grid
                    container
                    my={1}
                    style={{
                      display: 'flex',
                      padding: '5px',
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      dispatch(setOrderId({orderId: order.orderId}));
                      history.push('/orderdetails');
                      // history.push({
                      //   pathname: '/orderdetails',
                      //   orderID: order.orderId,
                      // });
                    }}
                  >
                    <Grid item xs={7}>
                      <Typography>
                        Order ID: {get(order, 'orderId', 'NA')}
                      </Typography>
                      <Typography>
                        Order Amount: ₹ {get(order, 'orderAmount', 'NA')}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={5}
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                    >
                      {/* <Typography>{`${t(order.status)}`}</Typography> */}
                      <Typography>{t(get(order, 'status', 'NA'))}</Typography>
                    </Grid>
                  </Grid>
                  <Divider />
                </Box>
              );
            })
          ) : (
            <Box p={2}>
              <Typography>No orders Found</Typography>
            </Box>
          )}
        </Box>
      )}
    </div>
  );
};

export default CustomerOrders;
