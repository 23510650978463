/* eslint-disable react/prop-types */
import {Box, Grid, Switch, Typography, Divider} from '@material-ui/core';
import React from 'react';
import {makeStyles} from '@material-ui/styles';
import Colors from '../theme/Colors';
import PropTypes from 'prop-types';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import moment from 'moment';

const useStyle = makeStyles((theme) => ({
  container: {
    // border: 'solid red',
  },
  textDay: {
    fontSize: 18,
    color: 'black',
    // flex: 1,
  },
  containerTime: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  textTime: {
    fontSize: 16,
    borderBottomColor: 'lightgray',
    borderBottomWidth: 1,
    flex: 1,
    padding: 5,
    marginHorizontal: 5,
  },
}));

const HoursItem = ({
  day,
  open,
  hours,
  toggleSwitch,
  invokeTimePicker,
  addHours,
}) => {
  const classes = useStyle();
  // console.log(
  //   'day',
  //   day,
  //   'open',
  //   open,
  //   'hours',
  //   hours,
  //   'toggleSwitch',
  //   toggleSwitch,
  //   'invokeTimePicker',
  //   invokeTimePicker,
  //   'addHours',
  //   addHours
  // );

  return (
    <Box className={classes.container}>
      <Grid
        container
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Grid
          item
          xs={4}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography className={classes.textDay}>{day}</Typography>
        </Grid>
        <Grid
          item
          xs={1}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Switch
            size="medium"
            checked={open}
            onChange={(value) => toggleSwitch(value.target.checked)}
            color="primary"
            name="checkedB"
            inputProps={{'aria-label': 'primary checkbox'}}
          />
        </Grid>
        <Grid
          item
          xs={5}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography style={{marginLeft: 20, color: 'gray'}}>
            {open ? 'Open' : 'Closed'}
          </Typography>
        </Grid>
      </Grid>
      {open &&
        (hours.length > 0
          ? hours.map((item, index) => {
              return (
                <Box key={index} className={classes.containerTime}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Grid container xs={12}>
                      <Grid
                        item
                        xs={6}
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                        }}
                      >
                        <KeyboardTimePicker
                          id="time-picker"
                          label="Start Time"
                          value={new Date(`Mon 03-Jul-2017, ${item.from}`)}
                          onChange={(time) => {
                            console.log('tie', time);
                            invokeTimePicker(index, 'from', time);
                          }}
                          KeyboardButtonProps={{
                            'aria-label': 'change time',
                          }}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                        }}
                      >
                        <KeyboardTimePicker
                          id="time-picker"
                          label="End Time"
                          value={new Date(`Mon 03-Jul-2017, ${item.to}`)}
                          onChange={(time) =>
                            invokeTimePicker(index, 'to', time)
                          }
                          KeyboardButtonProps={{
                            'aria-label': 'change time',
                          }}
                        />
                      </Grid>
                    </Grid>
                  </MuiPickersUtilsProvider>
                </Box>
              );
            })
          : '')}
      <Divider />
    </Box>
  );
};

HoursItem.propTypes = {
  day: PropTypes.any.isRequired,
  open: PropTypes.any.isRequired,
  hours: PropTypes.any.isRequired,
  toggleSwitch: PropTypes.any.isRequired,
  invokeTimePicker: PropTypes.any.isRequired,
  addHours: PropTypes.any.isRequired,
};

export default HoursItem;
