import {createMuiTheme} from '@material-ui/core/styles';
import Colors from './Colors';

export const theme = createMuiTheme({
  palette: {
    primary: {
      main: Colors.primary,
      dark: Colors.primaryDark,
      light: Colors.primaryLight,
    },
    secondary: {
      main: Colors.secondary,
      dark: Colors.secondaryDark,
      light: Colors.secondaryLight,
    },
  },
  typography: {
    fontFamily: ['Roboto'].join(','),
    button: {
      textTransform: 'none',
    },
  },
  custom: {
    dummyClass: {
      border: 'solid pink',
      backgroundColor: 'yellow',
    },
  },

  overrides: {
    MuiCssBaseline: {
      '@global': {
        '.btn': {
          border: 'solid',
          backgroundColor: 'yellow',
        },
      },
    },
    MuiButton: {
      root: {
        backgroundColor: '#0277BD',
        color: 'white',
        '&:hover': {
          backgroundColor: '#fff',
          color: '#3c52b2',
        },
      },
      contained: {
        // border: 'solid yellow',
        backgroundColor: '#0277BD',
        color: 'white',
        '&:hover': {
          backgroundColor: '#fff',
          color: '#3c52b2',
        },
      },
      outlined: {
        // border: 'solid green',
        backgroundColor: '#0277BD',
        color: 'white',
        '&:hover': {
          backgroundColor: '#fff',
          color: '#3c52b2',
        },
        '&:disabled': {
          border: '1px solid #999999',
          backgroundColor: '#cccccc',
          color: '#666666',
        },
      },
    },
    MuiFab: {
      root: {
        backgroundColor: '#0277BD',
        color: 'white',
        '&:disabled': {
          backgroundColor: '#D5D5D5',
        },
      },
    },
    MuiTabs: {
      indicator: {
        backgroundColor: 'transparent',
        height: '0%',
      },
      root: {
        backgroundColor: '#B0B0B0',
        color: 'black',
        height: 40,
        minHeight: 40,
        display: 'flex',
        alignItems: 'center',
      },
    },
    MuiTab: {
      root: {
        '&:hover': {
          backgroundColor: 'gray',
          color: 'black',
        },
        '&$selected': {
          backgroundColor: '#3578BD',
          color: 'white',
        },
      },
    },
    MuiAppBar: {
      root: {
        // border: 'solid red',
      },
    },

    // dummyClass: {
    //   border: 'solid pink',
    //   backgroundColor: 'yellow',
    // },
  },
});
