import {useDispatch, useSelector} from 'react-redux';
import {setUnreadNotifications} from '../redux/actions/user';
import getNotifications from '../services/getNotifications';

const useNotifications = () => {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.user.token);

  const getNotificationData = async () => {
    try {
      const response = await getNotifications(token);
      if (response.successCode === 1) {
        dispatch(
          setUnreadNotifications(
            Number(response.response.numberOfNotifications)
          )
        );
      }
    } catch (error) {
      console.log(error);
    }
  };
  return {getNotificationData};
};

export default useNotifications;
