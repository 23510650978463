/* eslint-disable react/prop-types */
import {
  Box,
  Button,
  Divider,
  Grid,
  TextField,
  createMuiTheme,
  Typography,
} from '@material-ui/core';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import React, {useState, useEffect} from 'react';
import DateFnsUtils from '@date-io/date-fns';
import useStyle from './style';
import WebToolbar from '../../components/toolbar/WebToolbar';
import AppBarName from '../../components/DumbComponents/AppBarName';
import SideNav from '../../components/sidenav/SideNav';
import getAdvertisementsPricing from '../../services/getAdvertisementsPricing';
import getAdvertisementsPackages from '../../services/getAdvertisementsPackages';
import applyCouponCode from '../../services/applyCouponCode';
import {useSelector} from 'react-redux';
import showToast from '../../components/Toast';
import {get} from 'lodash';
import ActivityIndicator from '../../components/ActivityIndicator';
import {ThemeProvider} from '@material-ui/core/styles';
import createAdvertisement from '../../services/createAdvertisement';
import {useHistory} from 'react-router';
import {SettingsApplicationsRounded} from '@material-ui/icons';
import ErrorBoundary from '../../components/ErrorBoundary';
import ErrorBoundaryFallback from '../../components/ErrorBoundaryFallback';
import MobileFooter from '../MobileFooter';

const data = [
  {
    level: 'Country Level',
    numberOfDays: '15 Days',
    price: 'Price',
    rs: 300,
  },
  {
    level: 'Country Level',
    numberOfDays: '7 Days',
    price: 'Price',
    rs: 200,
  },
  {
    level: 'State Level',
    numberOfDays: '15 Days',
    price: 'Price',
    rs: 250,
  },
  {
    level: 'State Level',
    numberOfDays: '7 Days',
    price: 'Price',
    rs: 150,
  },
];

const AdvertisementPricingDetails = (props) => {
  const newAdDetails = useSelector((state) => {
    console.log(
      'state.user.newAdvertisementDetails',
      state.user.newAdvertisementDetails
    );
    return state.user.newAdvertisementDetails;
  });

  const userDetails = useSelector((state) => {
    return state.user;
  });

  const classes = useStyle();
  const history = useHistory();
  const [couponCode, setCouponCode] = useState('');
  const [selected, setSelected] = useState(false);
  const [_selected, _setSelected] = useState(false);
  const [adPricing, setAdPricing] = useState();
  const [adPackages, setAdPackages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [applied, setApplied] = useState(false);
  const token = useSelector((state) => state.user.token);

  useEffect(() => {
    console.log('props', props);
    getAdPrice();
    getAdPackages();
  }, []);

  async function getAdPrice() {
    setLoading(true);
    try {
      const response = await getAdvertisementsPricing(
        token,
        newAdDetails.AdvertisementType,
        newAdDetails.AdvertisementLevel,
        newAdDetails.selectedCity.id
      );
      console.log('getAd pricing response', response);
      if (response.successCode === 1) {
        setAdPricing(response.response.adPrice);
        setLoading(false);
      } else {
        // history.push('/home');
        showToast('error', 'Failed to get data');
        setLoading(false);
      }
    } catch (error) {
      console.log('errorerror', error);
      setLoading(false);
    }
  }

  async function getAdPackages() {
    setLoading(true);
    try {
      const response = await getAdvertisementsPackages(token);
      console.log('getAd packages', response);
      if (response.successCode === 1) {
        setAdPackages(response.response);
        setLoading(false);
      } else {
        // history.push('/home');
        setLoading(false);
        showToast('error', 'Failed to get data');
      }
    } catch (error) {
      console.log('errorerror', error);
      setLoading(false);
    }
  }

  const handleClick = (data, index) => {
    setSelected(index);
    _setSelected(true);
    console.log('data', data, 'index', index);
  };

  const onPressNext = async () => {
    // history.push('/addupiscreen');
    setLoading(true);
    try {
      console.log('newAdDetails', newAdDetails);
      let v1 = JSON.stringify({
        UserId: userDetails.user.userId,
        AdvertisementType: newAdDetails.AdvertisementType,
        AdvertisementLevel: newAdDetails.AdvertisementLevel,
        pincodes: null,
        AdvText: newAdDetails.AdvertisementText,
        AdvTitle: 'Add title ',
        AdvDesciption: 'Desc',
        RequireNewPage: true,
        NewPageDetailRequest: {
          AdvertisementImage1: newAdDetails.selectedUploadImage,
          AdvertisementImage1Text: 'image 1 Text',
          AdvertisementImage2: newAdDetails.selectedUploadImage,
          AdvertisementImage2Text: 'image 2 Text',
          AdvertisementImage3: newAdDetails.selectedUploadImage,
          AdvertisementImage3Text: 'image 3 Text',
          AdvertisementImage4: newAdDetails.selectedUploadImage,
          AdvertisementImage4Text: 'image 4 Text',
        },
      });

      let v2 = JSON.stringify({
        AdvLevel: newAdDetails.AdvertisementLevel,
        CouponCode: 'XYX',
        AreaId: 1,
        DateFrom: newAdDetails.selectedStartDate,
        DateTo: newAdDetails.selectedEndDate,
        StartTime: newAdDetails.startTime,
        EndTime: newAdDetails.endTime,
        PriceFactorId: 1,
        PaymentState: '',
        Amount: 200,
      });
      const formData = new FormData();
      formData.append('AdvImage', newAdDetails.selectedUploadImage);
      formData.append('advRequest', v1);
      formData.append('advDetail', v2);
      // let data = JSON.stringify({
      //   UserId: "71",
      //   AdvertisementType: 1,
      //   AdvertisementStatus: "",
      //   AdvImage: newAdDetails.selectedUploadImage,
      //   AdvURL: "url",
      //   AdvText: "Add text",
      //   AdvTitle: "Add title ",
      //   AdvDesciption: "Desc",
      //   RequireNewPage: true,
      //   NewPageDetailRequest: {
      //     AdvertisementImage1: newAdDetails.selectedUploadImage,
      //     AdvertisementImage1Text: "image 1 Text",
      //     AdvertisementImage2: newAdDetails.selectedUploadImage,
      //     AdvertisementImage2Text: "image 2 Text",
      //     AdvertisementImage3: newAdDetails.selectedUploadImage,
      //     AdvertisementImage3Text: "image 3 Text",
      //     AdvertisementImage4: newAdDetails.selectedUploadImage,
      //     AdvertisementImage4Text: "image 4 Text",
      //   },
      //   AdvsDetailRequest: {
      //     Advlevel: 1,
      //     AreaId: 1,
      //     DateFrom: new Date(),
      //     DateTo: new Date(),
      //     StartTime: new Date(),
      //     EndTime: new Date(),
      //     CouponCode: "",
      //     PriceFactorId: 1,
      //     PaymentState: "",
      //     Amount: 200,
      //   },
      // })
      // formData.append('adData', data);
      console.log('formData', formData);

      const response = await createAdvertisement(formData, token);
      console.log('response from create add', response);
      if (response.successCode === 1) {
        showToast('success', 'Add Created');
        history.push('/advertisement');
      } else {
        showToast('error', response.returnMessage);
      }
      setLoading(false);
    } catch (error) {
      console.log('error', error);
      setLoading(false);
    }
  };

  const onPressApplyCouponCode = async () => {
    setLoading(true);
    try {
      const payload = {OriginalPrice: adPricing, CouponCode: couponCode};
      const response = await applyCouponCode(token, JSON.stringify(payload));
      console.log('apply coupon response', response);
      if (response.successCode === 1) {
        setApplied(true);
        showToast('success', 'coupon applied successfully');
        setLoading(false);
      } else {
        // history.push('/home');
        showToast('error', 'Failed to apply coupon');
        setLoading(false);
        setApplied(false);
      }
    } catch (error) {
      console.log('errorerror', error);
      setLoading(false);
      setApplied(false);
    }
  };

  return (
    <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
      <div className={classes.container}>
        <WebToolbar />
        <div className={classes.sideNav}>
          <SideNav />
        </div>
        <AppBarName title={'Advertisement Pricing'} />
        {loading ? (
          <ActivityIndicator />
        ) : (
          <>
            <Box p={1} boxShadow={1}>
              <Typography>Advertisement Price ₹: {adPricing} /-</Typography>
            </Box>
            <Box p={1}>
              {adPackages.length > 0
                ? adPackages.map((val, index) => {
                    return (
                      <Box key={index}>
                        <Grid
                          key={index}
                          container
                          onClick={() => {
                            handleClick(data, index);
                          }}
                          className={
                            selected === index
                              ? classes.slectedClass
                              : classes.nonSelectedClass
                          }
                        >
                          <Grid
                            xs={6}
                            item
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                            }}
                          >
                            <Box
                              style={{display: 'flex', flexDirection: 'column'}}
                            >
                              <Typography>Level: {val.level}</Typography>
                              <Typography>Days: {val.numberOfDays}</Typography>
                            </Box>
                          </Grid>
                          <Grid
                            xs={6}
                            item
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                            }}
                          >
                            <Box
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                              }}
                            >
                              <Typography>Type: {val.type}</Typography>
                              {/* <Typography>{val.price}</Typography>
                    <Typography>INR {val.rs}</Typography> */}
                            </Box>
                          </Grid>
                        </Grid>
                        <Divider />
                      </Box>
                    );
                  })
                : ''}
            </Box>
            <Box p={1}>
              {_selected && (
                <Box>
                  <Box
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <Typography style={{fontWeight: '600'}}>
                      Enter Coupon Code -
                    </Typography>
                    <Box
                      style={{
                        width: '80%',
                        display: 'flex',
                        justifyContent: 'space-evenly',
                        alignItems: 'center',
                      }}
                    >
                      <TextField
                        value={couponCode}
                        placeholder="Please enter coupon code"
                        onChange={(text) => setCouponCode(text.target.value)}
                        size="small"
                        variant="outlined"
                        inputProps={{style: {textTransform: 'uppercase'}}}
                      />
                      <Button
                        size="small"
                        variant="outlined"
                        onClick={onPressApplyCouponCode}
                        disabled={couponCode.length === 0}
                      >
                        {applied ? 'Applied' : 'Apply Coupon & Verify Cost'}
                      </Button>
                    </Box>
                  </Box>
                  <Box
                    py={2}
                    style={{display: 'flex', justifyContent: 'space-evenly'}}
                  >
                    <Button
                      className={classes.btn}
                      size="small"
                      variant="outlined"
                      onClick={onPressNext}
                    >
                      Confirm
                    </Button>
                  </Box>
                </Box>
              )}
            </Box>
          </>
        )}
      </div>
      <MobileFooter />
    </ErrorBoundary>
  );
};

export default AdvertisementPricingDetails;
