import useStyles from './styles';
import React, {useEffect, useState} from 'react';
import WebToolbar from '../../../components/toolbar/WebToolbar';
import ActivityIndicator from '../../../components/ActivityIndicator';
import ImageSlider from '../../../components/ImageSlider';
import PincodeModal from '../../../components/PincodeModal';
import getDashboard from '../../../services/getDashboard';
import {useSelector, useDispatch} from 'react-redux';
import SideNav from '../../../components/sidenav/SideNav';
import Categories from '../../../components/Categories';
import VendorSearchbar from '../../../components/VendorSearchbar';
import {
  setAdvertisement,
  setVendorTypes,
  setUnreadNotifications,
  setNearByVendors,
  setFavouriteVendors,
  setShowPincodeModal,
} from '../../../redux/actions/user';
import {Box} from '@material-ui/core';
import ErrorBoundary from '../../../components/ErrorBoundary';
import ErrorBoundaryFallback from '../../../components/ErrorBoundaryFallback';
import {useHistory} from 'react-router';
import VendorList from '../../../components/VendorList';

function WebHome() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const isLoggedIn = useSelector((state) => state.user.isLoggedIn);
  const advertisement = useSelector((state) => state.user.advertisement);
  const token = useSelector((state) => state.user.token);
  const user = useSelector((state) => {
    return state.user.user;
  });
  const vendorTypes = useSelector((state) => state.user.vendorTypes);
  const nearByVendors = useSelector((state) => state.user.nearByVendors);
  const favouriteVendors = useSelector((state) => state.user.favouriteVendors);

  const [loading, setLoading] = useState(false);
  //   const [searching, setSearching] = useState(false);

  const showPincodeModal = useSelector((state) => state.user.showPincodeModal);

  useEffect(() => {
    getData();
  }, []);

  async function getData() {
    try {
      setLoading(true);
      const response = await getDashboard(isLoggedIn, token, user.userId);

      if (response.successCode === 1) {
        dispatch(setAdvertisement(response.response.dashboard.advertisements));
        dispatch(setVendorTypes(response.response.dashboard.vendorTypes));
        dispatch(
          setUnreadNotifications(
            Number(response.response.dashboard.numberOfNotifications)
          )
        );
        localStorage.setItem('data', JSON.stringify(response));
        if (response.response.dashboard.nearByVendors) {
          dispatch(setNearByVendors(response.response.dashboard.nearByVendors));
        }
        if (response.response.dashboard.favouriteVendors) {
          dispatch(
            setFavouriteVendors(response.response.dashboard.favouriteVendors)
          );
        }
        if (response.response.dashboard.favouriteVendors === null) {
          dispatch(setFavouriteVendors(null));
        }
        setLoading(false);
      } else {
        console.log('error');
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      let collection = localStorage.getItem('data');
      if (collection) {
        let data = JSON.parse(collection);
        if (data) {
          dispatch(setAdvertisement(data.response.dashboard.advertisements));
          dispatch(setVendorTypes(data.response.dashboard.vendorTypes));
          if (data.response.dashboard.nearByVendors) {
            setNearByVendors(data.response.dashboard.nearByVendors);
          }
          if (data.response.dashboard.favouriteVendors) {
            setFavouriteVendors(data.response.dashboard.favouriteVendors);
          }
        }
      }
      console.log('error', error);
    }
  }

  return (
    <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
      <div className={classes.container}>
        <WebToolbar fetchNotifications={false} />
        <PincodeModal
          visible={showPincodeModal}
          hide={() => dispatch(setShowPincodeModal(false))}
        />
        <div className={classes.main}>
          <div className={classes.sideNav}>
            <SideNav />
          </div>
          <div className={classes.content}>
            {loading ? (
              <ActivityIndicator />
            ) : (
              <Box my={1}>
                <VendorSearchbar
                  showPincodeModal={() => dispatch(setShowPincodeModal(true))}
                />
                <ImageSlider
                  images={advertisement}
                  onImageClick={(clickedElement) => {
                    if (clickedElement.requireNewPage) {
                      history.push('/advertisementdetails');
                    } else {
                      history.push(
                        `/vendordetails/${clickedElement?.vendorId}`
                      );
                    }
                  }}
                />

                <Categories
                  categories={vendorTypes}
                  showPincodeModal={() => dispatch(setShowPincodeModal(true))}
                />

                {favouriteVendors !== null && favouriteVendors?.length > 0 && (
                  <VendorList
                    vendors={favouriteVendors}
                    title="Favourite Vendors"
                  />
                )}
                {nearByVendors?.length > 0 && (
                  <VendorList vendors={nearByVendors} title="Nearby Vendors" />
                )}
              </Box>
            )}
          </div>
        </div>
      </div>
    </ErrorBoundary>
  );
}

export default WebHome;
