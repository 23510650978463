/* eslint-disable react/prop-types */
import React, {useEffect, useState} from 'react';
import Drawer from '../drawer';
import LoginDialog from '../LoginDialog';
import Badge from '@material-ui/core/Badge';
import NotificationsIcon from '@material-ui/icons/Notifications';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import MenuIcon from '@material-ui/icons/Menu';
import IconButton from '@material-ui/core/IconButton';
import ImageDialog from '../../components/DumbComponents/ImageDialog';
import ic_logo1 from '../../assets/icons/geartenlogo.png';
import TermsDialog from '../TermsDialog';
import {useSelector, useDispatch} from 'react-redux';
import {useHistory} from 'react-router-dom';
import logoutService from '../../services/logout';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

import {setLoggedIn, setSelectedLanguage} from '../../redux/actions/user';
import useStyles from './styles';
import {
  Button,
  Typography,
  AppBar,
  Toolbar,
  createMuiTheme,
  responsiveFontSizes,
  Box,
  MuiThemeProvider,
  Grid,
  Avatar,
} from '@material-ui/core';
import i18n from 'i18next';
import {useTranslation} from 'react-i18next';
import showToast from '../Toast';
import ImagePreview from '../DumbComponents/ImagePreview';
import {get} from 'lodash';
import CustomizedDialog from '../DumbComponents/CustomizedDialog';
import useNotifications from '../../helpers/useNotifications';

let theme = createMuiTheme();
theme = responsiveFontSizes(theme);

const titleMessage = 'Welcome to GearConnect!';
const modalText =
  'A made in India App which is uniquely customized for Indian style of order related communication between Buyers and Sellers.';

const points = [
  'Buyer can send Order request to a Vendor through a Message/Chat, Photo or attachment and even for items not listed in Product/Service Catalogue of Vendor.',
  'Allowing Buyer and Seller to communicate after creation of Order request through Message/Chat, Photo or attachment). ',
  'Payment request by Vendor to a Buyer for a purchase made by Buyer outside of App.',
  'Allowing Buyer and Sellers to Cancel/Reject Order Request/Payment Request.',
  'Groups & Baskets - Allowing Buyer and Seller to create Groups and Order Baskets (within a Group) for Collaborative Buying or Selling to group of users or promoting Products/Services in a group.',
];
const WebToolbar = ({fetchNotifications = true}) => {
  const classes = useStyles();
  const {t} = useTranslation(['homeScreen', 'common']);
  const isLoggedIn = useSelector((state) => state.user.isLoggedIn);
  const [loading, setLoading] = useState(false);
  const [showLoginDialog, setShowLoginDialog] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const token = useSelector((state) => state.user.token);
  const {getNotificationData} = useNotifications();
  const unreadNotifications = useSelector(
    (state) => state.user.unreadNotifications
  );

  useEffect(() => {
    if (fetchNotifications) getNotificationData();
  }, []);

  const user = useSelector((state) => {
    // console.log('user', state.user.user);
    return state.user.user;
  });
  const [imageViewer, setImageViewer] = useState(false);

  const [state, setState] = useState({
    mobileView: false,
    drawerOpen: false,
  });
  const {mobileView, drawerOpen} = state;

  useEffect(() => {
    const currLang = localStorage.getItem('lang');
    i18n.changeLanguage(currLang);
    const setResponsiveness = () => {
      return window.innerWidth < 900
        ? setState((prevState) => ({...prevState, mobileView: true}))
        : setState((prevState) => ({...prevState, mobileView: false}));
    };
    setResponsiveness();
    window.addEventListener('resize', () => setResponsiveness());
    return () => {
      window.removeEventListener('resize', () => setResponsiveness());
    };
  }, []);

  function handleDrawerClose() {
    setState((prevState) => ({...prevState, drawerOpen: false}));
  }

  function handleDrawerOpen() {
    setState((prevState) => ({...prevState, drawerOpen: true}));
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (l) => {
    console.log('l', l);
    i18n.changeLanguage(l);
    localStorage.setItem('lang', l);

    dispatch(
      setSelectedLanguage(
        l === 'en' ? 'English' : l === 'hi' ? 'हिंदी' : 'मराठी'
      )
    );

    setAnchorEl(null);
  };

  function openMenu(menu) {
    console.log('mm', menu);
    if (menu === 'Privacy Policy') {
      history.push('/privacy-policy');
    } else if (menu === 'Terms of Use') {
      history.push('/terms-of-use');
    } else if (menu === 'Build Catalouge') {
      history.push('/buildcatalouge');
    } else if (menu === 'Favourite Vendors') {
      history.push('/favouritevendors');
    } else if (menu === 'Reset Password') {
      history.push('/resetpasswordscreen');
    } else if (menu === 'Change Password') {
      history.push('/changepasswordscreen');
    } else if (menu === 'Update Profile') {
      history.push('/updateprofilescreen');
    } else if (menu === 'Edit Vendor Details') {
      history.push('/editdetailshome');
    } else if (menu === 'Contact Us') {
      history.push('/contactus');
    } else if (menu === 'FAQ') {
      history.push('/faq');
    } else if (menu === 'Feedback') {
      history.push('/feedback');
    } else if (menu === 'Advertisement') {
      history.push('/advertisement');
    } else if (menu === 'About') {
      history.push('/aboutscreen');
    } else if (menu === 'home') {
      history.push('/home');
    } else if (menu === 'Become Vendor') {
      history.push('/addvendorshopdetails');
    }
  }

  async function handleLogout(event) {
    console.log('event', event);
    event.preventDefault();
    setLoading(true);

    try {
      const response = await logoutService(token);
      console.log('logout response', response);
      if (response.successCode === 1) {
        // dispatch(setUser(response.response));
        dispatch(setLoggedIn(false));
        history.push('/login');
      } else if (response.messageCode === '400300124') {
        // navigation.replace('OTP', {userName: email});
      } else {
        showToast('error', response.returnMessage);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log('response error', error);
    }
  }
  const closeModalFunc = () => {
    setShowModal(false);
  };

  function onPressDp() {
    console.log('user.profilePicture', user.username);
    if (user.profilePicture) {
      // setShowDpModal(true);
      setImageViewer(true);
    } else {
      console.log('In setImageModal');
      // setOpenDialog(!openDialog);
      // setImageModal(true);
    }
  }
  const closeImagePreviewDialogFunction = () => {
    setImageViewer(false);
  };

  const closeDialogFunction = () => {
    setOpenDialog(false);
    //Camera
    // setShowCamera(!showCamera);
  };

  const handleListItemClick = (val) => {
    setOpenDialog(false);
  };

  const userInitials = get(user, 'username.0');

  return (
    <header>
      <MuiThemeProvider theme={theme}>
        <TermsDialog />
        <LoginDialog
          visible={showLoginDialog}
          hide={() => setShowLoginDialog(false)}
        />

        <AppBar
          position="fixed"
          className={classes.appBar}
          style={{background: '#fff'}}
        >
          <Toolbar className={classes.toolbar}>
            <Grid container>
              <Grid item xs={6} style={{display: 'flex'}}>
                {isLoggedIn && (
                  <Box
                    style={{
                      display: 'flex',
                      // justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <Drawer
                      className={classes.drawerCont}
                      onMenuSelected={(menu) => {
                        console.log('menu', menu);
                        handleDrawerClose();
                        openMenu(menu);
                        // openMenu(menu.target.textContent);
                      }}
                      open={drawerOpen}
                      handleDrawerClose={handleDrawerClose}
                    />
                    <IconButton onClick={handleDrawerOpen} aria-label="drawer">
                      <MenuIcon className={classes.drawerItem} />
                    </IconButton>
                  </Box>
                )}
                <Box className={classes.logoContainer}>
                  <Box className={classes.appLogoContainer}>
                    <img
                      // src={ic_logo}
                      src={ic_logo1}
                      className={classes.appLogo}
                      onClick={() => {
                        history.push('/home');
                      }}
                    />
                  </Box>
                  <Box className={classes.appNameContainer}>
                    <Typography
                      onClick={() => {
                        history.push('/home');
                      }}
                      className={classes.appName}
                      // className={classes.drawerItem}
                      variant="h5"
                      noWrap
                    >
                      GearConnect™
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid
                item
                xs={6}
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                }}
              >
                {isLoggedIn && (
                  <Box
                    px={1}
                    style={{
                      display: 'flex',
                      justifyContent: 'end',
                      alignItems: 'center',
                    }}
                  >
                    <div style={{display: 'flex', alignItems: 'center'}}>
                      {/* <Box>
                <Button
                  aria-controls="simple-menu"
                  aria-haspopup="true"
                  variant="outlined"
                  className={classes.buttonLang}
                  onClick={handleClick}
                  endIcon={<ArrowDropDownOutlinedIcon />}
                >
                  <Typography>{selectedLanguage}</Typography>
                </Button>
                <Menu
                  id="simple-menu"
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={() => {
                    setAnchorEl(null);
                  }}
                >
                  <MenuItem onClick={() => handleClose('en')}>English</MenuItem>
                  <MenuItem onClick={() => handleClose('hi')}>हिंदी</MenuItem>
                  <MenuItem onClick={() => handleClose('mr')}>मराठी</MenuItem>
                </Menu>
              </Box> */}
                      <IconButton
                        aria-label="show 11 new notifications"
                        onClick={() => {
                          history.push('savedorders');
                        }}
                      >
                        <Badge badgeContent={0} max={9} color="error">
                          <ShoppingCartIcon className={classes.drawerItem} />
                        </Badge>
                      </IconButton>
                      <IconButton
                        aria-label={`show ${unreadNotifications} new notifications`}
                        onClick={() => {
                          history.push('notifications');
                        }}
                      >
                        <Badge
                          badgeContent={unreadNotifications}
                          max={9999}
                          color="error"
                        >
                          <NotificationsIcon className={classes.drawerItem} />
                        </Badge>
                      </IconButton>
                    </div>
                  </Box>
                )}
                {!mobileView && (
                  <Box
                    px={1}
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    {isLoggedIn ? (
                      <Button
                        className={classes.logoutBtn}
                        variant="outlined"
                        size="small"
                        onClick={(event) => {
                          setShowLoginDialog(false);
                          handleLogout(event);
                        }}
                      >
                        {t('logout')}
                      </Button>
                    ) : (
                      <Button
                        className={classes.logoutBtn}
                        variant="outlined"
                        type="submit"
                        onClick={() => setShowLoginDialog(true)}
                      >
                        {t('login')}
                      </Button>
                    )}
                  </Box>
                )}
                {isLoggedIn && (
                  <Box
                    px={1}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    {user.profilePicture ? (
                      <Avatar
                        className={classes.purple}
                        src={user.profilePicture}
                        alt={userInitials}
                        onClick={() => {
                          onPressDp();
                        }}
                      />
                    ) : (
                      <Avatar
                        className={classes.purple}
                        onClick={() => {
                          onPressDp();
                        }}
                      >
                        {userInitials}
                      </Avatar>
                    )}
                    <ImagePreview
                      open={imageViewer}
                      onSimpleDialogClose={closeImagePreviewDialogFunction}
                      image={user.profilePicture}
                    />
                    <Box>
                      <ImageDialog
                        open={openDialog}
                        onSimpleDialogClose={closeDialogFunction}
                        message="Pick Image From?"
                        handleListItemClick={handleListItemClick}
                      />
                    </Box>
                  </Box>
                )}
                <Box
                  style={{
                    display: 'flex',
                    justifyContent: 'end',
                    alignItems: 'center',
                  }}
                >
                  <IconButton
                    aria-label="show 11 new notifications"
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      setShowModal(!showModal);
                    }}
                  >
                    <Badge>
                      <InfoOutlinedIcon className={classes.drawerItem} />
                    </Badge>
                  </IconButton>
                  <CustomizedDialog
                    title={titleMessage}
                    handleClose={closeModalFunc}
                    open={showModal}
                  >
                    <>
                      <Typography gutterBottom>{modalText}</Typography>
                      <Box pl={4}>
                        {points.map((point, index) => {
                          return (
                            <Typography
                              key={index}
                              gutterBottom
                              style={{display: 'list-item'}}
                            >
                              {point}
                            </Typography>
                          );
                        })}
                      </Box>
                    </>
                  </CustomizedDialog>
                </Box>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
      </MuiThemeProvider>
    </header>
  );
};

export default WebToolbar;
