import baseUrl from '../utils/Constants';

export default async function (payload, token) {
  console.log('payload token', payload, token);
  const response = await fetch(`${baseUrl}/orders/generation`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(payload),
  });
  const responseJson = await response.json();
  return responseJson;
}
