import baseUrl from '../utils/Constants';

export default async function (token, payload) {
  const response = await fetch(`${baseUrl}/advertisements/applycoupon`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: payload,
  });
  console.log(response);
  const responseJson = await response.json();
  return responseJson;
}
