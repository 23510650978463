import React, {useEffect} from 'react';
import {Box, Grid, Typography} from '@material-ui/core';
import AppBarName from '../../../components/DumbComponents/AppBarName';
import SideNav from '../../../components/sidenav/SideNav';
import WebToolbar from '../../../components/toolbar/WebToolbar';
// import getAdvertisementTypes from '../../../services/getAdvertisementsTypes';
import useStyle from './style';
import {useDispatch} from 'react-redux';
import {setTypeOfAdvertisement} from '../../../redux/actions/user';
import {useHistory} from 'react-router';
import MobileFooter from '../../MobileFooter';
// import { useSelector } from 'react-redux';

const PromotionalAdvertisement = () => {
  const classes = useStyle();
  const dispatch = useDispatch();
  const history = useHistory();

  const addTypes = {
    type: 0,
    typeName: 'Free Promotional Advertisement',
  };

  // const token = useSelector((state) => state.user.token);

  // useEffect(() => {
  //     getAddTypes();
  // }, []);

  // const getAddTypes = async () => {
  //     try {
  //         const response = await getAdvertisementTypes(token);
  //         console.log('getads response', response);
  //     } catch (error) {
  //         console.log(error);
  //     }
  // }
  return (
    <>
      <div className={classes.container}>
        <WebToolbar />
        <div className={classes.sideNav}>
          <SideNav />
        </div>
        <AppBarName title={'Create Advertisement'} />
        <Box px={1}>
          <Typography variant="h6">Select type of advertisement :</Typography>
        </Box>
        <Box p={1}>
          <Grid container xs={12} spacing={3}>
            <Grid item xs={8}>
              <Box
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  border: '1px solid gray',
                  backgroundColor: '#3578BD',
                  color: 'white',
                  borderRadius: '5px',
                  height: '100%',
                  padding: '5px',
                  cursor: 'pointer',
                  width: '20rem',
                }}
                onClick={() => {
                  dispatch(setTypeOfAdvertisement(addTypes));
                  history.push('/advertisementdetails');
                }}
              >
                <Typography variant="h6">{addTypes.typeName}</Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </div>
      <MobileFooter />
    </>
  );
};

export default PromotionalAdvertisement;
