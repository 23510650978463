/* eslint-disable react/prop-types */
import {Box, Button, Checkbox, TextField, Typography} from '@material-ui/core';
import React, {useEffect, useState} from 'react';
import {useHistory, useParams} from 'react-router';
import useStyle from './style';
import getDefaultImages from '../../../services/getDefaultImages';
import {useDispatch, useSelector} from 'react-redux';
import showToast from '../../../components/Toast';
import WebToolbar from '../../../components/toolbar/WebToolbar';
import AppBarName from '../../../components/DumbComponents/AppBarName';
import SideNav from '../../../components/sidenav/SideNav';
import moment from 'moment';
import AdDetails from '../AdDetails';
import ActivityIndicator from '../../../components/ActivityIndicator';
import getListOfStates from '../../../services/getListOfStates';
import {setNewAdvertisementDetails} from '../../../redux/actions/user';
import ErrorBoundary from '../../../components/ErrorBoundary';
import ErrorBoundaryFallback from '../../../components/ErrorBoundaryFallback';
import {get, isEmpty} from 'lodash';
import SimpleDialog from '../../../components/DumbComponents/SimpleDialog';
import MobileFooter from '../../MobileFooter';

const AdvertisementDetails = (props) => {
  // const {typeName, type} = props.location.state.ads;
  const classes = useStyle();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  // const [level, setLevel] = useState(false);
  const token = useSelector((state) => state.user.token);
  let item;
  if (get(props, 'location.state')) {
    item = props?.location?.state?.item;
  }
  let {id} = useParams();

  const [defaultImages, setDefaultImages] = useState([]);
  const [states, setStates] = useState([]);
  const [open, setOpen] = useState({selectedItem: null, open: false});

  const typeOfAd = useSelector((state) => {
    console.log(
      'state.user.typeOfAdvertisement',
      state.user.typeOfAdvertisement
    );
    return state.user.typeOfAdvertisement;
  });
  const dispatch = useDispatch();
  console.log('item', item, typeOfAd);

  useEffect(() => {
    getStates();
    getDefaultImg();
  }, []);

  async function getAdvertisementDetails() {
    setLoading(true);
    try {
      // call advertisement details api and get advertisement information
      // const response = await getListOfStates(token);
      // console.log('get states', response.response);
      // if (response.successCode === 1) {
      //   setStates(response.response.area);
      // } else {
      //   console.log('err');
      // }
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }

  async function getStates() {
    setLoading(true);
    try {
      const response = await getListOfStates(token);
      console.log('get states', response.response);
      if (response.successCode === 1) {
        setStates(response.response.area);
        // setStates(
        //   response.response.area.map((item) => {
        //     return item.name;
        //   })
        // );
        // setLevel(3);
      } else {
        console.log('err');
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }

  async function getDefaultImg() {
    setLoading(true);
    try {
      const response = await getDefaultImages(token);
      if (response.successCode === 1) {
        setDefaultImages(response.response);
      } else {
        showToast('error', 'Failed to get data');
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }

  const onPressDeleteAdd = () => {
    setOpen({
      open: true,
      selectedItem: item,
    });
    console.log('delete button click');
  };

  async function onPressCreateAdd(adDetailsState) {
    setLoading(true);
    const data = {
      AdvertisementType: typeOfAd.type,
      AdvertisementLevel: adDetailsState.level,
      selectedImage: adDetailsState.selectedImage,
      selectedUploadImage: adDetailsState.selectedUploadImage,
      AdvertisementText: adDetailsState.title,
      AdvertisementDetails: adDetailsState.detailsText,
      // RequireNewPage: adDetailsState.checkedNP,
      // startTime: moment(adDetailsState.startTime).format('LT'),
      // endTime: moment(adDetailsState.endTime).format('LT'),
      selectedStartDate: moment(adDetailsState.selectedStartDate).format('L'),
      // selectedEndDate: moment(adDetailsState.selectedEndDate).format('L'),
      selectedState: adDetailsState.selectedState,
      selectedCity: adDetailsState.selectedCity,
      selectedPincode: adDetailsState.selectedPincode,
    };
    if (adDetailsState.advertisementLevel === 'city') {
      data['areaId'] = get(adDetailsState, 'selectedCity.id', '');
    } else if (adDetailsState.advertisementLevel === 'pincode') {
      data['areaId'] = get(adDetailsState, 'selectedPincode.id', '');
    }
    dispatch(setNewAdvertisementDetails(data));
    if (adDetailsState.checkedNP) {
      history.push('/linktonewpage');
    } else {
      history.push('/advertisementpreview');
    }
  }

  const onDeleteConfirmationNoClick = () => {
    setOpen({open: false, selectedItem: null});
  };

  const onDeleteConfirmationYesClick = () => {
    // Delete API call
    console.log(open);
    setOpen({open: false, selectedItem: null});
  };

  return (
    <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
      <div className={classes.container}>
        <WebToolbar />
        <div className={classes.sideNav}>
          <SideNav />
        </div>
        <AppBarName
          title={
            id ? 'Edit Advertisement Details' : 'New Advertisement Details'
          }
        />
        {loading ? (
          <ActivityIndicator />
        ) : (
          <Box>
            {typeOfAd.type === 0 ? (
              <AdDetails
                onPressCreateAdd={onPressCreateAdd}
                onPressDeleteAdd={onPressDeleteAdd}
                typeOfAd={typeOfAd}
                loading={loading}
                setLoading={setLoading}
                defaultImages={defaultImages}
                states={states}
                editData={item}
                isEdit={!isEmpty(item)}
                advertisementId={id}
              />
            ) : (
              ''
            )}
          </Box>
        )}
        <SimpleDialog
          open={open?.open}
          onSimpleDialogClose={onDeleteConfirmationNoClick}
          message={'Are you sure to delete ?'}
          handleBasketListItemClick={onDeleteConfirmationYesClick}
        />
      </div>
      <MobileFooter />
    </ErrorBoundary>
  );
};

export default AdvertisementDetails;
