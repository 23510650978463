import React from 'react';
import {
  Box,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import {camelCase, lowerCase, startCase} from 'lodash';

const SimpleSelect = ({
  title,
  label,
  val,
  selectedEle,
  setLoc,
  required = false,
  error = false,
  helperText = '',
  disabled = false,
}) => {
  return (
    <FormControl
      variant="outlined"
      fullWidth
      size="small"
      error={error}
      disabled={disabled}
      required={required}
    >
      <InputLabel required={true} id="label-for-appliances">
        {title}
      </InputLabel>

      <Select
        label={label}
        fullWidth
        variant="outlined"
        required={required}
        error={error}
        // native
        value={JSON.stringify(selectedEle) || ''}
        onChange={setLoc}
        inputProps={{
          name: 'selectedEle',
          id: 'outlined-age-native-simple',
        }}
      >
        {val.map((element) => {
          return (
            <MenuItem
              key={element.id}
              value={JSON.stringify({
                id: element.id,
                code: element.code,
                name: element.name,
                cities: element.cities,
              })}
            >
              {startCase(camelCase(element.name)) || element.pinCode}
            </MenuItem>
          );
          /* <option key={index} value={JSON.stringify(element)}>
              {element.name || element.pinCode}
            </option> */
        })}
      </Select>
      <FormHelperText>{helperText}</FormHelperText>
    </FormControl>
  );
};

SimpleSelect.propTypes = {
  title: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  val: PropTypes.array.isRequired,
  selectedEle: PropTypes.any.isRequired,
  setLoc: PropTypes.any.isRequired,
  required: PropTypes.bool,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  disabled: PropTypes.bool,
};

export default SimpleSelect;
