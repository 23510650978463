import {makeStyles} from '@material-ui/core';

const useStyle = makeStyles(() => ({
  container: {
    backgroundColor: 'white',
    // padding: 10,
    flex: 1,
    width: '80%',
    margin: 'auto',
    '& > header > header': {
      position: 'inherit',
      // display: 'flex',
      // justifyContent: 'center',
      // width: '80%',
    },
  },
  phdContainer: {display: 'flex', alignItems: 'center'},
  btnContainer: {display: 'flex', justifyContent: 'center'},
}));

export default useStyle;
