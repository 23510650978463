import {makeStyles} from '@material-ui/styles';
import Colors from '../../theme/Colors';

const useStyle = makeStyles((theme) => ({
  container: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    paddingBottom: '100px',
    width: '80%',
    margin: 'auto',
    backgroundColor: '#fff',
    '& > header > header': {
      position: 'inherit',
      // display: 'flex',
      // justifyContent: 'center',
      // width: '80%',
    },
  },
  sideNav: {
    ['@media (max-width:700px)']: {
      display: 'none',
    },
    //height: '100%',
    width: '92px',
    position: 'fixed',
    zIndex: 1,
    top: 0,
    // right: 0,
    right: '15px',
    overflowX: 'hidden',
    marginTop: 190,
    // marginRight: theme.spacing(1),
    marginRight: 0,
    backgroundColor: '#3B3B3B',
    elevation: 2,
  },
  textBtn: {
    color: 'white',
    fontSize: 24,
  },
  containerTime: {
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
  },
  textTime: {
    fontSize: 16,
    borderBottomColor: 'lightgray',
    borderBottomWidth: 1,
    flex: 1,
  },
  textTitle: {
    fontSize: 16,
    color: 'black',
    fontWeight: 'bold',
  },
  textInput: {
    borderRadius: 5,
    backgroundColor: '#F5F5F5',
  },
}));

export default useStyle;
