import {makeStyles} from '@material-ui/styles';
import Colors from '../../theme/Colors';

const useStyle = makeStyles((theme) => ({
  btn: {
    borderRadius: 4,
  },
  container: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    paddingBottom: '100px',
    position: 'relative',
    height: '650px',
    backgroundColor: 'white',
    // padding: 10,
    flex: 1,
    width: '80%',
    margin: 'auto',
    '& > header > header': {
      position: 'inherit',
      // display: 'flex',
      // justifyContent: 'center',
      // width: '80%',
    },
  },
  sideNav: {
    ['@media (max-width:700px)']: {
      display: 'none',
    },
    //height: '100%',
    width: '92px',
    position: 'fixed',
    zIndex: 1,
    top: 0,
    // right: 0,
    right: '15px',
    overflowX: 'hidden',
    marginTop: 190,
    // marginRight: theme.spacing(1),
    marginRight: 0,
    backgroundColor: '#3B3B3B',
    elevation: 2,
  },
  itemsContainer: {
    height: '470px',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto',
  },
  btnSelectAll: {display: 'flex', justifyContent: 'end'},
  itemsNamePrice: {
    display: 'flex',
    alignItems: 'center',
  },
  checkboxContaienr: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  deletBtn: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  editBtn: {display: 'flex', alignItems: 'center'},
  fab: {
    position: 'absolute',
    bottom: 25,
    right: 80,
    '&:disabled': {
      border: '1px solid #999999',
      backgroundColor: '#cccccc',
      color: '#666666',
    },
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },

  selected: {color: Colors.secondary, marginHorizontal: 5},
  nonSelected: {color: 'gray', marginHorizontal: 5},
}));

export default useStyle;
