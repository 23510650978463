import {makeStyles} from '@material-ui/core';

const useStyle = makeStyles((theme) => ({
  appBar: {
    margin: 0,
    backgroundColor: '#4497A8',
  },
  closeButton: {
    position: 'absolute',
    right: '2px',
    top: '2px',
    color: 'white',
    padding: '5px',
  },
  title: {
    // marginLeft: theme.spacing(2),
    // flex: 1,
    fontSize: '16px',
    fontWeight: 900,
  },
}));

export default useStyle;
