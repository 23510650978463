import React, {useState, useEffect} from 'react';
import WebToolbar from '../../components/toolbar/WebToolbar';
import useStyle from './styles';
import {AppBar, Tabs, Tab} from '@material-ui/core';
import VendorPaymentRequests from '../VendorPaymentRequests';
import {useSelector} from 'react-redux';
import SideNav from '../../components/sidenav/SideNav';
import AppBarName from '../../components/DumbComponents/AppBarName';

import {
  a11yProps,
  TabPanel,
} from '../../components/DumbComponents/TapPanelProps';
import ErrorBoundary from '../../components/ErrorBoundary';
import ErrorBoundaryFallback from '../../components/ErrorBoundaryFallback';
import MobileFooter from '../MobileFooter';
import {constants} from '../../utils/common';

const PaymentRequestsScreen = () => {
  const classes = useStyle();
  const [value, setValue] = useState(0);
  const forceUpdate = useState()[1].bind(null, {});
  const user = useSelector((state) => state.user.user);
  const {PAYMENT_REQUEST_CREATED, PAYMENT_REQUEST_RECEIVED} = constants;
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    forceUpdate();
  }, [user.isVendor]);

  return user.isVendor ? (
    <>
      <div className={classes.container}>
        <WebToolbar />
        <AppBarName title={'Payment Request'} />
        <div className={classes.sideNav}>
          <SideNav />
        </div>
        {/* <SimpleTabs /> */}
        <AppBar position="static" elevation={0}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="simple tabs example"
            variant="fullWidth"
          >
            <Tab
              className={classes.tabWidth}
              label="Received"
              {...a11yProps(0)}
            />
            <Tab
              className={classes.tabWidth}
              label="Created"
              {...a11yProps(1)}
            />
          </Tabs>
        </AppBar>
        <TabPanel value={value} index={0}>
          <VendorPaymentRequests
            isVendor={false}
            pageType={PAYMENT_REQUEST_RECEIVED}
          />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <VendorPaymentRequests
            isVendor={true}
            pageType={PAYMENT_REQUEST_CREATED}
          />
        </TabPanel>
      </div>
      <MobileFooter />
    </>
  ) : (
    <>
      <div className={classes.container}>
        <WebToolbar />
        <AppBarName title={'Received Payment Request'} />
        <div className={classes.sideNav}>
          <SideNav />
        </div>
        <div style={{marginTop: '2rem'}}>
          <VendorPaymentRequests
            isVendor={false}
            pageType={PAYMENT_REQUEST_RECEIVED}
          />
        </div>
      </div>
      <MobileFooter />
    </>
  );
};

export default PaymentRequestsScreen;
