import {Box, Typography} from '@material-ui/core';
import React from 'react';
import AppBarName from '../../components/DumbComponents/AppBarName';
import SideNav from '../../components/sidenav/SideNav';
import WebToolbar from '../../components/toolbar/WebToolbar';
import ErrorBoundary from '../../components/ErrorBoundary';
import ErrorBoundaryFallback from '../../components/ErrorBoundaryFallback';
import useStyle from './style';
import {ImportContacts} from '@material-ui/icons';
import MobileFooter from '../MobileFooter';

const AdvertisementConfirmation = () => {
  const classes = useStyle();
  return (
    <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
      <div className={classes.container}>
        <WebToolbar />
        <div className={classes.sideNav}>
          <SideNav />
        </div>
        <AppBarName title={'Advertisement Confirmation'} />
        <Box p={2}>
          <Typography variant="h4">To be done...</Typography>
        </Box>
      </div>
      <MobileFooter />
    </ErrorBoundary>
  );
};

export default AdvertisementConfirmation;
