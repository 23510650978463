/* eslint-disable react/prop-types */
import useStyle from './style';
import WebToolbar from '../../components/toolbar/WebToolbar';
import React, {useEffect, useState, useCallback} from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {Box, TextField, Typography, Grid, Button} from '@material-ui/core';
import {get, lowerCase, debounce} from 'lodash';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import searchvendor from '../../services/searchBasketVendor';
import {useSelector} from 'react-redux';
import DeleteIcon from '@material-ui/icons/Delete';
import Divider from '@material-ui/core/Divider';
import createBasket from '../../services/createBasket';
import addBasketVendor from '../../services/addBasketVendor';
import showToast from '../../components/Toast';
import {useTranslation} from 'react-i18next';
import {useHistory} from 'react-router';
import ActivityIndicator from '../../components/ActivityIndicator';
import AppBarName from '../../components/DumbComponents/AppBarName';
import SideNav from '../../components/sidenav/SideNav';
import SimpleAutocomplete from '../../components/DumbComponents/SimpleAutocomplete';
import ErrorBoundary from '../../components/ErrorBoundary';
import ErrorBoundaryFallback from '../../components/ErrorBoundaryFallback';
import MobileFooter from '../MobileFooter';

const AddBasketVendors = (props) => {
  const {getList, isNew} = props.location.state;
  const classes = useStyle();
  const {t} = useTranslation('basket');
  const token = useSelector((state) => state.user.token);
  const [searchResults, setSearchResults] = useState([]);
  const [selectedItem, setSelectedItem] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searching, setSearching] = useState(false);
  const history = useHistory();

  // const [items, setItems] = useState([]);
  useEffect(() => {
    console.log('props', props);
  }, []);

  async function onChangeText(text) {
    console.log('txt', text);
    // setQuery(text);
    if (text.length > 0) {
      handler(text);
    } else {
      setSearchResults([]);
      setLoading(false);
      setSearching(false);
    }
  }

  const handler = useCallback(
    debounce(async (text) => {
      console.log('text in handler', text);
      if (!text.trim()) {
        return;
      }
      try {
        const response = await searchvendor(token, text);
        console.log('search vendor response', response);
        if (response.successCode === 1) {
          const data = response.response.customerVendors;

          const obj = {
            description: text,
            isVendorItem: false,
            name: text,
            price: '',
          };
          data.push(obj);
          setSearchResults(data);
          setSearching(false);
        } else {
          setSearching(false);
          const data = [];
          const obj = {
            description: text,
            isVendorItem: false,
            name: text,
          };
          data.push(obj);
          // showToast('error', "No item's found");
          setSearchResults(data);
        }
        setLoading(false);
        setSearching(false);
      } catch (error) {
        setLoading(false);
        setSearching(false);
      }
    }, 500),
    []
  );

  function removeItem(index) {
    console.log('selectedItemvsvsvs', selectedItem);
    const data = selectedItem;
    console.log('data', data);
    data.splice(index, 1);
    console.log('dataaaaa', data);
    setSelectedItem([...data]);
  }

  async function onPressNext() {
    if (!isNew) {
      const data = props.location.state.payload;
      const temp = [];
      selectedItem.forEach((element) => {
        const obj = {VendorId: element.vendorId};
        temp.push(obj);
      });
      data.TentativeVendors = temp;
      setLoading(true);
      try {
        const response = await createBasket(data, token);
        console.log('response create basket', response);
        if (response.successCode === 1) {
          getList();
          // const popAction = StackActions.pop(3);
          // navigation.dispatch(popAction);
          history.replace('/basketdetails');
          showToast('success', 'Basket Created');
        } else {
          showToast('danger', response.title);
        }
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    } else {
      if (selectedItem.length === 0) {
        showToast('error', 'Please add vendor');
        return;
      } else {
        setLoading(true);
        try {
          const {groupId, id} = props.location.state.payload;
          console.log(groupId, id);
          const vendors = [];
          selectedItem.forEach((item) => {
            vendors.push(item.vendorId);
          });
          const response = await addBasketVendor(
            {VendorIds: vendors},
            id,
            groupId,
            token
          );
          console.log('response add basket vendor', response);
          if (response.successCode === 1) {
            // navigation.goBack();
            history.push('/basketdetails');
            showToast('success', 'Vendor added');
          } else {
            showToast('error', t(response.returnMessage));
          }
          setLoading(false);
        } catch (error) {
          console.log(error);
          setLoading(false);
        }
      }
    }
  }

  const onChangeValue = (value) => {
    console.log('value', value);
    //   setQuery(value.name);
    // const data = [];
    // data.push(value);
    // setSelectedItem(data);
    setSelectedItem((oldArray) => [...oldArray, value]);

    // setSearchResults([]);
  };

  return (
    <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
      <div className={classes.container}>
        <WebToolbar />
        <div className={classes.sideNav}>
          <SideNav />
        </div>
        <AppBarName title={'Add Basket Vendors'} />

        {loading ? (
          <ActivityIndicator />
        ) : (
          <>
            {/* <Box>
            <SimpleAutocomplete
              options={
                searchResults
                  ? searchResults.map((option) => {
                      console.log(';oo', option);
                      return lowerCase(option);
                    })
                  : ''
              }
              handleACOnChange={(value) => {
                return onChangeValue(value);
              }}
              getOptionLabel={(option) => {
                console.log(';11oo', option.organizationName);
                return option ? lowerCase(option.organizationName) : 'NA';
              }}
              labelText="Search Items"
              handleOnChange={onChangeText}
              searching={searching}
            />
          </Box> */}
            <Box py={1}>
              <Autocomplete
                id="free-solo-demo"
                freeSolo
                disableClearable={true}
                size="small"
                options={
                  searchResults ??
                  searchResults.map((item) => {
                    console.log('item', item);
                    return item.organizationName
                      ? lowerCase(item.organizationName)
                      : 'NA';
                  })
                }
                getOptionLabel={(searchResults) =>
                  searchResults
                    ? lowerCase(searchResults.organizationName)
                    : 'NA'
                }
                onChange={(event, value) => {
                  console.log('value', value);
                  //   setQuery(value.name);
                  // const data = [];
                  // data.push(value);
                  // setSelectedItem(data);
                  setSelectedItem((oldArray) => [...oldArray, value]);

                  // setSearchResults([]);
                }}
                renderInput={(searchItem) => (
                  <TextField
                    className={classes.ordersText}
                    {...searchItem}
                    //   label="Search Items"
                    onChange={(search) => {
                      onChangeText(search.target.value);
                    }}
                    placeholder="Search Vendors"
                    variant="outlined"
                    InputProps={{
                      ...searchItem.InputProps,
                      startAdornment: (
                        <>
                          <InputAdornment position="start">
                            <SearchIcon />
                          </InputAdornment>
                          {searchItem.InputProps.startAdornment}
                        </>
                      ),
                    }}
                  />
                )}
              />
            </Box>
            <Box py={2}>
              {console.log('selectedItem.length', selectedItem.length)}
              {selectedItem.length > 0 ? (
                selectedItem.map((itm, index) => {
                  return (
                    <Box key={index}>
                      <Grid container>
                        <Grid item xs={1} className={classes.vendors}>
                          <Typography>{`${index + 1}.`}</Typography>
                        </Grid>
                        <Grid item xs={5} className={classes.vendors}>
                          <Typography>{itm.organizationName}</Typography>
                        </Grid>

                        <Grid item xs={5} className={classes.vendors}>
                          <DeleteIcon
                            color="error"
                            style={{cursor: 'pointer'}}
                            onClick={() => {
                              removeItem(index, itm);
                            }}
                          />
                        </Grid>
                      </Grid>
                      <Divider />
                    </Box>
                  );
                })
              ) : (
                <Box p={2}>
                  <Typography>No Vendor added...</Typography>
                </Box>
              )}
            </Box>
            <Box className={classes.btnCont}>
              <Button variant="outlined" onClick={onPressNext}>
                Add
              </Button>
            </Box>
          </>
        )}
      </div>
      <MobileFooter />
    </ErrorBoundary>
  );
};

export default AddBasketVendors;
