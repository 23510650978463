import React, {useState, useEffect} from 'react';
import WebToolbar from '../../components/toolbar/WebToolbar';
import useStyle from './style';
import {
  TextField,
  Box,
  Grid,
  Button,
  InputAdornment,
  CircularProgress,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import SendOutlinedIcon from '@material-ui/icons/SendOutlined';
import DialpadOutlinedIcon from '@material-ui/icons/DialpadOutlined';
import {useHistory} from 'react-router';
import showToast from '../../components/Toast';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import newOrder from '../../services/sendOrder';
import {setBillDetails} from '../../redux/actions/user';
import SideNav from '../../components/sidenav/SideNav';
import AppbarName from '../../components/DumbComponents/AppBarName';
import MobileFooter from '../MobileFooter';

const CreatePaymentRequest = () => {
  const {t} = useTranslation('signUp');
  const classes = useStyle();
  const history = useHistory();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const token = useSelector((state) => state.user.token);
  const vendorId = useSelector((state) => state.user.vendorId);
  const user = useSelector((state) => state.user.user);
  const [mobileNumber, setMobileNumber] = useState();
  const [amount, setAmount] = useState();
  const [message, setMessage] = useState('');

  useEffect(() => {
    dispatch(setBillDetails(null));
  }, []);

  async function onPressSend() {
    var regexp = /^([6-9]{1})([0-9]{9})$/;
    if (!regexp.test(mobileNumber)) {
      showToast('warn', t('invalidMobileNumber'));
      return;
    }
    if (!amount) {
      showToast('warn', 'Enter amount');
      return;
    }
    if (!message) {
      showToast('warn', 'Enter message');
      return;
    }
    try {
      setLoading(true);
      const payload = {
        vendorid: Number(vendorId),
        //mode: 1,
        IsService: 1,
        OrderItems: [],
        orderimages: [],
        ordermessages: [
          {
            messageText: message,
            createdBy: user.userId,
            createdOn: new Date(),
          },
        ],
        CustomerMobile: mobileNumber,
        OrderType: 1,
        OrderAmount: Number(amount),
      };

      const response = await newOrder(payload, token);
      setLoading(false);
      if (response.successCode === 1) {
        showToast('success', 'Request sent');
        // history.push('/paymentrequestsscreen');
        history.goBack();
      } else {
        showToast('error', 'Failed');
      }
    } catch (error) {
      setLoading(false);

      showToast('error', 'Failed');
    }
  }

  function onPress() {
    if (!mobileNumber) {
      showToast('error', 'Enter mobile number');
      return;
    }
    if (!amount) {
      showToast('error', 'Enter amount');
      return;
    }
    if (!message) {
      showToast('error', 'Enter message');
      return;
    }

    var regexp = /^([6-9]{1})([0-9]{9})$/;
    if (!regexp.test(mobileNumber.trim())) {
      showToast('error', t('invalidMobileNumber'));
      return;
    }

    dispatch(
      setBillDetails({
        mobileNumber: mobileNumber,
        amount: amount,
        message,
      })
    );
    history.replace('/addbilldetails');
    // history.replace({
    //   pathname: 'addbilldetails',
    //   state: {
    //     mobileNumber: mobileNumber,
    //     amount: amount,
    //     message,
    //   },
    // });
  }

  return (
    <>
      <div className={classes.container}>
        <WebToolbar />
        <AppbarName title="Payment Request" />
        <div className={classes.sideNav}>
          <SideNav />
        </div>
        <Box my={2}>
          <Box p={1}>
            <Grid container direction={'column'} spacing={3}>
              <Grid item>
                <TextField
                  size="small"
                  autoComplete="off"
                  fullWidth
                  type="number"
                  id="outlined-basic"
                  className={classes.textfieldClass}
                  onInput={(e) => {
                    e.target.value = Math.max(0, parseInt(e.target.value))
                      .toString()
                      .slice(0, 10);
                  }}
                  label="Mobile Number"
                  variant="outlined"
                  onChange={(value) => {
                    setMobileNumber(value.target.value);
                  }}
                />
              </Grid>
              <Grid item>
                <TextField
                  size="small"
                  autoComplete="off"
                  fullWidth
                  type="number"
                  onInput={(e) => {
                    // e.target.value = Math.max(0, parseInt(e.target.value))
                    //   .toString()
                    //   .slice(0, 7);
                  }}
                  className={classes.textfieldClass}
                  id="outlined-basic"
                  label="Amount"
                  variant="outlined"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">₹</InputAdornment>
                    ),
                  }}
                  onChange={(value) => {
                    setAmount(value.target.value);
                  }}
                />
              </Grid>
              <Grid item>
                <TextField
                  size="small"
                  autoComplete="off"
                  fullWidth
                  inputProps={{maxLength: 100}}
                  id="outlined-basic"
                  label="Message"
                  variant="outlined"
                  onChange={(value) => {
                    setMessage(value.target.value);
                  }}
                />
              </Grid>
            </Grid>
          </Box>
          <Box className={classes.btnContainerOne} py={2}>
            {/* <Button
            variant="outlined"
            size="small"
            onClick={() => {
              // history.push('paymentrequestsscreen');
              history.goBack();
            }}
          >
            Back
          </Button> */}
            <Button
              variant="outlined"
              size="small"
              className={classes.button}
              startIcon={<AddIcon />}
              onClick={onPress}
            >
              Add Bill/Details
            </Button>
            <Button
              variant="outlined"
              size="small"
              className={classes.button}
              startIcon={loading ? <></> : <SendOutlinedIcon />}
              disabled={loading}
              onClick={onPressSend}
            >
              {loading ? (
                <CircularProgress color="white" size={24} />
              ) : (
                'Send Payment Request'
              )}
            </Button>
          </Box>
        </Box>
      </div>
      <MobileFooter />
    </>
  );
};

export default CreatePaymentRequest;
