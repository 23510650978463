import baseUrl from '../utils/Constants';

export default async function (token, StateId) {
  const response = await fetch(`${baseUrl}/areas/states/${StateId}/cities`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });
  const responseJson = await response.json();
  return responseJson;
}
