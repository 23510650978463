/* eslint-disable react/prop-types */
import React, {useState, useEffect} from 'react';
import {useSelector} from 'react-redux';
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Fab,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
  Zoom,
} from '@material-ui/core';
import ActivityIndicator from '../../../components/ActivityIndicator';
import addVendorItems from '../../../services/addVendorItems';
import getCategoryCatalouge from '../../../services/getItems';
import Colors from '../../../theme/Colors';
import {useHistory} from 'react-router';
import showToast from '../../../components/Toast';
import ErrorBoundary from '../../../components/ErrorBoundary';
import ErrorBoundaryFallback from '../../../components/ErrorBoundaryFallback';
import WebToolbar from '../../../components/toolbar/WebToolbar';
import SideNav from '../../../components/sidenav/SideNav';
import AppBarName from '../../../components/DumbComponents/AppBarName';
import useStyle from './styles';

const CatalougeAddItemsScreen = (props) => {
  //   const {item, getData} = route.params;
  const [listData, setListData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [reqesting, setReqesting] = useState(false);
  const token = useSelector((state) => state.user.token);
  const vendorId = useSelector((state) => state.user.vendorId);
  const forceUpdate = useState()[1].bind(null, {});
  const classes = useStyle();
  const history = useHistory();

  const {item} = (props.location && props.location.state) || {};

  console.log('item', item);
  useEffect(() => {
    getList();
  }, []);

  const getList = async () => {
    try {
      const response = await getCategoryCatalouge(token, vendorId, item.id);
      console.log('response', response);
      if (response.successCode === 1) {
        if (response.messageCode === '200200296') {
          //   history.push('/buildcatalouge');
          //   navigation.goBack();
          showToast('No items found', 'danger');
          return;
        }
        const items = response.response.items;
        items.forEach((element) => {
          element.isSelected = false;
        });
        setListData(items);
        setLoading(false);
      } else if (response.messageCode === '200200296') {
        // history.push('/buildcatalouge');
        // navigation.goBack();
        showToast('No items found', 'danger');
      } else {
        // history.push('/buildcatalouge');
        // navigation.goBack();
        showToast('Failed', 'danger');
      }
    } catch (error) {
      //   history.push('/buildcatalouge');
      //   navigation.goBack();
      showToast('Failed', 'danger');
      setLoading(false);
    }
  };

  const onPressCheck = (item, index) => {
    const data = [...listData];
    listData[index].isSelected = !listData[index].isSelected;
    setListData(data);
    forceUpdate();
  };

  const onChangeText = (index, text) => {
    if (!isNaN(text)) {
      const data = [...listData];
      listData[index].price = text;
      setListData(data);
      forceUpdate();
    }
  };

  const onPressAdd = async () => {
    try {
      const items = [];
      listData.forEach((element) => {
        if (element.isSelected) {
          items.push({
            Name: element.name,
            Description: element.description ? element.description : '',
            Price: element.price ? Number(element.price) : 0,
          });
        }
      });
      if (items.length === 0) {
        showToast('Please select items', 'danger');
        return;
      }
      setReqesting(true);
      const response = await addVendorItems(
        {
          VendorItems: items,
        },
        token
      );
      if (response.successCode === 1) {
        // getData();
        history.push('/buildcatalouge');
        showToast('Items added', 'success');
      } else {
        showToast('Failed to add items', 'danger');
      }
      setReqesting(false);
    } catch (error) {
      console.log(error);
      setReqesting(false);
    }
  };

  return (
    <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
      <Box className={classes.container}>
        <WebToolbar />
        <div className={classes.sideNav}>
          <SideNav />
        </div>
        <AppBarName title={'Add Items'} />
        {loading ? (
          <ActivityIndicator
            size="large"
            color={Colors.secondary}
            className={classes.activityIndicator}
          />
        ) : (
          <Box className={classes.content}>
            <Box className={classes.listContainer}>
              {listData.length > 0 ? (
                listData.map((item, index) => {
                  return (
                    <Box key={index}>
                      <Grid
                        container
                        className={classes.containerItem}
                        spacing={2}
                      >
                        <Grid className={classes.containerColumn}>
                          <Grid
                            item
                            s={4}
                            className={classes.textAndCheckboxContainer}
                          >
                            <Checkbox
                              checked={item.isSelected}
                              color="primary"
                              disabled={loading}
                              onClick={() => onPressCheck(item, index)}
                            />
                            <TextField
                              type="text"
                              size="small"
                              className={classes.textInput}
                              placeholder="Price ₹"
                              value={item.price}
                              disabled={!item.isSelected}
                              label="Price ₹"
                              name="price"
                              variant="outlined"
                              multiline={false}
                              onChange={(event) =>
                                onChangeText(index, event.target.value)
                              }
                              required
                            />
                          </Grid>
                          <Grid
                            item
                            s={8}
                            className={classes.itemNameContainer}
                          >
                            <Typography
                              className={classes.textItem}
                              numberOfLines={2}
                            >
                              {item.name}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Box>
                  );
                })
              ) : (
                <Box p={2}>
                  <Typography>No Catagories Found</Typography>
                </Box>
              )}
            </Box>
            <Zoom in={true} timeout={{enter: 500, exit: 500}} unmountOnExit>
              <Fab
                variant="extended"
                size="large"
                aria-label="add"
                className={classes.fab}
                onClick={(e) => onPressAdd(e)}
              >
                {loading ? (
                  <CircularProgress color="white" size={24} />
                ) : (
                  <Typography>Add</Typography>
                )}
              </Fab>
            </Zoom>
          </Box>
        )}
      </Box>
    </ErrorBoundary>
  );
};

export default CatalougeAddItemsScreen;
