import {makeStyles} from '@material-ui/styles';
import Avatar from '@material-ui/core/Avatar';
import {deepOrange} from '@material-ui/core/colors';
import Colors from '../../theme/Colors';

// const {width} = window.innerWidth;
const {innerWidth: width, innerHeight: height} = window;

const maxWidth = width * 0.3;

const useStyle = makeStyles((theme) => ({
  root: {},
  container: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    paddingBottom: '100px',
    backgroundColor: 'white',
    width: '80%',
    margin: 'auto',
    '& > header > header': {
      position: 'inherit',
      // display: 'flex',
      // justifyContent: 'center',
      // width: '80%',
    },
  },
  sideNav: {
    ['@media (max-width:700px)']: {
      display: 'none',
    },
    //height: '100%',
    width: '92px',
    position: 'fixed',
    zIndex: 1,
    top: 0,
    // right: 0,
    right: '15px',
    overflowX: 'hidden',
    marginTop: 190,
    // marginRight: theme.spacing(1),
    marginRight: 0,
    backgroundColor: '#3B3B3B',
    elevation: 2,
  },
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  table: {
    minWidth: 650,
  },
  chatSection: {
    backgroundColor: 'white',
    width: '100%',
    height: '80vh',
    overflow: 'hidden',
    position: 'relative',
  },
  headBG: {
    backgroundColor: '#e0e0e0',
  },
  borderRight500: {
    borderRight: '1px solid #e0e0e0',
  },
  messageArea: {
    height: '70vh',
    overflowY: 'auto',
  },
  imageViewer: {
    margin: 0,
  },
  toolar: {
    height: 55,
    width: '100%',
    flexDirection: 'row',
    backgroundColor: 'gray',
    alignItems: 'center',
  },
  containerOrderNow: {
    // height: 45,
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: Colors.secondary,
    alignItems: 'center',
  },
  textGroupName: {
    fontSize: 20,
    color: 'white',
    marginHorizontal: 5,
  },
  textOrderNow: {
    fontSize: 16,
    color: 'white',
    fontWeight: '900',
    marginLeft: '4%',
    flex: 1,
  },
  iconOrderNow: {color: 'white', marginRight: '3%'},
  containerTitle: {
    flex: 1,
  },
  list: {
    //paddingTop: 5,
    flexGrow: 1,
    justifyContent: 'flex-end',
  },
  btnBack: {
    padding: 10,
  },
  cardSelfMsg: {
    paddingHorizontal: 10,
    paddingVertical: 5,
    margin: 2,
    maxWidth: maxWidth,
    marginLeft: 'auto',
    backgroundColor: Colors.secondary,
    elevation: 2,
    borderTopLeftRadius: 15,
    borderTopRightRadius: 0,
    borderBottomLeftRadius: 15,
    borderBottomRightRadius: 15,
    minWidth: 70,
  },
  cardReceivedMsg: {
    paddingHorizontal: 10,
    paddingVertical: 5,
    margin: 3,
    maxWidth: maxWidth,
    marginRight: 'auto',
    backgroundColor: 'white',
    elevation: 2,
    minWidth: 70,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 15,
    borderBottomLeftRadius: 15,
    borderBottomRightRadius: 15,
  },
  textSelfMsg: {
    padding: '5px',
    wordWrap: 'break-word',
    fontSize: 15,
    color: 'black',
  },
  textReceivedMsg: {
    wordWrap: 'break-word',
    display: 'block',
    fontSize: 14,
  },
  row: {
    flexDirection: 'row',
  },
  textDatedOn: {
    padding: '5px',
    fontSize: 11,
    textAlign: 'right',
    color: 'black',
  },
  textSender: {
    fontSize: 16,
    color: 'black',
    fontWeight: '800',
  },
  cardReceivedImgMsg: {
    padding: 8,
    margin: 3,
    width: 220,
    marginRight: 'auto',
    backgroundColor: Colors.primary,
    elevation: 2,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 15,
    borderBottomLeftRadius: 15,
    borderBottomRightRadius: 15,
    minWidth: 70,
  },
  cardSelfImgMsg: {
    padding: 8,
    margin: 3,
    width: 220,
    marginLeft: 'auto',
    backgroundColor: Colors.secondary,
    elevation: 2,
    minWidth: 70,
    borderTopLeftRadius: 15,
    borderTopRightRadius: 0,
    borderBottomLeftRadius: 15,
    borderBottomRightRadius: 15,
  },
  selfImage: {
    height: 150,
    width: 206,
    borderRadius: 15,
    backgroundColor: '#dcdcdc',
  },
  containerFile: {
    width: 206,
    alignItems: 'center',
    flexDirection: 'row',
  },
  texFile: {
    fontSize: 16,
    color: 'white',
  },
  textInputMsg: {
    [`& fieldset`]: {
      borderRadius: 20,
    },
  },
  input: {
    display: 'none',
  },
  __container: {
    zIndex: 10,
    marginVertical: 1,
  },
  containerSelected: {
    backgroundColor: 'rgba(0,151,167,0.4)',
    zIndex: 10,
    marginVertical: 1,
  },
  text: {
    fontSize: 13,
    paddingHorizontal: 5,
    paddingVertical: 1,
    color: 'white',
    backgroundColor: 'gray',
    borderRadius: 6,
    alignSelf: 'center',
    marginTop: 2,
  },
  appBarStyle: {
    backgroundColor: '#4497A8',
    // height: '50px',
    display: 'flex',
    justifyContent: 'center',
  },
  appBarTxtCont: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    cursor: 'pointer',
  },
  appBarIconCont: {
    width: '30%',
    display: 'flex',
    justifyContent: 'space-around',
  },
  dateSeparatorCont: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  btnTxtIpContainer: {display: 'flex', height: '10vh', alignItems: 'center'},
  sendBtnContainer: {display: 'flex', justifyContent: 'center'},
}));

export default useStyle;
