/* Copyright (C) 2020 Gearten Technologies Private Limited - All Rights Reserved
 NOTICE:  All information contained herein is, and remains
 * the property of Gearten Technologies Private Limited
 * The intellectual and technical concepts contained
 * herein are proprietary to Gearten Technologies Private Limited.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Gearten Technologies Private Limited.
 */
import {createStore} from 'redux';
import {persistStore, persistReducer} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import rootReducer from '../reducers/index';

const persistConfig = {
  key: 'root',
  storage: storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);
// export const store = createStore(persistedReducer, undefined);
export const store = createStore(
  persistedReducer,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);
export const persister = persistStore(store);
