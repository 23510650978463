import baseUrl from '../utils/Constants';

export default async function (token, payload) {
  console.log('payload', payload, 'token', token);
  const response = await fetch(`${baseUrl}/orders/save`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: payload,
  });
  const responseJson = await response.json();
  return responseJson;
}
