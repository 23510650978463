import {makeStyles} from '@material-ui/styles';

const useStyle = makeStyles((theme) => ({
  root: {border: 'solid'},
  container: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    paddingBottom: '100px',
    minHeight: '586px',
    backgroundColor: 'white',
    width: '80%',
    margin: 'auto',
    '& > header > header': {
      position: 'inherit',
      // display: 'flex',
      // justifyContent: 'center',
      // width: '80%',
    },
  },
  sideNav: {
    ['@media (max-width:700px)']: {
      display: 'none',
    },
    //height: '100%',
    width: '92px',
    position: 'fixed',
    zIndex: 1,
    top: 0,
    // right: 0,
    right: '15px',
    overflowX: 'hidden',
    marginTop: 190,
    // marginRight: theme.spacing(1),
    marginRight: 0,
    backgroundColor: '#3B3B3B',
    elevation: 2,
  },
  basketDetailsContainer: {
    cursor: 'pointer',
  },
  nameStatusContainer: {display: 'flex', justifyContent: 'space-between'},
  nameTxt: {fontWeight: '900', fontSize: 18, color: 'black'},
  txt: {fontSize: 16, color: 'gray'},

  disabledButton: {
    // height: 50,
    // width: 50,
    position: 'fixed',
    bottom: '10%',
    right: '11%',
  },
  textActive: {
    backgroundColor: 'green',
    color: 'white',
    paddingHorizontal: 5,
    borderRadius: 4,
    paddingLeft: '5px',
    paddingRight: '5px',
    display: 'flex',
    alignItems: 'center',
  },
  textClosed: {
    backgroundColor: 'red',
    color: 'white',
    paddingHorizontal: 5,
    borderRadius: 4,
    paddingLeft: '5px',
    paddingRight: '5px',
    display: 'flex',
    alignItems: 'center',
  },
  textFreeze: {
    backgroundColor: 'orange',
    color: 'white',
    paddingHorizontal: 5,
    borderRadius: 4,
    paddingLeft: '5px',
    paddingRight: '5px',
    display: 'flex',
    alignItems: 'center',
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
}));

export default useStyle;
