import React, {useState} from 'react';

const DemoPage = () => {
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');

  const onSignup = () => {
    console.log('email', email, 'phoneNumber', phoneNumber);
  };

  const renderData = () => {
    return <div>Hello</div>;
  };

  return (
    <div style={{border: '1px solid red'}}>
      <form
        onSubmit={() => {
          onSignup();
        }}
      >
        <input
          type="text"
          placeholder="Enter Email ID"
          value={email}
          onChange={(e) => {
            setEmail(e.target.value);
          }}
        ></input>
        <input
          type="number"
          value={phoneNumber}
          placeholder="Enter Phone Number"
          onChange={(e) => {
            setPhoneNumber(e.target.value);
          }}
        ></input>
        <button type="submit">Sign Up</button>
        {/* {renderData} */}
      </form>
    </div>
  );
};

export default DemoPage;
