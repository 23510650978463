import React from 'react';
import {
  Typography,
  Dialog,
  AppBar,
  Toolbar,
  Box,
  DialogTitle,
} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import PropTypes from 'prop-types';
import useStyle from './style';
import {TransformWrapper, TransformComponent} from 'react-zoom-pan-pinch';
import {s3BaseUrl} from '../../../utils/Constants';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ImagePreview = ({open, onSimpleDialogClose, image}) => {
  const classes = useStyle();
  return (
    <Dialog
      //   fullScreen
      open={open}
      onClose={onSimpleDialogClose}
      TransitionComponent={Transition}
    >
      <DialogTitle disableTypography className={classes.appBar}>
        <IconButton
          edge="start"
          color="inherit"
          onClick={onSimpleDialogClose}
          className={classes.closeButton}
          aria-label="close"
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Box
        style={{
          //   display: 'flex',
          //   justifyContent: 'center',
          height: '500px',
          width: '600px',
        }}
      >
        <TransformWrapper>
          <TransformComponent>
            <img
              // src="https://www.cricbuzz.com/a/img/v1/152x152/i1/c171004/sachin-tendulkar.jpg"
              src={image}
              style={{width: '600px', height: '500px'}}
            ></img>
          </TransformComponent>
        </TransformWrapper>
      </Box>
    </Dialog>
  );
};

ImagePreview.propTypes = {
  open: PropTypes.bool,
  onSimpleDialogClose: PropTypes.func,
  image: PropTypes.any.isRequired,
};

export default ImagePreview;
