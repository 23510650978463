import {makeStyles} from '@material-ui/core';
import {blue} from '@material-ui/core/colors';

const drawerWidth = 260;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  title: {
    flexGrow: 1,
    color: 'black',
  },
  drawer: {
    width: drawerWidth,
  },
  menuItem: {
    // backgroundColor: '#3c52b2',
    // color: '#fff',
    '&:hover': {
      backgroundColor: '#ADD8E6',
    },

    height: '5%',
    [theme.breakpoints.down('sm')]: {
      minHeight: '5%',
    },
  },
  textSize: {
    '& span, & svg': {
      fontSize: '15px',
    },
  },
  textSizeIcon: {
    display: 'flex',
    justifyContent: 'center',
    '& span, & svg': {
      fontSize: '15px',
    },
  },
  container: {
    margin: 0,
    padding: 0,
    height: '100%',
  },
  header: {
    backgroundColor: '#3578BD',
    display: 'flex',
    alignItems: 'center',
    // backgroundColor: theme.palette.secondary.main,
    margin: 0,
    padding: theme.spacing(1),
  },
  info: {display: 'flex', flexDirection: 'column'},
  avatar: {
    width: theme.spacing(9),
    height: theme.spacing(9),
  },
  textName: {
    color: '#fff',
    marginTop: theme.spacing(1),
    fontSize: 16,
  },
  textEmail: {
    color: '#fff',
    fontSize: 12,
  },
  _avatar: {
    backgroundColor: blue[100],
    color: blue[600],
  },
}));

export default useStyles;
