import {makeStyles} from '@material-ui/styles';
import Colors from '../../theme/Colors';

const useStyle = makeStyles((theme) => ({
  container: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    paddingBottom: '100px',
    flex: 1,
    width: '80%',
    margin: 'auto',
    '& > header > header': {
      position: 'inherit',
      // display: 'flex',
      // justifyContent: 'center',
      // width: '80%',
    },
    paddingBottom: 50,
    backgroundColor: 'white',
  },
  sideNav: {
    ['@media (max-width:700px)']: {
      display: 'none',
    },
    //height: '100%',
    width: '92px',
    position: 'fixed',
    zIndex: 1,
    top: 0,
    // right: 0,
    right: '15px',
    overflowX: 'hidden',
    marginTop: 190,
    // marginRight: theme.spacing(1),
    marginRight: 0,
    backgroundColor: '#3B3B3B',
    elevation: 2,
  },
  profilePicContainer: {display: 'flex', justifyContent: 'center'},
  profilePicNAContainer: {height: '100px', width: '100px'},
  textInput: {
    borderRadius: 5,
    flex: 1,
  },
  btn: {
    backgroundColor: '#3578BD',
    color: 'white',
    width: '40%',
    borderRadius: '30px',
  },
  card: {
    padding: 10,
    borderRadius: 10,
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  textGender: {
    fontSize: 18,
    color: 'black',
    marginRight: 20,
    // fontWeight: 'bold',
  },
  textMale: {
    fontSize: 16,
    color: 'black',
  },
  viewImg: {
    border: 'solid',
    display: 'flex',
    height: 100,
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    marginBottom: 20,
  },
  image: {
    height: 100,
    width: 100,
    borderRadius: 50,
  },
  btnPlus: {
    position: 'absolute',
    top: 0,
    right: 0,
    backgroundColor: Colors.secondary,
    elevation: 2,
    height: 36,
    width: 36,
    borderRadius: 18,
    justifyContent: 'center',
    alignItems: 'center',
  },
  placeholder: {
    // display: 'flex',
    border: 'solid red',
    height: 100,
    width: 100,
    borderRadius: 50,
    backgroundColor: 'lightgray',
    justifyContent: 'center',
    alignItems: 'center',
  },
  textBtn: {
    color: 'white',
    fontSize: 24,
  },
  activityIndicator: {
    // height: height - 55,
  },
  modalCamera: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  containerCamModal: {
    width: 250,
    backgroundColor: 'white',
    paddingVertical: 10,
    paddingHorizontal: 5,
    borderRadius: 4,
    justifyContent: 'center',
  },
  textModal: {
    fontSize: 16,
    flex: 1,
  },
  containerItem: {
    flexDirection: 'row',
    alignItems: 'center',
    padding: 10,
  },
  containerPicker: {
    // backgroundColor: '#F5F5F5',
    borderColor: 'gray',
  },
  picker: {
    //width: width - 40,
  },
  textPicker: {
    color: '#989898',
    backgroundColor: '#F5F5F5',
    fontSize: 12,
  },
  chip: {
    elevation: 2,
    backgroundColor: 'lightgray',
    width: 130,
    justifyContent: 'center',
    alignItems: 'center',
  },
  chipSelected: {
    backgroundColor: Colors.secondary,
    elevation: 2,
    width: 130,
    justifyContent: 'center',
    alignItems: 'center',
  },
  containerCategories: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: 10,
    // borderWidth: 0.7,
    // width: width - 30,
  },

  root: {
    alignSelf: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    '& > *': {},
  },
  input: {
    display: 'none',
  },
  large: {
    display: 'flex',
    border: 'solid red',
    height: 100,
    width: 100,
    borderRadius: 50,
    backgroundColor: 'lightgray',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

export default useStyle;
