/* eslint-disable react/prop-types */
import React from 'react';
import {makeStyles} from '@material-ui/styles';
import {Box, Grid, Typography} from '@material-ui/core';
import Colors from '../theme/Colors';
import CheckBoxOutlineBlankOutlinedIcon from '@material-ui/icons/CheckBoxOutlineBlankOutlined';
import CheckBoxOutlinedIcon from '@material-ui/icons/CheckBoxOutlined';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';

const useStyle = makeStyles((theme) => ({
  card: {
    paddingHorizontal: 10,
    paddingVertical: 4,
  },
  textTitle: {
    fontSize: 15,
    fontWeight: 'bold',
    flex: 1,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  icon: {
    marginHorizontal: 5,
  },
}));

const CatalogueItem = ({
  item,
  onPress,
  onPressEdit,
  onPressDelete,
  showEdit,
  isShare,
}) => {
  const classes = useStyle();
  return (
    <Box className={classes.card} onClick={() => onPress(item)}>
      <Grid container xs={12}>
        <Grid item xs={10}>
          <Typography className={classes.textTitle} numberOfLines={2}>
            {item.name}
          </Typography>
          {/* <Text numberOfLines={2}>{item.description}</Text> */}
          {item.price ? (
            <Typography>{`Price: ₹ ${item.price}`}</Typography>
          ) : null}
        </Grid>
        <Grid item xs={2} style={{display: 'flex', alignItems: 'center'}}>
          {isShare ? (
            <Box className={classes.row}>
              <Box onClick={() => onPress(item)}>
                {item.isSelected ? (
                  <CheckBoxOutlinedIcon />
                ) : (
                  <CheckBoxOutlineBlankOutlinedIcon />
                )}
              </Box>
            </Box>
          ) : (
            <Box className={classes.row}>
              <Box onClick={() => onPressDelete(item)}>
                <DeleteOutlineOutlinedIcon
                  style={{color: 'red', cursor: 'pointer'}}
                />
              </Box>
              {showEdit && (
                <Box onClick={() => onPressEdit(item)}>
                  <EditOutlinedIcon />
                </Box>
              )}
            </Box>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default CatalogueItem;
