import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  main: {
    height: '100vh',
  },
  containerLeft: {
    display: 'flex',
    // justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    padding: theme.spacing(3),
  },
  containerRight: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2, 4),
  },
  rememberMeSection: {
    alignItems: 'center',
    [theme.breakpoints.down('350')]: {
      display: 'block',
    },
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
  },
  textWelcome: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  form: {
    marginTop: theme.spacing(2),
    width: '100%',
    flex: 1,
  },
  input_email: {
    // margin: theme.spacing(2, 0, 2, 0),
    margin: '10px !important',
  },
  input_password: {
    // margin: theme.spacing(2, 0, 2, 0),
    margin: '10px !important',
  },
  button: {
    width: '100%',
    marginTop: theme.spacing(2),
  },
  buttonGetStarted: {
    borderRadius: theme.spacing(5),
    // padding: theme.spacing(1.5, 1.5, 1.5, 1.5),
  },
}));

export default useStyles;
