import {TextField, CircularProgress} from '@material-ui/core';
import {Autocomplete} from '@material-ui/lab';
import React, {useState} from 'react';
import {lowerCase} from 'lodash';

const index = ({
  options,
  labelText,
  handleOnChange,
  handleACOnChange,
  getOptionLabel,
  value,
  setValue,
  searching,
}) => {
  return (
    <div>
      <Autocomplete
        id="asynchronous-demo"
        // style={{marginBottom: 10}}
        // open={false}
        // onOpen={() => {
        //   setOpen(true);
        // }}
        // onClose={() => {
        //   setOpen(false);
        // }}
        fullWidth
        freeSolo={true}
        size="small"
        onFocus={() => {}}
        options={options}
        getOptionLabel={(option) => {
          return option;
          //   return option.vendorName || '';
          //   return option ? option.title : option.title;
        }}
        autoComplete={false}
        loading={searching}
        includeInputInList
        filterSelectedOptions
        value={value}
        onChange={(event, newValue) => {
          handleACOnChange(newValue);
          console.log('newValue', newValue);
          //   setValue(newValue);
        }}
        onInputChange={(event, newInputValue) => {
          // console.log('newInputValue', newInputValue);
          // handleOnChange(lowerCase(newInputValue), true);
        }}
        renderInput={(params) => {
          return (
            <TextField
              {...params}
              label={labelText}
              variant="outlined"
              onChange={(value) => {
                console.log('value', value.target.value);
                handleOnChange(lowerCase(value.target.value));
              }}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {searching ? (
                      <CircularProgress color="secondary" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
            />
          );
        }}
        noOptionsText="Not found"
        // renderOption={(option, index) => {
        //   console.log('optot', option);
        //   return (
        //     <Box
        //       style={{width: '100%'}}
        //       key={index}
        //       onClick={() => {
        //         dispatch(setSelectedVendorForOrder(option));
        //         history.push('placeorder');
        //       }}
        //     >
        //       <Grid container alignItems="center">
        //         <Grid item>
        //           <Typography className={classes.textVendorName}>
        //             {option.organizationName}
        //           </Typography>
        //           <Typography className={classes.textAddress}>
        //             {`${option.vendorAddress.subLocality}, ${option.vendorAddress.locality}, ${option.vendorAddress.city}, ${option.vendorAddress.pinCode}.`}
        //           </Typography>
        //           <Box
        //             style={{
        //               display: 'flex',
        //             }}
        //           >
        //             {option.categories.map((opt, index) => {
        //               return (
        //                 <Chip
        //                   style={{
        //                     marginRight: '5px',
        //                     backgroundColor: '#DCDCDC',
        //                   }}
        //                   key={index}
        //                   boxShadow={3}
        //                   label={opt.description}
        //                 />
        //               );
        //             })}
        //           </Box>
        //         </Grid>
        //       </Grid>
        //     </Box>
        //   );
        // }}
      />
    </div>
  );
};

export default index;
