import {makeStyles} from '@material-ui/core';
import Colors from '../../theme/Colors';

const useStyle = makeStyles((theme) => ({
  container: {
    backgroundColor: 'white',
    flexGrow: 1,
  },
  list: {},
  headerImageContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  btnContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  btn: {},
  cameraContainer: {display: 'flex', flexDirection: 'column'},
  cameraBtnContainer: {display: 'flex', justifyContent: 'space-evenly'},
  imgListContainer: {display: 'flex', overflow: 'auto'},
  addRemoveBtnontainer: {
    display: 'flex',
    position: 'absolute',
    top: 0,
    right: 0,
    backgroundColor: Colors.primary,
    height: 26,
    width: 26,
    borderRadius: 15,
    justifyContent: 'center',
    alignItems: 'center',
    margin: 10,
    elevation: 2,
    cursor: 'pointer',
  },
  img: {
    height: 200,
    width: 300,
    backgroundColor: '#dcdcdc',
  },
  imgContainerOne: {
    position: 'relative',
    display: 'flex',
    height: 200,
    width: 300,
    justifyContent: 'center',
    alignItems: 'center',
    margin: 2,
  },
  imgContainer: {
    position: 'relative',
    height: 200,
    width: 300,
    justifyContent: 'center',
    alignItems: 'center',
  },
  emptyBox: {height: 200, width: 300, backgroundColor: 'lightgray'},
  textTitle: {
    fontWeight: 900,
    fontSize: 16,
  },
  containerHeaderImg: {
    display: 'flex',
    // aspectRatio: 16 / 9,
    // width: width - 30,
    justifyContent: 'center',
    alignItems: 'center',
  },
  placeholder: {
    width: '350px',
    height: '200px',
    backgroundColor: 'lightgray',
    cursor: 'pointer',
  },
  imageHeader: {
    cursor: 'pointer',
    width: '350px',
    height: '200px',
    // width: width - 30,
    // aspectRatio: 11 / 9,
    backgroundColor: '#dcdcdc',
  },
  containerImg: {
    border: 'solid yellow',
    display: 'flex',
    height: 110,
    width: 110,
    margin: 2,
  },
  image: {
    height: 105,
    width: 105,
    backgroundColor: '#dcdcdc',
  },
  text: {
    color: 'white',
    fontSize: 18,
    textAlign: 'center',
  },
  placeholderImg: {
    height: 105,
    width: 105,
    backgroundColor: 'lightgray',
  },
  btnRemove: {
    position: 'absolute',
    top: 0,
    right: 0,
    backgroundColor: Colors.primary,
    height: 26,
    width: 26,
    borderRadius: 15,
    justifyContent: 'center',
    alignItems: 'center',
    margin: 10,
    elevation: 2,
  },
}));

export default useStyle;
