/* eslint-disable react/prop-types */
import {Box, Button, Grid, TextField} from '@material-ui/core';
import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import useStyle from './styles';
import deleteCatalogueItem from '../../services/deleteVendorItem';
import SimpleDialog from '../../components/DumbComponents/SimpleDialog';
import editCatalogueItem from '../../services/editCatalogueItem';
import showToast from '../../components/Toast';
import {useHistory} from 'react-router';
import AppBarName from '../../components/DumbComponents/AppBarName';
import WebToolbar from '../../components/toolbar/WebToolbar';
import ActivityIndicator from '../../components/ActivityIndicator';
import SideNav from '../../components/sidenav/SideNav';
import ErrorBoundary from '../../components/ErrorBoundary';
import ErrorBoundaryFallback from '../../components/ErrorBoundaryFallback';
import MobileFooter from '../MobileFooter';

const EditItemsScreen = (props) => {
  const history = useHistory();
  const {item} = props.location.state;
  const classes = useStyle();
  const {t} = useTranslation('addItems');
  const token = useSelector((state) => state.user.token);
  //   const {getData, item} = route.params;
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState(item.name);
  const [price, setPrice] = useState(`${item.price}`);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  const closeDeleteDialogFunction = (value) => {
    setOpenDeleteDialog(!openDeleteDialog);
  };

  const handleFreezeBasketListItemClick = () => {
    deleteItem();
    setOpenDeleteDialog(!openDeleteDialog);
  };

  function onPressDelete() {
    setOpenDeleteDialog(!openDeleteDialog);
  }

  async function deleteItem() {
    setLoading(true);
    try {
      const payload = {
        ItemIds: [item.id],
      };
      const response = await deleteCatalogueItem(payload, token);
      if (response.successCode === 1) {
        // getData();
        // navigation.goBack();
        showToast(t('success'), 'deleted');
        history.push('/buildcatalouge');
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }

  async function onPressSave() {
    // Keyboard.dismiss();
    setLoading(true);
    try {
      const payload = {
        id: item.id,
        Name: name,
        Description: item.description,
        Price: Number(price),
      };
      const response = await editCatalogueItem(payload, token);
      console.log('response', response);
      if (response.successCode === 1) {
        showToast('success', t('changed'));
        history.goBack();
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }

  return (
    <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
      <Box className={classes.container}>
        <WebToolbar />{' '}
        <div className={classes.sideNav}>
          <SideNav />
        </div>
        <AppBarName title={'Edit Items'} />
        {loading ? (
          <ActivityIndicator />
        ) : (
          <Box p={2}>
            <Grid container direction={'column'} spacing={2}>
              <Grid item>
                <TextField
                  className={classes.textInput}
                  fullWidth
                  id="outlined-basic"
                  label={t('name')}
                  value={name}
                  size="small"
                  variant="outlined"
                  inputProps={{maxLength: 25}}
                  onChange={(txt) => {
                    setName(txt.target.value);
                  }}
                />
              </Grid>
              <Grid item>
                <TextField
                  className={classes.textInput}
                  fullWidth
                  id="outlined-basic"
                  label={t('price')}
                  value={price}
                  inputProps={{maxLength: 25}}
                  size="small"
                  variant="outlined"
                  onChange={(text) => {
                    if (!isNaN(text.target.value)) {
                      setPrice(text.target.value);
                    }
                  }}
                />
              </Grid>
            </Grid>
            <Box
              p={2}
              style={{display: 'flex', justifyContent: 'space-evenly'}}
            >
              <Button
                className={classes.deletBtn}
                variant="outlined"
                uppercase={false}
                onClick={() => onPressDelete()}
              >
                {t('delete')}
              </Button>
              <SimpleDialog
                open={openDeleteDialog}
                message={(t('areYouSure'), t('delete1'))}
                onSimpleDialogClose={closeDeleteDialogFunction}
                handleBasketListItemClick={handleFreezeBasketListItemClick}
              />
              <Button
                variant="outlined"
                style={{width: '40%'}}
                uppercase={false}
                disabled={!name.trim()}
                onClick={() => onPressSave()}
              >
                {t('save')}
              </Button>
            </Box>
          </Box>
        )}
      </Box>
      <MobileFooter />
    </ErrorBoundary>
  );
};

export default EditItemsScreen;
