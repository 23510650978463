/* Copyright (C) 2020 Gearten Technologies Private Limited - All Rights Reserved
 NOTICE:  All information contained herein is, and remains
 * the property of Gearten Technologies Private Limited
 * The intellectual and technical concepts contained
 * herein are proprietary to Gearten Technologies Private Limited.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Gearten Technologies Private Limited.
 */
import {
  SET_USER,
  SET_IS_LOGGEDIN,
  SET_IS_TRUE,
  SET_TOKEN,
  SET_SAVED_ITEMS,
  SET_PROFILE_PIC,
  SET_UNREAD_NOTIFICATIONS,
  SET_VENDOR_ID,
  SET_TERMS_ACCEPTED,
  SET_PINCODE,
  SET_ADVERTISEMENT,
  SET_VENDOR_TYPES,
  SET_NEARBY_VENDORS,
  SET_FAVOURITE_VENDORS,
  SET_SHOW_PINCODE_MODAL,
  SET_VENDORS,
  SET_EMAIL,
  SET_PASSWORD,
  SET_SELECTED_VENDOR_FOR_ORDER,
  SET_REPEAT_ORDER_CHECKED,
  SET_HOME_DELIVERY_CHECKED,
  SET_SELECTED_CART_ITEMS,
  NAME,
  SET_NOTIFICATION_DATA,
  SET_RECEIVED_ORDERS,
  SET_PLACED_ORDERS,
  SET_LANGUAGE,
  SET_VENDOR_DETAILS,
  SET_ORDER_ID,
  SET_CART_ORDER_ID,
  SET_GROUP_ID,
  SET_GROUP_DETAILS,
  SET_BASKET_DETAILS,
  SET_ITEMS_LIST_DATA,
  SET_BILL_DETAILS,
  SET_CATALOUGE_MODAL_COUNT,
  SET_REFRESH_FAV,
  SET_VENDOR_SHOP_DEATAILS,
  SET_TYPE_OF_ADVERTISEMENT,
  SET_NEW_ADVERTISEMENT_DETAILS,
  SET_UPI_IDS,
  SET_UPDATE_ORDER_DETAILS,
  SET_PLACE_BASKET_ORDER,
  SET_SELECT_ITEMS_DATA,
} from '../actions/ActionTypes';

const defaultState = {
  user: {},
  isLoggedIn: false,
  isTrue: false,
  token: '',
  savedItems: 0,
  profilePic: '',
  unreadNotifications: 0,
  vendorId: 0,
  isTermsAccepted: false,
  pincode: null,
  advertisement: [],
  vendorTypes: [],
  nearByVendors: [],
  favouriteVendors: [],
  showPincodeModal: false,
  vendors: [],
  email: '',
  password: '',
  selectedVendorForOrder: {},
  repeatOrderChecked: false,
  homeDeliveryChecked: false,
  selectedCartItems: [],
  name: 'Ajinkya',
  notificationData: '',
  receivedOrders: '',
  placedOrders: '',
  selectedLanguage: 'English',
  vendorDetails: null,
  orderId: {},
  cartOrderId: [],
  groupId: null,
  groupDetails: null,
  basketDetails: null,
  itemsListData: [],
  billDetails: null,
  showCatalougeModalCount: 0,
  refreshFav: false,
  vendorShopDetails: '',
  typeOfAdvertisement: '',
  newAdvertisementDetails: null,
  upiIds: [],
  updateOrderDetails: {},
  placeBasketOrders: null,
  selectItemsData: null,
};

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case SET_USER:
      return {...state, user: action.payload};
    case SET_IS_LOGGEDIN:
      return {...state, isLoggedIn: action.payload};
    case SET_IS_TRUE:
      return {...state, isTrue: action.payload};
    case SET_TOKEN:
      return {...state, token: action.payload};
    case SET_SAVED_ITEMS:
      return {...state, savedItems: action.payload};
    case SET_UNREAD_NOTIFICATIONS:
      return {...state, unreadNotifications: action.payload};
    case SET_VENDOR_ID:
      return {...state, vendorId: action.payload};
    case SET_PROFILE_PIC:
      return {...state, user: {...state.user, profilePicture: action.payload}};
    case SET_TERMS_ACCEPTED:
      return {...state, isTermsAccepted: action.payload};
    case SET_PINCODE:
      return {...state, pincode: action.payload};
    case SET_ADVERTISEMENT:
      return {...state, advertisement: action.payload};
    case SET_VENDOR_TYPES:
      return {...state, vendorTypes: action.payload};
    case SET_NEARBY_VENDORS:
      return {...state, nearByVendors: action.payload};
    case SET_FAVOURITE_VENDORS:
      return {...state, favouriteVendors: action.payload};
    case SET_SHOW_PINCODE_MODAL:
      return {...state, showPincodeModal: action.payload};
    case SET_VENDORS:
      return {...state, vendors: action.payload};
    case SET_EMAIL:
      return {...state, email: action.payload};
    case SET_PASSWORD:
      return {...state, password: action.payload};
    case SET_SELECTED_VENDOR_FOR_ORDER:
      return {...state, selectedVendorForOrder: action.payload};
    case SET_REPEAT_ORDER_CHECKED:
      return {...state, repeatOrderChecked: action.payload};
    case SET_HOME_DELIVERY_CHECKED:
      return {...state, homeDeliveryChecked: action.payload};
    case SET_SELECTED_CART_ITEMS:
      return {...state, selectedCartItems: action.payload};
    case NAME:
      return {...state, name: action.payload};
    case SET_NOTIFICATION_DATA:
      return {...state, notificationData: action.payload};
    case SET_RECEIVED_ORDERS:
      return {...state, receivedOrders: action.payload};
    case SET_PLACED_ORDERS:
      return {...state, placedOrders: action.payload};
    case SET_LANGUAGE:
      return {...state, selectedLanguage: action.payload};
    case SET_VENDOR_DETAILS:
      return {...state, vendorDetails: action.payload};
    case SET_ORDER_ID:
      return {...state, orderId: action.payload};
    case SET_CART_ORDER_ID:
      return {...state, cartOrderId: action.payload};
    case SET_GROUP_ID:
      return {...state, groupId: action.payload};
    case SET_GROUP_DETAILS:
      return {...state, groupDetails: action.payload};
    case SET_BASKET_DETAILS:
      return {...state, basketDetails: action.payload};
    case SET_ITEMS_LIST_DATA:
      return {...state, itemsListData: action.payload};
    case SET_BILL_DETAILS:
      return {...state, billDetails: action.payload};
    case SET_CATALOUGE_MODAL_COUNT:
      return {...state, showCatalougeModalCount: action.payload};
    case SET_REFRESH_FAV:
      return {...state, refreshFav: action.payload};
    case SET_VENDOR_SHOP_DEATAILS:
      return {...state, vendorShopDetails: action.payload};
    case SET_TYPE_OF_ADVERTISEMENT:
      return {...state, typeOfAdvertisement: action.payload};
    case SET_NEW_ADVERTISEMENT_DETAILS:
      return {...state, newAdvertisementDetails: action.payload};
    case SET_UPI_IDS:
      return {...state, upiIds: action.payload};
    case SET_UPDATE_ORDER_DETAILS:
      return {...state, updateOrderDetails: action.payload};
    case SET_PLACE_BASKET_ORDER:
      return {...state, placeBasketOrders: action.payload};
    case SET_SELECT_ITEMS_DATA:
      return {...state, selectItemsData: action.payload};
    default:
      return state;
  }
}
