/* eslint-disable react/prop-types */
import React from 'react';
import {Box} from '@material-ui/core';

const NotFound = ({message = 'Not found'}) => {
  return (
    <Box display="flex" justifyContent="center">
      <h1>{message}</h1>
    </Box>
  );
};

export default NotFound;
