import React, {useEffect, useState} from 'react';
import WebToolbar from '../../components/toolbar/WebToolbar';
import useStyle from './styles';
import {Box, Typography, Zoom} from '@material-ui/core';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import {useHistory} from 'react-router';
import {useTranslation} from 'react-i18next';
import getUserGruops from '../../services/getUserGruops';
import {useDispatch, useSelector} from 'react-redux';
import {get, isEmpty} from 'lodash';
import Divider from '@material-ui/core/Divider';
import {setGroupId} from '../../redux/actions/user';
import ActivityIndicator from '../../components/ActivityIndicator';
import AppBarName from '../../components/DumbComponents/AppBarName';
import SideNav from '../../components/sidenav/SideNav';
import {useTheme} from '@material-ui/core/styles';
import ErrorBoundary from '../../components/ErrorBoundary';
import ErrorBoundaryFallback from '../../components/ErrorBoundaryFallback';
import MobileFooter from '../MobileFooter';

const Groups = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const classes = useStyle();
  const history = useHistory();
  const {t} = useTranslation('groups');
  const [loading, setLoading] = useState(false);
  const token = useSelector((state) => state.user.token);
  const [groups, setGroups] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  async function getData() {
    try {
      setLoading(true);
      const response = await getUserGruops(token);
      console.log('response', response);
      if (response.successCode === 1) {
        setGroups(response.response.userGroups);
        setLoading(false);
      } else if (response.successCode === 0) {
        setLoading(false);
        setGroups([]);
      }
    } catch (error) {
      console.log('error', error);
      setLoading(false);
    }
  }

  return (
    <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
      <Box className={classes.container} style={theme.custom.container}>
        <WebToolbar />
        <div className={classes.sideNav}>
          <SideNav />
        </div>
        {/* <DemoPage /> */}
        <AppBarName title={'Groups'} />
        {loading ? (
          <ActivityIndicator />
        ) : (
          <Box m={2}>
            {groups.length > 0 ? (
              groups.map((item, index) => {
                return (
                  <Box
                    key={index}
                    className={classes.groupsContainer}
                    onClick={() => {
                      dispatch(setGroupId(item));
                      // dispatch(setGroupId(item.group.id));
                      history.push('groupmessagesscreen');
                      // history.push({pathname: 'groupmessagesscreen', state: item});
                    }}
                  >
                    <Typography className={classes.grpName}>
                      {get(item, 'group.name', 'NA')}
                    </Typography>
                    <Typography className={classes.grpDesc}>
                      {/* {get(item, 'group.description', '-')} */}
                      {get(item, 'group.description', '') != ''
                        ? get(item, 'group.description', '')
                        : '-'}
                    </Typography>
                    {/* <Box style={theme.custom.dummyClass}>
                    <Typography>Dummy class</Typography>
                  </Box> */}
                    <Divider />
                  </Box>
                );
              })
            ) : (
              <Box p={2}>
                <Typography>
                  Please create groups to send messages and collect orders from
                  group members.
                </Typography>
              </Box>
            )}
          </Box>
        )}
        <Zoom in={true} timeout={{enter: 500, exit: 500}} unmountOnExit>
          <Fab
            className={classes.fab}
            variant="extended"
            aria-label="add"
            size="small"
            onClick={() => {
              history.push('creategroup');
            }}
          >
            <AddIcon className={classes.extendedIcon} />
            Create Group
          </Fab>
        </Zoom>
      </Box>
      <MobileFooter />
    </ErrorBoundary>
  );
};

export default Groups;
