/* eslint-disable react/prop-types */
import React, {useEffect, useState, useCallback} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {useHistory} from 'react-router';
import {
  Typography,
  Button,
  AppBar,
  Tabs,
  Tab,
  Box,
  TextField,
  Dialog,
  DialogTitle,
  List,
  ListItem,
  ListItemText,
} from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import moment from 'moment';
import {get} from 'lodash';
import Autocomplete from '@material-ui/lab/Autocomplete';
import FilterListIcon from '@material-ui/icons/FilterList';
import AccountBoxOutlinedIcon from '@material-ui/icons/AccountBoxOutlined';
import PropTypes from 'prop-types';
import WebToolbar from '../../components/toolbar/WebToolbar';
import ActivityIndicator from '../../components/ActivityIndicator';
import useStyles from './styles';
import Tooltip from '@material-ui/core/Tooltip';

import {
  setLoading,
  setReceivedOrders,
  setPlacedOrders,
  setOrderId,
} from '../../redux/actions/user';
import getVendorsOrders from '../../services/getVendorsOrders';
import getVendorsPlacedOrders from '../../services/getVendorsPlacedOrders';
import getOrderStates from '../../services/getOrderStates';
import searchOrders from '../../services/searchUserOrders';
import searchVendorOrders from '../../services/searchVendorOrders';
import {isEmpty, upperCase, debounce, lowerCase} from 'lodash';
import {toast} from 'react-toastify';
import {useTranslation} from 'react-i18next';
import filterItems from './utils';
import showToast from '../../components/Toast';
import SideNav from '../../components/sidenav/SideNav';
import AppBarName from '../../components/DumbComponents/AppBarName';
import MobileFooter from '../MobileFooter';
import PlacedTab from './PlacedTab';
import ReceivedTab from './ReceivedTab';
import TabPanel from './TabPanel';

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const ReceivedOrders = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  // const {t} = useTranslation(['orderDetails', 'common']);

  const [width, setWidth] = useState(window.innerWidth);
  const [value, setValue] = useState(0);
  const [loading, setLoading] = useState(true);
  const token = useSelector((state) => state.user.token);
  const [searchItem, setSearchItem] = useState([]);
  // const [orderId, setOrderId] = useState(14);
  // const [orders, setOrders] = useState();
  const {receivedOrders, placedOrders, user} = useSelector(
    (state) => state.user
  );

  console.log('placedOrders', placedOrders);
  console.log('receivedOrders', receivedOrders);

  const [openFilterDialog, setOpenFilterDialog] = useState(false);
  const [selectedValue, setSelectedValue] = useState([filterItems[0]]);

  const [query, setQuery] = useState('');
  const [listData, setListData] = useState([]);
  const [listDataReceived, setListDataReceived] = useState([]);
  const [orders, setOrders] = useState([]);
  const [pOrders, setPOrders] = useState([]);
  const [searching, setSearching] = useState(false);
  const [filterData, setFilterData] = useState([]);
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [refreshing, setRefreshing] = useState(false);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    getVendorsOrdersData();
    getVendorsPlacedOrdersData();
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  async function getVendorsOrdersData() {
    try {
      // const response = await Promise.all([
      //   getVendorsOrders(token),
      //   getOrderStates(token),
      // ]);
      const response = await getVendorsOrders(token);

      if (response.successCode === 1) {
        setListDataReceived(response.response.orders);
        setOrders(response.response.orders);
        let data = [];
        const isRequests = false;
        if (isRequests) {
          data = response.response.orders.filter(
            (o) => o.customerName === 'VENDOR_GENERATED_ORDER'
          );
        } else {
          data = response.response.orders.filter(
            (o) => o.customerName !== 'VENDOR_GENERATED_ORDER'
          );
        }

        dispatch(setReceivedOrders(data));
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (error) {
      console.log('error', error);
      setLoading(false);
    }

    // try {
    //   dispatch(setLoading(true));
    //   const response = await getOrders(token, orderId);
    //   console.log('response', response);
    //   if (response.successCode === 1) {
    //     dispatch(setOrders(response.response.orders));
    //     dispatch(setLoading(false));
    //   } else {
    //     dispatch(setLoading(false));
    //   }
    // } catch (error) {
    //   console.log('error', error);
    //   dispatch(setLoading(false));
    // }
  }
  async function getVendorsPlacedOrdersData() {
    try {
      // const response = await Promise.all([
      //   getVendorsPlacedOrders(token),
      //   getOrderStates(token),
      // ]);

      const response = await getVendorsPlacedOrders(token);

      if (response.successCode === 1) {
        setListData(response.response.orders);
        setPOrders(response.response.orders);
        dispatch(setPlacedOrders(response.response.orders));
        setLoading(false);
      } else {
        //
      }
    } catch (error) {
      setLoading(false);
    }

    // try {
    //   dispatch(setLoading(true));
    //   const response = await getOrders(token, orderId);
    //   console.log('response', response);
    //   if (response.successCode === 1) {
    //     dispatch(setOrders(response.response.orders));
    //     dispatch(setLoading(false));
    //   } else {
    //     dispatch(setLoading(false));
    //   }
    // } catch (error) {
    //   console.log('error', error);
    //   dispatch(setLoading(false));
    // }
  }

  const _handler = useCallback(
    debounce(async (text) => {
      console.log('text', text);
      if (!text.trim()) {
        return;
      }
      try {
        console.log('text', text);
        const response = await searchVendorOrders(token, text);
        console.log('serach orders response', response);
        if (response.successCode === 1) {
          setListDataReceived(response.response.orders);
        } else {
          showToast('warn', 'No matching orders found.');
          setListDataReceived(orders);
        }
        setSearching(false);
      } catch (error) {
        setSearching(false);
      }
    }, 1000),
    []
  );

  const _handleSearchedOrder = (q) => {
    console.log('q value', q);
    setQuery(q);
    if (q.length > 0) {
      setSearching(true);
      _handler(q);
    } else {
      console.log('in else', listDataReceived);
      setListDataReceived(orders);
      setSearching(false);
    }
  };

  const handler = useCallback(
    debounce(async (text) => {
      console.log('text', text);
      if (!text.trim()) {
        return;
      }
      try {
        console.log('text', text);
        const response = await searchOrders(token, text);
        console.log('serach orders response', response);
        if (response.successCode === 1) {
          setListData(response.response.orders);
        } else {
          showToast('warn', 'No matching orders found.');
          setListData(pOrders);
        }
        setSearching(false);
      } catch (error) {
        setSearching(false);
      }
    }, 1000),
    []
  );
  const handleSearchedOrder = (q) => {
    console.log('q text', q);
    setQuery(q);
    if (q.length > 0) {
      setSearching(true);
      handler(q);
    } else {
      setListData(pOrders);
      setSearching(false);
    }
  };

  const openFilterDialogFunction = () => {
    setOpenFilterDialog(!openFilterDialog);
  };

  const closeFilterDialogFunction = (value) => {
    setOpenFilterDialog(false);
    setSelectedValue(value);
  };

  return (
    <>
      <div className={classes.container}>
        <WebToolbar />
        <AppBarName
          title={user.isVendor ? 'Received Orders' : 'Placed Orders'}
        />

        <Box className={classes.sideNav}>
          <SideNav />
        </Box>
        <div className={classes.content}>
          {loading ? (
            <ActivityIndicator />
          ) : (
            <>
              <div className={classes.main}>
                <Box boxShadow={1}>
                  {user.isVendor ? (
                    <>
                      <AppBar position="static" elevation={0}>
                        <Tabs
                          value={value}
                          onChange={handleChange}
                          variant="fullWidth"
                          aria-label="simple tabs example"
                        >
                          <Tab label="Received" {...a11yProps(0)} />
                          <Tab label="Placed" {...a11yProps(1)} />
                        </Tabs>
                      </AppBar>
                      <TabPanel value={value} index={0}>
                        <ReceivedTab
                          listDataReceived={listDataReceived}
                          openFilterDialogFunction={openFilterDialogFunction}
                          receivedOrders={receivedOrders}
                          selectedValue={selectedValue}
                          openFilterDialog={openFilterDialog}
                          closeFilterDialogFunction={closeFilterDialogFunction}
                          setListDataReceived={setListDataReceived}
                        />
                      </TabPanel>
                      <TabPanel value={value} index={1}>
                        <PlacedTab
                          loading={loading}
                          listData={listData}
                          handleSearchedOrder={handleSearchedOrder}
                          openFilterDialogFunction={openFilterDialogFunction}
                          placedOrders={placedOrders}
                          selectedValue={selectedValue}
                          openFilterDialog={openFilterDialog}
                          closeFilterDialogFunction={closeFilterDialogFunction}
                          setListData={setListData}
                        />
                      </TabPanel>
                    </>
                  ) : (
                    <Box p={3}>
                      <PlacedTab
                        loading={loading}
                        listData={listData}
                        handleSearchedOrder={handleSearchedOrder}
                        openFilterDialogFunction={openFilterDialogFunction}
                        placedOrders={placedOrders}
                        selectedValue={selectedValue}
                        openFilterDialog={openFilterDialog}
                        closeFilterDialogFunction={closeFilterDialogFunction}
                        setListData={setListData}
                      />
                    </Box>
                  )}
                </Box>
              </div>
            </>
          )}
        </div>
      </div>
      <MobileFooter />
    </>
  );
};

export default ReceivedOrders;
