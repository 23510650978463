/* eslint-disable react/prop-types */
import React from 'react';
import {Typography, Button, Box, TextField} from '@material-ui/core';
import moment from 'moment';
import Autocomplete from '@material-ui/lab/Autocomplete';
import FilterListIcon from '@material-ui/icons/FilterList';
import AccountBoxOutlinedIcon from '@material-ui/icons/AccountBoxOutlined';
import Tooltip from '@material-ui/core/Tooltip';
import useStyles from './styles';

import {setOrderId} from '../../redux/actions/user';
import {isEmpty, lowerCase} from 'lodash';
import SimpleDialog from './SimpleDialog';
import ActivityIndicator from '../../components/ActivityIndicator';
import {useDispatch} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {useHistory} from 'react-router';

const PlacedTab = ({
  loading,
  listData,
  handleSearchedOrder,
  openFilterDialogFunction,
  placedOrders,
  selectedValue,
  openFilterDialog,
  closeFilterDialogFunction,
  setListData,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const {t} = useTranslation(['orderDetails', 'common']);

  return (
    <>
      <Box
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Autocomplete
          size="small"
          id="free-solo-demo"
          style={{width: '100%'}}
          freeSolo
          disableClearable={true}
          options={
            listData
              ? listData.map((option) => {
                  return lowerCase(option.vendorName);
                })
              : 'NA'
          }
          onInputChange={(f) => {
            f.target.textContent.length > 0
              ? handleSearchedOrder(lowerCase(f.target.textContent))
              : '';
          }}
          renderInput={(searchItem) => (
            <TextField
              {...searchItem}
              label="Search Orders by Vendor Name"
              variant="outlined"
              onChange={(value) => {
                console.log('value', value);
                handleSearchedOrder(value.target.value);
              }}
            />
          )}
        />
        <Tooltip title="Filter Orders">
          <Button
            variant="outlined"
            className={classes.filterBtn}
            style={{margin: 1}}
            onClick={() => {
              openFilterDialogFunction();
            }}
          >
            <FilterListIcon style={{fontSize: 28}}></FilterListIcon>
          </Button>
        </Tooltip>
      </Box>
      <Box
        style={{
          height: '410px',
          overflow: 'auto',
        }}
      >
        {loading ? (
          <ActivityIndicator />
        ) : !isEmpty(listData) ? (
          listData.map((val, index) => {
            return (
              <Box
                boxShadow={1}
                key={index}
                style={{
                  display: 'flex',
                  padding: '10px',
                }}
                onClick={() => {
                  dispatch(setOrderId({orderId: val.orderId}));
                  history.push({
                    pathname: '/orderdetails',
                    // orderID: val.orderId,
                  });
                }}
              >
                <Box
                  style={{
                    width: '65%',
                  }}
                >
                  <Typography
                    className={classes.ordersText}
                  >{`Order ID: ${val.orderId}`}</Typography>
                  <Typography className={classes.ordersText}> </Typography>
                  <Typography
                    className={classes.ordersText}
                  >{`Vendor: ${val.vendorName}`}</Typography>
                  <Typography className={classes.ordersText}>{`Date: ${moment(
                    val.orderDate
                  ).format('DD/MM/YYYY, h:mm A')}`}</Typography>
                </Box>
                <Box
                  style={{
                    width: '35%',
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <Typography className={classes.ordersText}>{`${t(
                    val.status
                  )}`}</Typography>
                </Box>
              </Box>
            );
          })
        ) : (
          <>
            {/* <ActivityIndicator /> */}
            <Typography>No Orders Placed</Typography>
          </>
        )}
      </Box>
      <SimpleDialog
        orders={placedOrders}
        selectedValue={selectedValue}
        open={openFilterDialog}
        onClose={closeFilterDialogFunction}
        listD={listData}
        setListD={setListData}
      />
    </>
  );
};

export default PlacedTab;
