export const numberRules = {
  pattern: {
    value: /^([6-9]{1})([0-9]{9})$/,
    message: 'Invalid Phone Number',
  },
  required: {value: true, message: 'Phone Number Required'},
  minLength: {
    value: 10,
    message: 'Minimum 10 characters required',
  },
  maxLength: {
    value: 12,
    message: 'Limit Exceed',
  },
};

export const otpRules = {
  required: {value: true, message: 'Please Enter OTP'},
  minLength: {
    value: 4,
    message: 'Minimum 4 numbers required',
  },
  maxLength: {
    value: 4,
    message: 'Limit Exceed',
  },
};

export const newPasswordRules = {
  required: {value: true, message: 'New Password required'},
  pattern: {
    value: /(?=.*\d)(?=.*[A-Z]).{6,}/,
    message: 'Required atleast one character and a number',
  },
  minLength: {
    value: 6,
    message: 'Minimum 6 characters required',
  },
  maxLength: {
    value: 50,
    message: 'Limit exceed',
  },
};

export const confirmPasswordRules = {
  required: {value: true, message: 'Confirm Password required'},
  pattern: {
    value: /(?=.*\d)(?=.*[A-Z]).{6,}/,
    message: 'Required atleast one character and a number',
  },
  minLength: {
    value: 6,
    message: 'Minimum 6 characters required',
  },
  maxLength: {
    value: 50,
    message: 'Limit exceed',
  },
};

export const requiredCurrentPasswordRule = {
  required: {
    value: true,
    message: 'Current Password required',
  },
};

export const changePasswordRules = {
  pattern: {
    value: /(?=.*\d)(?=.*[A-Z]).{6,}/,
    message: 'Required atleast one character and a number',
  },
  required: {value: true, message: 'Current Password required'},
  minLength: {
    value: 6,
    message: 'Minimum 6 characters required',
  },
  maxLength: {
    value: 20,
    message: 'Limit exceed',
  },
};
