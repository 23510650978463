import {makeStyles} from '@material-ui/styles';

const useStyle = makeStyles((theme) => ({
  root: {border: 'solid'},
  container: {
    width: '90%',
    margin: 'auto',
    overflow: 'auto',
    backgroundColor: 'white',
    ['@media (max-width:700px)']: {},
    '& > header > header': {
      position: 'inherit',
      // display: 'flex',
      // justifyContent: 'center',
      // width: '80%',
    },
  },
  card: {
    padding: 5,
    cursor: 'pointer',
  },
  containerTextTop: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
  },
  searchbar: {
    height: 40,
    fontSize: 14,
    paddingLeft: 0,
    paddingRight: 0,
    flex: 1,
    marginRight: 10,
  },
  list: {
    paddingBottom: 100,
  },
  containerTop: {
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    paddingTop: '1rem',
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  fab: {
    position: 'relative',
    left: '62vw',
    marginTop: '5px',
    height: '40px',
    padding: '5px 15px',
    minWidth: '34px',
    borderRadius: '50px',
  },
}));

export default useStyle;
