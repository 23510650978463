import baseUrl from '../utils/Constants';

export default async function (token, lastOrderId) {
  let apiUrl = `${baseUrl}/orders/recent?numberOfOrders=10`;
  if (lastOrderId) {
    apiUrl = `${baseUrl}/orders/recent?numberOfOrders=10&lastOrderId=${lastOrderId}`;
  }
  const response = await fetch(apiUrl, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });
  const responseJson = await response.json();
  return responseJson;
}
