import React, {useState} from 'react';
import {Helmet} from 'react-helmet';
import {toast} from 'react-toastify';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import Lock from '@material-ui/icons/Lock';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Container from '@material-ui/core/Container';
import Avatar from '@material-ui/core/Avatar';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import {useHistory} from 'react-router-dom';

import passwordRecovery from '../services/passwordRecovery';
import changePassword from '../services/setPassword';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  main: {
    height: '100vh',
  },
  title: {
    flexGrow: 1,
    textAlign: 'center',
  },
  containerMain: {
    marginTop: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2.5, 2, 2.5, 2),
    justifyContent: 'center',
    alignItems: 'center',
  },
  avatar: {
    height: theme.spacing(10),
    width: theme.spacing(10),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    marginTop: theme.spacing(2),
    width: '100%',
    flex: 1,
  },
  input: {
    margin: theme.spacing(1.5, 0, 1.5, 0),
  },
  inputOtp: {
    margin: theme.spacing(1.5, 0, 1.5, 0),
  },
  button: {
    width: '100%',
    marginTop: theme.spacing(2),
  },
  buttonBack: {
    width: '100%',
    marginTop: theme.spacing(5),
  },
  otp: {
    width: '3rem !important',
    height: '3rem',
  },
  textOr: {
    textAlign: 'center',
    marginTop: theme.spacing(4),
  },
}));

function ForgotPassword() {
  const classes = useStyles();
  const [phoneNumber, setPhoneNumber] = useState('');
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [otp, setOtp] = useState('');
  const [otpSent, setOtpSent] = useState(false);
  const history = useHistory();

  const showToast = (event, message) => {
    event.preventDefault();
    toast.error(message, {
      position: 'top-right',
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
  async function onPress(e) {
    e.preventDefault();
    if (otpSent) {
      if (otp.trim().length < 4) {
        showToast(e, 'Invalid OTP, OTP must be 4 characters long');
        return;
      }
      if (
        !password.trim() ||
        !confirmPassword.trim() ||
        !confirmPassword.trim()
      ) {
        showToast(e, 'Please enter all fields');
        return;
      }
      if (password.trim().length < 6) {
        showToast(
          e,
          'Invalid password, current password must be at least 6 characters long'
        );
        return;
      }
      if (confirmPassword.trim().length < 6) {
        showToast(
          e,
          'Invalid password, new password must be at least 6 characters long'
        );
        return;
      }
      if (confirmPassword.trim() !== password.trim()) {
        showToast(e, 'Passwords did not matched');
        return;
      }
      setLoading(true);
      try {
        const payload = {
          UserName: `${phoneNumber}`,
          OTP: otp,
          Password: password,
          ConfirmPassword: password,
        };
        const response = await changePassword(payload);
        setLoading(false);
        if (response.successCode === 1) {
          toast.success('Password changed. Please log in to continue', {
            position: 'top-right',
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          history.replace('/login');
        } else {
          const message = response.message || response.status || 'Failed';
          showToast(e, message);
        }
      } catch (err) {
        console.log(err);
        showToast(e, 'Failed');
        setLoading(true);
      }
    } else {
      var regexp = /^([6-9]{1})([0-9]{9})$/;
      if (!regexp.test(phoneNumber.trim())) {
        showToast(e, 'Invalid mobile number');
        return;
      }
      setLoading(true);
      try {
        const response = await passwordRecovery(phoneNumber);
        console.log(response);
        if (response.successCode === 1) {
          setOtpSent(true);
        } else {
          const message = response.returnMessage || response.status || 'Failed';
          showToast(e, message);
        }
        setLoading(false);
      } catch (error) {
        showToast(e, 'Failed');
        setLoading(false);
      }
    }
  }
  return (
    <div className={classes.main}>
      <Helmet>
        <title>Forgot Password - GearConnect</title>
        <meta name="title" content="GearConnect Forgot Password" />
      </Helmet>

      <AppBar position="static">
        <Toolbar>
          <Typography variant="h5" className={classes.title}>
            GearConnect
          </Typography>
        </Toolbar>
      </AppBar>

      <Container
        className={classes.containerMain}
        square
        maxWidth="xs"
        component={Paper}
        elevation={4}
      >
        <Avatar className={classes.avatar}>
          <Lock style={{fontSize: 50}} />
        </Avatar>
        <Typography variant="h5">Forgot Password?</Typography>
        <Typography
          style={{
            textAlign: 'center',
            marginTop: 8,
            fontSize: 16,
            color: 'gray',
          }}
        >
          {`Enter your phone number, we'll send you a one time password to get
          back into your account.`}
        </Typography>
        <form className={classes.form} noValidate onSubmit={onPress}>
          <TextField
            type="text"
            placeholder="Phone Number"
            label="Phone Number"
            fullWidth
            name="username"
            autoComplete="off"
            inputProps={{maxLength: 10, inputMode: 'numeric'}}
            variant="outlined"
            value={phoneNumber}
            onChange={(event) =>
              setPhoneNumber(event.target.value.replace(/[^0-9]/g, ''))
            }
            required
            autoFocus
            multiline={false}
            disabled={loading || otpSent}
            className={classes.input}
          />
          {otpSent && (
            <div>
              <TextField
                type="text"
                placeholder="OTP"
                label="OTP"
                fullWidth
                name="username"
                autoComplete="off"
                inputProps={{maxLength: 4, inputMode: 'numeric'}}
                variant="outlined"
                value={otp}
                onChange={(event) =>
                  setOtp(event.target.value.replace(/[^0-9]/g, ''))
                }
                required
                multiline={false}
                disabled={loading}
                className={classes.inputOtp}
              />

              <TextField
                type={showPassword ? 'text' : 'password'}
                placeholder="Password"
                label="Password"
                fullWidth
                name="password"
                variant="outlined"
                value={password}
                multiline={false}
                onChange={(event) => setPassword(event.target.value)}
                required
                disabled={loading}
                className={classes.input}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        disabled={loading}
                        aria-label="toggle password visibility"
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                type={showConfirmPassword ? 'text' : 'password'}
                placeholder="Password"
                label="Password"
                fullWidth
                name="password"
                variant="outlined"
                value={confirmPassword}
                multiline={false}
                onChange={(event) => setConfirmPassword(event.target.value)}
                required
                disabled={loading}
                className={classes.input}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        disabled={loading}
                        aria-label="toggle password visibility"
                        onClick={() =>
                          setShowConfirmPassword(!showConfirmPassword)
                        }
                      >
                        {showConfirmPassword ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </div>
          )}

          <Button
            variant="outlined"
            type="submit"
            disabled={loading}
            className={classes.button}
          >
            {loading ? (
              <CircularProgress color="white" size={24} />
            ) : (
              <Typography>{otpSent ? 'Reset Password' : 'Submit'}</Typography>
            )}
          </Button>
          <Typography className={classes.textOr}>Or</Typography>

          <Button
            variant="outlined"
            disabled={loading}
            className={classes.button}
            onClick={(e) => history.replace('/accounts/new')}
          >
            <Typography>{'Sign Up'}</Typography>
          </Button>

          <Button
            variant="outlined"
            disabled={loading}
            className={classes.buttonBack}
            onClick={(e) => history.replace('/login')}
          >
            <Typography>{'Back to login'}</Typography>
          </Button>
        </form>
      </Container>
    </div>
  );
}

export default ForgotPassword;
