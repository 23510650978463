export default {
  common: {
    commonMessage: 'Common',
  },
  homeScreen: {
    logout: 'Logout',
    login: 'Login',
  },
  introScreen: {
    login: 'Login',
    tc: 'By logging in, you agree to terms and conditions',
    signIn: 'Dont have an account? Sign Up',
  },
  loginScreen: {
    mobileNo: 'Mobile Number',
    enterNo: 'Enter your mobile number',
    sendOtp: 'Send OTP',
    sendingOtp: 'Sending OTP',
    wellSend: 'We will send an one time password(OTP) to this number.',
    signIn: 'Dont have an account? Sign Up',
    email: 'Email or Phone Number',
    password: 'Password',
    400300106: 'Invalid crediantials',
    401500100: 'Invalid crediantials',
    400702492: 'Please update your app',
    NO_USER_FOUND: 'No user found',
  },
  signUp: {
    firstName: 'First Name',
    lastName: 'Last Name',
    email: 'Email(optional)',
    mobile: 'Mobile Number',
    address: 'Address',
    pincode: 'Pin code',
    password: 'Password',
    confirmPassword: 'Confirm Password',
    state: 'State',
    city: 'City',
    login: 'Already have an account? Sign in',
    locality: 'Localiity',
    subLocality: 'Sub Localiity',
    address1: 'Address 1, Society/Lane',
    address2: 'Address 2, House/Flat No.',
    society: 'Society',
    flatNo: 'Flat No',
    201100200: 'OTP sent',
    400600405: 'No pincode details found',
    200600205: 'Area details retrived',
    400100101: 'User already registered',
    201102218: 'Profile updated',
    400102426: 'Invalid user',
    wait: 'Please wait...',
    gender: 'Gender',
    male: 'Male',
    female: 'Female',
    yearOfBirth: 'Year of birth',
    enterFName: 'Please your first name',
    enterLname: 'Please your last name',
    enterEmail: 'Please enter email',
    invalidEmail: 'Invalid email address',
    invalidMobileNumber: 'Invalid mobile number',
    invalidYob: 'Select age group',
    vaildYob: 'You must be 18 years old',
    invalidAddres: 'Please enter your address',
    enterPassword: 'Please enter password and confirm password',
    shortPassword: 'Password must contain at least 6 characters',
    passwordMatch: 'Passwords did not match',
    agreeTerms: 'Please agree to terms and conditions',
    selectDp: 'Please select profile picture',
    name: 'Full Name',
    getProfileFailed: 'Failed to get profile',
    400702492: 'Please update your app',
  },
  drawer: {
    home: 'Home',
    contactUs: 'Contact Us',
    placeOrder: 'Place Order',
    logout: 'Logout',
    becomeVendor: 'Register as Vendor',
    changePassrod: 'Change Password',
    updateProfile: 'Update Profile',
    areYouSure: 'Are you sure?',
    logoutMsg: 'Do you want to logout?',
    no: 'No',
    yes: 'Yes',
    catalogue: 'Build Catalogue',
    groups: 'Groups',
    help: 'Help',
    privacyPolicy: 'Privacy Policy',
    faq: 'FAQ',
    about: 'About',
    settings: 'Settings',
    feedback: 'Feedback',
    shareVendor: 'Share Vendor',
  },
  changePassword: {
    changePassword: 'Change Password',
    currentPassword: 'Current Password',
    confirmPassword: 'Confirm Password',
    newPassword: 'New Password',
    400300106: 'Invalid credientials',
  },
  verifyOtp: {
    400100104: 'Invalid OTP',
    verify: 'Verify',
    verifying: 'Verifying',
  },
  becomeVendor: {
    next: 'Next',
    vendorName: 'Vendor Name',
    popularName: 'Popular Name',
    email: 'Email',
    secMobile: 'Secondary Mobile Number',
    address: 'Address',
    pincode: 'Pin code',
    password: 'Password',
    state: 'State',
    city: 'City',
    address1: 'Address 1, Lane',
    address2: 'Address 2, Office name/No.',
    enterVendorName: 'Please enter vendor name',
    enterPopularName: 'Please enter popular name',
    enterEmail: 'Please enter email',
    invalidEmail: 'Invalid email address',
    invalidMobileNumber: 'Invalid mobile number',
    invalidAddres: 'Please enter your address',
    isWeeklyoff: 'Is weekly off?',
    weeklyoffDays: 'Off days',
    breakTime: 'Break Time',
    shopTime: 'Shop Time',
    breakEndTime: 'Break End Time',
    regNo: 'Shop Registration No.',
    gstNo: 'GST No.',
    mainBusinessLine: 'Main Business Line',
    provideHomeDelivery: 'Provide home delivery/Service?',
    vendorOccupationID: 'Vendor Occupation ID',
    qualification: 'Qualification',
    enterRegNo: 'Enter Shop Registration No.',
    enterGstNo: 'Enter GST No.',
    enterOccupationId: 'Enter Vendor Occupation ID',
    enterBusinessLine: 'Enter Main Business Line',
    enterQualification: 'Enter Qualification',
    wait: 'Please wait...',
    locality: 'Localiity',
    subLocality: 'Sub Localiity',
    submit: 'Submit',
    success: 'Registration completed successfully',
    orgName: 'Organization Name',
    enterOrgName: 'Enter organization name',
    vendorType: 'Vendor Type',
    yes: 'Yes',
    no: 'No',
    VENDOR_ALREADY_REGISTERED: 'Vendor already registered',
    NO_PHOTOS_SPECIFIED: 'No photos specified',
  },
  addItems: {
    name: 'Name',
    price: 'Price ₹ (optional)',
    description: 'Description',
    add: 'Add',
    save: 'Save',
    delete: 'Delete',
    areYouSure: 'Are you sure?',
    delete1: 'Do you want to delete this item?',
    wait: 'Please wait',
    deleted: 'Deleted',
    changed: 'Changed',
  },
  searchVendor: {
    400201406: 'No vendor found',
  },
  groups: {
    groupName: 'Group Name',
    groupDescription: 'Group Description',
    create: 'Create',
    enterName: 'Please enter group name',
    403700476: 'Admin cannot exit group.',
    USER_GROUP_CREATED_SUCCESSFULLY: 'User group created successfully',
    USER_DELETED_FROM_GROUP_SUCCESSFULLY:
      'User deleted from group successfully',
  },
  orderDetails: {
    API_ERROR: 'Failed to execute the API call',
    SAVED: 'Saved',
    // ORDERED: 'Ordered',
    ORDERED: 'Requested (To be confirmed by vendor)',
    PAID_ADVANCE: 'Paid advance',
    CANCELLED: 'Cancelled',
    PAID: 'Paid',
    NOT_RECEIVED: 'Not Received/Invalid',
    RECEIVED: 'Received',
    RETURNED: 'Returned',
    REFUND_NOTRECEIVED: 'Refund not received',
    REFUND_RECEIVED: 'Refund received',
    SERVICE_NOT_PROVIDED: 'Service not provided',
    SERVICE_RECEIVED: 'Service received',
    REQUEST_FOR_REFUND: 'Request for refund',
    ACCEPTED: 'Accepted',
    ACCEPTED_WITH_TOKEN: 'Accepted with token',
    ACCEPTED_WITH_DATE: 'Accepted with date',
    DECLINED: 'Declined',
    DELIVERED: 'Deliverd',
    PAYMENT_PENDING: 'Payment pending',
    PAYMENT_RECEIVED: 'Payment received',
    PAID_CASH_ADVANCE: 'Paid cash advance',
    GOODS_NOT_RETURNED: 'Goods not returned',
    REFUND_GIVEN: 'Refund given',
    ADVANCE_PAID: 'Advance paid',
    CANCEL: 'Cancel',
    MAKE_PAYMENT: 'Mark paid',
    ITEM_NOT_RECEIVED: 'Item not received',
    ITEM_RECEIVED: 'Item received',
    RETURN_ITEM: 'Return item',
    REFUND_NOT_RECEIVED: 'Refund not received',
    SERVICE_NOT_RECEIVED: 'Service not received',
    ACCEPT: 'Accept',
    DECLINE: 'Decline',
    DELIVER: 'Delivered',
    ITEM_NOT_RETURNED: 'Item not returned',
    SAVE_ORDER: 'Save',
    ORDER: 'Order',
    PAY_ADVANCE: 'Advance Paid',
    CANCEL_ORDER: 'Cancel Order',
    FLAG_ITEM_NOT_RECEIVED: 'Item not received',
    FLAG_ITEM_RECEIVED: 'Item received',
    RETURN: 'Return',
    FLAG_REFUND_NOT_RECEIVED: 'Refund not received',
    FLAG_RECEIVE_REFUND: 'Refund received',
    FLAG_SERVICE_NOT_PROVIDED: 'Service not provided',
    FLAG_SERVICE_RECEIVED: 'Service received',
    SERVED: 'Served',
    REQUEST_REFUND: 'Request refund',
    MARK_PAYMENT_PENDING: 'Mark payment pending',
    MARK_PAYMENT_RECEIVED: 'Mark payment received',
    MARK_ITEM_NOT_RETURNED: 'Item not returned',
    MARK_FOR_REFUND: 'Mark for refund',
    Deliver: 'Delivered',
    ORDER_FEEDBACK: 'Order feedback',
    REQUEST_FOR_PAYMENT: 'Payment request',
    CANCEL_REQUEST_FOR_PAYMENT: 'Cancel payment request',
    PAYMENT_REQUESTED: 'Payment requested',
    PAYMENT_REQUEST_CANCELLED: 'Payment request cancelled',
    ONLINE_PAYMENT_FAILED: 'Online paymet failed',
    ONLINE_PAYMENT_SUCCESS: 'Online payment success',
    MAKE_PAYMENT_ONLINE: 'Make payment online',
    PAY_CASH: 'Paid cash',
    REGENERATE_ORDER: 'Regenerate order',
    PAY_ADVANCE_CASH: 'Paid advance cash',
    PAID_CASH: 'Paid cash',
    PAID_ONLINE: 'Paid online',
    REFUNDED: 'Refunded',
    REFUND_PAID: 'Refund Paid',
    CLOSED: 'Closed',
    MARK_FOR_PICKUP_BY_AGENT: 'Pickup By Agent',
    NO_ORDER_FOUND: 'No Order Found',
  },
  basket: {
    VENDORS_ALREADY_ASSIGNED_TO_BASKET: 'Vendor Already Added',
  },
  advertisements: {
    NO_ADVERTISEMENT_FOUND_FOR_USER: 'No Advertisement Found For User',
  },
};
