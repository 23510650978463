import {makeStyles} from '@material-ui/core';

const useStyle = makeStyles((theme) => ({
  input: {
    display: 'none',
  },
  button: {
    // marginBottom: '50px',
    width: '30%',
    borderRadius: '25px',
  },
  deleteButton: {
    width: '30%',
    borderRadius: '25px',
    backgroundColor: '#ED5E68',
    '&:hover': {
      backgroundColor: '#ED5E68',
      color: 'white',
    },
  },
  slectedClass: {
    padding: '5px',
    backgroundColor: 'gray',
    backgroundColor: '#4497A8',
  },
  nonSelectedClass: {
    // border: 'solid red',
  },
  radioGrp: {
    display: 'flex',
    flexDirection: 'row',
    padding: '0.5rem 1rem',
    ['@media (max-width:850px)']: {
      display: 'flex',
      flexDirection: 'column',
    },
  },
}));

export default useStyle;
