/* eslint-disable react/prop-types */
import React, {useState, useEffect, useRef} from 'react';
import {AppBar, Toolbar, Tooltip} from '@material-ui/core';
import ActivityIndicator from '../../components/ActivityIndicator';
import {Typography, Box, Paper} from '@material-ui/core';
import getGroupMessages from '../../services/getGroupMessages';
import getGroupDetails from '../../services/getGroupDetails';
import IconButton from '@material-ui/core/IconButton';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import PersonAddOutlinedIcon from '@material-ui/icons/PersonAddOutlined';
import ShoppingBasketOutlinedIcon from '@material-ui/icons/ShoppingBasketOutlined';
import StorefrontOutlinedIcon from '@material-ui/icons/StorefrontOutlined';
import useStyle from './styles';
import Grid from '@material-ui/core/Grid';
import {useDispatch, useSelector} from 'react-redux';
import showToast from '../../components/Toast';
import sendMessageToGroup from '../../services/sendMessageToGroup';
import {get} from 'lodash';
import {useHistory} from 'react-router';
import {
  setSelectedVendorForOrder,
  setGroupDetails,
} from '../../redux/actions/user';
import moment from 'moment';
import WebToolbar from '../../components/toolbar/WebToolbar';
import ImagePreview from '../../components/DumbComponents/ImagePreview';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import MessageItem from '../../components/MessageItem';
import DateSeperator from '../../components/DateSeparator';
import MessageInput from '../../components/MessageInput';
import addGroupFiles from '../../services/addGroupFiles';
import CameraDialog from '../../components/DumbComponents/CameraDialog';
import SideNav from '../../components/sidenav/SideNav';
import ErrorBoundary from '../../components/ErrorBoundary';
import ErrorBoundaryFallback from '../../components/ErrorBoundaryFallback';
import MobileFooter from '../MobileFooter';

const GroupMessagesScreen = (props) => {
  const dispatch = useDispatch();
  const classes = useStyle();
  const token = useSelector((state) => state.user.token);
  const groupId = useSelector((state) => state.user.groupId);
  const user = useSelector((state) => state.user.user);
  const history = useHistory();
  // const [grpItem, setGrpItem] = useState(groupId);
  const groupDetails = useSelector((state) => {
    return state.user.groupDetails;
  });
  const flatListRef = useRef(null);

  const [messages, setMessages] = useState([]);
  // const [userMessage, setUserMessage] = useState('');
  const [loading, setLoading] = useState(true);
  const [sending, setSending] = useState(false);
  const forceUpdate = useState()[1].bind(null, {});
  const [selectedMessages, setSelectedMessages] = useState([]);
  const [vendor, setVendor] = useState('');
  const [message, setMessage] = useState('');
  const [imageViewer, setImageViewer] = useState(false);
  const [selectedImage, setSelectedImage] = useState('');
  const [initialIndex, setInitialIndex] = useState(0);
  const [uploading, setUploading] = useState(false);
  const [showCameraDialog, setShowCameraDialog] = useState(false);
  const [cameraOn, setCameraOn] = useState(false);
  const [capturedImage, setCapturedImage] = useState();

  useEffect(() => {
    console.log('grpId', groupId);
    dispatch(setGroupDetails(null));
    getMessages();
  }, []);

  useEffect(() => {
    flatListRef.current?.scrollIntoView({
      behavior: 'smooth',
      // block: 'end',
      // inline: 'nearest',
    });
  }, [messages]);

  const closeImagePreviewDialogFunction = () => {
    setImageViewer(false);
  };

  async function getMessages() {
    try {
      const response = await Promise.all([
        getGroupMessages(token, groupId.group.id),
        getGroupDetails(token, groupId.group.id),
      ]);
      console.log('response 12', response);
      setLoading(false);
      if (response[0].successCode === 1) {
        setMessages(response[0].response.messages.reverse());
      }
      if (response[1].successCode === 1) {
        if (response[1].response.vendor) {
          setVendor(response[1].response.vendor);
        }
        dispatch(setGroupDetails(response[1].response));
        // setGrpItem(response[1].response);
      } else {
        showToast('error', 'Group not found');
        history.goBack();
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }

  function onPressMenu(menu) {
    switch (menu) {
      case 'info':
        // history.push('groupinfo', grpItem);
        history.push('groupinfo');
        break;
      case 'add':
        history.push('addmembersscreen');
        // history.push('addmembersscreen', grpItem);
        break;
      default:
        break;
    }
  }

  async function sendMessage() {
    if (message?.trim()) {
      setSending(true);
      const payload = {
        // GroupId: grpItem.group.id,
        GroupId: groupId.group.id,
        Message: message,
      };
      console.log('payload', payload);

      const response = await sendMessageToGroup(payload, token);
      console.log('send response', response);
      if (response.successCode === 1) {
        // const i = {
        //   communicationId: response.response.communicationId,
        //   createdBy: user.userId,
        //   createdOn: new Date().toString(),
        //   item: message,
        //   messageType: 0,
        // };
        // const data = [...messages, { ...i }];
        // //data.push(i);
        // data.splice(0, 0, i);
        // setMessages(data);
        setMessage('');
        getMessages();
        // forceUpdate();
      }
      setSending(false);
    }
  }
  function formatAMPM(date) {
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0' + minutes : minutes;
    var strTime = hours + ':' + minutes + ' ' + ampm;
    return strTime;
  }
  function goBack() {
    if (selectedMessages.length > 0) {
      setSelectedMessages([]);
      forceUpdate();
    } else {
      history.goBack();
    }
  }
  function onPressOrderNow() {
    const selected = [];
    for (let i = 0; i < messages.length; i++) {
      for (let j = 0; j < selectedMessages.length; j++) {
        if (messages[i].communicationId === selectedMessages[j]) {
          selected.push({
            message: messages[i].item,
            createdBy: user.userId,
            createdOn: new Date(),
          });
          break;
        }
      }
    }
    try {
      // dispatch(setSelectedVendorForOrder(vendor));
      dispatch(setSelectedVendorForOrder({vendor, selected}));
      history.push('/placeorder');
      // history.push('placeorder', {
      //   vendor: {
      //     vendorId: vendor.vendorId,
      //     organizationName: vendor.organizationName,
      //   },
      //   savedOrder: null,
      //   messages: selected,
      // });
    } catch (error) {
      console.log(error);
    }

    setSelectedMessages([]);
    forceUpdate();
  }

  function onLongPress(msg) {
    if (vendor === '') {
      //showToast('No vendor associated', 'danger');
      return;
    }

    if (msg.messageType === 0) {
      const selected = selectedMessages;
      const isSelected = selected.includes(msg.communicationId);
      if (isSelected) {
        const index = selected.indexOf(msg.communicationId);
        selected.splice(index, 1);
        setSelectedMessages(selected);
      } else {
        selected.push(msg.communicationId);
        setSelectedMessages(selected);
      }
      forceUpdate();
    }
  }
  function onPressMsg(msg) {
    if (vendor === '') {
      // showToast('error', 'No vendor associated');
      return;
    }
    if (msg.messageType === 0) {
      const selected = selectedMessages;
      const isSelected = selected.includes(msg.communicationId);
      if (isSelected) {
        const index = selected.indexOf(msg.communicationId);
        selected.splice(index, 1);
        setSelectedMessages(selected);
      } else {
        selected.push(msg.communicationId);
        console.log('selecyed', selected);
        setSelectedMessages(selected);
      }
      forceUpdate();
    } else if (msg.messageType === 1) {
      setSelectedImage(msg.item);
      if (!msg.item.includes('pdf')) {
        setImageViewer(true);
      }
    }
  }

  async function onDocument(doc) {
    console.log('doc', doc, groupId.group.id);
    const payload = new FormData();
    payload.append('files', doc);
    payload.append('groupId', groupId.group.id);

    try {
      const response = await addGroupFiles(payload, token);
      console.log('addGroupFiles response', response);
      if (response.successCode === 1) {
        // const { fileUploadResponse } = response.response;
        // const data = messages;
        // fileUploadResponse.forEach((element) => {
        //   const i = {
        //     communicationId: '12345',
        //     createdBy: user.userId,
        //     createdOn: new Date().toString(),
        //     item: element.uniqueFileName,
        //     messageType: 1,
        //   };
        //   //data.push(i);
        //   data.splice(0, 0, i);
        // });
        // setMessages(data);
        // forceUpdate();
        getMessages();
        setMessage('');
        // forceUpdate();
        // if (flatListRef.current) {
        //   flatListRef.current?.scrollIntoView({
        //     behavior: 'smooth',
        //     // block: 'end',
        //     // inline: 'nearest',
        //   });
        // }
      } else {
        // setLoading(false);
      }
    } catch (error) {
      setUploading(false);

      console.log(error);
      setLoading(false);
    }
  }

  async function onPhoto(txt) {
    setShowCameraDialog(true);
    setCameraOn(true);
  }

  const closeShowCameraDialogFunction = async (capturedFile) => {
    if (capturedFile) {
      setShowCameraDialog(false);
      setCameraOn(false);

      try {
        setLoading(true);
        const payload = new FormData();
        payload.append('files', capturedFile);
        payload.append('groupId', groupId.group.id);
        const response = await addGroupFiles(payload, token);
        setLoading(false);
        if (response.successCode === 1) {
          // const { fileUploadResponse } = response.response;
          // const data = messages;
          getMessages();
          setMessage('');
        } else {
          showToast('error', response.returnMessage);
        }
      } catch (error) {
        showToast('error', 'Failed');
        setLoading(false);
        console.log('error catch', error);
      }
    } else if (typeof data === 'object') {
      setShowCameraDialog(false);
      setCameraOn(false);
    }
  };

  function renderMessages(item, index) {
    const nextItem = messages[index + 1];
    let dateSeparator = null;
    const date = item.createdOn ? item.createdOn : new Date().toDateString();
    if (!nextItem) {
      dateSeparator = date;
    } else if (!moment(date).isSame(nextItem.createdOn, 'day')) {
      dateSeparator = date;
    }
    const msg = (
      <MessageItem
        onLongPress={(msg) => {
          onLongPress(msg);
        }}
        onPress={(msg) => {
          onPressMsg(msg);
        }}
        isSelected={selectedMessages.includes(item.communicationId)}
        item={item}
      />
    );
    if (dateSeparator) {
      return (
        <React.Fragment>
          {msg}
          <DateSeperator date={dateSeparator} />
        </React.Fragment>
      );
    }
    return msg;
  }

  return (
    <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
      <Box className={classes.container}>
        <WebToolbar />
        <div className={classes.sideNav}>
          <SideNav />
        </div>
        <AppBar elevation={0} position="static" className={classes.appBarStyle}>
          <Toolbar style={{minHeight: '32px'}}>
            <IconButton
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={goBack}
              color="inherit"
              size="small"
            >
              <ArrowBackIcon />
            </IconButton>
            <Box
              className={classes.appBarTxtCont}
              onClick={() => {
                onPressMenu('info');
              }}
            >
              <Typography variant="h6" className={classes.title}>
                {get(groupDetails, 'group.name', '')}
              </Typography>
            </Box>

            <Box className={classes.appBarIconCont}>
              {vendor !== '' && (
                <Tooltip title="Place Order">
                  <IconButton
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    size="small"
                    onClick={() => {
                      // console.log('vendor', vendor);
                      dispatch(setSelectedVendorForOrder({vendor: vendor}));
                      history.push('/placeorder');
                    }}
                    color="inherit"
                  >
                    <StorefrontOutlinedIcon />
                  </IconButton>
                </Tooltip>
              )}
              <Tooltip title="Create Basket">
                <IconButton
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  size="small"
                  onClick={() => {
                    history.push('basketsscreen');
                    // history.push({
                    //   pathname: 'basketsscreen',
                    //   state: grpItem,
                    // });
                  }}
                  color="inherit"
                >
                  <ShoppingBasketOutlinedIcon />
                </IconButton>
              </Tooltip>
              {/* {grpItem.isAdmin ? ( */}
              {groupId.isAdmin ? (
                <Tooltip title="Add Member">
                  <IconButton
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    size="small"
                    onClick={() => {
                      onPressMenu('add');
                    }}
                    color="inherit"
                  >
                    <PersonAddOutlinedIcon />
                  </IconButton>
                </Tooltip>
              ) : (
                ''
              )}
              <Tooltip title="Group Info">
                <IconButton
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  size="small"
                  onClick={() => {
                    onPressMenu('info');
                  }}
                  color="inherit"
                >
                  <InfoOutlinedIcon />
                </IconButton>
              </Tooltip>
            </Box>
          </Toolbar>
        </AppBar>
        <Box>
          {selectedMessages.length > 0 && (
            <Box
              p={1}
              onClick={() => onPressOrderNow()}
              className={classes.containerOrderNow}
            >
              <Typography className={classes.textOrderNow}>
                Order Now
              </Typography>
              <ArrowForwardIcon className={classes.iconOrderNow} />
            </Box>
          )}
        </Box>

        {
          loading ? (
            <ActivityIndicator />
          ) : (
            <Box>
              <Grid
                container
                direction={'column'}
                component={Paper}
                className={classes.chatSection}
              >
                <Grid
                  style={{
                    overflow: 'auto',
                    height: '88%',
                  }}
                >
                  <div></div>
                  {messages.map((item, index) => {
                    return renderMessages(item, index);
                  })}
                  {/* {messages
                  .slice(0)
                  .reverse()
                  .map((item, index) => {
                    return renderMessages(item, index);
                  })} */}
                  <div ref={flatListRef} />
                  {/* <List className={classes.messageArea}>
                  {messages.map((msg, index) => {
                    let fileType = false;
                    if (msg.messageType === 1) {
                      if (msg.item) {
                        const split = msg.item.split('?');
                        const split1 = split[0].split('.');
                        fileType = split1[split1.length - 1];
                      }
                    }
                    const isFile = msg.type
                      ? !msg.type.includes('image')
                      : false;
                    const nextItem = messages[index + 1];
                    let dateSeparator = null;
                    const date = msg.createdOn
                      ? msg.createdOn
                      : new Date().toDateString();
                    if (!nextItem) {
                      dateSeparator = date;
                    } else if (
                      !moment(date).isSame(nextItem.createdOn, 'day')
                    ) {
                      dateSeparator = date;
                    }

                    return msg.messageType === 1 ? (
                      <>
                        {dateSeparator ? (
                          <Box className={classes.dateSeparatorCont}>
                            <Typography className={classes.text}>
                              {moment(dateSeparator).format('DD MMM YYYY')}
                            </Typography>
                          </Box>
                        ) : (
                          ''
                        )}
                        <Box
                          className={
                            classes.__container
                            // isSelected
                            //   ? classes.containerSelected
                            //   : classes.__container
                          }
                        >
                          <Card
                            // onLongPress={() => onLongPress(item)}
                            // onPress={() => onPress(item)}
                            className={
                              user.userId === Number(msg.createdBy)
                                ? classes.cardSelfImgMsg
                                : classes.cardReceivedImgMsg
                            }
                          >
                            {user.userId !== Number(msg.createdBy) && (
                              <Typography className={classes.textSender}>
                                {msg.sender}
                              </Typography>
                            )}

                            {fileType === 'pdf' ? (
                              <Box
                                // onPress={() => onPressFile()}
                                className={classes.containerFile}
                              >
                                <PictureAsPdfIcon
                                  color="white"
                                  name="pdf-box"
                                  size={48}
                                />
                                <Typography className={classes.texFile}>
                                  PDF File
                                </Typography>
                              </Box>
                            ) : isFile ? (
                              <Box
                                // onPress={() => openFile(item.uri)}
                                className={classes.containerFile}
                              >
                                <PictureAsPdfIcon
                                  color="white"
                                  name="pdf-box"
                                  size={48}
                                />
                                <Typography className={classes.texFile}>
                                  PDF File
                                </Typography>
                              </Box>
                            ) : (
                              <Box
                              //  onPress={() => onPress(item)}
                              >
                                <img
                                  className={classes.selfImage}
                                  src={
                                    msg.uri ? msg.uri : msg.item
                                    // priority: FastImage.priority.normal,
                                  }
                                  // resizeMode={FastImage.resizeMode.cover}
                                  onClick={() => {
                                    console.log('msg.uri', msg.uri);
                                    console.log('msg.item', msg.item);
                                    setSelectedImage(
                                      msg.uri ? msg.uri : msg.item
                                    );
                                    setImageViewer(true);
                                  }}
                                />
                                <ImagePreview
                                  open={imageViewer}
                                  onSimpleDialogClose={
                                    closeImagePreviewDialogFunction
                                  }
                                  image={selectedImage}
                                />
                              </Box>
                            )}
                            <Typography
                              className={[
                                classes.textDatedOn,
                                {
                                  color:
                                    user.userId === Number(msg.createdBy)
                                      ? '#DCDCDC'
                                      : 'gray',
                                },
                              ]}
                            >
                              {formatAMPM(new Date(msg.createdOn))}
                            </Typography>
                          </Card>
                        </Box>
                      </>
                    ) : (
                      <Box
                        className={
                          classes.__container
                          // isSelected ? classes.containerSelected : classes.container
                        }
                      >
                        {dateSeparator ? (
                          <Box className={classes.dateSeparatorCont}>
                            <Typography className={classes.text}>
                              {moment(dateSeparator).format('DD MMM YYYY')}
                            </Typography>
                          </Box>
                        ) : (
                          ''
                        )}
                        <Box
                        // onLongPress={() => onLongPress(item)}
                        // onPress={() => onPress(item)}
                        >
                          <Card
                            className={
                              user.userId === Number(msg.createdBy)
                                ? classes.cardSelfMsg
                                : classes.cardReceivedMsg
                            }
                          >
                            {user.userId !== Number(msg.createdBy) && (
                              <Typography className={classes.textSender}>
                                {msg.sender}
                              </Typography>
                            )}
                            <Typography
                              className={
                                user.userId === Number(msg.createdBy)
                                  ? classes.textSelfMsg
                                  : classes.textReceivedMsg
                              }
                            >
                              {msg.item || msg.message}
                            </Typography>

                            <Typography
                              className={[
                                classes.textDatedOn,
                                {
                                  color:
                                    user.userId === Number(msg.createdBy)
                                      ? '#DCDCDC'
                                      : 'gray',
                                },
                              ]}
                            >
                              {formatAMPM(new Date(msg.createdOn))}
                            </Typography>
                          </Card>
                        </Box>
                      </Box>
                    );
                  })}
                </List>
                <Divider />
                <Grid container className={classes.btnTxtIpContainer}>
                  <Grid item xs={11}>
                    <TextField
                      id="outlined-basic"
                      label="Message"
                      variant="outlined"
                      size="small"
                      fullWidth
                      value={userMessage}
                      placeholder="Enter your message here..."
                      className={classes.textInputMsg}
                      onChange={(val) => {
                        setUserMessage(val.target.value);
                      }}
                      InputProps={{
                        endAdornment: (
                          <>
                            <InputAdornment position="end">
                              <input
                                accept="image/*"
                                className={classes.input}
                                id="icon-button-file"
                                type="file"
                                // onChange={(event) => fileSelectorHandler(event)}
                              />
                              <label htmlFor="icon-button-file">
                                <IconButton
                                  color="primary"
                                  aria-label="upload picture"
                                  component="span"
                                  type="file"
                                >
                                  <AttachmentIcon />
                                </IconButton>
                              </label>
                            </InputAdornment>
                            <InputAdornment position="end">
                              <CameraAltIcon
                                onClick={() => {
                                  // setOpenCamera(true);
                                }}
                              />
                            </InputAdornment>
                          </>
                        ),
                      }}
                    ></TextField>
                  </Grid>
                  <Grid xs={1} item className={classes.sendBtnContainer}>
                    <Fab
                      size="small"
                      aria-label="add"
                      onClick={() => {
                        sendMessage();
                      }}
                    >
                      <SendIcon />
                    </Fab>
                  </Grid>
                </Grid> */}
                </Grid>
                <Grid
                  style={{
                    width: '100%',
                    // height: '10%',
                    position: 'absolute',
                    bottom: 0,
                  }}
                >
                  <MessageInput
                    message={message}
                    loading={sending}
                    onDocument={(doc) => onDocument(doc)}
                    onPhoto={(image) => onPhoto(image)}
                    setMessage={(text) => setMessage(text)}
                    placeholder="Enter your message here"
                    onPressFab={() => {
                      sendMessage();
                    }}
                    disabled={false}
                  />
                  <CameraDialog
                    open={showCameraDialog}
                    camera={cameraOn}
                    onSimpleDialogClose={closeShowCameraDialogFunction}
                  />
                  <ImagePreview
                    open={imageViewer}
                    onSimpleDialogClose={closeImagePreviewDialogFunction}
                    image={selectedImage}
                  />
                </Grid>
              </Grid>
            </Box>
          )

          /* <Box width="100%" height="550px" p={1} position="relative">
          <Box style={{display: 'flex', justifyContent: 'center'}}>
            <Typography
              style={{
                backgroundColor: 'gray',
                color: 'white',
                padding: '5px',
                borderRadius: '15px',
              }}
              variant="subtitle2"
            >
              11 Dec 2021
            </Typography>
          </Box>
  
          <Box
            position="absolute"
            bottom="0px"
            left="0px"
            style={{width: '100%'}}
          >
            <TextField
              id="outlined-basic"
              variant="outlined"
              fullWidth
              size="small"
            />
          </Box>
        </Box> */
        }
      </Box>
      <MobileFooter />
    </ErrorBoundary>
  );
};

export default GroupMessagesScreen;
