import baseUrl from '../utils/Constants';

export default async function (token, id, lastOrderId) {
  let response;
  try {
    let apiUrl = `${baseUrl}/users/notifications?id=${id}`;
    if (lastOrderId) {
      apiUrl = `${baseUrl}/users/notifications?lastNotificationId=${lastOrderId}`;
    }
    // const response = await fetch(`${baseUrl}/users/notifications?${id}`, {
    response = await fetch(apiUrl, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });

    const responseJson = await response.json();
    return responseJson;
  } catch (error) {
    return {status: response.status, message: error};
  }
}
