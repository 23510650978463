/* eslint-disable react/prop-types */
import React, {useEffect, useState, useRef} from 'react';
import useStyle from './styles';
import {
  Box,
  Typography,
  Link,
  Button,
  Paper,
  Grid,
  Menu,
  Dialog,
  MenuItem,
  Divider,
  Card,
  CircularProgress,
  DialogContent,
  DialogActions,
} from '@material-ui/core';
import moment from 'moment';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import WebToolbar from '../../components/toolbar/WebToolbar';
import {useHistory} from 'react-router';
import {get} from 'lodash';
import {useDispatch, useSelector} from 'react-redux';
import CallIcon from '@material-ui/icons/Call';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import getOrderDetails from '../../services/getOrderDetails';
import updateOrderStatus from '../../services/updateOrderStatus';
import sendMessage from '../../services/sendMessage';
import {useTranslation} from 'react-i18next';
import showToast from '../../components/Toast';
import ActivityIndicator from '../../components/ActivityIndicator';
import CameraDialog from '../../components/DumbComponents/CameraDialog';
import SideNav from '../../components/sidenav/SideNav';
import AppBarName from '../../components/DumbComponents/AppBarName';
import {
  a11yProps,
  TabPanel,
} from '../../components/DumbComponents/TapPanelProps';
import {setUpdateOrderDetails} from '../../redux/actions/user';
import ErrorBoundary from '../../components/ErrorBoundary';
import ErrorBoundaryFallback from '../../components/ErrorBoundaryFallback';
import MobileFooter from '../MobileFooter';
import {getAddress} from '../../utils/common';
import getOrderDetailsLogs from '../../services/getOrderDetailsLogs';
import getOrderDetailsMessages from '../../services/getOrderDetailsMessages';
import HeaderSection from './HeaderSection';
import OrderItemsTabPanel from './OrderItemsTabPanel';
import MessagesTabPanel from './MessagesTabPanel';

const OrderDetails = (props) => {
  const flatListRef = React.createRef(null);
  const classes = useStyle();
  const history = useHistory();
  const dispatch = useDispatch();
  const {t} = useTranslation(['orderDetails', 'common']);
  const [value, setValue] = useState(0);
  const [orderDetails, setOrderDetails] = useState();
  const [orderStatus, setOrderStatus] = useState('');
  const [allowedActions, setAllowedActions] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [receivedMessages, setReceivedMessages] = useState([]);
  const token = useSelector((state) => state.user.token);

  const __orderId = useSelector((state) => state.user.orderId);

  const webRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [messageLoading, setMessageLoading] = useState(false);
  const [logsLoading, setLogsLoading] = useState(false);
  const user = useSelector((state) => state.user.user);

  const [reqesting, setReqesting] = useState(false);
  const [messages, setMessages] = useState([]);
  const [orderItems, setOrderItems] = useState([]);
  const [showMenu, setShowMenu] = useState(false);
  const [hotAction, setHotAction] = useState({});
  const [orderLogs, setOrderLogs] = useState([]);
  const [orderBy, setOrderBy] = useState('');
  const [subTotal, setSubTotal] = useState(0);
  const [orderAmt, setOrderAmt] = useState(0);
  const [total, setTotal] = useState(0);
  const [res, setRes] = useState('');
  const [isVendorOrder, setIsVendorOrder] = useState(false);
  const [isAllPrice, setIsAllPrice] = useState(true);
  const [requireHomeDelivery, setRequireHomeDelivery] = useState(false);
  const forceUpdate = useState()[1].bind(null, {});
  const [actionLoader, setActionLoader] = useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    if (newValue === 1) {
      getOrderMessages();
    } else if (newValue === 2) {
      getOrderLogs();
    }
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setShowMenu(null);
  };

  useEffect(() => {
    console.log('props in orderDetails', props);
    getOrderDetailsData();
  }, []);

  const bottomRef = useRef(null);

  useEffect(() => {
    flatListRef.current?.scrollIntoView({behavior: 'smooth'});
  }, [receivedMessages]);

  useEffect(() => {
    bottomRef.current?.scrollIntoView({behavior: 'smooth'});
  }, [receivedMessages]);

  async function getOrderDetailsData() {
    try {
      // const _orderId = get(props, 'location.orderID', '');
      setLoading(true);
      const response = await getOrderDetails(token, __orderId.orderId);
      const successCode = get(response, 'successCode', false);
      if (!successCode) {
        showToast('warn', t('API_ERROR'));
        setLoading(false);
        history.goBack();
      }
      if (response && response.successCode === 1) {
        setOrderDetails(response.response);
        setOrderStatus(response.response.statusLiteral);
        setAllowedActions(response.response.allowedActions);
        setLoading(false);
        setIsVendorOrder(response.response.isVendorOrder);
        setRequireHomeDelivery(response.response.requireHomeDelivery);
        setRes(response.response);
        setTotal(response.response.calculatedOrderAmount);
        setOrderAmt(response.response.totalOrderAmount);
        if (loading) {
          if (
            response.response.orderItems &&
            response.response.orderItems.length === 0
          ) {
            setInitialTab(1);
            let isAll = true;
            response.response.orderItems.forEach((item) => {
              if (item.price === 0) {
                isAll = false;
              }
            });
            setIsAllPrice(isAll);
          }
        }

        setOrderItems(response.response.orderItems);
        // setOrderLogs(response.response.orderLogs);
        setOrderBy(response.response.orderRequestedBy);
        // setCustomerMobile(response.response.customerMobile);
        const actions = [];
        if (response.response.allowedActions) {
          response.response.allowedActions.forEach((element, index) => {
            if (index === 0) {
              setHotAction(element);
            } else {
              actions.push(element);
            }
          });
        }
        setAllowedActions(actions);
        setLoading(false);
        forceUpdate();
      } else if (response.successCode === 0) {
        showToast('warn', t('NO_ORDER_FOUND'));
        history.goBack();
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (error) {
      console.log('error', error);
      setLoading(false);
    }
  }

  async function getOrderMessages() {
    try {
      setMessageLoading(true);
      const response = await getOrderDetailsMessages(token, __orderId.orderId);
      const successCode = get(response, 'successCode', false);
      if (!successCode) {
        showToast('warn', t('API_ERROR'));
        setMessageLoading(false);
      }
      if (response && response.successCode === 1) {
        setReceivedMessages(response.response.communications);
        const msg = response.response.communications;
        // response.response.orderImages.forEach((image) => {
        //   msg.push({
        //     messageType: 1,
        //     item: image.orderImageKey,
        //   });
        // });
        setMessages(msg);
        setMessageLoading(false);
      } else {
        setMessageLoading(false);
      }
    } catch (error) {
      console.log('error', error);
      setMessageLoading(false);
    }
  }

  async function getOrderLogs() {
    try {
      setLogsLoading(true);
      const response = await getOrderDetailsLogs(token, __orderId.orderId);
      const successCode = get(response, 'successCode', false);
      if (!successCode) {
        showToast('warn', t('API_ERROR'));
        setLogsLoading(false);
      }
      if (response && response.successCode === 1) {
        setOrderLogs(response.response.orderLogs);
        setLogsLoading(false);
      } else {
        setLogsLoading(false);
      }
    } catch (error) {
      console.log('error', error);
      setLogsLoading(false);
    }
  }

  async function onPressMenu(menu) {
    setActionLoader(true);
    if (menu.nextAction === 'ORDER_FEEDBACK') {
      history.push('orderreview', {
        item: menu,
        orderId: get(props, 'location.orderID', ''),
        // getData: getData,
        vendorId: res.vendorId,
      });
      setActionLoader(false);
      return;
    }
    if (menu.nextAction === 'MARK_FOR_PICKUP_BY_AGENT') {
      history.push('deliverorder', {
        item: menu,
        orderId: get(__orderId, 'orderId', ''),
        // getData: getData,
        vendorId: res.vendorId,
        res: res,
      });
      setActionLoader(false);
      return;
    }
    // if (menu.nextAction === 'MAKE_PAYMENT') {
    //   navigation.replace('OrderPayment', {
    //     item: menu,
    //     order: item,
    //     getData: getData,
    //     vendorId: res.vendorId,
    //     total: total,
    //   });
    //   return;
    // }
    if (menu.inputControls.length > 0) {
      const inputControls = [];
      menu.inputControls.forEach((element) => {
        inputControls.push(element.name);
      });
      history.push('/UpdateOrderStatus');
      dispatch(
        setUpdateOrderDetails({
          item: menu,
          // orderId: get(props, 'location.orderID', ''),
          orderId: __orderId.orderId,
          inputControls,
          // getData: getData,
          total: total,
          res: res,
          isAllPrice,
        })
      );
      setShowMenu(false);
      setActionLoader(false);
    } else {
      setShowMenu(false);
      var payload = {
        // orderId: get(props, 'location.orderID', ''),
        orderId: __orderId.orderId,
        OrderStatus: menu.nextState,
      };
      setReqesting(true);
      try {
        const response = await updateOrderStatus(payload, token);
        setReqesting(false);
        if (response.successCode === 1) {
          showToast('success', 'Order updated');
          // navigation.pop(1);
          history.push('receivedorders');
          // getData();
        } else {
          showToast('error', response.returnMessage);
        }
        setActionLoader(false);
      } catch (error) {
        console.log('error', error);
        setReqesting(false);
        setActionLoader(false);
      }
    }
  }

  // function urltoFile(url, filename, mimeType) {
  //   mimeType = mimeType || (url.match(/^data:( [^;]+);/) || '')[1];
  //   return fetch(url)
  //     .then(function (res) {
  //       return res.arrayBuffer();
  //     })
  //     .then(function (buf) {
  //       return new File([buf], filename, { type: mimeType });
  //     });
  // }

  return (
    <ErrorBoundary>
      <div className={classes.container}>
        <WebToolbar />
        <AppBarName title={'Order Details'} />

        <Box className={classes.sideNav}>
          <SideNav />
        </Box>

        {loading ? (
          <ActivityIndicator />
        ) : (
          <Box>
            <HeaderSection
              orderDetails={orderDetails}
              __orderId={__orderId}
              orderAmt={orderAmt}
              pageType={props.location.state?.pageType}
              total={total}
            />
            <Box>
              <Box className={classes.tabContainer} boxShadow={3}>
                <AppBar position="static" elevation={0}>
                  <Tabs
                    variant="fullWidth"
                    value={value}
                    onChange={handleChange}
                    aria-label="simple tabs example"
                  >
                    <Tab
                      className={classes.tabFont}
                      label="Items"
                      {...a11yProps(0)}
                    />
                    <Tab
                      className={classes.tabFont}
                      label="Messages"
                      {...a11yProps(1)}
                    />

                    <Tab
                      className={classes.tabFont}
                      label="Logs"
                      {...a11yProps(2)}
                    />
                  </Tabs>
                </AppBar>
                <OrderItemsTabPanel value={value} orderItems={orderItems} />
                <MessagesTabPanel
                  value={value}
                  messageLoading={messageLoading}
                  receivedMessages={receivedMessages}
                  bottomRef={bottomRef}
                  orderDetails={orderDetails}
                  setMessageLoading={setMessageLoading}
                  getOrderMessages={getOrderMessages}
                  flatListRef={flatListRef}
                />
                <TabPanel value={value} index={2}>
                  {logsLoading ? (
                    <ActivityIndicator
                      style={{minHeight: '100%', height: 'auto'}}
                    />
                  ) : orderLogs ? (
                    orderLogs.map((val, index) => {
                      return (
                        <Box key={index}>
                          <Box className={classes.logsContainer}>
                            <Grid container style={{cursor: 'pointer'}}>
                              <Grid item xs={8}>
                                <Typography
                                  className={classes.ordersText}
                                  style={{}}
                                >
                                  {val.updateRemarks.replace(/['"]+/g, '')}
                                </Typography>
                              </Grid>
                              <Grid item xs={4}>
                                <Typography className={classes.ordersText}>
                                  {`${moment(val.statusUpdatedOn).format(
                                    'DD/MM/YYYY, h:mm A'
                                  )}`}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Box>
                          <Divider />
                        </Box>
                      );
                    })
                  ) : (
                    'No logs available'
                  )}
                </TabPanel>
              </Box>
              <Box m={1} className={classes.btnContainer}>
                {Object.keys(hotAction).length > 0 && (
                  <Button
                    variant="outlined"
                    className={classes.deletBtn}
                    onClick={() => onPressMenu(hotAction)}
                    // loading={loading}
                  >
                    {t(hotAction.nextAction)}
                  </Button>
                )}
                {allowedActions.length > 0 && (
                  <Button
                    style={{width: '30%', borderRadius: '25px'}}
                    variant="outlined"
                    disabled={actionLoader}
                    onClick={(e) => {
                      setShowMenu(true);
                      setAnchorEl(e.currentTarget);
                    }}
                  >
                    {actionLoader ? (
                      <CircularProgress size={24} />
                    ) : (
                      <Typography>Actions</Typography>
                    )}
                  </Button>
                )}
                <Menu
                  id="simple-menu"
                  anchorEl={anchorEl}
                  open={Boolean(showMenu)}
                  onClose={handleClose}
                >
                  {allowedActions
                    ? allowedActions.map((element, index) => {
                        return (
                          <Box key={index}>
                            <MenuItem
                              className={classes.menuItem}
                              key={index}
                              onClick={() => onPressMenu(element)}
                            >
                              {t(element.nextAction)}
                            </MenuItem>
                            <Divider />
                          </Box>
                        );
                      })
                    : ''}
                </Menu>
              </Box>
            </Box>
          </Box>
        )}
      </div>
      <div ref={bottomRef}></div>
      <MobileFooter />
    </ErrorBoundary>
  );
};

export default OrderDetails;
