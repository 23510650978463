/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */

import {Box, Card, Typography} from '@material-ui/core';
import React, {useState} from 'react';
import {useSelector} from 'react-redux';
import Colors from '../theme/Colors';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import {makeStyles} from '@material-ui/core/styles';
import moment from 'moment';
import {isObject, isString} from 'lodash';

const maxWidth = window * 0.7;

const useStyle = makeStyles(() => ({
  container: {
    zIndex: 10,
    marginVertical: 1,
  },
  containerSelected: {
    backgroundColor: 'rgba(0,151,167,0.4)',
    zIndex: 10,
    marginVertical: 1,
  },
  cardSelfMsg: {
    maxWidth: maxWidth,
    width: '50%',
    marginLeft: 'auto',
    // backgroundColor: Colors.secondary,
    backgroundColor: '#ADD8E6',
    borderTopLeftRadius: 15,
    borderTopRightRadius: 0,
    borderBottomLeftRadius: 15,
    borderBottomRightRadius: 15,
    minWidth: 70,
    paddingLeft: '1%',
    paddingRight: '1%',
    margin: '1%',
  },
  cardReceivedMsg: {
    maxWidth: maxWidth,
    width: '50%',
    backgroundColor: '#D3D3D3',
    minWidth: 70,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 15,
    borderBottomLeftRadius: 15,
    borderBottomRightRadius: 15,
    paddingLeft: '1%',
    paddingRight: '1%',
    margin: '1%',
  },
  textSelfMsg: {
    fontSize: 15,
    color: 'black',
  },
  textReceivedMsg: {
    fontSize: 14,
  },
  row: {
    flexDirection: 'row',
  },
  textDatedOn: {
    fontSize: 11,
    textAlign: 'black',
    color: 'black',
  },
  textSender: {
    fontSize: 16,
    color: 'black',
    fontWeight: 500,
  },
  cardReceivedImgMsg: {
    padding: 8,
    margin: 3,
    width: 220,
    // marginRight: 'auto',
    // backgroundColor: Colors.primary,
    backgroundColor: '#D3D3D3',
    elevation: 2,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 15,
    borderBottomLeftRadius: 15,
    borderBottomRightRadius: 15,
    minWidth: 70,
  },
  cardSelfImgMsg: {
    padding: 8,
    margin: 3,
    width: 220,
    marginLeft: 'auto',
    // backgroundColor: Colors.secondary,
    backgroundColor: '#ADD8E6',
    elevation: 2,
    minWidth: 70,
    borderTopLeftRadius: 15,
    borderTopRightRadius: 0,
    borderBottomLeftRadius: 15,
    borderBottomRightRadius: 15,
  },
  selfImage: {
    height: 150,
    width: 206,
    borderRadius: 15,
    backgroundColor: '#dcdcdc',
    border: '1px solid red',
  },
  containerFile: {
    display: 'flex',
    width: 206,
    alignItems: 'center',
    flexDirection: 'row',
  },
  texFile: {
    fontSize: 16,
    color: 'black',
  },
  dateReceivedCol: {
    color: 'black',
  },
  dateSelfCol: {
    color: 'black',
  },
}));

const MessageItem = ({item, isSelected, onLongPress, onPress}) => {
  console.log('item', item);
  const user = useSelector((state) => state.user.user);
  const classes = useStyle();
  const [numPage, setNumPage] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [selectedPDF, setSelectedPDF] = useState(
    '7b54304b-1e73-4897-a274-356913364d94.pdf'
  );

  function formatAMPM(date) {
    // console.log('date11', date);
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0' + minutes : minutes;
    var strTime = hours + ':' + minutes + ' ' + ampm;
    return strTime;
  }

  function openFile(path) {
    setTimeout(() => {
      FileViewer.open(path)
        .then(() => {
          // success
        })
        .catch((error) => {
          console.log(error);
        });
    }, 1000);
  }
  let fileType = false;
  if (item.messageType === 1) {
    console.log('image >>', item);
    if (item.item) {
      if (isString(item.item)) {
        const split = item.item.split('?');
        const split1 = split[0].split('.');
        fileType = split1[split1.length - 1];
      }
      if (isObject(item.item)) {
        const split = item.item.name.split('.');
        fileType = split[split.length - 1];
      }
    }
  }

  function onDocLoadSuccess() {
    setNumPage(numPage);
    setPageNumber(1);
  }

  async function onPressFile(pdf) {
    const split = pdf.item.split('?');
    const split1 = split[0].split('.');
    fileType = split1[split1.length - 1];
    const tmp = split[0].split('/');
    const name = tmp;
    setSelectedPDF(name[4], '_blank');
    window.open(pdf.item, '_blank');
    // window.open(name[4]);
  }

  const userId = user.userId;
  const isFile = item.type ? !item.type.includes('image') : false;
  console.log('isFile', isFile, 'fileType', fileType);

  const getImage = () => {
    if (item.uri) {
      return item.uri;
    } else if (item.item.type) {
      return window.URL.createObjectURL(item.item);
    } else {
      return item.item;
    }
  };

  return item.messageType === 1 ? (
    <>
      <Box
        className={isSelected ? classes.containerSelected : classes.container}
      >
        <Card
          onLongPress={() => onLongPress(item)}
          onClick={() => onPress(item)}
          className={
            userId === Number(item.createdBy)
              ? classes.cardSelfImgMsg
              : classes.cardReceivedImgMsg
          }
        >
          {userId !== Number(item.createdBy) && (
            <Typography className={classes.textSender}>
              {item.sender}
            </Typography>
          )}

          {fileType === 'pdf' ? (
            <Box
              onClick={() => onPressFile(item)}
              className={classes.containerFile}
            >
              <PictureAsPdfIcon />
              <Typography className={classes.texFile}>PDF File</Typography>
            </Box>
          ) : isFile ? (
            <Box
              // onClick={() => openFile(item.uri)}
              className={classes.containerFile}
            >
              <PictureAsPdfIcon />
              <Typography className={classes.texFile}>PDF File</Typography>
            </Box>
          ) : (
            <Box
            //onClick={() => onPress(item)}
            >
              <img className={classes.selfImage} src={getImage()} />
            </Box>
          )}
          {/* 
            {isFile ? (
              <TouchableOpacity
                onPress={() => openFile(item.uri)}
                style={classes.containerFile}>
                <MaterialCommunityIcons color="white" name="pdf-box" size={48} />
                <Text style={classes.texFile}>PDF File</Text>
              </TouchableOpacity>
            ) : (
              <FastImage
                style={classes.selfImage}
                source={
                  item.uri
                    ? {uri: item.uri}
                    : {
                        uri: `${s3BaseUrl}/${item.item}`,
                        priority: FastImage.priority.normal,
                      }
                }
                resizeMode={FastImage.resizeMode.cover}
              />
            )} 
          */}

          <Typography
            className={[
              classes.textDatedOn,
              {color: userId === Number(item.createdBy) ? '#DCDCDC' : 'gray'},
            ]}
          >
            {formatAMPM(
              new Date(item.createdOn ? item.createdOn : item.item.lastModified)
            )}
          </Typography>
        </Card>
      </Box>
    </>
  ) : (
    <Box className={isSelected ? classes.containerSelected : classes.container}>
      <Box
        onLongPress={() => onLongPress(item)}
        onClick={() => {
          onPress(item);
        }}
      >
        <Card
          className={
            userId === Number(item.createdBy)
              ? classes.cardSelfMsg
              : classes.cardReceivedMsg
          }
        >
          {userId !== Number(item.createdBy) && (
            <Typography className={classes.textSender}>
              {item.sender}
            </Typography>
          )}
          <Typography
            className={
              userId === Number(item.createdBy)
                ? classes.textSelfMsg
                : classes.textReceivedMsg
            }
          >
            {item.item || item.message}
          </Typography>
          <Typography
            className={classes.textDatedOn}
            // className={[
            //   classes.textDatedOn,
            //   userId === Number(item.createdBy)
            //     ? classes.dateReceivedCol
            //     : classes.dateSelfCol,
            // ]}
          >
            {moment(item.createdOn).format('MMM D, YYYY, h:mm a')}
            {/* {formatAMPM(new Date(item.createdOn))} */}
          </Typography>
        </Card>
      </Box>
    </Box>
  );
};

export default MessageItem;
