/* eslint-disable react/prop-types */
import {
  AppBar,
  Box,
  Button,
  Checkbox,
  Divider,
  Grid,
  Toolbar,
  Typography,
} from '@material-ui/core';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {isEmpty} from 'lodash';
import useStyle from './style';
import ActivityIndicator from '../../components/ActivityIndicator';
import showToast from '../../components/Toast';
import getCurrentVendor from '../../services/getCurrentVendor';
import getDeliveryVendors from '../../services/getDeliveryVendors';
import addDeliveryPartner from '../../services/addDeliveryPartner';
import {useHistory} from 'react-router';
import Colors from '../../theme/Colors';
import AppBarName from '../../components/DumbComponents/AppBarName';
import WebToolbar from '../../components/toolbar/WebToolbar';
import ErrorBoundary from '../../components/ErrorBoundary';
import ErrorBoundaryFallback from '../../components/ErrorBoundaryFallback';
import {getAddress} from '../../utils/common';

const AddDeliveryPartnerScreen = (props) => {
  const classes = useStyle();
  const history = useHistory();
  const {t} = useTranslation('becomeVendor');
  const user = useSelector((state) => state.user.user);
  const token = useSelector((state) => state.user.token);
  const [loading, setLoading] = useState(true);
  const [vendors, setVendors] = useState([]);
  const forceUpdate = useState()[1].bind(null, {});
  const [reqesting, setReqesting] = useState(false);
  const [details, setDetails] = useState({});

  const [checked, setChecked] = React.useState(true);

  useEffect(() => {
    console.log('props', props);
    getData();
  }, []);

  const handleChange = (item, e) => {
    const updatedVendors = vendors.map((val) => {
      if (item.vendorId === val.vendorId) {
        return {...val, isSelected: e.target.checked};
      } else {
        return val;
      }
    });
    setVendors(updatedVendors);
  };

  async function getData() {
    try {
      const response = await Promise.all([
        getCurrentVendor(token),
        getDeliveryVendors(token),
      ]);
      console.log('ssss', response);
      setLoading(false);
      if (response[0].successCode === 1) {
        setDetails(response[0].response);
        if (response[1].messageCode === 'Not Found') {
          showToast('error', response[1].messageCode);
          return;
        }
        const data = response[1].response;
        if (response[0].response.deliveryPartners) {
          data.forEach((vendor, index) => {
            const isPartner = response[0].response.deliveryPartners.find(
              (partner) => partner.vendorId === vendor.vendorId
            );
            console.log(isPartner);

            if (isPartner) {
              data[index].isSelected = true;
            } else {
              data[index].isSelected = false;
            }
          });
        }
        setVendors(data);
      } else {
        history.goBack();
        // history.push('/editdeliveryoptionsscreen');

        showToast('error', response[0].returnMessage);
      }
    } catch (error) {
      setLoading(false);
    }
  }
  async function update() {
    try {
      const selected = vendors.filter((vendor) => vendor.isSelected);
      if (selected.length === 0) {
        showToast('error', 'Please select delivery partner');
        return;
      }

      const {
        ProvideHomeDelivery,
        DeliveryBy,
        HomeDeliveryRemarks,
      } = props.location.state;

      const payload = {
        ProvideHomeDelivery,
        DeliveryBy,
        HomeDeliveryRemarks: isEmpty(HomeDeliveryRemarks)
          ? ''
          : HomeDeliveryRemarks,
        PartnerIds: selected.map((a) => a.vendorId),
      };

      setReqesting(true);
      const response = await addDeliveryPartner(
        payload,
        token,
        details.vendorId
      );

      setReqesting(false);
      console.log('response addDeliveryPartner', response);
      if (response.successCode === 1) {
        showToast('success', 'Updated');
        // history.push('/home');
        // navigation.pop(1);
        // history.goBack();
        history.push('/editdetailshome');
      } else {
        showToast('error', response.messageCode);
      }
    } catch (error) {
      console.log(error);
      showToast('error', 'Failed, please try again');
      setReqesting(false);
    }
  }

  return (
    <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
      <div className={classes.container}>
        <WebToolbar />
        <AppBarName title={'Delivery Partner'} />
        <Box>
          {loading ? (
            <ActivityIndicator
              style={{height: '100%'}}
              size="large"
              color={Colors.secondary}
            />
          ) : (
            <>
              <Box p={2}>
                {vendors.length > 0
                  ? vendors.map((item, index) => {
                      return (
                        <Box
                          key={index}
                          onClick={() => {
                            const data = vendors;
                            data[index].isSelected = !data[index].isSelected;
                            setVendors(data);
                            forceUpdate();
                          }}
                        >
                          <Box className={classes.itemStyle}>
                            <Grid
                              container
                              xs={12}
                              style={{display: 'flex', alignItems: 'center'}}
                            >
                              <Grid xs={8}>
                                <Typography>{item.organizationName}</Typography>
                                {item.vendorAddress && (
                                  <Typography className={classes.textAddress}>
                                    {getAddress(item)}
                                  </Typography>
                                )}
                              </Grid>
                              <Grid xs={2}>
                                <Checkbox
                                  checked={item.isSelected ? true : false}
                                  onChange={(e) => {
                                    handleChange(item, e);
                                    // // setChecked(event.target.checked);
                                    // const data = vendors;
                                    // data[index].isSelected = !data[index]
                                    //   .isSelected;
                                    // setVendors(data);
                                    // forceUpdate();
                                  }}
                                  color="primary"
                                  inputProps={{
                                    'aria-label': 'secondary checkbox',
                                  }}
                                />
                              </Grid>
                            </Grid>
                            <Divider />
                          </Box>
                        </Box>
                      );
                    })
                  : ''}
              </Box>
              <Box style={{display: 'flex', justifyContent: 'center'}}>
                <Button variant="outlined" onClick={update}>
                  Update
                </Button>
              </Box>
            </>
          )}
        </Box>
      </div>
    </ErrorBoundary>
  );
};

export default AddDeliveryPartnerScreen;
