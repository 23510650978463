import './style.css';
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import React, {useEffect} from 'react';

const showToast = (type, message) => {
  if (type === 'success') {
    toast.success(message, {
      position: 'top-center',
      className: 'custom_success',
      autoClose: 1500,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      newestOnTop: true,
      pauseOnFocusLoss: false,
      draggable: true,
      progress: undefined,
    });
  } else if (type === 'error') {
    toast.error(message, {
      position: 'top-center',
      autoClose: 1500,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  } else if (type === 'warn') {
    toast.warn(message, {
      position: 'top-center',
      autoClose: 1500,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  } else if (type === 'info') {
    toast.info(message, {
      position: 'top-center',
      autoClose: 1500,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
  return (
    <div>
      <ToastContainer></ToastContainer>
    </div>
  );
};

export default showToast;

// const showToast = (type, message) => {
//   console.log('type', 'message', type, message);

//   toast.error(message, {
//     position: 'top-right',
//     autoClose: 5000,
//     hideProgressBar: true,
//     closeOnClick: true,
//     pauseOnHover: true,
//     draggable: true,
//     progress: undefined,
//   });

//   toast.success(message, {
//     position: 'top-right',
//     autoClose: 5000,
//     hideProgressBar: true,
//     closeOnClick: true,
//     pauseOnHover: true,
//     draggable: true,
//     progress: undefined,
//   });
// };

// export default function showToast(type, message) {
//   if (type === 'success') {
//     toast.success(message, {
//       className: 'custom-toast',
//       draggable: true,
//       position: toast.POSITION.TOP_CENTER,
//     });
//   } else if (
//     (type === 'error',
//     {
//       className: 'custom-toast',
//       draggable: true,
//       position: toast.POSITION.TOP_CENTER,
//     })
//   ) {
//     toast.error(message);
//   } else if (type === 'info') {
//     toast.info(message, {
//       className: 'custom-toast',
//       draggable: true,
//       position: toast.POSITION.TOP_CENTER,
//     });
//   } else if (type === 'warn') {
//     toast.warn(message, {
//       className: 'custom-toast',
//       draggable: true,
//       position: toast.POSITION.TOP_CENTER,
//     });
//   } else {
//     toast(message, {
//       className: 'custom-toast',
//       draggable: true,
//       position: toast.POSITION.TOP_CENTER,
//     });
//   }

//   // switch (type) {
//   //   case type === 'success':
//   //     console.log(
//   //       'type 1',
//   //       type,
//   //       'typeof(type)',
//   //       typeof type,
//   //       'type.length',
//   //       type.length
//   //     );
//   //     toast.success(message);
//   //     break;

//   //   case type === 'error':
//   //     console.log(
//   //       'type 2',
//   //       type,
//   //       'typeof(type)',
//   //       typeof type,
//   //       'type.length',
//   //       type.length
//   //     );
//   //     toast.error(message);
//   //     break;

//   //   case type === 'info':
//   //     console.log(
//   //       'type 3',
//   //       type,
//   //       'typeof(type)',
//   //       typeof type,
//   //       'type.length',
//   //       type.length
//   //     );
//   //     toast.info(message);
//   //     break;

//   //   case type == 'warn':
//   //     console.log(
//   //       'type 4',
//   //       type,
//   //       'typeof(type)',
//   //       typeof type,
//   //       'type.length',
//   //       type.length
//   //     );
//   //     toast.warn(message);
//   //     break;

//   //   default:
//   //     console.log(
//   //       'type 5',
//   //       type,
//   //       'typeof(type)',
//   //       typeof type,
//   //       'type.length',
//   //       type.length
//   //     );
//   //     toast(message);
//   //     break;
//   // }

//   // switch (type) {
//   //   case type === 'success':
//   //     toast.success(message, {
//   //       position: 'top-left',
//   //       autoClose: 5000,
//   //       hideProgressBar: true,
//   //       closeOnClick: true,
//   //       pauseOnHover: true,
//   //       draggable: true,
//   //       progress: undefined,
//   //     });
//   //     break;

//   //   case type === 'error':
//   //     toast.error(message, {
//   //       position: 'top-right',
//   //       autoClose: 5000,
//   //       hideProgressBar: true,
//   //       closeOnClick: true,
//   //       pauseOnHover: true,
//   //       draggable: true,
//   //       progress: undefined,
//   //     });
//   //     break;

//   //   case type === 'info':
//   //     // toast.info(message);
//   //     toast.info(message, {
//   //       position: 'top-right',
//   //       autoClose: 5000,
//   //       hideProgressBar: true,
//   //       closeOnClick: true,
//   //       pauseOnHover: true,
//   //       draggable: true,
//   //       progress: undefined,
//   //     });
//   //     break;

//   //   case type === 'warn':
//   //     // toast.warn(message);
//   //     toast.warn(message, {
//   //       position: 'top-right',
//   //       autoClose: 5000,
//   //       hideProgressBar: true,
//   //       closeOnClick: true,
//   //       pauseOnHover: true,
//   //       draggable: true,
//   //       progress: undefined,
//   //     });
//   //     break;

//   //   default:
//   //     toast(message);
//   //     break;
//   // }
// }

// export default showToast;
