import useStyle from './styles';
import WebToolbar from '../../components/toolbar/WebToolbar';
/* eslint-disable react/prop-types */
import React, {useState, useEffect} from 'react';
import {
  AppBar,
  Zoom,
  Toolbar,
  Typography,
  Box,
  Button,
  Grid,
  Divider,
} from '@material-ui/core';
import {get} from 'lodash';
import DeleteIcon from '@material-ui/icons/Delete';
import removeInvitedMember from '../../services/removeInvitedMember';
import removeMember from '../../services/removeMember';
import IconButton from '@material-ui/core/IconButton';
import PermIdentityIcon from '@material-ui/icons/PermIdentity';
import Avatar from '@material-ui/core/Avatar';
import AddIcon from '@material-ui/icons/Add';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import Fab from '@material-ui/core/Fab';
import {useHistory} from 'react-router';
import showToast from '../../components/Toast';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import exitGroup from '../../services/exitGroup';
import getGroupMembers from '../../services/getGroupMembers';
import PropTypes from 'prop-types';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import ActivityIndicator from '../../components/ActivityIndicator';
import AppBarName from '../../components/DumbComponents/AppBarName';
import SideNav from '../../components/sidenav/SideNav';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ErrorBoundary from '../../components/ErrorBoundary';
import ErrorBoundaryFallback from '../../components/ErrorBoundaryFallback';
import MobileFooter from '../MobileFooter';

SimpleDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired,
};

function SimpleDialog(props) {
  const {t} = useTranslation('groups');
  const token = useSelector((state) => state.user.token);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    console.log('props', props);
  }, []);
  const {
    onClose,
    selectedValue: {id, groupID, userID, invited, memberName},
    open,
    getGroupInfo,
  } = props;

  const handleClose = () => {
    onClose();
  };

  const removeGroupMemberFunc = async () => {
    console.log('removeGroupMemberFunc');
    try {
      const payload = {
        GroupID: groupID,
        RemoveUserId: userID,
      };
      const response = invited
        ? await removeInvitedMember(
            {
              GroupID: groupID,
              MobileNumber: memberName,
            },
            token
          )
        : await removeMember(payload, token);
      setLoading(true);
      console.log('remove member response', response);
      if (response.successCode === 1) {
        // setLoading(true);
        // setSelectedMember('');
        getGroupInfo();
        onClose();
        setLoading(false);
        showToast('success', `${memberName} removed from this group`);
      } else {
        onClose();
        setLoading(false);
        showToast('error', t(response.messageCode));
      }
    } catch (error) {
      onClose();
      setLoading(false);
      console.log(error);
    }
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      {/* <DialogTitle id="simple-dialog-title">Are you sure?</DialogTitle> */}
      <List>
        <ListItem>
          <Typography>
            Do you really want to remove ABCD from this group?
          </Typography>
        </ListItem>

        <ListItem
          style={{
            display: 'flex',
            justifyContent: 'center',
            justifyContent: 'space-evenly',
          }}
        >
          <Button
            variant="outlined"
            onClick={() => {
              removeGroupMemberFunc();
            }}
          >
            YES
          </Button>
          <Button
            variant="outlined"
            onClick={() => {
              onClose();
            }}
          >
            NO
          </Button>
        </ListItem>
      </List>
    </Dialog>
  );
}

function SimpleExitDialog(props) {
  const history = useHistory();
  const {t} = useTranslation('groups');
  const token = useSelector((state) => state.user.token);
  useEffect(() => {
    console.log('props in SimpleExitDialog', props);
  }, []);
  const {onClose, selectedValue, open} = props;

  const handleClose = () => {
    onClose();
  };

  const exitGroupFunc = async () => {
    console.log('exitGroupFunc');
    try {
      const response = await exitGroup(selectedValue, token);
      console.log('rsponse from exit group', response);
      if (response.successCode === 1) {
        showToast('success', t(response.returnMessage));
        history.push('/groups');
      } else {
        showToast('error', t(response.messageCode));
        onClose();
      }
    } catch (error) {
      onClose();
      console.log(error);
    }
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      {/* <DialogTitle id="simple-dialog-title">Are you sure?</DialogTitle> */}
      <List>
        <ListItem>
          <Typography>Do you really want to exit this group?</Typography>
        </ListItem>

        <ListItem
          style={{
            display: 'flex',
            justifyContent: 'center',
            justifyContent: 'space-evenly',
          }}
        >
          <Button
            variant="outlined"
            onClick={() => {
              exitGroupFunc();
            }}
          >
            YES
          </Button>
          <Button
            variant="outlined"
            onClick={() => {
              onClose();
            }}
          >
            NO
          </Button>
        </ListItem>
      </List>
    </Dialog>
  );
}

const GroupInfo = (props) => {
  // const {
  //   location: {
  //     state: {
  //       group: {id, name, description, groupOwner},
  //       isAdmin,
  //     },
  //   },
  // } = props;
  // const id = props.location.state.group.id;
  const classes = useStyle();
  const history = useHistory();
  const {t} = useTranslation('groups');
  const token = useSelector((state) => state.user.token);
  const groupId = useSelector((state) => state.user.groupId);
  const [open, setOpen] = useState(false);
  const [openExitModal, setOpenExitModal] = useState(false);
  const [selectedValue, setSelectedValue] = useState();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const groupDetails = useSelector((state) => state.user.groupDetails);

  const handleClose = (value) => {
    setOpen(false);
    setSelectedValue(value);
  };
  const handleCloseExitModal = (value) => {
    setOpenExitModal(false);
    // setSelectedValue(value);
  };

  useEffect(() => {
    console.log('props', props);
    getGroupInfo();
  }, []);

  async function getGroupInfo() {
    setLoading(true);
    try {
      console.log('groupppp', groupId.group);
      const response = await getGroupMembers(token, groupId.group.id);
      console.log('response', response);
      if (response.successCode === 1) {
        setData(response.response.members);
        response.response.invitations.forEach((element) => {
          const obj = {
            memberName: element,
            invited: true,
          };
          data.push(obj);
        });
        // setMembers(data);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  }

  async function removegroupMmber(i) {
    console.log('i', i);
    setOpen(!open);
  }

  async function exitThisGroup() {
    console.log('exit group');
    setOpenExitModal(!openExitModal);
  }

  function onPressMember(member) {
    console.log('member', member);
    /*if (groupItem.isAdmin) {
      if (member.invited) {
        return;
      }
      if (user.userId !== member.userID) {
        setOptions(['Remove member']);
        setShowOptions(true);
        setSelectedMember(member);
      }
    }*/
  }

  return (
    <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
      <div className={classes.container}>
        <WebToolbar />
        <div className={classes.sideNav}>
          <SideNav />
        </div>
        {/* <AppBarName backScreenUrl="groupmessagesscreen" */}
        <AppBarName
          leftTitle={get(groupDetails, 'group.name', '')}
          leftSubTitle={get(groupDetails, 'group.groupOwner', '')}
        />
        {/* <AppBar
        elevation={0}
        position="static"
        style={{
          backgroundColor: '#4497A8',
          height: '42px',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Toolbar>
          <Grid container>
            <Grid
              item
              xs={1}
              style={{
                display: 'flex',
                alignItems: 'center',
                cursor: 'pointer',
              }}
              onClick={() => {
                history.goBack();
              }}
            >
              <ArrowBackIcon />
            </Grid>
            <Grid
              item
              xs={11}
              style={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Typography className={classes.title}>
                {groupDetails.group.name}
              </Typography>
              <Typography style={{ fontSize: '14px' }}>
                {groupDetails.group.groupOwner}
              </Typography>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar> */}
        {loading ? (
          <ActivityIndicator />
        ) : (
          <Grid container direction={'column'}>
            <Grid item style={{display: 'flex', flexDirection: 'column'}}>
              <Typography style={{fontWeight: 900, padding: '10px'}}>
                {groupId.group.description}
              </Typography>
            </Grid>
            <Grid container direction={'column'}>
              <Grid
                item
                xs={11}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                }}
              >
                <Typography
                  style={{
                    fontWeight: 900,
                  }}
                >
                  Participants
                </Typography>
              </Grid>

              <Grid
                item
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  flexDirection: 'column',
                }}
              >
                {data.map((item, index) => {
                  return (
                    <Box
                      key={index}
                      style={{
                        width: '100%',
                      }}
                    >
                      <Grid
                        key={index}
                        container
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                        }}
                        onClick={() => {
                          onPressMember(item);
                        }}
                      >
                        <Grid
                          xs={1}
                          item
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                          }}
                        >
                          <Avatar className={classes.pink}>
                            <PermIdentityIcon />
                          </Avatar>
                        </Grid>
                        <Grid xs={6} item>
                          <Typography>{item.memberName}</Typography>
                        </Grid>
                        <Grid xs={1} item>
                          {item.isAdmin && (
                            <Box className={classes.AdminBrdr}>
                              <Typography>Admin</Typography>
                            </Box>
                          )}
                          {item.invited && (
                            <Box className={classes.AdminBrdr}>
                              <Typography>Invited</Typography>
                            </Box>
                          )}
                          {groupDetails.isAdmin && !item.isAdmin && (
                            <Box>
                              <IconButton
                                aria-label="delete"
                                onClick={() => {
                                  removegroupMmber(item);
                                }}
                                className={classes.removeIcon}
                              >
                                <DeleteIcon
                                  color="error"
                                  style={{cursor: 'pointer'}}
                                />
                              </IconButton>
                            </Box>
                          )}
                          <SimpleDialog
                            selectedValue={item}
                            open={open}
                            onClose={handleClose}
                            getGroupInfo={getGroupInfo}
                          />
                        </Grid>
                      </Grid>
                      <Divider style={{width: '100%', margin: '2px'}} />
                    </Box>
                  );
                })}
              </Grid>
            </Grid>
            <Grid item style={{display: 'flex', justifyContent: 'center'}}>
              <Button
                variant="contained"
                className={classes.button}
                startIcon={<DeleteIcon />}
                onClick={() => {
                  exitThisGroup();
                }}
              >
                Exit Group
              </Button>
              {openExitModal ? (
                <SimpleExitDialog
                  selectedValue={groupId.group.id}
                  open={openExitModal}
                  onClose={handleCloseExitModal}
                  getGroupInfo={getGroupInfo}
                />
              ) : (
                ''
              )}
            </Grid>
            <Grid item>
              {groupDetails.isAdmin && (
                <Zoom in={true} timeout={{enter: 500, exit: 500}} unmountOnExit>
                  <Fab
                    variant="extended"
                    size="medium"
                    aria-label="add"
                    className={classes.fab}
                    onClick={() => {
                      history.push('/addmembersscreen');
                      // history.push({
                      //   pathname: 'addmembersscreen',
                      //   state: groupId,
                      // });
                    }}
                  >
                    <AddIcon className={classes.extendedIcon} />
                    Add Members
                  </Fab>
                </Zoom>
              )}
            </Grid>
          </Grid>
        )}
      </div>
      <MobileFooter />
    </ErrorBoundary>
  );
};

export default GroupInfo;
