import {makeStyles} from '@material-ui/styles';

const useStyle = makeStyles((theme) => ({
  container: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    paddingBottom: '100px',
    height: '586px',
    backgroundColor: 'white',
    flex: 1,
    width: '80%',
    margin: 'auto',
    '& > header > header': {
      position: 'inherit',
      // display: 'flex',
      // justifyContent: 'center',
      // width: '80%',
    },
  },
  imageContainer: {
    height: '40vh',
    width: '80vh',
    marginTop: '30px',
    backgroundSize: 'contain',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundImage:
      'url(https://cdn.pixabay.com/photo/2014/02/27/16/10/flowers-276014__340.jpg)',
  },
  imageContainerOverlay: {
    height: '40vh',
    width: '80vh',
    opacity: '0.8',
    backgroundColor: '#fff',
    position: 'absolute',
  },
  imageTextContainer: {
    position: 'relative',
    height: '40vh',
    // width: '80vh',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    padding: '1rem',
    overflowWrap: 'anywhere',
  },
  sideNav: {
    ['@media (max-width:700px)']: {
      display: 'none',
    },
    //height: '100%',
    width: '92px',
    position: 'fixed',
    zIndex: 1,
    top: 0,
    // right: 0,
    right: '15px',
    overflowX: 'hidden',
    marginTop: 190,
    // marginRight: theme.spacing(1),
    marginRight: 0,
    backgroundColor: '#3B3B3B',
    elevation: 2,
  },
  button: {width: '30%', borderRadius: '25px'},
}));

export default useStyle;
