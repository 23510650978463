import baseUrl from '../utils/Constants';

export default async function (token) {
  const response = await fetch(
    `${baseUrl}/advertisements/list`,
    // `http://gcmobile-dev.ap-southeast-1.elasticbeanstalk.com/api/advertisements/list`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }
  );
  const responseJson = await response.json();
  return responseJson;
}
