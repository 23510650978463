import {
  Box,
  Button,
  Checkbox,
  Divider,
  Fab,
  Grid,
  Icon,
  Typography,
} from '@material-ui/core';
import WebToolbar from '../../components/toolbar/WebToolbar';
import React, {useEffect, useState} from 'react';
import useStyle from './styles';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import ShareOutlinedIcon from '@material-ui/icons/ShareOutlined';
import AddIcon from '@material-ui/icons/Add';
import {useHistory} from 'react-router';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import getVendorItems from '../../services/getVendorItems';
import ActivityIndicator from '../../components/ActivityIndicator';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CatalogueItem from '../../components/CatalogueItem';
import deleteCatalogueItem from '../../services/deleteVendorItem';
import showToast from '../../components/Toast';
import {isEmpty} from 'lodash';
// import SimpleDialog from '../../components/DumbComponents/SimpleDialog';

import {Dialog, DialogTitle, List, ListItem} from '@material-ui/core';
import PropTypes from 'prop-types';
import AppBarName from '../../components/DumbComponents/AppBarName';
import getCurrentVendor from '../../services/getCurrentVendor';
import ShareDialog from '../../components/DumbComponents/ShareDialog';
import SideNav from '../../components/sidenav/SideNav';
import ErrorBoundary from '../../components/ErrorBoundary';
import ErrorBoundaryFallback from '../../components/ErrorBoundaryFallback';
import MobileFooter from '../MobileFooter';

const DIALOG_TITLE = 'Are you sure?';

const SimpleDialog = ({
  open,
  onSimpleDialogClose,
  message,
  handleBasketListItemClick,
}) => {
  return (
    <Dialog
      onClose={onSimpleDialogClose}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      {/* <DialogTitle id="simple-dialog-title">{DIALOG_TITLE}</DialogTitle> */}
      <List>
        <ListItem>
          <Typography>{message}</Typography>
        </ListItem>

        <ListItem
          style={{
            display: 'flex',
            justifyContent: 'center',
            justifyContent: 'space-evenly',
          }}
        >
          <Button variant="outlined" onClick={handleBasketListItemClick}>
            YES
          </Button>
          <Button variant="outlined" onClick={onSimpleDialogClose}>
            NO
          </Button>
        </ListItem>
      </List>
    </Dialog>
  );
};

SimpleDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onSimpleDialogClose: PropTypes.func.isRequired,
  message: PropTypes.string.isRequired,
  handleBasketListItemClick: PropTypes.func.isRequired,
};

const VendorCatalogueScreen = () => {
  const classes = useStyle();
  const history = useHistory();
  const token = useSelector((state) => state.user.token);
  const {t} = useTranslation('addItems');
  const [loading, setLoading] = useState(false);
  const [listData, setListData] = useState([]);
  const [isShare, setIsShare] = useState(false);
  const forceUpdate = useState()[1].bind(null, {});
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  // const [openShare, setOpenShare] = useState(false);
  const [openShare, setOpenShare] = useState({
    isOpen: false,
    msg: '',
  });
  const [vendorDetails, setvendorDetails] = useState();
  const [message, setMessage] = useState('');
  const [_selectAll, _setSelectAll] = useState(false);

  useEffect(() => {
    getData();
  }, []);

  // useEffect(() => {
  //   console.log('messahe in useEffect');
  //   if (!isEmpty(message)) {
  //     setOpenShare(true);
  //   }
  // }, [message]);

  const closeShareDialogFunction = () => {
    // setOpenShare(false);
    setOpenShare({isOpen: false, msg: ''});
  };

  const closeDeleteDialogFunction = (value) => {
    setOpenDeleteDialog(!openDeleteDialog);
  };

  const handleFreezeBasketListItemClick = (item, index) => {
    deleteItem(item, index);
    setOpenDeleteDialog(!openDeleteDialog);
  };

  async function getData() {
    setLoading(true);
    try {
      const response = await getVendorItems(token);
      console.log('resa', response);
      setLoading(false);
      if (response.successCode === 1) {
        setListData(response.response.vendorTypes);
        setLoading(false);
      } else {
        setListData([]);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }

  function onPress(item, index) {
    console.log('item, index', 'listData', item, index, listData);
    _setSelectAll(false);
    if (isShare) {
      const data = listData;
      data[index].isSelected = !data[index].isSelected;
      setListData(data);
      forceUpdate();
      console.log('ld', listData);
    } else {
      history.push({pathname: '/edititemsscreen', state: {item}});
      // navigation.navigate('EditItem', {getData: getData, item});
    }
  }

  function onPressDelete(item) {
    setOpenDeleteDialog(!openDeleteDialog);
  }
  async function deleteItem(item, index) {
    setLoading(true);
    try {
      const payload = {
        ItemIds: [item.id],
      };
      const response = await deleteCatalogueItem(payload, token);
      if (response.successCode === 1) {
        showToast('success', t('deleted'));
        getData();
        history.push('/buildcatalouge');
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }
  const handleShare = () => {
    console.log('clicked');
    setIsShare(!isShare);
  };
  function selectAll(isSelected) {
    const data = listData;
    data.forEach((d) => {
      console.log('d', d);
      d.isSelected = isSelected;
      // d.isSelected = true;
    });
    console.log('data', data);
    setListData(data);
    forceUpdate();
  }

  const shareItems = async () => {
    try {
      setLoading(true);
      const response = await getCurrentVendor(token);
      console.log('getCurrentVendor response', response);
      if (response.successCode === 1) {
        setLoading(false);
        setvendorDetails(response.response);

        const data = listData;
        let _message = `Hey there, explore these exciting options at our online store ${response.organizationName} at GearConnect App\n`;
        for (let i = 0; i < data.length; i++) {
          if (data[i].isSelected) {
            _message = `${_message}${data[i].name}${
              data[i].price ? `:\t₹ ${data[i].price},` : ''
            }\n`;
            data[i].isSelected = false;
          }
        }
        _message = `${_message}\n\nhttps://www.gearten.in/vendors?vendorId=${response.vendorId}`;
        console.log('ssssssssssssssssss', _message);
        setMessage(_message);

        setLoading(false);
        setOpenShare({isOpen: true, msg: _message});

        // setOpenShare(true);
        // Share.open({
        //   message: message,
        //   title: 'Share Catalouge',
        // })
        //   .then((res) => {
        //     setIsShare(false);
        //     setListData(data);
        //     forceUpdate();
        //   })
        //   .catch((err) => {
        //     setIsShare(false);
        //     setListData(data);
        //     forceUpdate();
        //   });
      } else {
        showToast('Failed to get vendor details');
        setLoading(false);
      }
    } catch (error) {
      showToast('Failed to get vendor details');
      setLoading(false);
    }
  };
  const handleSelectAll = (event) => {
    _setSelectAll(!_selectAll);
    selectAll(!_selectAll);
  };

  return (
    <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
      <Box className={classes.container}>
        <WebToolbar />{' '}
        <div className={classes.sideNav}>
          <SideNav />
        </div>
        {isShare ? (
          <AppBarName
            title={'Share Catalogue'}
            isIcon={true}
            icon={<ShareOutlinedIcon />}
            onSelect={handleShare}
          />
        ) : (
          <AppBarName
            title={'Build Catalogue'}
            isIcon={true}
            icon={<ShareOutlinedIcon />}
            onSelect={handleShare}
          />
        )}
        <Box p={1}>
          {loading ? (
            <ActivityIndicator />
          ) : (
            <Box className={classes.itemsContainer}>
              <Box p={1} className={classes.btnSelectAll}>
                {isShare && (
                  <Grid container>
                    <Grid
                      item
                      xs={10}
                      style={{
                        display: 'flex',
                        justifyContent: 'end',
                        alignItems: 'center',
                      }}
                    >
                      <Typography>Select All</Typography>
                    </Grid>
                    <Grid
                      item
                      xs={2}
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                      onClick={(e) => {
                        handleSelectAll(e);
                      }}
                    >
                      {_selectAll ? (
                        <CheckBoxIcon style={{color: 'blue'}} />
                      ) : (
                        <CheckBoxOutlineBlankIcon style={{color: 'gray'}} />
                      )}
                    </Grid>
                  </Grid>
                )}
              </Box>

              {listData.length > 0 ? (
                listData.map((item, index) => {
                  return (
                    <>
                      <Box key={index} style={{cursor: 'pointer'}}>
                        <Grid container xs={12}>
                          <Grid
                            xs={10}
                            onClick={() => {
                              onPress(item, index);
                            }}
                            className={classes.itemsNamePrice}
                          >
                            <Box>
                              <Typography variant="subtitle1">
                                {item.name}
                              </Typography>
                              <Typography>Price: ₹ {item.price}</Typography>
                            </Box>
                          </Grid>

                          {isShare ? (
                            <Grid
                              xs={2}
                              className={classes.checkboxContaienr}
                              onClick={() => onPress(item, index)}
                            >
                              <Icon
                                // className={classes.icon}
                                // name={
                                //   item.isSelected
                                //     ? 'check-box'
                                //     : 'check-box-outline-blank'
                                // }
                                className={
                                  item.isSelected
                                    ? classes.selected
                                    : classes.nonSelected
                                }
                              >
                                {item.isSelected ? (
                                  <CheckBoxIcon />
                                ) : (
                                  <CheckBoxOutlineBlankIcon />
                                )}
                              </Icon>
                            </Grid>
                          ) : (
                            <>
                              <Grid xs={1} className={classes.deletBtn}>
                                <DeleteOutlineOutlinedIcon
                                  color="error"
                                  onClick={onPressDelete}
                                />
                                <SimpleDialog
                                  open={openDeleteDialog}
                                  message={(t('areYouSure'), t('delete1'))}
                                  onSimpleDialogClose={
                                    closeDeleteDialogFunction
                                  }
                                  handleBasketListItemClick={() => {
                                    handleFreezeBasketListItemClick(
                                      item,
                                      index
                                    );
                                  }}
                                />
                              </Grid>
                              <Grid xs={1} className={classes.editBtn}>
                                <EditOutlinedIcon
                                  onClick={() => onPress(item, index)}
                                />
                              </Grid>
                            </>
                          )}
                        </Grid>
                        <Divider />
                      </Box>
                    </>
                  );
                })
              ) : (
                <Box>
                  <Typography className={classes.textNoItems}>
                    No items found
                  </Typography>
                </Box>
              )}
            </Box>
          )}
        </Box>
        <Box>
          <ShareDialog
            open={openShare.isOpen}
            onSimpleDialogClose={closeShareDialogFunction}
            data={listData}
            message={openShare.msg}
          />
          {isShare && (
            <Fab
              variant="extended"
              size="medium"
              // disabled={!_selectAll}
              aria-label="add"
              className={classes.fab}
              onClick={() => {
                shareItems();
              }}
            >
              <ShareOutlinedIcon className={classes.extendedIcon} />
              Share Items
            </Fab>
          )}
          {!isShare && (
            <Fab
              variant="extended"
              size="medium"
              aria-label="add"
              className={classes.fab}
              onClick={() => {
                history.push({
                  pathname: '/buildcatalougescreen',
                  state: {
                    // getData: getData,
                    items: listData,
                  },
                });
              }}
            >
              <AddIcon className={classes.extendedIcon} />
              Add Items
            </Fab>
          )}
        </Box>
      </Box>
      <MobileFooter />
    </ErrorBoundary>
  );
};

export default VendorCatalogueScreen;
