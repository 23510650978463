import baseUrl from '../utils/Constants';
import getMessageFromStatus from '../utils/getMessageFromStatus';

export default async function (token) {
  try {
    const response = await fetch(`${baseUrl}/deliveries/vendors`, {
      // const response = await fetch(
      //   `http://gcmobile-dev.ap-southeast-1.elasticbeanstalk.com/api/deliveries/vendors`,
      //   {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status === 200) {
      const responseJson = await response.json();
      return responseJson;
    } else {
      return {
        successCode: 0,
        messageCode: getMessageFromStatus(response.status),
        statusCode: response.status,
        url: response.url,
      };
    }
  } catch (error) {
    return {
      successCode: 0,
      messageCode: getMessageFromStatus(0),
    };
  }
}
