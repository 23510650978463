import {makeStyles} from '@material-ui/core';

const useStyle = makeStyles((theme) => ({
  root: {
    height: 50,
    zIndex: 99,
    width: '100%',
    position: 'fixed',
    bottom: 0,
    '& .MuiBottomNavigationAction-root': {
      '@media (max-width: 768px)': {
        minWidth: 'auto',
        padding: '6px 0',
      },
    },
    ['@media (min-width:700px)']: {
      display: 'none',
    },
  },
}));

export default useStyle;
