/* eslint-disable react/prop-types */
import React, {useEffect, useState, useCallback} from 'react';
import {
  Box,
  Typography,
  Checkbox,
  Fab,
  TextField,
  Button,
  Grid,
  Divider,
  Zoom,
} from '@material-ui/core';
import SimpleAutocomplete from '../../components/DumbComponents/SimpleAutocomplete';
import WebToolbar from '../../components/toolbar/WebToolbar';
import useStyle from './style';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {lowerCase, debounce, get} from 'lodash';
import searchItems from '../../services/searchItems';
import {useDispatch, useSelector} from 'react-redux';
import AddIcon from '@material-ui/icons/Add';
import showToast from '../../components/Toast';
import {useHistory} from 'react-router';
import {setItemsListData} from '../../redux/actions/user';
import SideNav from '../../components/sidenav/SideNav';
import AppBarName from '../../components/DumbComponents/AppBarName';
import ErrorBoundary from '../../components/ErrorBoundary';
import ErrorBoundaryFallback from '../../components/ErrorBoundaryFallback';
import MobileFooter from '../MobileFooter';

const SelectItemsScreen = () => {
  const selectItemsData = useSelector((state) => state.user.selectItemsData);
  const classes = useStyle();
  const history = useHistory();
  const dispatch = useDispatch();
  const forceUpdate = useState()[1].bind(null, {});
  const [query, setQuery] = useState('');
  const [loading, setLoading] = useState(false);
  const [searching, setSearching] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const token = useSelector((state) => state.user.token);
  const [items, setItems] = useState([]);
  const billDetails = useSelector((state) => state.user.billDetails);
  const {message, mobileNumber, amount} = billDetails;

  const handleCheckboxChange = (event, index) => {
    const data = items;
    data[index].selected = !data[index].selected;
    setItems(data);
    forceUpdate();
    // setCheckboxChecked(event.target.checked);
  };

  useEffect(() => {
    const data = [];
    selectItemsData.cataloge.forEach((element) => {
      element.selected = false;
      data.push(element);
    });
    setItems(data);
  }, []);

  async function onChangeText(text) {
    setSearching(true);
    setQuery(text);
    if (text.length > 0) {
      setLoading(true);
      handler(text);
    } else {
      setSearchResults([]);
      setLoading(false);
      setSearching(false);
    }
  }

  const handler = useCallback(
    debounce(async (text) => {
      if (!text.trim()) {
        return;
      }
      try {
        const response = await searchItems(
          token,
          text,
          selectItemsData.vendorId
        );
        if (response.successCode === 1) {
          const data = response.response;
          // const obj = {
          //   description: text,
          //   isVendorItem: false,
          //   name: text,
          //   price: '',
          // };
          // data.push(obj);
          setSearchResults(data);
          setSearching(false);
        } else {
          const data = [];
          // const obj = {
          //   description: text,
          //   isVendorItem: false,
          //   name: text,
          // };
          // data.push(obj);
          // showToast('error', "No item's found");
          setSearchResults(data);
          setSearching(false);
        }
        setLoading(false);
        setSearching(false);
      } catch (error) {
        setLoading(false);
        setSearching(false);
      }
    }, 500),
    []
  );

  function onItemSelected(item) {
    const data = items;
    data.push({
      name: item.name,
      price: item.price ? item.price : 0,
      selected: true,
      quantity: 1,
    });
    setItems(data);
    setSearchResults([]);
    setQuery('');
    forceUpdate();
  }

  function addTextItem() {
    if (!query.trim()) {
      showToast('warn', 'Please enter item name');
      return;
    }
    const data = items;
    data.push({
      name: query,
      price: 0,
      selected: true,
      quantity: 1,
    });
    setItems(data);
    setQuery('');
    forceUpdate();
  }

  function onPressNext() {
    const data = [];
    items.forEach((element) => {
      if (element.selected) {
        data.push({
          ...element,
          qty: 1,
        });
      }
    });
    if (data.length === 0) {
      showToast('error', 'Please select at least one item');
    } else {
      dispatch(setItemsListData(data));
      // onItems(data);
      // history.push({
      //   pathname: '/addbilldetails',
      //   state: {message, mobileNumber, amount},
      // });
      history.goBack();
    }
  }
  const onChangeValue = (value) => {
    onItemSelected(value);
  };

  return (
    <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
      <Box className={classes.container}>
        <WebToolbar />
        <div className={classes.sideNav}>
          <SideNav />
        </div>
        <AppBarName title={'Select Items'} />
        <Grid container style={{display: 'flex', marginTop: '14px'}}>
          <Grid item xs={11}>
            {/* <Grid item style={{border: 'solid red'}}>
            <SimpleAutocomplete
              options={
                searchResults
                  ? searchResults.map((option) => {
                      console.log('opt', option.name);
                      return lowerCase(option.name);
                    })
                  : ''
              }
              handleACOnChange={(value) => {
                return onChangeValue(value);
              }}
              labelText="Search Items"
              handleOnChange={onChangeText}
              searching={searching}
            />
          </Grid> */}
            <Autocomplete
              id="free-solo-demo"
              fullWidth
              size="small"
              freeSolo
              disableClearable={true}
              options={
                searchResults ??
                searchResults.map((item) => {
                  console.log('__item', item);
                  return item ? lowerCase(item) : lowerCase(item);
                })
              }
              getOptionLabel={(searchResults) =>
                searchResults
                  ? lowerCase(searchResults.name)
                  : lowerCase(searchResults.name)
              }
              onChange={(event, value) => {
                onChangeValue(value);
                // onItemSelected(value);
                // setItems(value);
                // setQuery(value.name);
                // setSearchResults([]);
                //   setQuery(value.name);
                // setSelectedItem(value);
                // setSearchResults([]);
              }}
              renderInput={(searchItem) => (
                <TextField
                  className={classes.ordersText}
                  {...searchItem}
                  label="Search Items"
                  onChange={(search) => {
                    onChangeText(search.target.value);
                  }}
                  variant="outlined"
                />
              )}
            />
          </Grid>
          <Grid
            item
            xs={1}
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Zoom in={true} timeout={{enter: 500, exit: 500}} unmountOnExit>
              <Fab
                className={classes.fab}
                size="small"
                aria-label="add"
                onClick={addTextItem}
              >
                <AddIcon />
              </Fab>
            </Zoom>
          </Grid>
        </Grid>
        <Box style={{height: '430px', overflow: 'auto'}}>
          {items.length > 0 ? (
            items.map((item, index) => {
              console.log('item', 'index', item, index);
              return (
                <Box key={index} p={1} style={{borderRadius: '5px'}}>
                  <Grid container>
                    <Grid item xs={8} className={classes.gridStyleName}>
                      <Typography>{get(item, 'name', '')}</Typography>
                    </Grid>
                    <Grid item xs={2} className={classes.gridStyle}>
                      <Typography>₹ {get(item, 'price', '')}</Typography>
                    </Grid>
                    <Grid item xs={2} className={classes.gridStyle}>
                      <Checkbox
                        className={classes.tickSize}
                        checked={item.selected ? true : false}
                        // checked={item.selected ? 'checked' : 'unchecked'}
                        // checked={checkboxChecked}
                        onChange={(event) => {
                          handleCheckboxChange(event, index);
                        }}
                        color="primary"
                        inputProps={{'aria-label': 'secondary checkbox'}}
                      />
                    </Grid>
                  </Grid>
                  <Divider />
                </Box>
              );
            })
          ) : (
            <Box p={1}>
              <Typography>No Items</Typography>
            </Box>
          )}
        </Box>
        <Box
          style={{
            display: 'flex',
            justifyContent: 'center',
            // position: 'absolute',
            // bottom: 0,
            padding: '10px',
            width: '100%',
          }}
        >
          <Button
            variant="outlined"
            size="small"
            className={classes.button}
            startIcon={<AddIcon />}
            onClick={onPressNext}
          >
            Add
          </Button>
        </Box>
      </Box>
      <MobileFooter />
    </ErrorBoundary>
  );
};

export default SelectItemsScreen;
