/* eslint-disable react/prop-types */
import React, {useEffect, useState, useCallback} from 'react';
import {
  Box,
  Typography,
  AppBar,
  Tabs,
  Tab,
  Fab,
  Button,
  Tooltip,
  Grid,
  Divider,
  TextField,
  InputAdornment,
  Zoom,
} from '@material-ui/core';
import SendIcon from '@material-ui/icons/Send';
import RemoveOutlinedIcon from '@material-ui/icons/RemoveOutlined';
import AddOutlinedIcon from '@material-ui/icons/AddOutlined';
import CameraAltIcon from '@material-ui/icons/CameraAlt';
import AttachmentIcon from '@material-ui/icons/Attachment';
import Icon from '@material-ui/core/Icon';
import AddIcon from '@material-ui/icons/Add';
import useStyle from './style';
import WebToolbar from '../../components/toolbar/WebToolbar';
import getVendorItems from '../../services/getVendorItems';
import {useSelector, useDispatch} from 'react-redux';
import {useTranslation} from 'react-i18next';
import showToast from '../../components/Toast';
import {useHistory} from 'react-router';
import {get} from 'lodash';
import {setItemsListData, setSelectItemsData} from '../../redux/actions/user';
import sendOrderImages from '../../services/sendOrderImages';
import newOrder from '../../services/sendOrder';
import SideNav from '../../components/sidenav/SideNav';
import {
  a11yProps,
  TabPanel,
} from '../../components/DumbComponents/TapPanelProps';
import CameraDialog from '../../components/DumbComponents/CameraDialog';
import MessageItem from '../../components/MessageItem';
import ImagePreview from '../../components/DumbComponents/ImagePreview';
import AppBarName from '../../components/DumbComponents/AppBarName';
import ErrorBoundary from '../../components/ErrorBoundary';
import ErrorBoundaryFallback from '../../components/ErrorBoundaryFallback';
import MobileFooter from '../MobileFooter';

// const getImageSize = (filePath) => {
// new Promise((resolve, reject) => {
//   Image.getSize(
//     filePath,
//     (width, height) => {
//       resolve({width, height});
//     },
//     (e) => reject(e)
//   );
// });
// };

const AddBillDetails = (props) => {
  const classes = useStyle();
  const history = useHistory();
  const dispatch = useDispatch();
  // const {message, mobileNumber, amount} = props.location.state;
  const {message: msg, mobileNumber, amount} = useSelector(
    (state) => state?.user?.billDetails
  );
  const [value, setValue] = useState(0);
  const token = useSelector((state) => state.user.token);

  const [loading, setLoading] = useState(true);
  const {t} = useTranslation('groups');
  const vendorId = useSelector((state) => state.user.vendorId);

  const user = useSelector((state) => {
    // console.log('us', state.user.user);
    return state.user.user;
  });
  const itemsListData = useSelector((state) => {
    console.log('state.user.itemsListData', state.user.itemsListData);
    return state.user.itemsListData;
  });
  const [reqesting, setReqesting] = useState(false);
  const [listData, setListData] = useState([]);
  const [currentTab, setCurrentTab] = useState(0);
  const [message, setMessage] = useState('');
  const [showCameraDialog, setShowCameraDialog] = useState(false);
  const [cameraOn, setCameraOn] = useState(false);
  const [imageViewer, setImageViewer] = useState(false);
  const [selectedImage, setSelectedImage] = useState('');
  const [data, setData] = useState(
    msg !== ''
      ? [
          {
            message: msg,
            createdBy: user.userId,
            createdOn: new Date(),
          },
        ]
      : []
  );
  const [query, setQuery] = useState('');
  const forceUpdate = useCallback(() => updateState({}), []);
  const [, updateState] = useState();
  const [cataloge, setCataloge] = useState([]);

  useEffect(() => {
    console.log('useEff');
    // dispatch(setItemsListData([]));
    getList();
    dispatch(setSelectItemsData(null));
  }, []);

  async function getList() {
    try {
      const response = await getVendorItems(token);
      if (response.successCode === 1) {
        setCataloge(response.response.vendorTypes);
        setLoading(false);
      } else {
        //showToast('Failed', 'danger');
      }
      setLoading(false);
    } catch (error) {
      showToast('error', 'Failed');
      console.log(error);
      setLoading(false);
    }
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function onPressFab() {
    if (message.trim()) {
      const d = data;
      const obj = {
        message: message,
        createdBy: user.userId,
        createdOn: new Date(),
      };
      d.push(obj);
      setData(d);
      setMessage('');
      forceUpdate();
    }
  }
  function onItems(items) {
    const list = listData;
    list.push(...items);
    setListData(list);
    forceUpdate();
  }

  function addItems() {
    history.push('/selectitemsscreen');
    dispatch(
      setSelectItemsData({
        cataloge: cataloge,
        vendorId: vendorId,
        // onItems: onItems,
        message: message,
        mobileNumber: mobileNumber,
        amount: amount,
      })
    );
  }

  function removeQty(index) {
    // const data = listData;
    const data = itemsListData;
    data[index].qty -= 1;
    if (data[index].qty === 0) {
      data.splice(index, 1);
    }
    dispatch(setItemsListData(data));
    // setListData(data);
    forceUpdate();
  }

  function addQty(index) {
    console.log('ind', index);
    // const d = listData;
    const d = itemsListData;
    d[index].qty = Number(d[index].qty) + 1;
    dispatch(setItemsListData(d));
    // setListData(d);
    forceUpdate();
  }

  async function onPressSend() {
    // dispatch(setItemsListData([]));
    // if (listData.length === 0 && data.length === 0) {
    //   showToast('Please add at least one item or a message', 'danger');
    //   return;
    // }
    const formData = new FormData();
    const orderItems = [];
    const messages = [];
    const images = [];
    console.log('data', data);
    data.forEach((element) => {
      if (element.messageType === 1) {
        images.push(element);
      } else {
        var obj = {
          messageText: element.message,
        };
        messages.push(obj);
      }
    });
    itemsListData.forEach((element) => {
      var obj = {
        Item: element.name,
        Qty: Number(element.qty),
        Unit: '',
        //Price: element.price === 0 ? null : element.price,
      };
      if (element.price) {
        obj.Price = Number(element.price);
      }
      orderItems.push(obj);
    });
    const payload = {
      vendorid: Number(vendorId),
      //mode: 1,
      IsService: 1,
      OrderItems: orderItems,
      orderimages: [],
      ordermessages: messages,
      CustomerMobile: mobileNumber,
      OrderType: 1,
      OrderAmount: Number(amount),
    };
    // const obj = {
    //   TimeSlot: '00:00-00:00',
    //   ServiceDate: moment(new Date()).format('YYYY-MM-DD'),
    //   RequestFor: user.username,
    // };
    // payload.ServiceRequest = obj;
    if (images.length > 0) {
      setReqesting(true);
      const compressedImages = images.map((image, index) => {
        const split = image.name.split('.');
        const fileType = split[split.length - 1];
        return {
          type: image.type,
          path: image.uri,
          name: `file.${fileType}`,
        };
        // if (image.type.includes('image')) {
        //   return image.uri;
        // } else {
        //   const split = image.name.split('.');
        //   const fileType = split[split.length - 1];
        //   return {
        //     type: image.type,
        //     path: image.uri,
        //     name: `file.${fileType}`,
        //   };
        // }
      });
      compressedImages.forEach((i) => {
        if (i.type.includes('image')) {
          formData.append('files', {
            uri: `file:///${i.path}`,
            type: i.type,
            name: i.name,
          });
        } else {
          formData.append('files', {
            uri: `${i.path}`,
            type: i.type,
            name: i.name,
          });
        }
      });
      try {
        const resImages = await sendOrderImages(formData, token);
        if (resImages.successCode === 1) {
          const img = [];
          (get(resImages, 'response', []) || []).forEach((i) => {
            img.push(i.uniqueFileName);
          });
          payload.orderimages = img;
          const response = await newOrder(payload, token);
          setReqesting(false);
          if (response.successCode === 1) {
            showToast('Request sent', 'success');
            dispatch(setItemsListData([]));
            history.goBack();
          } else {
            showToast('error', 'Failed');
          }
        } else {
          showToast('error', 'Failed');
        }
        // console.log(resImages);
      } catch (error) {
        console.log(error);
        setReqesting(false);
      }
    } else {
      setReqesting(true);
      try {
        console.log(payload);
        const response = await newOrder(payload, token);
        setReqesting(false);
        if (response.successCode === 1) {
          showToast('success', 'Request sent');
          // history.goBack();
          history.push('/paymentrequestsscreen');
        } else {
          showToast('error', 'Failed');
        }
      } catch (error) {
        console.log(error);
        showToast('error', 'Failed');
        setReqesting(false);
      }
    }
  }

  async function addAttachment(e) {
    try {
      const res = e.target.files[0];
      if (res.type.includes('image') || res.type.includes('pdf')) {
      } else {
        console.log('Invalid document. Please select image or pdf file only');
        return;
      }
      const d = data;
      const obj = {
        message: message,
        createdBy: user.userId,
        createdOn: new Date(),
        messageType: 1,
        name: res.name,
        uri: URL.createObjectURL(res),
        type: res.type,
      };
      d.push(obj);
      setData(d);
      forceUpdate();
      // onDocument({
      //   path: res.uri,
      //   name: res.name,
      //   mime: res.type,
      // });
    } catch (err) {
      console.log('err', err);
    }
  }

  async function onPhoto() {
    setShowCameraDialog(true);
    setCameraOn(true);
  }

  const dataURLtoFile = (dataurl) => {
    const arr = dataurl.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];

    const filename =
      Math.round(+new Date() / 1000) + '.' + mime.split('/').pop();

    const bstr = window.atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n) {
      u8arr[n - 1] = bstr.charCodeAt(n - 1);
      n -= 1; // to make eslint happy
    }
    return new File([u8arr], filename, {type: mime});
  };

  const closeImagePreviewDialogFunction = (data) => {
    setImageViewer(false);
  };

  const onPressMsg = (msg) => {
    if (msg.messageType === 0) {
      // forceUpdate();
    } else if (msg.messageType === 1) {
      if (msg.item && !msg.item.includes('pdf')) {
        setImageViewer(true);
        setSelectedImage(msg.item);
      } else if (msg.uri) {
        if (!msg.uri.includes('pdf')) {
          setImageViewer(true);
          setSelectedImage(msg.uri);
        }
      }
    }
  };

  const closeShowCameraDialogFunction = (_data) => {
    if (typeof _data === 'object') {
      // const file = dataURLtoFile(_data);
      // console.log('file7', file);
      // const split = _data.split('/');
      // const name = split[split.length - 1];
      const obj = {
        message: message,
        createdBy: user.userId,
        createdOn: new Date(),
        messageType: 1,
        name: _data.name,
        uri: window.URL.createObjectURL(_data),
        type: _data.type,
      };
      let newData = [...data, obj];
      console.log('newData', newData);
      setData(newData);
      setShowCameraDialog(false);
      setCameraOn(false);
    } else if (typeof data === 'object') {
      setShowCameraDialog(false);
      setCameraOn(false);
    }
  };

  return (
    <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
      <Box className={classes.container}>
        <WebToolbar />
        <div className={classes.sideNav}>
          <SideNav />
        </div>
        <AppBarName title={'Add Bill Details'} />
        <Box p={1}>
          <Typography>{`Person : ${mobileNumber}`}</Typography>
          <Typography>{`Amount : ${amount}`}</Typography>
        </Box>
        <Box>
          <AppBar
            position="static"
            className={classes.appBarStyle}
            elevation={0}
          >
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="simple tabs example"
              variant="fullWidth"
            >
              <Tab
                className={classes.tabWidth}
                label="Messages"
                {...a11yProps(0)}
              />
              <Tab
                className={classes.tabWidth}
                label="Items"
                {...a11yProps(1)}
              />
            </Tabs>
          </AppBar>
          <TabPanel
            value={value}
            index={0}
            className={classes.messagesTabPanel}
          >
            <Box container className={classes.msgNtxtContainer}>
              <Box className={classes.msgContainer}>
                {console.log('main data', data)}
                {data.map((data, index) => {
                  return (
                    <Box key={index}>
                      <MessageItem
                        onPress={(msg) => {
                          onPressMsg(msg);
                        }}
                        item={data}
                      ></MessageItem>
                    </Box>
                  );
                })}
              </Box>
              <Box className={classes.txtIpContainer}>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={message}
                  placeholder="Add comments"
                  className={classes.textInputMsg}
                  onChange={(val) => {
                    setMessage(val.target.value);
                  }}
                  InputProps={{
                    endAdornment: (
                      <>
                        <InputAdornment position="end">
                          <Box
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              height: '100%',
                            }}
                          >
                            <input
                              accept="image/*,.pdf"
                              className={classes.input}
                              id="contained-button-file"
                              multiple
                              type="file"
                              onChange={(e) => {
                                addAttachment(e);
                              }}
                            />
                            <label htmlFor="contained-button-file">
                              <AttachmentIcon
                                style={{
                                  cursor: 'pointer',
                                  marginTop: '3px',
                                }}
                              />
                            </label>
                          </Box>
                        </InputAdornment>
                        <InputAdornment position="end">
                          <CameraAltIcon
                            style={{
                              cursor: 'pointer',
                            }}
                            onClick={() => {
                              onPhoto();
                            }}
                          />
                        </InputAdornment>
                      </>
                    ),
                  }}
                ></TextField>
                <Box className={classes.sendMsgBtnContainer}>
                  <Zoom
                    in={true}
                    timeout={{enter: 500, exit: 500}}
                    unmountOnExit
                  >
                    <Fab size="small" aria-label="add" onClick={onPressFab}>
                      <SendIcon />
                    </Fab>
                  </Zoom>
                </Box>
              </Box>
              <Box>
                <CameraDialog
                  open={showCameraDialog}
                  camera={cameraOn}
                  onSimpleDialogClose={closeShowCameraDialogFunction}
                />
                <ImagePreview
                  open={imageViewer}
                  onSimpleDialogClose={closeImagePreviewDialogFunction}
                  image={selectedImage}
                />
              </Box>
            </Box>
          </TabPanel>
          <TabPanel value={value} index={1} className={classes.itemsTabPanel}>
            <Box>
              {itemsListData.length > 0 ? (
                itemsListData.map((data, index) => {
                  return (
                    <>
                      <Grid container key={index}>
                        <Grid item xs={6} className={classes.nameContainer}>
                          <Typography>{get(data, 'name', '')}</Typography>
                        </Grid>
                        <Grid item xs={2} className={classes.priceContainer}>
                          <Typography>₹ {get(data, 'price', '--')}</Typography>
                        </Grid>
                        <Grid item xs={4} className={classes.qtyIncDec}>
                          <Button
                            variant="outlined"
                            size="small"
                            onClick={(eve) => {
                              console.log('index', index, 'eve', eve);
                              removeQty(index);
                            }}
                          >
                            <RemoveOutlinedIcon />
                          </Button>
                          <Typography>{data.qty}</Typography>
                          <Button
                            variant="outlined"
                            size="small"
                            onClick={() => addQty(index)}
                          >
                            <AddOutlinedIcon />
                          </Button>
                        </Grid>
                      </Grid>
                      <Divider style={{marginTop: '5px'}} />
                    </>
                  );
                })
              ) : (
                <Box className={classes.btnContainer}>
                  <Typography>Add Items</Typography>
                </Box>
              )}
            </Box>
            <Box className={classes.fab}>
              <Tooltip title="Add Items">
                <Zoom in={true} timeout={{enter: 500, exit: 500}} unmountOnExit>
                  <Fab size="small" aria-label="add" onClick={addItems}>
                    <AddIcon />
                  </Fab>
                </Zoom>
              </Tooltip>
            </Box>
          </TabPanel>
        </Box>
        <Box my={1} className={classes.btnContainer}>
          <Tooltip title="Send">
            <Button
              variant="outlined"
              className={classes.button}
              startIcon={<Icon>send</Icon>}
              onClick={onPressSend}
            >
              Send
            </Button>
          </Tooltip>
        </Box>
      </Box>
      <MobileFooter />
    </ErrorBoundary>
  );
};

export default AddBillDetails;
