export const nameRules = {
  required: {value: true, message: 'Please fill in fullname'},
};

export const phoneRules = {
  required: {value: true, message: 'Phone number required'},
  pattern: {
    value: /^([6-9]{1})([0-9]{9})$/,
    message: 'Invalid phone number',
  },
};
export const emailRules = {
  pattern: {
    value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    message: 'Please enter a valid email',
  },
};

export const passwodRules = {
  required: {value: true, message: 'Password required'},
  pattern: {
    value: /(?=.*\d)(?=.*[A-Z]).{6,}/,
    message: 'Required atleast one character and a number',
  },
  minLength: {
    value: 6,
    message: 'Minimum 6 characters required',
  },
  maxLength: {
    value: 50,
    message: 'Limit exceed',
  },
};

export const pincodeRules = {
  required: {value: true, message: 'Pincode required'},
  minLength: {
    value: 6,
    message: 'Minimum 6 characters required',
  },
  maxLength: {
    value: 6,
    message: 'Invalid pincode',
  },
};
