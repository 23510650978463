/* eslint-disable react/prop-types */
import {
    Box,
    Typography,
} from '@material-ui/core';
import { isEmpty } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import ActivityIndicator from '../../components/ActivityIndicator';

const InifiniteScrollComponent = ({ loading, listData, scrollListItem, fetchRecords, noDataMessage = "", height = "410px" }) => {
    const listInnerRef = useRef();
    const [currPage, setCurrPage] = useState(1);
    const [prevPage, setPrevPage] = useState(0);
    const [lastList, setLastList] = useState(false);

    useEffect(() => {
        if (!lastList && prevPage !== currPage) {
            fetchRecords(currPage, setLastList, setPrevPage);
        }
    }, [currPage, lastList, prevPage, listData]);

    const onScroll = () => {
        if (listInnerRef.current) {
            const { scrollTop = 0, scrollHeight = 0, clientHeight = 0 } = listInnerRef.current;
            console.log("onscroll", scrollTop, clientHeight, scrollHeight, scrollTop + clientHeight >= scrollHeight);
            if ((scrollTop + clientHeight) >= (scrollHeight - 1)) {
                setCurrPage(currPage + 1);
            }
        }
    };

    return <Box
        onScroll={onScroll}
        ref={listInnerRef}
        style={{
            height: height,
            overflow: 'auto',
        }}
    >
        {loading ? (
            <ActivityIndicator />
        ) :
            !isEmpty(listData) ? (
                listData.map((val, index) => {
                    return scrollListItem(val, index)
                })
            ) : (
                <>
                    {/* <ActivityIndicator /> */}
                    <Typography component={'div'}>{noDataMessage}</Typography>
                </>
            )}
    </Box>
}
export default InifiniteScrollComponent;
