import {makeStyles} from '@material-ui/core';

const useStyle = makeStyles((theme) => ({
  filterBtn: {
    '&:hover': {
      backgroundColor: '#B0B0B0',
    },
    margin: 1,
    width: '10%',
    [theme.breakpoints.down(450)]: {
      maxWidth: '10px',
      maxHeight: '40px',
      minWidth: '10px',
      minHeight: '40px',
    },
  },
  placedOrdersContainer: {
    display: 'flex',
    padding: '10px',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#D3D3D3',
    },
  },
  ordersText: {
    fontSize: '16px',
    display: 'block',
    ['@media (max-width:450px)']: {
      fontSize: '14px',
    },
  },
}));

export default useStyle;
