import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';

import en from '../translations/en';
import mr from '../translations/mr';
import hi from '../translations/hi';

i18n.use(initReactI18next).init({
  lng: 'en',
  resources: {
    en,
    mr,
    hi,
  },
  defaultNS: 'common',
  nsSeparator: ':',
  keySeparator: ':',
  fallbackLng: 'en',
  debug: false,
  interpolation: {
    escapeValue: false,
    formatSeparator: ',',
  },
  react: {
    wait: true,
  },
});

export default i18n;
