import React, {useState} from 'react';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import PeopleIcon from '@material-ui/icons/People';
import ListIcon from '@material-ui/icons/List';
import HomeIcon from '@material-ui/icons/Home';
import CalendarIcon from '@material-ui/icons/CalendarToday';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import {useHistory} from 'react-router';
import useStyle from './styles';

const MobileFooter = () => {
  const [value, setValue] = useState(0);
  const classes = useStyle();
  const history = useHistory();

  const handleChange = (event, newValue) => {
    setValue(newValue);
    switch (newValue) {
      case 0:
        return history.push('/home');
      case 1:
        return history.push('/groups');
      case 2:
        // return history.push('/receivedorders');
        return history.push('/ordersscreen');
      case 3:
        return history.push('/vendorappointmentsscreen');
      case 4:
        return history.push('/paymentrequestsscreen');
      default:
        return history.push('/home');
    }
  };

  return (
    <BottomNavigation
      value={value}
      onChange={handleChange}
      showLabels
      className={classes.root}
    >
      <BottomNavigationAction label="Home" icon={<HomeIcon />} />
      <BottomNavigationAction
        label="Groups"
        icon={<PeopleIcon />}
        // component={Link}
        // to="/groups"
      />
      <BottomNavigationAction label="Orders" icon={<ListIcon />} />
      <BottomNavigationAction label="Appointments" icon={<CalendarIcon />} />
      <BottomNavigationAction label="Requests" icon={<AttachMoneyIcon />} />
    </BottomNavigation>
  );
};

export default MobileFooter;
