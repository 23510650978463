/* eslint-disable react/prop-types */
import {Box, Button, Divider, Grid, Typography} from '@material-ui/core';
import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {useHistory} from 'react-router';
import showToast from '../../components/Toast';
import WebToolbar from '../../components/toolbar/WebToolbar';
import useStyle from './style';
import submitBasketOrder from '../../services/submitBasketOrder';
import AppBarName from '../../components/DumbComponents/AppBarName';
import SideNav from '../../components/sidenav/SideNav';
import ErrorBoundary from '../../components/ErrorBoundary';
import ErrorBoundaryFallback from '../../components/ErrorBoundaryFallback';
import MobileFooter from '../MobileFooter';

const PlaceBasketOrderScreen = (props) => {
  let response;
  const classes = useStyle();
  const history = useHistory();
  const forceUpdate = useState()[1].bind(null, {});
  const token = useSelector((state) => state.user.token);
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [addedItems, setAddedItems] = useState([]);
  const [total, setTotal] = useState(0);

  const placeBasketOrderData = useSelector(
    (state) => state.user.placeBasketOrders
  );

  useEffect(() => {
    const data = [];
    var t = 0;
    placeBasketOrderData.items.forEach((element) => {
      data.push({
        ...element,
        //quantity: 1,
      });
    });
    setAddedItems(data);
  }, []);
  useEffect(() => {
    var t = 0;
    addedItems.forEach((element) => {
      if (element.tentativePrice) {
        t = t + element.tentativePrice * element.quantity;
      }
    });
    setTotal(t);
  }, [JSON.stringify(addedItems)]);

  function onPressAddItems() {
    // navigation.goBack();
    history.goBack();
    // addMoreCallback(addedItems);
  }

  async function onPressSubmit() {
    setLoading(true);
    const data = [];
    addedItems.forEach((element) => {
      const obj = {
        Item: element.itemName,
        Qty: Number(element.quantity),
        Unit: '',
        Price: element.tentativePrice ? Number(element.tentativePrice) : 0,
      };
      data.push(obj);
    });
    console.log('00data', data);
    const payload = {
      basketId: placeBasketOrderData.BasketId,
      mode: 4,
      PreferredPaymentMode: 0,
      OrderItems: data,
    };
    try {
      response = await submitBasketOrder(payload, token);
      console.log('response', response);
      if (response.successCode === 1) {
        showToast('success', 'Basket order placed');
        // getBasketData();
        // navigation.pop(2);
        history.replace('/groups');
      } else {
        showToast('error', 'Falied');
      }
      setLoading(false);
    } catch (error) {
      console.log('er', error);
      setLoading(false);
    }
  }

  function addQty(index) {
    const d = addedItems;
    d[index].quantity = Number(d[index].quantity) + 1;
    setAddedItems(d);
    forceUpdate();
  }

  function removeQty(index) {
    const data = addedItems;
    data[index].quantity -= 1;
    if (data[index].quantity === 0) {
      data.splice(index, 1);
    }
    setAddedItems(data);
    forceUpdate();
  }

  return (
    <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
      <div className={classes.container}>
        <WebToolbar />
        <div className={classes.sideNav}>
          <SideNav />
        </div>
        <AppBarName title={'Place Basket Orders'} />

        <Box p={2}>
          {addedItems.length > 0 ? (
            addedItems.map((item, index) => {
              console.log('ooo', item);
              return (
                <>
                  <Grid container key={index} className={classes.card}>
                    <Grid
                      item
                      xs={8}
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <Typography
                        className={classes.textName}
                      >{`${item.itemName}`}</Typography>
                    </Grid>
                    <Grid
                      item
                      xs={1}
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      {item.tentativePrice !== 0 ? (
                        <Typography
                          className={classes.textPrice}
                        >{`₹ ${item.tentativePrice}`}</Typography>
                      ) : (
                        <Typography className={classes.textPrice}></Typography>
                      )}
                    </Grid>
                    <Grid
                      item
                      xs={3}
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-evenly',
                      }}
                    >
                      <Button
                        size="small"
                        variant="outlined"
                        onClick={() => {
                          removeQty(index);
                        }}
                      >
                        -
                      </Button>
                      <Typography className={classes.textQty}>
                        {item.quantity}
                      </Typography>
                      <Button
                        size="small"
                        variant="outlined"
                        onClick={() => {
                          addQty(index);
                        }}
                      >
                        +
                      </Button>
                    </Grid>
                  </Grid>
                  <Divider style={{margin: '5px'}} />
                </>
              );
            })
          ) : (
            <Box>
              <Typography className={classes.textEmpty}>
                No items added
              </Typography>
            </Box>
          )}
        </Box>
        <Box
          style={{
            position: 'absolute',
            bottom: 0,
            width: '100%',
          }}
        >
          <Typography className={classes.textTotal}>{`Sub total: ${
            total === 0 ? 'To be confirmed by vendor' : `${total}`
          }`}</Typography>
          <Typography className={classes.textTotal}>
            {'Total: To be confirmed by vendor'}
          </Typography>
          <Box
            py={1}
            style={{
              display: 'flex',
              justifyContent: 'space-evenly',
            }}
          >
            <Button
              className={classes.btn}
              variant="outlined"
              uppercase={false}
              size="small"
              // disabled={addedItems.length === 0} // TODO: Check if this is needed.
              // loading={loading}
              onClick={() => onPressAddItems()}
            >
              Add Items
            </Button>
            <Button
              className={classes.btn}
              variant="outlined"
              uppercase={false}
              size="small"
              disabled={addedItems.length === 0 || loading}
              loading={loading}
              onClick={() => onPressSubmit()}
            >
              Submit
            </Button>
          </Box>
        </Box>
      </div>
      <MobileFooter />
    </ErrorBoundary>
  );
};

export default PlaceBasketOrderScreen;
