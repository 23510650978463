import baseUrl from '../utils/Constants';

export default async function (pincode) {
  const response = await fetch(`${baseUrl}/areas/pincode?pinCode=${pincode}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  });
  console.log(response);
  const responseJson = await response.json();
  return responseJson;
}
