import React, {useState, useEffect} from 'react';
import useStyle from './styles';
import WebToolbar from '../../components/toolbar/WebToolbar';
import {Box, Divider, Grid, Typography} from '@material-ui/core';
import {useHistory} from 'react-router';
import moment from 'moment';
import {useDispatch, useSelector} from 'react-redux';
import getSavedOrders from '../../services/getSavedOrders';
import {get} from 'lodash';
import ActivityIndicator from '../../components/ActivityIndicator';
import AppBarName from '../../components/DumbComponents/AppBarName';
import SideNav from '../../components/sidenav/SideNav';
import ErrorBoundary from '../../components/ErrorBoundary';
import ErrorBoundaryFallback from '../../components/ErrorBoundaryFallback';
import MobileFooter from '../MobileFooter';

const CustomerDetails = () => {
  const classes = useStyle();
  const history = useHistory();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const token = useSelector((state) => state.user.token);
  const [savedOrders, setSavedOrders] = useState([]);
  const cartOrderId = useSelector((state) => {
    console.log('cscs', state.user.cartOrderId);
    return state.user.cartOrderId;
  });
  const user = useSelector((state) => {
    console.log('aaa', state.user);
    return state.user.user;
  });

  useEffect(() => {
    getSavedOrdersFunction();
  }, []);

  const getSavedOrdersFunction = async () => {
    try {
      setLoading(true);

      const response = await getSavedOrders(token);
      console.log('cart items', response);
      if (response.successCode === 1) {
        setSavedOrders(response.response.orders);
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (error) {
      console.log('error', error);
      setLoading(false);
    }
  };

  return (
    <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
      <div className={classes.container}>
        <WebToolbar />
        <AppBarName title={'Saved Orders'} />
        <Box className={classes.sideNav}>
          <SideNav />
        </Box>
        {loading ? (
          <ActivityIndicator />
        ) : (
          <Box p={1}>
            {savedOrders.length > 0 ? (
              savedOrders.map((item, index) => {
                return (
                  <Box key={index} className={classes.listItem}>
                    <Grid
                      container
                      onClick={() => {
                        history.push('/placeorder');
                      }}
                      style={{cursor: 'pointer'}}
                    >
                      <Grid item xs={8}>
                        <Typography>
                          Order Id :{get(item, 'orderId', 'NA')}
                        </Typography>
                        <Typography>
                          Order By :{get(item, 'vendorName', 'NA')}
                        </Typography>
                        <Typography>
                          Created :
                          {moment(get(item, 'orderDate', 'NA')).format(
                            'h:mm A, DD/MM/YYYY'
                          )}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={4}
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                        }}
                      >
                        <Typography>{get(item, 'status', 'NA')}</Typography>
                      </Grid>
                    </Grid>
                    <Divider />
                  </Box>
                );
              })
            ) : (
              <Box>
                <Typography>No Saved Orders Found</Typography>
              </Box>
            )}
          </Box>
        )}
      </div>
      <MobileFooter />
    </ErrorBoundary>
  );
};

export default CustomerDetails;
