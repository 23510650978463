/* eslint-disable react/prop-types */
import React, {useEffect, useState, useRef} from 'react';
import useStyle from './style';
import WebToolbar from '../../components/toolbar/WebToolbar';
import {
  Typography,
  Box,
  Divider,
  Grid,
  Button,
  Dialog,
  Paper,
  Zoom,
} from '@material-ui/core';
import MessageItem from '../../components/MessageItem';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import {get, isEmpty} from 'lodash';
import moment from 'moment';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import {useTranslation} from 'react-i18next';
import PropTypes from 'prop-types';
import Webcam from 'react-webcam';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import getBasketDetails from '../../services/getBasketDetails';
import {useDispatch, useSelector} from 'react-redux';
import DeleteIcon from '@material-ui/icons/Delete';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import {useHistory} from 'react-router';
import {setOrderId, setSelectedVendorForOrder} from '../../redux/actions/user';
import showToast from '../../components/Toast';
import closeBasket from '../../services/closeBasket';
import freezeBasket from '../../services/freezeBasket';
import SimpleDialog from '../../components/DumbComponents/SimpleDialog';
import deleteBasketItems from '../../services/deleteBasketItems';
import AppBarName from '../../components/DumbComponents/AppBarName';
import SideNav from '../../components/sidenav/SideNav';
import MessageInput from '../../components/MessageInput';
import addMessage from '../../services/addBasketMessage';
import addBasketFiles from '../../services/addBasketFiles';
import {
  a11yProps,
  TabPanel,
} from '../../components/DumbComponents/TapPanelProps';
import ImagePreview from '../../components/DumbComponents/ImagePreview';
import CameraDialog from '../../components/DumbComponents/CameraDialog';
import ErrorBoundary from '../../components/ErrorBoundary';
import ErrorBoundaryFallback from '../../components/ErrorBoundaryFallback';
import MobileFooter from '../MobileFooter';

const options = ['Submit Basket To Vendor', 'Freeze Basket', 'Close Basket'];

// function _SimpleDialog(props) {
//   const {onClose, open, orders, listD} = props;
//   const token = useSelector((state) => state.user.token);
//   useEffect(() => {
//     console.log('props in dia', props);
//   }, []);

//   const handleClose = () => {
//     onClose();
//   };

//   const _handleListItemClick = async (value) => {
//     try {
//       const response = await freezeBasket(token, orders);
//       if (response.successCode === 1) {
//         showToast('success', 'Basket freezed');
//         onClose();
//         // getList();
//         // navigation.goBack();
//       } else {
//         showToast('warn', response.returnMessage);
//         onClose();
//       }
//     } catch (error) {
//       console.log(error);
//     }
//   };

//   return (
//     <Dialog
//       onClose={handleClose}
//       aria-labelledby="simple-dialog-title"
//       open={open}
//     >
//       <DialogTitle id="simple-dialog-title">Are you sure?</DialogTitle>
//       <List>
//         <ListItem>
//           <Typography>
//             You can not add orders, items to basket once its freezed. Do you
//             want to proceed?
//           </Typography>
//         </ListItem>

//         <ListItem
//           style={{
//             display: 'flex',
//             justifyContent: 'center',
//             justifyContent: 'space-evenly',
//           }}
//         >
//           <Button
//             variant="outlined"
//             color="secondary"
//             onClick={() => {
//               _handleListItemClick();
//             }}
//           >
//             YES
//           </Button>
//           <Button
//             variant="outlined"
//             color="secondary"
//             onClick={() => {
//               onClose();
//             }}
//           >
//             NO
//           </Button>
//         </ListItem>
//       </List>
//     </Dialog>
//   );
// }

// _SimpleDialog.propTypes = {
//   onClose: PropTypes.func.isRequired,
//   open: PropTypes.bool.isRequired,
//   selectedValue: PropTypes.string.isRequired,
//   orders: PropTypes.array.isRequired,
//   dispatch: PropTypes.func.isRequired,
// };

SimpleDialog.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
  selectedValue: PropTypes.string,
  orders: PropTypes.array,
  dispatch: PropTypes.func,
};

const BasketDetails = (props) => {
  // const {
  //   isAdmin,
  //   item: {
  //     name,
  //     purpose,
  //     tentativeOrderDate,
  //     tentativeDeliveryDate,
  //     state,
  //     id,
  //     groupId,
  //   },
  // } = props.location.state;

  const groupDetails = useSelector((state) => {
    console.log('grpDetails', state.user.groupDetails);
    return state.user.groupDetails;
  });
  const basketDetails = useSelector((state) => {
    console.log('basketDetails', state.user.basketDetails);
    return state.user.basketDetails || {};
  });
  const {
    name,
    purpose,
    tentativeOrderDate,
    tentativeDeliveryDate,
    state,
    id,
    groupId,
  } = basketDetails;
  const flatListRef = useRef(null);
  const classes = useStyle();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [value, setValue] = useState(0);
  const forceUpdate = useState()[1].bind(null, {});
  const [loading, setLoading] = useState(true);
  const {t} = useTranslation('groups');
  const token = useSelector((state) => state.user.token);
  const user = useSelector((state) => state.user.user);
  const history = useHistory();
  const dispatch = useDispatch();
  const [sending, setSending] = useState(false);
  const [message, setMessage] = useState('');
  const [messages, setMessages] = useState([]);
  const [items, setItems] = useState([]);
  const [showMenu, setShowMenu] = useState(false);
  const [vendors, setVendors] = useState([]);
  const [basketOrders, setBasketOrders] = useState([]);
  const webRef = useRef(null);
  const [openCamera, setOpenCamera] = useState(false);
  const [capturedImage, setCapturedImage] = useState();
  const [openFilterDialog, setOpenFilterDialog] = useState(false);
  const [_openFilterDialog, _setOpenFilterDialog] = useState(false);
  const [imageViewer, setImageViewer] = useState(false);
  const [selectedImage, setSelectedImage] = useState('');
  const [showCameraDialog, setShowCameraDialog] = useState(false);
  const [cameraOn, setCameraOn] = useState(false);

  const closeFilterDialogFunction = (value) => {
    setOpenFilterDialog(false);
  };

  const _closeFilterDialogFunction = (value) => {
    _setOpenFilterDialog(false);
  };

  const FileComponent = ({message, type, item, i}) => {
    const [imageViewer, setImageViewer] = useState(false);
    const [selectedImage, setSelectedImage] = useState([]);
    const [initialIndex, setInitialIndex] = useState(0);

    // console.log('item', item);
    const split = item.split('?');
    const split1 = split[0].split('.');

    // console.log('split', 'split1', split, split1);

    const fileType = split1[split1.length - 1];
    // console.log('fileType', fileType);

    async function onPressFile() {
      try {
        const fileExtention = fileType;
        const tmp = split[0].split('/');
        const name = tmp;
        let directory = `${name[name.length - 1]}`;
        window.open(item);

        console.log(
          'fileExtention',
          'tmp',
          'directory',
          fileExtention,
          tmp,
          directory
        );
      } catch (error) {
        console.log(error);
      }
    }

    if (
      fileType === 'png' ||
      fileType === 'PNG' ||
      fileType === 'jpg' ||
      fileType === 'JPG' ||
      fileType === 'jpeg' ||
      fileType === 'JPEG'
    ) {
      return (
        <>
          <Box
            onClick={() => {
              handleClickOpenImgViewer();
              // setSelectedImage([
              //   {
              //     url: `${item}`,
              //   },
              // ]);
            }}
          >
            <img
              src={item}
              height="150px"
              width="200px"
              style={{cursor: 'pointer'}}
            />
          </Box>
          <Dialog
            onClose={handleCloseImgViewer}
            aria-labelledby="simple-dialog-title"
            open={open}
          >
            <img src={item} />
          </Dialog>
        </>
      );
    } else if (fileType === 'pdf') {
      return (
        <>
          <Box
            style={{
              display: 'flex',
              flexDirection: 'row',
            }}
            onClick={() => onPressFile()}
          >
            <PictureAsPdfIcon color="white" name="pdf-box" size={48} />
            <Typography className={classes.texFile}>PDF File</Typography>
          </Box>
        </>
      );
    } else {
      return <Box />;
    }
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    getBasketData();
  }, []);

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseBasketListItemClick = async () => {
    try {
      const response = await closeBasket(token, id);
      console.log('response close basket', response);
      if (response.successCode === 1) {
        showToast('success', 'Basket closed');
        history.push('/basketsscreen');
        closeFilterDialogFunction();
      } else {
        showToast('warn', response.returnMessage);
        history.push('/basketsscreen');
        closeFilterDialogFunction();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleFreezeBasketListItemClick = async () => {
    try {
      const response = await freezeBasket(token, id);
      if (response.successCode === 1) {
        showToast('success', 'Basket freezed');
        _closeFilterDialogFunction();
        // getList();
        history.push('/basketsscreen');
      } else {
        showToast('error', response.returnMessage);
        _closeFilterDialogFunction();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleMenuItemClick = (option) => {
    console.log('option', option);
    setAnchorEl(null);

    switch (option) {
      case 'close':
        setOpenFilterDialog(!openFilterDialog);

        break;
      case 'freeze':
        _setOpenFilterDialog(!_openFilterDialog);
        break;
      // case 'unfreeze':
      //   _setOpenFilterDialog(!_openFilterDialog);
      //   break;
      case 'submit':
        history.push({
          pathname: 'submitbasket',
          state: {
            vendors,
            basketId: id,
          },
        });
        break;
      default:
        setShowMenu(false);
        break;
    }
  };

  async function getBasketData() {
    setLoading(true);
    const response = await getBasketDetails(token, id, groupId);
    console.log('response', response);
    if (response.successCode === 1) {
      setMessages(response.response.messages);
      setItems(response.response.basketItems);
      setVendors(response.response.basketVendors);
      setBasketOrders(response.response.basketOrders);
    }
    setLoading(false);
  }

  async function removeItem(i, index) {
    console.log('in remove item');
    const payload = {
      GroupId: groupId,
      BasketId: id,
      ItemIds: [i.id],
    };
    try {
      const response = await deleteBasketItems(payload, token);
      console.log('response', response);
      if (response.successCode === 1) {
        // const data = basketItems;
        // data.splice(index, 1);
        //setBasketItems(data);
        // forceUpdate();

        showToast('success', 'Removed');
        getBasketData();
      } else {
        showToast('error', 'Failed');
      }
    } catch (error) {
      showToast('error', 'Failed');
      console.log(error);
    }
  }

  async function sendMessage() {
    if (message?.trim()) {
      setSending(true);
      const payload = {
        GroupId: groupDetails.group.id,
        Message: message,
        BasketId: basketDetails.id,
      };

      const response = await addMessage(payload, token);
      console.log('response msg', response);
      if (response.successCode === 1) {
        const i = {
          communicationId: response.response.communicationId,
          createdBy: user.userId,
          createdOn: new Date().toString(),
          item: message,
          messageType: 0,
        };
        const data = messages;
        //data.push(i);
        data.splice(0, 0, i);
        setMessages(data);
        setMessage('');
        if (flatListRef.current) {
          flatListRef.current.scrollToOffset({animated: true, offset: 0});
        }
      }
      setSending(false);
    }
  }

  const closeImagePreviewDialogFunction = () => {
    setImageViewer(false);
  };

  const closeShowCameraDialogFunction = async (capturedFile) => {
    setShowCameraDialog(false);
    setCameraOn(false);

    const payload = new FormData();
    if (capturedFile.name) {
      payload.append('files', capturedFile);
    }
    payload.append('groupId', groupDetails.group.id);
    payload.append('basketId', basketDetails.id);
    try {
      const response = await addBasketFiles(payload, token);
      console.log('response addBasketFiles', response);
      if (response.successCode === 1) {
        const i = {
          message: response.response.contentType[0].uniqueFileName,
          item: response.response.contentType[0].uniqueFileName,
          messageType: 1,
          communicationDate: new Date(),
          createdBy: user.userId,
        };
        const data = messages;
        data.push(i);
        data.splice(0, 0, i);
        setMessages(data);
        forceUpdate();
        if (flatListRef.current) {
          flatListRef.current.scrollToOffset({animated: true, offset: 0});
        }
      } else {
        // setLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  async function sendFile(file) {
    console.log('file', file);
    const payload = new FormData();
    if (file.name) {
      payload.append('files', file);
      // payload.append('files', {
      //   uri: file.path,
      //   type: file.mime,
      //   name: file.name,
      // });
    } else {
      const split = file.path.split('/');
      const name = split[split.length - 1];
      // payload.append('files', {
      //   uri: file.path,
      //   type: file.mime,
      //   name: name,
      // });
      payload.append('files', file);
    }
    console.log('groupId', groupDetails.group.id, 'basketId', basketDetails.id);
    payload.append('groupId', groupDetails.group.id);
    payload.append('basketId', basketDetails.id);
    //console.log(payload);
    try {
      const response = await addBasketFiles(payload, token);
      console.log('response addBasketFiles', response);
      if (response.successCode === 1) {
        const i = {
          message: response.response.contentType[0].uniqueFileName,
          item: response.response.contentType[0].uniqueFileName,
          messageType: 1,
          communicationDate: new Date(),
          createdBy: user.userId,
        };
        const data = messages;
        data.push(i);
        data.splice(0, 0, i);
        setMessages(data);
        forceUpdate();
        if (flatListRef.current) {
          flatListRef.current.scrollToOffset({animated: true, offset: 0});
        }
      } else {
        // setLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  }

  const onPhoto = () => {
    setShowCameraDialog(true);
    setCameraOn(true);
  };

  return (
    <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
      <div className={classes.container}>
        <WebToolbar />
        <div className={classes.sideNav}>
          <SideNav />
        </div>
        <AppBarName backscreenUrl="" title={'Basket Details'} />

        <Box my={1} boxShadow={1} className={classes.basketDetailCont}>
          <Box className={classes.basketDetailContOne}>
            <Typography className={classes.basketName}>{name}</Typography>
            <SimpleDialog
              open={openFilterDialog}
              onSimpleDialogClose={closeFilterDialogFunction}
              message="Do you really want to close this basket?"
              handleBasketListItemClick={handleCloseBasketListItemClick}
            />
            <SimpleDialog
              open={_openFilterDialog}
              message="You can not add orders, items to basket once its freezed. Do you want to proceed?"
              onSimpleDialogClose={_closeFilterDialogFunction}
              handleBasketListItemClick={handleFreezeBasketListItemClick}
            />
            <Box className={classes.menuCont}>
              <Typography
                className={
                  state === 2
                    ? classes.textFreeze
                    : state === 4
                    ? classes.textClosed
                    : classes.textActive
                }
              >
                {state === 2 ? 'Freezed' : state === 4 ? 'Closed' : 'Active'}
              </Typography>
              {state !== 4 ? (
                <IconButton
                  disabled={state === 4}
                  className={classes.icon}
                  aria-label="more"
                  aria-haspopup="true"
                  onClick={state === 4 ? null : handleClick}
                >
                  <MoreVertIcon />
                </IconButton>
              ) : (
                ''
              )}

              <Menu
                id="long-menu"
                anchorEl={anchorEl}
                keepMounted={false}
                PaperProps={{
                  className: classes.menuDropdown,
                }}
                open={open}
                onClose={handleClose}
              >
                <MenuItem onClick={() => handleMenuItemClick('submit')}>
                  Submit basket to vendor
                </MenuItem>
                {state === 2 ? (
                  <MenuItem onClick={() => handleMenuItemClick('unfreeze')}>
                    Unfreeze Basket
                  </MenuItem>
                ) : (
                  <MenuItem onClick={() => handleMenuItemClick('freeze')}>
                    Freeze Basket
                  </MenuItem>
                )}

                <MenuItem onClick={() => handleMenuItemClick('close')}>
                  Close Basket
                </MenuItem>
              </Menu>

              {/* <Typography
              style={{
                backgroundColor: 'green',
                color: 'white',
                paddingLeft: '5px',
                paddingRight: '5px',
                borderRadius: '5px',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {state === 2 ? 'Freezed' : state === 4 ? 'Closed' : 'Active'}
            </Typography>
            <IconButton
              className={classes.icon}
              aria-label="more"
              aria-haspopup="true"
              onClick={handleClick}
            >
              <MoreVertIcon />
            </IconButton>
            <Menu
              id="long-menu"
              anchorEl={anchorEl}
              keepMounted={false}
              PaperProps={{
                style: {
                  transform: 'translateX(-150px) translateY(50px)',
                },
              }}
              open={open}
              onClose={handleClose}
            >
              {options.map((option, index) => (
                <MenuItem
                  key={index}
                  selected={option === 'Pyxis'}
                  onClick={() => handleMenuItemClick(option, index)}
                >
                  {option}
                </MenuItem>
              ))}
            </Menu> */}
            </Box>
          </Box>

          <Typography className={classes.basketDetailText}>
            {purpose}
          </Typography>
          <Typography className={classes.basketDetailText}>
            Tentative Delivery Date:{' '}
            {moment(tentativeDeliveryDate).format('ddd MMM Do YYYY')}
          </Typography>
          <Typography className={classes.basketDetailText}>
            Order Acceptance Till:
            {moment(tentativeOrderDate).format('ddd MMM Do YYYY')}
          </Typography>
        </Box>
        <Box boxShadow={1}>
          <div className={classes.root}>
            <AppBar position="static" elevation={0}>
              <Tabs
                value={value}
                onChange={handleChange}
                variant="fullWidth"
                aria-label="full width tabs example"
              >
                <Tab
                  label="Orders"
                  {...a11yProps(0)}
                  className={classes.tabFont}
                />
                <Tab
                  label="Messages"
                  {...a11yProps(1)}
                  className={classes.tabFont}
                />
                <Tab
                  label="Vendors"
                  {...a11yProps(2)}
                  className={classes.tabFont}
                />
                <Tab
                  label="Items"
                  {...a11yProps(3)}
                  className={classes.tabFont}
                />
              </Tabs>
            </AppBar>
            <Box>
              <TabPanel
                value={value}
                index={0}
                style={{height: '350px', overflow: 'auto'}}
              >
                <Box>
                  {basketOrders.length > 0 ? (
                    basketOrders.map((order, index) => {
                      console.log('ord', order);
                      return (
                        <>
                          <Box
                            key={index}
                            className={classes.card}
                            style={{cursor: 'pointer'}}
                            onClick={() => {
                              dispatch(setOrderId({orderId: order.orderId}));
                              history.push({
                                pathname: '/orderdetails',
                                state: {
                                  // item: {orderId: order.orderId},
                                  isVendorOrder: false,
                                },
                              });
                              // navigation.navigate('VendorOrderDetails', {
                              //   item: {orderId: item.orderId},
                              //   isVendorOrder: false,
                              // });
                            }}
                          >
                            <Box style={{display: 'flex'}}>
                              <Typography
                                style={{flex: 1}}
                              >{`Order ID: ${order.orderId}`}</Typography>
                              <Typography>{`${t(
                                order.statusDescription
                              )}`}</Typography>
                            </Box>
                            {order.vendorName && (
                              <Typography>{`Vendor: ${order.vendorName}`}</Typography>
                            )}
                            {/* <Typography>{`Date: ${new Date().toDateString()}`}</Typography> */}
                            <Typography>{`Date: ${moment(
                              order.orderDate
                            ).format('ddd, MMM Do YYYY, h:mm A')}`}</Typography>

                            <Divider />
                          </Box>
                          <Divider />
                        </>
                      );
                    })
                  ) : (
                    <Box>
                      <Typography>No orders found.</Typography>
                    </Box>
                  )}
                </Box>
                <Zoom in={true} timeout={{enter: 500, exit: 500}} unmountOnExit>
                  <Fab
                    disabled={state !== 0}
                    className={classes.ordersFab}
                    aria-label="add"
                    onClick={() => {
                      history.push({
                        pathname: '/createbasketordersscreen',
                        state: {
                          items,
                          item: {
                            name,
                            purpose,
                            tentativeOrderDate,
                            tentativeDeliveryDate,
                            state,
                            id,
                            groupId,
                          },
                          // getBasketData,
                        },
                      });
                    }}
                  >
                    <AddIcon />
                  </Fab>
                </Zoom>
              </TabPanel>
              <TabPanel
                value={value}
                index={1}
                className={classes.messagesTabPanel}
                style={{height: '310px', overflow: 'auto'}}
              >
                <Grid
                  container
                  component={Paper}
                  direction={'column'}
                  style={{
                    height: '260px',
                    // overflow: 'auto',
                    backgroundColor: '#f2f2f2',
                    width: '100%',
                    overflow: 'hidden',
                    position: 'relative',
                  }}
                >
                  <Grid
                    style={{
                      overflow: 'auto',
                      height: '80%',
                    }}
                  >
                    {messages.length > 0 ? (
                      messages.map((singleMessage, index) => {
                        return singleMessage.length !== 0 ? (
                          <MessageItem
                            onLongPress={() => {}}
                            item={singleMessage}
                            onPress={(item) => {
                              setSelectedImage(item.item);
                              if (!item.item.includes('pdf')) {
                                setImageViewer(true);
                              }
                            }}
                          />
                        ) : (
                          /* <Box
                            key={index}
                            boxShadow={3}
                            className={
                              user.userId === singleMessage.createdBy
                                ? classes.cardSelfMsg
                                : classes.cardMessage
                            }
                          >
                            {user.userId !== singleMessage.createdBy && (
                              <Typography style={{fontWeight: '900'}}>
                                {singleMessage.sender}
                              </Typography>
                            )}
                            {singleMessage.communicationType === 1 ? (
                              <FileComponent
                                message={singleMessage.message}
                                type={singleMessage.communicationType}
                                item={singleMessage.message}
                                i={singleMessage}
                              />
                            ) : (
                              <Tooltip title={singleMessage.message}>
                                <Typography
                                  className={
                                    user.userId === singleMessage.createdBy
                                      ? classes.abc
                                      : classes.xyz
                                  }
                                >
                                  {singleMessage.item}
                                </Typography>
                              </Tooltip>
                            )}
  
                            <Typography
                              style={{
                                fontSize: 10,
                                textAlign: 'right',
                              }}
                            >
                              {moment(new Date(singleMessage.createdOn)).format(
                                'DD/MM/YYYY, h:mm A'
                              )}
                            </Typography>
                          </Box> */

                          ''
                        );
                      })
                    ) : (
                      <Box p={1}>
                        <Typography>No messages</Typography>
                      </Box>
                    )}

                    <Box style={{display: 'flex'}}>
                      <CameraDialog
                        open={showCameraDialog}
                        camera={cameraOn}
                        onSimpleDialogClose={closeShowCameraDialogFunction}
                      />
                    </Box>
                    <Box>
                      <ImagePreview
                        open={imageViewer}
                        onSimpleDialogClose={closeImagePreviewDialogFunction}
                        image={selectedImage}
                      />
                    </Box>
                  </Grid>
                  <Grid className={classes.msgIpCont}>
                    <MessageInput
                      message={message}
                      onDocument={(doc) => sendFile(doc)}
                      onPhoto={(image) => onPhoto(image)}
                      setMessage={(text) => setMessage(text)}
                      placeholder="Enter your message here"
                      onPressFab={() => {
                        sendMessage();
                      }}
                      disabled={false}
                    />
                  </Grid>
                </Grid>
              </TabPanel>
              <TabPanel
                value={value}
                index={2}
                style={{height: '350px', overflow: 'auto'}}
              >
                <Box>
                  {vendors.length > 0 ? (
                    vendors.map((vendor, index) => {
                      return (
                        <Box
                          key={index}
                          style={{cursor: 'pointer'}}
                          onClick={() => {
                            dispatch(setSelectedVendorForOrder(vendor));
                            history.push('placeorder');
                          }}
                        >
                          <Typography>
                            {get(vendor, 'vendorName', 'NA')}
                          </Typography>
                          <Divider style={{marginTop: '4px'}} />
                        </Box>
                      );
                    })
                  ) : (
                    <Box>
                      <Typography>No vendors available</Typography>
                    </Box>
                  )}
                </Box>
                <Zoom in={true} timeout={{enter: 500, exit: 500}} unmountOnExit>
                  <Fab
                    disabled={!groupDetails.isAdmin || state !== 0}
                    className={classes.vendorsFabCont}
                    aria-label="add"
                    onClick={() => {
                      history.push({
                        pathname: '/addbasketvendors',
                        state: {
                          payload: {
                            name,
                            purpose,
                            tentativeOrderDate,
                            tentativeDeliveryDate,
                            state,
                            id,
                            groupId,
                          },
                          getList: null,
                          isNew: true,
                        },
                      });
                    }}
                  >
                    <AddIcon />
                  </Fab>
                </Zoom>
              </TabPanel>
              <TabPanel
                value={value}
                index={3}
                style={{height: '350px', overflow: 'auto'}}
              >
                <Box>
                  {items.length > 0 ? (
                    items.map((item, index) => {
                      return (
                        <>
                          <Grid key={index} container>
                            <Grid item xs={4}>
                              <Typography>
                                {get(item, 'itemName', 'NA')}
                              </Typography>
                            </Grid>
                            <Grid
                              item
                              xs={4}
                              style={{
                                display: 'flex',
                                justifyContent: 'center',
                              }}
                            >
                              {item.tentativePrice !== 0 && (
                                <Typography>
                                  ₹ {get(item, 'tentativePrice', 'NA')}
                                </Typography>
                              )}
                            </Grid>
                            <Grid
                              item
                              xs={4}
                              style={{
                                display: 'flex',
                                justifyContent: 'end',
                              }}
                            >
                              {groupDetails.isAdmin && (
                                <Box
                                  disabled={
                                    item.state !== 0 || item.state === 3
                                  }
                                  onClick={() => removeItem(item, index)}
                                >
                                  <DeleteIcon
                                    color="error"
                                    style={{cursor: 'pointer'}}
                                    onClick={() => {
                                      // removeItem(index);
                                    }}
                                  />
                                </Box>
                              )}
                            </Grid>
                          </Grid>
                          <Divider />
                        </>
                      );
                    })
                  ) : (
                    <Box>
                      <Typography>No items found</Typography>
                    </Box>
                  )}
                </Box>
                <Zoom in={true} timeout={{enter: 500, exit: 500}} unmountOnExit>
                  <Fab
                    disabled={state !== 0}
                    className={classes.fab}
                    aria-label="add"
                    onClick={() => {
                      history.push({
                        pathname: '/additemstobasket',
                        state: {
                          items,
                          payload: {
                            name,
                            purpose,
                            tentativeOrderDate,
                            tentativeDeliveryDate,
                            state,
                            id,
                            groupId,
                          },
                        },
                      });
                    }}
                  >
                    <AddIcon />
                  </Fab>
                </Zoom>
              </TabPanel>
            </Box>
          </div>
        </Box>
      </div>
      <MobileFooter />
    </ErrorBoundary>
  );
};

export default BasketDetails;
