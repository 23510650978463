/* eslint-disable react/prop-types */
import React from 'react';
import useStyle from './styles';
import {Box, Typography, Grid, Divider} from '@material-ui/core';
import {TabPanel} from '../../components/DumbComponents/TapPanelProps';

const OrderItemsTabPanel = ({value, orderItems}) => {
  const classes = useStyle();

  return (
    <TabPanel value={value} index={0}>
      {orderItems ? (
        <Grid container style={{cursor: 'pointer'}}>
          <Grid item xs={9}>
            <Typography
              style={{
                fontWeight: 600,
              }}
            >
              Items
            </Typography>
          </Grid>
          <Grid
            item
            xs={1}
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Typography
              style={{
                fontWeight: 600,
              }}
            >
              Qty
            </Typography>
          </Grid>
          <Grid
            item
            xs={2}
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Typography
              style={{
                fontWeight: 600,
              }}
            >
              Total
            </Typography>
          </Grid>
        </Grid>
      ) : (
        "No item's found"
      )}

      {orderItems
        ? orderItems.map((val, index) => {
            return (
              <Box key={index}>
                <Box className={classes.orderItmCont}>
                  <Grid container style={{cursor: 'pointer'}}>
                    <Grid item xs={9}>
                      <Typography className={classes.ordersText} style={{}}>
                        {val.item}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={1}
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                    >
                      <Typography className={classes.ordersText}>
                        {val.qty}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={2}
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                    >
                      <Typography className={classes.ordersText}>
                        {`₹ ${parseFloat(val?.price) >= 0 ? val.price : 'N/A'}`}
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
                <Divider />
              </Box>
            );
          })
        : ''}
    </TabPanel>
  );
};

export default OrderItemsTabPanel;
