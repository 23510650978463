import {makeStyles} from '@material-ui/styles';
import Colors from '../../theme/Colors';

const useStyle = makeStyles((theme) => ({
  appBarTitle: {
    border: 'solid red',
    backgroundColor: 'red',
    color: 'green',
    display: 'flex',
    justifyContent: 'center',
  },
  container: {
    //margin: 10,
    paddingBottom: 50,
    backgroundColor: 'white',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    width: '80%',
    margin: 'auto',
    '& > header > header': {
      position: 'inherit',
      // display: 'flex',
      // justifyContent: 'center',
      // width: '80%',
    },
  },
  textInput: {
    borderRadius: 4,
    flex: 1,
    // backgroundColor: '#F5F5F5',
  },
  containerPassword: {
    alignItems: 'center',
    flexDirection: 'row',
  },
  iconPassword: {
    position: 'absolute',
    right: 0,
    marginEnd: 12,
    zIndex: 5,
  },
  btn: {
    marginTop: 30,
    borderRadius: 5,
    marginBottom: 10,
    height: 50,
  },
  card: {
    padding: 10,
    //elevation: 2,
    borderRadius: 10,
    paddingTop: 10,
    //marginTop: 20,
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  textGender: {
    fontSize: 18,
    color: 'black',
    marginRight: 20,
    // fontWeight: 'bold',
  },
  textMale: {
    fontSize: 16,
    color: 'black',
  },
  containerPicker: {
    // backgroundColor: '#F5F5F5',
    border: 'gray',
  },
  picker: {
    //width: width - 40,
  },
  textPicker: {
    color: '#989898',
    position: 'absolute',
    top: -10,
    left: 0,
    marginHorizontal: 10,
    paddingHorizontal: 1,
    backgroundColor: 'white',
    zIndex: 10,
    fontSize: 12,
    marginTop: 2,
  },
  viewImg: {
    height: 100,
    width: 100,
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    marginBottom: 10,
  },
  image: {
    height: 100,
    width: 100,
    borderRadius: 50,
  },
  btnPlus: {
    position: 'absolute',
    top: 0,
    right: 0,
    backgroundColor: Colors.secondary,
    elevation: 2,
    height: 36,
    width: 36,
    borderRadius: 18,
    justifyContent: 'center',
    alignItems: 'center',
  },
  placeholder: {
    height: 100,
    width: 100,
    borderRadius: 50,
    backgroundColor: 'lightgray',
    justifyContent: 'center',
    alignItems: 'center',
  },
  textBtn: {
    color: 'white',
    fontSize: 24,
  },
  modalCamera: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  containerCamModal: {
    width: 250,
    backgroundColor: 'white',
    paddingVertical: 10,
    paddingHorizontal: 5,
    borderRadius: 4,
    justifyContent: 'center',
  },
  textModal: {
    fontSize: 16,
    flex: 1,
  },
  containerItem: {
    flexDirection: 'row',
    alignItems: 'center',
    padding: 10,
  },
  ageGroupContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    border: '1px solid gray',
    borderRadius: '5px',
    padding: '5px',
  },
  ageGroupChip: {
    width: '7rem',
    margin: '5px',
  },
  chip: {
    margin: 2,
    elevation: 2,
    backgroundColor: 'lightgray',
    width: 130,
    justifyContent: 'center',
    alignItems: 'center',
  },
  chipSelected: {
    backgroundColor: 'green',
    elevation: 2,
    width: 130,
    justifyContent: 'center',
    alignItems: 'center',
  },
  containerCategories: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: 5,
    // borderWidth: 0.7,
    // width: width - 20,
  },
  _root: {
    display: 'flex',
    padding: '5px',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  chipOne: {},
  chipTwo: {},
}));

export default useStyle;
