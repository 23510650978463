const baseUrl = 'https://api.gearconnect.in/api';
// "https://api.gearconnect.in/api"
// const baseUrl = "https://api.gearconnect.in/api"
// const baseUrl =
//   'http://geconnecttest-dev.ap-southeast-1.elasticbeanstalk.com/api';
const s3BaseUrl = 'https://mybuddyimgs.s3.ap-southeast-1.amazonaws.com';

// const productionBaseUrl = 'https://api.gearten.in/api';
// const devBaseUrl =
//   'http://gcmobile-dev.ap-southeast-1.elasticbeanstalk.com/api';

export default baseUrl;

export {s3BaseUrl};
