/* eslint-disable react/prop-types */
import React, {useState, useMemo, useCallback, useEffect} from 'react';
import PropTypes from 'prop-types';
import {Typography, TextField, Box} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import SearchIcon from '@material-ui/icons/Search';
import debounce from 'lodash/debounce';
import Grid from '@material-ui/core/Grid';
import PincodeModal from '../PincodeModal';
import {useSelector, useDispatch} from 'react-redux';
import getVendorByCategoryId from '../../services/getVendorByCategoryId';
import {makeStyles} from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';

import Chip from '@material-ui/core/Chip';
import useStyles from './styles';
import {useHistory} from 'react-router';
import {setSelectedVendorForOrder} from '../../redux/actions/user';

function VendorSearchbarByCatagory(props) {
  const history = useHistory();
  const dispatch = useDispatch();
  const token = useSelector((state) => state.user.token);
  const classes = useStyles();
  const [searching, setSearching] = useState(false);
  const [query, setQuery] = useState('');
  const [options, setOptions] = useState([]);
  const [value, setValue] = useState(null);

  useEffect(() => {
    console.log('props', props);
  }, []);

  const handler = useCallback(
    debounce(async (text) => {
      if (!text.trim()) {
        return;
      }
      try {
        const response = await getVendorByCategoryId(
          token,
          props.categoryId,
          text
        );
        console.log('search response', response);
        if (response.successCode === 1) {
          setOptions(response.response.customerVendors);
        } else {
        }
        setSearching(false);
      } catch (error) {
        console.log(error);
        setSearching(false);
      }
    }, 500),
    []
  );

  function onQueryChange(text) {
    if (text.length > 0) {
      setSearching(true);
      handler(text);
    } else {
      setOptions([]);
      setSearching(false);
    }
  }

  return (
    <div>
      <Autocomplete
        id="asynchronous-demo"
        fullWidth
        size="small"
        options={options}
        getOptionLabel={(options) => {
          console.log('option', options);
          return options ? options.organizationName : options.organizationName;
        }}
        autoComplete={false}
        loading={searching}
        includeInputInList
        filterSelectedOptions
        value={value}
        onChange={(event, newValue) => {
          console.log('newValue', newValue);
          //setOptions(newValue ? [newValue, ...options] : options);
          setValue(newValue);
        }}
        onInputChange={(event, newInputValue) => {
          console.log('newInputValue', newInputValue);
          setSearching(true);
          onQueryChange(newInputValue);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Search Vendors"
            variant="outlined"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {searching ? (
                    <CircularProgress color="secondary" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
        noOptionsText="No match found"
        renderOption={(option, index) => {
          console.log('optot', option);
          return (
            <Box
              style={{width: '100%'}}
              key={index}
              onClick={() => {
                dispatch(setSelectedVendorForOrder({vendor: option}));
                history.push('/placeorder');
              }}
            >
              <Grid container alignItems="center">
                <Grid item>
                  <Typography className={classes.textVendorName}>
                    {option.organizationName}
                  </Typography>
                  <Typography className={classes.textAddress}>
                    {`${option.vendorAddress.subLocality}, ${option.vendorAddress.locality}, ${option.vendorAddress.city}, ${option.vendorAddress.pinCode}.`}
                  </Typography>
                  <Box
                    style={{
                      display: 'flex',
                    }}
                  >
                    {option.categories.map((opt, index) => {
                      return (
                        <Chip
                          style={{
                            marginRight: '5px',
                            backgroundColor: '#DCDCDC',
                          }}
                          key={index}
                          boxShadow={3}
                          label={opt.description}
                        />
                      );
                    })}
                  </Box>
                </Grid>
              </Grid>
              <Divider light variant="fullWidth" style={{marginTop: '4px'}} />
            </Box>
          );
        }}
      />
    </div>
  );
}

VendorSearchbarByCatagory.propTypes = {
  showPincodeModal: PropTypes.func,
};

export default VendorSearchbarByCatagory;
