/* eslint-disable react/prop-types */
import React, {useEffect, useState, useCallback} from 'react';
import WebToolbar from '../../components/toolbar/WebToolbar';
import useStyle from './style';
import {Box, TextField, Typography, Grid, Button} from '@material-ui/core';
import moment from 'moment';
import showToast from '../../components/Toast';
import {useHistory} from 'react-router';
import {get, debounce, lowerCase} from 'lodash';
import searchItems from '../../services/searchItems';
import {useSelector} from 'react-redux';
import Autocomplete from '@material-ui/lab/Autocomplete';
import DeleteIcon from '@material-ui/icons/Delete';
import Divider from '@material-ui/core/Divider';
import addBasketItem from '../../services/addBasketItem';
import AppBarName from '../../components/DumbComponents/AppBarName';
import SideNav from '../../components/sidenav/SideNav';
import SimpleAutocomplete from '../../components/DumbComponents/SimpleAutocomplete';
import ErrorBoundary from '../../components/ErrorBoundary';
import ErrorBoundaryFallback from '../../components/ErrorBoundaryFallback';
import MobileFooter from '../MobileFooter';
const AddItemsToBasket = (props) => {
  // const {items, payload} = props.location.state;
  const classes = useStyle();
  const history = useHistory();
  const [query, setQuery] = useState('');
  const [price, setPrice] = useState('');
  const [items, setItems] = useState([]);
  const [selectedItem, setSelectedItem] = useState('');
  const [searchResults, setSearchResults] = useState([]);

  const [loading, setLoading] = useState(false);
  const [searching, setSearching] = useState(false);

  const token = useSelector((state) => state.user.token);

  useEffect(() => {
    if (props.location.state?.items) {
      setItems(props.location.state.items);
    }
  });

  const onPressAdd = async () => {
    if (!query) {
      showToast('error', 'Please enter item name');
      return;
    }
    if (!price) {
      showToast('warn', 'Please enter valid amount');
      return;
    }
    if (price < 0) {
      showToast('warn', 'Please enter valid amount');
      return;
    }
    if (!selectedItem) {
      showToast('warn', 'Please select item name from search result');
      return;
    }
    const data = items;
    const obj = {
      // BasketId: props.location.state.items[0].basketId,
      BasketId: props.location.state.payload.id,
      ItemName: selectedItem,
      TentativePrice: price ? Number(price) : 0,
    };
    setLoading(true);
    try {
      const response = await addBasketItem(obj, token);
      if (response.successCode === 1) {
        setQuery('');
        setPrice('');
        setSelectedItem('');
        data.push({
          //BasketId: item.id,
          itemName: selectedItem,
          tentativePrice: price,
        });
        setItems(data);
        showToast('success', response.returnMessage);
        // history.push('/basketdetails');
      } else {
        showToast('error', response.returnMessage);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }

    // if (!query.trim()) {
    //   showToast('warn', 'Please search product');
    //   return;
    // }
    // if (!price.trim()) {
    //   showToast('warn', 'Please enter price');
    //   return;
    // }

    // const data = items;
    // const obj = {
    //   name: selectedItem,
    //   price,
    // };
    // setQuery('');
    // setPrice('');
    // data.push(obj);
    // setItems(data);
  };

  async function onChangeText(text) {
    setSearching(true);
    setQuery(text);
    if (text.length > 0) {
      setLoading(true);
      setSelectedItem(text);
      handler(text);
    } else {
      setSearchResults([]);
      setLoading(false);
      setSearching(false);
    }
  }

  const handler = useCallback(
    debounce(async (text) => {
      if (!text.trim()) {
        return;
      }
      try {
        const response = await searchItems(token, text);
        if (response.successCode === 1) {
          const data = response.response;
          if (data) {
            const obj = {
              description: text,
              isVendorItem: false,
              name: text,
              price: '',
            };
            data.push(obj);
            // setSelectedItem('');
            setSearchResults(data);
          } else {
            // setSelectedItem('');
            setSearchResults([]);
          }
          setSearching(false);
        } else {
          // const data = [];
          // const obj = {
          //   description: text,
          //   isVendorItem: false,
          //   name: text,
          // };
          // data.push(obj);
          // showToast('error', "No item's found");
          // setSearchResults(data);
        }
        setLoading(false);
        setSearching(false);
      } catch (error) {
        setLoading(false);
        setSearching(false);
      }
    }, 500),
    []
  );

  function removeItem(index) {
    const data = items;
    data.splice(index, 1);
    setItems(data);
    // forceUpdate();
  }

  const onChangeValue = (value) => {
    //   setQuery(value.name);
    setSelectedItem(value);
    setSearchResults([]);
  };

  return (
    <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
      <div className={classes.container}>
        <WebToolbar />
        <div className={classes.sideNav}>
          <SideNav />
        </div>
        <AppBarName title={'Add Items To Basket'} />
        <Box
          style={{
            display: 'flex',
            flexDirection: 'column',
            position: 'relative',
          }}
        >
          <Box m={2}>
            <Grid container direction={'column'} spacing={2}>
              <Grid item>
                <SimpleAutocomplete
                  value={selectedItem}
                  options={
                    searchResults
                      ? searchResults.map((option) => {
                          return lowerCase(option.name);
                        })
                      : ''
                  }
                  handleACOnChange={(value) => {
                    return onChangeValue(value);
                  }}
                  labelText="Search Items"
                  handleOnChange={onChangeText}
                  searching={searching}
                />
              </Grid>

              <Grid item>
                <TextField
                  fullWidth
                  id="outlined-basic"
                  label="Price"
                  size="small"
                  variant="outlined"
                  type="number"
                  value={price}
                  onChange={(txt) => {
                    if (txt.target.value.length <= 6) {
                      setPrice(txt.target.value);
                    }
                  }}
                />
              </Grid>
            </Grid>
            <Box
              my={2}
              style={{
                display: 'flex',
                justifyContent: 'space-around',
              }}
            >
              <Button
                variant="outlined"
                style={{width: '30%', borderRadius: '30px'}}
                onClick={() => {
                  onPressAdd();
                }}
              >
                Add
              </Button>
              <Button
                variant="outlined"
                style={{width: '30%', borderRadius: '30px'}}
                onClick={() => {
                  history.push('basketdetails');
                }}
              >
                Next
              </Button>
            </Box>
          </Box>
          <Box>
            {items.length > 0 ? (
              items.map((itm, index) => {
                return (
                  <Box key={index} px={1}>
                    <Grid container style={{marginTop: '10px'}}>
                      <Grid item xs={6}>
                        <Typography>
                          {`${index + 1}. `}
                          {get(itm, 'itemName', 'NA')}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={4}
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                        }}
                      >
                        <Typography>
                          ₹ {get(itm, 'tentativePrice', 'NA')}
                        </Typography>
                      </Grid>
                      {/* <Grid
                      item
                      xs={4}
                      style={{
                        display: 'flex',
                        justifyContent: 'end',
                      }}
                    >
                      <DeleteIcon
                        color="error"
                        style={{cursor: 'pointer'}}
                        onClick={() => {
                          removeItem(index);
                        }}
                      />
                    </Grid> */}
                    </Grid>
                    <Divider />
                  </Box>
                );
              })
            ) : (
              <Box boxShadow={2} m={2} p={2}>
                <Typography>No items added.</Typography>
              </Box>
            )}
          </Box>
        </Box>
      </div>
      <MobileFooter />
    </ErrorBoundary>
  );
};

export default AddItemsToBasket;
