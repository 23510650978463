import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import useStyles from './styles';
import WebToolbar from '../../components/toolbar/WebToolbar';
import {Box, Typography, Grid, Dialog, DialogContent} from '@material-ui/core';
import {s3BaseUrl} from '../../utils/Constants';
import defaultImg from '../../assets/images/default_user_image.png';
import {useDispatch, useSelector} from 'react-redux';
import {get} from 'lodash';
import AppBarName from '../../components/DumbComponents/AppBarName';
import SideNav from '../../components/sidenav/SideNav';
import getVendorDetails from '../../services/getVendorDetails';
import {setVendorDetails} from '../../redux/actions/user';
import ErrorBoundary from '../../components/ErrorBoundary';
import ErrorBoundaryFallback from '../../components/ErrorBoundaryFallback';
import NotFound from '../NotFound';
import MobileFooter from '../MobileFooter';
import {getAddress} from '../../utils/common';

const weekDays = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];

function VendorDetails() {
  const classes = useStyles();
  const [workingHours, setWorkingHours] = useState([]);
  const [workingDays, setWorkingDays] = useState([]);
  const [breakTime, setBreakTime] = useState('');
  const [loading, setLoading] = useState(false);
  const [noDataFound, setNoDataFound] = useState(false);
  const vendorDetails = useSelector((state) => state.user.vendorDetails);
  const token = useSelector((state) => state.user.token);
  const today = new Date().getDay();
  let {id} = useParams();
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const [imgToOpen, setImgToOpen] = useState();

  const handleClickOpen = (item) => {
    setOpen(true);
    setImgToOpen(item);
  };
  const handleClose = () => {
    setOpen(false);
  };

  useEffect(async () => {
    setLoading(true);
    try {
      const response = await getVendorDetails(token, id);
      if (response.successCode === 1) {
        let _vendorDetails = response.response;
        dispatch(setVendorDetails(response.response));
        setWorkingHours(
          _vendorDetails.workingHours
            ? _vendorDetails.workingHours.split(',')
            : []
        );
        setWorkingDays(
          _vendorDetails.workingDays
            ? _vendorDetails.workingDays.split(',')
            : []
        );
        setBreakTime(
          _vendorDetails.dailyBreakEndTime !== '' &&
            _vendorDetails.dailyBreakStartTime !== ''
            ? ` ${_vendorDetails.dailyBreakStartTime}-${_vendorDetails.dailyBreakEndTime}`
            : ''
        );
        setLoading(false);
        setNoDataFound(false);
      } else {
        // showToast('error', 'Failed to get vendor details');
        setNoDataFound(true);
        setLoading(false);
      }
    } catch (error) {
      console.log('error image', error);
      setLoading(false);
    }
  }, []);

  const getUserAddress = (customer) => {
    return (
      <Typography component={'span'} variant={'body1'}>
        <Box fontWeight="fontWeightMedium" display="inline">
          Address :
        </Box>
        {getAddress(customer, false) || 'Not available'}
      </Typography>
    );
  };

  return (
    <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
      <div className={classes.container}>
        <WebToolbar />
        <AppBarName title={'Vendor Details'} />
        <div className={classes.sideNav}>
          <SideNav />
        </div>
        {loading ? (
          <NotFound message="Loading..." />
        ) : noDataFound || !vendorDetails ? (
          <NotFound message="No vendor found" />
        ) : (
          <>
            <Box my={1} style={{display: 'flex', justifyContent: 'center'}}>
              {vendorDetails.vendorHeaderImage ? (
                <img
                  onClick={() => {
                    handleClickOpen(
                      get(vendorDetails, 'vendorHeaderImage', '')
                    );
                  }}
                  src={vendorDetails.vendorHeaderImage}
                  className={classes.media}
                />
              ) : (
                <img src={defaultImg} className={classes.media} />
              )}
            </Box>
            <Box
              boxShadow={1}
              style={{
                maxHeight: '210px',
                display: 'flex',
                overflowX: 'auto',
                justifyContent: 'center',
              }}
            >
              {vendorDetails.photos ? (
                vendorDetails.photos.map((item, index) => {
                  if (item.photoURL !== null && item.photoType === 1) {
                    return (
                      <img
                        onClick={() => {
                          handleClickOpen(get(item, 'photoURL', ''));
                        }}
                        key={index}
                        src={item.photoURL}
                        // src={`${s3BaseUrl}/${item.photoURL}`}
                        style={{
                          height: '190px',
                          border: '1px solid gray',
                          minWidth: '210px',
                          lineHeight: '110px',
                          textAlign: 'center',
                        }}
                      />
                    );
                  }
                })
              ) : (
                <Box p={1}>
                  <Typography
                    component={'span'}
                    variant={'body1'}
                    style={{fontWeight: '900'}}
                  >
                    No images Available
                  </Typography>
                </Box>
              )}
              <Dialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
              >
                <DialogContent dividers>
                  <img
                    src={imgToOpen}
                    style={{
                      height: '390px',
                      width: '310px',
                    }}
                  />
                </DialogContent>
              </Dialog>
            </Box>
            <Box
              style={{display: 'flex', flexDirection: 'column'}}
              boxShadow={1}
              p={1}
            >
              <Typography
                component={'span'}
                variant={'body1'}
                style={{fontWeight: '900', fontSize: '20px'}}
              >
                {vendorDetails.organizationName}
              </Typography>
              {getUserAddress(vendorDetails)}
              <Typography component={'span'} variant={'body1'}>
                <Box fontWeight="fontWeightMedium" display="inline">
                  Phone :
                </Box>
                {`${get(
                  vendorDetails,
                  'secondaryMobiles[0].secondaryMobile',
                  'Not Available'
                )}`}
              </Typography>
              {get(vendorDetails, 'secondaryMobiles[1].secondaryMobile') ? (
                <Typography component={'span'} variant={'body1'}>
                  <Box fontWeight="fontWeightMedium" display="inline">
                    Phone :
                  </Box>
                  {`${get(
                    vendorDetails,
                    'secondaryMobiles[1].secondaryMobile',
                    'Not Available'
                  )}`}
                </Typography>
              ) : (
                ''
              )}

              <Typography
                component={'span'}
                variant={'body1'}
                style={{fontWeight: '900'}}
              >
                Hours :{' '}
              </Typography>
              <Grid container>
                <Grid
                  xs={6}
                  item
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  {weekDays.map((item, index) => {
                    // eslint-disable-next-line react/jsx-key
                    return (
                      <Box key={index}>
                        <Typography>{`${weekDays[index]}: `}</Typography>
                      </Box>
                    );
                  })}
                </Grid>
                <Grid
                  xs={6}
                  item
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  {workingDays.map((item, index) => {
                    return (
                      <Box key={index}>
                        <Typography
                          className={
                            index === today ? classes.textGreen : classes.text
                          }
                        >
                          {`${item === '1' ? workingHours[index] : 'Closed'}`}
                        </Typography>
                      </Box>
                    );
                  })}
                </Grid>
              </Grid>
              <Box my={1}>
                {breakTime === ' 00:00-00:00' ? (
                  <Typography style={{fontWeight: '900'}}>
                    Break Time: No break time
                  </Typography>
                ) : (
                  <Typography
                    style={{fontWeight: '900'}}
                  >{`Break Time: ${breakTime}`}</Typography>
                )}
              </Box>
            </Box>
            <Box boxShadow={1} p={1}>
              <Typography style={{fontWeight: '900'}}>Catalogue : </Typography>
              {vendorDetails.vendorItems.length > 0 ? (
                vendorDetails.vendorItems.map((item, index) => {
                  return (
                    <Grid container key={index}>
                      <Grid
                        item
                        xs={6}
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <Typography>{item.name}</Typography>
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <Typography>₹ {item.price}</Typography>
                      </Grid>
                    </Grid>
                  );
                })
              ) : (
                <Box>
                  <Typography>No item found</Typography>
                </Box>
              )}
            </Box>
          </>
        )}
      </div>
      <MobileFooter />
    </ErrorBoundary>
  );
}

export default VendorDetails;
