import baseUrl from '../utils/Constants';

export default async function (token, basketId, groupId) {
  const response = await fetch(
    `${baseUrl}/usergroups/baskets/details?basketId=${basketId}&groupId=${groupId}`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    },
  );
  const responseJson = await response.json();
  return responseJson;
}
