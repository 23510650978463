import baseUrl from '../utils/Constants';

export default async function (formData, token) {
  console.log('payload', formData);
  // const response = await fetch(`${baseUrl}/advertisements/new`, {
  const response = await fetch(`${baseUrl}/advertisements/promotions/new`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    body: formData,
  });
  const responseJson = await response.json();
  return responseJson;
}
