import React from 'react';
import {Route, Switch, Redirect} from 'react-router-dom';
import Dashboard from '../pages/home';
import ForgotPassword from '../pages/ForgotPassword';
import NotFound from '../pages/NotFound';
import PrivacyPolicy from '../pages/PrivacyPolicy';
import TermsOfUse from '../pages/TermsOfUse';
import VendorsScreen from '../pages/VendorsScreen';
import VendorDetails from '../pages/VendorDetails';
import PlaceOrder from '../components/PlaceOrder';
import OrderSummary from '../components/OrderSummary';
import ReceivedOrders from '../pages/ReceivedOrders';
import OrdersScreen from '../pages/OrdersScreen';

import Notifications from '../pages/Notifications';
import MyCustomers from '../pages/MyCustomers';
import CustomerOrders from '../pages/CustomerOrders';
import OrderDetails from '../pages/OrderDetails';
import CustomerDetails from '../pages/CustomerDetails';
import SavedOrders from '../pages/SavedOrders';
import updateOrderStatus from '../pages/UpdateOrderStatus';
import Groups from '../pages/Groups';
import CreateGroup from '../pages/CreateGroup';
import GroupMessagesScreen from '../pages/GroupMessagesScreen';
import GroupInfo from '../pages/GroupInfo';
import AddMembersScreen from '../pages/AddMembersScreen';
import BasketsScreen from '../pages/BasketsScreen';
import CreateBasketsScreen from '../pages/CreateBasketScreen';
import AddBasketItems from '../pages/AddBasketItems';
import AddBasketVendors from '../pages/AddBasketVendors';
import BasketDetails from '../pages/BasketDetails';
import CreateBasketOrdersScreen from '../pages/CreateBasketOrdersScreen';
import AddItemsToBasket from '../pages/AddItemsToBasket';
import SubmitBasket from '../pages/SubmitBasket';
import PaymentRequestsScreen from '../pages/PaymentRequestsScreen';
import VendorPaymentRequests from '../pages/VendorPaymentRequests';
import CreatePaymentRequest from '../pages/CreatePaymentRequest';
import AddBillDetails from '../pages/AddBillDetails';
import SelectItemsScreen from '../pages/SelectItemsScreen';
import AddVendorShopDetails from '../pages/AddVendorShopDetails';
import AddHoursScreen from '../pages/AddHoursScreen';
import AddVendorShopImages from '../pages/AddVendorShopImages';
import VendorCatalogueScreen from '../pages/VendorCatalogueScreen';
import BuildCatalogueScreen from '../pages/BuildCatalogueScreen';
import AddItemsScreen from '../pages/AddItemsScreen';
import EditItemsScreen from '../pages/EditItemsScreen';
import FavouriteVendors from '../pages/FavouriteVendors';
import ResetPasswordScreen from '../pages/ResetPasswordScreen';
import ChangePasswordScreen from '../pages/ChangePasswordScreen';
import UpdateProfileScreen from '../pages/UpdateProfileScreen';
import EditVendorDetails from '../pages/EditVendorDetails';
import EditHoursScreen from '../pages/EditHoursScreen';
import EditVendorImages from '../pages/EditVendorImages';
import EditDeliveryOptionsScreen from '../pages/EditDeliveryOptionsScreen';
import AddDeliveryPartnerScreen from '../pages/AddDeliveryPartnerScreen';
import ContactUs from '../pages/ContactUs';
import FAQ from '../pages/FAQ';
import Feedback from '../pages/Feedback';
import Advertisement from '../pages/Advertisement';
import CreateAdvertisement from '../pages/CreateAdvertisement';
import AdvertisementDetails from '../pages/Advertisements/AdvertisementDetails';
import AdvertisementPreview from '../pages/Advertisements/AdvertisementPreview';
import VendorAppointmentsScreen from '../pages/VendorAppointmentsScreen';
import SignUp from '../pages/SignUp';
import Login from '../pages/Login';
import AboutScreen from '../pages/AboutScreen';
import LinkToNewPage from '../pages/LinkToNewPage';
import AdvertisementLocationSelection from '../pages/AdvertisementLocationSelection';
import AdvertisementPricingDetails from '../pages/AdvertisementPricingDetails';
import AdvertisementConfirmation from '../pages/AdvertisementConfirmation';
import AdvertisementDetailsScreen from '../pages/AdvertisementDetailsScreen';
import ViewAppointmentsScreen from '../pages/VIewAppointmentsScreen';
import EditDetailsHome from '../pages/EditDetailsHome';
import PlaceBasketOrderScreen from '../pages/PlaceBasketOrderScreen';
import DemoPage from '../pages/DemoPage';
import MyOrdersScreen from '../pages/MyOrdersScreen';
import VendorOrdersScreen from '../pages/VendorOrdersScreen';
import AddUPIScreen from '../pages/AddUPIScreen';
import ViewUPIsScreen from '../pages/ViewUPIsScreen';
import OrderPaymentScreen from '../pages/OrderPaymentScreen';
import OrderPlaced from '../pages/OrderPlaced';
import OrderReview from '../pages/OrderReviewScreen';
import DeliverOrderScreen from '../pages/DeliverOrderScreen';
import PromotionalAdvertisement from '../pages/Advertisements/PromotionalAdvertisement';
import CatalougeAddItemsScreen from '../pages/BuildCatalogueScreen/CatalogueAddItemsScreen';

const PrivateRoutes = () => {
  return (
    <Switch>
      <Route exact path="/" component={Dashboard} />
      <Route exact path="/login/resetpassword" component={ForgotPassword} />
      <Route
        exact
        path="/resetpasswordscreen"
        component={ResetPasswordScreen}
      />
      <Route exact path="/home" component={Dashboard} />
      <Route path="/vendors/:id" component={VendorsScreen} />
      <Route path="/vendordetails/:id" component={VendorDetails} />
      <Route path="/placeorder" component={PlaceOrder} />
      <Route path="/ordersummary" component={OrderSummary} />
      <Route exact path="/privacy-policy" component={PrivacyPolicy} />
      <Route exact path="/terms-of-use" component={TermsOfUse} />
      <Route path="/404" component={NotFound} />
      <Route exact path="/receivedorders" component={ReceivedOrders} />
      <Route exact path="/myordersorders" component={MyOrdersScreen} />
      <Route exact path="/vendororders" component={VendorOrdersScreen} />
      <Route exact path="/ordersscreen" component={OrdersScreen} />
      <Route exact path="/notifications" component={Notifications} />
      <Route exact path="/mycustomers" component={MyCustomers} />
      <Route exact path="/customerorders" component={CustomerOrders} />
      <Route exact path="/orderdetails" component={OrderDetails} />
      <Route exact path="/customerdetails" component={CustomerDetails} />
      <Route exact path="/savedorders" component={SavedOrders} />
      <Route exact path="/updateorderstatus" component={updateOrderStatus} />
      <Route exact path="/groups" component={Groups} />
      <Route exact path="/creategroup" component={CreateGroup} />
      <Route
        exact
        path="/groupmessagesscreen"
        component={GroupMessagesScreen}
      />
      <Route exact path="/addmembersscreen" component={AddMembersScreen} />
      <Route exact path="/groupinfo" component={GroupInfo} />
      <Route exact path="/basketsscreen" component={BasketsScreen} />
      <Route exact path="/basketdetails" component={BasketDetails} />
      <Route exact path="/createbasketscreen" component={CreateBasketsScreen} />
      <Route
        exact
        path="/createbasketordersscreen"
        component={CreateBasketOrdersScreen}
      />
      <Route exact path="/addbasketitems" component={AddBasketItems} />
      <Route exact path="/addbasketvendors" component={AddBasketVendors} />
      <Route exact path="/additemstobasket" component={AddItemsToBasket} />
      <Route exact path="/submitbasket" component={SubmitBasket} />
      <Route
        exact
        path="/paymentrequestsscreen"
        component={PaymentRequestsScreen}
      />
      <Route
        exact
        path="/vendorpaymentrequests"
        component={VendorPaymentRequests}
      />
      <Route
        exact
        path="/createpaymentrequest"
        component={CreatePaymentRequest}
      />
      <Route exact path="/addbilldetails" component={AddBillDetails} />
      <Route exact path="/selectitemsscreen" component={SelectItemsScreen} />
      <Route
        exact
        path="/addvendorshopdetails"
        component={AddVendorShopDetails}
      />
      <Route exact path="/addhoursscreen" component={AddHoursScreen} />
      <Route
        exact
        path="/addvendorshopimages"
        component={AddVendorShopImages}
      />
      <Route exact path="/buildcatalouge" component={VendorCatalogueScreen} />
      <Route
        exact
        path="/buildcatalougescreen"
        component={BuildCatalogueScreen}
      />
      <Route
        exact
        path="/catalougeAddItems"
        component={CatalougeAddItemsScreen}
      />
      <Route exact path="/additemsscreen" component={AddItemsScreen} />
      <Route exact path="/edititemsscreen" component={EditItemsScreen} />
      <Route exact path="/favouritevendors" component={FavouriteVendors} />

      <Route
        exact
        path="/changepasswordscreen"
        component={ChangePasswordScreen}
      />
      <Route
        exact
        path="/updateprofilescreen"
        component={UpdateProfileScreen}
      />
      <Route exact path="/editvendordetails" component={EditVendorDetails} />
      <Route exact path="/edithoursscreen" component={EditHoursScreen} />
      <Route exact path="/editvendorimages" component={EditVendorImages} />
      <Route
        exact
        path="/editdeliveryoptionsscreen"
        component={EditDeliveryOptionsScreen}
      />
      <Route exact path="/editdetailshome" component={EditDetailsHome} />
      <Route
        exact
        path="/adddeliverypartnerscreen"
        component={AddDeliveryPartnerScreen}
      />
      <Route exact path="/contactUs" component={ContactUs} />
      <Route exact path="/faq" component={FAQ} />
      <Route exact path="/feedback" component={Feedback} />
      <Route exact path="/advertisement" component={Advertisement} />
      <Route
        exact
        path="/createadvertisement"
        component={PromotionalAdvertisement}
      />
      <Route
        exact
        path="/advertisementdetails"
        component={AdvertisementDetails}
      />
      <Route
        exact
        path="/advertisementdetails/:id"
        component={AdvertisementDetails}
      />
      <Route
        exact
        path="/advertisementpreview"
        component={AdvertisementPreview}
      />
      <Route
        exact
        path="/vendorappointmentsscreen"
        component={VendorAppointmentsScreen}
      />
      <Route exact path="/aboutscreen" component={AboutScreen} />
      <Route exact path="/linktonewpage" component={LinkToNewPage} />
      <Route
        exact
        path="/advertisementlocationselection"
        component={AdvertisementLocationSelection}
      />
      <Route
        exact
        path="/advertisementpricingdetails"
        component={AdvertisementPricingDetails}
      />
      <Route
        exact
        path="/advertisementconfirmation"
        component={AdvertisementConfirmation}
      />
      <Route
        exact
        path="/advertisementdetailsscreen"
        component={AdvertisementDetailsScreen}
      />
      <Route
        exact
        path="/viewappointmentsscreen"
        component={ViewAppointmentsScreen}
      />
      <Route
        exact
        path="/placebasketorderscreen"
        component={PlaceBasketOrderScreen}
      />
      <Route exact path="/addupiscreen" component={AddUPIScreen} />
      <Route exact path="/viewupiscreen" component={ViewUPIsScreen} />
      <Route exact path="/orderpaymentscreen" component={OrderPaymentScreen} />
      <Route exact path="/orderplaced" component={OrderPlaced} />

      <Route exact path="/orderreview" component={OrderReview} />
      <Route exact path="/deliverorder" component={DeliverOrderScreen} />

      <Route exact path="/demopage" component={DemoPage} />

      <Redirect to="/" />

      <Redirect to="/404" />
    </Switch>
  );
};
export default PrivateRoutes;
