import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slide,
} from '@material-ui/core';
import React, {useCallback, useRef} from 'react';
import Webcam from 'react-webcam';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/styles';

const useStyles = makeStyles(() => ({
  webcamWrapper: {
    ['@media (max-width:600px)']: {
      height: '40vh',
      width: '55vw',
    },
    display: 'flex',
    flexDirection: 'column',
    height: '400px',
    minWidth: '26vw',
  },
  webcam: {
    height: '100%',
    width: '100%',
  },
}));

const videoConstraints = {
  width: 200,
  height: 200,
  facingMode: 'user',
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const CameraDialog = ({open, onSimpleDialogClose, camera}) => {
  const webcamRef = useRef(false);
  const classes = useStyles();

  const dataURLtoFile = (dataurl) => {
    const arr = dataurl.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];

    const filename =
      Math.round(+new Date() / 1000) + '.' + mime.split('/').pop();

    const bstr = window.atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n) {
      u8arr[n - 1] = bstr.charCodeAt(n - 1);
      n -= 1; // to make eslint happy
    }
    return new File([u8arr], filename, {type: mime});
  };

  const capture = useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    if (imageSrc) {
      const capturedImage = dataURLtoFile(imageSrc);
      onSimpleDialogClose(capturedImage);
    }
  }, [webcamRef]);

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={() => onSimpleDialogClose(false)}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle id="alert-dialog-slide-title">Take Photo</DialogTitle>
      <DialogContent>
        <Box className={classes.webcamWrapper}>
          {camera && (
            <Webcam
              audio={false}
              className={classes.webcam}
              ref={webcamRef}
              screenshotFormat="image/jpeg"
              width="auto"
              videoConstraints={videoConstraints}
            />
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={capture}>
          Capture
        </Button>
        <Button variant="outlined" onClick={() => onSimpleDialogClose(false)}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

CameraDialog.propTypes = {
  open: PropTypes.bool,
  onSimpleDialogClose: PropTypes.func,
  camera: PropTypes.bool,
};

export default CameraDialog;
