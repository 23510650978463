import {makeStyles} from '@material-ui/core/styles';
const useStyles = makeStyles((theme) => ({
  media: {
    width: '100%',
    aspectRatio: 1.8,
  },
  title: {
    // border: '1px solid red',
    display: 'flex',
    alignItems: 'center',
    fontSize: 20,
    fontWeight: 500,
    height: '30%',
    position: 'absolute',
    // bottom: 0,
    backgroundColor: 'rgb(0, 0, 0, 0.3)',
    width: '100%',
    color: '#fff',
    padding: 4,
  },
  root: {
    marginTop: theme.spacing(1),
  },
  card: {
    alignItems: 'center',
    padding: 0,
    justifyContent: 'center',
    position: 'relative',
    aspectRatio: 1.8,
  },
}));

export default useStyles;
