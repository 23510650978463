export const rules = {
  required: {value: true, message: 'Please fill organization name'},
  maxLength: {
    value: 30,
    message: 'Limit exceed',
  },
};

export const Address1Rules = {
  required: {value: true, message: 'Address1, lane,etc required'},
};
export const Address2Rules = {
  required: {value: true, message: 'Address2, office name/No,etc required'},
};

export const pincodeRules = {
  required: {value: true, message: 'Pincode required'},
  minLength: {
    value: 6,
    message: 'Minimum 6 characters required',
  },
  maxLength: {
    value: 6,
    message: 'Invalid pincode',
  },
};
