/* Copyright (C) 2020 Gearten Technologies Private Limited - All Rights Reserved
 NOTICE:  All information contained herein is, and remains
 * the property of Gearten Technologies Private Limited
 * The intellectual and technical concepts contained
 * herein are proprietary to Gearten Technologies Private Limited.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Gearten Technologies Private Limited.
 */
import {
  SET_USER,
  SET_IS_LOGGEDIN,
  SET_IS_TRUE,
  SET_TOKEN,
  SET_SAVED_ITEMS,
  SET_PROFILE_PIC,
  SET_UNREAD_NOTIFICATIONS,
  SET_VENDOR_ID,
  SET_TERMS_ACCEPTED,
  SET_PINCODE,
  SET_ADVERTISEMENT,
  SET_VENDOR_TYPES,
  SET_NEARBY_VENDORS,
  SET_FAVOURITE_VENDORS,
  SET_SHOW_PINCODE_MODAL,
  SET_VENDORS,
  SET_EMAIL,
  SET_PASSWORD,
  SET_SELECTED_VENDOR_FOR_ORDER,
  SET_REPEAT_ORDER_CHECKED,
  SET_HOME_DELIVERY_CHECKED,
  SET_SELECTED_CART_ITEMS,
  NAME,
  SET_NOTIFICATION_DATA,
  SET_RECEIVED_ORDERS,
  SET_PLACED_ORDERS,
  SET_LANGUAGE,
  SET_VENDOR_DETAILS,
  SET_ORDER_ID,
  SET_CART_ORDER_ID,
  SET_GROUP_ID,
  SET_GROUP_DETAILS,
  SET_BASKET_DETAILS,
  SET_ITEMS_LIST_DATA,
  SET_BILL_DETAILS,
  SET_CATALOUGE_MODAL_COUNT,
  SET_REFRESH_FAV,
  SET_VENDOR_SHOP_DEATAILS,
  SET_TYPE_OF_ADVERTISEMENT,
  SET_NEW_ADVERTISEMENT_DETAILS,
  SET_UPI_IDS,
  SET_UPDATE_ORDER_DETAILS,
  SET_PLACE_BASKET_ORDER,
  SET_SELECT_ITEMS_DATA,
} from './ActionTypes';

export const setTermsAccepted = (payload) => ({
  type: SET_TERMS_ACCEPTED,
  payload,
});

export const setPincode = (payload) => ({
  type: SET_PINCODE,
  payload,
});

export const setUser = (payload) => ({
  type: SET_USER,
  payload,
});

export const setLoggedIn = (payload) => ({
  type: SET_IS_LOGGEDIN,
  payload,
});

export const setIsTrue = (payload) => ({
  type: SET_IS_TRUE,
  payload,
});

export const setToken = (payload) => ({
  type: SET_TOKEN,
  payload,
});

export const setSavedItems = (payload) => ({
  type: SET_SAVED_ITEMS,
  payload,
});

export const setUnreadNotifications = (payload) => ({
  type: SET_UNREAD_NOTIFICATIONS,
  payload,
});

export const setVendorId = (payload) => ({
  type: SET_VENDOR_ID,
  payload,
});

export const setProfilePic = (payload) => ({
  type: SET_PROFILE_PIC,
  payload,
});

//-----------------------------------------------------------------------

export const setAdvertisement = (payload) => ({
  type: SET_ADVERTISEMENT,
  payload,
});

export const setVendorTypes = (payload) => ({
  type: SET_VENDOR_TYPES,
  payload,
});

export const setNearByVendors = (payload) => ({
  type: SET_NEARBY_VENDORS,
  payload,
});

export const setFavouriteVendors = (payload) => ({
  type: SET_FAVOURITE_VENDORS,
  payload,
});

export const setShowPincodeModal = (payload) => ({
  type: SET_SHOW_PINCODE_MODAL,
  payload,
});

export const setVendors = (payload) => ({
  type: SET_VENDORS,
  payload,
});

export const setEmail = (payload) => ({
  type: SET_EMAIL,
  payload,
});

export const setPassword = (payload) => ({
  type: SET_PASSWORD,
  payload,
});

export const setSelectedVendorForOrder = (payload) => ({
  type: SET_SELECTED_VENDOR_FOR_ORDER,
  payload,
});

export const setRepeatOrderChecked = (payload) => ({
  type: SET_REPEAT_ORDER_CHECKED,
  payload,
});

export const setHomeDeliveryChecked = (payload) => ({
  type: SET_HOME_DELIVERY_CHECKED,
  payload,
});

export const setSelectedCartItems = (payload) => ({
  type: SET_SELECTED_CART_ITEMS,
  payload,
});

export const setName = (payload) => ({
  type: NAME,
  payload,
});

export const setReceivedOrders = (payload) => ({
  type: SET_RECEIVED_ORDERS,
  payload,
});
export const setPlacedOrders = (payload) => ({
  type: SET_PLACED_ORDERS,
  payload,
});

export const setNotificationData = (payload) => ({
  type: SET_NOTIFICATION_DATA,
  payload,
});

export const setSelectedLanguage = (payload) => ({
  type: SET_LANGUAGE,
  payload,
});

export const setVendorDetails = (payload) => ({
  type: SET_VENDOR_DETAILS,
  payload,
});

export const setOrderId = (payload) => ({
  type: SET_ORDER_ID,
  payload,
});

export const setCartOrderId = (payload) => ({
  type: SET_CART_ORDER_ID,
  payload,
});

export const setGroupId = (payload) => ({
  type: SET_GROUP_ID,
  payload,
});

export const setGroupDetails = (payload) => ({
  type: SET_GROUP_DETAILS,
  payload,
});

export const setBasketDetails = (payload) => ({
  type: SET_BASKET_DETAILS,
  payload,
});

export const setItemsListData = (payload) => ({
  type: SET_ITEMS_LIST_DATA,
  payload,
});
export const setBillDetails = (payload) => ({
  type: SET_BILL_DETAILS,
  payload,
});

export const setCatalougeModalCount = (payload) => ({
  type: SET_CATALOUGE_MODAL_COUNT,
  payload,
});

export const setRefreshFav = (payload) => ({
  type: SET_REFRESH_FAV,
  payload,
});

export const setVendorShopDetails = (payload) => ({
  type: SET_VENDOR_SHOP_DEATAILS,
  payload,
});

export const setTypeOfAdvertisement = (payload) => ({
  type: SET_TYPE_OF_ADVERTISEMENT,
  payload,
});

export const setNewAdvertisementDetails = (payload) => ({
  type: SET_NEW_ADVERTISEMENT_DETAILS,
  payload,
});

export const setUpiIds = (payload) => ({
  type: SET_UPI_IDS,
  payload,
});

export const setUpdateOrderDetails = (payload) => ({
  type: SET_UPDATE_ORDER_DETAILS,
  payload,
});

export const setPlaceBasketOrders = (payload) => ({
  type: SET_PLACE_BASKET_ORDER,
  payload,
});

export const setSelectItemsData = (payload) => ({
  type: SET_SELECT_ITEMS_DATA,
  payload,
});
