/* eslint-disable react/prop-types */
import React, {useEffect, useState} from 'react';
import useStyle from './styles';
import {
  Box,
  Typography,
  Button,
  InputLabel,
  OutlinedInput,
  FormControl,
  InputAdornment,
} from '@material-ui/core';
import moment from 'moment';
import WebToolbar from '../../components/toolbar/WebToolbar';
import {useHistory} from 'react-router';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import confirmOrder from '../../services/confirmOrder';
import updateOrderStatus from '../../services/updateOrderStatus';
import showToast from '../../components/Toast';
import SideNav from '../../components/sidenav/SideNav';
import AppBarName from '../../components/DumbComponents/AppBarName';
import ErrorBoundary from '../../components/ErrorBoundary';
import ErrorBoundaryFallback from '../../components/ErrorBoundaryFallback';
import {get} from 'lodash';
import MobileFooter from '../MobileFooter';

const UpdateOrderStatus = () => {
  const classes = useStyle();
  const history = useHistory();

  const {t} = useTranslation(['orderDetails', 'common']);
  const token = useSelector((state) => state.user.token);
  const updateOrderDetails = useSelector((state) => {
    console.log('updateOrderDetails', state.user.updateOrderDetails);
    return state.user.updateOrderDetails;
  });
  const [values, setValues] = useState({
    amount: '',
  });
  const [isDatePickerVisible, setDatePickerVisibility] = useState(false);
  const [orderAmt, setOrderAmt] = useState(
    updateOrderDetails.total ? `${updateOrderDetails.total}` : ''
  );
  const [orderToken, setOrderToken] = useState('');
  const [appointmentDate, setAppointmentDate] = useState('');
  const [fromTime, setFromTime] = useState('00:00');
  const [toTime, setToTime] = useState('00:00');
  const [comments, setComments] = useState('');
  const [loading, setLoading] = useState(false);
  const [modalType, setModalType] = useState('');
  const [dateType, setDateType] = useState('');

  useEffect(() => {}, []);

  async function onButtonPress() {
    var payload = {
      orderId: updateOrderDetails.orderId,
      OrderStatus: updateOrderDetails.item.nextState,
    };
    if (updateOrderDetails.inputControls.includes('OrderAmount')) {
      console.log('orderAmt', !orderAmt);
      if (!orderAmt) {
        showToast('warn', 'Please enter order amount');
        return;
      }
      payload.OrderAmount = Number(orderAmt);
    }
    if (updateOrderDetails.inputControls.includes('ServiceToken')) {
      if (!orderAmt) {
        showToast('warn', 'Please enter service token');
        return;
      }
      payload.Token = token;
    }
    if (updateOrderDetails.inputControls.includes('ServiceDate')) {
      if (!orderAmt) {
        showToast('warn', 'Please select service date');
        return;
      }
      payload.AppointmentDate = appointmentDate
        ? moment(new Date(appointmentDate)).format('YYYY-MM-DD')
        : '';
    }
    if (updateOrderDetails.inputControls.includes('TimeSlot')) {
      if (!orderAmt) {
        showToast('warn', 'Please select timeslot');
        return;
      }
      payload.TimeSlot = `${fromTime}-${toTime}`;
    }
    if (updateOrderDetails.inputControls.includes('Comment')) {
      if (!orderAmt) {
        showToast('warn', 'Please enter comment');
        return;
      }
      payload.Comment = comments;
    }
    try {
      // dispatch(setLoading(true));
      const response =
        updateOrderDetails.item.nextState === 101
          ? await confirmOrder(
              {
                OrderID: updateOrderDetails.orderId,
                VendorId: updateOrderDetails.res.vendorId,
                CustomerId: updateOrderDetails.res.userId,
                OrderAmount: parseFloat(orderAmt),
                UserId: updateOrderDetails.res.userId,
                Comment: '',
              },
              token
            )
          : await updateOrderStatus(payload, token);
      // dispatch(setLoading(false));
      console.log('response confirmOrder or updateOrderStatus', response);
      if (response.successCode === 1) {
        showToast('success', 'Order updated');
        // navigation.pop(2);
        history.push('receivedorders');
        // getData();
      } else {
        showToast('warn', response.returnMessage);
      }
    } catch (error) {
      showToast('warn', 'Failed');
      console.log(error);
      /* dispatch(setLoading(false)); */
    }
  }

  return (
    <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
      <div className={classes.container}>
        <WebToolbar />
        <div className={classes.sideNav}>
          <SideNav />
        </div>
        <AppBarName title={'Update Order Status'} />
        <Box
          // boxShadow={2}
          my={1}
          style={{padding: '10px', display: 'flex', flexDirection: 'column'}}
        >
          {(get(updateOrderDetails, 'inputControls', []) || []).includes(
            'OrderAmount'
          ) && (
            <>
              <Box>
                {!updateOrderDetails.isAllPrice && (
                  <Typography
                    style={{fontSize: 16}}
                    className={classes.ordersText}
                  >
                    This order contails some items for which price is not
                    listed, please enter total by entering price of those items.
                  </Typography>
                )}
                {updateOrderDetails.total > 0 ? (
                  <Box my={1}>
                    <Typography
                      onPress={() => setOrderAmt(`${updateOrderDetails.total}`)}
                      className={classes.ordersText}
                    >{`Sub-Total: ₹ ${updateOrderDetails.total}`}</Typography>
                  </Box>
                ) : (
                  <Box my={1}>
                    <Typography className={classes.ordersText}>
                      {'Sub-Total: '}
                    </Typography>
                    <Typography className={classes.ordersText}>
                      Unknown
                    </Typography>
                  </Box>
                )}
              </Box>
              <Box>
                <Box py={1}>
                  <Typography>Please enter total amount - </Typography>
                </Box>
                <FormControl variant="outlined" size="small">
                  <InputLabel htmlFor="outlined-adornment-amount">
                    Order Amount
                  </InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-amount"
                    value={orderAmt}
                    onChange={(e) => {
                      setOrderAmt(e.target.value);
                    }}
                    labelWidth={105}
                    style={{backgroundColor: 'transparent'}}
                  />
                </FormControl>
                <Box py={1}>
                  <Button
                    size="small"
                    variant="outlined"
                    onClick={() => {
                      onButtonPress();
                    }}
                  >
                    {t(updateOrderDetails.item.nextAction)}
                  </Button>
                </Box>
              </Box>
            </>
          )}
          {updateOrderDetails.inputControls.includes('ServiceToken') && (
            <FormControl className={classes.margin} variant="outlined">
              <InputLabel htmlFor="outlined-adornment-amount">Token</InputLabel>
              <OutlinedInput
                id="outlined-adornment-amount"
                value={orderToken}
                onChange={(text) => setOrderToken(text)}
                startAdornment={
                  <InputAdornment position="start">Qty</InputAdornment>
                }
                labelWidth={105}
                style={{backgroundColor: 'transparent'}}
              />
            </FormControl>
          )}
          {updateOrderDetails.inputControls.includes('ServiceDate') && (
            <Box>
              <Typography className={classes.textLebel}>
                Appointment date
              </Typography>

              <Box
                className={classes.btnDate}
                onPress={() => selectDate('date', 'date')}
              >
                <Typography className={classes.textDate}>
                  {appointmentDate
                    ? new Date(appointmentDate).toDateString()
                    : 'Select appointment date'}
                </Typography>
              </Box>
            </Box>
          )}

          {updateOrderDetails.inputControls.includes('TimeSlot') && (
            <Box>
              <Typography className={classes.textLebel}>Time slot</Typography>
              <Box className={classes.containerTime}>
                <Typography
                  className={classes.textTime}
                  onPress={() => selectDate('time', 'fromTime')}
                >
                  {fromTime}
                </Typography>
                <Typography>-</Typography>
                <Typography
                  className={classes.textTime}
                  onPress={() => selectDate('time', 'toTime')}
                >
                  {toTime}
                </Typography>
              </Box>
            </Box>
          )}

          {updateOrderDetails.inputControls.includes('Comment') && (
            <FormControl
              fullWidth
              className={classes.margin}
              variant="outlined"
            >
              <InputLabel htmlFor="outlined-adornment-amount">
                Comments
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-amount"
                value={comments}
                onChange={(text) => setComments(text)}
                startAdornment={
                  <InputAdornment position="start">Qty</InputAdornment>
                }
                labelWidth={105}
                style={{backgroundColor: 'transparent'}}
              />
            </FormControl>
          )}
        </Box>
      </div>
      <MobileFooter />
    </ErrorBoundary>
  );
};

export default UpdateOrderStatus;
