import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {useHistory} from 'react-router';
import showToast from '../../components/Toast';
import getCurrentVendor from '../../services/getCurrentVendor';
import useStyle from './style';
import ActivityIndicator from '../../components/ActivityIndicator';
import Colors from '../../theme/Colors';
import {Box, Button, Typography} from '@material-ui/core';
import ImageDialog from '../../components/DumbComponents/ImageDialog';
import deleteImage from '../../services/deleteImage';
import addVendorImages from '../../services/addVendorImages';
import {get} from 'lodash';
import ErrorBoundary from '../../components/ErrorBoundary';
import ErrorBoundaryFallback from '../../components/ErrorBoundaryFallback';

const EditVendorImages = () => {
  const {t} = useTranslation('becomeVendor');
  const classes = useStyle();
  const history = useHistory();
  const user = useSelector((state) => state.user.user);
  const token = useSelector((state) => state.user.token);
  const [images, setImages] = useState([]);
  //const {vendorDetails} = route.params;
  // console.log(vendorDetails.photos);
  const [headerImage, setHeaderImage] = useState({
    photoURL: '',
  });
  const [loading, setLoading] = useState(false);
  const [requesting, setRequesting] = useState(false);
  const forceUpdate = useState()[1].bind(null, {});
  const [photos, setPhotos] = useState([]);
  const [initialLoading, setInitialLoading] = useState(true);
  const [vendorId, setVendorId] = useState('');
  const [openDialog, setOpenDialog] = useState(false);
  const [cameraDialog, setCameraDialog] = useState(false);
  const [selectedImageSection, setSelectedImageSection] = useState({
    isHeader: true,
    isImages: false,
  });

  useEffect(() => {
    getData();
  }, []);

  const closeDialogFunction = () => {
    setOpenDialog(false);
  };

  const handleListItemClick = (val) => {
    if (selectedImageSection.isHeader && get(val, 'name', false)) {
      setHeaderImage({
        photo: val,
      });
    } else if (selectedImageSection.isImages && get(val, 'name', false)) {
      let newArr = [...images];
      newArr.push(val);
      setImages(newArr);
    }
    setOpenDialog(false);
    setCameraDialog(false);
  };

  async function getData() {
    try {
      setInitialLoading(true);
      const response = await getCurrentVendor(token);
      if (response.successCode === 1) {
        const img = [];
        const {vendorHeaderImage} = response.response;
        setVendorId(response.response.vendorId);
        if (vendorHeaderImage) {
          setHeaderImage({
            photoURL: vendorHeaderImage,
          });
        }
        if (response.response.photos) {
          response.response.photos.forEach((i) => {
            if (i.photoURL !== null && i.photoType === 1) {
              img.push(i);
            }
          });
        }
        setImages(img);
        setInitialLoading(false);
      } else {
        // navigation.goBack();
        history.push('/home');
        showToast('error', response[0].returnMessage);
        setInitialLoading(false);
      }
    } catch (error) {
      //   navigation.goBack();
      history.push('/home');
      showToast('error', 'Error');
      setInitialLoading(false);
    }
  }

  async function setHeader() {
    setSelectedImageSection({
      ...selectedImageSection,
      isHeader: true,
      isImages: false,
    });
    setOpenDialog(!openDialog);
  }

  async function removeImg(item, index) {
    if (item.photoURL) {
      try {
        setRequesting(true);
        const response = await deleteImage(token, item.photoId, vendorId);
        setRequesting(false);
        if (response.successCode === 1) {
          const data = images;
          if (index !== null) {
            data.splice(index, 1);
            setImages(data);
          } else {
            setHeaderImage({
              photoURL: '',
            });
          }
          forceUpdate();
          showToast('success', 'Removed');
        } else {
          showToast('error', 'Failed');
        }
      } catch (error) {
        setRequesting(false);
        showToast('error', 'Failed');
        console.log(error);
      }
    } else {
      const data = images;
      data.splice(index, 1);
      setImages(data);
      forceUpdate();
    }
  }

  const onPressImage = (e) => {
    setSelectedImageSection({
      ...selectedImageSection,
      isHeader: false,
      isImages: true,
    });
    setOpenDialog(!openDialog);
  };
  async function onPressNext(e) {
    e.preventDefault();
    setLoading(true);
    uploadImages();
  }

  async function uploadImages() {
    if (headerImage || images.length > 0) {
      const payload = new FormData();
      if (headerImage.photo) {
        payload.append(
          'headerImage',
          headerImage.photo,
          URL.createObjectURL(headerImage.photo)
        );
      }
      images.forEach((element) => {
        if (element.name) {
          payload.append('photos', element, URL.createObjectURL(element));
        }
      });
      try {
        const response = await addVendorImages(payload, token);
        if (response.successCode === 1) {
          showToast('success', 'Saved');
          setLoading(false);
          history.push('/editdetailshome');
          // navigation.dispatch(
          //   CommonActions.reset({
          //     index: 1,
          //     routes: [{name: 'Dashboard'}],
          //   })
          // );
        } else {
          showToast('error', t(response.returnMessage));
          setLoading(false);
        }
      } catch (error) {
        console.log('errorr', error);
        setLoading(false);
      }
    } else {
      console.log('errorr else');
      setLoading(false);
      // history.push('/home');
      // navigation.dispatch(
      //   CommonActions.reset({
      //     index: 1,
      //     routes: [{name: 'Dashboard'}],
      //   })
      // );
    }
  }

  const getHeaderImagePath = () => {
    try {
      if (headerImage.photoURL && headerImage.photoURL.length)
        return headerImage.photoURL;
      else return URL.createObjectURL(headerImage.photo);
    } catch (err) {
      console.error('eeror', err);
    }
  };

  const isImagePresent = () => {
    if (headerImage.photoURL && get(headerImage, 'photoURL').length) {
      return true;
    }
    if (get(headerImage, 'photo.name')) {
      return true;
    }

    return false;
  };
  return (
    <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
      <Box>
        <Box py={1}>
          {initialLoading ? (
            <ActivityIndicator
              style={{height: '100%'}}
              size="large"
              color={Colors.secondary}
            />
          ) : (
            <Box className={classes.container}>
              <Box>
                <Typography style={{fontWeight: 900}}>
                  Header Image :{' '}
                </Typography>
                <Box className={classes.containerHeaderImg}>
                  {isImagePresent() ? (
                    <Box className={classes.headerImageContainer}>
                      <img
                        onClick={() => {
                          setHeader();
                        }}
                        className={classes.imageHeader}
                        src={getHeaderImagePath()}
                      />
                    </Box>
                  ) : (
                    <Box
                      onClick={() => {
                        setHeader();
                      }}
                    >
                      <Box className={classes.placeholder} />
                    </Box>
                  )}
                </Box>
                <ImageDialog
                  open={openDialog}
                  setOpen={setOpenDialog}
                  cameraDialog={cameraDialog}
                  setCameraDialog={setCameraDialog}
                  onSimpleDialogClose={closeDialogFunction}
                  message="Pick Image From?"
                  handleListItemClick={handleListItemClick}
                  closeShowCameraDialogFunction={handleListItemClick}
                />
                <Typography style={{fontWeight: 900}}>Images : </Typography>
                <Box className={classes.imgListContainer}>
                  {images.length > 0 ? (
                    images.map((item, index) => {
                      return (
                        <Box key={index} className={classes.imgContainerOne}>
                          <img
                            className={classes.img}
                            src={
                              item.photoURL
                                ? item.photoURL
                                : URL.createObjectURL(item)
                            }
                          />

                          <Box
                            className={classes.addRemoveBtnontainer}
                            onClick={() => removeImg(item, index)}
                          >
                            <Typography
                              style={{
                                color: 'white',
                                fontSize: 18,
                                textAlign: 'center',
                              }}
                              // className={classes.text}
                            >
                              X
                            </Typography>
                          </Box>
                        </Box>
                      );
                    })
                  ) : (
                    <Box></Box>
                  )}
                  <Box className={classes.imgContainer}>
                    {'' ? (
                      <Box
                        onClick={() => {
                          onPressImage();
                        }}
                      >
                        <img style={{height: 200, width: 300}} src={''} />
                      </Box>
                    ) : (
                      <Box
                        onClick={() => {
                          onPressImage();
                        }}
                      >
                        <Box className={classes.emptyBox} />
                      </Box>
                    )}

                    <Box
                      className={classes.addRemoveBtnontainer}
                      onClick={'' ? removeImg : onPressImage}
                    >
                      <Typography
                        style={{
                          color: 'white',
                          fontSize: 18,
                          textAlign: 'center',
                        }}
                      >
                        {'' ? 'x' : '+'}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box py={1} className={classes.btnContainer}>
                <Button
                  className={classes.btn}
                  variant="outlined"
                  size="small"
                  uppercase={false}
                  disabled={loading}
                  onClick={(e) => onPressNext(e)}
                >
                  {t('submit')}
                </Button>
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    </ErrorBoundary>
  );
};

export default EditVendorImages;
