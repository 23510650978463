/* eslint-disable react/prop-types */
import React, {useState, useEffect} from 'react';
import useStyle from './styles';
import {
  createMuiTheme,
  Box,
  Button,
  Grid,
  TextField,
  Typography,
} from '@material-ui/core';
import {useTranslation} from 'react-i18next';
import HoursItem from '../../components/AddHoursItem';
import {
  KeyboardTimePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import {ThemeProvider} from '@material-ui/core/styles';
import DateFnsUtils from '@date-io/date-fns';
import {useDispatch, useSelector} from 'react-redux';
import {setVendorShopDetails} from '../../redux/actions/user';
import AppBarName from '../../components/DumbComponents/AppBarName';
import WebToolbar from '../../components/toolbar/WebToolbar';
import SideNav from '../../components/sidenav/SideNav';
import {useHistory} from 'react-router';
import ErrorBoundary from '../../components/ErrorBoundary';
import ErrorBoundaryFallback from '../../components/ErrorBoundaryFallback';
import MobileFooter from '../MobileFooter';

const initialState = [
  {
    day: 'Sunday',
    open: false,
    hours: [],
  },
  {
    day: 'Monday',
    open: true,
    hours: [
      {
        from: '9:00 AM',
        to: '9:00 PM',
      },
    ],
  },
  {
    day: 'Tuesday',
    open: true,
    hours: [
      {
        from: '9:00 AM',
        to: '9:00 PM',
      },
    ],
  },
  {
    day: 'Wednesday',
    open: true,
    hours: [
      {
        from: '9:00 AM',
        to: '9:00 PM',
      },
    ],
  },
  {
    day: 'Thursday',
    open: true,
    hours: [
      {
        from: '9:00 AM',
        to: '9:00 PM',
      },
    ],
  },
  {
    day: 'Friday',
    open: true,
    hours: [
      {
        from: '9:00 AM',
        to: '9:00 PM',
      },
    ],
  },
  {
    day: 'Saturday',
    open: true,
    hours: [
      {
        from: '9:00 AM',
        to: '9:00 PM',
      },
    ],
  },
];

const materialTheme = createMuiTheme({
  overrides: {
    MuiButton: {
      textPrimary: {
        color: '#fff',
        backgroundColor: '#0277BD',
        '&:hover': {
          color: '#0277BD',
          backgroundColor: '#fff',
          border: '1px solid #0277BD',
        },
      },
    },
  },
});

const AddHoursScreen = (props) => {
  const classes = useStyle();
  const {t} = useTranslation('becomeVendor');
  const dispatch = useDispatch();
  const history = useHistory();
  //   const {vendorDetails} = route.params;
  //   const {vendorDetails} = props.location.state;
  const [listData, setListData] = useState(initialState);
  const [type, setType] = useState('');
  const forceUpdate = useState()[1].bind(null, {});
  const [showTimePicker, setShowTimePicker] = useState(false);
  const [index, setIndex] = useState('');
  const [subIndex, setSubIndex] = useState('');
  const [breakStart, setBreakStart] = useState('12:00:00');
  // const [breakStart, setBreakStart] = useState(new Date('2014-08-18T12:00:00'));
  const [breakEnd, setBreakEnd] = useState('13:00:00');
  const [timeRemarks, setTimeRemarks] = useState('');
  const [selectedTime, setSelectedTime] = useState(
    new Date('2014-08-18T21:11:54')
  );
  const vendorShopDetails = useSelector((state) => {
    console.log('vendorShopDetails', state.user.vendorShopDetails);
    return state.user.vendorShopDetails;
  });

  const handleBreakStartTimeChange = (time) => {
    console.log('time', time);
    setBreakStart(time);
  };
  const handleBreakEndTimeChange = (date) => {
    setBreakEnd(date);
  };
  function addHours(index) {
    const data = listData;
    const obj = {
      from: '9:00 AM',
      to: '9:00 PM',
    };
    data[index].hours.push(obj);
    setListData(data);
    forceUpdate();
  }
  function toggleOpen(index, value) {
    const data = listData;
    data[index].open = !data[index].open;
    if (value) {
      const obj = {
        from: '9:00 AM',
        to: '9:00 PM',
      };
      data[index].hours.push(obj);
    } else {
      data[index].hours = [];
    }
    setListData(data);
    forceUpdate();
  }
  function formatAMPM(date) {
    let hours = date.getHours();
    let minutes = date.getMinutes();
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours %= 12;
    hours = hours || 12;
    minutes = minutes < 10 ? `0${minutes}` : minutes;
    const strTime = `${hours}:${minutes} ${ampm}`;
    console.log('strTime', strTime);
    return strTime;
  }

  function invokeTimePicker(i, sIndex, ty, time) {
    setType(ty);
    setIndex(i);
    setSubIndex(sIndex);

    console.log('i', i, 'sIndex', sIndex, 'listData', listData, 'time', time);

    if (time) {
      const data = listData;
      if (ty === 'from') {
        console.log('data[i].hours[sIndex]', data[i].hours[sIndex].from);
        data[i].hours[sIndex].from = formatAMPM(new Date(time));
      } else if (ty === 'to') {
        data[i].hours[sIndex].to = formatAMPM(new Date(time));
      } else if (ty === 'breakStart') {
        setBreakStart(formatAMPM(time));
      } else if (ty === 'breakEnd') {
        setBreakEnd(formatAMPM(time));
      }
      setListData(data);
      forceUpdate();
    }
    setType('');
    setIndex('');
    setSubIndex('');
  }

  function onPressNext() {
    var day = [];
    var operatingHours = [];
    listData.forEach((element) => {
      if (element.open) {
        day.push(1);
      } else {
        day.push(0);
      }
      if (element.hours[0]) {
        operatingHours.push(`${element.hours[0].from}-${element.hours[0].to}`);
      } else {
        operatingHours.push('00-00');
      }
    });
    var data = {...vendorShopDetails};
    console.log('dataaa', data);
    data.WorkingDays = day;
    data.WorkingHours = operatingHours;
    data.TimeRemarks = timeRemarks;
    data.breakStart = breakStart;
    data.breakEnd = breakEnd;

    console.log('hours data', data);

    dispatch(setVendorShopDetails(data));
    history.push('addvendorshopimages');

    // history.push({
    //   pathname: 'addvendorshopimages',
    //   state: {vendorDetails: data},
    // });
    // navigation.navigate('AddVendorShopImages', {vendorDetails: data});
  }

  return (
    <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
      <div className={classes.container}>
        <WebToolbar />
        <div className={classes.sideNav}>
          <SideNav />
        </div>
        <AppBarName title="Timings" />
        <ThemeProvider theme={materialTheme}>
          <Box p={1}>
            <Typography className={classes.textTitle}>
              Working Hours :
            </Typography>
            <Box>
              {listData.length > 0 ? (
                listData.map((item, index) => {
                  console.log('item tt', item);
                  return (
                    <HoursItem
                      key={index}
                      day={item.day}
                      open={item.open}
                      hours={item.hours}
                      addHours={() => addHours(index)}
                      toggleSwitch={(value) => toggleOpen(index, value)}
                      invokeTimePicker={(subIndex, type, time) => {
                        invokeTimePicker(index, subIndex, type, time);
                      }}
                    />
                  );
                })
              ) : (
                <Box></Box>
              )}
            </Box>
            <Box>
              <Typography className={classes.textTitle}>
                Break Time :
              </Typography>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Grid container>
                  <Grid
                    item
                    xs={6}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <KeyboardTimePicker
                      id="time-picker"
                      // label="Start Time"
                      // value={breakStart}
                      // onChange={handleBreakStartTimeChange}
                      value={new Date(`Mon 03-Jul-2017, ${breakStart}`)}
                      onChange={(time) => {
                        invokeTimePicker(index, subIndex, 'breakStart', time);
                      }}
                      KeyboardButtonProps={{
                        'aria-label': 'change time',
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <KeyboardTimePicker
                      id="time-picker"
                      // label="End Time"
                      // value={breakEnd}
                      // onChange={handleBreakEndTimeChange}
                      value={new Date(`Mon 03-Jul-2017, ${breakEnd}`)}
                      onChange={(time) => {
                        invokeTimePicker(index, subIndex, 'breakEnd', time);
                      }}
                      KeyboardButtonProps={{
                        'aria-label': 'change time',
                      }}
                    />
                  </Grid>
                </Grid>
              </MuiPickersUtilsProvider>
              <Box py={2}>
                <TextField
                  fullWidth
                  id="outlined-basic"
                  label="Time Remarks"
                  placeholder="We are closed on every third Saturday"
                  value={timeRemarks}
                  size="small"
                  variant="outlined"
                  className={classes.textInput}
                  onChange={(txt) => {
                    setTimeRemarks(txt.target.value);
                  }}
                />
              </Box>
            </Box>
          </Box>
        </ThemeProvider>
        <Box
          style={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Button variant="outlined" onClick={onPressNext}>
            {t('next')}
          </Button>
        </Box>
      </div>
      <MobileFooter />
    </ErrorBoundary>
  );
};

export default AddHoursScreen;
