/* eslint-disable react/prop-types */
import React, {useEffect, useState, useCallback} from 'react';
import WebToolbar from '../../components/toolbar/WebToolbar';
import useStyle from './styles';
import {Box, TextField, Typography, Grid, Button} from '@material-ui/core';
import showToast from '../../components/Toast';
import {useHistory} from 'react-router';
import {get, debounce, lowerCase} from 'lodash';
import searchItems from '../../services/searchItems';
import {useSelector} from 'react-redux';
import Autocomplete from '@material-ui/lab/Autocomplete';
import DeleteIcon from '@material-ui/icons/Delete';
import Divider from '@material-ui/core/Divider';
import searchvendor from '../../services/searchBasketVendor';
import createBasket from '../../services/createBasket';
import addBasketVendor from '../../services/addBasketVendor';
import SearchVendorAutoComplete from '../../components/DumbComponents/SearchVendorAutocomplete';
import AppBarName from '../../components/DumbComponents/AppBarName';
import SideNav from '../../components/sidenav/SideNav';
import SimpleAutocomplete from '../../components/DumbComponents/SimpleAutocomplete';
import ErrorBoundary from '../../components/ErrorBoundary';
import ErrorBoundaryFallback from '../../components/ErrorBoundaryFallback';
import MobileFooter from '../MobileFooter';

const AddBasketItems = (props) => {
  const {state} = props.location;
  const classes = useStyle();
  const history = useHistory();

  const [searchVendorText, setSearchVendorText] = useState('');
  const [searchVendorResult, setSearchVendorResult] = useState([]);
  const [selectedSearchVendor, setSelectedSearchVendor] = useState([]);
  const [vendors, setVendors] = useState([]);

  const [query, setQuery] = useState('');
  const [price, setPrice] = useState('');
  const [items, setItems] = useState([]);
  // const [selectedItem, setSelectedItem] = useState([]);
  const [searchResults, setSearchResults] = useState([]);
  // const [_searchResults, _setSearchResults] = useState([]);
  // const [_selectedItem, _setSelectedItem] = useState([]);
  const forceUpdate = useState()[1].bind(null, {});

  const [loading, setLoading] = useState(false);
  const [searching, setSearching] = useState(false);
  const token = useSelector((state) => state.user.token);

  useEffect(() => {
    console.log('props', props, 'state', state);

    return () => {
      setQuery('');
      setPrice('');
      setItems([]);
      setSearchResults([]);
    };
  }, []);

  const onPressAdd = () => {
    if (!query) {
      showToast('warn', 'Please search product');
      return;
    }
    if (!price) {
      showToast('warn', 'Please enter valid amount');
      return;
    }
    if (price < 0) {
      showToast('warn', 'Please enter valid amount');
      return;
    }

    // const _data = state;
    // const _temp = [];
    // selectedSearchVendor.forEach((element) => {
    //   console.log('forEach', element);
    //   const obj = {VendorId: element.vendorId};
    //   _temp.push(obj);
    // });
    // _data.TentativeVendors = _temp;

    const data = items;
    const obj = {
      ItemName: query,
      TentativePrice: Number(price),
    };
    setQuery('');
    setPrice('');
    // setSelectedSearchVendor('');
    data.push(obj);
    console.log('added data', data);
    setItems(data);
    // const data = _selectedItem;
    // console.log('_selectedItem', _selectedItem);
    // console.log('selectedItem', selectedItem);
    // const obj = {
    //   name: selectedItem,
    //   price,
    //   vendor: _selectedItem[0].organizationName,
    // };
    // setQuery('');
    // setPrice('');
    // data.push(obj);
    // setItems(data);
  };

  async function onChangeText(text) {
    console.log('txt', text);
    setSearching(true);
    setQuery(text);
    if (text.length > 0) {
      setLoading(true);
      handler(text);
    } else {
      setSearchResults([]);
      setLoading(false);
      setSearching(false);
    }
  }
  async function _onChangeText(text) {
    if (text.length > 0) {
      setSearchVendorText(text);
      setLoading(true);
      _handler(text);
    } else {
      setSearchVendorResult([]);
      // _setSearchResults([]);
      setLoading(false);
    }
  }

  const handler = useCallback(
    debounce(async (text) => {
      console.log('text in handler', text, vendors);
      if (!text.trim()) {
        return;
      }
      let vendor;
      if (vendors.length) {
        vendor = vendors[vendors.length - 1];
      }
      console.log('vendorId', get(vendor, 'vendorId', ''));
      try {
        const response = await searchItems(
          token,
          text,
          get(vendor, 'vendorId', '')
        );
        console.log('search response', response);
        if (response.successCode === 1) {
          const data = response.response;
          const obj = {
            description: text,
            isVendorItem: false,
            name: text,
            price: '',
          };
          data.push(obj);
          setSearchResults(data);
          setSearching(false);
        } else {
          const data = [];
          const obj = {
            description: text,
            isVendorItem: false,
            name: text,
          };
          data.push(obj);
          // showToast('error', "No item's found");
          setSearchResults(data);
        }
        setLoading(false);
        setSearching(false);
      } catch (error) {
        setLoading(false);
        setSearching(false);
      }
    }, 500),
    []
  );

  const _handler = useCallback(
    debounce(async (text) => {
      console.log('text in handler', text);
      if (!text.trim()) {
        return;
      }
      try {
        const response = await searchvendor(token, text);
        console.log('search vendor response', response);
        if (response.successCode === 1) {
          const data = response.response.customerVendors;

          const obj = {
            description: text,
            isVendorItem: false,
            name: text,
            price: '',
          };
          data.push(obj);
          setSearchVendorResult(data);
          // _setSearchResults(data);
        } else {
          const data = [];
          const obj = {
            description: text,
            isVendorItem: false,
            name: text,
          };
          data.push(obj);
          // showToast('error', "No item's found");
          // _setSearchResults(data);
          setSearchVendorResult(data);
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    }, 500),
    []
  );

  function removeItem(index) {
    console.log('index', index);
    console.log('items', items);

    const data = items;
    data.splice(index, 1);
    setItems(data);
    forceUpdate();

    console.log('items', items);
    // const data = items;
    // data.splice(index, 1);
    // setItems(data);
    // forceUpdate();
  }

  async function onPressNext() {
    if (!state.isNew) {
      const _data = state;
      const _temp = [];
      selectedSearchVendor.forEach((element) => {
        console.log('forEach', element);
        const obj = {VendorId: element.vendorId};
        _temp.push(obj);
      });
      _data.TentativeVendors = _temp;

      // const data = state;
      // const temp = [];
      // console.log('items', items);
      // items.forEach((element) => {
      //   console.log('element', element);
      //   const obj = {VendorId: element.vendorId};
      //   temp.push(obj);
      // });
      // data.TentativeVendors = temp;
      state.items = items;
      console.log('state payload', state);
      setLoading(true);
      try {
        const response = await createBasket(state, token);
        console.log('response from create basket', response);
        if (response.successCode === 1) {
          // getList();
          // const popAction = StackActions.pop(3);
          // navigation.dispatch(popAction);
          showToast('success', 'Basket Created');

          history.push('basketsscreen');
        } else {
          showToast('error', response.returnMessage);
        }
        setLoading(false);
      } catch (error) {
        console.log('error', error);
        setLoading(false);
      }
    } else {
      if (items.length === 0) {
        showToast('danger', 'Please add vendor');
        return;
      } else {
        setLoading(true);
        try {
          const {groupId, id} = state;
          console.log(groupId, id);
          const vendors = [];
          items.forEach((item) => {
            vendors.push(item.vendorId);
          });
          const response = await addBasketVendor(
            {VendorIds: vendors},
            id,
            groupId,
            token
          );
          console.log('addBasketVendor response', response);
          if (response.successCode === 1) {
            // navigation.goBack();
            showToast('Vendor added', 'success');
          } else {
            showToast(response.returnMessage, 'danger');
          }
          setLoading(false);
        } catch (error) {
          console.log(error);
          setLoading(false);
        }
      }
    }
  }

  const handleChange = (value) => {
    console.log('value aa', value);
    _onChangeText(value);
  };
  const handleSelectionChange = (value) => {
    console.log('value', value);
    const data = vendors;
    data.push(...value);
    setVendors(data);
    setSearchVendorText('');
    setSearchVendorResult([]);
    setSelectedSearchVendor(value);

    console.log('1', vendors, '2', selectedSearchVendor);
    // _setSelectedItem(value);
  };

  const onChangeValue = (value) => {
    console.log('value item', value);
    setQuery(value);
    setSearchResults([]);
  };

  return (
    <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
      <div className={classes.container}>
        <WebToolbar />
        <div className={classes.sideNav}>
          <SideNav />
        </div>
        <AppBarName title={'Add Basket Items'} />
        <Box
          style={{
            height: '550px',
            display: 'flex',
            flexDirection: 'column',
            position: 'relative',
          }}
        >
          <Box m={2}>
            <Grid container direction={'column'} spacing={2}>
              <Grid item>
                <Box style={{marginTop: '15px'}}>
                  <SearchVendorAutoComplete
                    dropdownOptions={searchVendorResult}
                    placeholder="Search Vendors"
                    onChange={handleChange}
                    onSelectionChange={handleSelectionChange}
                  />
                </Box>
              </Grid>
              <Grid item>
                <SimpleAutocomplete
                  options={
                    searchResults
                      ? searchResults.map((option) => {
                          return lowerCase(option.name);
                        })
                      : ''
                  }
                  handleACOnChange={(value) => {
                    return onChangeValue(value);
                  }}
                  labelText="Search Items"
                  handleOnChange={onChangeText}
                  searching={searching}
                />
              </Grid>
              <Grid item>
                <TextField
                  fullWidth
                  id="outlined-basic"
                  label="Price"
                  value={price}
                  size="small"
                  type="number"
                  variant="outlined"
                  onChange={(txt) => {
                    if (txt.target.value.length <= 6) {
                      setPrice(txt.target.value);
                    }
                  }}
                />
              </Grid>
            </Grid>
            <Box
              my={2}
              style={{
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <Button
                variant="outlined"
                onClick={() => {
                  onPressAdd();
                }}
              >
                Add
              </Button>
            </Box>
          </Box>
          <Box>
            {items.length > 0 ? (
              items.map((itm, index) => {
                return (
                  <Box key={index} px={1}>
                    <Grid container style={{marginTop: '10px'}}>
                      <Grid item xs={4}>
                        <Typography>{`${index + 1}.  ${
                          itm.ItemName
                        }`}</Typography>
                      </Grid>
                      <Grid
                        item
                        xs={4}
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                        }}
                      >
                        <Typography>
                          ₹ {get(itm, 'TentativePrice', 'NA')}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={4}
                        style={{
                          display: 'flex',
                          justifyContent: 'end',
                        }}
                      >
                        <DeleteIcon
                          color="error"
                          style={{cursor: 'pointer'}}
                          onClick={() => {
                            removeItem(index);
                          }}
                        />
                      </Grid>
                    </Grid>
                    <Divider />
                  </Box>
                );
              })
            ) : (
              <Box p={1}>
                <Typography>No items added.</Typography>
              </Box>
            )}
          </Box>
          <Box
            //   my={2}
            style={{
              position: 'absolute',
              bottom: 10,
              display: 'flex',
              justifyContent: 'center',
              width: '100%',
            }}
          >
            <Button
              variant="outlined"
              onClick={() => {
                onPressNext();
              }}
            >
              Next
            </Button>
          </Box>
        </Box>
      </div>
      <MobileFooter />
    </ErrorBoundary>
  );
};

export default AddBasketItems;
