import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {useHistory} from 'react-router';
import showToast from '../../components/Toast';
import useStyle from './style';
import getFaq from '../../services/getFaq';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import WebToolbar from '../../components/toolbar/WebToolbar';
import AppBarName from '../../components/DumbComponents/AppBarName';
import SideNav from '../../components/sidenav/SideNav';
import ActivityIndicator from '../../components/ActivityIndicator';
import ErrorBoundary from '../../components/ErrorBoundary';
import ErrorBoundaryFallback from '../../components/ErrorBoundaryFallback';
import MobileFooter from '../MobileFooter';

const FAQ = () => {
  const classes = useStyle();
  const history = useHistory();
  const token = useSelector((state) => state.user.token);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  async function getData() {
    try {
      const response = await getFaq(token);
      console.log('getFaq response', response);
      if (response.successCode === 1) {
        setData(response.response);
        setLoading(false);
      } else {
        // navigation.goBack();
        history.push('/home');
        showToast('Failed to get data', 'danger');
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }

  return (
    <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
      <div className={classes.container}>
        <WebToolbar />
        <div className={classes.sideNav}>
          <SideNav />
        </div>
        <AppBarName title={'FAQ'} />
        {loading ? (
          <ActivityIndicator />
        ) : (
          <Box py={2}>
            {data.length > 0
              ? data.map((item, index) => {
                  console.log('item', item);
                  return (
                    <Accordion key={index}>
                      <AccordionSummary
                        style={{
                          backgroundColor: '#0277BD',
                          color: 'white',
                        }}
                        expandIcon={<ExpandMoreIcon style={{color: 'white'}} />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography className={classes.heading}>
                          {item.question}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails style={{backgroundColor: '#BDBDBD'}}>
                        <Typography>{item.answer}</Typography>
                      </AccordionDetails>
                    </Accordion>
                  );
                })
              : ''}
          </Box>
        )}
      </div>
      <MobileFooter />
    </ErrorBoundary>
  );
};

export default FAQ;
