import {makeStyles} from '@material-ui/styles';
import Colors from '../../theme/Colors';

const useStyle = makeStyles((theme) => ({
  container: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    paddingBottom: '100px',
    width: '80%',
    margin: 'auto',
    backgroundColor: '#fff',
    '& > header > header': {
      position: 'inherit',
      // display: 'flex',
      // justifyContent: 'center',
      // width: '80%',
    },
  },
  sideNav: {
    ['@media (max-width:700px)']: {
      display: 'none',
    },
    //height: '100%',
    width: '92px',
    position: 'fixed',
    zIndex: 1,
    top: 0,
    // right: 0,
    right: '15px',
    overflowX: 'hidden',
    marginTop: 190,
    // marginRight: theme.spacing(1),
    marginRight: 0,
    backgroundColor: '#3B3B3B',
    elevation: 2,
  },
  textInput: {
    borderRadius: 5,
    flex: 1,
    // backgroundColor: '#F5F5F5',
  },
  btn: {},
  card: {
    padding: 10,
    //elevation: 2,
    borderRadius: 10,
    paddingTop: 20,
    //marginTop: 20,
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  textGender: {
    fontSize: 18,
    color: 'black',
    marginRight: 20,
    // fontWeight: 'bold',
  },
  textMale: {
    fontSize: 16,
    color: 'black',
  },
  viewImg: {
    height: 100,
    width: 100,
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    marginBottom: 20,
  },
  image: {
    height: 100,
    width: 100,
    borderRadius: 50,
  },
  btnPlus: {
    position: 'absolute',
    top: 0,
    right: 0,
    backgroundColor: Colors.secondary,
    elevation: 2,
    height: 36,
    width: 36,
    borderRadius: 18,
    justifyContent: 'center',
    alignItems: 'center',
  },
  placeholder: {
    height: 100,
    width: 100,
    borderRadius: 50,
    backgroundColor: 'lightgray',
    justifyContent: 'center',
    alignItems: 'center',
  },
  textBtn: {
    color: 'white',
    fontSize: 24,
  },
  textTitle: {
    fontSize: 16,
    color: 'black',
  },
  containerPicker: {
    // backgroundColor: '#F5F5F5',
    borderColor: 'gray',
    borderRadius: 4,
    padding: '4px',
  },
  containerPickerOne: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    listStyle: 'none',
    padding: '10px',
    margin: 0,
  },
  itemStyle: {},
  picker: {
    //width: width - 40,
  },
  textPicker: {
    fontSize: 14,
  },
  chip: {
    margin: 2,
    elevation: 2,
    backgroundColor: 'lightgray',
    color: 'white',
  },
  chipSelected: {
    margin: 2,
    backgroundColor: 'red',
    color: 'white',
    elevation: 2,
  },
  containerCategories: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'flex-start',
    // padding: 5,
    // borderWidth: 0.7,
    // width: width - 20,
  },
}));

export default useStyle;
