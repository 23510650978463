import { AppBar, Box, Toolbar, Typography, Grid } from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useHistory } from 'react-router';
import useStyle from './style';

const AppBarName = ({
  backIcon,
  onSelectBackIcon,
  title,
  isIcon,
  icon,
  onSelect,
  backScreenUrl,
  leftTitle,
  leftSubTitle
}) => {
  const history = useHistory();
  const classes = useStyle();
  return (
    <div>
      <Box
        style={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <AppBar position="static" elevation={0}>
          <Toolbar variant="dense" style={{ minHeight: '32px' }}>
            <Box style={{ display: 'flex', width: '100%' }}>
              <Box
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Box
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    cursor: 'pointer',
                  }}
                  // onClick={onSelectBackIcon}
                  onClick={() => {
                    if (backScreenUrl) {
                      history.push(backScreenUrl);
                    } else {
                      history.goBack();
                    }
                  }}
                >
                  <ArrowBackIcon />
                  {/* {backIcon} */}
                </Box>
              </Box>
              {(leftTitle || leftSubTitle) && <Box pl={9}>
                <Grid container>
                  <Grid
                    item
                    xs={11}
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      width: "15rem"
                    }}
                  >
                    <Typography className={classes.title}>
                      {leftTitle}
                    </Typography>
                    <Typography style={{ fontSize: '14px' }}>
                      {leftSubTitle}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
              }
              <Box
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: '100%',
                }}
              >
                <Typography variant="h6">{title}</Typography>
              </Box>
              <Box
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                {isIcon ? (
                  <Box
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      cursor: 'pointer',
                    }}
                    onClick={onSelect}
                  >
                    {icon}
                  </Box>
                ) : (
                  ''
                )}
              </Box>
            </Box>
          </Toolbar>
        </AppBar>
      </Box>
    </div>
  );
};

export default AppBarName;

AppBarName.propTypes = {
  backIcon: PropTypes.any,
  onSelectBackIcon: PropTypes.any,
  title: PropTypes.string.isRequired,
  isIcon: PropTypes.bool,
  icon: PropTypes.element,
  onSelect: PropTypes.func,
  backScreenUrl: PropTypes.string,
  leftTitle: PropTypes.string,
  leftSubTitle: PropTypes.string
};
