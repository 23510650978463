import baseUrl from '../utils/Constants';

export default async function (payload, token) {
  const response = await fetch(`${baseUrl}/uploads/order_images`, {
    method: 'POST',
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${token}`,
    },
    body: payload,
  });
  const responseJson = await response.json();
  return responseJson;
}
