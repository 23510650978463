/* eslint-disable react/prop-types */
import React, {useState, useEffect} from 'react';
import useStyle from './styles';
import WebToolbar from '../../components/toolbar/WebToolbar';
import {useDispatch, useSelector} from 'react-redux';
import {setBasketDetails} from '../../redux/actions/user';
import getBasketsList from '../../services/getBasketsList';
import {useTranslation} from 'react-i18next';
import {Typography, Box, Divider, Zoom} from '@material-ui/core';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import {get, isEmpty} from 'lodash';
import {useHistory} from 'react-router';
import moment from 'moment';
import ActivityIndicator from '../../components/ActivityIndicator';
import AppBarName from '../../components/DumbComponents/AppBarName';
import SideNav from '../../components/sidenav/SideNav';
import ErrorBoundary from '../../components/ErrorBoundary';
import ErrorBoundaryFallback from '../../components/ErrorBoundaryFallback';
import InifiniteScrollComponent from '../../components/InifiniteScrollComponent';
import MobileFooter from '../MobileFooter';

const BasketsScreen = (props) => {
  const history = useHistory();
  // const {
  //   location: {
  //     state: {
  //       group: {id},
  //       isAdmin,
  //     },
  //   },
  // } = props;
  const classes = useStyle();
  const dispatch = useDispatch();
  const [lastOrderId, setLastOrderId] = useState(0);
  const groupDetails = useSelector((state) => {
    return state.user.groupDetails;
  });
  // const basketDetails = useSelector((state) => {
  //   return state.user.basketDetails;
  // });
  const [loading, setLoading] = useState(true);
  const token = useSelector((state) => state.user.token);
  const {t} = useTranslation('groups');
  const [baskets, setBaskets] = useState([]);

  useEffect(() => {
    dispatch(setBasketDetails(null));
    // getList();
  }, []);

  const getList = async (currPage, setLastList, setPrevPage) => {
    try {
      let _lastId = lastOrderId;
      if (baskets.length) {
        _lastId = baskets[baskets.length - 1]['orderId'];
      }
      const id = groupDetails.group.id;
      const response = await getBasketsList(token, id, _lastId);
      console.log('baskets', response);
      if (response.successCode === 1) {
        if (!response.response.length && _lastId === lastOrderId) {
          setLastList(true);
          return;
        }
        setLastOrderId(_lastId);
        setPrevPage(currPage);
        let newListData = [...baskets, ...response.response];
        setBaskets(newListData);
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const scrollListItem = (item, index) => {
    return (
      <Box
        p={2}
        key={index}
        className={classes.basketDetailsContainer}
        onClick={() => {
          dispatch(setBasketDetails(item));
          history.push('/basketdetails');
          // history.push({
          //   pathname: 'basketdetails',
          //   state: {item: item, isAdmin: groupDetails.isAdmin},
          // });
          // history.push({pathname: 'groupmessagesscreen', state: item});
        }}
      >
        <Box className={classes.nameStatusContainer}>
          <Typography className={classes.nameTxt}>
            {get(item, 'name', 'NA')}
          </Typography>
          <Typography
            className={
              item.state === 2
                ? classes.textFreeze
                : item.state === 4
                ? classes.textClosed
                : classes.textActive
            }
          >
            {item.state === 2
              ? 'Freezed'
              : item.state === 4
              ? 'Closed'
              : 'Active'}
          </Typography>
        </Box>

        <Typography className={classes.txt}>
          {get(item, 'purpose', '')}
        </Typography>
        <Typography className={classes.txt}>
          Tentative Delivery Date:{' '}
          {moment(get(item, 'tentativeDeliveryDate', '')).format(
            'ddd MMM Do YYYY'
          )}
        </Typography>
        <Typography className={classes.txt}>
          Order Acceptance Till:
          {moment(get(item, 'tentativeOrderDate', '')).format(
            'ddd MMM Do YYYY'
          )}
        </Typography>
        <Divider />
      </Box>
    );
  };

  return (
    <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
      <div className={classes.container}>
        <WebToolbar />
        <div className={classes.sideNav}>
          <SideNav />
        </div>
        <AppBarName title={'Baskets'} />
        <InifiniteScrollComponent
          loading={loading}
          listData={baskets}
          scrollListItem={scrollListItem}
          fetchRecords={getList}
          height="80vh"
          noDataMessage="Please create basket to collect group members orders."
        />
        <Zoom in={true} timeout={{enter: 500, exit: 500}} unmountOnExit>
          <Fab
            disabled={!groupDetails?.isAdmin}
            className={classes.disabledButton}
            aria-label="add"
            size="small"
            variant="extended"
            onClick={() => {
              history.push('/createbasketscreen');
              // history.push({
              //   pathname: 'createbasketscreen',
              //   state: groupDetails,
              //   // state: props.location.state,
              // });
            }}
          >
            <AddIcon className={classes.extendedIcon} />
            Add Basket
          </Fab>
        </Zoom>
      </div>
      <MobileFooter />
    </ErrorBoundary>
  );
};

export default BasketsScreen;
