import useStyle from './styles';
import React, {useEffect, useState} from 'react';
import {Box, Typography, Grid} from '@material-ui/core';
import WebToolbar from '../../components/toolbar/WebToolbar';
import {useHistory} from 'react-router';
import {get, isEmpty} from 'lodash';
import {useDispatch, useSelector} from 'react-redux';
import getCustomers from '../../services/getCustomers';
import getCustomerOrders from '../../services/getCustomerOrders';
import Divider from '@material-ui/core/Divider';
import ActivityIndicator from '../../components/ActivityIndicator';
import AppBarName from '../../components/DumbComponents/AppBarName';
import SideNav from '../../components/sidenav/SideNav';
import ErrorBoundary from '../../components/ErrorBoundary';
import ErrorBoundaryFallback from '../../components/ErrorBoundaryFallback';
import MobileFooter from '../MobileFooter';
import {getAddress} from '../../utils/common';

const MyCustomers = () => {
  const classes = useStyle();
  const history = useHistory();
  const dispatch = useDispatch();
  const token = useSelector((state) => state.user.token);
  const [orders, setOrders] = useState();
  const [customerId, setCustomerId] = useState(144);
  const [listData, setListData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getData();
  }, []);

  async function getData() {
    try {
      setLoading(true);
      const response = await getCustomers(token);
      console.log(' list data response', response);
      if (response.successCode === 1) {
        setListData(response.response.customers);
        setLoading(false);
      } else {
        //
        setLoading(false);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }

  const getUserAddress = (customer) => {
    return (
      <Typography>
        <Box fontWeight="fontWeightMedium" display="inline">
          {'Address : '}
        </Box>
        {getAddress(customer, false) || 'Not available'}
      </Typography>
    );
  };

  return (
    <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
      <div className={classes.container}>
        <WebToolbar />
        <div className={classes.sideNav}>
          <SideNav />
        </div>
        <AppBarName title="My Customers" />
        {loading ? (
          <ActivityIndicator />
        ) : (
          <Box>
            {listData.length > 0
              ? listData.map((customer, index) => {
                  return (
                    /* <Box
              key={index}
              boxShadow={2}
              my={3}
              p={1}
              onClick={() => {
                history.push({
                  pathname: '/customerorders',
                  state: customer.userId,
                });
              }}
            >
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <Typography>{`Name : ${customer.customerName}`}</Typography>
                  <Typography>{`Contact : ${customer.customerContact}`}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography>{`Address : ${customer.customerAddress.addressLine1}, ${customer.customerAddress.subLocality}, ${customer.customerAddress.locality}, ${customer.customerAddress.city}.`}</Typography>
                  <Typography style={{color: 'green', fontWeight: 900}}>
                    {`Total Dues : ₹ ${customer.totalDues}`}
                  </Typography>
                </Grid>
              </Grid>
            </Box> */

                    <Box
                      key={index}
                      p={1}
                      onClick={() => {
                        history.push({
                          pathname: '/customerorders',
                          state: {customer: customer},
                        });
                      }}
                    >
                      <Grid container style={{cursor: 'pointer'}}>
                        <Grid item xs={6}>
                          <Typography>
                            <Box fontWeight="fontWeightMedium" display="inline">
                              Name
                            </Box>
                            {` : ${customer.customerName}`}
                          </Typography>
                          {getUserAddress(customer)}
                        </Grid>
                        <Divider
                          orientation="vertical"
                          flexItem
                          style={{margin: '10px'}}
                        />
                        <Grid item xs={5}>
                          <Typography>
                            <Box fontWeight="fontWeightMedium" display="inline">
                              Contact
                            </Box>
                            {` : ${customer.customerContact}`}
                          </Typography>
                          <Typography
                            style={{
                              color: customer.totalDues ? '#800000' : 'green',
                              fontWeight: 900,
                            }}
                          >
                            <Box display="inline">Total Dues</Box>
                            {` : ₹ ${customer.totalDues}`}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Divider />
                    </Box>
                  );
                })
              : ''}
          </Box>
        )}
      </div>
      <MobileFooter />
    </ErrorBoundary>
  );
};

export default MyCustomers;
