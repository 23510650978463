import {makeStyles} from '@material-ui/core/styles';

const useStyle = makeStyles((theme) => ({
  sideNav: {
    ['@media (max-width:700px)']: {
      display: 'none',
    },
    //height: '100%',
    width: '92px',
    position: 'fixed',
    zIndex: 1,
    top: 0,
    // right: 0,
    right: '15px',
    overflowX: 'hidden',
    marginTop: 190,
    // marginRight: theme.spacing(1),
    marginRight: 0,
    backgroundColor: '#3B3B3B',
    elevation: 2,
  },
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
  main: {},
  content: {
    // padding: theme.spacing(2),
  },
  container: {
    // height: '650px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    width: '80%',
    margin: 'auto',
    '& > header > header': {
      position: 'inherit',
      // display: 'flex',
      // justifyContent: 'center',
      // width: '80%',
    },
  },
  searchBoxContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  receivedOrdersContainer: {
    display: 'flex',
    padding: '10px',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#D3D3D3',
    },
  },
  ordersText: {
    fontSize: '16px',
    display: 'block',
    ['@media (max-width:450px)']: {
      fontSize: '14px',
    },
  },
  filterBtn: {
    '&:hover': {
      backgroundColor: '#B0B0B0',
    },
    margin: 1,
    width: '10%',
    [theme.breakpoints.down(450)]: {
      maxWidth: '10px',
      maxHeight: '40px',
      minWidth: '10px',
      minHeight: '40px',
    },
  },
  AccountBtn: {
    '&:hover': {
      backgroundColor: '#B0B0B0',
    },
    width: '10%',
    [theme.breakpoints.down(450)]: {
      maxWidth: '10px',
      maxHeight: '40px',
      minWidth: '10px',
      minHeight: '40px',
    },
  },
  filterIconSize: {
    fontSize: 28,
    ['@media (max-width:450px)']: {
      fontSize: 15,
    },
  },
  AccountIconSize: {
    fontSize: 28,
    ['@media (max-width:450px)']: {
      fontSize: 15,
    },
  },
}));

export default useStyle;
