import {
  Avatar,
  Box,
  Button,
  Card,
  CardMedia,
  Typography,
} from '@material-ui/core';
import React, {useState} from 'react';
import useStyle from './styles';
import AddOutlinedIcon from '@material-ui/icons/AddOutlined';
import imag from '../../assets/icons/ic_farmer.jpg';
import AppBarName from '../../components/DumbComponents/AppBarName';
import WebToolbar from '../../components/toolbar/WebToolbar';
import SideNav from '../../components/sidenav/SideNav';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import Colors from '../../theme/Colors';
import register from '../../services/registerVendor';
import addVendorImages from '../../services/addVendorImages';
import showToast from '../../components/Toast';
import {setUser, setVendorId} from '../../redux/actions/user';
import ImageDialog from '../../components/DumbComponents/ImageDialog';
import ErrorBoundary from '../../components/ErrorBoundary';
import ErrorBoundaryFallback from '../../components/ErrorBoundaryFallback';
import {useHistory} from 'react-router';
import MobileFooter from '../MobileFooter';
import SimpleDialog from '../../components/DumbComponents/SimpleDialog';

const initialImgState = [
  {
    image: '',
  },
  {
    image: '',
  },
  {
    image: '',
  },
];

const AddVendorShopImages = () => {
  const classes = useStyle();
  const history = useHistory();

  const vendorShopDetails = useSelector((state) => {
    console.log('vendorShopDetails', state.user.vendorShopDetails);
    return state.user.vendorShopDetails;
  });

  const {t} = useTranslation('becomeVendor');
  const dispatch = useDispatch();
  const token = useSelector((state) => state.user.token);
  const user = useSelector((state) => state.user.user);
  const [images, setImages] = useState(initialImgState);
  // const {vendorDetails} = route.params;
  const [headerImage, setHeaderImage] = useState('');
  const [loading, setLoading] = useState(false);
  const forceUpdate = useState()[1].bind(null, {});
  const [openDialog, setOpenDialog] = useState({isOpen: false, index: null});
  const [cameraDialog, setCameraDialog] = useState(false);
  const [image, setImage] = useState([0, 0, 0]);
  const [showAlertDialog, setShowAlertDialog] = useState(false);

  // function onPressImage(index) {
  //   Alert.alert(
  //     '',
  //     'Pick image from',
  //     [
  //       {
  //         text: 'Gallary',
  //         onPress: () => {
  //           try {
  //             ImageCropPicker.openPicker({
  //               multiple: false,
  //               cropping: true,
  //               mediaType: 'photo',
  //               cropperToolbarColor: Colors.primary,
  //               cropperStatusBarColor: Colors.primaryDark,
  //               cropperTintColor: 'white',
  //               cropperToolbarWidgetColor: 'white',
  //             }).then((response) => {
  //               const data = images;
  //               data[index].image = response;
  //               setImages(data);
  //               forceUpdate();
  //             });
  //           } catch (error) {
  //             console.log(error);
  //           }
  //         },
  //         style: 'cancel',
  //       },
  //       {
  //         text: 'Camera',
  //         onPress: () => {
  //           try {
  //             ImageCropPicker.openCamera({
  //               multiple: false,
  //               cropping: true,
  //               mediaType: 'photo',
  //               cropperToolbarColor: Colors.primary,
  //               cropperStatusBarColor: Colors.primaryDark,
  //               cropperTintColor: 'white',
  //               cropperToolbarWidgetColor: 'white',
  //             }).then((response) => {
  //               const data = images;
  //               data[index].image = response;
  //               setImages(data);
  //               forceUpdate();
  //               forceUpdate();
  //             });
  //           } catch (error) {
  //             console.log(error);
  //           }
  //         },
  //       },
  //     ],
  //     {cancelable: true}
  //   );
  // }

  // function removeImg(index) {
  //   const data = images;
  //   data[index].image = '';
  //   setImages(data);
  //   forceUpdate();
  // }

  // function removeHeader() {
  //   setHeaderImage('');
  //   forceUpdate();
  // }

  // async function setHeader() {
  //   Alert.alert(
  //     '',
  //     'Pick image from',
  //     [
  //       {
  //         text: 'Gallary',
  //         onPress: () => {
  //           try {
  //             ImageCropPicker.openPicker({
  //               multiple: false,
  //               width: 700,
  //               height: 393,
  //               cropping: true,
  //               mediaType: 'photo',
  //               cropperToolbarColor: Colors.primary,
  //               cropperStatusBarColor: Colors.primaryDark,
  //               cropperTintColor: 'white',
  //               cropperToolbarWidgetColor: 'white',
  //             }).then((response) => {
  //               setHeaderImage(response);
  //             });
  //           } catch (error) {
  //             console.log(error);
  //           }
  //         },
  //         style: 'cancel',
  //       },
  //       {
  //         text: 'Camera',
  //         onPress: () => {
  //           try {
  //             ImageCropPicker.openCamera({
  //               multiple: false,
  //               width: 700,
  //               height: 393,
  //               cropping: true,
  //               mediaType: 'photo',
  //               cropperToolbarColor: Colors.primary,
  //               cropperStatusBarColor: Colors.primaryDark,
  //               cropperTintColor: 'white',
  //               cropperToolbarWidgetColor: 'white',
  //             }).then((response) => {
  //               setHeaderImage(response);
  //             });
  //           } catch (error) {
  //             console.log(error);
  //           }
  //         },
  //       },
  //     ],
  //     {cancelable: true}
  //   );
  // }

  // async function getBase64(img) {
  //   const base64 = await RNFS.readFile(img.image.path, 'base64');
  //   const obj = {
  //     PhotoType: 1,
  //     Photo: base64,
  //   };
  //   //console.log('obj', obj);
  //   return obj;
  // }

  async function onPressNext() {
    // console.log(vendorDetails);
    // var weeklyOffDays = [];
    // vendorDetails.days.forEach((element, index) => {
    //   if (element.isSelected) {
    //     const obj = {DayOfWeek: index + 1};
    //     weeklyOffDays.push(obj);
    //   }
    // });
    try {
      // const photos = await Promise.all[
      //   images.map(async (img) => {
      //     if (img.image !== '') {
      //       return await getBase64(img);
      //     }
      //   })
      // ];
      // const obj = await getBase64(images[0]);
      const payload = {
        OrganizationName: vendorShopDetails.OrganizationName,
        // PopularName: vendorDetails.popularName,
        //VendorTypeId: Number(vendorDetails.category),
        VendorCategories: [
          Number(vendorShopDetails.category),
          ...vendorShopDetails.subCategories,
        ],
        // StartTime: vendorShopDetails.shopStart,
        // EndTime: vendorShopDetails.shopEnd,
        // Photo  s: [headerImage],
        IsOrg: vendorShopDetails.IsOrg,
        AddressLine1: vendorShopDetails.AddressLine1,
        AddressLine2: vendorShopDetails.AddressLine2,
        SubLocalityID: vendorShopDetails.SubLocalityID,
        LocalityID: vendorShopDetails.LocalityID,
        PinCodeId: vendorShopDetails.PinCodeId,
        CityID: vendorShopDetails.CityID,
        stateId: vendorShopDetails.stateId,
        CountryID: vendorShopDetails.CountryID,
        //Email: vendorShopDetails.email,
        ProvideHomeDelivery: vendorShopDetails.isHomeDelivery,
        ShopRegistrationNo: vendorShopDetails.regNo,
        GSTNo: vendorShopDetails.gstNo,
        // VendorOccupationID: vendorShopDetails.occupationId,
        WorkingDays: vendorShopDetails.WorkingDays.toString(),
        WorkingHours: vendorShopDetails.WorkingHours.toString(),
        DailyBreakStartTime: vendorShopDetails.breakStart,
        DailyBreakEndTime: vendorShopDetails.breakEnd,
        TimeRemarks: vendorShopDetails.TimeRemarks,
        AutoGenerateToken: vendorShopDetails.AutoGenerateToken,
        Qualification: vendorShopDetails.qualification,
        //MainBusinessLine: vendorShopDetails.businessLine,
        Holidays: [],
        AssociatedCategories: vendorShopDetails.AssociatedCategories
          ? vendorShopDetails.AssociatedCategories
          : [],
        SecondaryMobiles: [{SecondaryMobile: `${user.mobile}`}],
        //WeeklyOffDays: weeklyOffDays,
      };
      setLoading(true);
      console.log('payload', payload);
      const response = await register(payload, token);
      console.log('response', response);
      if (response.successCode === 1) {
        showToast('success', t('success'));
        uploadImages();
        var userObj = user;
        userObj.isVendor = true;
        dispatch(setUser(userObj));
        dispatch(setVendorId(response.response.vendor.vendorId));
      } else {
        showToast('error', t('VENDOR_ALREADY_REGISTERED'));
      }
    } catch (error) {
      console.log('error', error);
      setLoading(false);
    }
  }

  function showSuccessDialog() {
    showToast('success', 'Registration Successful');
    setShowAlertDialog(true);
  }

  async function uploadImages() {
    var i = [];
    images.forEach((element) => {
      if (element.image) {
        i.push(element.image);
      }
    });
    if (!headerImage) {
      if (i.length === 0) {
        showSuccessDialog();
        setLoading(false);
        return;
      }
    }
    const payload = new FormData();
    // let val = {
    //   uri: headerImage.path,
    //   type: headerImage.mime,
    //   name: `${headerImage.modificationDate}.jpg`,
    // }
    payload.append('headerImage', headerImage);
    // images.forEach((element) => {
    //   // if (element.image) {
    //   //   payload.append('photos', {
    //   //     uri: element.image.path,
    //   //     type: element.image.mime,
    //   //     name: `${element.image.modificationDate}.jpg`,
    //   //   });
    //   // }
    //   payload.append('photos', element);
    // });
    try {
      const response = await addVendorImages(payload, token);
      setLoading(false);

      if (response.successCode === 1) {
        showToast('success', 'Registration Successful');
        setShowAlertDialog(true);
      } else {
        history.push('home');
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
    //console.log(payload);
  }
  const handleListItemClick = (val) => {
    setOpenDialog({isOpen: false, index: null});
    setCameraDialog(false);

    if (openDialog.index === 0) {
      let newArray = image;
      newArray.splice(0, 1, val);
      setImage(newArray);
    } else if (openDialog.index === 1) {
      let newArray = image;
      newArray.splice(1, 1, val);
      setImage(newArray);
    } else if (openDialog.index === 2) {
      let newArray = image;
      newArray.splice(2, 1, val);
      setImage(newArray);
    } else if (openDialog.index === null) {
      setHeaderImage(val);
    }
  };
  const closeDialogFunction = () => {
    setOpenDialog({isOpen: false, index: null});
  };

  return (
    <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
      <div className={classes.container}>
        <WebToolbar />
        <div className={classes.sideNav}>
          <SideNav />
        </div>
        <AppBarName title="Shop Images" />
        <Box p={1}>
          <Typography style={{fontWeight: 900}}>Header Image :</Typography>
          <Box
            style={{
              position: 'relative',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            {/* <img src={headerImage} width="500px" height="300px"></img> */}
            <Card>
              <div
                style={{position: 'relative'}}
                onClick={() => {
                  setOpenDialog({isOpen: true, index: null});
                }}
              >
                <CardMedia
                  style={{
                    width: '300px',
                    height: '150px',
                    paddingTop: '0%',
                    backgroundColor: 'gray',
                  }}
                  component="img"
                  image={headerImage ? URL.createObjectURL(headerImage) : ''}
                />

                <div
                  style={{
                    position: 'absolute',
                    color: 'white',
                    top: '5%',
                    left: '90%',
                    transform: 'translateX(-50%)',
                    cursor: 'pointer',
                  }}
                >
                  <Avatar
                    style={{
                      backgroundColor: '#4497A8',
                      width: '30px',
                      height: '30px',
                    }}
                  >
                    <AddOutlinedIcon />
                  </Avatar>
                </div>
              </div>
            </Card>
            <Box>
              <ImageDialog
                open={openDialog.isOpen}
                setOpen={(_isOpen) =>
                  setOpenDialog({isOpen: _isOpen, index: null})
                }
                onSimpleDialogClose={closeDialogFunction}
                message="Pick Image From?"
                handleListItemClick={handleListItemClick}
                cameraDialog={cameraDialog}
                setCameraDialog={setCameraDialog}
                closeShowCameraDialogFunction={handleListItemClick}
              />
            </Box>
          </Box>
        </Box>
        <Box p={1}>
          <Typography style={{fontWeight: 900}}>Images : </Typography>
          <Box
            style={{
              display: 'flex',
              justifyContent: 'space-evenly',
            }}
          >
            {image.map((card, index) => {
              return (
                <Card key={index} className={classes.root}>
                  <div style={{position: 'relative'}}>
                    <CardMedia
                      style={{
                        width: '200px',
                        height: '125px',
                        paddingTop: '0%',
                        backgroundColor: 'gray',
                      }}
                      component="img"
                      image={card ? URL.createObjectURL(card) : card}
                    />
                    <div
                      style={{
                        position: 'absolute',
                        color: 'white',
                        top: 10,
                        left: '85%',
                        transform: 'translateX(-50%)',
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        setOpenDialog({isOpen: true, index: index});
                      }}
                    >
                      <Avatar
                        style={{
                          backgroundColor: '#4497A8',
                          width: '30px',
                          height: '30px',
                        }}
                      >
                        <AddOutlinedIcon />
                      </Avatar>
                    </div>
                  </div>
                </Card>
              );
            })}
          </Box>
        </Box>
        <Box style={{display: 'flex', justifyContent: 'center'}}>
          <Button
            className={classes.btn}
            variant="outlined"
            onClick={onPressNext}
          >
            Submit
          </Button>
        </Box>
      </div>
      <MobileFooter />
      <SimpleDialog
        open={showAlertDialog}
        onSimpleDialogClose={() => {
          setShowAlertDialog(false);
          history.push('home');
        }}
        message="Do you want to build catalogue of products/services?"
        handleBasketListItemClick={() => navigation.navigate('VendorCatalogue')}
      />
      ;
    </ErrorBoundary>
  );
};

export default AddVendorShopImages;
