import {makeStyles} from '@material-ui/styles';

const useStyle = makeStyles((theme) => ({
  container: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    paddingBottom: '100px',
    backgroundColor: 'white',
    // padding: 10,
    flex: 1,
    width: '80%',
    margin: 'auto',
    '& > header > header': {
      position: 'inherit',
      // display: 'flex',
      // justifyContent: 'center',
      // width: '80%',
    },
  },
  sideNav: {
    ['@media (max-width:700px)']: {
      display: 'none',
    },
    //height: '100%',
    width: '92px',
    position: 'fixed',
    zIndex: 1,
    top: 0,
    // right: 0,
    right: '15px',
    overflowX: 'hidden',
    marginTop: 190,
    // marginRight: theme.spacing(1),
    marginRight: 0,
    backgroundColor: '#3B3B3B',
    elevation: 2,
  },
  imgContainer: {display: 'flex', alignItems: 'center'},
  iconStyle: {height: 35, width: 35, borderRadius: 20},
  dataContainer: {display: 'flex', alignItems: 'center', cursor: 'pointer'},
  btnContainer: {display: 'flex', alignItems: 'center'},
  item: {display: 'flex'},
  textName: {
    fontSize: 16,
    flex: 1,
    marginHorizontal: 5,
  },
  line: {
    width: '100%',
    height: 0.5,
    backgroundColor: 'gray',
  },
  textEmpty: {
    marginTop: 10,
    // textAlign: 'center',
    fontSize: 18,
    marginHorizontal: 20,
  },
  btnRemove: {
    marginHorizontal: 5,
  },
  imageVendor: {
    height: 35,
    width: 35,
    borderRadius: 20,
  },
}));

export default useStyle;
