import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {
  Box,
  Typography,
  TextField,
  Grid,
  Select,
  Button,
  Divider,
  Switch,
  Chip,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
} from '@material-ui/core';
import useStyle from './styles';
import ActivityIndicator from '../../components/ActivityIndicator';
import Colors from '../../theme/Colors';
import showToast from '../../components/Toast';
import pinCodeService from '../../services/pincode';
import getTypes from '../../services/getVendorTypes';
import getAssociationTypes from '../../services/getAssociationTypes';
import AppbarName from '../../components/DumbComponents/AppBarName';
import WebToolbar from '../../components/toolbar/WebToolbar';
import SideNav from '../../components/sidenav/SideNav';
import RHFTextInput from '../../components/DumbComponents/RHFTextInput';
import {Controller, useForm} from 'react-hook-form';
import {Address1Rules, Address2Rules, pincodeRules, rules} from './utils';
import {useHistory} from 'react-router-dom';
import {setVendorShopDetails} from '../../redux/actions/user';
import ErrorBoundary from '../../components/ErrorBoundary';
import ErrorBoundaryFallback from '../../components/ErrorBoundaryFallback';
import MobileFooter from '../MobileFooter';

const AddVendorShopDetails = () => {
  const classes = useStyle();
  const {t} = useTranslation('becomeVendor');
  const history = useHistory();
  const token = useSelector((state) => state.user.token);
  const [regNo, setRegNo] = useState('');
  const [gstNo, setGstNo] = useState('');
  const forceUpdate = useState()[1].bind(null, {});
  const [qualification, setQualification] = useState('');
  const [businessLine, setBusinessLine] = useState('');
  const [occupationId, setOccupationId] = useState('');
  const [orgName, setOrgName] = useState('');
  const [isHomeDelivery, setIsHomeDelivery] = useState(false);
  const [loading, setLoading] = useState(false);
  const [vendorTypes, setVendorTypes] = useState([]);
  const [selectedType, setSelectedType] = useState('');
  const [state, setState] = useState('');
  const [city, setCity] = useState('');
  const [pinCode, setPincode] = useState('');
  const [address1, setAddress1] = useState('');
  const [address2, setAddress2] = useState('');
  const [locality, setLocality] = useState('');
  const [subLocality, setSubLocality] = useState('');
  const [reqesting, setReqesting] = useState(false);
  const [pincodeObj, setPincodeObj] = useState('');
  const [subCategories, setSubCategories] = useState([]);
  const [isServiceType, setIsServiceType] = useState(false);
  const [autogenerateToken, setAutogenerateToken] = useState(false);
  const [isOrg, setIsOrg] = useState(false);
  const [subLocalities, setSubLocalities] = useState([]);
  const [localities, setLocalities] = useState([]);
  const [homeDeliveryRemarks, setHomeDeliveryRemarks] = useState('');
  const [deliveryPartners, setDeliveryPartners] = useState([]);

  const [selectedSubCat, setselectedSubCat] = useState();

  const dispatch = useDispatch();

  const {register, handleSubmit, control} = useForm({
    defaultValues: {
      orgName: '',
      pinCode: '',
      address1: '',
      address2: '',
    },
  });

  useEffect(() => {
    getVendorTypes();
  }, []);

  async function getVendorTypes() {
    setLoading(true);
    try {
      const response = await Promise.all([
        getTypes(token),
        //getDeliveryVendors(token),
        getAssociationTypes(token),
      ]);
      console.log('get Vendor & association types', response);
      setLoading(false);

      if (response[0].successCode === 1) {
        // setSelectedType(response.response.vendorTypes[0].id);
        setIsServiceType(response[0].response.vendorTypes[0].isServiceType);
        setVendorTypes(response[0].response.vendorTypes);
        setDeliveryPartners(response[1].response.vendorTypes);
        const subCats = response[0].response.vendorTypes[0].subCategories;
        subCats.forEach((element) => {
          element.isSelected = false;
        });
        setSubCategories(subCats);
      } else {
        showToast('error', response[0].returnMessage);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }

  const handleSwitchChange = (event) => {
    console.log('event.target.checked', event.target.checked);
    setIsOrg(event.target.checked);
  };

  const handleautogenerateTokenSwitchChange = (event) => {
    console.log('event.target.checked', event.target.checked);
    setAutogenerateToken(event.target.checked);
  };

  const handleisHomeDeliverySwitchChange = (event) => {
    console.log('event.target.checked', event.target.checked);
    setIsHomeDelivery(event.target.checked);
  };

  const handleisSelectedSwitchChange = (event, index) => {
    console.log('event.target.checked', event.target.checked, 'index', index);
    // setIsHomeDelivery(event.target.checked);
    const data = deliveryPartners;
    console.log('data', data, 'data[index]', data[index].isSelected);
    data[index].isSelected = !data[index].isSelected;
    console.log('data after', data);
    setDeliveryPartners(data);
    forceUpdate();
  };

  async function onPincodeChange(text) {
    setPincode(text);
    setPincodeObj('');
    if (text.length === 6) {
      setReqesting(true);
      try {
        const response = await pinCodeService(text);
        setReqesting(false);
        if (response.successCode === 1) {
          const data = response.response;
          setLocalities(data.area.localities);
          setSubLocalities(data.area.localities[0].subLocalities);
          setPincodeObj(data);
          setLocality(data.area.localities[0].id);
          setSubLocality(data.area.localities[0].subLocalities[0].id);
          setCity(data.area.city.name);
          setState(data.area.state.name);
        } else {
          // showToast('error', t(response.messageCode));
          showToast('error', 'Area not Supported');
          setPincodeObj('');
          setLocality('');
          setSubLocality('');
        }
        console.log(response);
      } catch (error) {
        setReqesting(false);
        console.log(error);
      }
    }
  }

  function onPressSubCategory(subCat, index) {
    console.log('subCat', subCat, 'index', index);
    setselectedSubCat(subCat.id);
    // const subCats = subCategories;
    // subCats[index].isSelected = !subCats[index].isSelected;
    // setSubCategories(subCats);
    // forceUpdate();
  }

  function onCategoryChange(cat, data) {
    console.log('cat', cat.target.value, data.props.data);
    console.log('vendorTypes', vendorTypes);
    if (cat === '-1') {
      showToast('error', 'Please select valid option');
      return;
    }
    setSelectedType(cat.target.value);

    setIsServiceType(vendorTypes[data.props.data].isServiceType);
    const subCats = vendorTypes[data.props.data].subCategories;
    console.log('subcats', subCats);
    subCats.forEach((element) => {
      element.isSelected = false;
    });
    setSubCategories(subCats);
  }

  const onPressNext = (data) => {
    if (selectedType === '') {
      showToast('error', 'Please select vendor type');
      return;
    }
    // if (!gstNo.trim()) {
    //   showToast('error',t('enterGstNo'));
    //   return;
    // }
    // if (!occupationId.trim()) {
    //   showToast('error',t('enterOccupationId'));
    //   return;
    // }
    // if (!businessLine.trim()) {
    //   showToast('error',t('enterBusinessLine'));
    //   return;
    // }
    // if (!qualification.trim()) {
    //   showToast('error',t('enterQualification'));
    //   return;
    // }
    const subCats = [];
    subCategories.forEach((element) => {
      if (element.isSelected) {
        subCats.push(element.id);
      }
    });
    const selectedLocaliity = localities.find((loc) => loc.id === locality);
    console.log(selectedLocaliity, locality);
    const selectedSubLocality = selectedLocaliity.subLocalities.find(
      (loc) => loc.id === subLocality
    );
    var data = {
      OrganizationName: data.orgName,
      AddressLine1: data.address1,
      AddressLine2: data.address2,
      SubLocalityID: selectedSubLocality.id,
      SubLocalityName: selectedSubLocality.name,
      LocalityID: selectedLocaliity.id,
      LocalityName: selectedLocaliity.name,
      PinCodeId: Number(data.pinCode),
      CityID: pincodeObj.area.city.id,
      stateId: pincodeObj.area.state.id,
      CountryID: pincodeObj.area.country.id,
      homeDeliveryRemarks,
    };
    data.isHomeDelivery = isHomeDelivery;
    data.businessLine = businessLine;
    data.gstNo = gstNo;
    data.occupationId = occupationId;
    data.regNo = regNo;
    data.qualification = qualification;
    data.category = selectedType;
    data.subCategories = subCats;
    data.IsOrg = isOrg;
    data.AutoGenerateToken = autogenerateToken;
    if (selectedType === 36) {
      const deliveryCategories = deliveryPartners.filter(
        (cat) => cat.isSelected === true
      );
      if (deliveryCategories) {
        const cats = [];
        deliveryCategories.forEach((element) => {
          cats.push(element.id);
        });
        data.AssociatedCategories = cats;
      }
    }
    console.log('data', data, 'history', history);
    dispatch(setVendorShopDetails(data));
    history.push('addhoursscreen');
    // history.push({pathname: 'addhoursscreen', state: {data}});
  };

  return (
    <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
      <Box className={classes.container}>
        <WebToolbar />
        <div className={classes.sideNav}>
          <SideNav />
        </div>
        <AppbarName title="Business Details" />
        {loading ? (
          <ActivityIndicator
            style={{height: '100%'}}
            size="large"
            color={Colors.secondary}
          />
        ) : (
          <Box p={1}>
            <form
              className={classes.form}
              onSubmit={handleSubmit(onPressNext)}
              noValidate
            >
              <Grid container direction={'column'} spacing={1}>
                <Grid item>
                  <RHFTextInput
                    name="orgName"
                    label={`${t('orgName')}`}
                    control={control}
                    type="text"
                    rules={rules}
                    showPasswordIcon={false}
                    // showPassword={showConfirmPassword}
                    // handleClickShowPassword={handleClickShowConfirmPassword}
                  />
                  {/* <TextField
                  label={`${t('orgName')}*`}
                  // placeholder={`${t('orgName')}*`}
                  fullWidth
                  size="small"
                  value={orgName}
                  onChange={(text) => setOrgName(text.target.value)}
                  variant="outlined"
                  disabled={loading}
                  className={classes.textInput}
                /> */}
                </Grid>
                <Grid
                  container
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    paddingLeft: '15px',
                  }}
                >
                  <Grid item xs={8}>
                    <Typography
                      onClick={() => setIsOrg(!isOrg)}
                      className={[classes.textTitle, {flex: 1}]}
                    >{`Is organization/company or individual?    ${
                      isOrg ? 'Organization' : 'Individual'
                    }`}</Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Switch
                      checked={isOrg}
                      onChange={handleSwitchChange}
                      color="primary"
                      name="checkedB"
                      inputProps={{'aria-label': 'primary checkbox'}}
                    />
                  </Grid>
                </Grid>
                <Grid item>
                  <Controller
                    name="pinCode"
                    control={control}
                    defaultValue=""
                    render={({
                      field: {onChange, value},
                      fieldState: {error},
                    }) => (
                      <TextField
                        id="outlined-basic1"
                        size="small"
                        fullWidth
                        label={`${t('pincode')}`}
                        variant="outlined"
                        value={value}
                        onChange={(e) => {
                          onChange(e);
                          onPincodeChange(e.target.value);
                        }}
                        error={!!error}
                        helperText={error ? error.message : null}
                        inputProps={{maxLength: 6}}
                      />
                    )}
                    rules={pincodeRules}
                  />
                  {/* <TextField
                label={`${t('pincode')}*`}
                // placeholder={`${t('pincode')}*`}
                fullWidth
                size="small"
                value={pinCode}
                inputProps={{maxLength: 6}}
                onChange={(text) => onPincodeChange(text.target.value)}
                variant="outlined"
                disabled={loading}
                className={classes.textInput}
              /> */}
                </Grid>
                <Grid item>
                  {pincodeObj ? (
                    <Grid container direction={'column'} spacing={1}>
                      <Grid item>
                        <TextField
                          label={t('state')}
                          value={state}
                          fullWidth
                          size="small"
                          inputProps={{readOnly: true}}
                          onChange={(text) => setState(text.target.value)}
                          variant="outlined"
                          editable={false}
                          disabled={loading}
                          className={classes.textInput}
                        />
                      </Grid>
                      <Grid item>
                        <TextField
                          label={t('city')}
                          value={city}
                          onChange={(text) => setCity(text.target.value)}
                          fullWidth
                          inputProps={{readOnly: true}}
                          variant="outlined"
                          editable={false}
                          disabled={loading}
                          size="small"
                          className={classes.textInput}
                        />
                      </Grid>
                      <Grid item>
                        <FormControl
                          variant="outlined"
                          size="small"
                          fullWidth
                          className={classes.containerPicker}
                        >
                          <InputLabel required={true} id="label-for-appliances">
                            {t('locality')}
                          </InputLabel>

                          <Select
                            label={t('locality')}
                            fullWidth
                            variant="outlined"
                            native
                            value={locality}
                            onChange={(type, index) => {
                              setLocality(type);
                              setSubLocalities(localities[index].subLocalities);
                              setSubLocality(
                                localities[index].subLocalities.name
                              );
                            }}
                            inputProps={{
                              name: 'age',
                              id: 'outlined-age-native-simple',
                            }}
                          >
                            {localities.map((element, index) => (
                              <option key={index} value={element.name}>
                                {element.name}
                              </option>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>

                      {/* <TextInput
                  label={t('locality')}
                  value={locality}
                  onChange={(text) => setLocality(text.target.value)}
                  fullWidth
                  variant="outlined"
                  editable={false}
                  disabled={loading}
                  style={classes.textInput}
                />
                <TextInput
                  label={t('subLocality')}
                  value={subLocality}
                  onChange={(text) => setSubLocality(text.target.value)}
                  fullWidth
                  variant="outlined"
                  editable={false}
                  disabled={loading}
                  style={classes.textInput}
                />
*/}

                      <Grid item>
                        <FormControl
                          fullWidth
                          variant="outlined"
                          size="small"
                          className={classes.containerPicker}
                        >
                          <InputLabel required={true} id="label-for-appliances">
                            {t('subLocality')}
                          </InputLabel>

                          <Select
                            label={t('sublocality')}
                            fullWidth
                            native
                            variant="outlined"
                            value={subLocalities}
                            onChange={(type, index) => setSubLocality(type)}
                            inputProps={{
                              name: 'age',
                              id: 'outlined-age-native-simple',
                            }}
                          >
                            {subLocalities.map((element, index) => (
                              <option key={index} value={element.name}>
                                {element.name}
                              </option>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>

                      <Grid item>
                        <RHFTextInput
                          name="address1"
                          label={t('address1')}
                          control={control}
                          type="text"
                          rules={Address1Rules}
                          showPasswordIcon={false}
                          // showPassword={showConfirmPassword}
                          // handleClickShowPassword={handleClickShowConfirmPassword}
                        />
                        {/* <TextField
                      label={t('address1')}
                      value={address1}
                      onChange={(text) => setAddress1(text.target.value)}
                      fullWidth
                      size="small"
                      variant="outlined"
                      disabled={loading}
                      className={classes.textInput}
                    /> */}
                      </Grid>
                      <Grid item>
                        <RHFTextInput
                          name="address2"
                          label={t('address2')}
                          control={control}
                          type="text"
                          rules={Address2Rules}
                          showPasswordIcon={false}
                          // showPassword={showConfirmPassword}
                          // handleClickShowPassword={handleClickShowConfirmPassword}
                        />
                        {/* <TextField
                      label={`${t('address2')} (optional)`}
                      // placeholder={`${t('address2')} (optional)`}
                      value={address2}
                      onChange={(text) => setAddress2(text.target.value)}
                      fullWidth
                      size="small"
                      variant="outlined"
                      disabled={loading}
                      className={classes.textInput}
                    /> */}
                      </Grid>
                    </Grid>
                  ) : null}
                </Grid>
                <Grid item>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                    fullWidth
                    size="small"
                  >
                    <InputLabel required={true} id="label-for-appliances">
                      {t('vendorType')}
                    </InputLabel>
                    {console.log('selectedId', selectedType)}
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      value={selectedType}
                      onChange={(e, data) => {
                        onCategoryChange(e, data);
                      }}
                      label={t('vendorType')}
                    >
                      {vendorTypes.map((element, index) => {
                        return (
                          <MenuItem key={index} data={index} value={element.id}>
                            {element.description}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                  {/* <FormControl
                  fullWidth
                  variant="outlined"
                  size="small"
                  className={classes.containerPicker}
                >
                  <InputLabel required={true} id="label-for-appliances">
                    {t('vendorType')}
                  </InputLabel>
                  <Select
                    label={t('vendorType')}
                    fullWidth
                    native
                    variant="outlined"
                    placeholder="Select vendor type"
                    // value={vendorTypes}

                    onChange={(e) => {
                      onCategoryChange(e);
                    }}
                    inputProps={{
                      name: 'age',
                      id: 'age-native-label-placeholder',
                    }}
                  >
                    {vendorTypes.map((element, index) => {
                      console.log('ele', element);
                      return (
                        <option key={index} value={element.id}>
                          {element.description}
                        </option>
                      );
                    })}
                  </Select>
                </FormControl> */}
                </Grid>

                {selectedType === 36 ? (
                  <Grid
                    container
                    direction={'column'}
                    className={classes.containerPicker}
                  >
                    <Grid item style={{paddingLeft: '15px'}}>
                      <Typography>
                        Do you provide delivery service to
                        restaurants/grocery/chemists/food services in your local
                        area?
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      style={{
                        border: '1px solid gray',
                        borderRadius: '5px',
                      }}
                    >
                      {deliveryPartners.length > 0 ? (
                        deliveryPartners.map((item, index) => {
                          return (
                            <Box key={index}>
                              <Grid
                                container
                                className={classes.itemStyle}
                                style={{paddingLeft: '4px'}}
                              >
                                <Grid item xs={8}>
                                  <Typography>{item.description}</Typography>
                                </Grid>
                                <Grid item xs={4}>
                                  <Switch
                                    size="small"
                                    checked={item.isSelected}
                                    onChange={(e) => {
                                      handleisSelectedSwitchChange(e, index);
                                    }}
                                    color="primary"
                                    name="checkedA"
                                    inputProps={{
                                      'aria-label': 'primary checkbox',
                                    }}
                                  />
                                </Grid>
                              </Grid>
                              <Divider />
                            </Box>
                          );
                        })
                      ) : (
                        <></>
                      )}
                    </Grid>
                  </Grid>
                ) : (
                  ''
                )}
                <Grid
                  item
                  style={{display: subCategories.length > 0 ? '' : 'none'}}
                >
                  {subCategories.length > 0 ? (
                    <Box boxShadow={2} className={classes.containerPickerOne}>
                      <Typography className={classes.textPicker}>
                        Select Sub Categories of Business
                      </Typography>
                      <Box className={classes.containerCategories}>
                        {subCategories.map((value, index) => {
                          console.log('value q', value, index);
                          return (
                            <Chip
                              // className={
                              //   selectedSubCat === value.id
                              //     ? classes.chipSelected
                              //     : classes.chip
                              // }
                              color={
                                selectedSubCat === value.id
                                  ? 'primary'
                                  : 'default'
                              }
                              variant={
                                selectedSubCat === value.id
                                  ? 'default'
                                  : 'outlined'
                              }
                              key={index}
                              // selectedColor={value.isSelected ? 'white' : 'black'}
                              // selected={value.isSelected}
                              onClick={(e) => onPressSubCategory(value, index)}
                              label={value.description}
                            ></Chip>
                          );
                        })}
                      </Box>
                    </Box>
                  ) : (
                    ''
                  )}
                </Grid>
                {isServiceType ? (
                  <Grid
                    container
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <Grid item xs={8} style={{paddingLeft: '15px'}}>
                      <Typography
                        onClick={() => setAutogenerateToken(!autogenerateToken)}
                        className={classes.textTitle}
                      >
                        {`Would you like to autogenerate token for orders?    ${
                          autogenerateToken ? t('yes') : t('no')
                        }`}
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Switch
                        checked={autogenerateToken}
                        onChange={handleautogenerateTokenSwitchChange}
                        color="primary"
                        name="checkedA"
                        inputProps={{'aria-label': 'primary checkbox'}}
                      />
                    </Grid>
                  </Grid>
                ) : (
                  ''
                )}

                <Grid item>
                  <TextField
                    label={`${t('regNo')} (optional)`}
                    // placeholder={`${t('pincode')}*`}
                    fullWidth
                    size="small"
                    value={regNo}
                    inputProps={{maxLength: 20}}
                    onChange={(text) => setRegNo(text.target.value)}
                    variant="outlined"
                    disabled={loading}
                    className={classes.textInput}
                  />
                </Grid>
                <Grid item>
                  <TextField
                    label={`${t('gstNo')} (optional)`}
                    // placeholder={`${t('pincode')}*`}
                    fullWidth
                    size="small"
                    value={gstNo}
                    inputProps={{maxLength: 20}}
                    onChange={(text) => setGstNo(text.target.value)}
                    variant="outlined"
                    disabled={loading}
                    className={classes.textInput}
                  />
                </Grid>
                <Grid item>
                  <TextField
                    label={`${t('qualification')} (optional)`}
                    // placeholder={`${t('pincode')}*`}
                    fullWidth
                    size="small"
                    value={qualification}
                    inputProps={{maxLength: 20}}
                    onChange={(text) => setQualification(text.target.value)}
                    variant="outlined"
                    disabled={loading}
                    className={classes.textInput}
                  />
                </Grid>
                <Grid
                  container
                  spacing={1}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <Grid item xs={8} style={{paddingLeft: '15px'}}>
                    <Typography
                      onPress={() => setIsHomeDelivery(!isHomeDelivery)}
                      className={[classes.textTitle, {flex: 1}]}
                    >
                      {`${t('provideHomeDelivery')}    ${
                        isHomeDelivery ? t('yes') : t('no')
                      }`}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Switch
                      checked={isHomeDelivery}
                      onChange={handleisHomeDeliverySwitchChange}
                      color="primary"
                      name="checkedC"
                      inputProps={{'aria-label': 'primary checkbox'}}
                    />
                  </Grid>
                </Grid>
                <Grid item>
                  {isHomeDelivery && (
                    <TextField
                      label={'Delivery Remarks'}
                      placeholder="We provide service/delivery within 2 kms"
                      fullWidth
                      size="small"
                      value={homeDeliveryRemarks}
                      inputProps={{maxLength: 20}}
                      onChange={(text) =>
                        setHomeDeliveryRemarks(text.target.value)
                      }
                      variant="outlined"
                      disabled={loading}
                      className={classes.textInput}
                    />
                  )}
                </Grid>
                <Grid
                  item
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <Button
                    type="submit"
                    className={classes.btn}
                    variant="outlined"
                    color={Colors.secondary}
                    uppercase={false}
                    disabled={reqesting || !pincodeObj}
                    // onClick={onPressNext}
                  >
                    {t('next')}
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Box>
        )}
      </Box>
      <MobileFooter />
    </ErrorBoundary>
  );
};

export default AddVendorShopDetails;
