/* eslint-disable react/prop-types */
import React from 'react';
import {Typography, Button, Box, TextField} from '@material-ui/core';
import moment from 'moment';
import Autocomplete from '@material-ui/lab/Autocomplete';
import FilterListIcon from '@material-ui/icons/FilterList';
import AccountBoxOutlinedIcon from '@material-ui/icons/AccountBoxOutlined';
import Tooltip from '@material-ui/core/Tooltip';
import useStyles from './styles';

import {setOrderId} from '../../redux/actions/user';
import {isEmpty, lowerCase} from 'lodash';
import SimpleDialog from './SimpleDialog';
import {useDispatch} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {useHistory} from 'react-router';

const ReceivedTab = ({
  listDataReceived,
  _handleSearchedOrder,
  openFilterDialogFunction,
  receivedOrders,
  selectedValue,
  openFilterDialog,
  closeFilterDialogFunction,
  setListDataReceived,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const {t} = useTranslation(['orderDetails', 'common']);

  return (
    <>
      <Box className={classes.searchBoxContainer}>
        <Autocomplete
          size="small"
          id="free-solo-demo"
          style={{width: '100%'}}
          freeSolo
          disableClearable={true}
          options={
            listDataReceived
              ? listDataReceived.map((option) => {
                  return lowerCase(option.customerName);
                })
              : 'NA'
          }
          getOptionLabel={(option) => (option ? lowerCase(option) : 'NA')}
          onInputChange={(f) => {
            f.target.textContent.length > 0
              ? _handleSearchedOrder(lowerCase(f.target.textContent))
              : '';
          }}
          renderInput={(searchItem) => (
            <TextField
              className={classes.ordersText}
              {...searchItem}
              label="Search Orders by Vendor Name"
              onChange={(search) => {
                console.log('search', search);
                // setSearchItem(search.target.value);
                _handleSearchedOrder(search.target.value);
              }}
              variant="outlined"
            />
          )}
        />
        <Tooltip title="Filter Orders">
          <Button
            variant="outlined"
            className={classes.filterBtn}
            onClick={() => {
              openFilterDialogFunction();
            }}
          >
            <FilterListIcon className={classes.filterIconSize}></FilterListIcon>
          </Button>
        </Tooltip>
        <Tooltip title="Customer Details">
          <Button
            variant="outlined"
            className={classes.AccountBtn}
            onClick={() => {
              history.push('/mycustomers');
            }}
          >
            <AccountBoxOutlinedIcon
              className={classes.AccountIconSize}
            ></AccountBoxOutlinedIcon>
          </Button>
        </Tooltip>
      </Box>
      <Box
        style={{
          height: '410px',
          overflow: 'auto',
        }}
      >
        {!isEmpty(listDataReceived) ? (
          listDataReceived.map((item, index) => {
            return (
              <Box
                key={index}
                boxShadow={1}
                className={classes.receivedOrdersContainer}
                onClick={() => {
                  dispatch(setOrderId({orderId: item.orderId}));
                  history.push('/orderdetails');
                  // history.push({
                  //   pathname: '/orderdetails',
                  //   // orderID: item.orderId,
                  // });
                }}
              >
                <Box
                  style={{
                    width: '65%',
                  }}
                >
                  <Typography
                    className={classes.ordersText}
                  >{`Order ID: ${item.orderId}`}</Typography>
                  {item.customerName ? (
                    <Typography className={classes.ordersText}>
                      {`Order By: ${item.customerName}`}
                    </Typography>
                  ) : (
                    ''
                  )}
                  {item.orderRequestedBy ? (
                    <Typography className={classes.ordersText}>
                      {`Order By: ${item.orderRequestedBy}`}
                    </Typography>
                  ) : (
                    ''
                  )}

                  <Typography className={classes.ordersText}>{`Date: ${moment(
                    item.orderDate
                  ).format('DD/MM/YYYY, h:mm A')}`}</Typography>
                </Box>
                <Box
                  style={{
                    width: '35%',
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <Typography className={classes.ordersText}>
                    {`${t(item.status)}`}
                  </Typography>
                </Box>
              </Box>
            );
          })
        ) : (
          <Typography>No Orders Received</Typography>
        )}
      </Box>
      <SimpleDialog
        orders={receivedOrders}
        dispatch={dispatch}
        selectedValue={selectedValue}
        open={openFilterDialog}
        onClose={closeFilterDialogFunction}
        listD={listDataReceived}
        setListD={setListDataReceived}
      />
    </>
  );
};

export default ReceivedTab;
