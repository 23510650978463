import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {useHistory} from 'react-router';
import useStyle from './style';
import getCurrentVendor from '../../services/getCurrentVendor';
import updateVendor from '../../services/updateVendor';
import getTypes from '../../services/getVendorTypes';
import getAssociationTypes from '../../services/getAssociationTypes';
import pinCodeService from '../../services/pincode';
import showToast from '../../components/Toast';
import {
  Box,
  Chip,
  Button,
  FormControl,
  Grid,
  InputLabel,
  Select,
  Switch,
  TextField,
  Typography,
} from '@material-ui/core';
import ActivityIndicator from '../../components/ActivityIndicator';
import Colors from '../../theme/Colors';
import ErrorBoundary from '../../components/ErrorBoundary';
import ErrorBoundaryFallback from '../../components/ErrorBoundaryFallback';

const EditVendorDetails = () => {
  const classes = useStyle();
  const history = useHistory();

  const {t} = useTranslation('becomeVendor');
  const token = useSelector((state) => state.user.token);
  const user = useSelector((state) => state.user.user);
  const [regNo, setRegNo] = useState('');
  const [gstNo, setGstNo] = useState('');
  const forceUpdate = useState()[1].bind(null, {});
  const [qualification, setQualification] = useState('');
  const [businessLine, setBusinessLine] = useState('');
  const [occupationId, setOccupationId] = useState('');
  const [orgName, setOrgName] = useState('');
  const [isHomeDelivery, setIsHomeDelivery] = useState(false);
  const [loading, setLoading] = useState(false);
  const [vendorTypes, setVendorTypes] = useState([]);
  const [selectedType, setSelectedType] = useState('');
  const [state, setState] = useState('');
  const [city, setCity] = useState('');
  const [pinCode, setPincode] = useState('');
  const [address1, setAddress1] = useState('');
  const [address2, setAddress2] = useState('');
  const [locality, setLocality] = useState('');
  const [subLocality, setSubLocality] = useState('');
  const [reqesting, setReqesting] = useState(false);
  const [pincodeObj, setPincodeObj] = useState('');
  const [subCategories, setSubCategories] = useState([]);
  const [details, setDetails] = useState({});
  const [isServiceType, setIsServiceType] = useState(false);
  const [autogenerateToken, setAutogenerateToken] = useState(false);
  const [isOrg, setIsOrg] = useState(false);
  const [photos, setPhotos] = useState([]);
  const [subLocalities, setSubLocalities] = useState([]);
  const [localities, setLocalities] = useState([]);
  const [homeDeliveryRemarks, setHomeDeliveryRemarks] = useState('');
  const [homeDeliveryRemarksHint, setHomeDeliveryRemarksHint] = useState('');
  const [deliveryPartners, setDeliveryPartners] = useState([]);
  const disabledClassNameProps = {className: 'Mui-disabled'};

  const handleClose = (value) => {
    setOpen(false);
    history.push('/home');
    setSelectedValue(value);
  };

  const handleSwitchChange = (event) => {
    setIsOrg(event.target.checked);
  };

  useEffect(() => {
    getData();
  }, []);

  async function getData() {
    setLoading(true);
    try {
      const response = await Promise.all([
        getCurrentVendor(token),
        getTypes(token),
        getAssociationTypes(token),
      ]);
      console.log('three response', response);
      setLoading(false);
      if (response[0].successCode === 1) {
        setDetails(response[0].response);
        setOrgName(response[0].response.organizationName);
        setIsOrg(response[0].response.isOrg);
        setPhotos(response[0].response.photos);
        setRegNo(response[0].response.shopRegistrationNo);
        setGstNo(response[0].response.gstNo);
        setQualification(response[0].response.qualification);
        setIsHomeDelivery(response[0].response.provideHomeDelivery);
        setAddress1(response[0].response.vendorAddress.addressLine1);
        setAddress2(response[0].response.vendorAddress.addressLine2);
        setAutogenerateToken(response[0].response.autoGenerateToken);
        setHomeDeliveryRemarks(response[0].response.homeDeliveryRemarks);
        setHomeDeliveryRemarksHint(
          response[0].response.homeDeliveryRemarksHint
        );
        onPincodeChange(
          `${response[0].response.vendorAddress.pinCode}`,
          response[0].response.vendorAddress.locality,
          response[0].response.vendorAddress.subLocality
        );
        if (response[1].successCode === 1) {
          //setSelectedType(response[0].response.vendorTypeId);
          const types = response[1].response.vendorTypes;
          const selectedTypes = response[0].response.vendorTypes;
          let tt = null;
          for (let i = 0; i < types.length; i++) {
            for (let j = 0; j < selectedTypes.length; j++) {
              if (types[i].id === selectedTypes[j].id) {
                tt = types[i];
                break;
              }
            }
          }
          if (t) {
            setSelectedType(tt.id);
            const subCats = tt.subCategories;
            subCats.forEach((element) => {
              element.isSelected = false;
            });
            for (let i = 0; i < selectedTypes.length; i++) {
              for (let j = 0; j < subCats.length; j++) {
                if (selectedTypes[i].id === subCats[j].id) {
                  subCats[j].isSelected = true;
                }
              }
            }
            setSubCategories(subCats);
          } else {
            setSelectedType(response[0].response.vendorTypeId);
            const subCats = response[1].response.vendorTypes[0].subCategories;
            subCats.forEach((element) => {
              element.isSelected = false;
            });
            setSubCategories(subCats);
          }
          setVendorTypes(response[1].response.vendorTypes);
        } else {
          showToast('error', response[1].returnMessage);
        }
        if (response[0].response.associatedVendorTypes) {
          const associatedTypes = response[2].response.vendorTypes;
          associatedTypes.forEach((type, index) => {
            const isAdded = response[0].response.associatedVendorTypes.find(
              (item) => item.id === type.id
            );
            if (isAdded) {
              associatedTypes[index].isSelected = true;
            }
          });
          setDeliveryPartners(associatedTypes);
        } else {
          setDeliveryPartners(response[2].response.vendorTypes);
        }
      } else {
        showToast('error', response[0].messageCode);
        // navigation.goBack();
        history.push('/home');
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }

  async function onPincodeChange(text, loc, sLoc) {
    console.log('text', 'loc', 'sLoc', text, loc, sLoc);
    setPincode(text);
    setPincodeObj('');
    if (text.length === 6) {
      setReqesting(true);
      try {
        const response = await pinCodeService(text);
        console.log('pinCodeService response', response);
        setReqesting(false);
        if (response.successCode === 1) {
          const data = response.response;
          setPincodeObj(data);
          setLocalities(data.area.localities);
          setPincodeObj(data);
          setCity(data.area.city.name);
          setState(data.area.state.name);
          if (loc) {
            const subLoc = data.area.localities.find((a) => a.name === loc);
            setLocality(subLoc.id);
            setSubLocalities(subLoc.subLocalities);
            const subLocId = subLoc.subLocalities.find((a) => a.name === sLoc);
            setSubLocality(subLocId.id);
          } else {
            setLocality(data.area.localities[0].id);
            setSubLocality(data.area.localities[0].subLocalities[0].id);
            setSubLocalities(data.area.localities[0].subLocalities);
          }
        } else {
          showToast('error', t(response.messageCode));
          setPincodeObj('');
          setLocality('');
          setSubLocality('');
        }
      } catch (error) {
        setReqesting(false);
        console.log(error);
      }
    }
  }

  async function onPressNext() {
    if (!orgName.trim()) {
      showToast('error', t('enterOrgName'));
      return;
    }
    if (!address1.trim()) {
      showToast('error', t('invalidAddres'));
      return;
    }
    const subCats = [];
    subCategories.forEach((element) => {
      if (element.isSelected) {
        subCats.push(element.id);
      }
    });
    const selectedLocaliity = localities.find((loc) => loc.id === locality);
    const selectedSubLocality = selectedLocaliity.subLocalities.find(
      (loc) => loc.id === subLocality
    );
    /*var data = {
      OrganizationName: orgName,
      AddressLine1: address1,
      AddressLine2: address2,
      SubLocalityID: selectedSubLocality.id,
      SubLocalityName: selectedSubLocality.name,
      LocalityID: selectedLocaliity.id,
      LocalityName: selectedLocaliity.name,
      PinCodeId: Number(pinCode),
      CityID: pincodeObj.area.city.id,
      stateId: pincodeObj.area.state.id,
      CountryID: pincodeObj.area.country.id,
    };
    data.isHomeDelivery = isHomeDelivery;
    data.businessLine = businessLine;
    data.gstNo = gstNo;
    data.occupationId = occupationId;
    data.regNo = regNo;
    data.qualification = qualification;
    data.category = selectedType;
    data.subCategories = subCats;
    data.details = details;
    data.IsOrg = isOrg;
    data.AutoGenerateToken = autogenerateToken;
    data.photos = photos;
    //navigation.navigate('EditHours', {vendorDetails: data});
    */
    try {
      const payload = {
        VendorId: details.vendorId,
        OrganizationName: orgName,
        VendorTypeId: Number(selectedType),
        VendorCategories: [Number(selectedType), ...subCats],
        AddressLine1: address1,
        AddressLine2: address2,
        SubLocalityID: selectedSubLocality.id,
        LocalityID: selectedLocaliity.id,
        PinCodeId: Number(pinCode),
        CityID: pincodeObj.area.city.id,
        stateId: pincodeObj.area.state.id,
        CountryID: pincodeObj.area.country.id,
        ProvideHomeDelivery: isHomeDelivery,
        ShopRegistrationNo: regNo,
        GSTNo: gstNo,
        WorkingDays: details.workingDays,
        WorkingHours: details.workingHours,
        dailyBreakStartTime: details.dailyBreakStartTime,
        dailyBreakEndTime: details.dailyBreakEndTime,
        TimeRemarks: details.timeRemarks ? details.timeRemarks : '',
        Qualification: qualification,
        IsOrg: isOrg,
        //Holidays: [],
        SecondaryMobiles: [{SecondaryMobile: `${user.mobile}`}],
        AutoGenerateToken: autogenerateToken,
        homeDeliveryRemarks: homeDeliveryRemarks ? homeDeliveryRemarks : '',
      };
      if (selectedType === 36) {
        const deliveryCategories = deliveryPartners.filter(
          (cat) => cat.isSelected === true
        );
        if (deliveryCategories) {
          const cats = [];
          deliveryCategories.forEach((element) => {
            cats.push(element.id);
          });
          payload.AssociatedCategories = cats;
        }
      } else {
        payload.AssociatedCategories = [];
      }
      setReqesting(true);
      const response = await updateVendor(payload, token);
      setReqesting(false);
      console.log(response);
      if (response.successCode === 1) {
        showToast('success', 'Updated');
        // navigation.goBack();
        history.push('/home');
      } else {
        showToast('error', response.messageCode);
      }
    } catch (error) {
      console.log(error);
      showToast('error', 'Failed, please try again');
      setReqesting(false);
    }
  }

  function onCategoryChange(cat) {
    setSelectedType(cat.target.value);
    setIsServiceType(vendorTypes[cat.target.selectedIndex].isServiceType);
    const subCats = vendorTypes[cat.target.selectedIndex].subCategories;
    subCats.forEach((element) => {
      element.isSelected = false;
    });
    setSubCategories(subCats);
  }
  const handleisSelectedSwitchChange = (event, index) => {
    // console.log('event.target.checked', event.target.checked);
    // setIsHomeDelivery(event.target.checked);
    const data = deliveryPartners;
    data[index].isSelected = !data[index].isSelected;
    setDeliveryPartners(data);
    forceUpdate();
  };
  function onPressSubCategory(e, subCat, index) {
    const subCats = subCategories;
    subCats[index].isSelected = !subCats[index].isSelected;
    setSubCategories(subCats);
    forceUpdate();
  }
  const handleautogenerateTokenSwitchChange = (event) => {
    console.log('event.target.checked', event.target.checked);
    setAutogenerateToken(event.target.checked);
  };

  return (
    <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
      <Box>
        {loading ? (
          <ActivityIndicator />
        ) : (
          <Box py={1}>
            <Grid container direction={'column'}>
              <Grid item>
                <TextField
                  label={`${t('orgName')}*`}
                  // placeholder={`${t('orgName')}*`}
                  fullWidth
                  size="small"
                  value={orgName}
                  onChange={(text) => setOrgName(text.target.value)}
                  variant="outlined"
                  disabled={loading}
                  inputProps={{maxLength: 20}}
                  className={classes.textInput}
                />
              </Grid>
            </Grid>
            <Grid container className={classes.isOrgContainer}>
              <Grid item xs={6}>
                <Typography
                  component={'div'}
                  onClick={() => setIsOrg(!isOrg)}
                  className={classes.textTitle}
                >{`Is organization/company or individual?    ${
                  isOrg ? 'Organization' : 'Individual'
                }`}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Switch
                  checked={isOrg}
                  onChange={handleSwitchChange}
                  color="primary"
                  name="checkedB"
                  inputProps={{'aria-label': 'primary checkbox'}}
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid item>
                <TextField
                  label={`${t('pincode')}*`}
                  // placeholder={`${t('pincode')}*`}
                  // fullWidth
                  size="small"
                  value={pinCode}
                  inputProps={{maxLength: 6}}
                  onChange={(text) => onPincodeChange(text.target.value)}
                  variant="outlined"
                  disabled={loading}
                  className={classes.textInput}
                />
              </Grid>
            </Grid>

            <Box py={1}>
              {pincodeObj ? (
                <Grid container direction={'column'} spacing={1}>
                  <Grid item>
                    <TextField
                      label={t('state')}
                      {...disabledClassNameProps}
                      inputProps={{readOnly: true}}
                      InputProps={{...disabledClassNameProps}}
                      value={state}
                      fullWidth
                      size="small"
                      onChange={(text) => setState(text.target.value)}
                      variant="outlined"
                      disabled={loading}
                      className={classes.textInput}
                    />
                  </Grid>
                  <Grid item>
                    <TextField
                      {...disabledClassNameProps}
                      inputProps={{readOnly: true}}
                      InputProps={{...disabledClassNameProps}}
                      label={t('city')}
                      value={city}
                      onChange={(text) => setCity(text.target.value)}
                      fullWidth
                      variant="outlined"
                      disabled={loading}
                      size="small"
                      className={classes.textInput}
                    />
                  </Grid>
                  <Grid item>
                    <FormControl
                      variant="outlined"
                      fullWidth
                      size="small"
                      className={classes.containerPicker}
                    >
                      <InputLabel required={true} id="label-for-appliances">
                        {t('locality')}
                      </InputLabel>

                      <Select
                        label={t('locality')}
                        fullWidth
                        variant="outlined"
                        native
                        value={locality}
                        onChange={(type, index) => {
                          console.log('type1', type);
                          setLocality(type.target.value);
                          setSubLocalities(
                            localities[type.target.selectedIndex].subLocalities
                          );
                          setSubLocality(
                            localities[type.target.selectedIndex]
                              .subLocalities[0].id
                          );
                        }}
                        inputProps={{
                          name: 'age',
                          id: 'outlined-age-native-simple',
                        }}
                      >
                        {localities.map((element, index) => {
                          return (
                            <option key={index} value={element.id}>
                              {element.name}
                            </option>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Grid>

                  {/* <TextInput
                  label={t('locality')}
                  value={locality}
                  onChange={(text) => setLocality(text.target.value)}
                  fullWidth
                  variant="outlined"
                  
                  disabled={loading}
                  style={classes.textInput}
                />
                <TextInput
                  label={t('subLocality')}
                  value={subLocality}
                  onChange={(text) => setSubLocality(text.target.value)}
                  fullWidth
                  variant="outlined"
                  
                  disabled={loading}
                  style={classes.textInput}
                />
*/}
                  <Grid item>
                    <FormControl
                      fullWidth
                      variant="outlined"
                      size="small"
                      className={classes.containerPicker}
                    >
                      <InputLabel required={true} id="label-for-appliances">
                        {t('subLocality')}
                      </InputLabel>
                      <Select
                        label={t('sublocality')}
                        fullWidth
                        native
                        variant="outlined"
                        value={subLocality}
                        onChange={(type, index) => {
                          console.log('typeeee', type);
                          setSubLocality(type.target.value);
                        }}
                        inputProps={{
                          name: 'age',
                          id: 'outlined-age-native-simple',
                        }}
                      >
                        {subLocalities.map((element, index) => {
                          console.log('ele', element);
                          return (
                            <option key={index} value={element.id}>
                              {element.name}
                            </option>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item>
                    <TextField
                      label={t('address1')}
                      value={address1}
                      onChange={(text) => setAddress1(text.target.value)}
                      fullWidth
                      size="small"
                      variant="outlined"
                      disabled={loading}
                      className={classes.textInput}
                    />
                  </Grid>
                  <Grid item>
                    <TextField
                      label={`${t('address2')} (optional)`}
                      // placeholder={`${t('address2')} (optional)`}
                      value={address2}
                      onChange={(text) => setAddress2(text.target.value)}
                      fullWidth
                      size="small"
                      variant="outlined"
                      disabled={loading}
                      className={classes.textInput}
                    />
                  </Grid>
                </Grid>
              ) : null}
            </Box>
            <Grid container direction={'column'} spacing={1}>
              <Grid item>
                <FormControl
                  fullWidth
                  variant="outlined"
                  size="small"
                  className={classes.containerPicker}
                >
                  <InputLabel
                    required={true}
                    id="label-for-appliances"
                    disabled={true}
                  >
                    {t('vendorType')}
                  </InputLabel>
                  <Select
                    label={t('vendorType')}
                    fullWidth
                    native
                    variant="outlined"
                    value={selectedType}
                    disabled={true}
                    onChange={(e, index) => {
                      onCategoryChange(e, index);
                    }}
                    inputProps={{
                      name: 'age',
                      id: 'age-native-label-placeholder',
                    }}
                  >
                    {vendorTypes.map((element, index) => (
                      <option key={index} value={element.id}>
                        {element.description}
                      </option>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>

            {selectedType === 36 && (
              <Box className={classes.containerPicker}>
                <Typography component={'div'}>
                  Do you provide delivery service to
                  restaurants/grocery/chamists/food services in your local area?
                </Typography>

                <Box>
                  {deliveryPartners.length > 0 ? (
                    deliveryPartners.map((item, index) => {
                      return (
                        <Box
                          key={index}
                          onClick={() => {
                            const data = deliveryPartners;
                            data[index].isSelected = !data[index].isSelected;
                            setDeliveryPartners(data);
                            forceUpdate();
                          }}
                        >
                          <Box className={classes.itemStyle}>
                            <Typography component={'div'}>
                              {item.description}
                            </Typography>
                            <Switch
                              checked={item.isSelected}
                              onChange={(e) => {
                                handleisSelectedSwitchChange(e, index);
                              }}
                              color="primary"
                              name="checkedA"
                              inputProps={{'aria-label': 'primary checkbox'}}
                            />
                          </Box>
                        </Box>
                      );
                    })
                  ) : (
                    <Box></Box>
                  )}
                </Box>
              </Box>
            )}
            {subCategories.length > 0 && (
              <Box className={classes.containerPicker}>
                <Typography className={classes.textPicker} component={'div'}>
                  Select Sub Categories of Business
                </Typography>
                <Box className={classes.containerCategories}>
                  {subCategories.map((value, index) => {
                    return (
                      <Chip
                        className={
                          value.isSelected ? classes.chipSelected : classes.chip
                        }
                        key={index}
                        // selectedColor={value.isSelected ? 'white' : 'black'}
                        // selected={value.isSelected}
                        onClick={(e) => onPressSubCategory(e, value, index)}
                        label={value.description}
                      ></Chip>
                    );
                  })}
                </Box>
              </Box>
            )}
            {isServiceType && (
              <Box className={classes.isServiceTypeContainer}>
                <Typography
                  component={'div'}
                  onPress={() => setAutogenerateToken(!autogenerateToken)}
                  className={classes.textTitle}
                >
                  {`Would you like to autogenerate token for orders?    ${
                    autogenerateToken ? t('yes') : t('no')
                  }`}
                </Typography>
                <Switch
                  checked={autogenerateToken}
                  onChange={handleautogenerateTokenSwitchChange}
                  color="primary"
                  name="checkedA"
                  inputProps={{'aria-label': 'primary checkbox'}}
                />
              </Box>
            )}
            <Grid container direction={'column'} spacing={1}>
              <Grid item>
                <TextField
                  label={`${t('regNo')} (optional)`}
                  // placeholder={`${t('pincode')}*`}
                  fullWidth
                  size="small"
                  value={regNo}
                  inputProps={{maxLength: 20}}
                  onChange={(text) => setRegNo(text.target.value)}
                  variant="outlined"
                  disabled={loading}
                  className={classes.textInput}
                />
              </Grid>
              <Grid item>
                <TextField
                  label={`${t('gstNo')} (optional)`}
                  // placeholder={`${t('pincode')}*`}
                  fullWidth
                  size="small"
                  value={gstNo}
                  inputProps={{maxLength: 20}}
                  onChange={(text) => setGstNo(text.target.value)}
                  variant="outlined"
                  disabled={loading}
                  className={classes.textInput}
                />
              </Grid>
              <Grid item>
                <TextField
                  label={`${t('qualification')} (optional)`}
                  // placeholder={`${t('pincode')}*`}
                  fullWidth
                  size="small"
                  value={qualification}
                  inputProps={{maxLength: 20}}
                  onChange={(text) => setQualification(text.target.value)}
                  variant="outlined"
                  disabled={loading}
                  className={classes.textInput}
                />
              </Grid>
            </Grid>

            {/* <Grid
            container
            spacing={2}
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Grid item xs={6}>
              <Typography
              component={'div'}
                onPress={() => setIsHomeDelivery(!isHomeDelivery)}
                className={classes.textTitle}
              >
                {`${t('provideHomeDelivery')}    ${
                  isHomeDelivery ? t('yes') : t('no')
                }`}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Switch
                checked={isHomeDelivery}
                // onChange={handleisHomeDeliverySwitchChange}
                color="primary"
                name="checkedC"
                inputProps={{'aria-label': 'primary checkbox'}}
              />
            </Grid>
          </Grid> */}

            {isHomeDelivery && (
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <TextField
                    label={'Delivery Remarks'}
                    placeholder="We provide service/delivery within 2 kms"
                    fullWidth
                    size="small"
                    value={homeDeliveryRemarks}
                    inputProps={{maxLength: 20}}
                    onChange={(text) =>
                      setHomeDeliveryRemarks(text.target.value)
                    }
                    variant="outlined"
                    disabled={loading}
                    className={classes.textInput}
                  />
                </Grid>
              </Grid>
            )}
            <Box
              style={{display: 'flex', justifyContent: 'space-evenly'}}
              py={2}
            >
              <Button
                className={classes.btn}
                variant="outlined"
                onClick={() => history.goBack()}
              >
                Cancel
              </Button>
              <Button
                className={classes.btn}
                variant="outlined"
                color={Colors.secondary}
                disabled={reqesting || !pincodeObj}
                onClick={onPressNext}
              >
                Update
              </Button>
            </Box>
          </Box>
        )}
      </Box>
    </ErrorBoundary>
  );
};

export default EditVendorDetails;
