/* eslint-disable react/prop-types */
import {Box, Button, Grid, TextField} from '@material-ui/core';
import React, {useEffect, useState} from 'react';
import {useHistory} from 'react-router';
import AppBarName from '../../components/DumbComponents/AppBarName';
import SideNav from '../../components/sidenav/SideNav';
import WebToolbar from '../../components/toolbar/WebToolbar';
import ErrorBoundary from '../../components/ErrorBoundary';
import ErrorBoundaryFallback from '../../components/ErrorBoundaryFallback';
import useStyle from './style';
import MobileFooter from '../MobileFooter';

const LinkToNewPage = (props) => {
  const classes = useStyle();
  const [text, setText] = useState('');
  const [selectedUploadImage1, setSelectedUploadImage1] = useState();
  const [selectedUploadImage2, setSelectedUploadImage2] = useState();
  const [selectedUploadImage3, setSelectedUploadImage3] = useState();
  const [selectedUploadImage4, setSelectedUploadImage4] = useState();
  const history = useHistory();

  useEffect(() => {
    console.log('props', props);
  });

  const handleFileSelect1 = (data) => {
    if (data.target.files.length > 0) {
      setSelectedUploadImage1(window.URL.createObjectURL(data.target.files[0]));
    }
  };
  const handleFileSelect2 = (data) => {
    if (data.target.files.length > 0) {
      setSelectedUploadImage2(window.URL.createObjectURL(data.target.files[0]));
    }
  };
  const handleFileSelect3 = (data) => {
    if (data.target.files.length > 0) {
      setSelectedUploadImage3(window.URL.createObjectURL(data.target.files[0]));
    }
  };
  const handleFileSelect4 = (data) => {
    if (data.target.files.length > 0) {
      setSelectedUploadImage4(window.URL.createObjectURL(data.target.files[0]));
    }
  };

  return (
    <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
      <Box className={classes.container}>
        <WebToolbar />
        <div className={classes.sideNav}>
          <SideNav />
        </div>
        {/* <DemoPage /> */}
        <AppBarName title={'Link To New Page'} />

        <Grid container style={{padding: '5px'}}>
          <Grid
            xs={6}
            item
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <input
              accept="image/*"
              className={classes.input}
              id="contained-button-file1"
              type="file"
              multiple
              onChange={(data) => {
                handleFileSelect1(data);
              }}
            />
            <label htmlFor="contained-button-file1">
              {selectedUploadImage1 ? (
                <img src={selectedUploadImage1} width="300px" height="150px" />
              ) : (
                <Button
                  variant="outlined"
                  style={{width: '300px', height: '150px'}}
                  component="span"
                >
                  Upload an Image
                </Button>
              )}
            </label>
          </Grid>
          <Grid
            xs={6}
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <input
              accept="image/*"
              className={classes.input}
              id="contained-button-file2"
              multiple
              type="file"
              onChange={(e) => {
                handleFileSelect2(e);
              }}
            />
            <label htmlFor="contained-button-file2">
              {selectedUploadImage2 ? (
                <img src={selectedUploadImage2} width="300px" height="150px" />
              ) : (
                <Button
                  variant="outlined"
                  style={{width: '300px', height: '150px'}}
                  component="span"
                >
                  Upload an Image
                </Button>
              )}
            </label>
          </Grid>
        </Grid>
        <Grid container style={{padding: '5px'}}>
          <Grid
            xs={6}
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <input
              accept="image/*"
              className={classes.input}
              id="contained-button-file3"
              multiple
              type="file"
              onChange={(e) => {
                handleFileSelect3(e);
              }}
            />
            <label htmlFor="contained-button-file3">
              {selectedUploadImage3 ? (
                <img src={selectedUploadImage3} width="300px" height="150px" />
              ) : (
                <Button
                  variant="outlined"
                  style={{width: '300px', height: '150px'}}
                  component="span"
                >
                  Upload an Image
                </Button>
              )}
            </label>
          </Grid>
          <Grid
            xs={6}
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <input
              accept="image/*"
              className={classes.input}
              id="contained-button-file4"
              multiple
              type="file"
              onChange={(e) => {
                handleFileSelect4(e);
              }}
            />
            <label htmlFor="contained-button-file4">
              {selectedUploadImage4 ? (
                <img src={selectedUploadImage4} width="300px" height="150px" />
              ) : (
                <Button
                  variant="outlined"
                  style={{width: '300px', height: '150px'}}
                  component="span"
                >
                  Upload an Image
                </Button>
              )}
            </label>
          </Grid>
        </Grid>
        <Box p={2}>
          <TextField
            label={'Enter text'}
            value={text}
            variant="outlined"
            fullWidth
            size="small"
            multiline
            rows={2}
            inputProps={{maxLength: 150}}
            onChange={(txt) => {
              setText(txt.target.value);
            }}
            className={classes.textInput}
          />
        </Box>
        <Box style={{display: 'flex', justifyContent: 'center'}} py={2}>
          <Button
            variant="outlined"
            className={classes.button}
            onClick={() => {
              history.push({
                pathname: '/advertisementpreview',
                state: {
                  text: text,
                  selectedUploadImage: [
                    selectedUploadImage1,
                    selectedUploadImage2,
                    selectedUploadImage3,
                    selectedUploadImage4,
                  ],
                  data: props.location.state.data,
                },
              });
            }}
          >
            Next (Preview)
          </Button>
        </Box>
      </Box>
      <MobileFooter />
    </ErrorBoundary>
  );
};

export default LinkToNewPage;
