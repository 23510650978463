import React, {useEffect, useState} from 'react';
import {
  Box,
  Button,
  Divider,
  Fab,
  Grid,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
  Zoom,
  IconButton,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/DeleteForever';
import useStyle from './styles';
import AddIcon from '@material-ui/icons/Add';
import {useHistory} from 'react-router';
import getAdvertisement from '../../services/getAdvertisements';
import {useSelector} from 'react-redux';
import showToast from '../../components/Toast';
import WebToolbar from '../../components/toolbar/WebToolbar';
import AppBarName from '../../components/DumbComponents/AppBarName';
import SideNav from '../../components/sidenav/SideNav';
import FilterListIcon from '@material-ui/icons/FilterList';
import ActivityIndicator from '../../components/ActivityIndicator';
import {useTranslation} from 'react-i18next';
import ErrorBoundary from '../../components/ErrorBoundary';
import ErrorBoundaryFallback from '../../components/ErrorBoundaryFallback';
import SimpleDialog from '../../components/DumbComponents/SimpleDialog';
import MobileFooter from '../MobileFooter';

const Advertisement = () => {
  const classes = useStyle();
  const history = useHistory();
  const [ads, setAds] = useState([]);
  const [convertedAds, setConvertedAds] = useState([]);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState({selectedItem: null, open: false});
  const token = useSelector((state) => state.user.token);
  const [anchorEl, setAnchorEl] = useState(null);
  const {t} = useTranslation('advertisements');

  let [dummyads, setdummyads] = useState([
    {
      name: 'Previous Booked Advertisement',
      status: 'Active',
    },
    {
      name: 'Booked Advertisement',
      status: 'Inactive',
    },
    {
      name: 'New Advertisement',
      status: 'Inactive',
    },
    {
      name: 'New Advertisement',
      status: 'disabled',
    },
  ]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClick = (value) => {
    const _dummyAds = [...dummyads];
    let filteredData = _dummyAds.filter((ele) => {
      if (value === 'All') {
        console.log('da', dummyads);
        return dummyads;
      } else if (ele.status === value) {
        return ele;
      }
    });
    setAnchorEl(null);
    console.log('fd', filteredData);
    setdummyads(filteredData);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    getAds();
  }, []);

  async function getAds() {
    setLoading(true);
    try {
      const response = await getAdvertisement(token);
      console.log('getads response', response);
      if (response.successCode === 1) {
        setAds(response.response);
        convert(response.response);
        setLoading(false);
      } else {
        if (response.returnMessage !== 'NO_ADVERTISEMENT_FOUND_FOR_USER')
          showToast('error', t(response.returnMessage));
        setLoading(false);
        setConvertedAds([]);
      }
    } catch (error) {
      console.log('err', error);
      setLoading(false);
    }
  }

  function convert(_ads) {
    const typeHashMap = {
      0: 'promotional',
      1: 'Home page top',
      2: 'Home page bottom',
      3: 'Search result',
      4: 'Search result without image',
    };
    const levelHashmap = {
      0: 'Sublocality',
      1: 'Locality',
      2: 'Pincode',
      3: 'City',
      4: 'State',
      5: 'National',
    };

    const updatedAds = _ads.map((val) => {
      console.log('val', val);
      return {
        type: typeHashMap[val.advertisementType],
        level: levelHashmap[val.advertisementLevel],
        text: val.advertisementText,
        title: val.advertisementTitle,
        rnp: val.requireNewPage,
        image: val.advertisementImageURL,
        id: val.advBookingId,
        dateFrom: val.dateFrom,
        dateTo: val.dateTo,
        areaId: val.advertisementAreaId,
        description: val.advertisementDescription,
        stateId: val.stateId,
      };
    });

    console.log('updatedAds', updatedAds);
    setConvertedAds(updatedAds);
  }

  const onDeleteConfirmationNoClick = () => {
    setOpen({open: false, selectedItem: null});
  };

  const onDeleteConfirmationYesClick = () => {
    // Delete API call
    console.log(open);
    setOpen({open: false, selectedItem: null});
  };

  return (
    <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
      <div className={classes.container}>
        <WebToolbar />
        <div className={classes.sideNav}>
          <SideNav />
        </div>
        <AppBarName
          // backIcon={<ArrowBackIcon />}
          // onSelectBackIcon={handleBackBtn}
          title={'Advertisements'}
        />
        <Box
          p={2}
          style={{
            display: 'flex',
            width: '95%',
            justifyContent: 'flex-end',
          }}
        >
          <Zoom in={true} timeout={{enter: 500, exit: 500}} unmountOnExit>
            <Fab
              variant="extended"
              size="small"
              aria-label="add"
              className={classes.fab}
              onClick={() => {
                history.push('/createadvertisement');
              }}
            >
              <AddIcon />
              Create New Advertisement
            </Fab>
          </Zoom>
        </Box>
        {loading ? (
          <ActivityIndicator />
        ) : (
          <>
            <Box>
              {/* <Tooltip title="Sort Status By">
              <Button
                variant="outlined"
                style={{marginLeft: '70%'}}
                onClick={handleClick}
              >
                <FilterListIcon
                  className={classes.filterIconSize}
                ></FilterListIcon>
              </Button>
            </Tooltip> */}
              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem onClick={() => handleMenuClick('All')}>All</MenuItem>
                <MenuItem onClick={() => handleMenuClick('Active')}>
                  Active
                </MenuItem>
                <MenuItem onClick={() => handleMenuClick('Inactive')}>
                  Inactive
                </MenuItem>
                <MenuItem onClick={() => handleMenuClick('Disabled')}>
                  Disabled
                </MenuItem>
              </Menu>
            </Box>
            <Box p={1}>
              {convertedAds.length > 0 ? (
                <Grid
                  container
                  style={{
                    backgroundColor: '#D3D3D3',
                  }}
                >
                  <Grid
                    item
                    xs={4}
                    style={{
                      display: 'flex',
                      // justifyContent: 'center',
                    }}
                  >
                    <Typography style={{fontWeight: '900'}}>Name</Typography>
                  </Grid>
                  <Grid
                    item
                    xs={4}
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    <Typography style={{fontWeight: '900'}}>Type</Typography>
                  </Grid>
                  <Grid
                    item
                    xs={4}
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    <Typography style={{fontWeight: '900'}}>Level</Typography>
                  </Grid>
                  {/* <Grid
                    item
                    xs={3}
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    <Typography style={{fontWeight: '900'}}>Action</Typography>
                  </Grid> */}
                </Grid>
              ) : (
                ''
              )}

              {convertedAds.length > 0 ? (
                convertedAds.map((item, index) => {
                  return (
                    <>
                      <Grid container className={classes.listItem}>
                        <Grid
                          xs={4}
                          item
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            overflowWrap: 'anywhere',
                          }}
                          onClick={() => {
                            history.push({
                              pathname: '/advertisementdetailsscreen',
                              state: {item},
                            });
                          }}
                        >
                          <Typography>
                            {`${index + 1}.  ${
                              item.title ? item.title : '  -  '
                            }`}
                          </Typography>
                        </Grid>
                        <Grid
                          xs={4}
                          item
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                          onClick={() => {
                            history.push({
                              pathname: '/advertisementdetailsscreen',
                              state: {item},
                            });
                          }}
                        >
                          <Typography>{item.type}</Typography>
                        </Grid>
                        <Grid
                          xs={4}
                          item
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                          onClick={() => {
                            history.push({
                              pathname: '/advertisementdetailsscreen',
                              state: {item},
                            });
                          }}
                        >
                          <Typography>{item.level}</Typography>
                        </Grid>
                        {/* <Grid
                          xs={3}
                          item
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                           <IconButton
                            aria-label="edit"
                            disabled={true}
                            className={classes.editButton}
                            onClick={() => {
                              history.push({
                                pathname: `/advertisementdetails/${item?.id}`,
                                state: {item: item},
                              });

                              console.log('edit button click');
                            }}
                          >
                            <EditIcon />
                          </IconButton> 
                          <IconButton
                            aria-label="delete"
                            className={classes.deleteButton}
                            onClick={() => {
                              setOpen({
                                open: true,
                                selectedItem: item,
                              });
                              console.log('delete button click');
                            }}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Grid> */}
                      </Grid>
                      <Divider />
                    </>
                  );
                })
              ) : (
                <Box p={1}>
                  <Typography style={{fontWeight: '900'}}>
                    {t('NO_ADVERTISEMENT_FOUND_FOR_USER')}
                  </Typography>
                  <Divider />
                </Box>
              )}
            </Box>
          </>
        )}
        {/* <Box
        p={2}
        style={{
          display: 'flex',
          flexDirection: 'column',
          overflow: 'auto',
        }}
      >
        {convertedAds.length > 0
          ? convertedAds.map((ad, index) => {
              return (
                <>
                  <Grid container key={index}>
                    <Grid
                    item
                      xs={6}
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: 'column',
                      }}
                    >
                      <Typography>
                        <Box fontWeight="fontWeightMedium" display="inline">
                          Type :
                        </Box>
                        {ad.type}
                      </Typography>
                      <Typography>
                        <Box fontWeight="fontWeightMedium" display="inline">
                          Level :
                        </Box>
                        {ad.level}
                      </Typography>
                    </Grid>
                    <Grid
                    item
                      xs={6}
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: 'column',
                      }}
                    >
                      <Typography>
                        <Box fontWeight="fontWeightMedium" display="inline">
                          Description :
                        </Box>
                        {ad.text}
                      </Typography>
                      <Typography>
                        <Box fontWeight="fontWeightMedium" display="inline">
                          Require New Page :
                        </Box>
                        {ad.rnp.toString()}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Divider />
                </>
              );
            })
          : ''}
      </Box> */}
      </div>
      <SimpleDialog
        open={open?.open}
        onSimpleDialogClose={onDeleteConfirmationNoClick}
        message={'Are you sure to delete ?'}
        handleBasketListItemClick={onDeleteConfirmationYesClick}
      />
      <MobileFooter />
    </ErrorBoundary>
  );
};

export default Advertisement;
