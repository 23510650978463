/* eslint-disable react/prop-types */
import React, {Component, useEffect, useState} from 'react';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import {connect} from 'react-redux';
import _MonthView from './_MonthView';
import Colors from '../theme/Colors';
import getVendorAppointments from '../services/getVendorAppointments';
import getUserAppointments from '../services/getUserAppointments';
import moment from 'moment';
import {makeStyles} from '@material-ui/core/styles';
import {Box, Typography} from '@material-ui/core';
import ActivityIndicator from './ActivityIndicator';

// const screenWidth = Dimensions.get('window').width;
const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

const weekDays = [
  {day: 'Mon', date: null},
  {day: 'Tue', date: null},
  {day: 'Wed', date: null},
  {day: 'Thu', date: null},
  {day: 'Fri', date: null},
  {day: 'Sat', date: null},
  {day: 'Sun', date: null},
];
const nDays = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

const useStyle = makeStyles(() => ({
  container: {
    width: '100%',
    // height: 580,
  },
  activityIndicator: {
    height: '100%',
  },
  headerContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  btn: {
    paddingLeft: 10,
    paddingRight: 10,
  },
  textMonth: {
    fontSize: 20,
    textAlign: 'center',
    flex: 1,
    color: 'black',
  },
}));

const Calender = (props) => {
  const styles = useStyle();
  const [activeDate, setActiveDate] = useState(new Date());
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [appointments, setAppointments] = useState([]);

  useEffect(() => {
    console.log('props', props);
    getAppointments();
  }, []);

  useEffect(() => {
    generateMatrix();
  }, [appointments]);

  const getAppointments = async () => {
    try {
      const response =
        props.type === 'user'
          ? await getUserAppointments(
              props.token,
              activeDate.getFullYear(),
              activeDate.getMonth() + 1
            )
          : await getVendorAppointments(
              props.token,
              activeDate.getFullYear(),
              activeDate.getMonth() + 1
            );
      console.log('resonse', response);
      if (response.successCode === 1) {
        setAppointments(response.response.appointments);
        // generateMatrix();
      }
      generateMatrix();
    } catch (error) {
      generateMatrix();
    }
  };

  const changeMonth = (n) => {
    console.log('n', n);
    setLoading(true);
    activeDate.setMonth(activeDate.getMonth() + n);
    getAppointments();
  };

  const generateMatrix = () => {
    const matrix = [];
    matrix[0] = weekDays;
    const year = activeDate.getFullYear();
    const month = activeDate.getMonth();
    const firstDay = new Date(year, month, 0).getDay();
    let maxDays = nDays[month];
    if (month === 1) {
      if ((year % 4 === 0 && year % 100 !== 0) || year % 400 === 0) {
        maxDays += 1;
      }
    }
    let counter = 1;
    for (let row = 1; row < 7; row++) {
      matrix[row] = [];
      for (let col = 0; col < 7; col++) {
        matrix[row][col] = {
          day: '',
          item: [],
          date: null,
        };
        if (row === 1 && col >= firstDay) {
          console.log('in iff');
          const obj = {
            day: counter,
            item: [],
            date: moment(`${year}-${month + 1}-${counter}`, 'YYYY-MM-DD'),
          };
          matrix[row][col] = obj;
          counter++;
        } else if (row > 1 && counter <= maxDays) {
          console.log('in else iff');
          const _appointments = [];
          appointments.forEach((appointment) => {
            console.log('appointment', appointment);
            const appointmentDate = moment(
              `${year}-${month + 1}-${counter}`,
              'YYYY-MM-DD'
            );
            const currentDate = moment(appointment.serviceDate, 'YYYY-MM-DD');
            const isSame = appointmentDate.isSame(currentDate, 'day');
            if (isSame) {
              _appointments.push(appointment);
            }
          });
          console.log('counter', counter, '_appointments', _appointments);
          const obj = {
            day: counter,
            item: _appointments,
            date: moment(`${year}-${month + 1}-${counter}`, 'YYYY-MM-DD'),
          };
          console.log('obj', obj, 'mm', matrix);
          matrix[row][col] = obj;
          console.log('mm1', matrix);
          counter++;
        }
      }
    }
    //this.state.appointments.forEach((appointment) => {});
    console.log('matrix', matrix, 'data', data);
    setData([...matrix]);
    setLoading(false);
  };

  return (
    <div>
      <Box className={styles.container}>
        <Box>
          <Box className={styles.headerContainer}>
            <Box
              className={styles.btn}
              disabled={loading}
              onClick={() => changeMonth(-1)}
            >
              <ArrowLeftIcon />
            </Box>
            <Typography className={styles.textMonth}>
              {months[activeDate.getMonth()]} &nbsp;
              {activeDate.getFullYear()}
            </Typography>
            <Box
              className={styles.btn}
              disabled={loading}
              onClick={() => changeMonth(+1)}
            >
              <ArrowRightIcon />
            </Box>
          </Box>
          {loading ? (
            <ActivityIndicator
              color={Colors.secondary}
              className={styles.activityIndicator}
              size="large"
            />
          ) : (
            <_MonthView data={data} />
          )}
        </Box>
      </Box>
    </div>
  );
};

const mapStateToProps = (state) => ({
  token: state.user.token,
});

export default connect(mapStateToProps)(Calender);
