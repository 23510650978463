import {makeStyles} from '@material-ui/styles';

const useStyle = makeStyles((theme) => ({
  container: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    backgroundColor: 'white',
    paddingBottom: '100px',
    width: '80%',
    margin: 'auto',
    '& > header > header': {
      position: 'inherit',
      // display: 'flex',
      // justifyContent: 'center',
      // width: '80%',
    },
  },
  sideNav: {
    ['@media (max-width:700px)']: {
      display: 'none',
    },
    //height: '100%',
    width: '92px',
    position: 'fixed',
    zIndex: 1,
    top: 0,
    // right: 0,
    right: '15px',
    overflowX: 'hidden',
    marginTop: 190,
    // marginRight: theme.spacing(1),
    marginRight: 0,
    backgroundColor: '#3B3B3B',
    elevation: 2,
  },
  _container: {
    padding: 10,
    height: '100%',
    backgroundColor: 'white',
  },
  textInput: {
    marginTop: 5,
    marginBottom: 5,
    borderRadius: 5,
    flex: 2,
    backgroundColor: 'white',
  },
  containerPicker: {
    margin: 5,
    flex: 2,
    paddingVertical: 2,
    borderWidth: 1,
    borderColor: 'gray',
    borderRadius: 4,
    marginTop: 10,
  },
  picker: {
    //width: width - 40,
  },
  textPicker: {
    color: '#989898',
    position: 'absolute',
    top: -10,
    left: 0,
    marginHorizontal: 10,
    paddingHorizontal: 1,
    backgroundColor: 'white',
    zIndex: 10,
    fontSize: 12,
  },
  containerInput: {
    flexDirection: 'row',
  },
  btn: {
    marginTop: 10,
    borderRadius: 5,
    marginBottom: 10,
  },
  textUpiId: {
    fontSize: 16,
    color: 'black',
    fontWeight: '600',
  },
  textNote: {
    fontSize: 14,
    color: 'red',
    marginVertical: 20,
    textAlign: 'justify',
  },
  activityIndicator: {
    height: '90%',
  },
}));

export default useStyle;
