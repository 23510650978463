import React, {useEffect, useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {Typography, Button, Box} from '@material-ui/core';
import WebToolbar from '../../components/toolbar/WebToolbar';
import SideNav from '../../components/sidenav/SideNav';
import VendorSearchbar from '../../components/VendorSearchbar';
import ActivityIndicator from '../../components/ActivityIndicator';
import searchVendor from '../../services/searchVendor';
import __getVendorDetails from '../../services/getVendorDetails';
import getVendorByCategoryId from '../../services/getVendorByCategoryId';
import VendorList from '../../components/VendorList';
import useStyles from './styles';
import {setShowPincodeModal, setVendors} from '../../redux/actions/user';
import showToast from '../../components/Toast';
import {useHistory} from 'react-router';
import AppBarName from '../../components/DumbComponents/AppBarName';
import VendorSearchbarByCatagory from '../../components/VendorSearchbarByCatagory';
import MobileFooter from '../MobileFooter';

const VendorsScreen = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [vendors, setVendors] = useState([]);
  const token = useSelector((state) => state.user.token);
  let categoryId = '';
  const url = window.location.href.split('vendors/');
  categoryId = url[1];
  const [width, setWidth] = useState(window.innerWidth);
  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    _getVendorByCategoryId();
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  async function _getVendorByCategoryId() {
    try {
      const url = window.location.href.split('vendors/');
      categoryId = url[1];
      setLoading(true);
      const response = await getVendorByCategoryId(token, categoryId, '');
      console.log('vendor fetched', response);
      if (response.successCode === 1) {
        console.log('error in if');
        setVendors(response.response.customerVendors);
        setLoading(false);
      } else {
        if (!response.returnMessage === 'NO_MATCHING_VENDOR_FOUND') {
          history.push('/login');
          setLoading(false);
        }
        // showToast('error', 'Login Expired');

        console.log('error in else else', response.messageCode);
        setLoading(false);
      }
    } catch (error) {
      console.log('error', error);
      setLoading(false);
    }
  }

  return (
    <div className={classes.container}>
      <WebToolbar />
      <AppBarName title={'Vendors'} />
      <div className={classes.sideNav}>{width >= 768 && <SideNav />}</div>
      <Box
        py={1}
        style={{
          display: 'flex',
        }}
      >
        <Box style={{width: '100%'}}>
          <VendorSearchbarByCatagory categoryId={categoryId} />
        </Box>
      </Box>
      <div className={classes.content}>
        {loading ? (
          <ActivityIndicator />
        ) : (
          <>
            <div className={classes.main}>
              {vendors.length > 0 ? (
                <VendorList vendors={vendors} />
              ) : (
                <Box p={1}>
                  <Typography noWrap className={classes.title}>
                    {"No Vendor's Found..."}
                  </Typography>
                </Box>
              )}
            </div>
          </>
        )}
      </div>
      <MobileFooter />
    </div>
  );
};

export default VendorsScreen;
