import {makeStyles} from '@material-ui/styles';

const useStyle = makeStyles((theme) => ({
  root: {flexGrow: 1, backgroundColor: theme.palette.background.paper},
  container: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    paddingBottom: '100px',
    backgroundColor: 'white',
    width: '80%',
    margin: 'auto',
    '& > header > header': {
      position: 'inherit',
      // display: 'flex',
      // justifyContent: 'center',
      // width: '80%',
    },
  },
  sideNav: {
    ['@media (max-width:700px)']: {
      display: 'none',
    },
    //height: '100%',
    width: '92px',
    position: 'fixed',
    zIndex: 1,
    top: 0,
    // right: 0,
    right: '15px',
    overflowX: 'hidden',
    marginTop: 190,
    // marginRight: theme.spacing(1),
    marginRight: 0,
    backgroundColor: '#3B3B3B',
    elevation: 2,
  },
  tabWidth: {
    minWidth: '50%',
  },

  appBarStyle: {backgroundColor: '#0277BD'},

  sendMsgBtnContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginLeft: '5%',
  },
  msgNtxtContainer: {
    position: 'absolute',
    bottom: 0,
    width: '97%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  messagesTabPanel: {height: '400px', position: 'relative'},
  txtIpContainer: {width: '100%', display: 'flex'},
  msgContainer: {width: '100%', height: '350px', overflow: 'auto'},

  fab: {
    position: 'absolute',
    bottom: 20,
    right: 20,
  },
  input: {
    display: 'none',
  },
  btnContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  qtyIncDec: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-evenly',
  },
  priceContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  nameContainer: {display: 'flex', alignItems: 'center'},
  itemsTabPanel: {height: '400px', position: 'relative'},
}));

export default useStyle;
