import baseUrl from '../utils/Constants';

export default async function (token) {
  const response = await fetch(`${baseUrl}/application/contact_us/reasons`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });
  const responseJson = await response.json();
  return responseJson;
}
