import './style.css';
import React from 'react';
import Button from '@material-ui/core/Button';
import MobileStepper from '@material-ui/core/MobileStepper';
import Paper from '@material-ui/core/Paper';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import Typography from '@material-ui/core/Typography';
import {useTheme} from '@material-ui/core/styles';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import {Carousel} from 'react-responsive-carousel';
import PropTypes from 'prop-types';
import {Box} from '@material-ui/core';

const ImageSlider = ({images, onImageClick}) => {
  const theme = useTheme();
  const [index, setActiveStep] = React.useState(0);

  const goToNextPicture = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  return (
    <Box
      style={{
        marginTop: '8px',
        borderRadius: '4px',
      }}
    >
      <Carousel
        autoPlay
        interval={4000}
        infiniteLoop
        showArrows={false}
        showStatus={false}
        showThumbs={false}
      >
        {images.map((item, index) => (
          <Box key={index} className="image" onClick={() => onImageClick(item)}>
            <img src={item.advertisementImageURL} />
          </Box>
        ))}
      </Carousel>
    </Box>
  );
};

ImageSlider.propTypes = {
  images: PropTypes.array,
  onImageClick: PropTypes.func,
};

export default ImageSlider;
