import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container_one: {
    display: 'flex',
    flexDirection: 'column',
  },
  org_name_txt: {
    fontSize: '20px',
    fontWeight: '600',
  },
  categories_container: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
  },
  categories_btn: {
    width: '140px',
    margin: 3,
    borderRadius: 15,
    backgroundColor: '#EAF2F5',
  },
  imgContainer: {
    width: '80%',
    height: '96px',
    ['@media (max-width:960px)']: {
      width: '100%',
      backgroundColor: 'yellow',
    },
  },
  vendor_detail_container: {
    display: 'flex',
    alignItems: 'center',
  },
  vendor_detail_title: {
    fontSize: '13px',
    // fontWeight: '600',
  },
  vendor_detail_value: {fontSize: '15px', fontWeight: '600'},
  media: {
    width: '100%',
    height: '100%',
  },
  title: {
    // border: '1px solid red',
    display: 'flex',
    alignItems: 'center',
    fontSize: 20,
    fontWeight: 500,
    height: '30%',
    position: 'absolute',
    bottom: 20,
    backgroundColor: 'rgb(0, 0, 0, 0.5)',
    width: '100%',
    color: '#fff',
    padding: 4,
  },
  bgTitle: {backgroundColor: '#0277bd ', color: '#ffffff', width: '100%'},
  root: {
    // padding: '1% 8.2%',
    padding: '5px',
    // border: 'solid',
  },
  container: {
    width: '100%',
    // marginTop: theme.spacing(2),
    // marginBottom: theme.spacing(2),
  },
  textName: {
    fontSize: 18,
    padding: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
    color: 'white',
    borderRadius: 4,
    width: '96%',
  },
  card: {
    alignItems: 'center',
    padding: 0,
    justifyContent: 'center',
    position: 'relative',
    aspectRatio: 1.5,
  },
  actionArea: {
    padding: 0,
    margin: 0,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    height: '100%',
    color: theme.palette.text.secondary,
  },
  sideNav: {
    //height: '100%',
    width: '92px',
    position: 'fixed',
    zIndex: 1,
    top: 0,
    right: 0,
    overflowX: 'hidden',
    marginTop: 190,
    // marginRight: theme.spacing(1),
    marginRight: 0,
    backgroundColor: '#3B3B3B',
    elevation: 2,
  },
  main: {
    marginTop: 70,
    //marginRight: '180px',
  },
  content: {
    padding: theme.spacing(2),
  },
  vendorDetails: {
    marginTop: 10,
    boxShadow:
      'rgb(0 0 0 / 20%) 0px 3px 3px -2px, rgb(0 0 0 / 14%) 0px 3px 4px 0px, rgb(0 0 0 / 12%) 0px 1px 8px 0px',
    ['@media (max-width:960px)']: {
      // width: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
  },
  order_now_button_grid: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  order_now_button: {
    backgroundColor: 'green',
    fontSize: '14px',
    color: 'white',
    width: '150px',
    height: '45px',
    ['@media (max-width:950px)']: {
      width: '50px',
      height: '35px',
      fontSize: '10px',
    },
  },
}));

export default useStyles;
