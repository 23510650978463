/* eslint-disable react/prop-types */
import React, {useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import {useDispatch} from 'react-redux';
import pincodeSearch from '../services/pincode';
import {setPincode as setPincodeObj} from '../redux/actions/user';
import {toast} from 'react-toastify';

const useStyles = makeStyles((theme) => ({
  dialog: {
    padding: theme.spacing(1),
    justifyContent: 'center',
    alignSelf: 'center',
    alignItems: 'center',
  },
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
  title: {
    color: '#fff',
  },
  text: {
    color: '#fff',
    textAlign: 'justify',
  },
  button: {
    marginTop: theme.spacing(1),
  },
  form: {
    width: '100%',
    flex: 1,
  },
  input: {
    margin: theme.spacing(1, 0, 1, 0),
  },
  containerDetails: {
    width: '100%',
  },
  formControl: {
    margin: theme.spacing(0),
    width: '100%',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export default function PincodeModal({visible, hide}) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const classes = useStyles();
  const [pincode, setPincode] = useState('');
  const [searching, setSearching] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [area, setArea] = useState(null);
  const [subLocality, setSubLocality] = useState(null);

  async function search(event) {
    setPincode(event.target.value);
    if (event.target.value.length !== 6) {
      return;
    }
    setSearching(true);

    try {
      const response = await pincodeSearch(event.target.value);
      console.log('pincode response', response);
      setSearching(false);
      if (response.successCode === 1) {
        setArea(response.response.area);
        setErrorMessage('');
      } else {
        setArea(null);
        setErrorMessage(response.returnMessage);
      }
      // console.log(response);
    } catch (error) {
      setSearching(false);
      setArea(null);
      console.log(error);
    }
  }

  return (
    <div>
      <Dialog
        onClose={hide}
        aria-labelledby="customized-dialog-title"
        open={visible}
        fullWidth={true}
        classes={{
          paper: classes.dialog,
        }}
      >
        <DialogTitle style={{padding: '5px'}} id="form-dialog-title">
          Enter your Pin Code
        </DialogTitle>
        <form className={classes.form} noValidate>
          <TextField
            type="number"
            placeholder="Pincode"
            label="Pincode"
            fullWidth
            name="pincode"
            variant="outlined"
            onInput={(e) => {
              e.target.value = Math.max(0, parseInt(e.target.value))
                .toString()
                .slice(0, 6);
            }}
            value={pincode}
            onChange={(event) => {
              search(event);
            }}
            required
            error={errorMessage}
            helperText={errorMessage}
            autoFocus
            //disabled={searching}
            className={classes.input}
          />
        </form>

        {area && (
          <div className={classes.containerDetails}>
            {/* <Typography>{`State: ${area.state.name}`}</Typography>
            <Typography>{`City: ${area.city.name}`}</Typography> */}
            <FormControl className={classes.formControl}>
              <InputLabel id="demo-simple-select-label">
                Sub Locality
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={subLocality}
                onChange={(e) => setSubLocality(e.target.value)}
              >
                {area.localities[0].subLocalities.map((subLoc, index) => (
                  <MenuItem key={index} value={subLoc.id}>
                    {subLoc.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        )}

        <Grid className={classes.button} container spacing={1}>
          <Grid item xs={6} md={6} sm={6}>
            <Button
              variant="outlined"
              type="button"
              style={{width: '100%'}}
              disabled={searching}
              onClick={(e) => {
                e.preventDefault();
                hide();
              }}
            >
              <Typography>{`Cancel`}</Typography>
            </Button>
          </Grid>

          <Grid item xs={6} md={6} sm={6}>
            <Button
              variant="outlined"
              type="submit"
              disabled={area === null || subLocality === null}
              style={{width: '100%'}}
              onClick={(e) => {
                dispatch(
                  setPincodeObj({
                    city: area.city,
                    state: area.state,
                    locality: {
                      id: area.localities[0].id,
                      name: area.localities[0].name,
                      pinCodeId: area.localities[0].pinCodeId,

                      pincode: pincode,
                    },
                    subLocality: {
                      id: subLocality,
                    },
                  })
                );
                hide();
              }}
            >
              <Typography>Submit</Typography>
            </Button>
          </Grid>
        </Grid>
      </Dialog>
    </div>
  );
}
