import {get} from 'lodash';

export const getAddress = (addressDetails, getPrefix = true) => {
  if (!addressDetails) {
    return '';
  }
  let address = 'Address - ';
  if (!getPrefix) {
    address = '';
  }
  let _addressDetails = '';
  if (addressDetails?.hasOwnProperty('state')) {
    _addressDetails = addressDetails;
  } else if (addressDetails?.vendorAddress) {
    _addressDetails = addressDetails?.vendorAddress;
  } else if (addressDetails?.address) {
    _addressDetails = addressDetails?.address;
  } else if (addressDetails?.customerAddress) {
    _addressDetails = addressDetails?.customerAddress;
  }
  if (!_addressDetails) {
    return '';
  }

  let addrLine1 = get(_addressDetails, 'addressLine1', 'NA');
  if (addrLine1) {
    address += addrLine1;
  }
  let addrLine2 = get(_addressDetails, 'addressLine2', 'NA');
  if (addrLine2) {
    address += ', ' + addrLine2;
  }
  let locality = get(_addressDetails, 'locality', 'NA');
  if (locality) {
    address += ', ' + locality;
  }
  let subLocality = get(_addressDetails, 'subLocality', 'NA');
  if (subLocality) {
    address += ', ' + subLocality;
  }
  let city = get(_addressDetails, 'city', 'NA');
  if (city) {
    address += ', ' + city;
  }
  let state = get(_addressDetails, 'state', 'NA');
  if (state) {
    address += ', ' + state;
  }
  let pinCode = get(_addressDetails, 'pinCode', 'NA');
  if (pinCode) {
    address += ', ' + pinCode;
  }
  return address;
};

export const constants = {
  ORDER_PLACED: 'ORDER_PLACED',
  ORDER_RECEIVED: 'ORDER_RECEIVED',
  PAYMENT_REQUEST_CREATED: 'PAYMENT_REQUEST_CREATED',
  PAYMENT_REQUEST_RECEIVED: 'PAYMENT_REQUEST_RECEIVED',
};
