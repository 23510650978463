/* eslint-disable react/prop-types */
import {
  Box,
  Button,
  Checkbox,
  Divider,
  TextField,
  Typography,
} from '@material-ui/core';
import React, {useEffect, useState} from 'react';
import {useHistory} from 'react-router';
import {useSelector} from 'react-redux';
import AppBarName from '../../components/DumbComponents/AppBarName';
import WebToolbar from '../../components/toolbar/WebToolbar';
import useStyle from './style';
import ActivityIndicator from '../../components/ActivityIndicator';
import Colors from '../../theme/Colors';
import showToast from '../../components/Toast';
import getCurrentVendor from '../../services/getCurrentVendor';
import updateOrderStatus from '../../services/updateOrderStatus';
import {get} from 'lodash';
import SideNav from '../../components/sidenav/SideNav';
import MobileFooter from '../MobileFooter';
import {getAddress} from '../../utils/common';

const DeliverOrderScreen = (props) => {
  const classes = useStyle();
  const history = useHistory();

  // eslint-disable-next-line react/prop-types
  const {item, orderId, res, getData, orderAmt} = props.location.state;
  const [loading, setLoading] = useState(false);
  const user = useSelector((state) => state.user.user);
  const token = useSelector((state) => state.user.token);
  const [vendors, setVendors] = useState([]);
  const forceUpdate = useState()[1].bind(null, {});
  const [reqesting, setReqesting] = useState(false);
  const [comment, setComment] = useState('');
  // const vendorId = useSelector((state) => state.user.vendorId);
  // const orderId = useSelector((state) => state.user.orderId);

  useEffect(() => {
    getVendor();
  }, []);

  async function getVendor() {
    setLoading(true);
    try {
      const response = await Promise.all([getCurrentVendor(token)]);
      //   console.log('response' JSON.stringify(response[0].response.deliveryPartners));
      console.log('response', response);

      if (response[0].successCode === 1) {
        //const data = response[1].response;
        //console.log(JSON.stringify(data));
        if (response[0].response.deliveryPartners) {
          setVendors(response[0].response.deliveryPartners);
        }
        setLoading(false);
      } else {
        history.goBack();
        showToast('error', response[0].returnMessage);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  }

  async function update() {
    try {
      const selected = vendors.filter((vendor) => vendor.isSelected);
      if (selected.length === 0) {
        showToast('Please select delivery partner', 'error');
        return;
      }
      const payload = {
        OrderID: orderId,
        VendorId: res.vendorId,
        CustomerId: res.userId,
        UserId: res.userId,
        Comment: comment,
        DeliveryVendorId: selected.map((a) => a.vendorId)[0],
        OrderStatus: item.nextState,
      };
      setReqesting(true);
      const response = await updateOrderStatus(payload, token);
      setReqesting(false);
      if (response.successCode === 1) {
        showToast('Order updated', 'success');
        history.goBack();
        // getData();
      } else {
        showToast('error', response.returnMessage);
      }
    } catch (error) {
      console.log(error);
      showToast('error', 'Failed');
      console.log(error);
      setReqesting(false);
    }
  }

  return (
    <>
      <div className={classes.container}>
        <WebToolbar />
        <div className={classes.sideNav}>
          <SideNav />
        </div>
        <AppBarName title={'Deliver Order'} />
        <Box>
          {loading ? (
            <ActivityIndicator />
          ) : (
            <Box>
              {vendors.length > 0
                ? vendors.map((item, index) => {
                    return (
                      <Box px={1} key={index}>
                        <Box className={classes.itemStyle}>
                          <Box style={{flex: 1}}>
                            <Typography className={classes.textName}>
                              {item.organizationName}
                            </Typography>
                            <Typography className={classes.textAddress}>
                              {getAddress(item)}
                            </Typography>
                          </Box>
                          <Checkbox
                            status={item.isSelected ? 'checked' : 'unchecked'}
                            thumbColor={Colors.primaryColor}
                            size="small"
                            //value={item.isSelected}
                            onChange={(value) => {
                              const data = [...vendors];
                              data[index].isSelected = !data[index].isSelected;
                              setVendors(data);
                              // forceUpdate();
                            }}
                          />
                        </Box>
                        <Divider />
                      </Box>
                    );
                  })
                : ''}
              <Box p={1}>
                <TextField
                  label="Comments"
                  value={comment}
                  fullWidth
                  size="small"
                  onChange={(text) => setComment(text.target.value)}
                  variant="outlined"
                  numberOfLines={1}
                  disabled={loading}
                  className={classes.textInput}
                />
              </Box>

              <Box p={1} style={{display: 'flex', justifyContent: 'center'}}>
                <Button
                  className={classes.btn}
                  variant="outlined"
                  disabled={reqesting}
                  onClick={() => update()}
                >
                  Update
                </Button>
              </Box>
            </Box>
          )}
        </Box>
      </div>
      <MobileFooter />
    </>
  );
};

export default DeliverOrderScreen;
