/* eslint-disable react/prop-types */
import React, {useState, useEffect, useCallback} from 'react';
import {
  Box,
  Typography,
  Button,
  Tabs,
  Tab,
  TextField,
  AppBar,
  Grid,
  Checkbox,
  Avatar,
  Fab,
  Divider,
} from '@material-ui/core';
import CallIcon from '@material-ui/icons/Call';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import PermIdentityOutlinedIcon from '@material-ui/icons/PermIdentityOutlined';
import SideNav from '../../components/sidenav/SideNav';
import {debounce, get, isEmpty, lowerCase} from 'lodash';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import {useSelector, useDispatch} from 'react-redux';
import WebToolbar from '../../components/toolbar/WebToolbar';
import ActivityIndicator from '../../components/ActivityIndicator';
import useStyles from './styles';
import {useHistory} from 'react-router-dom';
import getSearchDataService from '../../services/getSearchDataService';
import {setSelectedCartItems, setVendorDetails} from '../../redux/actions/user';
import getVendorDetails from '../../services/getVendorDetails';
import showToast from '../Toast';
import AppBarName from '../DumbComponents/AppBarName';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import BottomDialog from '../DumbComponents/BottomDialog';
import {a11yProps, TabPanel} from '../DumbComponents/TapPanelProps';
import SimpleAutocomplete from '../../components/DumbComponents/SimpleAutocomplete';
import MobileFooter from '../../pages/MobileFooter';
import {getAddress} from '../../utils/common';

const modalText = 'You can send an Order Request by multiple ways:';
const point1 = '1. By selecting items from Vendor Catalogue:';
const point2 =
  '2. Even if an item is not present in Vendor Catalogue you can still include it in your Order Request by searching it in ‘Search items’ are clicking it if found or add it by clicking ‘+’ button.';
const point3 = '3. Through Message/chat or through Photo or attachment.';
const point4 =
  'A Vendor on receiving an Order Request can Accept/Reject and specify the cost of Order Request.';

const PlaceOrder = (props) => {
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();
  const token = useSelector((state) => state.user.token);
  const selectedVendorForOrder = useSelector((state) => {
    return state.user.selectedVendorForOrder;
  });
  const [items, setItems] = useState([]);
  const vendorDetails = useSelector((state) => state.user.vendorDetails);
  // const [vendorDetails, setVendorDetails] = useState();
  const [hours, setHours] = useState('');
  const [number, setNumber] = useState('');
  const [vendorAddress, setVendorAddress] = useState('');
  const [searchItem, setSearchItem] = useState('');
  const [searchResult, setSearchResult] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searching, setSearching] = useState(false);
  const [value, setValue] = useState(0);
  const [imageUrl, setImageUrl] = useState('');
  const [deliveryRemarksHint, setDeliveryRemarksHint] = useState('');
  const [vendorFor, setVendorFor] = useState(0);
  const [vendorName, setVendorName] = useState('');
  const [reviews, setReviews] = useState([]);
  const [avgReview, setAvgReview] = useState(0);
  const [data, setData] = useState([]);
  const [currentTab, setCurrentTab] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [query, setQuery] = useState('');
  const [savedOrder, setSavedOrder] = useState(null);

  const [, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({}), []);

  const [addItemButton, setAddItemButton] = useState(true);

  const selectedCartItems = useSelector((state) => {
    return state.user.selectedCartItems;
  });
  const user = useSelector((state) => {
    return state.user.user;
  });

  // const [selectedCartItems, setSelectedCartItems] = useState([]);
  const [vendorImages, setVendorImages] = useState([]);

  const [messagesState, setMessagesState] = useState({
    messages: [],
  });
  const [userMessage, setUserMessage] = useState('');
  const [showCameraDialog, setShowCameraDialog] = useState(false);
  const [cameraOn, setCameraOn] = useState(false);

  useEffect(() => {
    _getVendorDetails();
  }, []);

  const handleClickOpen = (e) => {
    e.preventDefault();
    if (selectedVendorForOrder.vendor.vendorId) {
      history.push('/vendordetails/' + selectedVendorForOrder.vendor.vendorId);
    }
  };

  const IncNum = (item_id, index) => {
    let _selectedItems = [...selectedCartItems];
    _selectedItems[index].count = _selectedItems[index].count + 1;
    // setSelectedCartItems(_selectedItems);
    dispatch(setSelectedCartItems(_selectedItems));
  };
  const DecNum = (item_id, index) => {
    let _selectedItems = [...selectedCartItems];
    if (_selectedItems[index].count === 1) {
      _selectedItems.splice(index, 1);
    } else {
      //_selectedItems[index].count = _selectedItems[index].count - 1;
      _selectedItems[index].count =
        _selectedItems[index].count > 0 ? _selectedItems[index].count - 1 : 0;
    }
    // setSelectedCartItems(_selectedItems);
    dispatch(setSelectedCartItems(_selectedItems));
  };

  const handleCheckChange = (event, index) => {
    const _items = [...items]; //copy of object
    _items[index] = {
      //index value manipulation
      ..._items[index],
      isSelected: get(event, 'target.checked', false),
    };
    setItems(_items);

    const isSelectedItem = _items.find((item) => {
      return item.isSelected;
    });
    if (isSelectedItem) {
      setAddItemButton(false);
    }
  };

  const addItemsToCart = () => {
    let _selectedCartItems = [...selectedCartItems];
    let isItemPresentInCart = false;
    items.forEach((item, index) => {
      const _item = _selectedCartItems.filter((ci) =>
        ci.id ? ci.id === item.id : ci.name === item.name
      );
      if (item.isSelected) {
        if (_item.length === 0) {
          _selectedCartItems.push({...item, count: 1});
        }
      } else {
        if (!item.isSelected && _item.length > 0) {
          _selectedCartItems = _selectedCartItems.filter((si) =>
            si.id ? si.id !== _item.id : si.name !== _item.name
          );
        }
      }
      if (
        item.isSelected &&
        _item.length > 0 &&
        isItemPresentInCart === false
      ) {
        isItemPresentInCart = true;
      }
    });
    // if (isItemPresentInCart) {
    //   showToast('warn', 'Few selected items are already present in the order');
    // }

    // setSelectedCartItems(_selectedCartItems);
    dispatch(setSelectedCartItems(_selectedCartItems));
  };

  const [width, setWidth] = useState(window.innerWidth);
  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  let obj = {isSelected: false};

  if (obj.isSelected) {
  }
  function setVendorHours(workingHours, workingDays) {
    try {
      if (workingHours) {
        const hrs = workingHours ? workingHours.split(',') : [];
        const days = workingDays ? workingDays.split(',') : [];
        const today = new Date().getDay();
        if (days[today] === '1') {
          setHours(hrs[today]);
        } else {
          setHours('Closed');
        }
      } else {
        setHours('Information not available');
      }
    } catch (error) {
      setHours('Information not available');
      console.log(error);
    }
  }

  async function _getVendorDetails() {
    setLoading(true);
    try {
      // const vendorId = props.location.state.vendorId;
      const vendorId = selectedVendorForOrder.vendor.vendorId;
      const response = await getVendorDetails(token, vendorId);
      if (response.successCode === 1) {
        dispatch(setVendorDetails(response.response));
        setItems(response.response.vendorItems);
        localStorage.setItem('data_order_items', JSON.stringify(response));
        setVendorImages(response.response.vendorHeaderImage);
        localStorage.setItem('data_images', JSON.stringify(response));
        setVendorHours(
          response.response.workingHours,
          response.response.workingDays
        );
        setDeliveryRemarksHint(
          response.response.homeDeliveryRemarks
            ? response.response.homeDeliveryRemarks
            : ''
        );
        setVendorFor(response.response.vendorFor);
        setVendorName(response.response.organizationName);
        setVendorAddress(response.response.vendorAddress);
        setNumber(response.response.secondaryMobiles[0].secondaryMobile);

        if (response.response.vendorHeaderImage) {
          setImageUrl(response.response.vendorHeaderImage);
        }
        setReviews(response.response.reviews);
        calculateAvgReview(response.response.reviews);
        if (props.location.state.messages) {
          setData(props.location.state.messages);
          setCurrentTab(1);
          forceUpdate();
        }
        setLoading(false);
      } else {
        showToast('error', 'Failed to get vendor details');
        history.replace('/home');
        // setVendors([]);
        setLoading(false);
      }
    } catch (error) {
      console.log('error image', error);
      let collection = localStorage.getItem('data_order_items');
      let data = JSON.parse(collection);
      setVendorImages(data.response.vendorHeaderImage);
      setLoading(false);
    }
  }
  function calculateAvgReview(res) {
    try {
      if (res.length > 0) {
        let total = 0;
        res.forEach((d) => {
          total = total + d.rating;
        });
        if (total > 0) {
          const avg = total / res.length;
          setAvgReview(avg);
        } else {
          setAvgReview(0);
        }
      } else {
        setAvgReview(0);
      }
    } catch (error) {
      setAvgReview(0);
    }
  }

  async function onChangeText(text) {
    setSearching(true);
    setQuery(text);
    if (text.length > 0) {
      handler(text);
    } else {
      setSearchResult([]);
      setQuery('');
      setLoading(false);
      setSearching(false);
    }
  }

  const handler = useCallback(
    debounce(async (text) => {
      if (!text.trim()) {
        return;
      }
      try {
        const vendorId = selectedVendorForOrder.vendor.vendorId;
        const response = await getSearchDataService(token, text, vendorId);

        if (response.successCode === 1) {
          const data = response.response;
          setSearchResult(data);
          setSearching(false);
        } else {
          const data = [];
          setSearchResult(data);
          setSearching(false);
        }
        setLoading(false);
        setSearching(false);
      } catch (error) {
        setLoading(false);
        setSearching(false);
      }
    }, 500),
    []
  );

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleInfoClick = () => {
    setShowModal(!showModal);
  };
  const closeModalFunc = () => {
    setShowModal(false);
  };

  function addTextItem() {
    if (!query.trim()) {
      showToast('warn', 'Please enter item name');
      return;
    }
    const data = items;
    data.push({
      name: query,
      price: 0,
      selected: true,
      quantity: 1,
    });
    setItems(data);
    setQuery('');
    forceUpdate();
  }

  function R() {
    // if (message.trim()) {
    //   const d = data;
    //   const obj = {
    //     message: message,
    //     createdBy: user.userId,
    //     createdOn: new Date(),
    //   };
    //   if (savedOrder) {
    //     database.write(() => {
    //       savedOrder.orderMessages.push({
    //         id: genrateId(),
    //         message: message,
    //         createdBy: `${user.userId}`,
    //         createdOn: new Date().toString(),
    //       });
    //       database.create('order', savedOrder, true);
    //     });
    //   } else {
    //     d.push(obj);
    //     setData(d);
    //   }
    //   setMessage('');
    //   forceUpdate();
    // }
  }

  async function addAttachment(e) {
    try {
      const res = e.target.files[0];
      if (res.type.includes('image') || res.type.includes('pdf')) {
      } else {
        console.log('Invalid document. Please select image or pdf file only');
        return;
      }
      const d = data;

      if (savedOrder) {
        // database.write(() => {
        //   savedOrder.orderMessages.push({
        //     id: genrateId(),
        //     message: '',
        //     name: res.name,
        //     uri: res.uri,
        //     type: res.type,
        //     messageType: 1,
        //     createdBy: `${user.userId}`,
        //     createdOn: new Date().toString(),
        //   });
        //   database.create('order', savedOrder, true);
      } else {
        const obj = {
          message: userMessage,
          createdBy: user.userId,
          createdOn: new Date(),
          messageType: 1,
          name: res.name,
          uri: res.webkitRelativePath,
          type: res.type,
        };
        d.push(obj);
        setData(d);
      }

      forceUpdate();
      // onDocument({
      //   path: res.uri,
      //   name: res.name,
      //   mime: res.type,
      // });
    } catch (err) {
      console.log(err);
    }
  }

  const getVendorHeaderDetails = () => {
    return (
      <Grid container className={classes.venDetailContainer}>
        <Grid xs={2} item className={classes.imgContainer}>
          {imageUrl ? (
            <img
              src={imageUrl}
              className={classes.media}
              onClick={handleClickOpen}
              alt=""
            />
          ) : (
            <Avatar className={classes.avatarStyle} onClick={handleClickOpen}>
              <PermIdentityOutlinedIcon style={{fontSize: '35px'}} />
            </Avatar>
          )}
        </Grid>
        <Grid xs={10} item className={classes.detailsContainer}>
          <Box
            style={{
              display: 'flex',
              alignItems: 'center',
              width: '100%',
            }}
          >
            <Box
              style={{
                width: '20%',
              }}
            >
              <Typography
                component={'span'}
                style={{fontWeight: '900', fontSize: '16px'}}
              >
                {get(vendorDetails, 'organizationName', 'NA')}
              </Typography>
            </Box>
            <Box
              style={{
                display: 'flex',
                alignItems: 'center',
                width: '20%',
              }}
            >
              <AccessTimeIcon
                style={{
                  fontSize: '14px',
                }}
              />
              {hours}
            </Box>
            <Box
              style={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
                width: '25%',
              }}
            >
              <Box>
                <CallIcon style={{fontSize: '14px'}} />{' '}
                <a
                  href={`tel:${get(
                    vendorDetails,
                    'secondaryMobiles[0].secondaryMobile',
                    'NA'
                  )}`}
                >
                  {get(
                    vendorDetails,
                    'secondaryMobiles[0].secondaryMobile',
                    'NA'
                  )}
                </a>
              </Box>
              {get(vendorDetails, 'secondaryMobiles[1].secondaryMobile') ? (
                <Box>
                  <CallIcon style={{fontSize: '14px'}} />{' '}
                  <a
                    href={`tel:${get(
                      vendorDetails,
                      'secondaryMobiles[1].secondaryMobile',
                      ''
                    )}`}
                  >
                    {get(
                      vendorDetails,
                      'secondaryMobiles[1].secondaryMobile',
                      ''
                    )}
                  </a>
                </Box>
              ) : (
                ''
              )}
            </Box>
            <Box style={{width: '35%'}}>{getUserAddress(vendorDetails)}</Box>
          </Box>
          {/* {categories(props.location.state).map((val) => { */}
          {/* {categories(vendorDetails, hours).map((val, index) => {
            return (
            // eslint-disable-next-line react/jsx-key
            <Box key={index} className={classes.vendorDetailContainer}>
            <val.icon style={{margin: 3}} />
            <Typography
              style={{
                fontSize: val.font_size,
                fontWeight: '600',
              }}
            >
              {val.value}subLocality
            </Typography>
            </Box>
            );
            })} */}
        </Grid>
      </Grid>
    );
  };

  const getUserAddress = (addr) => {
    let address = getAddress(addr);
    if (address.length === 0) {
      return '';
    }
    return address + '.';
  };

  const getVendorHeaderDetailsMobile = () => {
    return (
      <Grid container className={classes.venDetailContainer}>
        <Grid xs={4} item className={classes.leftContainer}>
          {imageUrl ? (
            <img
              src={imageUrl}
              className={classes.media}
              onClick={handleClickOpen}
              alt=""
            />
          ) : (
            <Avatar className={classes.avatarStyle} onClick={handleClickOpen}>
              <PermIdentityOutlinedIcon style={{fontSize: '35px'}} />
            </Avatar>
          )}
          <Box>
            <Typography component={'span'} className={classes.vendorName}>
              {get(vendorDetails, 'organizationName', 'NA')}
            </Typography>
          </Box>
        </Grid>
        <Grid xs={8} item className={classes.rightContainer}>
          <Box className={classes.rightSectionAddress}>
            {getUserAddress(vendorDetails)}
          </Box>
          <Box className={classes.rightSectionTimeAndPhoneContainer}>
            <Box className={classes.rightSectionTime}>
              <AccessTimeIcon
                style={{
                  fontSize: '14px',
                }}
              />
              {hours}
            </Box>
            <Box className={classes.rightSectionPhone}>
              <Box className={classes.phoneWrapper}>
                <CallIcon style={{fontSize: '14px'}} />
                <a
                  href={`tel:${get(
                    vendorDetails,
                    'secondaryMobiles[0].secondaryMobile',
                    'NA'
                  )}`}
                >
                  {get(
                    vendorDetails,
                    'secondaryMobiles[0].secondaryMobile',
                    'NA'
                  )}
                </a>
              </Box>
              {get(vendorDetails, 'secondaryMobiles[1].secondaryMobile') ? (
                <Box className={classes.phoneWrapper}>
                  <CallIcon style={{fontSize: '14px'}} />{' '}
                  <a
                    href={`tel:${get(
                      vendorDetails,
                      'secondaryMobiles[1].secondaryMobile',
                      ''
                    )}`}
                  >
                    {get(
                      vendorDetails,
                      'secondaryMobiles[1].secondaryMobile',
                      ''
                    )}
                  </a>
                </Box>
              ) : (
                ''
              )}
            </Box>
          </Box>
          {/* {categories(props.location.state).map((val) => { */}
          {/* {categories(vendorDetails, hours).map((val, index) => {
            return (
            // eslint-disable-next-line react/jsx-key
            <Box key={index} className={classes.vendorDetailContainer}>
            <val.icon style={{margin: 3}} />
            <Typography
              style={{
                fontSize: val.font_size,
                fontWeight: '600',
              }}
            >
              {val.value}subLocality
            </Typography>
            </Box>
            );
            })} */}
        </Grid>
      </Grid>
    );
  };
  return (
    <div className={classes.container}>
      <>
        <WebToolbar />
        <AppBarName
          title={'Place Order'}
          isIcon={true}
          icon={<InfoOutlinedIcon />}
          onSelect={handleInfoClick}
        />
        <div className={classes.sideNav}>
          <SideNav />
        </div>
        {loading ? (
          <ActivityIndicator />
        ) : (
          <Box className={classes.UiContainer} py={1}>
            {getVendorHeaderDetailsMobile()}
            <BottomDialog open={showModal} onSimpleDialogClose={closeModalFunc}>
              <Box pl={3} pr={3} pb={3}>
                <Typography>{modalText}</Typography>
                <Typography>{point1}</Typography>
                <Typography>{point2}</Typography>
                <Typography>{point3}</Typography>
                <Typography style={{textIndent: '30px'}}>{point4}</Typography>
              </Box>
            </BottomDialog>
            <Box py={1} className={classes.add_item_order_container}>
              <Box className={classes.addItemContainer}>
                <Box className={classes.innerAddItemCont}>
                  <Box className={classes.titleCont}>
                    <Typography
                      component={'span'}
                      style={{fontWeight: '900', fontSize: '16px'}}
                    >
                      Add Items/Service
                    </Typography>
                  </Box>
                  <Box className={classes.searchBox} pl={2} pr={2}>
                    <Box style={{width: '100%'}}>
                      <SimpleAutocomplete
                        options={
                          searchResult
                            ? searchResult.map((option) => {
                                return lowerCase(option.name);
                              })
                            : ''
                        }
                        handleACOnChange={(text) => {
                          setQuery(text);
                          setSearchResult([]);
                        }}
                        labelText="Search Item"
                        handleOnChange={onChangeText}
                        searching={searching}
                      />
                    </Box>
                    <Box
                      px={1}
                      onClick={() => {
                        addTextItem();
                      }}
                    >
                      <Fab size="small" aria-label="add">
                        <AddIcon />
                      </Fab>
                    </Box>
                  </Box>
                  {items && items.length === 0 && (
                    <Box className={classes.noItemText}>
                      <Typography>No Items Added</Typography>
                    </Box>
                  )}
                  <Box className={classes.itemsNbtnCont} pl={2} pr={2}>
                    <Box className={classes._items}>
                      {items.map((item, index) => {
                        return (
                          <Box key={index}>
                            <Grid container className={classes._itemsGrid}>
                              <Grid xs={8} item>
                                <Box>
                                  <Typography component={'span'} noWrap={false}>
                                    {item.name}
                                  </Typography>
                                </Box>
                              </Grid>
                              <Grid xs={2} item>
                                <Box>
                                  <Typography component={'span'}>
                                    ₹ {item.price === 0 ? 'NA' : item.price}
                                  </Typography>
                                </Box>
                              </Grid>
                              <Grid xs={2} item>
                                <Box>
                                  <Checkbox
                                    value={item.name}
                                    checked={item.isSelected}
                                    onChange={(event) =>
                                      handleCheckChange(event, index)
                                    }
                                    // defaultChecked
                                    color="primary"
                                    inputProps={{
                                      'aria-label': 'secondary checkbox',
                                    }}
                                  />
                                </Box>
                              </Grid>
                            </Grid>
                            <Divider />
                          </Box>
                        );
                      })}
                    </Box>
                    <Box pl={2} pb={2}>
                      <Typography component={'span'} variant="subtitle2">
                        * Price NA means, price not applied by vendor yet
                      </Typography>
                    </Box>
                    <Box
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                    >
                      <Button
                        variant="outlined"
                        disabled={addItemButton}
                        className={
                          items.length === 0
                            ? classes.disabled_button
                            : classes.active_button
                        }
                        onClick={addItemsToCart}
                        startIcon={<AddIcon />}
                      >
                        Add
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </Box>

              <Box className={classes.root}>
                <Box className={classes.orderTxtCont}>
                  <Typography
                    component={'span'}
                    style={{fontWeight: '900', fontSize: '16px'}}
                  >
                    Order
                  </Typography>
                </Box>
                <AppBar position="static" elevation={0}>
                  <Tabs
                    variant="fullWidth"
                    value={value}
                    onChange={handleChange}
                    aria-label="simple tabs example"
                  >
                    <Tab label="Item" {...a11yProps(0)} />
                  </Tabs>
                </AppBar>
                <Box style={{height: '82%'}}>
                  <TabPanel
                    value={value}
                    index={0}
                    className={classes.tabPanelOne}
                  >
                    <Box className={classes.tabPanelOneBox}>
                      {isEmpty(selectedCartItems) ? (
                        <Typography component={'span'}>
                          No items Added
                        </Typography>
                      ) : (
                        selectedCartItems.map((singleItem, index) => {
                          return (
                            <Box key={index}>
                              <Grid container className={classes.GridContOrder}>
                                <Grid
                                  xs={7}
                                  item
                                  className={classes.addDecNumCont}
                                >
                                  <Typography component={'span'} noWrap={true}>
                                    {singleItem.name}
                                  </Typography>
                                </Grid>
                                <Grid
                                  xs={2}
                                  item
                                  className={classes.addDecNumCont}
                                >
                                  <Typography component={'span'} noWrap={true}>
                                    ₹{' '}
                                    {singleItem.price === 0
                                      ? 'NA'
                                      : singleItem.price}
                                  </Typography>
                                </Grid>
                                <Grid
                                  xs={3}
                                  item
                                  className={classes.addDecNumCont}
                                >
                                  <Button
                                    variant="outlined"
                                    size="small"
                                    onClick={() => {
                                      DecNum(singleItem.id, index);
                                    }}
                                    className={classes.addDecBtn}
                                  >
                                    <RemoveIcon style={{fontSize: '20px'}} />
                                  </Button>
                                  <Typography
                                    component={'span'}
                                    style={{margin: 10}}
                                  >
                                    {get(singleItem, 'count', 0)}
                                  </Typography>
                                  <Button
                                    variant="outlined"
                                    size="small"
                                    onClick={() => IncNum(singleItem.id, index)}
                                    className={classes.addDecBtn}
                                  >
                                    <AddIcon style={{fontSize: '20px'}} />
                                  </Button>
                                </Grid>
                              </Grid>
                              <Divider />
                            </Box>
                          );
                        })
                      )}
                    </Box>
                  </TabPanel>
                  <Box className={classes.NxtBtnCont}>
                    {/* <Box
            style={{
              backgroundColor: '#E0E0E0',
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              margin: '1px',
            }}
          > */}
                    {/* {selectedCartItems.map((itemForCount, index) => {
      const price = 100;
      const total = price * itemForCount.count;
      return <Typography key={index}>Total ₹ {total}</Typography>;
    })} */}
                    {/* <Typography>Total ₹ 500</Typography>
          </Box> */}

                    <Button
                      variant="outlined"
                      disabled={isEmpty(selectedCartItems)}
                      className={
                        items.length === 0
                          ? classes.disabled_button
                          : classes.active_button
                      }
                      fullWidth
                      // onClick={() => orderAddedItems()}
                      onClick={() => {
                        history.push({
                          pathname: '/ordersummary',
                          state: {
                            vendorFor: vendorFor,
                            selectedCartItems: selectedCartItems,
                            // vendorId: props.location.state.vendorId,
                            vendor: selectedVendorForOrder,
                            savedOrder,
                            deliveryRemarksHint,
                            usrMsg: userMessage,
                            messages: data,
                          },
                        });
                      }}
                    >
                      Next
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        )}
      </>
      <MobileFooter />
    </div>
  );
};

export default PlaceOrder;
