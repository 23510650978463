/* eslint-disable react/prop-types */
import React from 'react';
import {
  Box,
  Dialog,
  DialogTitle,
  List,
  ListItem,
  ListItemText,
} from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import FilterListIcon from '@material-ui/icons/FilterList';
import PropTypes from 'prop-types';
import useStyles from './styles';

import {upperCase} from 'lodash';
import filterItems from './utils';

const SimpleDialog = (props) => {
  const classes = useStyles();

  const {onClose, selectedValue, open, orders, setListD, listD} = props;

  const handleClose = () => {
    onClose(selectedValue);
  };

  const handleListItemClick = (value) => {
    console.log('value', value);
    const _filteredItems = orders.filter((item) => {
      console.log('item', item.status);
      console.log('upperCase(value)', upperCase(value));
      if (value === 'All') {
        return listD;
      }
      if (item.status === upperCase(value)) {
        return item;
      }
    });
    console.log('filteredItems', _filteredItems);
    // setOrders(filteredItems);
    setListD(_filteredItems);
    onClose(value);
  };

  return (
    <Dialog
      style={{
        height: '400px',
        position: 'absolute',
        left: '43%',
        top: '22%',
      }}
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <Box
        style={{
          display: 'flex',
          alignItems: 'center',
          backgroundColor: '#F7F7F7',
        }}
      >
        <DialogTitle id="simple-dialog-title">Filter By :-</DialogTitle>
        <FilterListIcon style={{fontSize: 28}}></FilterListIcon>
      </Box>

      <List>
        {filterItems.map((email) => (
          <>
            <ListItem
              button
              onClick={() => handleListItemClick(email)}
              key={email}
            >
              <ListItemText primary={email} />
            </ListItem>
            <Divider component="li" />
          </>
        ))}
      </List>
    </Dialog>
  );
};

SimpleDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired,
  orders: PropTypes.array.isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default SimpleDialog;
