const filterItems = [
  'All',
  'Saved',
  'Ordered',
  'Paid cash advanced',
  'Cancelled',
  'Paid online',
  'Not received/invalid',
  'Received',
  'Returned',
  'Refund not received',
  'Refund received',
  'Request for refund',
  'Paid cash',
  'Accepted',
  'Accepted with token',
  'Accepted with date',
  'Declined',
  'Delivered',
  'Payment received',
  'Goods not returned',
  'Refunded',
  'Payment requested',
  'Payment request cancelled',
  'Online payment failed',
  'Online payment success',
  'Closed',
];

export default filterItems;
