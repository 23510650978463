import {Box, Button, Icon, TextField, Typography} from '@material-ui/core';
import {Rating} from '@material-ui/lab';
import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import AppBarName from '../../components/DumbComponents/AppBarName';
import SideNav from '../../components/sidenav/SideNav';
import WebToolbar from '../../components/toolbar/WebToolbar';
import Colors from '../../theme/Colors';
import useStyle from './style';
import feedbackService from '../../services/orderReview';
import showToast from '../../components/Toast';
import ErrorBoundary from '../../components/ErrorBoundary';
import ErrorBoundaryFallback from '../../components/ErrorBoundaryFallback';
import MobileFooter from '../MobileFooter';

const OrderReview = (props) => {
  // eslint-disable-next-line react/prop-types
  const {orderId, vendorId} = props.location.state;
  const token = useSelector((state) => state.user.token);
  const [rating, setRating] = useState(1);
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const classes = useStyle();
  // const vendorId = useSelector((state) => state.user.vendorId);
  // const orderId = useSelector((state) => state.user.orderId);

  useEffect(() => {
    console.log('props', props);
  });

  async function onPressSend() {
    // if (!message.trim()) {
    //   showToast('Please enter your message', 'danger');
    //   return;
    // }
    // const {orderId, vendorId} = route.params;
    //setLoading(true);
    try {
      const payload = {
        Rating: rating,
        OrderId: orderId,
        ReviewComment: message,
      };
      const response = await feedbackService(token, payload, vendorId);
      setLoading(false);
      if (response.successCode === 1) {
        showToast('success', 'Thanks for submitting your feedback');
        history.goBack();
      } else {
        showToast('error', 'Failed');
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      showToast('error', 'Failed');
    }
  }

  return (
    <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
      <div className={classes.container}>
        <WebToolbar />
        <AppBarName title={'Order Review'} />

        <Box className={classes.sideNav}>
          <SideNav />
        </Box>
        <Box p={1}>
          <Typography className={classes.text}>
            How would you rate vendor service?
          </Typography>
        </Box>
        <Box p={1} className={classes.ratingContainer}>
          <Rating
            name="simple-controlled"
            value={rating}
            onChange={(event, newValue) => {
              setRating(newValue);
            }}
          />
        </Box>
        <Box p={1}>
          <TextField
            label={'Comment'}
            fullWidth
            value={message}
            onChange={(text) => setMessage(text.target.value)}
            variant="outlined"
            numberOfLines={10}
            disabled={loading}
            maxLength={400}
            multiline
            className={classes.textInput}
          />
        </Box>
        <Box p={1} className={classes.ratingContainer}>
          <Button
            size="small"
            className={classes.btn}
            variant="outlined"
            //   icon="send"
            onClick={() => onPressSend()}
            endIcon={<Icon>send</Icon>}
          >
            Submit
          </Button>
        </Box>
      </div>
      <MobileFooter />
    </ErrorBoundary>
  );
};

export default OrderReview;
