import {Box, Button, Typography} from '@material-ui/core';
import React, {useEffect} from 'react';
import AppBarName from '../../components/DumbComponents/AppBarName';
import SideNav from '../../components/sidenav/SideNav';
import WebToolbar from '../../components/toolbar/WebToolbar';
import useStyle from './style';
import {useHistory} from 'react-router-dom';
import ErrorBoundary from '../../components/ErrorBoundary';
import ErrorBoundaryFallback from '../../components/ErrorBoundaryFallback';
import MobileFooter from '../MobileFooter';

const OrderPlaced = (props) => {
  // eslint-disable-next-line react/prop-types
  const {orderId} = props.location.state;
  const classes = useStyle();
  const history = useHistory();

  useEffect(() => {
    console.log('props', props);
  }, []);

  return (
    <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
      <div className={classes.container}>
        <WebToolbar />
        <div className={classes.sideNav}>
          <SideNav />
        </div>
        <AppBarName title={'Order Placed Successfully'} />
        <Box p={1}>
          <Typography>
            You have successfully placed order with order ID - {orderId}
          </Typography>
        </Box>
        <Box p={1}>
          <Typography>Would You like to continue purchasing?</Typography>
          <Box p={1} className={classes.buttonWrapper}>
            <Button
              variant="outlined"
              className={classes.submitButton}
              size="small"
              onClick={() => {
                history.push('/home');
              }}
            >
              Go home
            </Button>
            <Button
              variant="outlined"
              className={classes.submitButton}
              size="small"
              onClick={() => {
                history.replace('/placeorder');
              }}
            >
              Create Another Order
            </Button>
          </Box>
        </Box>
      </div>
      <MobileFooter />
    </ErrorBoundary>
  );
};

export default OrderPlaced;
