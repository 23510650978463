/* Copyright (C) 2020 Gearten Technologies Private Limited - All Rights Reserved
 NOTICE:  All information contained herein is, and remains
 * the property of Gearten Technologies Private Limited
 * The intellectual and technical concepts contained
 * herein are proprietary to Gearten Technologies Private Limited.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Gearten Technologies Private Limited.
 */
import {combineReducers} from 'redux';
import userReducer from './userReducer';

export default combineReducers({
  user: userReducer,
});
