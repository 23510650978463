import {
  Box,
  Button,
  FormControl,
  Grid,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';
import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import useStyle from './style';
import showToast from '../../components/Toast';
import {useHistory} from 'react-router';
import getResons from '../../services/getResons';
import contactUsService from '../../services/contactUs';
import WebToolbar from '../../components/toolbar/WebToolbar';
import AppBarName from '../../components/DumbComponents/AppBarName';
import SideNav from '../../components/sidenav/SideNav';
import ErrorBoundary from '../../components/ErrorBoundary';
import ErrorBoundaryFallback from '../../components/ErrorBoundaryFallback';
import MobileFooter from '../MobileFooter';

const ContactUs = () => {
  const history = useHistory();
  const classes = useStyle();
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [requesting, setRequesting] = useState(true);
  const token = useSelector((state) => state.user.token);
  const user = useSelector((state) => state.user.user);
  const [pickerValue, setPickerValue] = useState(1);
  const [pickerData, setPickerData] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  async function getData() {
    try {
      const response = await getResons(token);
      console.log('getResons response', response);
      if (response.successCode === 1) {
        setPickerData(response.response);
        setPickerValue(response.response[0].reasonId);
      } else {
        showToast('error', 'Failed to get data');
        // navigation.goBack();
        history.push('/home');
      }
      setRequesting(false);
    } catch (error) {
      console.log(error);
      showToast('error', 'Failed to get data');
      //   navigation.goBack();
      history.push('/home');
      setRequesting(false);
    }
  }

  async function onPressSend() {
    if (!message.trim()) {
      showToast('error', 'Please enter your message');
      return;
    }
    // Keyboard.dismiss();
    setLoading(true);
    try {
      const payload = {
        ContactReason: 1,
        Comment: message,
      };
      const response = await contactUsService(token, payload);
      console.log('contactUsService res', response);
      setLoading(false);
      if (response.successCode === 1) {
        showToast(
          'success',
          'Thanks for submitting your message, we will get back to you soon'
        );
        // navigation.goBack();
        history.push('/home');
      } else {
        showToast('error', 'Failed');
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      showToast('error', 'Failed');
    }
  }

  return (
    <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
      <div className={classes.container}>
        <WebToolbar />
        <div className={classes.sideNav}>
          <SideNav />
        </div>
        <AppBarName title={'Contact Us'} />
        <Box p={1}>
          <Box>
            <Typography className={classes.text}>
              Get help, support, troubleshoot your queries or get in touch with
              us.
            </Typography>
          </Box>

          <Box py={1}>
            <Grid container direction={'column'} spacing={1}>
              <Grid item>
                <TextField
                  label={'Name'}
                  fullWidth
                  size="small"
                  value={user.username}
                  onChange={(text) => {}}
                  variant="outlined"
                  disabled={true}
                  inputProps={{maxLength: 200}}
                  className={classes.textInput}
                />
              </Grid>
              <Grid item>
                <TextField
                  label={'Email'}
                  value={user.email}
                  variant="outlined"
                  fullWidth
                  size="small"
                  onChange={(text) => {}}
                  disabled={true}
                  className={classes.textInput}
                />
              </Grid>
              <Grid item>
                {' '}
                <FormControl
                  variant="outlined"
                  size="small"
                  className={classes.containerPicker}
                  fullWidth
                >
                  <Select
                    fullWidth
                    variant="outlined"
                    native
                    value={pickerValue}
                    onChange={(type, index) => {
                      setPickerValue(type.target.value);
                    }}
                    inputProps={{
                      name: 'age',
                      id: 'outlined-age-native-simple',
                    }}
                  >
                    {pickerData.length > 0 ? (
                      pickerData.map((element, index) => {
                        return (
                          <option key={index} value={element.reasonId}>
                            {element.text}
                          </option>
                        );
                      })
                    ) : (
                      <Box>No Options Available</Box>
                    )}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item>
                <TextField
                  label={'Message'}
                  value={message}
                  variant="outlined"
                  fullWidth
                  multiline
                  rows={2}
                  inputProps={{maxLength: 250}}
                  size="small"
                  onChange={(text) => {
                    setMessage(text.target.value);
                  }}
                  className={classes.textInput}
                />
              </Grid>
            </Grid>
          </Box>

          <Box className={classes.btnContainer} py={2}>
            <Button
              variant="outlined"
              className={classes.button}
              onClick={() => {
                //history.goBack();
                history.push('/home');
              }}
            >
              Cancel
            </Button>
            <Button
              variant="outlined"
              className={classes.button}
              onClick={onPressSend}
              disabled={!message.trim()}
            >
              Send
            </Button>
          </Box>
        </Box>
      </div>
      <MobileFooter />
    </ErrorBoundary>
  );
};

export default ContactUs;
