import baseUrl from '../utils/Constants';

export default async function (token, advType, advLevel, areaId) {
  const response = await fetch(
    // `${baseUrl}/advertisements/price?AdvLevel=1&AreaId=2`,
    `${baseUrl}/advertisements/price?adType=${advType}&adLevel=${advLevel}&areaId=${areaId}`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }
  );
  const responseJson = await response.json();
  return responseJson;
}
