import React from 'react';
import SimpleTabs from '../../components/DumbComponents/SimpleTabs';
import SideNav from '../../components/sidenav/SideNav';
import WebToolbar from '../../components/toolbar/WebToolbar';
import useStyle from './style';
import SimpleAppBar from '../../components/DumbComponents/AppBarName';
import ErrorBoundary from '../../components/ErrorBoundary';
import ErrorBoundaryFallback from '../../components/ErrorBoundaryFallback';
import MobileFooter from '../MobileFooter';

const EditDetailsHome = () => {
  const classes = useStyle();
  return (
    <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
      <div className={classes.container}>
        <WebToolbar />
        <SimpleAppBar title="Edit Vendor Details" />
        <div className={classes.sideNav}>
          <SideNav />
        </div>
        <SimpleTabs />
      </div>
      <MobileFooter />
    </ErrorBoundary>
  );
};

export default EditDetailsHome;
