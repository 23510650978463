/* eslint-disable react/prop-types */
import {Box, Divider, Typography} from '@material-ui/core';
import {isEmpty} from 'lodash';
import moment from 'moment';
import React, {useEffect} from 'react';
import {useSelector} from 'react-redux';
import {useHistory} from 'react-router';
import AppBarName from '../../components/DumbComponents/AppBarName';
import SideNav from '../../components/sidenav/SideNav';
import WebToolbar from '../../components/toolbar/WebToolbar';
import useStyle from './style';
import MobileFooter from '../MobileFooter';

const ViewUPIsScreen = (props) => {
  const classes = useStyle();
  const history = useHistory();

  const upiIds = useSelector((state) => {
    return state.user.upiIds;
  });

  const onItemSelected = (item) => {
    console.log('item', item);
  };

  return (
    <>
      <div className={classes.container}>
        <WebToolbar />
        <div className={classes.sideNav}>
          <SideNav />
        </div>
        <AppBarName title={"View UPI's"} />
        <Box p={1}>
          {upiIds.length > 0
            ? upiIds.map((item, index) => {
                console.log('item', item);
                return (
                  <>
                    <Box
                      key={index}
                      onClick={() => onItemSelected(item)}
                      className={classes.item}
                    >
                      <Typography className={classes.textId}>
                        {item.upI_Id}
                      </Typography>
                      <Typography className={classes.textDate}>
                        {`Updated: ${moment(item.updatedOn).format(
                          'MM/DD/YYYY, h:mm A'
                        )}`}
                      </Typography>
                    </Box>
                    <Divider />
                  </>
                );
              })
            : ''}
          {/* <FlatList
          className={classes.listSearch}
          keyboardShouldPersistTaps="handled"
          data={upiIds.reverse()}
          ItemSeparatorComponent={() => <View className={classes.line} />}
          renderItem={({item}) => (
            <TouchableOpacity
              activeOpacity={0.65}
              onPress={() => onItemSelected(item)}
              className={classes.item}
            >
              <Text className={classes.textId}>{item.upI_Id}</Text>
              <Text style={classes.textDate}>
                {`Updated: ${moment(item.updatedOn).format(
                  'MM/DD/YYYY, h:mm A'
                )}`}
              </Text>
            </TouchableOpacity>
          )}
        /> */}
        </Box>
      </div>
      <MobileFooter />
    </>
  );
};

export default ViewUPIsScreen;
