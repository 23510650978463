import {
  AppBar,
  Box,
  Button,
  Fab,
  Grid,
  TextField,
  Toolbar,
  Typography,
} from '@material-ui/core';
import React, {useEffect, useState} from 'react';
import useStyle from './styles';
import SaveOutlinedIcon from '@material-ui/icons/SaveOutlined';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import CatalogueItem from '../../components/CatalogueItem';
import addVendorItems from '../../services/addVendorItems';
import showToast from '../../components/Toast';
import {useHistory} from 'react-router';
import WebToolbar from '../../components/toolbar/WebToolbar';
import AppBarName from '../../components/DumbComponents/AppBarName';
import ActivityIndicator from '../../components/ActivityIndicator';
import SideNav from '../../components/sidenav/SideNav';
import ErrorBoundary from '../../components/ErrorBoundary';
import ErrorBoundaryFallback from '../../components/ErrorBoundaryFallback';
import MobileFooter from '../MobileFooter';

const AddItemsScreen = (props) => {
  const classes = useStyle();
  const {t} = useTranslation('addItems');
  const token = useSelector((state) => state.user.token);
  // const {getData, items} = route.params;
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [price, setPrice] = useState('');
  const [listData, setListData] = useState([]);
  const forceUpdate = useState()[1].bind(null, {});
  const history = useHistory();

  useEffect(() => {
    console.log('props', props);
  }, []);

  function onPressAdd() {
    console.log('on press add');
    // const totalItems = items.length + listData.length;
    // if (totalItems >= 10) {
    //   showToast('Maximum 10 items can be added', 'danger');
    //   return;
    // }
    const obj = {
      name: name,
      description: description,
      price: price,
    };
    setName('');
    setDescription('');
    setPrice('');
    setListData([...listData, obj]);
  }
  function onPressDelete(item, index) {
    const data = listData;
    data.splice(index, 1);
    setListData(data);
    forceUpdate();
  }

  async function onPressSave() {
    // Keyboard.dismiss();
    setLoading(true);
    try {
      const items = [];
      listData.forEach((element) => {
        const obj = {
          Name: element.name,
          Description: element.description,
          Price: Number(element.price),
        };
        items.push(obj);
      });
      const payload = {
        VendorItems: items,
      };
      const response = await addVendorItems(payload, token);
      console.log('response addVendorItems', response);
      if (response.successCode === 1) {
        setListData([]);
        // getData();
        // navigation.pop(2);
        history.push('/buildcatalouge');
        showToast('success', 'Items added to catalogue');
      } else {
        showToast('error', 'Failed to add items');
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }

  return (
    <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
      <Box className={classes.container}>
        <WebToolbar />{' '}
        <div className={classes.sideNav}>
          <SideNav />
        </div>
        <AppBarName title={'Add Individual Items'} />
        {loading ? (
          <ActivityIndicator />
        ) : (
          <>
            <Box p={2}>
              <Grid container direction={'column'} spacing={2} xs={12}>
                <Grid item>
                  <TextField
                    size="small"
                    autoComplete="off"
                    fullWidth
                    value={name}
                    id="outlined-basic"
                    label={t('name')}
                    onChange={(text) => {
                      setName(text.target.value);
                    }}
                    variant="outlined"
                  />
                </Grid>
                <Grid item>
                  <TextField
                    size="small"
                    fullWidth
                    autoComplete="off"
                    id="outlined-basic"
                    type="number"
                    value={price}
                    inputProps={{maxLength: 10}}
                    onInput={(e) => {
                      // e.target.value = Math.max(0, parseInt(e.target.value))
                      //   .toString()
                      //   .slice(0, 10);
                    }}
                    label={t('price')}
                    onChange={(text) => {
                      if (!isNaN(text.target.value)) {
                        setPrice(text.target.value);
                      }
                    }}
                    variant="outlined"
                  />
                </Grid>
              </Grid>
            </Box>
            <Box p={2} className={classes.addBtnContainer}>
              <Button
                variant="outlined"
                className={classes.fab}
                onClick={onPressAdd}
                disabled={!name.trim()}
              >
                {t('add')}
              </Button>
              <Button
                variant="outlined"
                className={classes.fab}
                aria-label="add"
                disabled={listData.length === 0}
                onClick={() => {
                  onPressSave();
                }}
              >
                Save And Submit
              </Button>
            </Box>
            <Box p={2}>
              {listData.length > 0 ? (
                listData.map((item, index) => {
                  return (
                    <Box key={index}>
                      <CatalogueItem
                        item={item}
                        onPressEdit={(i) => {}}
                        onPressDelete={(i) => onPressDelete(item, index)}
                        onPress={(i) => {}}
                        showEdit={false}
                        isShare={false}
                      />
                    </Box>
                  );
                })
              ) : (
                <Box p={2}>
                  <Typography className={classes.textNoItems}>
                    No items added
                  </Typography>
                </Box>
              )}
            </Box>
          </>
        )}
      </Box>
      <MobileFooter />
    </ErrorBoundary>
  );
};

export default AddItemsScreen;
