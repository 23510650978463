import baseUrl from '../utils/Constants';

export default async function (isLogin, token, user) {
  console.log('isLogin, token, user', isLogin, token, user);
  try {
    const response = await fetch(
      isLogin
        ? `${baseUrl}/user/dashboard?userId=${user}`
        : 'http://devapi.gearten.in/pwa/user/dashboard',
      {
        method: 'GET',
        headers: isLogin
          ? {
              'Content-Type': 'application/json',
              appVersion: 25,
              Authorization: `Bearer ${token}`,
            }
          : {
              'Content-Type': 'application/json',
              appVersion: 25,
            },
      }
    );
    const responseJson = await response.json();
    return responseJson;
  } catch (error) {
    console.log('error from getDashboard', error);
  }
}
