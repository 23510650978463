import baseUrl from '../utils/Constants';

export default async function (payload, token) {
  const formBody = JSON.stringify(payload, (key, value) => {
    if (value !== null) {
      return value;
    }
    return {};
  });
  const response = await fetch(`${baseUrl}/usergroups/new`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: formBody,
  });
  const responseJson = await response.json();
  return responseJson;
}
