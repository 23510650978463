import React, {useState, useEffect} from 'react';
import WebToolbar from '../../components/toolbar/WebToolbar';
import useStyle from './styles';
import {Box, TextField, Typography, Button, Grid} from '@material-ui/core';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import {useHistory} from 'react-router';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import showToast from '../../components/Toast';
import createGroup from '../../services/createGroup';
import {setGroupId} from '../../redux/actions/user';
import AppBarName from '../../components/DumbComponents/AppBarName';
import SideNav from '../../components/sidenav/SideNav';
import MobileFooter from '../MobileFooter';

const CreateGroup = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyle();
  const {t} = useTranslation('groups');
  const token = useSelector((state) => state.user.token);
  const userId = useSelector((state) => state.user.userId);
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');

  async function onPressCreate() {
    if (!name.trim()) {
      showToast('error', t('enterName'));
      return;
    }
    const payload = {
      Name: name,
      Description: description,
    };
    setLoading(true);
    try {
      const response = await createGroup(payload, token);
      console.log('response', response);
      if (response.successCode === 1) {
        showToast('success', t('USER_GROUP_CREATED_SUCCESSFULLY'));
        // getData();
        const item = {
          group: {
            createdBy: userId,
            description: description,
            groupAttachments: null,
            groupMembers: null,
            groupMessages: null,
            id: response.response.groupId,
            name: name,
          },
        };

        dispatch(setGroupId(item));
        history.push('/groupmessagesscreen');

        // history.push({
        //   pathname: 'groupmessagesscreen',
        //   state: item,
        // });
      }
      setLoading(false);
    } catch (error) {
      console.log('error', error);
      setLoading(false);
    }
  }

  return (
    <>
      <div className={classes.container}>
        <WebToolbar />
        <div className={classes.sideNav}>
          <SideNav />
        </div>
        <AppBarName title={'Create Group'} />

        <Box p={2}>
          <Grid container direction={'column'} spacing={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                id="outlined-basic"
                label={t('groupName')}
                size="small"
                value={name}
                inputProps={{maxLength: 20}}
                onChange={(txt) => {
                  setName(txt.target.value);
                }}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                value={description}
                onChange={(txt) => {
                  setDescription(txt.target.value);
                }}
                size="small"
                id="outlined-basic"
                label={t('groupDescription')}
                variant="outlined"
                inputProps={{maxLength: 50}}
              />
            </Grid>
          </Grid>

          <Box p={2} className={classes.btnContainer}>
            <Button
              variant="outlined"
              onClick={() => {
                onPressCreate();
              }}
            >
              {t('create')}
            </Button>
          </Box>
        </Box>
      </div>
      <MobileFooter />
    </>
  );
};

export default CreateGroup;
