import React from 'react';
import { useSelector } from 'react-redux';
import ErrorBoundary from '../components/ErrorBoundary';
import PrivateRoutes from './PrivateRoutes';
import PublicRoutes from './PublicRoutes';
import PropTypes from 'prop-types';

const Routes = () => {
  const isLoggedIn = useSelector((state) => state.user.isLoggedIn);
  console.log('isLog', isLoggedIn);
  return <div>
    <ErrorBoundary FallbackComponent={OurFallbackComponent}>
      {!isLoggedIn ? <PublicRoutes /> : <PrivateRoutes />}
    </ErrorBoundary>
  </div>;
};

const OurFallbackComponent = ({ error, componentStack, resetErrorBoundary }) => {
  return (
    <div>
      <h1>An error occurred: {error.message}</h1>
      <button onClick={resetErrorBoundary}>Try again</button>
    </div>
  );
};

OurFallbackComponent.propTypes = {
  error: PropTypes.any.isRequired,
  componentStack: PropTypes.any.isRequired,
  resetErrorBoundary: PropTypes.func.isRequired,
};

export default Routes;
