/* eslint-disable react/prop-types */
import React, {useEffect, useState, useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import getOrders from '../../services/getPaymentRequests';
import {Fab, Box, Typography, TextField, Zoom} from '@material-ui/core';
import useStyle from './style';
import AddIcon from '@material-ui/icons/Add';
import {useHistory} from 'react-router';
import ActivityIndicator from '../../components/ActivityIndicator';
import Colors from '../../theme/Colors';
import moment from 'moment';
import Divider from '@material-ui/core/Divider';
import {setOrderId} from '../../redux/actions/user';
import {debounce} from 'lodash';
import ErrorBoundary from '../../components/ErrorBoundary';
import ErrorBoundaryFallback from '../../components/ErrorBoundaryFallback';

const VendorPaymentRequests = ({isVendor, pageType}) => {
  const classes = useStyle();
  const token = useSelector((state) => state.user.token);
  const history = useHistory();
  const dispatch = useDispatch();
  const {t} = useTranslation(['orderDetails', 'common']);
  const [query, setQuery] = useState('');
  const user = useSelector((state) => state.user.user);
  const [listData, setListData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searching, setSearching] = useState(false);
  const [orders, setOrders] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [refreshing, setRefreshing] = useState(false);

  useEffect(() => {
    getData();
  }, []);

  async function getData() {
    try {
      const response = await getOrders(token);
      console.log('response from vendor payment requests', response);
      if (response.successCode === 1) {
        let data = [];
        if (isVendor) {
          if (response.response.ordersAsVendor) {
            data = response.response.ordersAsVendor;
          }
        } else {
          if (response.response.ordersAsCustomer) {
            data = response.response.ordersAsCustomer;
          }
        }
        setQuery('');
        setListData(data);
        setOrders(data);
        //setOrders(response.response.orders);
        // if (response[1].successCode === 1) {
        //   setFilterData([
        //     {stateID: 0, stateName: 'All'},
        //     ...response[1].response,
        //   ]);
        // }
      } else {
        //
      }
      setRefreshing(false);
      setLoading(false);
    } catch (error) {
      setRefreshing(false);
      setLoading(false);
    }
  }

  const handleTextInputChange = (value) => {
    onTextChanged(value.target.value);
  };
  async function onTextChanged(q) {
    setQuery(q);
    if (q.length > 0) {
      setSearching(true);
      handler(q, orders);
    } else {
      setListData(orders);
      setSearching(false);
    }
  }

  const handler = useCallback(
    debounce(async (text, o) => {
      if (!text.trim()) {
        return;
      }
      try {
        const data = o.filter(
          (i) =>
            `${i.orderId}`.toLowerCase().includes(text.toLowerCase()) ||
            i.vendorName.toLowerCase().includes(text.toLowerCase()) ||
            i.orderRequestedBy.toLowerCase().includes(text.toLowerCase())
        );
        console.log('data', data);
        setListData(data);
        setSearching(false);
      } catch (error) {
        console.log('error', error);
        setSearching(false);
      }
    }, 500),
    []
  );
  const onPressItem = (item) => {
    console.log('item', item);
    dispatch(
      setOrderId({
        orderId: item.orderId,
        address: item.vendorAddress,
      })
    );
    history.push({
      pathname: '/orderDetails',
      state: {
        pageType: pageType,
      },
    });
    //  {
    //   item,
    //   isVendorOrder: true,
    //   getData: getData,
    //   address: item.customerAddress ? item.customerAddress : item.vendorAddress,
    //   contact: item.customerContact ? item.customerContact : item.vendorContact,
    // }
  };
  return loading ? (
    <ActivityIndicator />
  ) : (
    <Box>
      <Box className={classes.container}>
        <Box className={classes.containerTop}>
          <TextField
            value={query}
            id="outlined-basic"
            label="Search Requests"
            size="small"
            variant="outlined"
            fullWidth
            onChange={handleTextInputChange}
          />

          {/* <TouchableOpacity
          activeOpacity={0.6}
          style={{
            backgroundColor: Colors.secondary,
            paddingHorizontal: 10,
            paddingVertical: 5,
            marginHorizontal: 5,
          }}
          onPress={() => setShowFilterModal(true)}>
          <Icon name="filter" size={24} color="white" />
        </TouchableOpacity> */}
        </Box>
        {searching && (
          <Box className={[classes.containerTop, {marginTop: 5}]}>
            <ActivityIndicator size="small" color={Colors.secondary} />
            <Typography style={{marginHorizontal: 10}}>Searching...</Typography>
          </Box>
        )}
        <Box>
          {listData.length > 0 ? (
            listData.map((item, index) => {
              return (
                <Box key={index}>
                  <Box
                    className={classes.card}
                    onClick={() => {
                      onPressItem(item);
                    }}
                  >
                    <Box className={classes.containerTextTop}>
                      <Typography>{`Request ID: ${item.orderId}`}</Typography>
                      <Typography>{`${t(item.status)}`}</Typography>
                    </Box>
                    {!isVendor && (
                      <Typography>{`Vendor: ${item.vendorName}`}</Typography>
                    )}
                    {item.orderRequestedBy && (
                      <Typography>{`Requested To: ${item.orderRequestedBy}`}</Typography>
                    )}
                    <Typography>{`Amount: ₹ ${item.orderAmount}`}</Typography>
                    <Typography>{`Date: ${moment(item.orderDate).format(
                      'DD/MM/YYYY, h:mm A'
                    )}`}</Typography>
                  </Box>
                  <Divider variant="fullWidth" />
                </Box>
              );
            })
          ) : (
            <Box p={2}>
              <Typography>No requests found</Typography>
            </Box>
          )}
        </Box>
      </Box>
      {user.isVendor && (
        <Zoom in={true} timeout={{enter: 500, exit: 500}} unmountOnExit>
          <Fab
            className={classes.fab}
            variant="extended"
            size="small"
            aria-label="add"
            onClick={() => {
              history.push('/createpaymentrequest');
            }}
          >
            <AddIcon className={classes.extendedIcon} />
            CREATE REQUEST
          </Fab>
        </Zoom>

        /* <FAB onPress={() => navigation.navigate('CreatePaymetRequest')}*/
      )}
    </Box>
  );
};

export default VendorPaymentRequests;
