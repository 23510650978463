import baseUrl from '../utils/Constants';

export default async function (token, searchText, vendorId) {
  try {
    console.log('search text', token, searchText, vendorId);
    const response = await fetch(
      `${baseUrl}/customers/items?searchText=${searchText}&vendorId=${vendorId}`,
      // `http://gcmobile-dev.ap-southeast-1.elasticbeanstalk.com/api/customers/items?searchText=${searchText}&vendorId=${vendorId}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    );

    const responseJson = await response.json();
    return responseJson;
  } catch (error) {
    console.log(error);
  }
}
