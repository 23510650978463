import {makeStyles} from '@material-ui/styles';

const useStyle = makeStyles((theme) => ({
  MuiButton: {
    textPrimary: {
      color: 'green',
    },
  },
  container: {
    backgroundColor: 'white',
    padding: 10,
  },
  timePickerContainer: {display: 'flex', justifyContent: 'center'},
  textBtn: {
    color: 'white',
    fontSize: 24,
  },
  btnContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  btn: {
    borderRadius: 5,
    marginBottom: 10,
    width: '8rem',
  },
  containerTime: {
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
  },
  textTime: {
    fontSize: 16,
    borderBottomColor: 'lightgray',
    borderBottomWidth: 1,
    flex: 1,
    padding: 5,
    marginHorizontal: 5,
  },
  textTitle: {
    fontSize: 18,
    color: 'black',
    fontWeight: 'bold',
    marginBottom: 5,
  },
  textInput: {
    backgroundColor: '#F5F5F5',
  },
  timePickerBtn: {
    color: 'red',
    backgroundColor: 'green',
  },
}));

export default useStyle;
