import {makeStyles} from '@material-ui/styles';

const useStyle = makeStyles((theme) => ({
  container: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    paddingBottom: '100px',
    backgroundColor: 'white',
    width: '80%',
    margin: 'auto',
    '& > header > header': {
      position: 'inherit',
      // display: 'flex',
      // justifyContent: 'center',
      // width: '80%',
    },
  },
  sideNav: {
    ['@media (max-width:700px)']: {
      display: 'none',
    },
    //height: '100%',
    width: '92px',
    position: 'fixed',
    zIndex: 1,
    top: 0,
    // right: 0,
    right: '15px',
    overflowX: 'hidden',
    marginTop: 190,
    // marginRight: theme.spacing(1),
    marginRight: 0,
    backgroundColor: '#3B3B3B',
    elevation: 2,
  },
  line: {
    height: 0.5,
    backgroundColor: 'lightgray',
    width: '100%',
  },
  item: {
    paddingVertical: 7,
    paddingHorizontal: 10,
  },
  textId: {
    fontSize: 17,
    fontWeight: '500',
  },
  textDate: {
    fontSize: 12,
    color: 'gray',
  },
}));

export default useStyle;
