/* eslint-disable react/prop-types */
import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import showToast from '../../components/Toast';
import useStyle from './style';
import getVendorDetails from '../../services/getVendorDetails';
import makeTransaction from '../../services/makeTransaction';
import ActivityIndicator from '../../components/ActivityIndicator';
import {Box, Button} from '@material-ui/core';
import {useHistory} from 'react-router';
import WebToolbar from '../../components/toolbar/WebToolbar';
import AppBarName from '../../components/DumbComponents/AppBarName';
import SideNav from '../../components/sidenav/SideNav';
import ErrorBoundary from '../../components/ErrorBoundary';
import ErrorBoundaryFallback from '../../components/ErrorBoundaryFallback';
import MobileFooter from '../MobileFooter';

const OrderPaymentScreen = (props) => {
  const classes = useStyle();
  const history = useHistory();
  const token = useSelector((state) => state.user.token);
  const [loading, setLoading] = useState(false);
  const [reqesting, setReqesting] = useState(false);
  const {item, getData, order, vendorId, total} = props;
  const [vendorDetails, setVendorDetails] = useState('');
  const [transactionId, setTransactionId] = useState('');

  useEffect(() => {
    getDetails();
  }, []);

  async function getDetails() {
    try {
      const response = await getVendorDetails(token, vendorId);
      console.log('res', response);
      if (response.successCode === 1) {
        if (
          response.response.vendorAccounts.length &&
          response.response.vendorAccounts.length === 0
        ) {
          history.goBack();
          showToast('error', 'Vendor UPI unavailable');
        } else {
          setVendorDetails(response.response);
          setLoading(false);
        }
      } else {
        showToast('error', 'Failed to get vendor details');
        // history.goBack();
      }
    } catch (error) {
      console.log(error);
      showToast('error', 'Failed to get vendor details');
      //   history.goBack();
    }
  }

  async function onPressPay() {
    setReqesting(true);
    try {
      const payload = {
        OrderId: order.orderId,
        UserAccountId: vendorDetails.vendorAccounts[0].id,
        Amount: total,
        Reference: 'Test Reference',
        State: 0,
      };
      const resTrans = await makeTransaction(payload, token);
      console.log('response resTrans', resTrans);
      if (resTrans.successCode === 1) {
        setTransactionId(resTrans.response.transactionId);
        // UPI code for react js
      } else {
        showToast('error', 'Transaction failed');
        history.goBack();
      }
    } catch (error) {
      console.log(error);
      history.goBack();
      showToast('error', 'Transaction failed');
    }
    return;
  }

  return (
    <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
      <div className={classes.container}>
        <WebToolbar />
        <div className={classes.sideNav}>
          <SideNav />
        </div>
        <AppBarName title={'Order Payment Screen'} />
        {loading ? (
          <ActivityIndicator />
        ) : (
          <Box p={1}>
            <Button
              className={classes.btn}
              mode="contained"
              uppercase={false}
              contentStyle={{height: 45}}
              loading={reqesting}
              disabled={reqesting}
              onPress={onPressPay}
            >
              {`Proceed to pay ₹ ${total}`}
            </Button>
          </Box>
        )}
      </div>
      <MobileFooter />
    </ErrorBoundary>
  );
};

export default OrderPaymentScreen;
