import React from 'react';
import {Route, Switch, Redirect} from 'react-router-dom';
import Login from '../pages/Login';
import OTPVerifyScreen from '../pages/OTPVerifyScreen';
import SignUp from '../pages/SignUp';
import ResetPasswordScreen from '../pages/ResetPasswordScreen';
import DemoPage from '../pages/DemoPage';
import PrivacyPolicy from '../pages/PrivacyPolicy';
import TermsOfUse from '../pages/TermsOfUse';

const PublicRoutes = () => {
  return (
    <Switch>
      <Route exact path="/login" component={Login} />
      <Route exact path="/otp" component={OTPVerifyScreen} />
      <Route exact path="/accounts/new" component={SignUp} />
      <Route exact path="/demopage" component={DemoPage} />
      <Route
        exact
        path="/resetpasswordscreen"
        component={ResetPasswordScreen}
      />
      <Route exact path="/privacy-policy" component={PrivacyPolicy} />
      <Route exact path="/terms-of-use" component={TermsOfUse} />
      <Route exact path="/*">
        <Redirect to="/login" />
      </Route>
    </Switch>
  );
};
export default PublicRoutes;
