import {AppBar, Tab, Tabs} from '@material-ui/core';
import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import useStyle from './styles';
import WebToolbar from '../../components/toolbar/WebToolbar';
import SideNav from '../../components/sidenav/SideNav';
import AppBarName from '../../components/DumbComponents/AppBarName';
import MyOrdersScreen from '../MyOrdersScreen';
import ReceivedOrders from '../ReceivedOrders';
import VendorOrdersScreen from '../VendorOrdersScreen';
import {
  a11yProps,
  TabPanel,
} from '../../components/DumbComponents/TapPanelProps';
import ErrorBoundary from '../../components/ErrorBoundary';
import ErrorBoundaryFallback from '../../components/ErrorBoundaryFallback';
import MobileFooter from '../MobileFooter';
import {constants} from '../../utils/common';
const {ORDER_PLACED, ORDER_RECEIVED} = constants;

const OrdersScreen = () => {
  const user = useSelector((state) => state.user.user);
  const forceUpdate = useState()[1].bind(null, {});
  const [value, setValue] = useState(0);
  const classes = useStyle();

  useEffect(() => {
    forceUpdate();
  }, [user.isVendor]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
      <div className={classes.container}>
        <WebToolbar />
        <div className={classes.sideNav}>
          <SideNav />
        </div>
        <AppBarName title={user.isVendor ? 'Orders' : 'Placed Orders'} />
        {user.isVendor ? (
          <>
            <AppBar position="static" elevation={0}>
              <Tabs
                value={value}
                onChange={handleChange}
                variant="fullWidth"
                aria-label="simple tabs example"
              >
                <Tab label="Received" {...a11yProps(0)} />
                <Tab label="Placed" {...a11yProps(1)} />
              </Tabs>
            </AppBar>
            <TabPanel value={value} index={0}>
              {/* <ReceivedOrders /> */}
              <VendorOrdersScreen pageType={ORDER_RECEIVED} />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <MyOrdersScreen pageType={ORDER_PLACED} />
            </TabPanel>
          </>
        ) : (
          <MyOrdersScreen pageType={ORDER_PLACED} />
        )}
      </div>
      <MobileFooter />
    </ErrorBoundary>
  );
};

export default OrdersScreen;
