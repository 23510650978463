import baseUrl from '../utils/Constants';

export default async function (token, year, month) {
  const response = await fetch(
    `${baseUrl}/vendors/${year}/${month}/appointments`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    },
  );
  const responseJson = await response.json();
  return responseJson;
}
