import React from 'react';
import {
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  Typography,
  Avatar,
} from '@material-ui/core';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import PropTypes from 'prop-types';
import useStyle from './style';

const BottomDialog = ({
  props,
  children,
  open,
  onSimpleDialogClose,
  titleMessage = '',
  message,
}) => {
  const classes = useStyle();
  return (
    <Dialog
      onClose={onSimpleDialogClose}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <DialogActions style={{ padding: '4px 4px 0px 0px' }}>
        <Avatar
          className={classes.avatarContaier}
          onClick={onSimpleDialogClose}
        >
          <CloseOutlinedIcon className={classes.deleteIcon} />
        </Avatar>
      </DialogActions>
      <DialogContent style={{ padding: '0px 2px 2px 2px' }}>
        {children}
        {/* <Typography style={{fontWeight: 900}}>{titleMessage}</Typography>
        <p>
          {message.split('\n').map((i, key) => {
            return <p key={key}>{i}</p>;
          })}
        </p> */}
      </DialogContent>
    </Dialog>
  );
};

BottomDialog.propTypes = {
  props: PropTypes.any,
  children: PropTypes.any,
  open: PropTypes.bool,
  onSimpleDialogClose: PropTypes.func,
  titleMessage: PropTypes.string,
  message: PropTypes.string,
};

export default BottomDialog;
