import baseUrl from '../utils/Constants';

export default async function (token, orderId) {
  try {
    const response = await fetch(`${baseUrl}/orders/${orderId}/logs`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });

    const responseJson = await response.json();
    return responseJson;
  } catch (error) {
    console.log(error);
  }
}
