import React from 'react';
import PropTypes from 'prop-types';
import MenuItem from '@material-ui/core/MenuItem';
import PeopleIcon from '@material-ui/icons/People';
import ListIcon from '@material-ui/icons/List';
import HomeIcon from '@material-ui/icons/Home';
import CalendarIcon from '@material-ui/icons/CalendarToday';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import './styles';
import {useHistory} from 'react-router-dom';
import Rs from '../../assets/icons/ic_rupee.png';
import Typography from '@material-ui/core/Typography';
import useStyle from './styles';
import {BiRupee} from 'react-icons/bi';

export const Item = ({name, Icon, onClick}) => {
  const classes = useStyle();
  const history = useHistory();
  return (
    <MenuItem
      //className={classes.drawer}

      onClick={onClick}
    >
      <div className={classes.menu}>
        <Icon />
        <Typography className={classes.name}>{name}</Typography>
      </div>
    </MenuItem>
  );
};

Item.propTypes = {
  name: PropTypes.string,
  Icon: PropTypes.func,
  onClick: PropTypes.func,
};

function SideNav() {
  const history = useHistory();
  const classes = useStyle();

  return (
    <div className={classes.container}>
      <Item
        Icon={() => <HomeIcon style={{fill: 'white'}} />}
        onClick={() => history.push(`/home`)}
        name="Home"
      />
      <Item
        Icon={() => <PeopleIcon style={{fill: 'white'}} />}
        onClick={() => {
          history.push(`/groups`);
        }}
        name="Groups"
      />
      <Item
        Icon={() => <ListIcon style={{fill: 'white'}} />}
        onClick={() => {
          // history.push('/receivedorders');
          history.push('/ordersscreen');
        }}
        name="Orders"
      />
      <Item
        Icon={() => <CalendarIcon style={{fill: 'white'}} />}
        onClick={() => {
          history.push('/vendorappointmentsscreen');
        }}
        name="Appointments"
      />
      <Item
        // Icon={() => <AttachMoneyIcon style={{fill: 'white'}} />}
        Icon={() => <BiRupee size={'1.5em'} style={{fill: 'white'}} />}
        onClick={() => {
          history.push('/paymentrequestsscreen');
        }}
        name="Requests"
      />
    </div>
  );
}

SideNav.propTypes = {};

export default SideNav;
