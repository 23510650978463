import baseUrl from '../utils/Constants';
import getMessageFromStatus from '../utils/getMessageFromStatus';

export default async function (mobile) {
  try {
    const response = await fetch(
      `${baseUrl}/users/verification/otp?mobile=${mobile}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );
    if (response.status === 200) {
      const responseJson = await response.json();
      return responseJson;
    } else {
      return {
        successCode: 0,
        messageCode: getMessageFromStatus(response.status),
        statusCode: response.status,
        url: response.url,
      };
    }
  } catch (error) {
    return {
      successCode: 0,
      messageCode: getMessageFromStatus(0),
    };
  }
}
