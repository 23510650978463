/* eslint-disable react/prop-types */
import React, {useEffect, useState} from 'react';
import WebToolbar from '../../components/toolbar/WebToolbar';
import useStyle from './style';
import {Box, TextField, Typography, Grid, Button} from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import moment from 'moment';
import showToast from '../../components/Toast';
import {useHistory} from 'react-router';
import {useSelector} from 'react-redux';
import AppBarName from '../../components/DumbComponents/AppBarName';
import SideNav from '../../components/sidenav/SideNav';
import MobileFooter from '../MobileFooter';

const CreateBasketScreen = (props) => {
  // const {
  //   location: {
  //     state: {
  //       group: {id},
  //     },
  //   },
  // } = props;
  const classes = useStyle();
  const history = useHistory();
  const [selectedDeliveryDate, setSelectedDeliveryDate] = useState(new Date());
  const [selectedAcceptanceDate, setSelectedAcceptanceDate] = useState(
    new Date()
  );
  const [basketName, setBasketName] = useState('');
  const [basketPurpose, setBasketPurpose] = useState('');
  const groupDetails = useSelector((state) => {
    return state.user.groupDetails;
  });
  useEffect(() => {
    console.log('props', props, groupDetails);
  }, []);

  const handleDeliveryDateChange = (date) => {
    console.log('delivery date', date);
    setSelectedDeliveryDate(date);
  };
  const handleAcceptanceDateChange = (date) => {
    console.log('acceptance date', date);
    setSelectedAcceptanceDate(date);
  };

  async function onPressCreate() {
    try {
      if (!basketName.trim()) {
        showToast('warn', 'Please enter basket name');
        return;
      }
      if (!basketPurpose.trim()) {
        showToast('warn', 'Please enter basket purpose');
        return;
      }
      // if (deliveryDate === '') {
      //   showToast('Please select tentative delivery date', 'danger');
      //   return;
      // }
      // if (orderDate === '') {
      //   showToast('Please select acceptance till date', 'danger');
      //   return;
      // }
      const payload = {
        GroupId: groupDetails.group.id,
        Name: basketName,
        State: 0,
        Purpose: basketPurpose,
        isNew: false,
      };
      if (selectedAcceptanceDate !== '') {
        payload.TentativeOrderDate = selectedAcceptanceDate
          ? moment(new Date(selectedAcceptanceDate)).format('YYYY-MM-DD')
          : '';
      }
      if (selectedDeliveryDate !== '') {
        payload.TentativeDeliveryDate = selectedDeliveryDate
          ? moment(new Date(selectedDeliveryDate)).format('YYYY-MM-DD')
          : '';
      }

      history.push({pathname: 'addbasketitems', state: payload});
      //   navigation.navigate('AddBasketItems', {payload, getList: getList});
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <>
      <div className={classes.container}>
        <WebToolbar />
        <div className={classes.sideNav}>
          <SideNav />
        </div>
        <AppBarName title={'Create Basket'} />

        <Box m={2}>
          <Grid container direction={'column'} spacing={2}>
            <Grid item>
              <TextField
                my={2}
                id="outlined-basic"
                fullWidth
                size="small"
                label="Basket Name"
                variant="outlined"
                inputProps={{maxLength: 20}}
                onChange={(txt) => {
                  setBasketName(txt.target.value);
                }}
              />
            </Grid>
            <Grid item>
              <TextField
                fullWidth
                id="outlined-basic"
                size="small"
                label="Purpose"
                variant="outlined"
                inputProps={{maxLength: 50}}
                onChange={(txt) => {
                  setBasketPurpose(txt.target.value);
                }}
              />
            </Grid>
            <Grid item>
              <Box>
                <Typography>Tentative Delivery Date</Typography>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    disableToolbar
                    fullWidth
                    variant="inline"
                    inputVariant="outlined"
                    size="small"
                    format="MM/dd/yyyy"
                    autoOk={true}
                    disablePast={true}
                    margin="normal"
                    id="date-picker-inline"
                    label="Select tentative delivery date"
                    value={selectedDeliveryDate}
                    onChange={handleDeliveryDateChange}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                  />
                </MuiPickersUtilsProvider>
              </Box>
            </Grid>
            <Grid item>
              <Box>
                <Typography>Order Acceptance Till Date</Typography>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    fullWidth
                    disableToolbar
                    variant="inline"
                    inputVariant="outlined"
                    format="MM/dd/yyyy"
                    margin="normal"
                    autoOk={true}
                    disablePast={true}
                    id="date-picker-inline"
                    size="small"
                    //disableFuture={selectedDeliveryDate}
                    maxDate={selectedDeliveryDate}
                    maxDateMessage={'Date should not be after delivery date'}
                    label="Select order acceptance till date"
                    value={selectedAcceptanceDate}
                    onChange={handleAcceptanceDateChange}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                  />
                </MuiPickersUtilsProvider>
              </Box>
            </Grid>
          </Grid>
          <Box style={{display: 'flex', justifyContent: 'center'}}>
            <Button
              variant="outlined"
              onClick={() => {
                onPressCreate();
              }}
            >
              Next
            </Button>
          </Box>
        </Box>
      </div>
      <MobileFooter />
    </>
  );
};

export default CreateBasketScreen;
