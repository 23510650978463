import {makeStyles} from '@material-ui/core';

const useStyle = makeStyles((theme) => ({
  container: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    paddingBottom: '100px',
    backgroundColor: 'white',
    // padding: 10,
    flex: 1,
    width: '80%',
    margin: 'auto',
    '& > header > header': {
      position: 'inherit',
      // display: 'flex',
      // justifyContent: 'center',
      // width: '80%',
    },
  },
  sideNav: {
    ['@media (max-width:700px)']: {
      display: 'none',
    },
    //height: '100%',
    width: '92px',
    position: 'fixed',
    zIndex: 1,
    top: 0,
    // right: 0,
    right: '15px',
    overflowX: 'hidden',
    marginTop: 190,
    // marginRight: theme.spacing(1),
    marginRight: 0,
    backgroundColor: '#3B3B3B',
    elevation: 2,
  },
  activityIndicator: {
    height: '100%',
  },
  logo: {
    height: 100,
    width: 100,
  },
  textTitle: {
    fontSize: 20,
    color: 'black',
    fontWeight: 'bold',
    width: '100%',
    textAlign: 'center',
  },
  text: {
    fontSize: '16px',
    fontWeight: '400',
    whiteSpace: 'pre-line',
  },
  textCompany: {
    fontSize: 16,
    color: 'blue',
  },
  textCopyright: {
    fontSize: 16,
    textAlign: 'center',
    marginTop: 50,
  },
}));

export default useStyle;
