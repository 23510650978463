import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    width: '80%',
    margin: 'auto',
    '& > header > header': {
      position: 'inherit',
      // display: 'flex',
      // justifyContent: 'center',
      // width: '80%',
    },
  },
  sideNav: {
    ['@media (max-width:700px)']: {
      display: 'none',
    },
    //height: '100%',
    width: '92px',
    position: 'fixed',
    zIndex: 1,
    top: 0,
    // right: 0,
    right: '15px',
    overflowX: 'hidden',
    marginTop: 190,
    // marginRight: theme.spacing(1),
    marginRight: 0,
    backgroundColor: '#3B3B3B',
    elevation: 2,
  },
  backgroundColorGray: {
    display: 'flex',
    justifyContent: 'space-between',
    // backgroundColor: '#9e9e9e',
    backgroundColor: '#bcd4e6',
    '&:hover': {
      backgroundColor: '#D3D3D3',
    },
  },
  backgroundColorWhite: {
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: '#FFFFFF',
    '&:hover': {
      backgroundColor: '#D3D3D3',
    },
  },
  divider: {
    background: 'gray',
  },
}));

export default useStyles;
