import {makeStyles} from '@material-ui/styles';

const useStyle = makeStyles((theme) => ({
  root: {border: 'solid'},
  container: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    paddingBottom: '100px',
    width: '80%',
    margin: 'auto',
    '& > header > header': {
      position: 'inherit',
      // display: 'flex',
      // justifyContent: 'center',
      // width: '80%',
    },
  },
}));

export default useStyle;
