import {
  Box,
  Grid,
  TextField,
  Button,
  Typography,
  InputAdornment,
  IconButton,
} from '@material-ui/core';
import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useHistory} from 'react-router';
import ActivityIndicator from '../../components/ActivityIndicator';
import showToast from '../../components/Toast';
import useStyle from './style';
import changePassword from '../../services/setPassword';
import passwordRecovery from '../../services/passwordRecovery';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import WebToolbar from '../../components/toolbar/WebToolbar';
import AppBarName from '../../components/DumbComponents/AppBarName';
import SideNav from '../../components/sidenav/SideNav';
import RHFTextInput from '../../components/DumbComponents/RHFTextInput';
import {useSelector} from 'react-redux';

import {Controller, useForm} from 'react-hook-form';
import {
  confirmPasswordRules,
  newPasswordRules,
  numberRules,
  otpRules,
} from './utils';

const ResetPasswordScreen = () => {
  const classes = useStyle();
  const history = useHistory();
  const {t} = useTranslation(['loginScreen', 'common']);
  const [loading, setLoading] = useState(false);
  // const [email, setEmail] = useState('');
  const [otp, setOtp] = useState('');
  const [password, setPassword] = useState('');
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState('');
  const [confirmPassVisible, setConfirmPassVisible] = useState(false);
  const [otpSent, setOtpSent] = useState(false);
  const [otpVerified, setOtpVerified] = useState(false);
  const [mobileNumber, setMobileNumber] = useState('');
  const [data, setData] = useState({});
  const isLoggedIn = useSelector((state) => state.user.isLoggedIn);
  const token = useSelector((state) => state.user.token);

  const {register, handleSubmit, control} = useForm({
    defaultValues: {
      email: '',
      otp: '',
      password: '',
      confirmPassword: '',
    },
  });

  //   const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setPasswordVisible(!passwordVisible);
  const handleClickShowConfirmPassword = () =>
    setConfirmPassVisible(!confirmPassVisible);

  async function onPress(data) {
    console.log('data', data, 'otp', otp);
    // Analytics.reportEvent(EVENTS.FORGOT_PASSWORD);
    if (otpSent) {
      if (confirmPassword.trim() !== password.trim()) {
        showToast('warn', 'Passwords did not matched');
        return;
      }
      setLoading(true);
      try {
        const payload = {
          UserName: data.email,
          OTP: data.otp,
          Password: data.password,
          ConfirmPassword: data.password,
          // Password: password,
          // ConfirmPassword: password,
        };
        const response = await changePassword(payload);
        console.log('changePassword response', response);
        setLoading(false);
        if (response.successCode === 1) {
          showToast('success', 'Password changed');
          // history.goBack();
          setLoading(false);
          history.push('/home');
        } else {
          showToast('error', t(response.returnMessage));
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
      }
    } else {
      setLoading(true);
      try {
        const response = await passwordRecovery(data.email, token);
        if (response.successCode === 1) {
          setMobileNumber(data.email);
          setOtpSent(true);
          showToast('success', "OTP has been sent on User's mobile");
        } else {
          showToast('error', t(response.returnMessage));
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    }
  }

  return (
    <Box className={classes.container}>
      <WebToolbar />
      <div className={classes.sideNav}>{isLoggedIn && <SideNav />}</div>
      <AppBarName title={'Reset Password'} />
      <form className={classes.root} onSubmit={handleSubmit(onPress)}>
        <Box p={1}>
          <Box p={1}>
            <Typography>Enter your phone number : </Typography>
          </Box>

          <Box className={classes.textInputContainer}>
            <Typography className={classes.textCountryCode}>+91</Typography>
            <RHFTextInput
              name="email"
              label="Enter Phone"
              control={control}
              type="text"
              rules={numberRules}
              showPasswordIcon={false}
            />
            {/* <TextField
                className={classes.textInput}
                fullWidth
                placeholder="Enter Phone"
                id="outlined-basic"
                // label="Pin Code"
                inputProps={{maxLength: 10}}
                value={email}
                size="small"
                variant="outlined"
                onChange={(txt) => {
                  setEmail(txt.target.value);
                }}
              /> */}
          </Box>
        </Box>
        <Box p={2}>
          {otpSent && (
            <Box>
              <Typography
                className={classes.textInputTitle}
              >{`Enter OTP sent to ${mobileNumber}`}</Typography>
              <Box py={2} className={classes.textInputContainer}>
                {/* <TextField
                  className={[classes.textInput, {letterSpacing: 15}]}
                  fullWidth
                  placeholder="Enter OTP"
                  id="outlined-basic"
                  // label="Pin Code"
                  inputProps={{maxLength: 4}}
                  value={otp}
                  size="small"
                  variant="outlined"
                  onChange={(txt) => {
                    setOtp(txt.target.value);
                  }}
                /> */}
                <RHFTextInput
                  name="otp"
                  label="Enter OTP"
                  control={control}
                  type="number"
                  rules={otpRules}
                  showPasswordIcon={false}
                />
              </Box>

              <Box>
                <Box className={classes.textInputContainer}>
                  <RHFTextInput
                    name="password"
                    label="New Password"
                    control={control}
                    type={passwordVisible ? 'text' : 'password'}
                    rules={newPasswordRules}
                    showPassword={passwordVisible}
                    showPasswordIcon={true}
                    handleClickShowPassword={handleClickShowPassword}
                  />
                  {/* <TextField
                    className={classes.textInput}
                    fullWidth
                    placeholder="New Password"
                    id="outlined-basic"
                    // label="Pin Code"
                    value={password}
                    type={passwordVisible ? 'text' : 'password'}
                    size="small"
                    variant="outlined"
                    onChange={(txt) => {
                      setPassword(txt.target.value);
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                          >
                            {passwordVisible ? (
                              <VisibilityIcon />
                            ) : (
                              <VisibilityOffIcon />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  /> */}
                </Box>

                <Box py={2} className={classes.textInputContainer}>
                  <RHFTextInput
                    name="confirmPassword"
                    label="Confirm Password"
                    control={control}
                    type={confirmPassVisible ? 'text' : 'password'}
                    rules={confirmPasswordRules}
                    showPassword={confirmPassVisible}
                    showPasswordIcon={true}
                    handleClickShowPassword={handleClickShowConfirmPassword}
                  />
                  {/* <TextField
                    className={classes.textInput}
                    fullWidth
                    placeholder="Confirm Password"
                    id="outlined-basic"
                    // label="Pin Code"
                    value={confirmPassword}
                    type={confirmPassVisible ? 'text' : 'password'}
                    size="small"
                    variant="outlined"
                    onChange={(txt) => {
                      setConfirmPassword(txt.target.value);
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowConfirmPassword}
                          >
                            {confirmPassVisible ? (
                              <VisibilityIcon />
                            ) : (
                              <VisibilityOffIcon />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  /> */}
                </Box>
              </Box>
            </Box>
          )}
        </Box>
        <Box className={classes.btnContainer}>
          {loading ? (
            <ActivityIndicator color="white" size="small" />
          ) : (
            <Button
              variant="outlined"
              style={{
                width: '12rem',
                borderRadius: '20px',
                marginBottom: '1rem',
              }}
              type="text"
              // onClick={() => onPress()}
            >
              {!otpSent
                ? 'Send OTP'
                : otpVerified
                ? 'Change Password'
                : otpSent
                ? 'Change Password'
                : ''}{' '}
            </Button>
          )}
        </Box>
      </form>
    </Box>
  );
};

export default ResetPasswordScreen;
