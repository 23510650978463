/* eslint-disable react/prop-types */

import React, {Component, useEffect, useState} from 'react';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import {connect} from 'react-redux';
import MonthView from './MonthView';
import Colors from '../theme/Colors';
import getVendorAppointments from '../services/getVendorAppointments';
import getUserAppointments from '../services/getUserAppointments';
import moment from 'moment';
import {withStyles} from '@material-ui/core/styles';
import {Box, Typography} from '@material-ui/core';
import ActivityIndicator from './ActivityIndicator';

// const screenWidth = Dimensions.get('window').width;
const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

const weekDays = [
  {day: 'Mon', date: null},
  {day: 'Tue', date: null},
  {day: 'Wed', date: null},
  {day: 'Thu', date: null},
  {day: 'Fri', date: null},
  {day: 'Sat', date: null},
  {day: 'Sun', date: null},
];
const nDays = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

const useStyle = () => ({
  container: {
    width: '50%',
    height: 350,
  },
  activityIndicator: {
    height: '100%',
  },
  headerContainer: {
    flexDirection: 'row',
    paddingHorizontal: 5,
    paddingVertical: 10,
    justifyContent: 'center',
    alignItems: 'center',
    margin: 5,
  },
  btn: {
    paddingLeft: 10,
    paddingRight: 10,
  },
  textMonth: {
    fontSize: 20,
    textAlign: 'center',
    flex: 1,
    color: 'black',
  },
});

class Calender extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeDate: new Date(),
      loading: true,
      data: [],
      appointments: [],
    };
  }

  async componentDidMount() {
    this.getAppointments();
  }

  async getAppointments() {
    try {
      console.log('this.props', this.props);
      const response =
        this.props.type === 'user'
          ? await getUserAppointments(
              this.props.token,
              this.state.activeDate.getFullYear(),
              this.state.activeDate.getMonth() + 1
            )
          : await getVendorAppointments(
              this.props.token,
              this.state.activeDate.getFullYear(),
              this.state.activeDate.getMonth() + 1
            );
      console.log('res', response);
      if (response.successCode === 1) {
        this.setState(
          {
            appointments: response.response.appointments,
          },
          () => this.generateMatrix()
        );
      } else {
        this.generateMatrix();
      }
    } catch (error) {
      this.generateMatrix();
    }
  }

  getInnerData() {
    'some inner data';
  }

  changeMonth(n) {
    this.setState({loading: true}, () => {
      this.setState(() => {
        this.state.activeDate.setMonth(this.state.activeDate.getMonth() + n);
        this.getAppointments();
        return this.state;
      });
    });
  }

  refreshCalender() {}

  generateMatrix() {
    console.log('in generate matrix');
    const matrix = [];
    matrix[0] = weekDays;
    const year = this.state.activeDate.getFullYear();
    const month = this.state.activeDate.getMonth();
    const firstDay = new Date(year, month, 0).getDay();
    let maxDays = nDays[month];
    console.log(
      'year',
      year,
      'month',
      month,
      'firstDay',
      firstDay,
      'maxDays',
      maxDays,
      'matrix',
      matrix
    );
    if (month === 1) {
      if ((year % 4 === 0 && year % 100 !== 0) || year % 400 === 0) {
        maxDays += 1;
      }
    }
    let counter = 1;
    for (let row = 1; row < 7; row++) {
      matrix[row] = [];
      for (let col = 0; col < 7; col++) {
        matrix[row][col] = {
          day: '',
          item: [],
          date: null,
        };
        if (row === 1 && col >= firstDay) {
          const obj = {
            day: counter,
            item: [],
            date: moment(`${year}-${month + 1}-${counter}`, 'YYYY-MM-DD'),
          };
          matrix[row][col] = obj;
          counter++;
        } else if (row > 1 && counter <= maxDays) {
          const appointments = [];
          this.state.appointments.forEach((appointment) => {
            const appointmentDate = moment(
              `${year}-${month + 1}-${counter}`,
              'YYYY-MM-DD'
            );
            const currentDate = moment(appointment.serviceDate, 'YYYY-MM-DD');
            const isSame = appointmentDate.isSame(currentDate, 'day');
            if (isSame) {
              appointments.push(appointment);
            }
          });
          const obj = {
            day: counter,
            item: appointments,
            date: moment(`${year}-${month + 1}-${counter}`, 'YYYY-MM-DD'),
          };
          //console.log(obj);
          matrix[row][col] = obj;
          counter++;
        }
      }
    }

    console.log('matrix', matrix);

    //this.state.appointments.forEach((appointment) => {});
    this.setState({data: matrix, loading: false});
  }

  render() {
    const styles = this.props;

    return (
      <Box className={styles.container}>
        <Box>
          <Box className={styles.headerContainer}>
            <Box
              className={styles.btn}
              disabled={this.state.loading}
              onClick={() => this.changeMonth(-1)}
            >
              <ArrowLeftIcon size={14} />
            </Box>
            <Typography style={styles.textMonth}>
              {months[this.state.activeDate.getMonth()]} &nbsp;
              {this.state.activeDate.getFullYear()}
            </Typography>
            <Box
              style={styles.btn}
              disabled={this.state.loading}
              onClick={() => this.changeMonth(+1)}
            >
              <ArrowRightIcon size={14} />
            </Box>
          </Box>
          {this.state.loading ? (
            <ActivityIndicator
              color={Colors.secondary}
              style={styles.activityIndicator}
              size="large"
            />
          ) : (
            <MonthView
              data={this.state.data}
              onClick={(item) => this.props.onPress(item)}
            />
          )}
        </Box>
      </Box>
    );
  }
}

const mapStateToProps = (state) => ({
  token: state.user.token,
});

// export default compose(
//   withStyles(useStyle, {name: 'Calender'}),
//   connect(mapStateToProps, null)
// )(Calender);
// export default connect(mapStateToProps(withStyles(useStyle))(Calender));

export default connect(mapStateToProps)(withStyles(useStyle)(Calender));
