import React, {useState, useEffect} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import ActivityIndicator from '../../components/ActivityIndicator';
import WebToolbar from '../../components/toolbar/WebToolbar';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import PeopleIcon from '@material-ui/icons/People';
import ListIcon from '@material-ui/icons/List';
import HomeIcon from '@material-ui/icons/Home';
import CalendarIcon from '@material-ui/icons/CalendarToday';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import ImageSlider from '../../components/ImageSlider';
import getDashboard from '../../services/getDashboard';
import {useDispatch, useSelector} from 'react-redux';
import Categories from '../../components/Categories';
import VendorList from '../../components/VendorList';
// import VendorSearchbar from '../../components/VendorSearchbar';
import VendorSearchbar from '../../components/VendorSearchbar';
import PincodeModal from '../../components/PincodeModal';
import {
  setAdvertisement,
  setFavouriteVendors,
  setNearByVendors,
  setShowPincodeModal,
  setUnreadNotifications,
  setVendorTypes,
} from '../../redux/actions/user';
import {useHistory} from 'react-router';
import {Link} from 'react-router-dom';
import MobileFooter from '../MobileFooter';
import ErrorBoundary from '../../components/ErrorBoundary';
import ErrorBoundaryFallback from '../../components/ErrorBoundaryFallback';

const useStyles = makeStyles({
  main: {
    marginTop: 65,
    marginBottom: 50,
    width: '100%',
  },
});

function MobileHome() {
  const token = useSelector((state) => state.user.token);
  const user = useSelector((state) => state.user.user);
  const isLoggedIn = useSelector((state) => state.user.isLoggedIn);
  const advertisements = useSelector((state) => state.user.advertisement);
  const vendorTypes = useSelector((state) => state.user.vendorTypes);
  const nearByVendors = useSelector((state) => state.user.nearByVendors);
  const favouriteVendors = useSelector((state) => state.user.favouriteVendors);
  const showPincodeModal = useSelector((state) => state.user.showPincodeModal);

  const classes = useStyles();
  const dispatch = useDispatch();
  // const [advertisements, setAdvertisements] = useState([]);
  // const [vendorTypes, setVendorTypes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searching, setSearching] = useState(false);
  // const [nearByVendors, setNearByVendors] = useState([]);
  // const [favouriteVendors, setFavouriteVendors] = useState([]);
  // const [showPincodeModal, setShowPincodeModal] = useState(false);

  const history = useHistory();

  useEffect(() => {
    getData();
  }, [isLoggedIn]);

  async function getData() {
    try {
      setLoading(true);
      const response = await getDashboard(isLoggedIn, token, user.userId);
      if (response.successCode === 1) {
        dispatch(setAdvertisement(response.response.dashboard.advertisements));
        dispatch(setVendorTypes(response.response.dashboard.vendorTypes));
        dispatch(
          setUnreadNotifications(
            Number(response.response.dashboard.numberOfNotifications)
          )
        );
        localStorage.setItem('data', JSON.stringify(response));
        if (response.response.dashboard.nearByVendors) {
          dispatch(setNearByVendors(response.response.dashboard.nearByVendors));
        }
        if (response.response.dashboard.favouriteVendors) {
          dispatch(
            setFavouriteVendors(response.response.dashboard.favouriteVendors)
          );
        }
        if (response.response.dashboard.favouriteVendors === null) {
          dispatch(setFavouriteVendors(null));
        }
        setLoading(false);
      } else {
        console.log('error');
        setLoading(false);
      }
      console.log(response);
    } catch (error) {
      setLoading(false);
      let collection = localStorage.getItem('data');
      if (collection) {
        let data = JSON.parse(collection);
        if (data) {
          dispatch(setAdvertisement(data.response.dashboard.advertisements));
          dispatch(setVendorTypes(data.response.dashboard.vendorTypes));
          if (data.response.dashboard.nearByVendors) {
            setNearByVendors(data.response.dashboard.nearByVendors);
          }
          if (data.response.dashboard.favouriteVendors) {
            setFavouriteVendors(data.response.dashboard.favouriteVendors);
          }
        }
      }
      console.log(error);
    }
  }

  return (
    <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
      <div>
        <WebToolbar fetchNotifications={false} />
        {/* <ActivityIndicator /> */}
        <PincodeModal
          visible={showPincodeModal}
          hide={() => dispatch(setShowPincodeModal(false))}
        />
        <div className={classes.main}></div>
        {loading ? (
          <ActivityIndicator />
        ) : (
          <div style={{padding: 4, paddingBottom: 100}}>
            <VendorSearchbar
              showPincodeModal={() => dispatch(setShowPincodeModal(false))}
            />
            <ImageSlider
              images={advertisements}
              onImageClick={(clickedElement) => {
                if (clickedElement.requireNewPage) {
                  history.push('/advertisementdetails');
                } else if (clickedElement?.vendorId > 0) {
                  history.push(`/vendordetails/${clickedElement?.vendorId}`);
                }
              }}
            />
            <Categories
              categories={vendorTypes}
              showPincodeModal={() => dispatch(setShowPincodeModal(false))}
            />
            {favouriteVendors?.length > 0 && (
              <VendorList
                vendors={favouriteVendors}
                title="Favourite Vendors"
              />
            )}
            {nearByVendors?.length > 0 && (
              <VendorList vendors={nearByVendors} title="Nearby Vendors" />
            )}
          </div>
        )}
        <MobileFooter />
      </div>
    </ErrorBoundary>
  );
}

export default MobileHome;
