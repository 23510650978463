import { makeStyles } from '@material-ui/core';

const useStyle = makeStyles(() => ({
    title: {
        fontSize: '16px',
        fontWeight: '900',
    },
}));

export default useStyle;
