import baseUrl from '../utils/Constants';
import getMessageFromStatus from '../utils/getMessageFromStatus';

export default async function (payload, token) {
  try {
    const formBody = JSON.stringify(payload, (key, value) => {
      if (value !== null) {
        return value;
      }
      return {};
    });
    const response = await fetch(`${baseUrl}/usergroups/baskets/AddItem`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: formBody,
    });
    if (response.status === 200) {
      const responseJson = await response.json();
      return responseJson;
    } else {
      return {
        successCode: 0,
        messageCode: getMessageFromStatus(response.status),
        statusCode: response.status,
        url: response.url,
      };
    }
  } catch (error) {
    return {
      successCode: 0,
      messageCode: getMessageFromStatus(0),
    };
  }
}
