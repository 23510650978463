/* eslint-disable react/prop-types */
import {
  Box,
  Grid,
  Typography,
  Button,
  TextField,
  Link,
} from '@material-ui/core';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import React, {useState} from 'react';
import DateFnsUtils from '@date-io/date-fns';
import useStyle from './style';
import WebToolbar from '../../components/toolbar/WebToolbar';
import AppBarName from '../../components/DumbComponents/AppBarName';
import SideNav from '../../components/sidenav/SideNav';
import {useHistory} from 'react-router';
import ErrorBoundary from '../../components/ErrorBoundary';
import ErrorBoundaryFallback from '../../components/ErrorBoundaryFallback';
import MobileFooter from '../MobileFooter';
import moment from 'moment';

const TextFieldComponent = (props) => {
  return <TextField {...props} disabled={true} />;
};

const AdvertisementDetailsScreen = (props) => {
  const {item: advertisement} = props.location.state;
  const classes = useStyle();
  const history = useHistory();
  const [selectedStartDate, setSelectedStartDate] = useState(new Date());
  const [selectedEndDate, setSelectedEndDate] = useState(
    selectedStartDate + 15
  );

  return (
    <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
      <div className={classes.container}>
        <WebToolbar />{' '}
        <div className={classes.sideNav}>
          <SideNav />
        </div>
        <AppBarName title={'Advertisement Details'} />
        <Box
          p={1}
          style={{
            display: 'flex',
            width: '70%',
            justifyContent: 'center',
            alignItems: 'center',
            margin: 'auto',
          }}
        >
          <Grid container direction={'column'} spacing={1}>
            <Grid item>
              <Box
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Typography style={{fontSize: '16px'}}>
                  Advertisement status : &nbsp;
                </Typography>
                <Typography>Active</Typography>
              </Box>
            </Grid>
            <Grid item>
              <Typography>
                {`Booking Details : ${moment(selectedStartDate).format(
                  'DD/MM/yyyy'
                )}`}{' '}
                {`(Advertisement will end after 15 days)`}
              </Typography>
            </Grid>
            <Grid item>
              <Box
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <Typography>Adv type details : {advertisement.type}</Typography>
                <Typography>Locality : NA</Typography>
                <Typography>Level : {advertisement.level}</Typography>
                <Typography>
                  Advertisement details : {advertisement.text}
                </Typography>
                <img
                  src={advertisement.image}
                  style={{width: '15rem', height: '10rem'}}
                />
              </Box>
            </Grid>
            {/* <Grid item>
              <Link>Advertisement Detail page</Link>
            </Grid> */}
            {/* <Grid item>
              <Link
                onClick={() => {
                  history.push('advertisementpricingdetails');
                }}
              >
                Amount details page
              </Link>
            </Grid> */}
            {/* <Grid item>
              <Box
                style={{
                  display: 'flex',
                  justifyContent: 'space-evenly',
                }}
              >
                <Button
                  variant="outlined"
                  style={{width: '30%', borderRadius: '25px'}}
                >
                  Disable
                </Button>
                <Button
                  disabled={true}
                  variant="outlined"
                  style={{width: '30%', borderRadius: '25px'}}
                >
                  Book Again
                </Button>
              </Box>
            </Grid> */}
          </Grid>
        </Box>
      </div>
      <MobileFooter />
    </ErrorBoundary>
  );
};

export default AdvertisementDetailsScreen;
