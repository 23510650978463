import {makeStyles} from '@material-ui/styles';

const useStyle = makeStyles((theme) => ({
  container: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    paddingBottom: '100px',
    width: '80%',
    margin: 'auto',
    backgroundColor: 'white',
    '& > header > header': {
      position: 'inherit',
    },
  },
  sideNav: {
    ['@media (max-width:700px)']: {
      display: 'none',
    },
    //height: '100%',
    width: '92px',
    position: 'fixed',
    zIndex: 1,
    top: 0,
    // right: 0,
    right: '15px',
    overflowX: 'hidden',
    marginTop: 190,
    // marginRight: theme.spacing(1),
    marginRight: 0,
    backgroundColor: '#3B3B3B',
    elevation: 2,
  },
  textInput: {
    borderRadius: 5,
    flex: 1,
    padding: 5,
    backgroundColor: 'transparent',
  },
  btn: {},
  deletBtn: {
    backgroundColor: 'red',
    color: 'white',
    width: '40%',
    '&:hover': {
      backgroundColor: '#fff',
      color: 'red',
    },
  },
}));

export default useStyle;
