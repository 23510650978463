import baseUrl from '../utils/Constants';

export default async function (mobileNo, token) {
  const headers = {
    'Content-Type': 'application/json',
    appVersion: 25,
  };
  if (token) {
    headers['Authorization'] = `Bearer ${token}`;
  }
  const response = await fetch(
    `${baseUrl}/recoverpassword/otp?userName=${mobileNo}`,
    {
      method: 'GET',
      headers: headers,
    }
  );
  const responseJson = await response.json();
  return responseJson;
}
