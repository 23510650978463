import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    backgroundColor: '#fff',
    width: '80%',
    margin: 'auto',
    paddingBottom: '100px',
    '& > header > header': {
      position: 'inherit',
    },
  },
  activityIndicator: {
    height: '100%',
  },
  sideNav: {
    ['@media (max-width:700px)']: {
      display: 'none',
    },
    //height: '100%',
    width: '92px',
    position: 'fixed',
    zIndex: 1,
    top: 0,
    right: 0,
    overflowX: 'hidden',
    marginTop: 140,
    marginRight: theme.spacing(1),
    backgroundColor: '#3B3B3B',
    elevation: 2,
  },
  UiContainer: {
    display: 'flex',
    flexDirection: 'column',
    // height: '500px',
  },
  venDetailContainer: {
    display: 'flex',
    flexDirection: 'row',
    border: '1px solid gray',
    borderRadius: '5px',
  },
  leftContainer: {
    display: 'flex',
    alignItems: 'center',
    height: '96px',
    ['@media (max-width:850px)']: {
      flexDirection: 'column',
      height: 'auto',
    },
  },
  imgContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '96px',
  },
  media: {
    width: '50%',
    height: '100%',
    cursor: 'pointer',
    marginRight: '5px',
    ['@media (max-width:850px)']: {
      width: '75%',
    },
    ['@media (max-width:800px)']: {
      width: '90%',
      marginRight: '10px',
    },
  },
  avatarStyle: {
    height: '12vh',
    width: '12vh',
    marginRight: '7px',
    ['@media (max-width:700px)']: {
      height: '8vh',
      width: '8vh',
    },
  },
  rightContainer: {
    display: 'flex',
    flexDirection: 'row-reverse',
    alignItems: 'center',
    // flexWrap: 'wrap',
    ['@media (max-width:800px)']: {
      flexDirection: 'column',
      height: 'auto',
      justifyContent: 'space-evenly',
      alignItems: 'flex-start',
      margin: '5px 0',
    },
  },
  rightSectionAddress: {
    width: 'auto',
    marginRight: '5px',
    ['@media (max-width:400px)']: {
      marginRight: '0',
    },
  },
  rightSectionTimeAndPhoneContainer: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    ['@media (max-width:400px)']: {
      display: 'block',
      margin: '5px 0',
    },
  },
  rightSectionTime: {
    display: 'flex',
    alignItems: 'center',
    marginRight: '2rem',
    marginBottom: '5px',
    ['@media (max-width:400px)']: {
      marginRight: '0',
    },
  },
  rightSectionPhone: {
    display: 'flex',
    // alignItems: 'center',
    flexDirection: 'column',
  },
  detailsContainer: {
    display: 'flex',
    flexDirection: 'row',
    // flexWrap: 'wrap',
    ['@media (max-width:850px)']: {},
  },
  vendorDetailContainer: {
    display: 'flex',
    alignItems: 'center',
    width: '33%',
    flexWrap: 'wrap',
  },
  add_item_order_container: {
    display: 'flex',
    flexDirection: 'row',
    ['@media (max-width:850px)']: {
      display: 'flex',
      flexDirection: 'column',
    },
  },
  addItemContainer: {width: '100%', padding: 0},
  noItemText: {
    marginTop: '1rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  },
  innerAddItemCont: {
    display: 'flex',
    flexDirection: 'column',
    // width: '550px',
    width: '100%',
    height: '420px',
  },
  disabled_button: {
    backgroundColor: 'gray',
    color: 'white',
    bottom: 0,
    // position: 'absolute',
    borderRadius: '25px',
    width: '40%',
    marginBottom: '10px',
  },
  active_button: {
    backgroundColor: '#3578BD',
    color: 'white',
    borderRadius: '25px',
    width: '40%',
    marginBottom: '10px',
  },
  vendorName: {
    fontWeight: '900',
    fontSize: '16px',
    display: 'flex',
    textAlign: 'center',
  },
  phoneWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  titleCont: {display: 'flex', justifyContent: 'center'},
  searchBox: {
    display: 'flex',
    // flexDirection: 'column',
    alignItems: 'center',
    height: '40px',
  },
  itemsNbtnCont: {height: '85%', display: 'flex', flexDirection: 'column'},
  _items: {
    height: '100%',
    overflowY: 'scroll',
    overflowX: 'hidden',
    margin: '1px',
  },
  _itemsGrid: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    margin: '1px',
  },
  orderTxtCont: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: '#0097A7',
    color: 'white',
    padding: '7px 0px',
  },
  tabPanelOne: {
    height: '85.5%',
    maxHeight: '500px',
    width: '100%',
    overflowY: 'scroll',
  },
  tabPanelOneBox: {
    display: 'flex',
    // alignItems: 'center',
    // height: '40px',
    width: '100%',
    flexDirection: 'column',
  },
  gridContOrder: {display: 'flex', flexDirection: 'row', alignItems: 'center'},
  addDecNumCont: {display: 'flex', alignItems: 'center'},
  addDecBtn: {
    maxWidth: '25px',
    maxHeight: '25px',
    minWidth: '25px',
    minHeight: '25px',
  },
  tabPanelTwo: {
    backgroundColor: '#f2f2f2',
    height: '88%',
    maxHeight: '500px',
    width: '100%',
    overflowY: 'scroll',
    position: 'relative',
  },
  inputRounded: {
    borderRadius: '25px',
  },
  txtIpCont: {
    backgroundColor: 'white',
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: '100%',
  },
  NxtBtnCont: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  root: {
    height: '420px',
    width: '100%',
  },
  input: {display: 'none'},
}));

export default useStyles;
