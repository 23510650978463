import {Box, Typography} from '@material-ui/core';
import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {useHistory} from 'react-router';
import showToast from '../../components/Toast';
import getAbout from '../../services/getAbout';
import useStyle from './style';
// import logo from '../../assets/icons/ic_launcher.png';
import logo from '../../assets/icons/geartenlogo.png';
import ActivityIndicator from '../../components/ActivityIndicator';
import WebToolbar from '../../components/toolbar/WebToolbar';
import AppBarName from '../../components/DumbComponents/AppBarName';
import SideNav from '../../components/sidenav/SideNav';
import ErrorBoundary from '../../components/ErrorBoundary';
import ErrorBoundaryFallback from '../../components/ErrorBoundaryFallback';
import MobileFooter from '../MobileFooter';

const AboutScreen = () => {
  const token = useSelector((state) => state.user.token);
  const [content, setContent] = useState('');
  const [loading, setLoading] = useState(true);
  const history = useHistory();
  const classes = useStyle();

  useEffect(() => {
    getData();
  }, []);

  async function getData() {
    try {
      const response = await getAbout(token);
      if (response.successCode === 1) {
        console.log('getAbout response', response);
        setContent(response.response);
      } else {
        showToast('error', 'Failed to get data');
        // navigation.goBack();
        history.push('/home');
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      showToast('error', 'Failed to get data');
      //   navigation.goBack();
      history.push('/home');
      setLoading(false);
    }
  }
  return (
    <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
      <div className={classes.container}>
        <WebToolbar />
        <AppBarName title={'About'} />
        <div className={classes.sideNav}>
          <SideNav />
        </div>
        {loading ? (
          <ActivityIndicator />
        ) : (
          <Box>
            <Box p={1} style={{display: 'flex', justifyContent: 'center'}}>
              <img src={logo} className={classes.logo} />
            </Box>
            <Typography className={classes.textTitle}>GearConnect™</Typography>
            <Box style={{display: 'flex', justifyContent: 'center'}}>
              <Typography className={classes.textCompany}>
                Gearten Technologies Pvt Ltd
              </Typography>
            </Box>
            <Box p={3}>
              <Typography className={classes.text}>{content}</Typography>
            </Box>

            <Typography className={classes.textCopyright}>
              {`© ${new Date().getFullYear()} Gearten Technologies Pvt Ltd`}
            </Typography>
          </Box>
        )}
      </div>
      <MobileFooter />
    </ErrorBoundary>
  );
};

export default AboutScreen;
