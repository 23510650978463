/* eslint-disable react/prop-types */
import React, {useState, useEffect} from 'react';
import useStyle from './style';
import {
  Box,
  Button,
  createMuiTheme,
  Grid,
  TextField,
  Typography,
} from '@material-ui/core';
import {ThemeProvider} from '@material-ui/core/styles';
import {useTranslation} from 'react-i18next';
import HoursItem from '../../components/AddHoursItem';
import ActivityIndicator from '../../components/ActivityIndicator';
import {
  KeyboardTimePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import {useSelector} from 'react-redux';
import getCurrentVendor from '../../services/getCurrentVendor';
import getTypes from '../../services/getVendorTypes';
import showToast from '../../components/Toast';
import updateHours from '../../services/updateHours';
import {useHistory} from 'react-router-dom';
import ErrorBoundary from '../../components/ErrorBoundary';
import ErrorBoundaryFallback from '../../components/ErrorBoundaryFallback';

const initialState = [
  {
    day: 'Sunday',
    open: false,
    hours: [],
  },
  {
    day: 'Monday',
    open: true,
    hours: [
      {
        from: '9:00 AM',
        to: '9:00 PM',
      },
    ],
  },
  {
    day: 'Tuesday',
    open: true,
    hours: [
      {
        from: '9:00 AM',
        to: '9:00 PM',
      },
    ],
  },
  {
    day: 'Wednesday',
    open: true,
    hours: [
      {
        from: '9:00 AM',
        to: '9:00 PM',
      },
    ],
  },
  {
    day: 'Thursday',
    open: true,
    hours: [
      {
        from: '9:00 AM',
        to: '9:00 PM',
      },
    ],
  },
  {
    day: 'Friday',
    open: true,
    hours: [
      {
        from: '9:00 AM',
        to: '9:00 PM',
      },
    ],
  },
  {
    day: 'Saturday',
    open: true,
    hours: [
      {
        from: '9:00 AM',
        to: '9:00 PM',
      },
    ],
  },
];
const weekDays = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];

const materialTheme = createMuiTheme({
  overrides: {
    MuiButton: {
      textPrimary: {
        color: '#fff',
        backgroundColor: '#0277BD',
        '&:hover': {
          color: '#0277BD',
          backgroundColor: '#fff',
          border: '1px solid #0277BD',
        },
      },
    },
  },
});

const EditHoursScreen = (props) => {
  const classes = useStyle();

  const user = useSelector((state) => state.user.user);
  const token = useSelector((state) => state.user.token);
  //const {vendorDetails} = route.params;
  const [requesting, setRequesting] = useState(true);
  const [loading, setLoading] = useState(false);
  const [details, setDetails] = useState(null);
  const [types, setTypes] = useState([]);

  const {t} = useTranslation('becomeVendor');
  //   const {vendorDetails} = route.params;
  //   const {vendorDetails} = props.location.state;
  const [listData, setListData] = useState(initialState);
  const [type, setType] = useState('');
  const forceUpdate = useState()[1].bind(null, {});
  const [showTimePicker, setShowTimePicker] = useState(false);
  const [index, setIndex] = useState('');
  const [subIndex, setSubIndex] = useState('');
  const [breakStart, setBreakStart] = useState(new Date('2014-08-18T12:00:00'));
  const [breakEnd, setBreakEnd] = useState(new Date('2014-08-18T13:00:00'));
  const [timeRemarks, setTimeRemarks] = useState('');
  const [selectedTime, setSelectedTime] = useState(new Date());
  const history = useHistory();

  useEffect(() => {
    updateState();
  }, []);

  async function updateState() {
    try {
      const response = await Promise.all([
        getCurrentVendor(token),
        getTypes(token),
      ]);
      setRequesting(false);
      if (response[0].successCode === 1) {
        const {
          workingDays,
          workingHours,
          dailyBreakStartTime,
          dailyBreakEndTime,
        } = response[0].response;
        setTimeRemarks(response[0].response.timeRemarks);
        setDetails(response[0].response);
        setBreakStart(dailyBreakStartTime ? dailyBreakStartTime : '');
        setBreakEnd(dailyBreakEndTime ? dailyBreakEndTime : '');
        const days = workingDays.split(',');
        const hours = workingHours.split(',');
        const data = [];
        days.forEach((element, i) => {
          if (element === '1') {
            const temp = hours[i].split('-');
            data.push({
              day: weekDays[i],
              open: true,
              hours: [
                {
                  from: temp[0],
                  to: temp[1],
                },
              ],
            });
          } else {
            data.push({
              day: weekDays[i],
              open: false,
              hours: [
                {
                  from: '00:00',
                  to: '00:00',
                },
              ],
            });
          }
        });
        setListData(data);
        setTypes(response[1].response.vendorTypes);
      } else {
        // navigation.goBack();
        history.push('/home');
        showToast('error', response[0].returnMessage);
      }
    } catch (error) {
      setRequesting(false);
    }
  }

  const handleBreakStartTimeChange = (time) => {
    setBreakStart(time);
  };
  const handleBreakEndTimeChange = (date) => {
    setBreakEnd(date);
  };
  function addHours(index) {
    const data = listData;
    const obj = {
      from: '9:00 AM',
      to: '9:00 PM',
    };
    data[index].hours.push(obj);
    setListData(data);
    forceUpdate();
  }
  function toggleOpen(index, value) {
    const data = listData;
    data[index].open = !data[index].open;
    if (value) {
      const obj = {
        from: '9:00 AM',
        to: '9:00 PM',
      };
      data[index].hours.push(obj);
    } else {
      data[index].hours = [];
    }
    setListData(data);
    forceUpdate();
  }
  function invokeTimePicker(i, sIndex, ty, time) {
    setType(ty);
    setIndex(i);
    setSubIndex(sIndex);
    console.log(
      'i',
      i,
      'sIndex',
      sIndex,
      'ty',
      ty,
      'time',
      time,
      'listData',
      listData
    );

    // console.log('i', i, 'sIndex', sIndex, 'ty', ty, 'listData', listData);

    if (time) {
      const data = listData;
      if (ty === 'from') {
        console.log('data[i].hours[sIndex]', data[i].hours[sIndex].from);
        data[i].hours[sIndex].from = formatAMPM(new Date(time));
      } else if (ty === 'to') {
        data[i].hours[sIndex].to = formatAMPM(new Date(time));
      } else if (ty === 'breakStart') {
        setBreakStart(formatAMPM(time));
      } else if (ty === 'breakEnd') {
        setBreakEnd(formatAMPM(time));
      }
      setListData(data);
      forceUpdate();
    }
    setType('');
    setIndex('');
    setSubIndex('');
  }
  function formatAMPM(date) {
    let hours = date.getHours();
    let minutes = date.getMinutes();
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours %= 12;
    hours = hours || 12;
    minutes = minutes < 10 ? `0${minutes}` : minutes;
    const strTime = `${hours}:${minutes} ${ampm}`;
    return strTime;
  }

  async function onPressNext() {
    var day = [];
    var operatingHours = [];
    listData.forEach((element) => {
      if (element.open) {
        day.push(1);
      } else {
        day.push(0);
      }
      if (element.hours[0]) {
        operatingHours.push(`${element.hours[0].from}-${element.hours[0].to}`);
      } else {
        operatingHours.push('00-00');
      }
    });
    //var data = {...details};
    // data.WorkingDays = day;
    // data.WorkingHours = operatingHours;
    // data.TimeRemarks = timeRemarks;
    // data.breakStart = breakStart;
    // data.breakEnd = breakEnd;
    const selectedTypes = details.vendorTypes;
    let tt = null;
    for (let i = 0; i < types.length; i++) {
      for (let j = 0; j < selectedTypes.length; j++) {
        if (types[i].id === selectedTypes[j].id) {
          tt = types[i];
          break;
        }
      }
    }
    let subCats = [];
    if (t) {
      subCats = tt.subCategories;
      subCats.forEach((element) => {
        element.isSelected = false;
      });
      for (let i = 0; i < selectedTypes.length; i++) {
        for (let j = 0; j < subCats.length; j++) {
          if (selectedTypes[i].id === subCats[j].id) {
            subCats[j].isSelected = true;
          }
        }
      }
    }
    try {
      const payload = {
        VendorId: details.VendorId,
        WorkingDays: day.toString(),
        WorkingHours: operatingHours.toString(),
        dailyBreakStartTime: breakStart,
        dailyBreakEndTime: breakEnd,
        TimeRemarks: details.TimeRemarks,
        //Holidays: [],
      };
      setLoading(true);
      const response = await updateHours(payload, token);
      console.log('response', response);
      setLoading(false);
      if (response.successCode === 1) {
        showToast('success', 'Updated');
        // navigation.goBack();
        history.push('/home');
      } else {
        showToast('error', response.returnMessage);
      }
    } catch (error) {
      console.log(error);
      showToast('error', 'Failed, please try again');
      setLoading(false);
    }

    // history.push({
    //   pathname: 'addvendorshopimages',
    //   state: {vendorDetails: data},
    // });
    // navigation.navigate('AddVendorShopImages', {vendorDetails: data});
  }

  return (
    <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
      <div>
        {loading ? (
          <ActivityIndicator />
        ) : (
          <Box>
            <Typography className={classes.textTitle}>
              Working Hours :-
            </Typography>
            <ThemeProvider theme={materialTheme}>
              <Box>
                {listData.length > 0 ? (
                  listData.map((item, index) => {
                    console.log('item ld', item);
                    return (
                      <HoursItem
                        key={index}
                        day={item.day}
                        open={item.open}
                        hours={item.hours}
                        addHours={() => addHours(index)}
                        toggleSwitch={(value) => toggleOpen(index, value)}
                        invokeTimePicker={(subIndex, type, time) => {
                          invokeTimePicker(index, subIndex, type, time);
                        }}
                      />
                    );
                  })
                ) : (
                  <Box></Box>
                )}
              </Box>
              <Box py={1}>
                <Typography className={classes.textTitle}>
                  Break Time
                </Typography>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <Grid container xs={12}>
                    <Grid item xs={6} className={classes.timePickerContainer}>
                      <KeyboardTimePicker
                        margin="normal"
                        id="time-picker"
                        // label="Start Time"
                        value={new Date(`Mon 03-Jul-2017, ${breakStart}`)}
                        // onChange={handleBreakStartTimeChange}
                        onChange={(time) => {
                          // invokeTimePicker(index, subIndex, type);
                          invokeTimePicker(index, subIndex, 'breakStart', time);
                        }}
                        KeyboardButtonProps={{
                          'aria-label': 'change time',
                        }}
                      />
                    </Grid>
                    <Grid item xs={6} className={classes.timePickerContainer}>
                      <KeyboardTimePicker
                        margin="normal"
                        id="time-picker"
                        // label="End Time"
                        value={new Date(`Mon 03-Jul-2017, ${breakEnd}`)}
                        // onChange={handleBreakEndTimeChange}
                        onChange={(time) => {
                          invokeTimePicker(index, subIndex, 'breakEnd', time);
                        }}
                        KeyboardButtonProps={{
                          'aria-label': 'change time',
                        }}
                      />
                    </Grid>
                  </Grid>
                </MuiPickersUtilsProvider>
                <Box py={2}>
                  <TextField
                    fullWidth
                    id="outlined-basic"
                    label="Time Remarks"
                    placeholder="We are closed on every third Saturday"
                    value={timeRemarks}
                    size="small"
                    variant="outlined"
                    className={classes.textInput}
                    onChange={(txt) => {
                      setTimeRemarks(txt.target.value);
                    }}
                  />
                </Box>
              </Box>
            </ThemeProvider>
            <Box className={classes.btnContainer}>
              <Button
                className={classes.btn}
                variant="outlined"
                onClick={onPressNext}
              >
                {t('Update')}
              </Button>
            </Box>
          </Box>
        )}
      </div>
    </ErrorBoundary>
  );
};

export default EditHoursScreen;
