/* eslint-disable react/prop-types */
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
} from '@material-ui/core';
import SimpleSelect from '../../components/DumbComponents/SimpleSelect';
import React, {useEffect, useState} from 'react';
import useStyle from './style';
import WebToolbar from '../../components/toolbar/WebToolbar';
import AppBarName from '../../components/DumbComponents/AppBarName';
import {useHistory} from 'react-router';
import SideNav from '../../components/sidenav/SideNav';
import getListOfStates from '../../services/getListOfStates';
import {useSelector} from 'react-redux';
import ActivityIndicator from '../../components/ActivityIndicator';
import getListOfCities from '../../services/getListOfCities';
import getListOfPincodes from '../../services/getListOfPincodes';
import getListOfSublocalities from '../../services/getListOfSublocalities';
import ErrorBoundary from '../../components/ErrorBoundary';
import ErrorBoundaryFallback from '../../components/ErrorBoundaryFallback';
import MobileFooter from '../MobileFooter';

const AdvertisementLocationSelection = (props) => {
  const classes = useStyle();
  const history = useHistory();
  const [countryLevel, setCountryLevel] = useState('India');
  const [selectedState, setSelectedState] = useState('');
  const [selectedCity, setSelectedCity] = useState('');
  const [selectedPincode, setSelectedPincode] = useState('');
  const [selectedLocality, setSelectedLocality] = useState('');
  const [loading, setLoading] = useState(false);
  const [value1, setValue1] = useState('country');
  const token = useSelector((state) => state.user.token);
  const [states, setStates] = useState([]);
  const [citiess, setCitiess] = useState([]);
  const [pinCode, setPinCode] = useState([]);
  const [locality, setLocality] = useState([]);

  useEffect(() => {
    getStates();
  }, []);

  const handleChange = (event) => {
    setValue1(event.target.value);
  };

  async function getStates() {
    setLoading(true);
    try {
      const response = await getListOfStates(token);
      console.log('get states', response.response);
      if (response.successCode === 1) {
        setStates(response.response.area);
        // setStates(
        //   response.response.area.map((item) => {
        //     return item.name;
        //   })
        // );
        setLoading(false);
      } else {
        setLoading(false);
        console.log('err');
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }

  async function getCities(_stateId) {
    setLoading(true);
    try {
      const response = await getListOfCities(token, _stateId);
      console.log('get cities', response.response.area);
      if (response.successCode === 1) {
        setCitiess(response.response.area);
        setLoading(false);
      } else {
        setLoading(false);
        console.log('err');
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }

  async function getPincodeLevel(cityId) {
    setLoading(true);
    try {
      const response = await getListOfPincodes(token, cityId);
      console.log('get pincode', response);
      if (response.successCode === 1) {
        setPinCode(response.response.area);
        setLoading(false);
      } else {
        setLoading(false);
        console.log('err');
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }
  async function getLocalityLevel(localityId) {
    setLoading(true);
    try {
      const response = await getListOfSublocalities(token, localityId);
      console.log('get sublocalities', response.response.area);
      if (response.successCode === 1) {
        setLocality(response.response.area);
        setLoading(false);
      } else {
        setLoading(false);
        console.log('err');
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }

  return (
    <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
      <div className={classes.container}>
        <WebToolbar />
        <div className={classes.sideNav}>
          <SideNav />
        </div>
        <AppBarName title={'Location Selection'} />
        <Box>
          {loading ? (
            <ActivityIndicator />
          ) : (
            <>
              <Box p={1}>
                <FormControl component="fieldset" style={{width: '100%'}}>
                  <FormLabel component="legend">
                    Select Advertisement location
                  </FormLabel>
                  <RadioGroup
                    className={classes.radioGrp}
                    aria-label="gender"
                    name="gender1"
                    value={value1}
                    onChange={handleChange}
                  >
                    <FormControlLabel
                      value="country"
                      control={<Radio />}
                      label="Country level(India)"
                    />
                    <FormControlLabel
                      value="state"
                      control={<Radio />}
                      label="State level"
                    />
                    <FormControlLabel
                      value="city"
                      control={<Radio />}
                      label="City level"
                    />
                    <FormControlLabel
                      value="pincode"
                      control={<Radio />}
                      label="Pincode level"
                    />
                    <FormControlLabel
                      value="locality"
                      control={<Radio />}
                      label="Locality level"
                    />
                  </RadioGroup>
                </FormControl>
              </Box>
              <Box px={2}>
                {value1 === 'locality' ? (
                  <Grid container direction={'column'} spacing={3}>
                    <Grid item>
                      <SimpleSelect
                        title="Select State"
                        label="State Level"
                        val={states}
                        selectedEle={selectedState}
                        setLoc={(e) => {
                          const value = e.target.value;
                          const myJSON = JSON.parse(value);
                          setSelectedState(myJSON);
                          getCities(myJSON.id);
                        }}
                      />
                    </Grid>
                    <Grid item>
                      <SimpleSelect
                        title="Select City"
                        label="City Level"
                        val={citiess}
                        selectedEle={selectedCity}
                        setLoc={(e) => {
                          const value = e.target.value;
                          const myJSON = JSON.parse(value);
                          setSelectedCity(myJSON);
                          getPincodeLevel(myJSON.id);
                        }}
                      />
                    </Grid>
                    <Grid item>
                      <SimpleSelect
                        title="Select Pincode"
                        label="Pincode Level"
                        val={pinCode}
                        selectedEle={selectedPincode}
                        setLoc={(e) => {
                          const value = e.target.value;
                          const myJSON = JSON.parse(value);
                          setSelectedPincode(myJSON);
                          getLocalityLevel(myJSON.id);
                        }}
                      />
                    </Grid>
                    <Grid item>
                      {console.log('sle', selectedLocality)}
                      <SimpleSelect
                        title="Select Locality"
                        label="Locality Level"
                        val={locality}
                        selectedEle={selectedLocality}
                        setLoc={(e) => {
                          const value = e.target.value;
                          const myJSON = JSON.parse(value);
                          setSelectedLocality(myJSON);
                        }}
                      />
                    </Grid>
                  </Grid>
                ) : (
                  ''
                )}
              </Box>
              <Box px={2}>
                {value1 === 'pincode' ? (
                  <Grid container direction={'column'} spacing={3}>
                    <Grid item>
                      <SimpleSelect
                        title="Select State"
                        label="State Level"
                        val={states}
                        selectedEle={selectedState}
                        setLoc={(e) => {
                          const value = e.target.value;
                          const myJSON = JSON.parse(value);
                          setSelectedState(myJSON);
                          getCities(myJSON.id);
                        }}
                      />
                    </Grid>
                    <Grid item>
                      <SimpleSelect
                        title="Select City"
                        label="City Level"
                        val={citiess}
                        selectedEle={selectedCity}
                        setLoc={(e) => {
                          const value = e.target.value;
                          const myJSON = JSON.parse(value);
                          getPincodeLevel(myJSON.id);
                          setSelectedCity(myJSON);
                        }}
                      />
                    </Grid>
                    <Grid item>
                      <SimpleSelect
                        title="Select Pincode"
                        label="Pincode Level"
                        val={pinCode}
                        selectedEle={selectedPincode}
                        setLoc={(e) => {
                          const value = e.target.value;
                          const myJSON = JSON.parse(value);
                          setSelectedPincode(myJSON);
                        }}
                      />
                    </Grid>
                  </Grid>
                ) : (
                  ''
                )}
              </Box>
              <Box px={2}>
                {value1 === 'city' ? (
                  <Grid container direction={'column'} spacing={3}>
                    <Grid item>
                      <SimpleSelect
                        title="Select State"
                        label="State Level"
                        val={states}
                        selectedEle={selectedState}
                        setLoc={(e) => {
                          const value = e.target.value;
                          const myJSON = JSON.parse(value);
                          setSelectedState(myJSON);
                          getCities(myJSON.id);
                        }}
                      />
                    </Grid>
                    <Grid item>
                      <SimpleSelect
                        title="Select City"
                        label="City Level"
                        val={citiess}
                        selectedEle={selectedCity}
                        setLoc={(e) => {
                          const myJSON = JSON.parse(e.target.value);
                          setSelectedCity(myJSON);
                        }}
                      />
                    </Grid>
                  </Grid>
                ) : (
                  ''
                )}
              </Box>
              <Box px={2}>
                {console.log('selectedState', selectedState)}
                {value1 === 'state' ? (
                  <Box>
                    <SimpleSelect
                      title="Select State"
                      label="State Level"
                      val={states}
                      selectedEle={selectedState}
                      setLoc={(e) => {
                        const value = e.target.value;
                        const myJSON = JSON.parse(value);
                        setSelectedState(myJSON);
                      }}
                    />
                  </Box>
                ) : (
                  ''
                )}
              </Box>
              <Box
                py={2}
                style={{
                  display: 'flex',
                  width: '100%',
                  justifyContent: 'center',
                }}
              >
                <Button
                  variant="outlined"
                  size="small"
                  style={{width: '30%', borderRadius: '25px'}}
                  onClick={() => {
                    let obj;
                    if (value1 === 'state') {
                      obj = {
                        _state: props.location.state,
                        loc: selectedState.name,
                      };
                    } else if (value1 === 'city') {
                      obj = {
                        _state: props.location.state,
                        state: selectedState.name,
                        city: selectedCity.name,
                      };
                    } else if (value1 === 'pincode') {
                      obj = {
                        _state: props.location.state,
                        state: selectedState.name,
                        city: selectedCity.name,
                        pin: selectedPincode.id,
                      };
                    } else if (value1 === 'locality') {
                      obj = {
                        _state: props.location.state,
                        state: selectedState.name,
                        city: selectedCity.name,
                        pin: selectedPincode.id,
                        loca: selectedLocality.name,
                      };
                    } else {
                      obj = {
                        _state: props.location.state,
                        level: countryLevel,
                      };
                    }

                    console.log('obj', obj);
                    history.push({
                      pathname: '/advertisementpricingdetails',
                      state: obj,
                    });
                  }}
                >
                  Next
                </Button>
              </Box>
            </>
          )}
        </Box>
      </div>
      <MobileFooter />
    </ErrorBoundary>
  );
};

export default AdvertisementLocationSelection;
