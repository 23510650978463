import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {Grid, Typography, Box, Button, Divider} from '@material-ui/core';
import {get, isEmpty} from 'lodash';
import {useHistory} from 'react-router-dom';
import {useSelector, useDispatch} from 'react-redux';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import NotFound from '../../pages/NotFound';
import useStyles from './styles';
import {getVendorDetails} from './utils';
import __getVendorDetails from '../../services/getVendorDetails';
import {
  setSelectedCartItems,
  setSelectedVendorForOrder,
} from '../../redux/actions/user';
import showToast from '../../components/Toast';
import {getAddress} from '../../utils/common';

function VendorList({vendors, title}) {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  // const [homeDeliveryAvailable, setHomeDeliveryAvailable] = useState('');

  function renderItems(item) {
    console.log('press', item);
    if (!item) {
      return <></>;
    }
    const vaal = get(item, 'provideHomeDelivery', false) ? 'Yes' : 'No';

    const getUserAddress = (addr) => {
      let address = getAddress(addr);
      if (address.length === 0) {
        return <></>;
      }

      return (
        <Typography style={{fontSize: '14px'}}>{address + '.'}</Typography>
      );
    };

    return (
      <>
        <Box
          px={1}
          pt={1}
          pb={1}
          style={{cursor: 'pointer'}}
          onClick={() => {
            console.log('press', item);
            dispatch(setSelectedVendorForOrder({vendor: item}));
            dispatch(setSelectedCartItems([]));
            history.push({pathname: '/placeorder', state: {messages: ''}});
          }}
        >
          <Typography style={{fontWeight: '600', fontSize: '16px'}}>
            {item.organizationName}
          </Typography>
          <Typography
            style={{fontSize: '14px'}}
          >{`Home Delivery Available - ${vaal}`}</Typography>
          {getUserAddress(item)}
        </Box>
        <Divider />
        {/* <Button
          variant="outlined"
          onClick={() => {
            showToast('error', 'Demo toast');
          }}
        >
          Show Toast
        </Button> */}
      </>
      // <Box className={classes.container_one}>
      //   <Box boxShadow={3}>
      //     <Box>
      //       <Typography className={classes.org_name_txt}>
      //         {/* {get(item, 'organizationName', '')} */}
      //         {get(vendors, 'organizationName', '')}
      //       </Typography>
      //     </Box>
      //     <Box className={classes.categories_container}>
      //       {get(item, 'categories', []).map((val, index) => {
      //         return (
      //           <Button
      //             key={index}
      //             variant="outlined"
      //             className={classes.categories_btn}
      //           >
      //             {val.description}
      //           </Button>
      //         );
      //       })}
      //     </Box>
      //   </Box>
      //   {/* <img
      //     className={classes.media}
      //     draggable={false}
      //     // src={item.vendorHeaderImage}
      //     src="https://bit.ly/3GyND6O"
      //   /> */}
      //   <Grid
      //     container
      //     item
      //     xs={12}
      //     className={classes.vendorDetails}
      //     boxShadow={3}
      //   >
      //     <Grid md={2}>
      //       <Box className={classes.imgContainer}>
      //         <img
      //           className={classes.media}
      //           draggable={false}
      //           // src={item.vendorHeaderImage}
      //           src="https://bit.ly/3GyND6O"
      //         />
      //       </Box>
      //     </Grid>
      //     <Grid md={7} style={{display: 'flex'}}>
      //       <Grid container>
      //         {getVendorDetails(vendors).map((val) => {
      //           return (
      //             // eslint-disable-next-line react/jsx-key
      //             <Grid xs={4} className={classes.vendor_detail_container}>
      //               <Grid xs={2}>
      //                 <val.icon />
      //               </Grid>
      //               <Grid xs={4}>
      //                 <Typography className={classes.vendor_detail_title}>
      //                   {val.title}&nbsp;
      //                 </Typography>
      //               </Grid>
      //               <Grid xs={6}>
      //                 <Typography className={classes.vendor_detail_value}>
      //                   {val.value}
      //                 </Typography>
      //               </Grid>
      //             </Grid>
      //           );
      //         })}
      //       </Grid>
      //     </Grid>
      //     <Grid md={3} className={classes.order_now_button_grid}>
      //       <Box>
      //         <Button
      //           variant="outlined"
      //           className={classes.order_now_button}
      //           endIcon={<ArrowForwardIosIcon />}
      //           onClick={() => {
      //             dispatch(setSelectedVendorForOrder(item));
      //             history.push('/placeorder');
      //           }}
      //         >
      //           Order Now
      //         </Button>
      //       </Box>
      //     </Grid>
      //   </Grid>
      // </Box>
    );
  }

  return (
    <div className={classes.container}>
      <Grid className={classes.root} container spacing={1}>
        {!isEmpty(title) ? (
          <Box px={1} className={classes.bgTitle}>
            <Typography style={{fontWeight: 900, fontSize: '16px'}}>
              {title}
            </Typography>
          </Box>
        ) : (
          ''
        )}
        {!isEmpty(vendors) ? (
          vendors.map((text, index) => (
            <Box key={index} style={{width: '100%'}}>
              {renderItems(text)}
            </Box>
          ))
        ) : (
          <NotFound />
        )}
      </Grid>
    </div>
  );
}

VendorList.propTypes = {
  vendors: PropTypes.array,
  title: PropTypes.string,
};

export default VendorList;
