/* Copyright (C) 2020 Gearten Technologies Private Limited - All Rights Reserved
 NOTICE:  All information contained herein is, and remains
 * the property of Gearten Technologies Private Limited
 * The intellectual and technical concepts contained
 * herein are proprietary to Gearten Technologies Private Limited.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Gearten Technologies Private Limited.
 */
export const SET_USER = 'SET_USER';
export const SET_IS_LOGGEDIN = 'SET_IS_LOGGEDIN';
export const SET_IS_TRUE = 'SET_IS_TRUE';

export const SET_TOKEN = 'SET_TOKEN';
export const SET_SAVED_ITEMS = 'SET_SAVED_ITEMS';
export const SET_PROFILE_PIC = 'SET_PROFILE_PIC';
export const SET_UNREAD_NOTIFICATIONS = 'SET_UNREAD_NOTIFICATIONS';
export const SET_VENDOR_ID = 'SET_VENDOR_ID';
export const SET_TERMS_ACCEPTED = 'SET_TERMS_ACCEPTED';
export const SET_PINCODE = 'SET_PINCODE';
//------------------------------------------------------
export const SET_ADVERTISEMENT = 'SET_ADVERTISEMENT';
export const SET_VENDOR_TYPES = 'SET_VENDOR_TYPES';
export const SET_NEARBY_VENDORS = 'SET_NEARBY_VENDORS';
export const SET_FAVOURITE_VENDORS = 'SET_FAVOURITE_VENDORS';
export const SET_SHOW_PINCODE_MODAL = 'SET_SHOW_PINCODE_MODAL';
export const SET_VENDORS = 'SET_VENDORS';
export const SET_EMAIL = 'SET_EMAIL';
export const SET_PASSWORD = 'SET_PASSWORD';
export const SET_SELECTED_VENDOR_FOR_ORDER = 'SET_SELECTED_VENDOR_FOR_ORDER';
export const SET_REPEAT_ORDER_CHECKED = 'SET_REPEAT_ORDER_CHECKED';
export const SET_HOME_DELIVERY_CHECKED = 'SET_HOME_DELIVERY_CHECKED';
export const SET_SELECTED_CART_ITEMS = 'SET_SELECTED_CART_ITEMS';
export const NAME = 'NAME';
export const SET_NOTIFICATION_DATA = 'SET_NOTIFICATION_DATA';
export const SET_RECEIVED_ORDERS = 'SET_RECEIVED_ORDERS';
export const SET_PLACED_ORDERS = 'SET_PLACED_ORDERS';
export const SET_LANGUAGE = 'SET_LANGUAGE';
export const SET_VENDOR_DETAILS = 'SET_VENDOR_DETAILS';
export const SET_ORDER_ID = 'SET_ORDER_ID';
export const SET_CART_ORDER_ID = 'SET_CART_ORDER_ID';
export const SET_GROUP_ID = 'SET_GROUP_ID';
export const SET_GROUP_DETAILS = 'SET_GROUP_DETAILS';
export const SET_BASKET_DETAILS = 'SET_BASKET_DETAILS';
export const SET_ITEMS_LIST_DATA = 'SET_ITEMS_LIST_DATA';
export const SET_BILL_DETAILS = 'SET_BILL_DETAILS';

export const SET_CATALOUGE_MODAL_COUNT = 'SET_CATALOUGE_MODAL_COUNT';
export const SET_REFRESH_FAV = 'SET_REFRESH_FAV';
export const SET_VENDOR = 'SET_REFRESH_FAV';
export const SET_VENDOR_SHOP_DEATAILS = 'SET_VENDOR_SHOP_DEATAILS';

export const SET_TYPE_OF_ADVERTISEMENT = 'SET_TYPE_OF_ADVERTISEMENT';
export const SET_NEW_ADVERTISEMENT_DETAILS = 'SET_NEW_ADVERTISEMENT_DETAILS';

export const SET_UPI_IDS = 'SET_UPI_IDS';

export const SET_UPDATE_ORDER_DETAILS = 'SET_UPDATE_ORDER_DETAILS';
export const SET_PLACE_BASKET_ORDER = 'SET_PLACE_BASKET_ORDER';
export const SET_SELECT_ITEMS_DATA = 'SET_SELECT_ITEMS_DATA';
