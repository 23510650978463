/* eslint-disable react/prop-types */
import React from 'react';
import useStyle from './styles';
import {Box, Typography, Link, Grid} from '@material-ui/core';
import moment from 'moment';
import {useHistory} from 'react-router';
import {get} from 'lodash';
import CallIcon from '@material-ui/icons/Call';
import {constants, getAddress} from '../../utils/common';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';

const HeaderSection = ({
  orderDetails,
  __orderId,
  orderAmt,
  total,
  pageType,
}) => {
  const classes = useStyle();
  const history = useHistory();
  const user = useSelector((state) => state.user.user);
  const {
    ORDER_PLACED,
    ORDER_RECEIVED,
    PAYMENT_REQUEST_CREATED,
    PAYMENT_REQUEST_RECEIVED,
  } = constants;
  const {t} = useTranslation(['orderDetails', 'common']);

  const isOrderPlacedOrCreated = () => {
    if (pageType === ORDER_PLACED || pageType === PAYMENT_REQUEST_CREATED) {
      return true;
    }
    return false;
  };

  const getUserAddress = (userAddress) => {
    let address = getAddress(userAddress);
    if (address.length === 0) {
      return <></>;
    }

    return (
      <Typography className={classes.ordersText}>{address + '.'}</Typography>
    );
  };

  return (
    <Box boxShadow={1} p={1} className={classes.orderDetailsContainer}>
      <Grid container>
        <Grid item xs={6}>
          <Typography className={(classes.ordersText, classes.orderIdTxt)}>
            Order ID: {get(orderDetails, 'orderID', '')}
          </Typography>
          {isOrderPlacedOrCreated() ? (
            <Box className={classes.containerOrderBy}>
              <Typography
                className={classes.ordersText}
                onClick={() => {
                  history.push({
                    pathname: `/vendordetails/${orderDetails?.vendorId}`,
                  });
                }}
              >
                Order to :{' '}
                <Link>
                  {pageType === PAYMENT_REQUEST_CREATED
                    ? get(orderDetails, 'customer.name', '')
                    : get(orderDetails, 'vendorName', '')}
                </Link>
                &nbsp;
              </Typography>
              <a
                href={`tel:${
                  pageType === PAYMENT_REQUEST_CREATED
                    ? get(orderDetails, 'customer.mobile', '')
                    : orderDetails?.vendorMobileNo
                }`}
                style={{textDecoration: 'none'}}
              >
                <CallIcon className={classes.phoneIcon} />
              </a>
            </Box>
          ) : (
            <Box className={classes.containerOrderBy}>
              <Typography
                className={classes.ordersText}
                onClick={() => {
                  history.push({
                    pathname: '/customerdetails',
                    // state: get(props, 'location.orderID', ''),
                    state: {
                      phone: orderDetails.customerMobile,
                      name: orderDetails.orderRequestedBy,
                      customerId: orderDetails.userId,
                      address: __orderId.address,
                    },
                  });
                }}
              >
                Order by :{' '}
                <Link>
                  {pageType === PAYMENT_REQUEST_RECEIVED
                    ? get(orderDetails, 'vendorName', '')
                    : get(orderDetails, 'customer.name', '')}
                </Link>
                &nbsp;
              </Typography>
              <a
                href={`tel:${
                  pageType === PAYMENT_REQUEST_RECEIVED
                    ? orderDetails?.vendorMobileNo
                    : get(orderDetails, 'customer.mobile')
                }`}
                style={{textDecoration: 'none'}}
              >
                <CallIcon className={classes.phoneIcon} />
              </a>
            </Box>
          )}
          {getUserAddress(
            isOrderPlacedOrCreated()
              ? pageType === PAYMENT_REQUEST_CREATED
                ? get(orderDetails, 'customer.address', null)
                : null
              : pageType === PAYMENT_REQUEST_RECEIVED
              ? null
              : get(orderDetails, 'customer.address', null)
          )}
          <Typography className={classes.ordersText}>
            Ordered On:{' '}
            {moment(get(orderDetails, 'orderDate', '')).format(
              'h:mm A, DD/MM/YYYY'
            )}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography
            component={'span'}
            className={classes.ordersText}
            style={{fontWeight: 900}}
          >
            {`${t(get(orderDetails, 'statusLiteral', ''))}`}
          </Typography>
          <Typography className={classes.ordersText}>
            Total Items: {get(orderDetails, 'orderItems.length', '')}
          </Typography>

          {total > 0 && (
            <Typography
              className={classes.ordersText}
            >{`Sub-Total: ₹ ${total}`}</Typography>
          )}
          {orderAmt > 0 ? (
            <Typography
              className={classes.ordersText}
            >{`Total: ₹ ${orderAmt}`}</Typography>
          ) : (
            <Typography className={classes.ordersText}>
              {'Total: Confirm with vendor'}
            </Typography>
          )}
          {/* <Typography className={classes.ordersText}>
    Total: ₹ {get(orderDetails, 'totalOrderAmount', '')}
  </Typography> */}
        </Grid>
      </Grid>
    </Box>
  );
};

export default HeaderSection;
