import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    paddingBottom: '100px',
    backgroundColor: 'white',
    width: '80%',
    margin: 'auto',
    '& > header > header': {
      position: 'inherit',
    },
  },
  submitButtons: {
    margin: '10px',
    borderRadius: '25px',
    width: '30%',
    minWidth: '20rem',
    ['@media (max-width:700px)']: {
      minWidth: 'auto',
      width: '10rem',
    },
  },
  sideNav: {
    ['@media (max-width:700px)']: {
      display: 'none',
    },
    //height: '100%',
    width: '92px',
    position: 'fixed',
    zIndex: 1,
    top: 0,
    right: 0,
    overflowX: 'hidden',
    marginTop: 190,
    // marginRight: theme.spacing(1),
    marginRight: 0,
    backgroundColor: '#3B3B3B',
    elevation: 2,
  },
  root: {
    fontSize: '1rem',
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.8rem',
    },
  },
}));

export default useStyles;
