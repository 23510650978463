import messages from './httpCodesToMessage';

export default function (code) {
  const msg = messages[code];
  if (msg) {
    return msg;
  } else {
    return 'Failed';
  }
}
