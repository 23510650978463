/* eslint-disable react/prop-types */
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  Divider,
  List,
  ListItem,
  ListItemText,
  Tooltip,
  Typography,
} from '@material-ui/core';
import {Autocomplete} from '@material-ui/lab';
import {debounce, isEmpty, lowerCase, upperCase} from 'lodash';
import moment from 'moment';
import React, {useCallback, useEffect, useState} from 'react';
import useStyle from './style';
import FilterListIcon from '@material-ui/icons/FilterList';
import AccountBoxOutlinedIcon from '@material-ui/icons/AccountBoxOutlined';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {useHistory} from 'react-router';
import {
  setOrderId,
  setPlacedOrders,
  setReceivedOrders,
} from '../../redux/actions/user';
import showToast from '../../components/Toast';
import searchVendorOrders from '../../services/searchVendorOrders';
import filterItems from '../ReceivedOrders/utils';
import PropTypes from 'prop-types';
import getVendorsOrders from '../../services/getVendorsOrders';
// import getVendorsPlacedOrders from '../../services/getVendorsPlacedOrders';
import getOrderStates from '../../services/getOrderStates';
import ActivityIndicator from '../../components/ActivityIndicator';
import SimpleAutocomplete from '../../components/DumbComponents/SimpleAutocomplete';
import ErrorBoundary from '../../components/ErrorBoundary';
import ErrorBoundaryFallback from '../../components/ErrorBoundaryFallback';
import InifiniteScrollComponent from '../../components/InifiniteScrollComponent';

function SimpleDialog(props) {
  // eslint-disable-next-line react/prop-types
  const {onClose, selectedValue, open, orders, setListD, listD} = props;
  const {t} = useTranslation(['orderDetails', 'common']);
  const handleClose = () => {
    onClose(selectedValue);
  };

  const handleListItemClick = (value) => {
    console.log('value', value);
    const _filteredItems = orders.filter((item) => {
      console.log(
        'upperCase(value)',
        value,
        '{t(item.status)}',
        t(item.status)
      );
      if (value === 'All') {
        return listD;
      }
      if (t(item.status) === value) {
        return item;
      }
    });
    console.log('filteredItems', _filteredItems);
    // setOrders(filteredItems);
    setListD(_filteredItems);
    onClose(value);
  };

  return (
    <Dialog
      style={{
        height: '400px',
        position: 'absolute',
        left: '43%',
        top: '22%',
      }}
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <Box
        style={{
          display: 'flex',
          alignItems: 'center',
          backgroundColor: '#F7F7F7',
        }}
      >
        <DialogTitle id="simple-dialog-title">Filter By :-</DialogTitle>
        <FilterListIcon style={{fontSize: 28}}></FilterListIcon>
      </Box>

      <List>
        {filterItems.map((email, index) => (
          <Box key={index}>
            <ListItem
              button
              onClick={() => handleListItemClick(email)}
              key={email}
            >
              <ListItemText primary={email} />
            </ListItem>
            <Divider component="li" />
          </Box>
        ))}
      </List>
    </Dialog>
  );
}

SimpleDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired,
  orders: PropTypes.array.isRequired,
  dispatch: PropTypes.func.isRequired,
};

const VendorOrdersScreen = ({pageType}) => {
  const classes = useStyle();
  const dispatch = useDispatch();
  const history = useHistory();
  const {t} = useTranslation(['orderDetails', 'common']);
  const [lastOrderId, setLastOrderId] = useState(0);
  const [listDataReceived, setListDataReceived] = useState([]);
  const token = useSelector((state) => state.user.token);
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searching, setSearching] = useState(false);
  const [query, setQuery] = useState('');
  const [openFilterDialog, setOpenFilterDialog] = useState(false);
  const [selectedValue, setSelectedValue] = useState([filterItems[0]]);
  const receivedOrders = useSelector((state) => state.user.receivedOrders);

  // useEffect(() => {
  //   getVendorsOrdersData();
  // }, []);

  const getVendorsOrdersData = async (currPage, setLastList, setPrevPage) => {
    try {
      let _lastId = lastOrderId;
      if (listDataReceived.length) {
        _lastId = listDataReceived[listDataReceived.length - 1]['orderId'];
      }
      // const response = await Promise.all([
      //   getVendorsOrders(token, lastOrderId),
      //   getOrderStates(token),
      // ]);
      const response = await getVendorsOrders(token, lastOrderId);
      if (response.successCode === 1) {
        if (!response.response.orders.length) {
          setLastList(true);
          return;
        }
        setLastOrderId(_lastId);
        setPrevPage(currPage);
        let newListData = [...listDataReceived, ...response.response.orders];
        setListDataReceived(newListData);
        setOrders(newListData);
        // setListDataReceived(response.response.orders);
        // setOrders(response.response.orders);
        let data = [];
        const isRequests = false;
        if (isRequests) {
          data = response.response.orders.filter(
            (o) => o.customerName === 'VENDOR_GENERATED_ORDER'
          );
        } else {
          data = response.response.orders.filter(
            (o) => o.customerName !== 'VENDOR_GENERATED_ORDER'
          );
        }

        dispatch(setReceivedOrders(data));
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (error) {
      console.log('error', error);
      setLoading(false);
    }
  };

  const _handler = useCallback(
    debounce(async (text) => {
      console.log('text', text);
      if (!text.trim()) {
        return;
      }
      try {
        console.log('text', text);
        const response = await searchVendorOrders(token, text);
        console.log('serach orders response', response);
        if (response.successCode === 1) {
          setListDataReceived(response.response.orders);
        } else {
          showToast('error', 'No matching orders found.');
          setListDataReceived(orders);
        }
      } catch (error) {}
    }, 1000),
    []
  );

  const _handleSearchedOrder = (q) => {
    console.log('q value', q);
    setQuery(q);
    if (q.length > 0) {
      _handler(q);
    } else {
      console.log('in else', listDataReceived);
      setListDataReceived(orders);
    }
  };

  const openFilterDialogFunction = () => {
    setOpenFilterDialog(!openFilterDialog);
  };

  const closeFilterDialogFunction = (value) => {
    setOpenFilterDialog(false);
    setSelectedValue(value);
  };

  const getListItem = (item, index) => {
    return (
      <Box key={index}>
        <Box
          className={classes.receivedOrdersContainer}
          onClick={() => {
            dispatch(
              setOrderId({
                orderId: item.orderId,
                address: item.customerAddress,
                from: 'vendorOrdersScreen',
              })
            );
            history.push({
              pathname: '/orderDetails',
              state: {
                pageType: pageType,
              },
            });
          }}
        >
          <Box
            style={{
              width: '65%',
            }}
          >
            <Typography
              className={classes.ordersText}
              component={'span'}
              variant={'body2'}
            >{`Order ID: ${item.orderId}`}</Typography>
            {item.customerName ? (
              <Typography
                className={classes.ordersText}
                component={'span'}
                variant={'body2'}
              >
                {`Order By: ${item.customerName}`}
              </Typography>
            ) : (
              ''
            )}
            {item.orderRequestedBy ? (
              <Typography
                className={classes.ordersText}
                component={'span'}
                variant={'body2'}
              >
                {`Order By: ${item.orderRequestedBy}`}
              </Typography>
            ) : (
              ''
            )}

            <Typography
              className={classes.ordersText}
              component={'span'}
              variant={'body2'}
            >{`Date: ${moment(item.orderDate).format(
              'DD/MM/YYYY, h:mm A'
            )}`}</Typography>
          </Box>
          <Box
            style={{
              width: '35%',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Typography
              component={'span'}
              variant={'body2'}
              className={classes.ordersText}
            >
              {`${t(item.status)}`}
            </Typography>
          </Box>
        </Box>
        <Divider />
      </Box>
    );
  };

  return (
    <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
      <div>
        <Box className={classes.searchBoxContainer}>
          <Box style={{width: '100%'}}>
            <SimpleAutocomplete
              options={
                listDataReceived
                  ? listDataReceived.map((option) => {
                      return lowerCase(option.customerName);
                    })
                  : ''
              }
              labelText="Search Orders"
              handleACOnChange={() => {}}
              handleOnChange={_handleSearchedOrder}
              searching={searching}
            />
          </Box>

          <Tooltip title="Filter Orders">
            <Button
              variant="outlined"
              className={classes.filterBtn}
              onClick={() => {
                openFilterDialogFunction();
              }}
            >
              <FilterListIcon
                className={classes.filterIconSize}
              ></FilterListIcon>
            </Button>
          </Tooltip>
          <SimpleDialog
            orders={receivedOrders}
            dispatch={dispatch}
            selectedValue={selectedValue}
            open={openFilterDialog}
            onClose={closeFilterDialogFunction}
            listD={listDataReceived}
            setListD={setListDataReceived}
          />
          <Tooltip title="Customer Details">
            <Button
              variant="outlined"
              className={classes.AccountBtn}
              onClick={() => {
                history.push('/mycustomers');
              }}
            >
              <AccountBoxOutlinedIcon
                className={classes.AccountIconSize}
              ></AccountBoxOutlinedIcon>
            </Button>
          </Tooltip>
        </Box>
        {/* {loading ? (
          <ActivityIndicator />
        ) : ( */}
        <InifiniteScrollComponent
          loading={loading}
          listData={listDataReceived}
          scrollListItem={getListItem}
          fetchRecords={getVendorsOrdersData}
          noDataMessage="No Orders Received"
        />
        {/* )} */}
      </div>
    </ErrorBoundary>
  );
};

export default VendorOrdersScreen;
