/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-no-duplicate-props */
import React, {useState, useEffect} from 'react';
import {Typography, Box, TextField, Button} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import useStyle from './styles';
import {useSelector} from 'react-redux';
import addMembers from '../../services/addMembers';
import showToast from '../../components/Toast';
import {useHistory} from 'react-router';
import WebToolbar from '../../components/toolbar/WebToolbar';
import AppBarName from '../../components/DumbComponents/AppBarName';
import SideNav from '../../components/sidenav/SideNav';
import ErrorBoundary from '../../components/ErrorBoundary';
import ErrorBoundaryFallback from '../../components/ErrorBoundaryFallback';
import MobileFooter from '../MobileFooter';

const AddMembersScreen = (props) => {
  // const {
  //   location: {
  //     state: {
  //       group: {id},
  //     },
  //   },
  // } = props;
  const classes = useStyle();
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState();
  const [phoneError, setPhoneError] = useState();

  const token = useSelector((state) => state.user.token);
  const [displayContacts, setDisplayContacts] = useState([]);
  const [query, setQuery] = useState('');
  const forceUpdate = useState()[1].bind(null, {});
  const [loading, setLoading] = useState(true);
  const [reqesting, setReqesting] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [permissionState, setPermissionState] = useState('');
  const [isSelectedContact, setIsSelectedContact] = useState();
  const [selectedContacts, setSelectedContacts] = useState([]);
  const [number, setNumber] = useState();
  const groupDetails = useSelector((state) => state.user.groupDetails);

  useEffect(() => {
    console.log('prps', props, groupDetails);
  }, []);

  // function onPressContact(contact, index) {
  //   console.log('item', contact, 'index', index);
  //   setIsSelectedContact(index);
  //   setSelectedContacts(contact.num);
  // }

  async function onPressAdd() {
    // const payload = {
    //   MobileNos: selectedContacts,
    //   groupId: id,
    // };
    const payload = {
      // groupId: props.location.state.group.id,
      groupId: groupDetails.group.id,
      MobileNos: [number],
    };
    setReqesting(true);
    try {
      console.log('payload', payload);
      const response = await addMembers(payload, token);
      console.log('add response', response);
      if (response.successCode === 1) {
        // showToast('success', response.returnMessage);
        showToast('success', 'User Added to group successfully');
        history.push('groupinfo');
        // if (getGroupInfo) {
        //   getGroupInfo();
        // }
      } else {
        showToast('error', response.returnMessage);
      }
      setReqesting(false);
    } catch (error) {
      setReqesting(false);
    }
  }

  const checkPhoneError = (val) => {
    let phoneErr = '';
    let validMobileNumber = new RegExp(/^([6-9]{1})([0-9]{9})$/);
    if (val?.length < 10) {
      phoneErr = 'Minimum 10 characters are required';
    }
    if (!validMobileNumber.test(val)) {
      phoneErr = 'Invalid Phone Number';
    }
    return phoneErr;
  };

  return (
    <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
      <div className={classes.container}>
        <WebToolbar />
        <div className={classes.sideNav}>
          <SideNav />
        </div>
        <AppBarName title={'Add Member'} />
        <Box p={1}>
          <Typography style={{fontWeight: 900}}>Add Group Member : </Typography>
          <Box style={{display: 'flex'}} my={1}>
            <TextField
              id="outlined-basic"
              type="number"
              label="Phone Number"
              variant="outlined"
              onInput={(e) => {
                e.target.value = Math.max(0, parseInt(e.target.value))
                  .toString()
                  .slice(0, 10);
              }}
              inputProps={{maxLength: 12}}
              onBlur={(e) => {
                const err = checkPhoneError(e.target.value);
                setPhoneError(err);
              }}
              error={!!phoneError}
              helperText={phoneError ? phoneError : null}
              size="small"
              onChange={(item) => {
                setNumber(item.target.value);
              }}
            />
            <Button
              style={{marginLeft: '5%'}}
              variant="outlined"
              className={classes.button}
              startIcon={<AddIcon />}
              onClick={() => {
                const err = checkPhoneError(number);
                if (err?.length) {
                  setPhoneError(err);
                } else {
                  setPhoneError('');
                  onPressAdd();
                }
              }}
            >
              Add
            </Button>
          </Box>
        </Box>
        {/* <Box
        style={{
          width: '98%',
          margin: '10px',
        }}
      >
        {contacts.map((item, index) => {
          return (
            <>
              <Grid
                key={index}
                container
                style={{}}
                style={
                  isSelectedContact === index
                    ? {
                        backgroundColor: 'gray',
                        color: 'white',
                        alignItems: 'center',
                        padding: '5px',
                      }
                    : {
                        display: 'flex',
                        alignItems: 'center',
                        cursor: 'pointer',
                        padding: '5px',
                      }
                }
                onClick={() => {
                  onPressContact(item, index);
                }}
              >
                <Grid xs={1}>
                  <Avatar className={classes.small_avatar}>
                    <PersonIcon></PersonIcon>
                  </Avatar>
                </Grid>
                <Grid xs={11}>
                  <Box>
                    <Typography>{item.name}</Typography>
                    <Typography style={{fontSize: '14px'}}>
                      {item.num}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
              <Divider style={{margin: '6px'}} />
            </>
          );
        })}
      </Box>
      <Fab
        disabled={selectedContacts.length === 0}
        aria-label="like"
        style={{position: 'fixed', bottom: '10%', right: '10%'}}
        onClick={() => {
          onPressAdd();
        }}
      >
        <SendIcon />
      </Fab> */}
      </div>
      <MobileFooter />
    </ErrorBoundary>
  );
};

export default AddMembersScreen;
