import {makeStyles} from '@material-ui/styles';
import Colors from '../../theme/Colors';

const useStyle = makeStyles((theme) => ({
  // container: {
  //   backgroundColor: 'white',
  //   position: 'relative',
  //   height: '600px',
  //   // padding: 10,
  //   flex: 1,
  // },
  sideNav: {
    ['@media (max-width:700px)']: {
      display: 'none',
    },
    //height: '100%',
    width: '92px',
    position: 'fixed',
    zIndex: 1,
    top: 0,
    // right: 0,
    right: '15px',
    overflowX: 'hidden',
    marginTop: 190,
    // marginRight: theme.spacing(1),
    marginRight: 0,
    backgroundColor: '#3B3B3B',
    elevation: 2,
  },
  container: {
    position: 'relative',
    height: '500px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    width: '80%',
    margin: 'auto',
    '& > header > header': {
      position: 'inherit',
      // display: 'flex',
      // justifyContent: 'center',
      // width: '80%',
    },
  },
  text: {
    fontSize: 18,
    fontWeight: 'bold',
    marginTop: 10,
  },
  textInput: {
    display: 'flex',
    letterSpacing: 10,
    fontSize: 28,
    fontWeight: 'bold',
    alignSelf: 'center',
    width: '7rem',
  },
  fabWrapper: {
    display: 'flex',
    justifyContent: 'center',
  },
  fab: {
    position: 'absolute',
    // right: 50,
    bottom: 20,
    width: '7rem',
  },
  text1: {
    fontSize: 14,
    marginTop: 10,
  },
}));

export default useStyle;
