/* eslint-disable react/prop-types */
import React, {useState, useEffect} from 'react';
import useStyle from './styles';
import WebToolbar from '../../components/toolbar/WebToolbar';
import {Box, Divider, Grid, Typography} from '@material-ui/core';
import {useHistory} from 'react-router';
import intro from '../../assets/images/default_user_image.png';
import CallIcon from '@material-ui/icons/Call';
import {get, isEmpty} from 'lodash';
import {useDispatch, useSelector} from 'react-redux';
import getOrderDetails from '../../services/getOrderDetails';
import OrderDetails from '../OrderDetails';
import getCustomerOrders from '../../services/getCustomerOrders';
import ActivityIndicator from '../../components/ActivityIndicator';
import AppBarName from '../../components/DumbComponents/AppBarName';
import {setOrderId} from '../../redux/actions/user';
import {useTranslation} from 'react-i18next';
import SideNav from '../../components/sidenav/SideNav';
import MobileFooter from '../MobileFooter';
import {getAddress} from '../../utils/common';

const CustomerDetails = (props) => {
  const classes = useStyle();
  const {state} = props?.location;
  const name = state?.name;
  const phone = state?.phone;
  const address = state?.address;
  const customerId = state?.customerId;
  const history = useHistory();
  const dispatch = useDispatch();
  const [orders, setOrders] = useState([]);
  const token = useSelector((state) => state.user.token);
  const [loading, setLoading] = useState(false);
  const {t} = useTranslation(['orderDetails', 'common']);

  useEffect(() => {
    console.log('customer props', props);
    getCustomerOrdersData();
  }, []);

  async function getCustomerOrdersData() {
    try {
      setLoading(true);
      // eslint-disable-next-line react/prop-types
      const response = await getCustomerOrders(token, customerId);
      console.log(' orders  response', response);
      if (response.successCode === 1) {
        setOrders(response.response.orders);
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (error) {
      console.log('error', error);
      setLoading(false);
    }
  }

  const getUserAddress = (addr) => {
    const address = getAddress(addr);
    if (address.length === 0) {
      return <></>;
    }

    return (
      <Typography className={classes.ordersText}>{address + '.'}</Typography>
    );
  };

  return (
    <>
      <div className={classes.container}>
        <WebToolbar />
        <div className={classes.sideNav}>
          <SideNav />
        </div>
        <AppBarName title="Customer Details" />
        {loading ? (
          <>
            <ActivityIndicator />
          </>
        ) : (
          <Box>
            <Box boxShadow={1} className={classes.containerOne}>
              <Box className={classes.detailsContainer}>
                <Typography className={classes.ordersText}>{name}</Typography>
                {getUserAddress(address)}
                <Box style={{display: 'flex', alignItems: 'center'}}>
                  <Typography className={classes.ordersText}>
                    Phone :{' '}
                    <a href={`tel:${phone}`} style={{textDecoration: 'none'}}>
                      {phone}
                    </a>
                    {/* {phone} */}
                  </Typography>
                  <a href={`tel:${phone}`} style={{textDecoration: 'none'}}>
                    <CallIcon className={classes.phoneIcon} />
                  </a>
                </Box>
              </Box>
            </Box>
            <Box py={2}>
              <Typography style={{fontWeight: 900}}>
                Customer orders :{' '}
              </Typography>
              {!isEmpty(orders) ? (
                orders.map((order, index) => {
                  return (
                    <Box key={index}>
                      <Grid
                        container
                        my={1}
                        style={{
                          display: 'flex',
                          padding: '5px',
                          cursor: 'pointer',
                        }}
                        onClick={() => {
                          dispatch(setOrderId({orderId: order.orderId}));
                          history.push('/orderdetails');
                          // history.push({
                          //   pathname: '/orderdetails',
                          //   orderID: order.orderId,
                          // });
                        }}
                      >
                        <Grid item xs={7}>
                          <Typography>
                            Order ID: {get(order, 'orderId', 'NA')}
                          </Typography>
                          <Typography>
                            Order Amount: ₹ {get(order, 'orderAmount', 'NA')}
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          xs={5}
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                          }}
                        >
                          {/* <Typography>{`${t(order.status)}`}</Typography> */}
                          <Typography>
                            {t(get(order, 'status', 'NA'))}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Divider />
                    </Box>
                  );
                })
              ) : (
                <Box p={2}>
                  <Typography>No orders Found</Typography>
                </Box>
              )}
            </Box>
          </Box>
        )}
      </div>
      <MobileFooter />
    </>
  );
};

export default CustomerDetails;
