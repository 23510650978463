import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  textVendorName: {
    fontSize: 16,
    fontWeight: '500',
  },
  textAddress: {
    fontSize: 13,
  },
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    padding: theme.spacing(0),
    margin: 0,
    flexDirection: 'row',
  },
  chip: {
    margin: theme.spacing(0.5),
  },
  vendorCategories: {
    fontSize: 13,
    padding: '5px',
    marginLeft: '5px',
    marginRight: '5px',
  },
}));

export default useStyles;
