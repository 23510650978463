import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {Grid, Card, Typography, CardActionArea} from '@material-ui/core';
import {useHistory} from 'react-router-dom';
import PincodeModal from '../PincodeModal';
import {useSelector} from 'react-redux';
import {
  ic_grocery,
  ic_farmer,
  ic_salon,
  ic_clinic,
  ic_hospital,
  ic_pharmacy,
  ic_tomato,
  ic_other,
  ic_restaurant,
  ic_news,
  ic_dryclean,
  ic_wholesale,
  ic_electrician,
  ic_plumber,
  ic_bakery,
  ic_dairy,
  ic_chaiwalla,
  ic_delivery,
  ic_pathalogy_lab,
  ic_medical,
  pex_dairy,
  pex_delivery1,
  pex_dryClean,
  pex_electrician,
  pex_pathalogy_lab,
  pex_fitnessWellness,
} from '../../assets';

import useStyles from './styles';

function Categories({categories, showPincodeModal}) {
  const classes = useStyles();
  const history = useHistory();
  //const [showPincodeModal, setShowPincodeModal] = useState(false);
  const pinCode = useSelector((state) => state.user.pincode);

  function onPress(item) {
    if (pinCode === null) {
      showPincodeModal();
      return;
    }
    history.push(`/vendors/${item.id}`);
  }

  function renderBlogs(item) {
    return (
      <Card className={classes.card}>
        <CardActionArea
          className={classes.actionArea}
          onClick={() => onPress(item)}
        >
          <Typography noWrap className={classes.title}>
            {item.description}
          </Typography>
          <img
            className={classes.media}
            draggable={false}
            src={
              item.id === 1
                ? ic_grocery
                : item.id === 2
                ? ic_salon
                : item.id === 3
                ? ic_clinic
                : item.id === 4
                ? ic_hospital
                : item.id === 5
                ? ic_tomato
                : item.id === 6
                ? ic_farmer
                : item.id === 7
                ? ic_pharmacy
                : item.id === 18
                ? ic_news
                : item.id === 14
                ? ic_restaurant
                : item.id === 23
                ? ic_wholesale
                : item.id === 22
                ? pex_dryClean
                : item.id === 26
                ? pex_electrician
                : item.id === 25
                ? ic_plumber
                : item.id === 30
                ? pex_dairy
                : item.id === 31
                ? ic_bakery
                : item.id === 32
                ? ic_chaiwalla
                : item.id === 34
                ? pex_pathalogy_lab
                : item.id === 33
                ? pex_fitnessWellness
                : item.id === 36
                ? pex_delivery1
                : ic_other
            }
          />
        </CardActionArea>
      </Card>
    );
  }

  return (
    <Grid className={classes.root} container spacing={1}>
      {categories.map((text, index) => (
        <Grid key={index} item xs={6} md={4} sm={3}>
          {renderBlogs(text)}
        </Grid>
      ))}
    </Grid>
  );
}

Categories.propTypes = {
  categories: PropTypes.array,
  showPincodeModal: PropTypes.func,
};

export default Categories;
