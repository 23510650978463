import baseUrl from '../utils/Constants';

export default async function (token, basketId) {
  const formBody = JSON.stringify({}, (key, value) => {
    if (value !== null) {
      return value;
    }
    return {};
  });
  const response = await fetch(
    `${baseUrl}/usergroups/baskets/close?basketId=${basketId}`,
    {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: formBody,
    },
  );
  const responseJson = await response.json();
  return responseJson;
}
