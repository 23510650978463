import baseUrl from '../utils/Constants';

export default async function (token, basketId) {
  const response = await fetch(
    `${baseUrl}/usergroups/baskets/freeze?basketId=${basketId}`,
    {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    },
  );
  const responseJson = await response.json();
  return responseJson;
}
