import {makeStyles} from '@material-ui/styles';

const useStyle = makeStyles((theme) => ({
  root: {},
  container: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    paddingBottom: '100px',
    width: '80%',
    height: '600px',
    backgroundColor: 'white',
    position: 'relative',
    margin: 'auto',
    '& > header > header': {
      position: 'inherit',
      // display: 'flex',
      // justifyContent: 'center',
      // width: '80%',
    },
  },
  sideNav: {
    ['@media (max-width:700px)']: {
      display: 'none',
    },
    //height: '100%',
    width: '92px',
    position: 'fixed',
    zIndex: 1,
    top: 0,
    // right: 0,
    right: '15px',
    overflowX: 'hidden',
    marginTop: 190,
    // marginRight: theme.spacing(1),
    marginRight: 0,
    backgroundColor: '#3B3B3B',
    elevation: 2,
  },
  icon: {
    width: 40,
    height: 40,
  },
  searchContainer: {marginTop: '15px', display: 'flex', alignItems: 'center'},
  selectedItemCont: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
  },
  btnCont: {
    position: 'absolute',
    bottom: '0',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
}));

export default useStyle;
