import baseUrl from '../utils/Constants';

export default async function (payload) {
  const formBody = JSON.stringify(payload, (key, value) => {
    if (value !== null) {
      return value;
    }
    return {};
  });
  const response = await fetch(
    `${baseUrl}/auth/passwordrecovery/changepassword`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: formBody,
    }
  );
  const responseJson = await response.json();
  return responseJson;
}
