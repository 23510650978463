import {makeStyles} from '@material-ui/core';

const useStyle = makeStyles((theme) => ({
  sideNav: {
    ['@media (max-width:700px)']: {
      display: 'none',
    },
    //height: '100%',
    width: '92px',
    position: 'fixed',
    zIndex: 1,
    top: 0,
    // right: 0,
    right: '15px',
    overflowX: 'hidden',
    marginTop: 190,
    // marginRight: theme.spacing(1),
    marginRight: 0,
    backgroundColor: '#3B3B3B',
    elevation: 2,
  },
  container: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    paddingBottom: '100px',
    width: '80%',
    backgroundColor: 'white',
    margin: 'auto',
    '& > header > header': {
      position: 'inherit',
    },
  },
  orderDetailsContainer: {display: 'flex'},
  tabContainer: {minHeight: '380px', overflow: 'auto'},
  ordersText: {
    fontSize: '16px',
    ['@media (max-width:450px)']: {
      fontSize: '14px',
    },
  },
  orderIdTxt: {fontWeight: '900'},
  containerOrderBy: {display: 'flex', alignItems: 'center', cursor: 'pointer'},
  tabFont: {
    borderLeft: '1px solid gray',
    ['@media (max-width:450px)']: {
      fontSize: '10px',
    },
  },
  txtfild: {
    [`& fieldset`]: {
      borderRadius: 15,
    },
  },
  input: {
    display: 'none',
  },
  cor_Btn: {
    color: 'white',
    backgroundColor: '#4497A8',
  },
  cardSelfMsg: {
    padding: 8,
    marginRight: 'auto',
    margin: 3,
    maxWidth: '100%',
    marginLeft: '50%',
    backgroundColor: '#ADD8E6',
    color: 'black',
    elevation: 2,
    borderTopLeftRadius: 15,
    borderTopRightRadius: 0,
    borderBottomLeftRadius: 15,
    borderBottomRightRadius: 15,
    minWidth: 70,
  },
  textSender: {
    color: 'black',
    fontWeight: 500,
  },
  cardMessage: {
    padding: 4,
    margin: 3,
    maxWidth: '100%',
    marginRight: '50%',
    backgroundColor: '#D3D3D3',
    color: 'black',
    elevation: 2,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 15,
    borderBottomLeftRadius: 15,
    borderBottomRightRadius: 15,
    minWidth: 70,
  },
  textSelfMsg: {
    fontSize: 14,
    color: 'black',
  },
  textDate: {
    fontSize: 10,
    color: 'black',
    textAlign: 'right',
  },
  xyz: {},
  abc: {display: 'flex', justifyContent: 'flex-end'},
  orderItmCont: {
    display: 'flex',
    justifyContent: 'space-around',
  },
  tabPanelTwoCont: {display: 'flex', flexDirection: 'column'},
  msgIpBtnContainer: {
    display: 'flex',
    alignItems: 'center',
    position: 'sticky',
    bottom: '0px',
    backgroundColor: '#FAFAFA',
  },
  sendMessageBtn: {color: 'white', backgroundColor: '#4497A8'},
  cameraContainer: {display: 'flex'},
  cameraContainerOne: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  captureImgContainer: {display: 'flex', flexDirection: 'column'},
  captureSaveBtnContainer: {display: 'flex', justifyContent: 'space-evenly'},
  saveImgBtn: {backgroundColor: '#3F8F3C', color: 'white'},
  retakeBtn: {backgroundColor: '#9C27B0', color: 'white'},
  logsContainer: {display: 'flex', justifyContent: 'space-around'},
  btnContainer: {display: 'flex', justifyContent: 'space-evenly'},
  chatSection: {
    backgroundColor: '#f2f2f2',
    width: '100%',
    height: '45vh',
    overflow: 'hidden',
    position: 'relative',
    overflow: 'scroll',
    scrollBehavior: 'smooth',
  },
  deletBtn: {
    width: '30%',
    borderRadius: '25px',
  },
  menuItem: {
    '&:hover': {
      backgroundColor: '#fff',
      color: '#4497A8',
    },
  },
}));

export default useStyle;
