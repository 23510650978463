import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import AppBarName from '../../components/DumbComponents/AppBarName';
import SideNav from '../../components/sidenav/SideNav';
import WebToolbar from '../../components/toolbar/WebToolbar';
import useStyle from './style';
import getVendorDetails from '../../services/getVendorDetails';
import getUpiHandles from '../../services/getUpiHandles';
import addUpiId from '../../services/addUpiId';
import {useHistory} from 'react-router';
import showToast from '../../components/Toast';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  List,
  ListItem,
  Menu,
  MenuItem,
  TextField,
  Typography,
} from '@material-ui/core';
import ActivityIndicator from '../../components/ActivityIndicator';
import {setUpiIds} from '../../redux/actions/user';
import ErrorBoundary from '../../components/ErrorBoundary';
import ErrorBoundaryFallback from '../../components/ErrorBoundaryFallback';
import MobileFooter from '../MobileFooter';

function _SimpleDialog(props) {
  const {onClose, open, id} = props;
  const token = useSelector((state) => state.user.token);
  const history = useHistory();

  const handleClose = () => {
    onClose();
  };

  const _handleListItemClick = async () => {
    try {
      const response = await addUpiId(
        {
          UPI_Id: id,
          Enabled: true,
        },
        token
      );
      console.log('response', response);
      if (response.successCode === 1) {
        showToast('success', 'UPI ID updated');
        // navigation.goBack();
        handleClose();
        // history.goBack();
      } else {
        showToast('error', response.returnMessage);
        handleClose();
      }
    } catch (error) {
      showToast('error', 'Failed. Please try again');
      handleClose();
      console.log(error);
    }
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <List>
        <ListItem>
          <Typography>
            {('Are you sure?', `Is ${id} this your merchant UPI ID?`)},
          </Typography>
        </ListItem>

        <ListItem
          style={{
            display: 'flex',
            justifyContent: 'center',
            justifyContent: 'space-evenly',
          }}
        >
          <Button
            variant="outlined"
            onClick={() => {
              _handleListItemClick();
            }}
          >
            YES
          </Button>
          <Button variant="outlined" onClick={handleClose}>
            NO
          </Button>
        </ListItem>
      </List>
    </Dialog>
  );
}

_SimpleDialog.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
  id: PropTypes.any,
};

const AddUPIScreen = () => {
  const classes = useStyle();
  const history = useHistory();
  const dispatch = useDispatch();
  const token = useSelector((state) => state.user.token);
  const vendorId = useSelector((state) => {
    console.log('state.user.vendorId', state.user.vendorId);
    return state.user.vendorId;
  });
  const user = useSelector((state) => {
    return state.user.user;
  });
  const upiIds = useSelector((state) => {
    console.log('state.user.upiIds', state.user.upiIds);
    return state.user.upiIds;
  });
  const [openDialog, setOpenDialog] = useState(false);
  const [upiId, setUpiId] = useState('');
  const [handles, setHandles] = useState([]);
  const [selectedHandle, setSelectedHandle] = useState();
  const [reqesting, setReqesting] = useState(false);
  const [loading, setLoading] = useState(true);
  // const [upiIds, setUpiIds] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const closeDialogFunction = (value) => {
    setOpenDialog(false);
  };

  const handleClose = (val) => {
    console.log('val', val);
    setSelectedHandle(val);
    setAnchorEl(null);
  };

  useEffect(() => {
    getData();
  }, []);

  async function getData() {
    try {
      const response = await Promise.all([
        getVendorDetails(token, vendorId),
        getUpiHandles(token),
      ]);
      setLoading(false);
      console.log('response', response);
      if (response[1].successCode === 1) {
        setHandles(response[1].response);
        if (response[0].response.vendorAccounts) {
          if (response[0].response.vendorAccounts.length === 0) {
            setSelectedHandle(response[1].response[0].name);
          } else {
            dispatch(setUpiIds(response[0].response.vendorAccounts));
            const split = response[0].response.vendorAccounts[
              response[0].response.vendorAccounts.length - 1
            ].upI_Id.split('@');
            setUpiId(split[0]);
            setSelectedHandle(split[1]);
          }
        }
      } else {
        // navigation.goBack();
        history.goBack();
        showToast('error', 'Failed');
      }
    } catch (error) {
      setLoading(false);
      history.goBack();
      //   navigation.goBack();
      showToast('error', 'Failed');
    }
  }
  function onPressSubmit() {
    if (!upiId.trim()) {
      showToast('error', 'Please enter your merchant UPI ID.');
      return;
    }
    // const id = `${upiId}@${selectedHandle}`;
    setOpenDialog(!openDialog);
  }

  return (
    <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
      <div className={classes.container}>
        <WebToolbar />
        <div className={classes.sideNav}>
          <SideNav />
        </div>
        <AppBarName title={'Add UPI'} />
        <Box>
          {loading ? (
            <ActivityIndicator />
          ) : (
            <>
              <Box className={classes._container}>
                <Typography>{'Enter your merchant UPI ID'}</Typography>

                <Box className={classes.containerInput}>
                  <TextField
                    label={'UPI ID'}
                    value={upiId}
                    size="small"
                    onChange={(text) => setUpiId(text.target.value)}
                    variant="outlined"
                    disabled={reqesting}
                    className={classes.textInput}
                  />
                  <Box className={classes.containerPicker}>
                    <Button
                      aria-controls="simple-menu"
                      aria-haspopup="true"
                      variant="outlined"
                      onClick={handleClick}
                      size="small"
                    >
                      {'UPI Handle'}
                    </Button>
                    <Menu
                      id="simple-menu"
                      anchorEl={anchorEl}
                      keepMounted
                      open={Boolean(anchorEl)}
                      onClose={handleClose}
                    >
                      {handles.map((element, index) => (
                        <Box key={index}>
                          <MenuItem
                            onClick={(val) => {
                              handleClose(val.target.textContent);
                            }}
                            value={element.name}
                          >{`${element.name}`}</MenuItem>
                        </Box>
                      ))}
                    </Menu>
                  </Box>
                </Box>
                {upiId.trim() !== '' && (
                  <Typography
                    className={classes.textUpiId}
                  >{`Your UPI ID: ${upiId}@${selectedHandle}`}</Typography>
                )}

                <Button
                  className={classes.btn}
                  variant="outlined"
                  disabled={reqesting}
                  size="small"
                  onClick={onPressSubmit}
                >
                  Submit
                </Button>

                <Typography className={classes.textNote}>
                  NOTE: Payment will be only processed when payee is having a
                  valid merchant account of UPI. This won’t work for general UPI
                  payee user.
                </Typography>

                {upiIds
                  ? upiIds.length > 1 && (
                      <Box
                        onClick={
                          () => history.push('/viewupiscreen')
                          // history.push({pathname: 'viewupiscreen', state: upiIds})
                        }
                        style={{
                          marginVertical: 10,
                          padding: 10,
                          borderRadius: 8,
                          borderWidth: 1,
                        }}
                      >
                        <Typography
                          style={{textAlign: 'center', color: 'blue'}}
                        >
                          View Previous UPI IDS
                        </Typography>
                      </Box>
                    )
                  : ''}
              </Box>
              <Box>
                <_SimpleDialog
                  open={openDialog}
                  onClose={closeDialogFunction}
                  id={`${upiId}@${selectedHandle}`}
                />
              </Box>
            </>
          )}
        </Box>
      </div>
      <MobileFooter />
    </ErrorBoundary>
  );
};

export default AddUPIScreen;
