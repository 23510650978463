/* eslint-disable react/prop-types */
import React, {useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import CircularProgress from '@material-ui/core/CircularProgress';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Typography from '@material-ui/core/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {useDispatch} from 'react-redux';
import loginService from '../services/login';
import {
  setUser,
  setLoggedIn,
  setToken,
  setProfilePic,
  setVendorId,
} from '../redux/actions/user';
import {toast} from 'react-toastify';

const useStyles = makeStyles((theme) => ({
  dialog: {
    padding: theme.spacing(1),
    justifyContent: 'center',
    alignSelf: 'center',
    alignItems: 'center',
  },
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
  title: {
    color: '#fff',
  },
  text: {
    color: '#fff',
    textAlign: 'justify',
  },
  button: {
    marginTop: theme.spacing(1),
  },
  form: {
    marginTop: theme.spacing(1),
    width: '100%',
    flex: 1,
  },
  input: {
    margin: theme.spacing(1, 0, 1, 0),
  },
}));

export default function CustomizedDialogs({visible, hide}) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [rememberMe, setRememberMe] = useState(false);
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const classes = useStyles();

  const showToast = (event, message) => {
    console.log('event', 'message', event, message);
    event.preventDefault();

    toast.error(message, {
      position: 'top-right',
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  async function handleSubmit(event) {
    event.preventDefault();
    if (!email.trim() || !password.trim()) {
      showToast(event, 'Please enter email/phone and password', 'danger');
      return;
    }

    setLoading(true);
    setShowPassword(false);
    const fcmToken = 'fcmToken';
    const deviceId = 'deviceId';
    const payload = {
      userName: email,
      password: password,
      deviceId: deviceId,
      fcmToken: fcmToken,
    };
    try {
      const response = await loginService(payload, fcmToken, deviceId, 25);
      console.log(response);
      if (response.successCode === 1) {
        dispatch(setUser(response.response));
        dispatch(setToken(response.response.token));
        dispatch(setProfilePic(response.response.profilePicture));
        if (response.response.vendor) {
          dispatch(setVendorId(response.response.vendor.vendorId));
        }
        dispatch(setLoggedIn(true));
        hide();
      } else if (response.messageCode === '400300124') {
        // navigation.replace('OTP', {userName: email});
      } else {
        showToast(event, response.returnMessage);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  }

  return (
    <div>
      <Dialog
        onClose={hide}
        aria-labelledby="customized-dialog-title"
        open={visible}
        fullWidth={true}
        classes={{
          paper: classes.dialog,
        }}
      >
        <DialogTitle id="form-dialog-title">Sign In</DialogTitle>
        <DialogContent>
          <form className={classes.form} onSubmit={handleSubmit} noValidate>
            <TextField
              type="email"
              placeholder="Email/Phone Number"
              label="Email/Phone Number"
              fullWidth
              name="email"
              variant="outlined"
              value={email}
              onChange={(event) => setEmail(event.target.value)}
              required
              autoFocus
              multiline={false}
              disabled={loading}
              className={classes.input}
              helperText=""
            />

            <TextField
              type={showPassword ? 'text' : 'password'}
              placeholder="Password"
              label="Password"
              fullWidth
              name="password"
              variant="outlined"
              value={password}
              multiline={false}
              onChange={(event) => setPassword(event.target.value)}
              required
              disabled={loading}
              className={classes.input}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      disabled={loading}
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />

            <Grid
              container
              style={{
                alignItems: 'center',
              }}
            >
              <Grid item xs>
                <FormControlLabel
                  checked={rememberMe}
                  disabled={loading}
                  onChange={(e) => setRememberMe(e.target.checked)}
                  control={
                    <Checkbox
                      checked={rememberMe}
                      color="primary"
                      disabled={loading}
                      onChange={(e) => setRememberMe(e.target.checked)}
                    />
                  }
                  label="Remember me"
                />
              </Grid>
              <Grid item>
                <Link
                  color="secondary"
                  href="/login/resetpassword"
                  variant="body2"
                >
                  Forgot password?
                </Link>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
        <div style={{display: 'flex', flexDirection: 'column'}}>
          <Button
            variant="outlined"
            type="submit"
            disabled={loading}
            className={classes.button}
            onClick={(e) => handleSubmit(e)}
          >
            {loading ? (
              <CircularProgress color="white" size={24} />
            ) : (
              <Typography>Submit</Typography>
            )}
          </Button>

          <Button
            variant="outlined"
            type="button"
            disabled={loading}
            className={classes.button}
            onClick={(e) => {
              e.preventDefault();
              //history.push('/accounts/new');
            }}
            style={{marginTop: 20}}
          >
            <Typography>{`New to GearConnect? Sign Up`}</Typography>
          </Button>
        </div>
      </Dialog>
    </div>
  );
}
