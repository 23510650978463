import {AppBar, Box, Fab, Toolbar, Typography, Zoom} from '@material-ui/core';
import React, {useEffect, useState} from 'react';
import useStyle from './styles';
import AddIcon from '@material-ui/icons/Add';
import {useHistory} from 'react-router';
import {useDispatch, useSelector} from 'react-redux';
import getVendorItemCategories from '../../services/getVendorItemCategories';
import showToast from '../../components/Toast';
import {setCatalougeModalCount} from '../../redux/actions/user';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import WebToolbar from '../../components/toolbar/WebToolbar';
import AppBarName from '../../components/DumbComponents/AppBarName';
import ActivityIndicator from '../../components/ActivityIndicator';
import SideNav from '../../components/sidenav/SideNav';
import ErrorBoundary from '../../components/ErrorBoundary';
import ErrorBoundaryFallback from '../../components/ErrorBoundaryFallback';
import MobileFooter from '../MobileFooter';

const BuildCatalogueScreen = (props) => {
  const classes = useStyle();
  const history = useHistory();
  const dispatch = useDispatch();
  const showCatalougeModalCount = useSelector((state) => {
    console.log('showCatalogueModalCount', state.user.showCatalougeModalCount);
    return state.user.showCatalougeModalCount;
  });
  const [listData, setListData] = useState([]);
  const [loading, setLoading] = useState(true);
  const token = useSelector((state) => state.user.token);
  const vendorId = useSelector((state) => state.user.vendorId);
  const [showModal, setShowModal] = useState(false);
  const forceUpdate = useState()[1].bind(null, {});

  useEffect(() => {
    console.log('props', props);
    console.log('in build catalogue');
    getList();
    showInfoModal();
  }, []);

  async function getList() {
    try {
      const response = await getVendorItemCategories(token, vendorId);
      console.log('response', response);
      if (response.successCode === 1) {
        setListData(response.response.itemCategories);
        setLoading(false);
      } else {
        //showToast('Failed', 'danger');
      }
      setLoading(false);
    } catch (error) {
      showToast('error', 'Failed');
      console.log(error);
      setLoading(false);
    }
  }

  function showInfoModal() {
    if (showCatalougeModalCount === undefined) {
      dispatch(setCatalougeModalCount(0));
      setShowModal(true);
    } else if (showCatalougeModalCount < 2) {
      dispatch(setCatalougeModalCount(showCatalougeModalCount + 1));
      setShowModal(true);
    }
  }

  return (
    <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
      <Box className={classes.container}>
        <WebToolbar />
        <div className={classes.sideNav}>
          <SideNav />
        </div>
        <AppBarName title={'Add Items'} />
        {loading ? (
          <ActivityIndicator />
        ) : (
          <>
            <Box>
              {listData.length > 0 ? (
                listData.map((item, index) => {
                  return (
                    <Box key={index}>
                      <Box
                        onClick={() => {
                          if (item.children) {
                            const data = listData;
                            listData[index].expanded = !listData[index]
                              .expanded;
                            setListData(data);
                            forceUpdate();
                          } else {
                            history.push({
                              pathname: '/catalougeAddItems',
                              state: {item: item},
                            });
                          }
                        }}
                        className={classes.containerItem}
                      >
                        <Typography className={classes.textItem}>
                          {item.name}
                        </Typography>
                        <ArrowRightAltIcon style={{cursor: 'pointer'}} />
                      </Box>
                      {item.expanded &&
                        {
                          /* <SubItems navigation={navigation} items={item.children} /> */
                        }}
                    </Box>
                  );
                })
              ) : (
                <Box p={2}>
                  <Typography>No Catagories Found</Typography>
                </Box>
              )}
            </Box>
            <Box>
              <Zoom in={true} timeout={{enter: 500, exit: 500}} unmountOnExit>
                <Fab
                  variant="extended"
                  size="medium"
                  aria-label="add"
                  className={classes.fab}
                  onClick={() => {
                    history.push({
                      pathname: '/additemsscreen',
                      state: {items: listData},
                    });
                  }}
                >
                  <AddIcon />
                  Add Individual Items
                </Fab>
              </Zoom>
            </Box>
          </>
        )}
      </Box>
      <MobileFooter />
    </ErrorBoundary>
  );
};

export default BuildCatalogueScreen;
