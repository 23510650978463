import React from 'react';
import {
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@material-ui/core';
import PropTypes from 'prop-types';

const DIALOG_TITLE = 'Are you sure?';

const SimpleDialog = ({
  open,
  onSimpleDialogClose,
  message,
  handleBasketListItemClick,
}) => {
  return (
    <Dialog
      onClose={onSimpleDialogClose}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      {/* <DialogTitle id="simple-dialog-title">{DIALOG_TITLE}</DialogTitle> */}
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <Typography>{message}</Typography>
        </DialogContentText>

        <DialogActions
          style={{
            display: 'flex',
            justifyContent: 'space-evenly',
          }}
        >
          <Button
            variant="outlined"
            style={{backgroundColor: '#3578BD', color: 'white'}}
            onClick={handleBasketListItemClick}
          >
            YES
          </Button>
          <Button
            variant="outlined"
            style={{backgroundColor: '#3578BD', color: 'white'}}
            onClick={onSimpleDialogClose}
          >
            NO
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

SimpleDialog.propTypes = {
  open: PropTypes.bool,
  onSimpleDialogClose: PropTypes.func,
  message: PropTypes.string.isRequired,
  handleBasketListItemClick: PropTypes.func,
};

export default SimpleDialog;
