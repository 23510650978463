/* eslint-disable react/prop-types */
import {AppBar, Box, Tab, Tabs} from '@material-ui/core';
import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import WebToolbar from '../../components/toolbar/WebToolbar';
import useStyle from './style';
import CalenderView from '../../components/CalenderView';
import _CalenderView from '../../components/_CalenderView';
import AppbarName from '../../components/DumbComponents/AppBarName';
import SideNav from '../../components/sidenav/SideNav';
import {
  a11yProps,
  TabPanel,
} from '../../components/DumbComponents/TapPanelProps';
import ErrorBoundary from '../../components/ErrorBoundary';
import ErrorBoundaryFallback from '../../components/ErrorBoundaryFallback';
import MobileFooter from '../MobileFooter';

const VendorAppointmentsScreen = () => {
  const classes = useStyle();
  const user = useSelector((state) => state.user.user);
  const forceUpdate = useState()[1].bind(null, {});
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    forceUpdate();
  }, [user.isVendor]);

  return (
    <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
      <div className={classes.container}>
        <WebToolbar />
        <AppbarName title={'Appointments'} />
        <div className={classes.sideNav}>
          <SideNav />
        </div>
        <Box>
          {user.isVendor ? (
            <Box>
              <AppBar position="static" elevation={0}>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="simple tabs example"
                >
                  <Tab
                    className={classes.tab}
                    label="As User"
                    {...a11yProps(0)}
                  />
                  <Tab
                    className={classes.tab}
                    label="As Vendor"
                    {...a11yProps(1)}
                  />
                </Tabs>
              </AppBar>
              <TabPanel value={value} index={0}>
                <CalenderView type="user" />
              </TabPanel>
              <TabPanel value={value} index={1}>
                <CalenderView type="vendor" />
              </TabPanel>
            </Box>
          ) : (
            <CalenderView type="user" />
          )}
        </Box>
      </div>
      <MobileFooter />
    </ErrorBoundary>
  );
};

export default VendorAppointmentsScreen;
