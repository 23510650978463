import baseUrl from '../utils/Constants';

export default async function (token, groupId, _lastId) {
  console.log('token', token, 'groupId', groupId);
  let apiUrl = `${baseUrl}/usergroups/baskets/list?groupId=${groupId}`;
  if (_lastId) {
    apiUrl = `${baseUrl}/usergroups/baskets/list?groupId=${groupId}?lastBasketId=${_lastId}`;
  }
  const response = await fetch(
    `${baseUrl}/usergroups/baskets/list?groupId=${groupId}`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }
  );
  const responseJson = await response.json();
  return responseJson;
}
