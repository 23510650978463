import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Box from '@material-ui/core/Box';
import MuiDialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import Link from '@material-ui/core/Link';
import {useSelector, useDispatch} from 'react-redux';
import {setTermsAccepted} from '../redux/actions/user';

const useStyles = makeStyles((theme) => ({
  dialog: {
    width: '82%',
    position: 'absolute',
    top: 0,
    padding: theme.spacing(1),
    margin: theme.spacing(1),
    justifyContent: 'center',
    alignSelf: 'center',
    alignItems: 'center',
    // backgroundColor: theme.palette.secondary.main,
    backgroundColor: '#D6D7D3',
  },
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
  title: {
    color: '#000',
    fontSize: '16px',
  },
  text: {
    color: '#000',
    fontSize: '14px',
  },
  button: {
    marginTop: theme.spacing(1),
    color: 'white',
    backgroundColor: '#0277BD',
    '&:hover': {
      color: '#0277BD',
      backgroundColor: 'white',
      border: '1px solid #0277BD',
    },
  },
}));

export default function CustomizedDialogs() {
  const classes = useStyles();
  const isTermsAccepted = useSelector((state) => state.user.isTermsAccepted);
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(setTermsAccepted(true));
  };

  return (
    <div>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={!isTermsAccepted}
        fullWidth={true}
        disableBackdropClick
        maxWidth="xl"
        classes={{
          paper: classes.dialog,
        }}
      >
        <Box width="100%">
          <MuiDialogTitle disableTypography className={classes.root}>
            <Typography className={classes.title}>
              Accept our Terms of Service and Privacy Policy
            </Typography>
          </MuiDialogTitle>
          <MuiDialogContent className={classes.root}>
            <Typography className={classes.text}>
              We use cookies and other tracking technologies to improve your
              browsing experience on our website, to analyze our website
              traffic.
            </Typography>
            <Typography className={classes.text}>
              By clicking on Accept, you agree to our
              <Link
                onClick={() =>
                  window.open(
                    'https://gcterms.s3.ap-southeast-1.amazonaws.com/termsofuse.html',
                    '_blank'
                  )
                }
                color="primary"
                href="#"
              >
                {' Terms of Use '}
              </Link>
              and
              <Link
                color="primary"
                onClick={() =>
                  window.open(
                    'https://gcterms.s3.ap-southeast-1.amazonaws.com/privacypolicy.html',
                    '_blank'
                  )
                }
                href="#"
              >
                {' Privacy Policy'}
              </Link>
            </Typography>
            <Button
              className={classes.button}
              variant="outlined"
              onClick={handleClose}
            >
              Accept
            </Button>
          </MuiDialogContent>
        </Box>
      </Dialog>
    </div>
  );
}
