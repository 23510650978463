import {makeStyles} from '@material-ui/core';
import Colors from '../../theme/Colors';

const useStyle = makeStyles(() => ({
  container: {
    paddingBottom: '100px',
    width: '80%',
    margin: 'auto',
    '& > header > header': {
      position: 'inherit',
    },
  },
  sideNav: {
    ['@media (max-width:700px)']: {
      display: 'none',
    },
    //height: '100%',
    width: '92px',
    position: 'fixed',
    zIndex: 1,
    top: 0,
    // right: 0,
    right: '15px',
    overflowX: 'hidden',
    marginTop: 190,
    // marginRight: theme.spacing(1),
    marginRight: 0,
    backgroundColor: '#3B3B3B',
    elevation: 2,
  },

  card: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  list: {
    paddingBottom: 100,
  },
}));

export default useStyle;
