import baseUrl from '../utils/Constants';
import {setRefreshFav} from '../redux/actions/user';
import {store} from '../redux/store';
import getMessageFromStatus from '../utils/getMessageFromStatus';

export default async function (vendorId, token) {
  try {
    const formBody = JSON.stringify({}, (key, value) => {
      if (value !== null) {
        return value;
      }
      return {};
    });
    const response = await fetch(
      `${baseUrl}/users/favorite_vendors/${vendorId}`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: formBody,
      },
    );

    if (response.status === 200) {
      const responseJson = await response.json();
      if (responseJson.successCode === 1) {
        store.dispatch(setRefreshFav(true));
      }
      return responseJson;
    } else {
      return {
        successCode: 0,
        messageCode: getMessageFromStatus(response.status),
        statusCode: response.status,
        url: response.url,
      };
    }
  } catch (error) {
    return {
      successCode: 0,
      messageCode: getMessageFromStatus(0),
    };
  }
}
